import { bulkTicketPayloadData } from "./constants"

export const champReferralBonusInitialStates = {
    // fundamental comman variables  

    pending: false,
    loader: false,
    filterData: [],
    filterDataTemp: [],
    filteredSelectedRows: [],

    // varies based on requirement variable 
    masterReasonData: [],
    pausePaidData: [],
    renewalReasonData: [],
    adminListData: [],
    supervisorListData: [],
    vehicleReasonData: [],
    whatsAppTemplatesData: [],
    fetchCallToggler: null,
    storeFilterList: null,

    // common states dont need to make every time changes 
    totalRows: 0,
    perPageSize: 10,
    sortby: "",
    direction: "desc",
    page: 1,
    search: "",
    filterkey: {},
    filter: [],

    // bulk ticket assing  common variable  

    bulkTicketModalOpen: false,
    isBulkAssignApiLoading: false,
    amountModalOpen: false,
    isLoadingSubmitAmount: false,
    ticketPayload: bulkTicketPayloadData(["dueDate"]),


    // bulk Whatsapp Notification send common variable 
    bulkWhatsAppNotificationModalOpen: false,
    isBulkWhatsAppApiLoading: false,
    whatsAppPayload: {
        templateName: '',
    }
}


export const champReferralBonusReducer = (champReferralBonusInitialStates: any, { type, payload }: any) => {

    switch (type) {
        case "RTL_PENDING":
            return { ...champReferralBonusInitialStates, pending: payload }
        case "RTL_LOADER":
            return { ...champReferralBonusInitialStates, loader: payload }
        case "RTL_FILTERED_DATA":
            return { ...champReferralBonusInitialStates, filterData: payload }
        case "RTL_TEMP_FILTERED_DATA":
            return { ...champReferralBonusInitialStates, filterDataTemp: payload }
        case "RTL_FILTERED_SELECTED_ROWS":
            return { ...champReferralBonusInitialStates, filteredSelectedRows: payload }
        case "RTL_MASTER_REASON":
            return { ...champReferralBonusInitialStates, masterReasonData: payload }
        case "RTL_PAUSE_PAID":
            return { ...champReferralBonusInitialStates, pausePaidData: payload }
        case "RTL_RENEWAL_REASON":
            return { ...champReferralBonusInitialStates, renewalReasonData: payload }
        case "RTL_ADD_ADMIN_LIST":
            return { ...champReferralBonusInitialStates, adminListData: payload }
        case "RTL_ADD_SUPERVISOR_LIST":
            return { ...champReferralBonusInitialStates, supervisorListData: payload }
        case "RTL_ADD_VEHICLE_REASONS":
            return { ...champReferralBonusInitialStates, vehicleReasonData: payload }
        case "RTL_WHATSAPP_TEMPLATE":
            return { ...champReferralBonusInitialStates, whatsAppTemplatesData: payload }
        case "RTL_FETCH_CALL_LOG_TOGGLER":
            return { ...champReferralBonusInitialStates, fetchCallToggler: payload }
        case "RTL_STOLRE_FILTER_LISTS":
            return { ...champReferralBonusInitialStates, storeFilterList: payload }
        case "RTL_TOTAL_ROWS":
            return { ...champReferralBonusInitialStates, totalRows: payload }
        case "RTL_PER_PAGE_SIZE":
            return { ...champReferralBonusInitialStates, perPageSize: payload }
        case "RTL_SORT_BY":
            return { ...champReferralBonusInitialStates, sortby: payload }
        case "RTL_DIRECTION":
            return { ...champReferralBonusInitialStates, direction: payload }
        case "RTL_PAGE":
            return { ...champReferralBonusInitialStates, page: payload }
        case "RTL_SEARCH":
            return { ...champReferralBonusInitialStates, search: payload }
        case "RTL_FILTER_KEY":
            return { ...champReferralBonusInitialStates, filterkey: payload }
        case "RTL_FILTER":
            return { ...champReferralBonusInitialStates, filter: payload }
        case "RTL_BULK_TICKET_ASSIGN_MODAL":
            return { ...champReferralBonusInitialStates, bulkTicketModalOpen: payload }
        case "RTL_BULK_ASSINING_API_LOADING":
            return { ...champReferralBonusInitialStates, isBulkAssignApiLoading: payload }
        case "RTL_BULK_TICKET_PAYLOAD":
            return { ...champReferralBonusInitialStates, ticketPayload: payload }
            // Whatsapp notificaion case here 
        case "RTL_BULK_WHATS_APP_MODAL_OPEN":
            return { ...champReferralBonusInitialStates, bulkWhatsAppNotificationModalOpen: payload }
        case "RTL_BULK_WHATS_APP_API_LOADING":
            return { ...champReferralBonusInitialStates, isBulkWhatsAppApiLoading: payload }
        case "RTL_BULK_WHATS_APP_PAYLOAD":
            return { ...champReferralBonusInitialStates, whatsAppPayload: payload }
        case "OPEN_AMOUNT_MODAL":
            return { ...champReferralBonusInitialStates, amountModalOpen: payload }
        case "API_LOADING_AMOUNT":
            return { ...champReferralBonusInitialStates, isLoadingSubmitAmount: payload }
        default:
            return champReferralBonusInitialStates
    }
}





