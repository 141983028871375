
const initState = {
    DailyReAssign: null,
    DailyReAssigDateChange: null,
    areawise: {},
    trackIDAssigning: "",
    trackIDAssigningCanceled: "",
    refreshbankAccount: 0,
    refreshtTickets: null,
    CallAlertRender: null,
    RenewalReason: null,
    job_dash_refresh: null,
    chatbox: null,
    Change_Status: {
        Change_Status: null,
        Change_Statu_Render: null
    },
    Intiate_Refund: {
        Intiate_Refund: null,
        Intiate_Refund_Render: null
    },
    dublicateVehicleremove: {
        dublicateid: null,
        dublicate_Render: null
    },
    vehicleDeleted: {
        vehicleid: null,
        vehicle_render: null
    },
    offerListItem: {
        offerid: null,
        offer_render: null
    },
    vehicleUpdated: {
        vehcileid: null,
        updateVehicle_render: null
    },
    completSubscription: {
        vehicleid: null,
        complete_subscription: null
    },
    clickUpTaskComment: {
        clickUp: null,
        clcikUp_render: null
    },
    CurrentMessageSource: {
        CurrentMessageSource: null,
        CurrentMessageSourceRender: null,
        from: "TicketMessageSource"
    },
    champ_rquirement_type: null,
    menuKeys: {},
    toggleColumn: {},
    dailyDashboardRefresh: false,
    isReadOnly: {
    },

    isAllowedAction: {},
    refreshCreateBatch: null,
    smartFilterListData: {},

}


export const RefreshPage = (state = initState, { type, payload }: any) => {

    switch (type) {
        case "DAILY-RE-ASSIGN":
            return { ...state, DailyReAssign: payload }
        case "DAILY-RE-ASSIGN-DATE-CHANGE":
            return { ...state, DailyReAssigDateChange: payload }
        case "GOOGLEATUOCOMPLETEAREAWISE":
            return { ...state, areawise: payload }
        case "TRACK_ASSSIGNING":
            return { ...state, trackIDAssigning: payload }
        case "TRACK_ASSSIGNING_CANCELED":
            return { ...state, trackIDAssigningCanceled: payload }
        case "REFRESH-BANK":
            return { ...state, refreshbankAccount: payload }
        case "REFRESH_TICKET":
            return { ...state, refreshtTickets: payload }
        case "CallingAlert":
            return { ...state, CallAlertRender: payload }
        case "REFRESH_JOB_DASH":
            return { ...state, job_dash_refresh: payload }

        case "CHAT_BOX":
            return { ...state, chatbox: payload }
        case "REASON_REFRESH":
            return { ...state, RenewalReason: payload }
        case "CHANGE_STATUS":
            return { ...state, Change_Status: payload }
        case "INITIATE_REFUND":
            return { ...state, Intiate_Refund: payload }
        case "DUBLICATE_rEMOVE":
            return { ...state, dublicateVehicleremove: payload }
        case "VEHICLE_DELTED":
            return { ...state, vehicleDeleted: payload }
        case "OFFER_LIST_DELETED":
            return { ...state, offerListItem: payload }
        case "VEHICLE_UPDATED":
            return { ...state, vehicleUpdated: payload }
        case "REFRESH_CREATE_BATCH":
            return { ...state, refreshCreateBatch: payload }
        case "COMPLETE_SUBSCRIPTION":
            return { ...state, completSubscription: payload }
        case "CURRENT_MESSAGE_SOURCE":
            return { ...state, CurrentMessageSource: payload }
        case "CLICK_UP_TASK_COMMENT":
            return { ...state, clickUpTaskComment: payload }
        case "CHAMP_REQUIREMENT_TYPE":
            return { ...state, champ_rquirement_type: payload }
        case "MENU_KEYS":
            return { ...state, menuKeys: payload }
        case "TOGGLE_COLUMNS":
            return { ...state, toggleColumn: payload }
        case "DAILY_DASHBOARD_REFRESH":
            return { ...state, dailyDashboardRefresh: payload }
        case "IS_READ_ONLY":
            return { ...state, isReadOnly: payload }
        case "STORE_SMART_FILTER_DATA":
            return { ...state, smartFilterListData: { ...state.smartFilterListData, [payload?.key]: payload?.data } }
        case "IS_ALLOWED_ACTION":
            return { ...state, isAllowedAction: { ...state.isAllowedAction, [payload?.keyName]: payload?.data } }
        default:
            return state
    }
}



// const chatboxRefresh = useSelector((store:any)=>store.RefreshPage.chatbox)
// const smartFilterListData = useSelector((store:any)=>store.RefreshPage.smartFilterListData)
// const job_dash_refresh = useSelector((store:any)=>store.RefreshPage.job_dash_refresh)
// const job_dash_refresh = useSelector((store:any)=>store.RefreshPage.job_dash_refresh)
// const Change_Status = useSelector((store:any)=>store.RefreshPage.Change_Status)
// const Intiate_Refund_rEfresh = useSelector((store:any)=>store.RefreshPage.Intiate_Refund)
// const dublicateVehicleremove = useSelector((store:any)=>store.RefreshPage.dublicateVehicleremove)
// const vehicleDeleted = useSelector((store:any)=>store.RefreshPage.vehicleDeleted)
// const vehicleUpdated = useSelector((store:any)=>store.RefreshPage.vehicleUpdated)
// const offerListItem = useSelector((store:any)=>store.RefreshPage.offerListItem)
// vehicleUpdated.updateVehicle_render
// const completSubscription = useSelector((store:any)=>store.RefreshPage.completSubscription)
// const CurrentMessageSource = useSelector((store: any) => store.RefreshPage.CurrentMessageSource)
// const clickUpTaskComment = useSelector((store: any) => store.RefreshPage.clickUpTaskComment)
// const champ_rquirement_type = useSelector((store: any) => store.RefreshPage.champ_rquirement_type)
// const menuKeysData = useSelector((store: any) => store.RefreshPage.menuKeys)
// const toggleColumn = useSelector((store: any) => store.RefreshPage.toggleColumn)
// const dailyDashboardRefresh = useSelector((store: any) => store.RefreshPage.dailyDashboardRefresh)
// const isReadOnly = useSelector((store: any) => store.RefreshPage.isReadOnly)
// const isAllowedAction = useSelector((store: any) => store.RefreshPage.isAllowedAction)
// const refreshCreateBatch = useSelector((store: any) => store.RefreshPage.refreshCreateBatch)

// completSubscription.complete_subscription

// dispatch({ type:"REFRESH_TICKET", payload:Math.random()+payloads.ticketid})
// dispatch({ type:"REFRESH_TICKET", payload:Math.random()+payloads.ticketid})
