import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import MultiSelectSearchDetailsDefault from '../../consts/Select/MultiSelectSearchDetailsDefault';
import { useSelector } from 'react-redux';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes, toastNotifier } from '../../consts/components/Utility';
import SingleSelectSearchCategoryDefault from '../../consts/Select/SingleSelectSearchCategoryDefault';
import { ToggleColumns } from '../../consts/toggleColumns/ToggleColumns';
import IsPaidTaskListAndRenewalTaskYesNo from '../../consts/SweetAlert/IsPaidTaskListAndRenewalTaskYesNo';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import Refresh from '../../consts/Refresh';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import AdminBulkAssignUnassignForm from '../admins/adminForms/AdminBulkAssignUnassignForm';
import { filteredSelectedRowsIds, useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils';
import AdminBulkForm from '../admins/adminForms/AdminBulkForm';
import { adminMasterReasonTemplateFormData, adminWatiNotifyTemplatFormData } from '../admins/adminConstant';
const userid = JSON.parse(localStorage.getItem("user") || "0");

const RenewalTaskListPagination = ({ filterDataOutsource }: any) => {

  const AdminListData = useSelector((store: any) => store.VisitorDataReducer.adminList)
  const AllSupervisroList = useSelector((store: any) => store.VisitorDataReducer.supervisorList)
  const renewalReasons = useSelector((store: any) => store.ReasonsListDataReducer.renewalReason)
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [filterDataTemp, setfilterDataTemp] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload(false, {}))
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [renders, setRender] = useState<any>(null)

  const openIsPaidModalHandler = () => {
    setRender(Math.random())
  }

  async function loadData() {
    const whatsappTemplates: any = await postRequest(URL_EndPoints(null)?.get_WhatsAppTemplate, {
      wa_number_id: 1
    }, null);
    setWhatsappTemplates(whatsappTemplates?.data?.data);
  }

  useEffect(() => {
    loadData();
  }, []);

  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.renewalTaskPagination, payloads, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.taskList)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  };

  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }

  }, [search])

  useEffect(() => {
    let { isFilter, renwalTaskListFilter } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: renwalTaskListFilter }, null)
    isFilter && setPayload({ ...payload, ["filterData"]: renwalTaskListFilter })
  }, [
    search,
    filterDataOutsource?.renwalTaskListFilter,
    filterDataOutsource?.isFilter,
  ])

  function handleFetchAllCallLogs() {
    setfilterData((prev: any) => {
      setfilterDataTemp(prev);
      return prev;
    });
  }

  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setPending(true);
      const clone = filterDataTemp.slice();
      const customerIds = clone.map((e: any) => e.customer_id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, setPending);
      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e?.vehicleCustomer?.phone || ele[0]?.To === e?.vehicleCustomer?.phone) || [];
        clone[i].callLogs = foundLog;
      }
      setfilterData(clone);
      setPending(false);
    }
    filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [filterDataTemp])

  const [sortedColumns, setSortedColumns] = useState<any>(columns(handleFetchAllCallLogs));

  useEffect(() => {
    if (filterData?.length > 0) {
      const newColumns = columns(handleFetchAllCallLogs, filterData?.map((ele: any) => ele?.attendencecleaner?.id), filterData?.map((ele: any) => ele?.subscriptionMonth?.customerid));
      setSortedColumns(newColumns);
    }
  }, [filterData]);

  const [filteredSelectedRowsBulkIds, setfilteredSelectedRowsBulkIds] = useState<any>([]);

  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [showAssignPopUp, setShowAssignPopUp] = useState(false)
  const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
  const [assignPayload, setAssignPayload] = useState<any>({
    admins: [],
    cleaners: [],
    should_unassign: false,
    admins_to_unassign: [],
    cleaners_to_unassign: [],
  });

  const handleSelectedRowsChange = (selectedRows: any) => {
    let isPaidIds = selectedRows?.selectedRows?.map((row: any) => row?.id) || [];
    setfilteredSelectedRowsBulkIds(isPaidIds)
    setFilteredSelectedRows(selectedRows?.selectedRows)
  };

  const handleAssignPopUpClose = () => {
    setAssignPayload({
      admins: [],
      cleaners: [],
      should_unassign: false,
      admins_to_unassign: [],
      cleaners_to_unassign: [],
      dueDate: '',
    })
    setShowAssignPopUp(false)
  }

  const handleAssignPayloadUpdate = (event: any, name: any) => {
    setAssignPayload({ ...assignPayload, [name]: event })
  }

  const handleAssignSubmit = async (e: any) => {
    e.preventDefault()
    let ids = filteredSelectedRows?.map((row: any) => row?.vehicleSubscriptionsSingle?.subscriptionticket?.id);
    ids = ids.filter((id: number) => (id !== undefined) && (id !== 0));
    assignPayload['ids'] = ids;
    setIsBulkAssignApiLoading(true)
    const response: any = await postRequest(URL_EndPoints(null)?.assignToBulkTickets, assignPayload, null)
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
    setShowAssignPopUp(false)
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows && setFilteredSelectedRows([])
    setIsBulkAssignApiLoading(false)

  }

  const [showNotifyPopUp, setShowNotifyPopUp] = useState(false);
  const [isBulkNotifyLoading, setIsBulkNotifyLoading] = useState(false);
  const [whatsappNotifyPayload, setWhatsappNotifyPayload] = useState<any>({
    templateName: '',
  });

  const handleNotifyPopUpClose = () => {
    setWhatsappNotifyPayload({
      templateName: '',
    });
    setShowNotifyPopUp(false);
  }

  const handleWhatsappInputChange = (e: any) => {
    setWhatsappNotifyPayload({
      ...whatsappNotifyPayload,
      [e.target.name]: e.target.value
    });
  }

  const handleBulkWhatsappNotifySubmit = async (e: any) => {
    e.preventDefault();
    let customerIds = filteredSelectedRows?.map((row: any) => row?.vehicleCustomer?.id);
    whatsappNotifyPayload['customerIds'] = customerIds || [];
    setIsBulkNotifyLoading(true)
    const response: any = await postRequest(URL_EndPoints(null)?.sendBulkWhatsAppTemplate, whatsappNotifyPayload, setIsBulkNotifyLoading)
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows && setFilteredSelectedRows([])
    setShowNotifyPopUp(false)
    setIsBulkNotifyLoading(false)
  }

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);

  }, [selectedFilterData?.length]);

  const [showStatusPopUp, setShowStatusPopUp] = useState(false);
  const [isBulkStatusUpdateLoading, setIsBulkStatusUpdateLoading] = useState(false);
  const [statusPayload, setStatusPayload] = useState({
    renewal_reason_id: 0,
    subscriptionIds: [],
  });

  useEffect(() => {
    renewalReasons?.length ? setStatusPayload(prev => ({ ...prev, renewal_reason_id: renewalReasons[0]?.id })) : setStatusPayload(prev => ({ ...prev, renewal_reason_id: 0 }));
  }, [renewalReasons, renewalReasons?.length]);

  const handleStatusPopUpClose = () => {
    setShowStatusPopUp(false);
    setStatusPayload({
      renewal_reason_id: 0,
      subscriptionIds: [],
    });
  }

  const handleStatusChange = (event: any) => {
    if (event === null) {
      setStatusPayload(prev => ({ ...prev, renewal_reason_id: 0 }));
    } else {
      setStatusPayload(prev => ({ ...prev, renewal_reason_id: event.value }));
    }
  }

  const handleBulkStatusUpdateSubmit = async (event: any) => {
    event.preventDefault();
    let subscriptionIds = filteredSelectedRows?.map((row: any) => row?.id);
    const _statusPayload = { ...statusPayload };
    _statusPayload['subscriptionIds'] = subscriptionIds;
    setIsBulkStatusUpdateLoading(true);
    const response: any = await postRequest(URL_EndPoints(null)?.updateSubscriptionData, _statusPayload, setIsBulkStatusUpdateLoading);
    setToggleClearRows(!toggledClearRows)
    toastNotifier(response?.data, "message", setShowStatusPopUp)
    setFilteredSelectedRows && setFilteredSelectedRows([])
    setIsBulkStatusUpdateLoading(false);
  }

  const [showResetLeadPopUp, setShowResetLeadPopUp] = useState(false);
  const [isBulkResetLoading, setIsBulkResetLoading] = useState(false);
  const [resetPayload, setResetPayload] = useState<any>({
    adminId: userid,
    vehicleIds: [],
    adminIds: [],
    areaManagerIds: [],
  });

  const handleResetPopUpClose = () => {
    setAssignPayload({
      adminId: userid,
      vehicleIds: [],
      adminIds: [],
      areaManagerIds: [],
    });
    setShowResetLeadPopUp(false);
  }

  const handleResetPayloadUpdate = (event: any, name: any) => {
    setResetPayload({ ...resetPayload, [name]: event })
  }

  const handleResetSubmit = async (e: any) => {
    e.preventDefault();
    let ids = filteredSelectedRows?.map((row: any) => row?.id);
    resetPayload['vehicleIds'] = ids.filter((id: number) => id);
    setIsBulkResetLoading(true)
    const response: any = await postRequest(URL_EndPoints(null)?.bulkResetCustomerVehicle, resetPayload, setIsBulkResetLoading)
    setIsBulkResetLoading(false)
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows && setFilteredSelectedRows([])
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })

  }

  const [bulkRenewalTaskLoading, setBulkRenewalTaskLoading] = useState(false);
  const handleBulkRenewalTaskCreate = async (parameter?: any) => {
    let ids = filteredSelectedRows?.map((row: any) => row?.subscriptionMonth?.id);
    const payload = {
      subscriptionIds: ids.filter((id: number) => id),
    }
    setBulkRenewalTaskLoading(true);
    const response: any = await postRequest(URL_EndPoints(null)?.createRenewalTaskForNextDateBulk, payload, setBulkRenewalTaskLoading)
    setBulkRenewalTaskLoading(false)
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      setFilteredSelectedRows && setFilteredSelectedRows([])
    } else {
      toast.info(response?.data?.message);
    }
  }

  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  const refreshNow = () => {
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }


  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("renewalTaskPagination")
  }, [])
  return (
    <>
      <div>

        <DataTable
          title={!filterDataOutsource?.isFilter && "Renewal Task List"}
          selectableRows
          progressPending={pending}
          columns={reorderColumns(sortedColumns, "renewalListTaskReorderedItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "renewalListTaskReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          // server 
          pagination
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          clearSelectedRows={toggledClearRows}
          // server 
          subHeaderComponent={
            <>
              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={search} setSearch={setSearch} />
                </LeftBox>
                <RightBox>
                  <Refresh refreshNow={refreshNow} />
                  <ToggleColumns columns={[{ name: "RENEWAL DATE", id: "RENEWAL DATE" }]} from="RENEWAL_TASK_LIST" />
                  <FilterHandler
                    onChangeHandler={onChangeHandler}
                    actionHandler={actionHandler}
                    getCurrentFilter={getCurrentFilter}
                    removeFilter={removeFilter}
                    payload={payload}
                    Component={DynamicFilter}
                    filterData={filter}
                    OperatorsData={operators}
                    selectedFilterData={selectedFilterData}
                    filterComponents={KeywiseComponents()}
                    storeFilterList={storeFilterList}
                    filterkey={filterkey}
                    setSeacrch={setSearchFilter}
                    isLoadingFilter={isLoadingFilter}
                    listApiUrl={listApiUrl("renewalTaskPagination")}

                  />
                </RightBox>
              </MainContainerWrapper>
              {
                filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                  <BulkUtilButton title="Bulk Assign Ticket" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignPopUp} />
                  <BulkUtilButton title="Bulk WhatsApp Notify Customer" filteredSelectedRows={filteredSelectedRows} setShow={setShowNotifyPopUp} />
                  <BulkUtilButton title="Bulk Reset Customer" filteredSelectedRows={filteredSelectedRows} setShow={setShowResetLeadPopUp} />
                  <BulkUtilButton title="Bulk Update Status" filteredSelectedRows={filteredSelectedRows} setShow={setShowStatusPopUp} />
                  <BulkUtilButton title="Bulk Assign Batch To Vehicles" filteredSelectedRows={filteredSelectedRows} setShow={setRender} randomValue={true} />
                  <BulkUtilButton title={bulkRenewalTaskLoading ? "Creating Next Service..." : "Create Next Service Renewal Task"} filteredSelectedRows={filteredSelectedRows} setShow={handleBulkRenewalTaskCreate} />
                  <BulkUtilButton title={"Bulk Update Is Paid Status"} filteredSelectedRows={filteredSelectedRows} setShow={openIsPaidModalHandler} />
                </BulkUtilButtonWrapper> : <></>
              }


            </>
          }
        />

      </div>

      {/* 
      {
        showAssignPopUp ? <AdminBulkAssignUnassignForm
          show={showAssignPopUp}
          setShow={setShowAssignPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "vehicleSubscriptionsSingle.subscriptionticket.id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="msg"
          initialData={{
            admins: [],
            cleaners: [],
            admins_to_unassign: [],
            cleaners_to_unassign: [],
            dueDate: "",
            should_unassign: false,
          }}
          validationSchema={{
            admins: { required: false, errorMessage: "Admins is Required !" },
            cleaners: { required: false, errorMessage: "Supervisors is Required !" },
            admins_to_unassign: { required: false, errorMessage: "Unassign Admins is Required !" },
            cleaners_to_unassign: { required: false, errorMessage: "Unassign Supervisor is Required !" },
            dueDate: { required: false, errorMessage: "Due Date is Required !" },
            should_unassign: { required: false, errorMessage: "" }
          }}
          keyName="renewalTaskListAssignForm"
          formTitle={`Assign ${filteredSelectedRows?.length} Tickets to Admins/Supervisors`}
          bulkKeyName="ids"
          formSubmitApiUrl="assignToBulkTickets"
        /> : <></>
      } */}





      <Modal size='lg' show={showAssignPopUp} onHide={handleAssignPopUpClose} >
        <Modal.Header closeButton>
          <Modal.Title>Assign {filteredSelectedRows.length} Tickets to Admins/Supervisors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAssignSubmit}>
            <Form.Group className="mb-3" >
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleAssignPayloadUpdate}
                HeaderTitle="Select admins"
                SelectData={AdminListData}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="admins"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>
            <Form.Group className="mb-3" >
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleAssignPayloadUpdate}
                HeaderTitle="Select supervisor"
                SelectData={AllSupervisroList}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="cleaners"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Due Date</Form.Label>
              <Form.Control type="date" value={assignPayload?.dueDate} onChange={(e: any) => handleAssignPayloadUpdate(e?.target?.value, 'dueDate')} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Check
                type='checkbox'
                label='Bulk Unassign'
                id='bulk_unassign'
                checked={assignPayload.should_unassign}
                onChange={(e: any) => {
                  setAssignPayload({
                    ...assignPayload,
                    should_unassign: e.target.checked
                  })
                }}
              />
            </Form.Group>
            {assignPayload?.should_unassign && <Form.Group className="mb-3" >
              <span className='text-muted'>Note: Keep admins empty to unassign all admins</span>
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleAssignPayloadUpdate}
                HeaderTitle="Select admins"
                SelectData={AdminListData}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="admins_to_unassign"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>}
            {assignPayload?.should_unassign && <Form.Group className="mb-3" >
              <span className='text-muted'>Note: Keep supervisors empty to unassign all supervisors</span>
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleAssignPayloadUpdate}
                HeaderTitle="Select supervisors"
                SelectData={AllSupervisroList}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="cleaners_to_unassign"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>}
            <Form.Group className="mb-3 p-2" >
              {
                isBulkAssignApiLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Assign
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>


      <Modal size='lg' show={showNotifyPopUp} onHide={handleNotifyPopUpClose} >
        <Modal.Header closeButton>
          <Modal.Title>Send WhatsApp Notification to {filteredSelectedRows.length} Customers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBulkWhatsappNotifySubmit}>
            <Form.Group className="mb-3" >
              <Form.Select aria-label="Default select example" value={whatsappNotifyPayload.templateName} required name='templateName' onChange={handleWhatsappInputChange}>
                <option value="">Select Template</option>
                {whatsappTemplates?.map((template: any) => <option value={template.name}>{template.name}</option>)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 p-2" >
              {
                isBulkNotifyLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Send
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* {
        showNotifyPopUp ? <AdminBulkForm
          show={showNotifyPopUp}
          setShow={setShowNotifyPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "vehicleCustomer.id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="msg"
          formData={adminWatiNotifyTemplatFormData({ HeaderTitle: "", valueType: "", name: "", errMessage: "", label: "", wa_number_id: 1 })}
          initialData={{
            customerIds: [],
            templateName: '',
          }}
          keyName="renewalTaskListWatiNotify"
          formTitle={`Send WhatsApp Notification to ${filteredSelectedRows?.length} Customers`}
          bulkKeyName="customerIds"
          formSubmitApiUrl="sendBulkWhatsAppTemplate"
        /> : <></>
      } */}

      <Modal size='lg' show={showStatusPopUp} onHide={handleStatusPopUpClose} >
        <Modal.Header closeButton>
          <Modal.Title>Update Status of {filteredSelectedRows.length} Vehicles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBulkStatusUpdateSubmit}>
            <SingleSelectSearchCategoryDefault
              handleChangeInputData={handleStatusChange}
              HeaderTitle={"Select Status"}
              SelectData={renewalReasons}
              DynamicKey={"name"}
              id={"id"}
              name={"name"}
              defaultData={renewalReasons?.length ? { label: renewalReasons[0]?.name, value: renewalReasons[0]?.id } : {}}
            />
            <Form.Group className="mb-3 p-2" >
              {
                isBulkStatusUpdateLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Update
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>


      {/* {
        showStatusPopUp ? <AdminBulkForm
          show={showStatusPopUp}
          setShow={setShowNotifyPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="message"
          formData={[
            {
              HeaderTitle: 'Select Master Category',
              filterApi: 'getSubscriptionRenewalReason',
              label: '',
              valueType: 'select',
              inputType: '',
              displayFields: ['name'],
              valueField: 'id',
              name: 'categoryId',
              from: '',
              to: '',
              isRequired: true,
              errMessage: 'Status name is required !',
              searchable: false,
              wa_number_id: null,
              userType: null,
              categoryId: null,
            },
          ]}
          initialData={{
            renewal_reason_id: 0,
            subscriptionIds: [],
          }}
          keyName="renewalTaskListStatusForm"
          formTitle={`Update Status of ${filteredSelectedRows.length} Vehicles`}
          bulkKeyName="subscriptionIds"
          formSubmitApiUrl="updateSubscriptionData"
        /> : <></>
      } */}


      <Modal size='lg' show={showResetLeadPopUp} onHide={handleResetPopUpClose} >
        <Modal.Header closeButton>
          <Modal.Title>Reset {filteredSelectedRows.length} Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleResetSubmit}>
            <Form.Group className="mb-3" >
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleResetPayloadUpdate}
                HeaderTitle="Select admins"
                SelectData={AdminListData}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="adminIds"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>
            <Form.Group className="mb-3" >
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleResetPayloadUpdate}
                HeaderTitle="Select area manager"
                SelectData={AllSupervisroList}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="areaManagerIds"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>
            <Form.Group className="mb-3 p-2" >
              {
                isBulkResetLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Reset
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>



      {
        renders ?
          <IsPaidTaskListAndRenewalTaskYesNo
            render={renders}
            confirm={'Yes'}
            cancel={'No'}
            payload={{
              "jobIds": filteredSelectedRowsBulkIds,
              "approver_id": localStorage.getItem("user"),
              "requested_by": localStorage.getItem("user"),
              "requested_user_type": 5,
              "updateFields": {
                "ispaid": 1
              }
            }}
            setRender={setRender}
            title={`Are you sure want to Paid ?`}
            setFilteredSelectedRows={setFilteredSelectedRows}
          /> : ""
      }

    </>
  )
}

export default RenewalTaskListPagination
