
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { KTCardBody } from '../../../_metronic/helpers';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { renderToString } from 'react-dom/server';
import Handsontable from 'handsontable';
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import Refresh from '../../consts/Refresh';
import { BulkUtilButton } from '../../consts/reuseModules/BulkUtilButton';
import PayoutFilter from './PayoutFilter';
import ShowPayoutChart from './ShowPayoutChart';
import { toast } from 'react-toastify';
import MomentDate from '../../consts/MomentDate';
import moment from 'moment';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu';
import { colWidthsHotTable, getFilterSelectedRowsData, handleAfterSelectionRow, hotInstanceLoader, rocordTotalFound, searchFieldKeyupCallback, cellRenderInner, getFilteredRowDataNew2 } from '../admins/adminUtils';
registerAllModules();

const payoutTypeData = [{ name: "Weekly", id: 1 }, { name: "Monthly", id: 2 }]
const applyFixedNumber = (value: any) => typeof value === 'number' ? value.toString()?.includes(".") ? value.toFixed(2) : value : value;
function convertData(graphData: any) {
  let kyes = Object.keys(graphData[0] || {})
  return {
    categories: kyes,
    series: graphData?.map((element: any) => {
      let keys = Object.keys(element)
      return {
        name: element["column"],
        data: keys.map((item: any) => applyFixedNumber(element[item]))
      }
    })
  }
}

export const NewPayoutTimelineList = () => {
  const hotTableComponentRef = useRef<any>(null);
  const [search, setSearch] = useState<any>("")
  const [pending, setPending] = useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [showPayoutChart, setShowPayoutChart] = useState(false)
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [chartData, setChartData] = useState([]);

  const [payload, setPayload] = useState<any>({
    start_date: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
    end_date: MomentDate(0),
    payout_type: 1,
  })

  async function getPayoutTimeLineData4List() {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    setPending(true)
    const payoutTimeLineList = await postRequest(URL_EndPoints(null)?.getPayoutTimeline, payload, setPending)
    setPending(false)
    setColumns(payoutTimeLineList?.data?.columns)
    setfilterData(payoutTimeLineList?.data?.data)
    setData(payoutTimeLineList?.data?.data)
  }

  useEffect(() => {
    getPayoutTimeLineData4List()
  }, [])

  let firstTimeNotRun = useRef<any>(false)
  useEffect(() => {
    let x = setTimeout(() => {
      const filterSearchData = AllFielSearch(data, search)
      firstTimeNotRun?.current === true && setfilterData(filterSearchData)
      if (firstTimeNotRun?.current === false) {
        firstTimeNotRun.current = true
      }
    }, 2000)
    return () => {
      clearTimeout(x)
    }
  }, [search])

  const searchHandler = () => {
    getPayoutTimeLineData4List()
  }

  const onChangeHandler = (event: any, name: any) => {
    setPayload({ ...payload, [name]: event ? event?.value : event })
  }

  const removeUnderScore = (str: any) => {
    if (str?.includes("_")) {
      return str?.split("_")?.join(" ")?.toUpperCase()
    }
    else {
      return str?.toUpperCase()
    }
  }

  const showChartHandler = (value: boolean) => {
    if (filteredSelectedRows?.length == 0) {
      toast.error("⚠️ Please select some rows in bulk to see chart", { position: "top-center" })
      return
    }
    setShowPayoutChart(value)
  }

  // hot table code ------------------------------------
  useEffect(() => {
    hotInstanceLoader(hotTableComponentRef)
  }, []);

  const cellRenderer = (...args: any) => {
    cellRenderInner(args, Handsontable)
  }

  const shortWords = (sentence: any, index: any) => {
    if (index < 1) { return removeUnderScore(sentence) }
    const splitedStr = sentence?.split(' ')?.map((ele: any, index: any) => index ? ele?.substring(0, 3) : ele?.substring(0, 3))?.join(" ")
    return removeUnderScore(splitedStr)
  }

  const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
    handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
  };

  // const handleAfterDeselect = () => {
  //   setFilteredSelectedRows([]);
  // };

  // useEffect(() => {
  //   let clearTimeoutFunction = getFilterSelectedRowsData(filteredSelectedRows, filterData, setChartData)
  //   return () => {
  //     clearTimeout(clearTimeoutFunction)
  //   }
  // }, [filteredSelectedRows])


  return (
    <>
      <KTCardBody>
        <div className='mb-4 fs-4 fw-semibold'>Payout Timeline List</div>
        <MainContainerWrapper>
          <LeftBox>
            <SearchBoxModal search={search} setSearch={setSearch} />
          </LeftBox>
          <RightBox>
            <Refresh refreshNow={getPayoutTimeLineData4List} />
            <BulkUtilButton title="Show Payout Chart" filteredSelectedRows="static" setShow={showChartHandler} />
            {
              <DropDownFilterMenu
                ParentData={{
                  handleActions: searchHandler,
                  payloads: payload,
                  onChangeHandler: onChangeHandler,
                  payoutTypeData: payoutTypeData,
                }}
                Component={PayoutFilter}
              ></DropDownFilterMenu>
            }
          </RightBox>
        </MainContainerWrapper>


        {pending ? <IsLoading /> : <HotTable
          ref={hotTableComponentRef}
          style={{ zIndex: 1 }}
          data={filterData}
          columns={columns?.map((ele: any, index: any) => {
            return {
              title: renderToString(<div id={ele} >{shortWords(ele, index)} </div>),
              type: 'text',
              data: ele,
              renderer: cellRenderer
            }
          })}

          filters={true}
          dropdownMenu={true}
          colWidths={colWidthsHotTable(columns, 1, 150, 65)}
          width="100%"
          height={"80vh"}
          title='Payout Timeline '
          rowHeaders={true}
          colHeaders={true}
          fixedColumnsLeft={1}
          contextMenu={true}
          manualColumnFreeze={true}
          autoWrapRow={true}
          autoWrapCol={true}
          licenseKey="non-commercial-and-evaluation"
          autoRowSize={true}
          autoColumnSize={true}
          readOnly={true}
          manualRowResize={true}
          manualColumnResize={true}
          manualRowMove={true}
          multiColumnSorting={true}
          search={{ callback: searchFieldKeyupCallback }}
          afterSelection={handleAfterSelection}
          // afterDeselect={handleAfterDeselect}
        />}

      </KTCardBody>
      {columns?.map((ele: any) => (
        <ReactTooltip
          anchorId={ele}
          place={"top"}
          className="bg-dark"
          content={ele}
        />
      ))}

      {
        showPayoutChart ? <ShowPayoutChart
          chartData={convertData(getFilteredRowDataNew2(filteredSelectedRows, filterData))}
          show={showPayoutChart}
          setShow={setShowPayoutChart}
        /> : <></>
      }
    </>
  );
};

