import React from 'react'
import { AdminToolTip } from '../../admins/adminComponents/AdminToolTipModal'
import { AreaManagerWalletsChart } from './AreaManagerWalletsChart'
const CardCountWidgetAreaWallet = ({ className, color, data, chartData, custom_color = null, handleTabClick }: any) => {

    let style: any = {
        backgroundColor: "#3AABFF",
        minHeight: "30px"
    }

    if (custom_color) {
        style.backgroundColor = custom_color
    }

    if (data?.style) {
        data.style = { ...data.style }
        data.style.minHeight = "30px"
    }

    return (
        <div className={`${className}`} >
            <div className={`totalCount col-12 p-0`} style={data?.style ?? style} >
                {/* onClick={() => handleTabClick && handleTabClick(data?.filter, data?.tabKey, data?.isRedirect)} */}
                <div className='d-flex justify-content-center text-center'>
                    <h3 className='m-0 mt-1 text-white fw-bold fs-5'>{data?.areaManager?.first_name + " " + data?.areaManager?.last_name}</h3>
                </div>
                {/* <div className='d-flex text-center flex-column text-white'>
                    <span className='fw-bold fs-2 pt-1'>{data?.total}</span>
                </div> */}
            </div>
            <div className="col-12 p-0" >
                {
                    <AreaManagerWalletsChart data={chartData} />
                }
            </div>
            <div className='p-2 col-12'>
                {
                    data?.otherDetail?.map((sub: any, index: any) => {
                        let toolTipId = sub?.title + "_" + index
                        return <div className='d-flex flex-column align-items-center mb-3' style={sub?.style} id={toolTipId}>
                            {
                                index == 0 && <div className='d-flex align-items-center flex-wrap w-100 subCount'>
                                    <div className='mb-2 col-6' >
                                        <a href='#' className='fs-7 text-gray-800'>
                                            {/* {sub?.title} */}
                                        </a>
                                    </div>
                                    <div className='col-3 d-flex justify-content-center align-items-center'>
                                        <div className=' fs-7 text-dark fw-semibold pe-1'>Till Now</div>
                                    </div>
                                    <div className='col-3 d-flex justify-content-center align-items-center'>
                                        <div className=' fs-7 text-dark fw-semibold pe-1'>Projected</div>
                                    </div>
                                </div>
                            }

                            <div className='d-flex align-items-center flex-wrap w-100 subCount' >
                                {/* onClick={() => handleTabClick ? handleTabClick(sub?.filter, sub?.tabKey, data?.isRedirect) : ""} */}
                                <div className='mb-1 col-6' >
                                    <a href='#' className='fs-7 text-gray-800'>
                                        {sub?.title}
                                    </a>
                                </div>
                                <div className='col-3 d-flex justify-content-center align-items-center'>
                                    <div className=' fs-7 text-gray-800 pe-1'> {sub?.tillNowValue}</div>
                                </div>
                                <div className='col-3 d-flex justify-content-center align-items-center'>
                                    <div className=' fs-7 text-gray-800 pe-1'> {sub?.projectedValue}</div>
                                </div>
                            </div>
                            {sub?.titleTooltip && <AdminToolTip anchorId={toolTipId} title={sub?.titleTooltip} place={""} />}
                        </div>
                    })
                }
            </div>

        </div>
    )
}
export { CardCountWidgetAreaWallet }
