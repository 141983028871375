import React, { useState } from 'react'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { Button, Form, Modal } from 'react-bootstrap'
import SingleSelectSearchDetailsDefaultDyanmic from '../../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic'
import { toast } from 'react-toastify'
import { adminToastRunner } from '../adminUtils'

const AdminLastStatusCustomerForm = ({ setShow, show, row, initialData,  defaultLastStatus, setUpdatedData, lastStatusListData }: any) => {

    // update modal code 
    const [loader, setLoader] = useState<any>(false)

    const [payload, setPayload] = useState<any>(initialData)

    const onChangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : null, ["item"]: event ? event?.item : event })
    }

    const submitFormHandler = async (e: any) => {
        e.preventDefault()
        if (!payload?.renewalreasonid && !payload?.subscriptionid) {
            toast.error("Please select status !", { position: "top-center" })
            return
        }

        setLoader(true)
        let newPaylod = JSON.parse(JSON.stringify(payload))
        delete newPaylod["item"]
        const response = await postRequest(URL_EndPoints(null)?.updateSubscriptionRenewalReason, newPaylod, setLoader)
        adminToastRunner(response)
        if (response?.data?.status === 200) {
            setUpdatedData && setUpdatedData(payload)
            setShow(false)
        }
    }

    return (
        <>
            {
                show ? <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Last Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form onSubmit={submitFormHandler}>

                            <Form.Group className={`col-12  mb-1`}>
                                <SingleSelectSearchDetailsDefaultDyanmic
                                    handleChangeInputData={onChangeHandler}
                                    HeaderTitle={"Select Reason"}
                                    SelectData={lastStatusListData}
                                    DynamicKeys={["name"]}
                                    id={"id"}
                                    name={"renewalreasonid"}
                                    targetValue={"value"}
                                    defaultData={defaultLastStatus ? defaultLastStatus : null}
                                />

                            </Form.Group>

                            <Form.Group className="mb-3 p-2 text-end" >
                                {
                                    <Button type='submit' variant={loader ? "success" : "primary"} >
                                        {loader ? "Please wait..." : "Submit"}
                                    </Button>
                                }
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                </Modal> : ''
            }
        </>
    )
}

export default AdminLastStatusCustomerForm
