import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
const UpdateJobVerificationStatus = ({ show, setShow, jobID, is_verified }: any) => {
    const [loader, setLoader] = useState(false)
    const [payload, setPayload] = useState<any>({
        "job_id": jobID,
        "is_verified": is_verified, 
        "comment": ""
    })

    const bulkStatusUpdateHandler = async () => {
        setLoader(true)
        if (payload?.job_id) {
            const response = await postRequest(URL_EndPoints(null)?.updateAttendenceColumns, payload, setLoader)
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
            setShow(false)

        } else {
            toast.error("Job id missing")
            setShow(false)
        }
    }

    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Job Verification Status </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-3`}>
                        <textarea
                            placeholder='Comment...'
                            name='comment'
                            value={payload?.comment}
                            className='form-control  mb-3 mb-lg-0'
                            autoComplete='off'
                            onChange={(event: any) => setPayload({ ...payload, [event?.target?.name]: event.target.value })}

                        ></textarea>
                    </div>
                    <div className="d-flex justify-content-start aligh-items-center mb-3 col-12">
                        <input type="checkbox" checked={payload?.is_verified == 0 ? false : true} name="isVerified" id="isVerified" onClick={() => setPayload((prev: any) => ({ ...payload, ["is_verified"]: prev?.is_verified == 0 ? 1 : 0 }))} style={{ height: "20px", width: "20px" }} className='me-2 cursor-pointer' />
                        <label className="form-check-label ml-1 fw-bold text-muted" htmlFor="isVerified">Is Verified</label>
                    </div>
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 p-2 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => bulkStatusUpdateHandler()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Status</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default UpdateJobVerificationStatus
