import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { KTCardBody } from '../../../_metronic/helpers'
import CustomerAssignedAgents from './CustomerAssignedAgents'
import { getRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
const CustomerAssignedAgentController: any = () => {
    const dispatch = useDispatch()
    const getData = async () => {
        const supervisorList =   await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
        dispatch({ type: "ADD_SUPERVISOR_LIST", payload: supervisorList?.data?.data });        
        const adminList = await getRequest(URL_EndPoints(null)?.getAdminList, null)
        dispatch({ type: "ADD_ADMIN_LIST", payload: adminList?.data?.data })
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <KTCardBody className='card py-4' >
            <CustomerAssignedAgents />
        </KTCardBody>
    )
}
export default CustomerAssignedAgentController
