import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import AdminSingleSelectFilter from '../admins/adminForms/adminFormComponents/AdminSingleSelectFilter'
import { getRequest } from '../../consts/Server/Requests'
import { adminQueryGenerator, isHtmlContent } from '../admins/adminUtils'
import IsLoading from '../../consts/IsLoading/IsLoading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle, faExclamationTriangle, faStar } from '@fortawesome/free-solid-svg-icons'
import { Checkbox } from '@mui/material'
import AdminReadOnlyEditor from '../admins/adminForms/adminFormComponents/AdminReadOnlyEditor'

export const language = [{ name: "English", id: "en" }, { name: "Hindi", id: "hi" }, { name: "Kannada", id: "kn" }]
const ViewTerms = ({ show, setShow, keyName }: any) => {
    const [filterData, setFilterData] = useState<any>({ notImp: [], imp: [] })
    const [pending, setPending] = useState<any>(false)

    const getTerms = async (payload: any) => {
        setPending(true)
        const response = await getRequest(URL_EndPoints(adminQueryGenerator("getTermsAndCondition", payload))?.getTermsAndCondition, setPending)
        const formatedData = await response?.data?.data?.reduce((obj: any, ele: any) => {
            if (ele?.is_checkbox_required == 0) {
                obj["notImp"].push(ele)
            }
            if (ele?.is_checkbox_required == 1) {
                obj["imp"].push(ele)
            }
            return obj

        }, { notImp: [], imp: [] })

        setFilterData(formatedData)
    }

    const onChangeHandler: any = (event: any, name: any, isDate?: any) => {
        getTerms({ lang: event ? event?.value : "en" })
    }

    useEffect(() => {
        getTerms({ lang: "en" })
    }, [])


    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="xl"
                key={keyName}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Terms & Condition</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Label className='text-muted'>Language</Form.Label>
                    <div className='mb-5' style={{ position: "relative" }}>
                        <AdminSingleSelectFilter
                            onChangeHandler={onChangeHandler}
                            HeaderTitle="Select Reason"
                            SelectData={language}
                            displayFields={["name"]}
                            id="id"
                            name="lang"
                            defaultData={{ item: { label: "English", value: "en" } }}
                            searchable={false}
                        />
                    </div>

                    {
                        pending ? <IsLoading /> : <>
                            <Container className='p-2 g-3' >
                                {
                                    filterData?.notImp?.map((item: any, index: any) => {
                                        return (
                                            <>
                                                <Row className="p-0" >
                                                    <Col className='p-0 col-12 mb-2'>
                                                        {
                                                            isHtmlContent(item?.text) ?
                                                                <AdminReadOnlyEditor
                                                                    content={item?.text}
                                                                />
                                                                :
                                                                item?.text
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    })
                                }
                                {
                                    filterData?.notImp?.length > 0 && <hr className='text-white' />
                                }
                                {
                                    filterData?.imp?.map((item: any, index: any) => {
                                        return (
                                            <>
                                                <Row className="p-0 g-0">
                                                    <Col className="p-0 col-1 d-flex align-items-center justify-content-start">
                                                        <Checkbox style={{ height: "20px" }} checked={true} />
                                                    </Col>
                                                    <Col className="p-0 col-11 d-flex align-items-center justify-content-start">
                                                        {
                                                            isHtmlContent(item?.text) ?
                                                                <AdminReadOnlyEditor
                                                                    content={item?.text}
                                                                />
                                                                :
                                                                item?.text
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    })
                                }

                            </Container>
                        </>

                    }

                </Modal.Body>
            </Modal >
        </>
    )
}
export default ViewTerms
