import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'
import { getRequest, postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import TagInputs from '../../TagInputs/TagInputs';

const AddPublicTag = ({ handleCloseForm, show, row, setRowData, states }: any) => {
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [PayloadsTags, setPayloadsTags] = useState<any>([])
    const [suggestionTagData, setsuggestionTagData] = useState<any>([])

    const getData = async () => {
        let QeueryParams = {
            To: "getMasterTags",
            What: `?user_type=${row?.userType}`
        }
        const result = await getRequest(URL_EndPoints(QeueryParams)?.getMasterTags, null)
        setsuggestionTagData(result?.data?.data)
    }


    useEffect(() => {
        getData()
    }, [])



    const handleChangeInputData = (event: any) => {
        setPayloadsTags(event)
    }


    const handleCreateTags = async () => {
        if (PayloadsTags?.length >= 0) {
            setLoader(true)
            let payloads: any = { tag: PayloadsTags.join(","), cleanerid: row?.id, tagtype: "public" }
            const result = await postRequest(URL_EndPoints()?.addtagforcleaner, payloads, setLoader)
            if (result?.data?.status == 200) {
                toast.success(`${"Public"} tags are Added Successfully`)
                setDisable(200)
                let updatedData: any;
                row.public_tag = PayloadsTags?.length > 0 ? PayloadsTags?.join(",") : ""
                updatedData = row
                setRowData(updatedData)
                handleCloseForm()
            } else {
                setLoader(false)
                setDisable(null)
                toast.error(result?.data?.message)
            }
            handleChangeInputData([])
        }
        else {
            toast.error("Tag is missing ")
        }
    }


    // by this we closeing form
    const handleClose = () => {
        handleCloseForm()
    }
    return (
        <>




            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal-small"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update/Create Public tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <>
                        <div className="col-12  mb-3">
                            <h5> Public Tag </h5>
                            <TagInputs
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Add Tags"
                                SelectData={suggestionTagData}
                                DynamicKey={"name"}
                                id={"id"}
                                name="private_tags"
                                defaultData={states?.length > 0 ? states : []}
                            />
                        </div>

                        <div className='modal-footer'>
                            <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={() => handleCreateTags()}>
                                {
                                    loader ?
                                        <>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Adding.....</h4>
                                        </>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Add Tag </h4>
                                }
                            </button>
                        </div>
                    </>


                </Modal.Body>
            </Modal> : ''}








        </>



    )
}
export default AddPublicTag
