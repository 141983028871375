import React from 'react'

const ShowPercentage = ({ title, value, color, margin }: any) => {
    return < div className={`d-flex justify-content-start  text-${color} rounded-0 ${margin ? "me-4 ms-4" : ""}`}>
        <span className='fw-bold fs-7 me-2'>{title}: 👉 </span>
        <span className='fw-bold fs-7 text-dark'>{value}</span>
    </div>
}

export default ShowPercentage
