import React from 'react';
import { Bars } from 'react-loader-spinner';

const LoaderBars: React.FC = () => {
    return (
        <Bars
            height={20}
            width={20}
            color="#000000"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
        />
    );
};

export default LoaderBars;
