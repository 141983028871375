import React from 'react'
const MininmizeMaximize = ({ setExpandHeight }: any) => {
    return (
        <div className='d-flex justify-content-end' title='Click to expnad or shrink chat for better UI experiance !'>
            <span className={"text-primary border border-primary p-0 px-2 cursor-pointer text-end"} onClick={() => setExpandHeight((prev: any) => !prev)}>
                Minimize/Maximize
            </span>
        </div>
    )
}

export default MininmizeMaximize
