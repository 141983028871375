import React, { useEffect, useState } from 'react'
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
interface Props {
    attendenceId: number,
}
const ImageActionPopup = ({ attendenceId }: Props) => {
    const imageBaseUrl = "https://dic554fk1zcxm.cloudfront.net/";
    const [images, setImages] = useState<any>([]);
    
    useEffect(() => {
        const fetch = async () => {
            const res: any = await postRequest(URL_EndPoints(null)?.showattendenceimages, {
                attendenceid: attendenceId,
            }, null);
            res?.data?.data && setImages(res.data.data);
        }
        fetch();
    },[]);

    return (
        <div className="p-2">
            {images?.length ? 
            <div className="p-5 mr-auto">
               <img src={imageBaseUrl+images[0]?.image}
                    className="img-fluid" alt="Selfie" />
            </div> :
            <div className="p-5 mr-auto">
                {<img src="https://banksrl.co.za/wp-content/uploads/2019/10/NoProductImage_600.jpg" className="img-fluid" alt="Not available" />}
            </div>}
        </div>
    )
}
export default ImageActionPopup
