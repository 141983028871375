import { MD5 } from "crypto-js"
import uuid from "react-uuid";

export const Authorization = async (user_id:any)=>{
    let unique = uuid()
	// const userAgent = navigator.userAgent;
    // const hash = MD5(userAgent).toString();
    // return hash;
	const userAgent = navigator.userAgent;
    const newUserAgent = userAgent+user_id+unique
    const hash = MD5(newUserAgent).toString();
    return hash;
}