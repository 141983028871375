import React, { useEffect, useState } from 'react'
import InactiveCampaignStatus from './actionOnCall/InactiveCampaignStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ProspectStatus from './actionOnCall/ProspectStatus'
import AdminDueDateForm from '../../../modules/admins/adminComponents/AdminDueDateForm'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { getRequest, postRequest } from '../../Server/Requests'
import { useDispatch } from 'react-redux'
import { QueryParams } from '../../Server/QueryParams'

const ActionOnCall = ({ noticationCredential, customerDetailData }: any) => {
    console.log('noticationCredential', noticationCredential);
    const [reasonData, setReasonData] = useState([])
    const [statusData, setStatusData] = useState([])
    const [subStatus, setSubStatus] = useState([])
    const dispatch = useDispatch()
    const getData = async () => {

        const inactiveData = await postRequest(URL_EndPoints(null)?.getSubscriptionRenewalReason, { category_id: 4 }, null)
        dispatch({ type: 'STORE_RENEWAL_REASON_DATA', payload: inactiveData?.data?.data })
        setReasonData(inactiveData?.data?.data)
        const statusData = await getRequest(URL_EndPoints(null)?.getMasterReasonForVisitors, null)
        let newQeueryParams = { ...QueryParams("getMasterSubReason", "?") }
        newQeueryParams.What += "reasonId=" + noticationCredential?.status_id;
        const subStatusData = await getRequest(URL_EndPoints(newQeueryParams)?.getMasterSubReason, null);



        setStatusData(statusData?.data?.data)
        setSubStatus(subStatusData?.data?.data)
    }


    useEffect(() => {
        getData()
    }, [])


    const [updatDueDateInactive, setUpdatDueDateInactive] = useState(false)
    const [updateStatusInactive, setUpdateStatusInactive] = useState(false)
    const [updateCampaignStatusProspect, setupdateCampaignStatusProspect] = useState(false)
    const [updateDueDateProspect, setupdateDueDateProspect] = useState(false)

    const inactiveStatus: any = reasonData?.find((ele: any) => ele.id == noticationCredential?.renewal_reason_id)
    console.log('inactiveStatus', inactiveStatus);
    const prospectStatus: any = statusData?.find((ele: any) => ele.id == noticationCredential?.status_id)
    console.log('prospectStatus', prospectStatus);
    const prospectSubStatus: any = subStatus?.find((ele: any) => ele.id == noticationCredential?.sub_status_id)
    console.log('prospectSubStatus', prospectSubStatus);

    // status_id
    // sub_status_id
    // due_date


    // inactive 
    // renewal_reason_id
    // due_date

    return (
        <div className='mt-10 mb-10 d-flex justify-content-center flex-column align-items-center'>

            {
                noticationCredential?.title ||  noticationCredential?.body  && <div className='mt-8 mb-10'>
                    <div className='h3 text-success'>{noticationCredential?.title}</div>
                    <p className='text-danger fw-semibold'>Warning: {noticationCredential?.body}</p>
                </div>
            }

            {
                noticationCredential?.customer_type == "prospect" ? <div className='d-flex justify-content-center align-items-center gap-3px'>
                    < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0 me-4'>
                        <FontAwesomeIcon icon={faEdit} className='text-muted me-4 fs-5 cursor-pointer' onClick={() => setupdateDueDateProspect(true)} />
                        <span className='fw-bold fs-7 text-dark me-2'>{noticationCredential?.due_date ?? "Not Set"} </span>
                    </div>

                    < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                        <FontAwesomeIcon icon={faEdit} onClick={() => setupdateCampaignStatusProspect(true)} className='text-muted me-4 fs-5 cursor-pointer' />
                        <span className='fw-bold fs-7 text-dark me-2'>{prospectStatus ? prospectStatus?.name : "Not Set"} </span>
                    </div>
                </div> : noticationCredential?.customer_type == "inactive" ? <div className='d-flex justify-content-center align-items-center gap-3px'>
                    < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0 me-4'>
                        <FontAwesomeIcon icon={faEdit} className='text-muted me-4 fs-5 cursor-pointer' onClick={() => setUpdatDueDateInactive(true)} />
                        <span className='fw-bold fs-7 text-dark me-2'>{noticationCredential?.due_date ?? "Not Set"}</span>
                    </div>

                    < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                        <FontAwesomeIcon icon={faEdit} onClick={() => setUpdateStatusInactive(true)} className='text-muted me-4 fs-5 cursor-pointer' />
                        <span className='fw-bold fs-7 text-dark me-2'>{inactiveStatus ? inactiveStatus?.name : "Not Set"} </span>
                    </div>
                </div> : <></>
            }







            {/* update propect due date   */}
            {
                updateCampaignStatusProspect ? <ProspectStatus
                    show={updateCampaignStatusProspect}
                    setShow={setupdateCampaignStatusProspect}
                    defaultData={prospectStatus ? { label: prospectStatus?.name, value: prospectStatus?.id } : null}
                    row={customerDetailData}
                    statusData={statusData}
                    prospectSubStatus={prospectSubStatus}
                /> : <></>
            }

            {
                updateDueDateProspect ? <AdminDueDateForm
                    show={updateDueDateProspect}
                    setShow={setupdateDueDateProspect}
                    setUpdatedData={null}
                    prevInitialPayload={{
                        customerID: customerDetailData?.id,
                        due_date: noticationCredential?.due_date,

                    }}
                    row={customerDetailData}
                    title="Due Date Update"
                    apiUrl="updateCustomerProspectData"
                    dueDateKeyName="due_date"
                    isDateTime={true}
                    isServerDueDate={false}
                    serverApiUrl="xxxxx"
                /> : <></>
            }
            {/* update inactive due date   */}
            {
                updateStatusInactive ? <InactiveCampaignStatus
                    show={updateStatusInactive}
                    reasonData={reasonData}
                    setShow={setUpdateStatusInactive}
                    row={customerDetailData} f
                    defaultData={inactiveStatus ? { label: inactiveStatus?.name, value: inactiveStatus?.id } : null}

                /> : <></>
            }

            {
                updatDueDateInactive ? <AdminDueDateForm
                    show={updatDueDateInactive}
                    setShow={setUpdatDueDateInactive}
                    setUpdatedData={null}
                    prevInitialPayload={{
                        customerID: customerDetailData?.id,
                        due_date: noticationCredential?.due_date,
                    }}
                    row={customerDetailData}
                    title="Due Date Update"
                    apiUrl="updateCustomerInactiveData"
                    dueDateKeyName="due_date"
                    isDateTime={true}
                    isServerDueDate={false}
                    serverApiUrl="xxxxx"
                /> : <></>

            }


        </div>
    )
}

export default ActionOnCall