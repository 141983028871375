import React, { memo } from 'react';
import { withSwal as DeleteAreaName } from 'react-sweetalert2';
import { postRequest } from '../../../Server/Requests';
import { URL_EndPoints } from '../../../Server/URL_EndPoints';
import { useDispatch } from 'react-redux';
import { openChatByChamp } from '../../../../../Redux/champChatDashboardReducer/champsActions';
export default DeleteAreaName(({swal, confirm, cancel ,render,successTitle, FailedTitle,payload,setDeleteArea }:any) => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints()?.updateCleanerArea, payload, null)

            if (response?.data?.status == 200) {
                swal.fire(successTitle)
                dispatch(openChatByChamp({ champId: payload?.champId }, payload?.champId) as any)

            }
            else{
                swal.fire(FailedTitle)
            }
        }
        if (render) {
            swal.fire({
                title: 'Do you want to delete the area name ?',
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    setDeleteArea(null)
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});
