const FEATURES = {
    "customerAssignedAgents": "customerAssignedAgents",
    "adminUsersList": "adminUsersList",
    "weeklyPayoutReport": "weeklyPayoutReport",
    "newWeeklyPayoutReport": "newWeeklyPayoutReport",
    "generateSpecificCleanerWeeklyReport": "generateSpecificCleanerWeeklyReport",
    "jobListNotAtHomeAction": "jobListNotAtHomeAction",
    "faqCreateUpdateAction": "faqCreateUpdateAction",
    "activeCleanerExport": "activeCleanerExport",
    "cronHistory": "cronHistory",
}

const PermissionsAllowList: any = {
    [FEATURES.customerAssignedAgents]: [115, "Admin"],
    [FEATURES.adminUsersList]: ["Admin"],
    [FEATURES.weeklyPayoutReport]: [7, 25, 34, 115, 70, 110],
    [FEATURES.newWeeklyPayoutReport]: [7, 25, 34, 115, 70, 110],
    [FEATURES.generateSpecificCleanerWeeklyReport]: [7],
    [FEATURES.jobListNotAtHomeAction]: [7, 115],
    [FEATURES.faqCreateUpdateAction]: [7, 25, 127, 115, 70, 110],
    [FEATURES.activeCleanerExport]: [7, 25, 115, 70, 107],
    [FEATURES.cronHistory]: [7, 25, 115, 70, 107],
}

const PermissionsBlackList: any = {
    [FEATURES.customerAssignedAgents]: [],
}

const isFeatureAllowed = (featureName: string) => {
    const userId = Number(localStorage.getItem("user") ?? 0);
    const role = localStorage.getItem("role") ?? "";
    if(PermissionsBlackList?.[featureName]?.includes(userId)) {
        return false;
    }
    return PermissionsAllowList?.[featureName]?.includes(userId) || PermissionsAllowList?.[featureName]?.includes(role)
}

export {
    isFeatureAllowed,
    FEATURES,
}