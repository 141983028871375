import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'

// const userTypes = [
//     {
//         id: 'champ',
//         name: 'Champ'
//     },
//     {
//         id: 'customer',
//         name: 'Customer'
//     },
// ];

const getValidData = (data: any) => data?.map((d: any) => ({
    ...d,
    first_name: d?.first_name ?? '',
    last_name: d?.last_name ?? '',
    phone: d?.phone ?? '',
}));

const AssignChampModal = ({ showModal, handleClose, row }: any) => {
    const [loader, setLoader] = useState<any>(false)
    const [userList, setUserList] = useState<any>([]);
    const [defaultUser, setDefaultUser] = useState<any>({});
    const [loadingUser, setLoadingUser] = useState<any>(false);
    const [payload, setPayload] = useState<any>({
        reviewId: row?.id,
        userType: 'champ',
        userId: 0,
    });

    const handleChangeInputHandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event?.value });
    }

    useEffect(() => {
        const loadUsers = async () => {
            setLoadingUser(true);
            const typeApiMap: any = {
                'customer': 'getActiveCustomerlist',
                'champ': 'getActiveChampList',
            }
            const response: any = await getRequest(URL_EndPoints(null)[typeApiMap[payload?.userType] ?? 'getActiveChampList'], setLoadingUser);
            response?.data?.data && setDefaultUser(response?.data?.data[0]);
            response?.data?.data && setUserList(getValidData(response?.data?.data));
            setLoadingUser(false);
        }
        payload?.userType && loadUsers();
    }, [payload?.userType]);

    const handleSelectChamp = async () => {
        setLoader(true);
        if(!payload?.userId) {
            setLoader(false);
            toast.error("Please select valid champ");
            return;
        }
        const response: any = await postRequest(URL_EndPoints(null)?.assignUserToReview, payload, setLoader);
        if(response?.data?.status === 200) {
            toast.success(response?.data?.message);
            handleClose();
        }else {
            toast.info(response?.data?.message);
        }
    }

    return (
        <>
            <Modal size='lg' show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Champ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {loadingUser ? <Spinner animation="border" variant="success" /> : <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChangeInputHandler}
                            HeaderTitle={"Select User Type"}
                            SelectData={userList}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name={"userId"}
                            defaultData={row?.first_name && { label: row?.first_name + " " + row?.last_name, value: row?.id }}
                        />}
                        
                        <div className=' col-12 w-100 d-flex justify-content-end mt-8 me-'>
                            <Button variant="primary btn-sm text-end btn-sm " disabled={loader} onClick={handleSelectChamp}>
                                {
                                    loader ?
                                        <div className='d-flex'>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Selecting...</h4>
                                        </div>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Select</h4>
                                }
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default AssignChampModal
