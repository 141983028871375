import React, { memo } from 'react';
import { withSwal as SweetAlertCloseTimer } from 'react-sweetalert2';
export default SweetAlertCloseTimer((props: any) => {
    const { swal }: any = props;
    React.useEffect(() => {
        if (props?.Re_Render) {
            let timerInterval: any
            swal.fire({
                html: '<h3 >Calling within ( 5 Seconds) <b></b></h3>',
                timer: 5000,
                timerProgressBar: true,
                poisition:"center",
                didOpen: () => {
                    swal.showLoading()
                    const b = swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                        b.textContent = swal.getTimerLeft()
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            }).then((result: any) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === swal.DismissReason.timer) {
                }
            })
        }
    }, [props?.Re_Render])
});
