import axios from 'axios'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { KTCardBody } from '../../../_metronic/helpers'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import VisitorsListMainV2 from './VisitorsListMainV2'
import { setStoreLastStatusListData } from '../../../Redux/actions/cxAction'
import { catchCurrentOpenedUrl } from '../../consts/components/Utility'
const VisitorsListV2= ({ filterDataOutsource }: any) => {
    const dispatch = useDispatch()
    const getData = async () => {
        const response = getRequest(URL_EndPoints(null)?.getMasterReasonForVisitors, null)
        const response2 = getRequest(URL_EndPoints(null)?.getAllSocieties, null)
        const ticketSources = getRequest(URL_EndPoints(null)?.getTicketSources, null)
        const ticketStatuses = getRequest(URL_EndPoints(null)?.getTicketStatuses, null)
        const referByFilter = getRequest(URL_EndPoints(null)?.getReferBySettings, null)
        const adminList = getRequest(URL_EndPoints(null)?.getAdminList, null)
        const supervisorList = getRequest(URL_EndPoints(null)?.getSupervisorList, null)
        const subReasons = getRequest(URL_EndPoints(null)?.getMasterSubReason, null)

        const result: any[] = await Promise.allSettled([
            response,
            response2,
            ticketSources,
            ticketStatuses,
            referByFilter,
            adminList,
            supervisorList,
            subReasons
        ]);

        dispatch(setStoreLastStatusListData({
            keyName: catchCurrentOpenedUrl(),
            data: result[0]?.value?.data?.data
        }) as any)
        dispatch({ type: "ADD_STATUSES", payload: result[0]?.value?.data?.data })
        dispatch({ type: "FETCH_SOCIETIES", payload: result[1]?.value?.data?.data })
        dispatch({ type: "ADD_TICKET_SOURCES", payload: result[2]?.value?.data?.data })
        dispatch({ type: "ADD_TICKET_STATUSES", payload: result[3]?.value?.data?.data })
        dispatch({ type: "ADD_REFER_BY_FILTER", payload: result[4]?.value?.data?.data })
        dispatch({ type: "ADD_ADMIN_LIST", payload: result[5]?.value?.data?.data })
        dispatch({ type: "ADD_SUPERVISOR_LIST", payload: result[6]?.value?.data?.data })
        dispatch({ type: "ADD_SUB_REASONS", payload: result[7]?.value?.data?.data })

    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <KTCardBody className='card py-4'>
            <VisitorsListMainV2 filterDataOutsource={filterDataOutsource}></VisitorsListMainV2>
        </KTCardBody>
    )
}
export default VisitorsListV2
