export const createTicketformData = [
  {
    HeaderTitle: 'Title Here...',
    filterApi: '',
    label: {
      show: false,
      name: 'LabelNameHere',
    },
    valueType: 'textBox',
    inputType: 'text',
    displayFields: [],
    valueField: '',
    name: 'title',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Title is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Description...',
    filterApi: '',
    label: {
      show: false,
      name: 'LabelNameHere',
    },
    valueType: 'textArea',
    inputType: 'textarea',
    displayFields: [],
    valueField: '',
    name: 'answer',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Description is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Choose Date',
    filterApi: '',
    label: {
      show: true,
      name: 'Choose Due Date',
    },
    valueType: 'selectDate',
    inputType: '',
    displayFields: [],
    valueField: '',
    name: 'duedate',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Due Date is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Select Customer',
    filterApi: 'getCustomersList',
    label: {
      show: false,
      name: 'LabelNameHere',
    },
    valueType: 'select',
    inputType: '',
    displayFields: ['first_name', 'last_name', 'phone'],
    valueField: 'id',
    name: 'customerid',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Customer is required !',
    searchable: true,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Select Category',
    filterApi: 'getTicketCategories',
    label: {
      show: false,
      name: 'LabelNameHere',
    },
    valueType: 'select',
    inputType: '',
    displayFields: ['category_name'],
    valueField: 'id',
    name: 'categoryid',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Category is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },

  {
    HeaderTitle: 'Select Sub Category',
    filterApi: 'getTicketSubCategories',
    label: {
      show: false,
      name: 'LabelNameHere',
    },
    valueType: 'multi',
    inputType: '',
    displayFields: ['subcategory_name'],
    valueField: 'id',
    name: 'subcategoryid',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Sub Category is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Select Source',
    filterApi: 'getTicketSources',
    label: {
      show: false,
      name: 'LabelNameHere',
    },
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'source_id',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Source is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  
  {
    HeaderTitle: 'Select Here',
    filterApi: 'staticFormData',
    label: {
      show: true,
      name: 'Show to Customer or Not',
    },
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'visible_id',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Show to customer or not is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Select Admins',
    filterApi: 'getAdminList',
    label: {
      show: true,
      name: 'Assign To Admin / Supervisor / Both',
    },
    valueType: 'multi',
    inputType: '',
    displayFields: ['first_name', "last_name", "phone"],
    valueField: 'id',
    name: 'admins',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Admin is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Select Supervisor',
    filterApi: 'getSupervisorList',
    label: {
      show: false,
      name: 'Assign To Admin / Supervisor / Both',
    },
    valueType: 'multi',
    inputType: '',
    displayFields: ['first_name', "last_name", "phone"],
    valueField: 'id',
    name: 'cleaners',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Supervisor is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  
]

export const chooseToShowCustomerData = [
  {name: "No, Don't show to customer", id: 0},
  {name: "Yes, Don't show to customer", id: '1'},
]
