import React, { FC, useEffect, useState } from 'react'
import SingleSelectSearchDetailsDefaultDyanmic from '../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic'
const ModelTrainingFilteration = ({ ParentData }: any) => {

  return (
    <div className='p-6 w-325px w-md-375px'>
      <h3 className='fw-bold text-dark mb-8'>Search & Filteration</h3>

      <div className='col-12 mb-2' style={{ position: "relative" }}>
        <span className='mb-1'>Training Date</span>
        <div className='input-group mb-3 d-flex justify-content-between gap-2' style={{ height: "40px" }}>
          <input className='form-control' type="date" name="date"
            onChange={(event) => ParentData?.handleChangeInputData({ value: event?.target?.value }, "date")}
          />
        </div>
      </div>

      <div className='col-12 mb-2' key={434343} >
        <SingleSelectSearchDetailsDefaultDyanmic
          handleChangeInputData={ParentData?.handleChangeInputData}
          HeaderTitle='Select User'
          SelectData={ParentData?.adminList || []}
          DynamicKeys={["first_name", "last_name", "phone"]}
          id={"id"}
          name={"userid"}
          targetValue={"value"}
          defaultData={null}
        // defaultData={null}
        ></SingleSelectSearchDetailsDefaultDyanmic>
      </div>

    </div>
  )
}
export { ModelTrainingFilteration }
