import { Dialog } from "@mui/material";
import ImageActionPopup from "./ImageActionPopup";
interface Props {
    attendenceId: number
    handleDiloagboxform: any
    PopupUpdateStatusOpenClose: boolean
}
const DialogBox = ({ attendenceId, handleDiloagboxform, PopupUpdateStatusOpenClose }: Props) => {

    return (
        <>
            {
                PopupUpdateStatusOpenClose &&
                <Dialog
                    open={true}
                    onClose={handleDiloagboxform}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {<ImageActionPopup attendenceId={attendenceId} ></ImageActionPopup>}
                </Dialog>
            }
        </>
    )
}
export default DialogBox
