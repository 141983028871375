const Constants = {
  companyName: 'Carselona Daily',
  token: 'auth-token',
  user: 'user',
  webKit_hash_id: 'webKit_hash_id',
}

export default Constants

export const printScreen = (value: any = "",hint:any="",) => {
  const localKeyCheck = JSON.parse(localStorage.getItem('API') || '0')
  let currentPath = window.location?.pathname
  if (localKeyCheck == false && value || window.location.origin.includes("3011") && localKeyCheck == true && value ) {
     console.log(`✔ ${currentPath} / ${hint  ? hint : value} ---->  ${value}`)
  } 
}



// let screans = window.innerWidth

export const getFrequency = (frequencyId: number) => {
  const freq: any = {
    1: 'Alternate',
    2: 'Weekly',
    3: 'Daily',
    4: 'One Time',
  }

  return freq[frequencyId]
}


export const getSubscriptionType = (subscriptionTypeId: number) => {
  const subscriptionType: any = {
    1: 'New',
    2: 'Renewed',
    3: 'Resumed',
    4: 'Closed',
  }

  return subscriptionType[subscriptionTypeId] || 'Unknown'
}

