import React from 'react'

const SwitchToggleActionButton = ({ title, className, toggleActionHandler, isChecked }: any) => {
    return (
        <div className="form-check form-switch me-2" >
            <input className="form-check-input me-5 mt-3" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={toggleActionHandler} defaultChecked={isChecked} />
            <label className={`form-check-label ${className}`} >{title}</label>
        </div>
    )
}

export default SwitchToggleActionButton