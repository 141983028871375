import React from 'react'
const RightBox = ({ children }: any) => {
    return (
        <div className="d-flex align-items-center justify-content-end">
           <div className='d-flex align-items-center justify-content-center my-2'>
            {children}
           </div>
        </div>
    )
}
export default RightBox