import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { BankAccountInterface } from './Interfaces'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import { postRequest } from '../../../../consts/Server/Requests'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
export const columns: TableColumn<BankAccountInterface>[] = [
  // id:           number;
  // cleaner_id:   number;
  // account_type: string;
  // upi_id:       string;
  // holder_name:  null;
  // account_no:   null;
  // ifsc:         null;
  // status:       number;
  // createdAt:    number;
  // updatedAt:    number;

  {
    name: 'Edit',
    cell: (row: any) => <UpdateUserAccountDetail row={row} />,
    sortable: true,
    id: 'css',
  },
  {
    name: 'Account Holder Name',
    selector: (row: any) => row.holder_name ? row.holder_name : "NA",
    sortable: true,
    id: 'css',
  },
  {
    name: 'Account Type',
    selector: (row: any) => row.account_type ? row.account_type : "NA",
    sortable: true,
    id: 'css',
  },
  {
    name: 'Account Number',
    selector: (row: any) => row.account_no ? row.account_no : "NA",
    sortable: true,
  },
  {
    name: 'IFSC Code',
    selector: (row: any) => row.ifsc ? row.ifsc : "NA",
    sortable: true,
  },
  {
    name: 'UPI ID',
    selector: (row: any) => row.upi_id ? row.upi_id : "NA",
    sortable: true,
  },

]




const UpdateUserAccountDetail = ({ row }: any) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState<boolean>(false)
  const [name, setName] = useState(row?.holder_name ?? "NA")
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setName(row?.holder_name)
  }, [row?.holder_name])

  const updateUserAccountDetails = async () => {
    setLoader(true)
    const result = await postRequest(URL_EndPoints()?.updateCleanerBankDetails, {
      holder_name: name,
      accountId: row?.id,
    }, setLoader)

    if (result?.data?.msg == 200) {
      dispatch({ type: "REFRESH-BANK", payload: Math.random() * 1000 })
      setShow(false)
      toast.success(result.data?.msg)

    } else {
      toast.error(result?.data.msg)
      setShow(false)
    }

  }


  return (
    <>


      <FontAwesomeIcon className='text-danger fs-4' icon={faEdit} onClick={() => setShow(true)} />

      <Modal show={show} onHide={() => setShow(false)} style={{ borderColor: "black" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Acount Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <label  className='fw-bold'>Account Holder Name</label>
            <input
              type="text"
              placeholder='Search Here'
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              className='w-100 form-control me-2 mt-2 mb-4 align-start' />

          </div>

          <button className={`btn btn-sm btn-${loader ? "success" : "primary"}`} onClick={updateUserAccountDetails} disabled={loader}>{loader ? "Updating..." : "Update"}</button>
        </Modal.Body>
      </Modal >

    </>
  )
}

