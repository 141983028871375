import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getRequest, postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { vehicleTypes } from '../../CommonData';
import { useSelector } from 'react-redux';
import SingleSelectSearchCategoryDefault from '../../Select/SingleSelectSearchCategoryDefault';
import { adminToastRunner } from '../../../modules/admins/adminUtils';

const TrainingAction = ({ cleanerid }: any) => {
    const [trainingLocation, setTrainingLocation] = useState<any>([])
    const [prevetrainingLocation, setPreveTrainingLocation] = useState<any>({})
    const [prevVehicleData, setPrevVehicleData] = useState<any>({})

    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const champsHistoryData = useSelector((store: any) => store.champsReducer.champsHistoryData[cleanerid])
    let champsDetails = champsHistoryData?.ChampDetail


    const [payload, setPayload] = useState<any>({
        champId: cleanerid,
        location_id: champsDetails?.location_id,
        own_vehicle: champsDetails?.own_vehicle,
        address: champsDetails?.address || "",
    })


    useEffect(() => {

        const locationArea = champsDetails?.id && trainingLocation?.find((ele: any) => ele?.id == champsDetails?.location_id)
        const ownVehicle = champsDetails?.id && vehicleTypes?.find((ele: any) => ele?.id == champsDetails?.own_vehicle)


        let newData = {
            label: locationArea?.locationname,
            value: locationArea?.id
        }
        let ownVehicleData = {
            label: ownVehicle?.name,
            value: ownVehicle?.id
        }


        newData?.label && setPreveTrainingLocation(newData)
        ownVehicleData?.label && setPrevVehicleData(ownVehicleData)

        // setPayload({ ...payload, ["location_id"]: locationArea?.id })
        // champsDetails?.champsDetails &&  setPayload({ ...payload, ["own_vehicle"]: champsDetails?.own_vehicle })

        // trainingLocationPreData?.address && setPayload({ ...payload, ["address"]: trainingLocationPreData?.address })

    }, [champsDetails?.id, trainingLocation])


    const getData = async () => {
        const trainingLocations = await getRequest(URL_EndPoints(null)?.getAllTrainingLocations, null)
        setTrainingLocation(trainingLocations?.data?.data)
    }


    useEffect(() => {
        getData()
    }, [])

    const handleChangeInputData = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
    }

    const updateHandler = async () => {
        if (payload?.champId) {
            setLoader(true)
            const result = await postRequest(URL_EndPoints()?.updateChampData, payload, setLoader)
            adminToastRunner(result, "message")
            setLoader(false)
        }
        else {
            toast.error("some field are  missing ")
        }
    }

    return (
        <div className='card p-8 w-75 mt-12' style={{ margin: "auto" }}>
            <div className="col-12  mb-3">
                <h5>Training Location </h5>

                <div key={prevetrainingLocation?.value}>
                    <SingleSelectSearchCategoryDefault
                        handleChangeInputData={handleChangeInputData}
                        HeaderTitle="Select Training"
                        DynamicKey={"locationname"}
                        id={"id"}
                        name="location_id"
                        SelectData={trainingLocation.map((ele: any) => {
                            let concatedLocationName = { ...ele }
                            concatedLocationName.locationname = concatedLocationName.locationname + ", " + ele?.areaDetails?.areaname
                            return concatedLocationName
                        })}
                        defaultData={prevetrainingLocation?.label ? prevetrainingLocation : null}
                    ></SingleSelectSearchCategoryDefault>
                </div>
            </div>


            <div className="col-12  mb-3" key={3}>
                <div key={prevetrainingLocation?.value}>
                    <SingleSelectSearchCategoryDefault
                        handleChangeInputData={handleChangeInputData}
                        HeaderTitle="Select vehicle type"
                        DynamicKey={"name"}
                        id={"id"}
                        name="own_vehicle"
                        SelectData={vehicleTypes}
                        defaultData={prevVehicleData?.label ? prevVehicleData : null}

                    ></SingleSelectSearchCategoryDefault>
                </div>
            </div>

            <div className="col-12  mb-3">
                <h5>Address </h5>
                <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control  col-12 me-3 '
                    style={{ height: "38px" }}
                    placeholder='Location freeform'
                    name="address"
                    value={payload?.address}
                    onChange={(event: any) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                />
            </div>

            <button className="col-12 border border-none  mb-3 bg-primary p-2 py-3 rounded text-center mt-8 cursor-pointer"
                onClick={() => updateHandler()}
                disabled={disable || loader ? true : false}
            >
                {
                    loader ?
                        <>
                            <div>
                                <span className='fw-bold text-white fs-5'>
                                    <i className="fa-solid fa-database text-white fs-2 me-4"></i>
                                    Applying
                                    <span className="spinner-grow spinner-grow-sm  text-white me-2 ms-2" role="status" aria-hidden="true"></span>
                                    <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                    <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                </span>
                            </div>
                        </>
                        : <span className='fw-bold text-white fs-5 me-3'>
                            <i className="bi bi-save2 text-white fs-4 me-2"> </i>
                            Apply now
                        </span>
                }

            </button>
        </div>


    )
}
export default TrainingAction
