import { isAllowedChampNumber } from "../../components/Utility";


const HoverToolTips = (props:any) => {


    return (
        <>
         <div className='' key={1}>
            <span className={props?.classeTitle}   onMouseOver={() => props?.handlChange(props?.uniqueId)} onMouseOut={() => props?.handlChange(null)}  >{props?.title}</span>
            <span  className={`${props?.uniqueId == props?.ShowDataRefs && props?.toggle ? "visible" : "invisible"} ${props?.classeShowData}`}>{isAllowedChampNumber(props?.showData , props?.uniqueId)}</span>
        </div>
        </>
    )
}

export default HoverToolTips
