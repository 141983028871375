import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminToastRunner } from '../../admins/adminUtils';
import MediaUploadFile from '../MediaUploadFile';
import MediaPreview from '../MediaPreview';

const AddUpdateVendorInvoice = ({ show, refreshNow, setShow, setData, initialValue, setInitialValue, apiUrl }: any) => {
    const [loading, setLoading] = useState(false);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [file, setFile] = useState<any>(null);

    useEffect(() => {
        setFile(initialValue?.media)
    }, [initialValue?.media]);
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [purchaseOrderList] = await Promise.all([
                    getRequest(URL_EndPoints()?.getPurchaseOrderListing, null),
                ]);
                setPurchaseOrderList(purchaseOrderList?.data?.data);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchData();
        }
    }, [show]);

    const handleInputChange = (e: any) => {
        setInitialValue({
            ...initialValue,
            [e.target.name]: e.target.value,
        });
    };
    
    const [fileSelected, setFileSelected] = useState<boolean>(false);
    // const [file, setFile] = useState<any>(null);

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        if (typeof (file) == "string" || !file) {
            delete initialValue.media
        }
        else {
            initialValue.media = file
        }
        const response = await postRequestWithFile(URL_EndPoints()?.[apiUrl], initialValue, setLoading)
        adminToastRunner(response)
        if (response?.data?.data?.items) {
            setData(response?.data?.data?.items);
        }

        refreshNow && refreshNow();
        setShow && setShow(false);
    };


    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{initialValue?.id ? "Edit" : "Add"} Vendor Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="purchase_order_id">
                                <Form.Label><strong>Select Purchase Order</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="purchase_order_id"
                                    value={initialValue["purchase_order_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Purchase Order</option>
                                    {purchaseOrderList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.purchase_order_id ? item.purchase_order_id : `${(item.purchase_order_id)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>


                            <Form.Group as={Col} className="mb-3" controlId="invoice_date">
                                <Form.Label><strong>Invoice Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Invoice Date"
                                    name="invoice_date"
                                    value={initialValue['invoice_date']}
                                    onChange={handleInputChange}
                                    required
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="invoice_number">
                                <Form.Label><strong>Invoice Number</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Invoice Number"  
                                    name="invoice_number"
                                    value={initialValue['invoice_number']}
                                    onChange={handleInputChange}
                                    required
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">


                            <Form.Group as={Col} className="mb-3" controlId="invoice_amount">
                                <Form.Label><strong>Invoice Amount</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Invoice Amount"
                                    name="invoice_amount"
                                    value={initialValue['invoice_amount']}
                                    onChange={handleInputChange}
                                    required
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="notes">
                                <Form.Label><strong>Notes</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Notes"
                                    name="notes"
                                    value={initialValue['notes']}
                                    onChange={handleInputChange}
                                    required
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">
                            <MediaUploadFile setFileSelected={setFileSelected} setFile={setFile} file={file} fileSelected={fileSelected} setPayload={setInitialValue} payload={initialValue} />
                            <MediaPreview fileSelected={fileSelected} file={file} CancelImage={CancelImage} defaultUrl={initialValue?.media} />
                        </Row>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddUpdateVendorInvoice;
