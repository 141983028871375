import { Dialog } from "@mui/material";
// props =========================
// handleCloseForm, PopUpPostFormOpen, Components, ParentData,selectedId,payloads, Endpoint
// props =========================

// ChatGlobal
const DialogBoxGlobal = ({ handleCloseForm, PopUpPostFormOpen, Components, ParentData, row, selectedId, payloads, Endpoint, showAssignPopUp }: any) => {
    return (
        <>
            {
                PopUpPostFormOpen &&
                <Dialog
                    open={true}
                    onClose={handleCloseForm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Components handleCloseForm={handleCloseForm} ParentData={ParentData} row={row} selectedId={selectedId} PopUpPostFormOpen={PopUpPostFormOpen} payloads={payloads} Endpoint={Endpoint} showAssignPopUp={showAssignPopUp} ></Components>
                </Dialog>
            }
        </>
    )
}
export default DialogBoxGlobal
