import React from 'react'
const Not_Found_Data = ({ headerTitle, size }: any) => {
    return (
        <div className='d-flex align-items-center justify-content-center h-75 flex-column'>
            <img className={`w-${size ? size : 25} mb-2`} src="https://i.pinimg.com/originals/a5/98/d4/a598d4fc4118f5ea0cb49e2c9a34524d.gif" alt="" />
            {/* <h4 className='fw-bold text-primary'>  {headerTitle}</h4> */}
            <span className='fs-5 me-6 text-muted font-italic text-center'>
                <br />
                <i className="bi bi-bug-fill fs-2 text-muted me-4 "></i>
                <br />
                &#x261d; {headerTitle}
            </span>
        </div>
    )
}
export default Not_Found_Data