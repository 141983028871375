import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

interface Product {
    purchaseOrderInventoryItems: {
    title: string;
  };
  total_price: number;
  quantity_ordered: number;
}

interface ProductModalProps {
  show: boolean;
  productData: Product[];
  handleClose: () => void;
  modalLoader: boolean
}

const ViewProductModal: React.FC<ProductModalProps> = ({ show, productData, handleClose, modalLoader }) => {
  const columns = [
    {
      name: 'Item Name',
      selector: (row: Product) => row.purchaseOrderInventoryItems.title,
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row: Product) => row.quantity_ordered,
      sortable: true,
    },
    {
        name: 'Price',
        selector: (row: Product) => row.total_price,
        sortable: true,
    }
  ];

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Item Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalLoader ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : productData && productData.length > 0 ? (
          <DataTable
            columns={columns}
            data={productData}
            pagination
            highlightOnHover
            striped
          />
        ) : (
          <p>No items available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewProductModal;
