
const initState = {
    job_list_refresh: null,


}
export const JobListRefreshData = (state = initState, { type, payload }: any) => {
  
    switch (type) {
        case "JOB_LIST_REFRESH":
            return { ...state, job_list_refresh: payload }
      
    
        default:
            return state
    }
}



// const job_list_refresh = useSelector((store:any)=>store.JobListRefreshData.job_list_refresh)