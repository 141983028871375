import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
const InactiveStatusUpdate = ({ FilteredData, setStoreRowP2p = null, row }: any) => {
    const inactiveReasonData = useSelector((store: any) => store?.ReasonsListDataReducer?.Inactive_reason)
    const [leavveReasonList, setleavveReasonList] = React.useState<any>([]);
    const [leaveReasonsId, setleaveReasonsId] = React.useState<any>(null);
    const [leaveReasonDefaultData, setleaveReasonDefaultData] = React.useState<any>({});
    const [inactiveReasonListActive, setInactiveReasonListActive] = React.useState<any>([]);
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    useEffect(() => {
        setleavveReasonList(inactiveReasonData)
    }, [inactiveReasonData])
    const handleChange = (event: any, name: any) => {
        if (event === null) {
            setleaveReasonsId(null)
        }
        else {
            setleaveReasonsId(event.value)
        }
    }
    const handleInactiveReason = async () => {
        if (leaveReasonsId) {
            setLoader(true)
            let payloads = { cleanerid: FilteredData?.selectedId?.cleaner_id, date: FilteredData?.selectedId?.attendance_date, inactive_reason_id: +leaveReasonsId }
            const response = await postRequest(URL_EndPoints(null)?.updateCleanerReasonStatus, payloads, setLoader)
            if (response?.data?.message) {
                toast.success(response?.data?.message)
                if (setStoreRowP2p) {
                    if (row?.attendance?.length > 0) {
                        row.attendance[0].inactive_reason_id = +leaveReasonsId
                        setDisable(200)
                    }
                    setStoreRowP2p(row)
                }
                FilteredData?.handleCloseForm()

            }
            else{
                toast.success("Something is wrong")

                FilteredData?.handleCloseForm()

            }
        } else {
            toast.error("Reason id missing")
            FilteredData?.handleCloseForm()

        }
    }
    useEffect(() => {
        const updated = leavveReasonList?.filter((ele: any) => ele?.id === FilteredData?.selectedId?.inactive_reason_id)
        const inactiveReasonListUpdate = inactiveReasonData?.filter((ele: any) => ele?.status === 1)
        setInactiveReasonListActive(inactiveReasonListUpdate)
        FilteredData?.selectedId?.inactive_reason_id && setleaveReasonsId(FilteredData?.selectedId?.inactive_reason_id)
        FilteredData?.selectedId?.inactive_reason_id && setleaveReasonDefaultData(updated?.length > 0 ? updated[0] : "")
    }, [leavveReasonList, FilteredData?.selectedId?.inactive_reason_id])
    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select  Reason"}
                            SelectData={inactiveReasonListActive}
                            DynamicKey={"name"}
                            id={"id"}
                            name={"name"}
                            defaultData={{ label: leaveReasonDefaultData?.name, value: leaveReasonDefaultData?.id }}
                        />
                    </div>
                    <div className='d-flex  align-items-center justify-content-end '>
                        {/* <a className="btn btn-success mt-4" onClick={() => handleInactiveReason()}>
                            {'Save'}
                        </a> */}




                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={disable || loader ? true : false} onClick={() => handleInactiveReason()}>
                         

                         {
                         loader ?
                             <>
                                 <div className='spinner-border mr-15' role='status'></div>
                                 <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                             </>
                             : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                     }
                     </button>






                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default InactiveStatusUpdate
