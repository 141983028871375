import React from 'react'
import SingleSelectSearchCategory from '../../consts/Select/SingleSelectSearchCategory'
import { AdminMultiSelectDefault } from '../../consts/Select/AdminMultiSelectDefault'
import SingleSelectSearchDetails from '../../consts/Select/SingleSelectSearchDetails'
import { filterDataManuplation } from '../../consts/components/Utility'
const NewTicketDashboardFiltration = ({ ParentData }: any) => {

  return (
    <div className='p-6 w-325px w-md-375px'>
      <h3 className='fw-bold text-dark mb-7'>Search & Filteration</h3>

      <div className='col-12 mb-2' style={{ position: "relative" }}>
        <div className='input-group mb-3 d-flex justify-content-between gap-2' style={{ height: "40px" }}>
          <input className='form-control' type="date" name="startDate" id="startDate"
            autoComplete='off'
            onChange={(event) => ParentData?.handleChangeInputData(event.target, "startDate")}
            value={ParentData?.payloads.startDate}
          />
          <input className='form-control' type="date" name="endDate" id="endDate"
            autoComplete='off'
            onChange={(event) => ParentData?.handleChangeInputData(event.target, "endDate")}
            value={ParentData?.payloads.endDate}
          />
        </div>
      </div>

      <div className="mb-2 d-flex ">
        <select
          name='date_typer'
          aria-label='Select a Timezone'
          data-control='select2'
          data-placeholder='Period'
          className='form-select form-select-sm '
          style={{ height: "38px" }}
          onChange={(event) => ParentData?.handleChangeInputData(event.target, "dateKey")}
        >
          < option value={0}> Select Date Type </option>
          {
            ParentData?.ticketStates?.DateFilter?.map((ele: any) => {
              return (
                <>
                  < option value={ele}>{ele}</option>
                </>
              )
            })
          }
        </select>
      </div>

      <div className="mb-2 col-12" key={"ewew2"}>
        <SingleSelectSearchDetails
          handleChangeInputData={ParentData?.handleChangeInputData}
          HeaderTitle="Select Supervisor"
          SelectData={ParentData?.ticketStates?.supervisorListData}
          DynamicKey={"first_name"}
          DynamicKey2={"last_name"}
          DynamicKey3={"phone"}
          id={"id"}
          name="areaManagerId"
        />
      </div>

      <div className="mb-2 col-12" key={"3dfdfd"}>
        <SingleSelectSearchDetails
          handleChangeInputData={ParentData?.handleChangeInputData}
          HeaderTitle="Select Admin"
          SelectData={ParentData?.ticketStates?.adminListData}
          DynamicKey={"first_name"}
          DynamicKey2={"last_name"}
          DynamicKey3={"phone"}
          id={"id"}
          name="adminUserId"
        />
      </div>

      <div className="mb-2 col-12" key={"3dfdfd34"}>
        <SingleSelectSearchDetails
          handleChangeInputData={ParentData?.handleChangeInputData}
          HeaderTitle="Select POC"
          SelectData={ParentData?.ticketStates?.adminListData}
          DynamicKey={"first_name"}
          DynamicKey2={"last_name"}
          DynamicKey3={"phone"}
          id={"id"}
          name="agent_id"
        />
      </div>

      <div className="mb-2 col-12" key={"434343"}>

        <AdminMultiSelectDefault
          key="newTicketDashboardStatusId_1"
          options={filterDataManuplation(ParentData?.ticketStates?.statusListData, ["name"])}
          onChange={ParentData?.handleChangeInputData}
          setSeacrch={null}
          isLoading={false}
          defaultValue={null}
          isSelectAll={true}
          menuPlacement={"bottom"}
          name="statusId"
          title="Select Status"
        />
      </div>
      <div className="mb-2 col-12" key={43431123}>
        <AdminMultiSelectDefault
          key="newTicketDashboardCampaignId_2"
          options={filterDataManuplation(ParentData?.ticketStates?.campaignListData, ["name"])}
          onChange={ParentData?.handleChangeInputData}
          setSeacrch={null}
          isLoading={false}
          defaultValue={null}
          isSelectAll={true}
          menuPlacement={"bottom"}
          name="campaignId"
          title="Select Campaign"
        />
      </div>

      <div className="mb-2 col-12" key={43432432}>
        <SingleSelectSearchCategory
          handleChangeInputData={ParentData?.handleChangeInputData}
          HeaderTitle='Select Category'
          SelectData={ParentData?.ticketStates?.categoryListData}
          DynamicKey={'category_name'}
          id={'id'}
          name='categoryId'
        ></SingleSelectSearchCategory>
      </div>

   
      <div className="mb-2 d-flex ">
        <select
          name='date_typer'
          aria-label='Select a Timezone'
          data-control='select2'
          data-placeholder='Period'
          className='form-select form-select-sm '
          style={{ height: "38px" }}
          onChange={(event) => ParentData?.handleChangeInputData(event.target, "is_visible")}
        >
          < option value=""> Select Visibility </option>
          {
            [{ name: "Public", id: 1 }, { name: "Private", id: 0 }]?.map((ele: any) => {
              return (
                <>
                  < option value={ele?.id}>{ele?.name}</option>
                </>
              )
            })
          }
        </select>
      </div>
      <div className="mb-2 d-flex ">
        <select
          name='date_typer'
          aria-label='Select a Timezone'
          data-control='select2'
          data-placeholder='Period'
          className='form-select form-select-sm '
          style={{ height: "38px" }}
          onChange={(event) => ParentData?.handleChangeInputData(event.target, "tags")}
        >
          < option value={""}> Select Tag </option>
          {
            [{ name: "Review", id: "Review" }, { name: "Follow Up", id: "Follow Up" }]?.map((ele: any) => {
              return (
                <>
                  < option value={ele?.id}>{ele?.name}</option>
                </>
              )
            })
          }
        </select>
      </div>



    </div>
  )
}
export { NewTicketDashboardFiltration }
