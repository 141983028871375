import moment from 'moment';


export const GetRazorpaySubscriptionOrderColumns: any = () => [
    // {
    //     name: 'Action',
    //     cell: (row: any) => (
    //         <DeleteOffer row={row} />
    //     ),
    //     sortable: true,
    //     id: "css"
    // },
    {
        name: <div>SUBSCRIPTION ID</div>,
        selector: (row: any) => row.receipt,
        sortable: true,
    },

    {
        name: <div>RAZORPAY ORDER ID</div>,
        selector: (row: any) => row.id,
        sortable: true,
        maxwidth: "100px"
    },


    {
        name: <div>AMOUNT<br />PAID</div>,
        selector: (row: any) => row.amount_paid / 100,
        sortable: true,
    },


    {
        name: <div>STATUS</div>,
        selector: (row: any) => row.status,
        sortable: true,
    },




    {
        name: <div>ATTTEMPTS</div>,
        selector: (row: any) => row.attempts,
        sortable: true,
    },

    {
        name: <div>CREATED AT</div>,
        selector: (row: any) => moment(new Date(row?.created_at * 1000)).format('MMMM YYYY HH:mm:ss'),
        sortable: true,
    },


];
