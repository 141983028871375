import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import SingleSelectSearchCategoryDefault from '../../consts/Select/SingleSelectSearchCategoryDefault'
import IsLoading from './../../consts/IsLoading/IsLoading';
const UpdateTrainingLocation = ({ setShow, show, row, setRowData = null }: any) => {

    const [loader, setLoader] = useState(false)
    const [listData, setListData] = useState([])
    const [defaultData, setDefaultData] = useState<any>(null)
    const [loaderDefault, setLoaderDefault] = useState(false)
    const [payload, setPayload] = useState({
        taskId: row?.id,
        location_id: null
    })


    useEffect(() => {
        setLoaderDefault(true)
        const defaultValue: any = listData?.find((ele: any) => ele?.id == row?.location_id)
        defaultValue && setDefaultData({ label: defaultValue?.locationname, id: defaultValue?.id })
        setTimeout(() => {
            setLoaderDefault(false)
        }, 1000);

    }, [listData, row?.location_id])

    const handleChnageOnSource = async (event: any, name: string) => {
        setPayload({ ...payload, [name]: event ? +event.value : event })
    }

    const updateHandler = async () => {
        if (payload?.taskId && payload?.location_id) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.updateTaskData, payload, setLoader)
            if (response?.data?.status == 200) {
                if (setRowData) {
                    const defaultValues: any = listData?.find((ele: any) => ele?.id == payload?.location_id)
                    row.taskTrainingLocation = {}
                    row.taskTrainingLocation.locationname =  defaultValues?.locationname
                }
                toast.success(response?.data?.message, { position: "top-center" })
                setShow(false)
                setLoader(false)
            } else {
                toast.error(response?.data?.message, { position: "top-center" })
                setLoader(false)
                setShow(false)

            }
        }
        else {
            toast.error("Something is missing", { position: "top-center" })
            setLoader(false)
            setShow(false)
        }
    }

    const initialData = async () => {
        const listData = await getRequest(URL_EndPoints()?.getAllTrainingLocations, null)
        setListData(listData?.data?.data)
    }

    useEffect(() => {
        initialData()
    }, [])



    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Training Location </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-8`}>
                        {
                            loaderDefault ? <IsLoading /> : <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChnageOnSource}
                                HeaderTitle={"Select Training Location"}
                                SelectData={listData}
                                DynamicKey={"locationname"}
                                id={"id"}
                                name={"location_id"}
                                defaultData={defaultData ? defaultData : null}
                            />
                        }
                    </div>


                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 p-1 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => updateHandler()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h6 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating...</h6>
                                    </>
                                    : <h6 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Training Location</h6>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default UpdateTrainingLocation
