import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getPostMultipleApiHandler, postRequest } from '../../../consts/Server/Requests'
import { champLeadBatchApi } from '../../VisitorListChamp/champLeadsUtil'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'

const BulkAssignBatchDeactivatedForm = ({ showAssignPopUp, setShowAssignPopUp, filteredSelectedRows, toggledClearRows, setToggleClearRows, handleRefresh }: any) => {
    const [isBulkBatchAssignLoading, setIsBulkBatchAssignLoading] = useState<any>(false)
    const [batches, setBatches] = useState([]);

    const [loader, setLoader] = React.useState<any>(false)
    const [batchAssignPayload, setBatchAssignPayload] = useState<any>({
        ids: [],
        type: 'champ',
        batch_id: 0,
      });

    async function getFetchInitialData() {
        setLoader(true)
        const results = await getPostMultipleApiHandler(champLeadBatchApi, setLoader)
        setBatches(results["getBatches"] || [])
    }

    useEffect(() => {
        showAssignPopUp && getFetchInitialData()
    }, [showAssignPopUp])

    const handleBatchAssignPayloadUpdate = (event: any, name: any) => {
        setBatchAssignPayload({ ...batchAssignPayload, [name]: event });
      }
    const handleBatchAssignSubmit = async (e: any) => {
        e.preventDefault();
        let ids = filteredSelectedRows?.map((row: any) => row?.id);
        batchAssignPayload['ids'] = ids.filter((id: number) => id);
        setIsBulkBatchAssignLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.assignBatch, batchAssignPayload, setIsBulkBatchAssignLoading)
        setToggleClearRows(!toggledClearRows)
        handleRefresh()
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
        setShowAssignPopUp(false)

    }

    return (
        <>

            <Modal size='lg' show={showAssignPopUp} onHide={() => setShowAssignPopUp(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Assign batch to {filteredSelectedRows.length} Leads</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleBatchAssignSubmit}>
                        <Form.Group className="mb-3" >
                            <Form.Select aria-label="Default select example" value={batchAssignPayload.batch_id} required name='batch_id' onChange={(e: any) => handleBatchAssignPayloadUpdate(e.target.value, 'batch_id')}>
                                <option value="0">Select Batches</option>
                                {batches?.map((batch: any) => <option value={batch.id}>{batch.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 p-2" >
                            {
                                isBulkBatchAssignLoading ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary">
                                        Bulk Assign
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default BulkAssignBatchDeactivatedForm
