
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import moment from "moment"

export const DateFormate = ({ date = "", from = "" }: any) => {
  const toggleColumn = useSelector((store: any) => store.RefreshPage.toggleColumn)
  const [toggleColumns, setToggleColumns] = useState<any>({})

  useEffect(() => {
    const toggleColumns: any = JSON.parse(localStorage.getItem("toggleColumns") || "{}") || {}
    setToggleColumns(toggleColumns || {})
  }, [toggleColumn])
  
  const momentObj = moment.tz(date, 'Asia/Kolkata')

  return (<>
    {/* {date ? moment(new Date(date)).format(toggleColumns[from] ? 'DD-MM-YYYY hh:mm:ss A' : "DD-MM-YYYY") : "NA"} */}
    {(!date) ? 'NA' : momentObj ? momentObj.format(toggleColumns[from] ? 'DD-MM-YYYY hh:mm:ss A' : 'DD-MM-YYYY') : 'NA'}
  </>
  )
}




