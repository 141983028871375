import React, { useState, useEffect, useReducer, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes, toastNotifier } from '../../consts/components/Utility';
import useOwnMoinState from './useOwnMoinState';
import { customerMissedCallReducer, customer_missed_call_states } from '../admins/ad_states/customerMissedCallStates';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import SingleSelectSearchCategoryDefault from '../../consts/Select/SingleSelectSearchCategoryDefault';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils';

const CustomerMissedCallList = ({ filterDataOutsource }: any) => {
  const dispatch = useDispatch()
  const [storeFilterList, setStoreFilterList] = useOwnMoinState({})
  const [filterkey, setFilterKey] = useOwnMoinState({})
  const [selectedFilterData, setSelectedFilterData] = useOwnMoinState([])
  const [payload, setPayload] = useOwnMoinState(initialPayload(false, {}))
  const [sortedColumns, setSortedColumns] = useOwnMoinState(columns(handleFetchAllCallLogs));
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [customerMissedCallStates, setLocalStateDispatch] = useReducer(customerMissedCallReducer, customer_missed_call_states);

  useEffect(() => {
    if (customerMissedCallStates?.filterData) {
      const newColumns = columns(handleFetchAllCallLogs, customerMissedCallStates?.filterData?.map((ele: any) => ele?.id))
      setSortedColumns(newColumns);
    }
  }, [customerMissedCallStates?.filterData]);

  async function InvokedRendered(payloads: any, setloading: any) {
    setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
    const { data } = await postRequest(URL_EndPoints(null)?.getCustomerCallStatusList, payloads, null)
    setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: data?.data })
    setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: data?.recordsTotal })
    setLocalStateDispatch({ type: "RTL_FILTER", payload: data?.filter?.CustomerCallStatusList })
    setLocalStateDispatch({ type: "RTL_PENDING", payload: false })
    const admins = await getRequest(URL_EndPoints(null)?.getAdminList, null);
    dispatch({ type: "ADD_ADMIN_LIST", payload: admins?.data?.data })
    setLocalStateDispatch({ type: "UPDATE_ADMIN_LIST", payload: admins?.data?.data })

  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: 10 })
    await InvokedRendered(handlePagination(customerMissedCallStates?.search, customerMissedCallStates?.sortby, customerMissedCallStates?.direction, customerMissedCallStates?.page, customerMissedCallStates?.perPageSize), null)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setLocalStateDispatch({ type: "RTL_SORT_BY", payload: column.sortField })
    setLocalStateDispatch({ type: "RTL_DIRECTION", payload: sortDirection })
    InvokedRendered(handlePagination(customerMissedCallStates?.search, column.sortField, sortDirection, customerMissedCallStates?.page, customerMissedCallStates?.perPageSize), null)
  };

  const handlePageChange = (page: any) => {
    setLocalStateDispatch({ type: "RTL_PAGE", payload: page })
    InvokedRendered(handlePagination(customerMissedCallStates?.search, customerMissedCallStates?.sortby, customerMissedCallStates?.direction, page, customerMissedCallStates?.perPageSize), null)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setLocalStateDispatch({ type: "RTL_PER_PAGE_SIZE", payload: newPerPageSize })
  };

  useEffect(() => {

    let timer = setTimeout(async function () {
      customerMissedCallStates?.search && setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
      customerMissedCallStates?.search && InvokedRendered(handlePagination(customerMissedCallStates?.search && customerMissedCallStates?.search, customerMissedCallStates?.sortby, customerMissedCallStates?.direction, customerMissedCallStates?.page, customerMissedCallStates?.perPageSize), null)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [customerMissedCallStates?.search])

  useEffect(() => {
    let { isFilter, ...rest } = filterDataOutsource?.ticketListOpenFilterData?.customerMissedCallFilter || {}
    !customerMissedCallStates?.search && !isFilter && InvokedRendered(handlePagination(customerMissedCallStates?.search, customerMissedCallStates?.sortby, customerMissedCallStates?.direction, customerMissedCallStates?.page, customerMissedCallStates?.perPageSize), null)
    isFilter && InvokedRendered({ ...payload, filterData: rest }, null)
    isFilter && setPayload({ ...payload, ["filterData"]: rest })

  }, [
    customerMissedCallStates?.search,
    filterDataOutsource?.ticketListOpenFilterData?.customerMissedCallFilter,
    filterDataOutsource?.ticketListOpenFilterData?.customerMissedCallFilter?.isFilter
  ])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(customerMissedCallStates?.search, customerMissedCallStates?.sortby, customerMissedCallStates?.direction, 1, customerMissedCallStates?.perPageSize), null);
  }, [selectedFilterData?.length]);

  function handleFetchAllCallLogs() {
    setLocalStateDispatch({ type: "RTL_FETCH_CALL_LOG_TOGGLER", payload: Math.random() })
  }

  useEffect(() => {
    customerMissedCallStates?.fetchCallToggler && setLocalStateDispatch({ type: "RTL_TEMP_FILTERED_DATA", payload: customerMissedCallStates?.filterData })
  }, [customerMissedCallStates?.fetchCallToggler])

  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setLocalStateDispatch({ type: "RTL_PENDING", payload: true });
      const clone = customerMissedCallStates?.filterDataTemp?.slice();
      const customerIds = clone.map((e: any) => e.customer_id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, null);

      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e?.vehicleCustomer?.phone || ele[0]?.To === e?.vehicleCustomer?.phone) || [];
        clone[i].callLogs = foundLog;
      }

      setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: clone })
      setLocalStateDispatch({ type: "RTL_PENDING", payload: false });

    }
    customerMissedCallStates?.filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [customerMissedCallStates?.filterDataTemp])

  const handleSelectedRowsChange = (selectedRows: any) => {
    setLocalStateDispatch({ type: "RTL_FILTERED_SELECTED_ROWS", payload: selectedRows?.selectedRows })
  };

  const handleBulkAgentUpdate = async (e: any) => {
    e.preventDefault();
    setLocalStateDispatch({ type: "UPDATE_BULK_ASSIGN_LOADING", payload: true })
    const res: any = await postRequest(URL_EndPoints()?.bulkUpdateCallbackAgent, {
      customerIds: customerMissedCallStates.filteredSelectedRows?.map((row: any) => row?.id),
      agentId: customerMissedCallStates.selectedAdminId,
    }, null);
    toast?.[res?.data?.status === 200 ? 'success' : 'info'](res.data.message)
    setLocalStateDispatch({ type: "UPDATE_BULK_ASSIGN_LOADING", payload: false })
    setLocalStateDispatch({ type: "UPDATE_SHOW_BULK_ASSIGN_AGENT", payload: false })
    setToggleClearRows(!toggledClearRows)
    setLocalStateDispatch({ type: "RTL_FILTERED_SELECTED_ROWS", payload: [] })



  }

  const handleBulkAgentUpdateModalClose = () => {
    setLocalStateDispatch({ type: "UPDATE_SHOW_BULK_ASSIGN_AGENT", payload: false })
    setLocalStateDispatch({ type: "UPDATE_BULK_ASSIGN_LOADING", payload: false })
  }

  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])
  
  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getCustomerCallStatusList")
  }, [])
  return (
    <>
      <div>

        <DataTable
          title="Customer Missed Call Lists"
          selectableRows
          progressPending={customerMissedCallStates?.pending}
          columns={reorderColumns(sortedColumns, "customerMissedCallListTaskReorderedItem")}
          data={customerMissedCallStates?.filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "customerMissedCallListTaskReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          pagination
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={customerMissedCallStates?.totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          clearSelectedRows={toggledClearRows}
          subHeaderComponent={
            <>
              <div className='w-100 d-flex justify-content-between mb-3 '>
                <div className='d-flex align-items-start justify-content-start '>
                  <input
                    type="text"
                    placeholder='Search Here'
                    value={customerMissedCallStates?.search}
                    onChange={(e: any) => setLocalStateDispatch({ type: "RTL_SEARCH", payload: e.target.value })}
                    className='w-100 form-control align-start'
                  />
                </div>
                <div className='d-flex align-items-center justify-content-end '>
                  <div className='d-flex align-items-center justify-content-center my-2'>
                    <FilterHandler
                      onChangeHandler={onChangeHandler}
                      actionHandler={actionHandler}
                      getCurrentFilter={getCurrentFilter}
                      removeFilter={removeFilter}
                      payload={payload}
                      Component={DynamicFilter}
                      filterData={customerMissedCallStates?.filter}
                      OperatorsData={operators}
                      selectedFilterData={selectedFilterData}
                      filterComponents={KeywiseComponents()}
                      storeFilterList={storeFilterList}
                      filterkey={filterkey}
                      setSeacrch={setSearchFilter}
                      isLoadingFilter={isLoadingFilter}
                      listApiUrl={listApiUrl("getCustomerCallStatusList")}

                    />
                  </div>
                </div>
              </div>
              <div className="w-100  d-flex mb-2">
                <div className='fiteredSelectedRows col-3 ml-2'>
                  {
                    customerMissedCallStates.filteredSelectedRows.length > 0 ?
                      <div className=''>
                        <Button className='btn-sm' onClick={() => setLocalStateDispatch({ type: "UPDATE_SHOW_BULK_ASSIGN_AGENT", payload: true })}>Bulk Assign Callback Agent to {customerMissedCallStates.filteredSelectedRows?.length} Customers</Button>
                      </div>
                      : <div></div>
                  }
                </div>
              </div>
            </>
          }
        />
      </div>

      <Modal size='lg' show={customerMissedCallStates.showBulkAgentAssign} onHide={handleBulkAgentUpdateModalClose} >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Assign {customerMissedCallStates.filteredSelectedRows?.length} Customers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBulkAgentUpdate}>
            <Form.Group className="mb-3" >
              <SingleSelectSearchCategoryDefault
                handleChangeInputData={(event: any, name: string) => setLocalStateDispatch({ type: "UPDATE_SELECTED_ADMIN_ID", payload: event.value })}
                HeaderTitle={"Select Agent"}
                SelectData={customerMissedCallStates.adminList}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
              />
            </Form.Group>
            <Form.Group className="mb-3 p-2" >
              {
                customerMissedCallStates.isBulkAssignLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Assign
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomerMissedCallList
