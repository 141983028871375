import { useEffect, useRef, useState } from 'react'
import { HotTable } from '@handsontable/react'
import { registerAllModules } from 'handsontable/registry'
import 'handsontable/dist/handsontable.full.min.css'
import Handsontable from 'handsontable'
import {
  colWidthsHotTable,
  handleAfterSelectionRow,
  hotInstanceLoader,
  searchFieldKeyupCallback,
} from '../../../admins/adminUtils'
import moment from 'moment'
import MomentDate from '../../../../consts/MomentDate'
import MainContainerWrapper from '../../../../consts/mediaQueryLayout/MainContainerWrapper'
import LeftBox from '../../../../consts/mediaQueryLayout/LeftBox'
import SearchBoxModal from '../../../../consts/reuseModules/SearchBoxModal'
import RightBox from '../../../../consts/mediaQueryLayout/RightBox'
import Refresh from '../../../../consts/Refresh'
import { getRequest, postRequest } from '../../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import IsLoading from '../../../../consts/IsLoading/IsLoading'
import InventoryOrderItemsByManagerFilter from './InventoryOrderItemsByManagerFilter'
import DropDownFilterMenu from '../../../../consts/BootrapDropdown/DropDownFilterMenu'
import ShowFilterHint from '../../../ticketCountLists/ticketComponents/ShowFilterHint'
import {AllFielSearch} from '../../../../consts/FieldSearch/AllFieldSearch'
import axios from 'axios'

registerAllModules()
const intervalType = [
  {name: 'Daily', id: 'daily'},
  {name: 'Weekly', id: 'weekly'},
  {name: 'Monthly', id: 'monthly'},
]

const dataType = [
  { id: 1, name: 'Item' }, // based on no. of items
  { id: 2, name: 'Order' }, // based on total order value
  { id: 3, name: 'Champ' } // based on no. of unique champs
]

export const InventoryOrderItemsByManager = () => {
  const hotTableComponentRef = useRef<any>(null)
  const [search, setSearch] = useState<string>('')
  const [pending, setPending] = useState(false)
  const [filterData, setFilterData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [inventoryItems, setInventoryItems] = useState<any>([])
  const [orderStatuses, setOrderStatuses] = useState<any>([])
  const [supervisors, setSupervisors] = useState<any>([])
  const [cleaners, setCleaners] = useState<any>([])
  const [payload, setPayload] = useState<any>({
    start_date: moment().startOf("month").format("YYYY-MM-DD"),
    end_date: MomentDate(0),
    type: "daily",
    item_id: "",
    status: "",
    area_manager: "",
    data_type: 1
  })

  async function getDropdownData() {
    const itemsResponse = await getRequest(URL_EndPoints(null)?.getAllInventoryItems, setPending)
    const statusesResponse = await getRequest(URL_EndPoints(null)?.getAllMasterOrderStatus, setPending)
    const supervisorsResponse = await getRequest(URL_EndPoints(null)?.getSupervisorList, setPending)
    
    setInventoryItems(itemsResponse?.data?.data || [])
    setOrderStatuses(statusesResponse?.data?.data || [])
    setSupervisors(supervisorsResponse?.data?.data || [])
  }

  async function getOrderedItemsByManager() {
    setFilteredSelectedRows([])
    setPending(true)
    const response = await postRequest(
      URL_EndPoints(null)?.getOrderedItemsByAreaManager,
      payload,
      setPending
    )

    // Filter out ID fields
    const filteredColumns = response?.data?.columns?.filter(
      (col: string) => !col.toLowerCase().includes('id')
    ) || []
    
    const filteredData = response?.data?.data?.map((item: any) => {
      const newItem = {...item}
      Object.keys(newItem).forEach(key => {
        if (key.toLowerCase().includes('id')) {
          delete newItem[key]
        }
      })
      return newItem
    }) || []

    setColumns(filteredColumns)
    setFilterData(filteredData)
    setData(filteredData)
  }

  useEffect(() => {
    getDropdownData()
    getOrderedItemsByManager()
  }, [])

  const onChangeHandler = (event: any, name: any) => {
    setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
  }

  let firstTimeNotRun = useRef<any>(false)
  useEffect(() => {
    let x = setTimeout(() => {
      const filterSearchData = AllFielSearch(data, search)
      firstTimeNotRun?.current === true && setFilterData(filterSearchData)
      if (firstTimeNotRun?.current === false) {
        firstTimeNotRun.current = true
      }
    }, 2000)
    return () => {
      clearTimeout(x)
    }
  }, [search])

  // Hot table setup
  useEffect(() => {
    hotInstanceLoader(hotTableComponentRef)
  }, [])

  const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
    handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
  }

  const cellRenderer = (...args: any) => {
    const [instance, td, row, col, prop, value, cellProperties] = args

    Handsontable.renderers.TextRenderer.apply(this, args)
    const rowData = instance.getSourceDataAtRow(row)
    const isParentRow = rowData?.__children
    if (isParentRow && prop === 'category') {
      td.style.backgroundColor = '#059df0'
      td.style.color = 'white'
    }
    else if (!isParentRow && value > 0) {
      td.style.backgroundColor = '#e8f0fe'
    }
  }

  const removeUnderScore = (str: any) => {
    if (str?.includes("_")) {
      return str?.split("_")?.join(" ")?.toUpperCase()
    }
    return str?.toUpperCase()
  }


  const shortWords = (sentence: any, index: any) => {
    if (index < 1) { return removeUnderScore(sentence) }
    if (sentence == "__children") return
    
    // Handle inventory item names with units
    if (sentence.includes('(')) {
      const [itemName, unit] = sentence.split('(')
      // Split long item names into multiple lines
      const words = itemName.trim().split(' ')
      const lines = []
      let currentLine = ''
      
      words.forEach((word: string) => {
        if (currentLine.length + word.length > 10) {
          lines.push(currentLine.trim())
          currentLine = word
        } else {
          currentLine += (currentLine ? ' ' : '') + word
        }
      })
      if (currentLine) {
        lines.push(currentLine.trim())
      }
      // Add unit on the last line
      lines.push(`(${unit}`)
      
      return {
        html: `<div title="${sentence}">${lines.join('<br>')}</div>`,
        title: sentence
      }
    }
    
    // Original handling for other columns
    if (sentence?.includes(" ")) {
      const [firstName, ...lastNames] = sentence.split(" ")
      return {
        html: `<div title="${sentence}">${firstName}<br>${lastNames.join(" ")}</div>`,
        title: sentence
      }
    }
    
    return {
      html: `<div title="${sentence}">${sentence}</div>`,
      title: sentence
    }
  }

  const mapIdToName = (id: any, list: any, nameKey = 'name') => {
    const item = list.find((item: any) => item.id === id);
    if (!item) return "";
    
    if (item.first_name && item.last_name) {
      return `${item.first_name} ${item.last_name}`;
    }
    return item[nameKey];
  };

  const mappedPayload = {
    ...payload,
    status: mapIdToName(payload.status, orderStatuses),
    area_manager: mapIdToName(payload.area_manager, supervisors),
    data_type: mapIdToName(payload.data_type, dataType),
  };

  return (
    <>
      <MainContainerWrapper>
        <LeftBox>
          <SearchBoxModal search={search} setSearch={setSearch} />
        </LeftBox>
        <RightBox>
          <Refresh refreshNow={getOrderedItemsByManager} />
          <DropDownFilterMenu
            ParentData={{
              handleActions: getOrderedItemsByManager,
              payloads: payload,
              onChangeHandler: onChangeHandler,
              intervalType: intervalType,
              defaultInterval: { label: "Daily", value: "daily" },
              dataType: dataType,
              defaultDataType: { label: "Item", value: 1 },
              inventoryItems: inventoryItems?.map((item: any) => ({
                id: item.id,
                name: item.title
              })),
              orderStatuses: orderStatuses?.map((status: any) => ({
                id: status.id,
                name: status.name
              })),
              supervisors: supervisors?.map((supervisor: any) => ({
                id: supervisor.id,
                name: supervisor.first_name + " " + supervisor.last_name
              })),
              defaultSupervisor: { label: "All", value: "" },
              defaultInventoryItem: { label: "All", value: "" },
              defaultOrderStatus: { label: "All", value: "" },
            }}
            Component={InventoryOrderItemsByManagerFilter}
          />
        </RightBox>
      </MainContainerWrapper>

      {/* hint components */}
      <ShowFilterHint payload={mappedPayload} />

      {pending ? (
        <IsLoading />
      ) : (
        <div>
          <HotTable
            style={{ zIndex: 1 }}
            data={filterData}
            className='orderedItemsByManager'
            filters={true}
            dropdownMenu={true}
            colWidths={colWidthsHotTable(columns, 1, 130, 55)}
            width="100%"
            height={"80vh"}
            title='Ordered Items By Area Manager'
            rowHeaders={true}
            colHeaders={(index) => {
              const header = columns[index]
              const formatted = shortWords(header, index)
              return formatted?.html || formatted
            }}
            allowHtml={true}
            fixedColumnsLeft={1}
            contextMenu={true}
            manualColumnFreeze={true}
            autoWrapRow={true}
            autoWrapCol={true}
            licenseKey="non-commercial-and-evaluation"
            autoRowSize={true}
            autoColumnSize={true}
            readOnly={true}
            manualRowResize={true}
            manualColumnResize={true}
            manualRowMove={true}
            multiColumnSorting={true}
            search={{ callback: searchFieldKeyupCallback }}
            afterSelection={handleAfterSelection}
            ref={hotTableComponentRef}
            preventOverflow="horizontal"
            nestedRows={true}
            bindRowsWithHeaders={true}
            cells={(row, col) => ({
              renderer: cellRenderer,
            })}
          />
        </div>
      )}
    </>
  )
}