/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CreateAppModal } from '../../../partials'
import { useLayout } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'
import React from "react"
import GoogleAutocompleteAddress from '../../../../app/modules/AreaCleanerAvailibiltybyAddress/GoogleAutocompleteAddress/GoogleAutocompleteAddress'
import { LocalBaseURL } from '../../../../BaseURLmanagement'

const Toolbar1 = () => {
  LocalBaseURL()

 

  const CardTemplate = useSelector<any>((state) => state?.NotificationActionReducer.CreatTemplate)
  const { classes } = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [createTemplatesPop, setCreateTemplatesPopOpen] = useState<boolean>(false)

  const handleCreateTemplatesPop = () => {
    setCreateTemplatesPopOpen(!createTemplatesPop)
  }

  React.useEffect(() => {
    const onclickeBaseURLChange = JSON.parse(localStorage.getItem("ischecked") || "0")
    if (!onclickeBaseURLChange) {
      localStorage.setItem("ischecked", JSON.stringify(false))
    }
  }, [])

  const onclickeBaseURLChange = JSON.parse(localStorage.getItem("ischecked") || "0") ? JSON.parse(localStorage.getItem("ischecked") || "0") : false
  const [localKey, setlocalKey] = useState<boolean>(onclickeBaseURLChange || false)

  const changeStatusApi = () => {
    localStorage.setItem("ischecked", JSON.stringify(!localKey))
    const onclickeBaseURLChange = JSON.parse(localStorage.getItem("ischecked") || "0")
    setlocalKey(onclickeBaseURLChange)
  }


  React.useEffect(() => {

    if (window.location.host == "testadmin.carselonadaily.com") {
      localStorage.setItem("API", JSON.stringify(false))
      localStorage.setItem("ischecked", JSON.stringify(false))
    }
    else {
      if (localKey) {
        localStorage.setItem("API", JSON.stringify(true))
      } else {
        if (window.location.origin.includes("3011")) {
          localStorage.setItem("API", JSON.stringify(false))
        }
        else {
          localStorage.setItem("API", JSON.stringify(true))
        }
      }
    }


  }, [localKey])


  const urlEndPoint = window.location.pathname.split("/")
  {
    urlEndPoint[urlEndPoint.length - 1] === "area-wise-cleaner" &&
      <div className='me-4'>
        <GoogleAutocompleteAddress></GoogleAutocompleteAddress>
      </div>
  }


  return (
    <>
      <div className='toolbar' id='kt_toolbar'
        style={{ background: "rgb(249,249,250)" }}

      >
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}

        >
          <DefaultTitle />



          <div className='d-flex align-items-center py-1'>
            {/* area-wise-cleaner cleaner list seacrh box ======================================================  */}
            {
              urlEndPoint[urlEndPoint.length - 1] === "area-wise-cleaner" &&
              <div className='me-4'>
                <GoogleAutocompleteAddress></GoogleAutocompleteAddress>
              </div>
            }
            {/* area-wise-cleaner cleaner list seacrh box ========================================================= */}


            {/* to toggle running api into test admin or live admin==============================================   */}
            <div className='me-2'>
              {/* begin::Menu */}
              {
                !window.location.origin.includes("3011") ? <></> : <a
                // !window.location.origin.includes("3011") && window.location.host != "testadmin.carselonadaily.com" ? <></> : <a
                  // href='#'
                  className={`btn btn-sm btn-flex btn-light btn-${localKey ? "danger" : "primary"} fw-bolder`}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={changeStatusApi}
                >
                  <i className="bi bi-globe"></i>
                  {localKey ? "ADMIN-API-RUNNING" : "TEST-ADMIN-API-RUNNING"}
                </a>
              }
            </div>
            {/* to toggle running api into test admin or live admin ==================================================  */}


            {/* Notification template form page ===================================================================  */}
            {
              CardTemplate == "create_notify_template" ?
                <a
                  className='btn btn-sm btn-primary cursor-pointer'
                  id='kt_toolbar_primary_button'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_create_app'
                  onClick={() => handleCreateTemplatesPop()}
                >
                  Create Templates
                </a> : ""
            }



          </div>

        </div>

      </div>
      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} CardTemplate={CardTemplate} handleCreateTemplatesPop={handleCreateTemplatesPop} createTemplatesPop={createTemplatesPop} />
    </>
  )
}
export { Toolbar1 }
