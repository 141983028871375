import React, { useEffect, useState } from 'react'

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

import { Spinner } from 'react-bootstrap'
import "./CallSupervisors.css"
import { getRequest, postRequest } from '../Server/Requests'
import { URL_EndPoints } from '../Server/URL_EndPoints'
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG'
import { clickToCallFromTicket } from '../../modules/JobsDashboard/API'

const CallSupervisors = ({ isSupervisorCallOpen, setIsSupervisorCallOpen }: any) => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isCalling, setIsCalling] = useState<boolean>(false)
    const [supervisors, setSupervisors] = useState<any>([])
    const [clickedSupervisor, setClickedSupervisor] = useState<any>(0)


    const toggleDrawer = () => {
        setIsSupervisorCallOpen((prevState: any) => !prevState)
    }

    let userid = localStorage.getItem("user") || 0

    useEffect(() => {

        setLoading(true)
        async function InvokedRendered() {
            if (isSupervisorCallOpen == true) {
                const { data } = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
                setSupervisors(data?.data)
            }
            setLoading(false)
        }

        InvokedRendered()

    }, [isSupervisorCallOpen])

    const CallSupervisor = async (type: any, cleaner_id: any) => {
        setIsCalling(true)
        setClickedSupervisor(cleaner_id)
        const paylaods: any = {
            userid: JSON.parse(localStorage.getItem("user") || "0"),
        }
        paylaods[type] = cleaner_id
        const response = await clickToCallFromTicket(paylaods)
        if (response?.data?.status === 200) {
            // toast.success(response?.data?.msg)
            setIsCalling(false)
        }
        else {
            // toast.error(response?.data?.msg)
            setIsCalling(false)
        }
        setClickedSupervisor(0)

    }

    return (
        <>
            <Drawer
                lockBackgroundScroll={true}
                open={isSupervisorCallOpen}
                onClose={toggleDrawer}
                direction='right'
                className='bla bla bla'
                size={500}
                overlayOpacity={0.1}
                style={{ overflow: "scroll" }}
            >
                <div className='p-4'>
                    <h2>Supervisors List</h2>
                </div>
                <div>
                  
                    <div className='p-5'>
                        {
                            !isLoading ?
                                <ul className="list-group list-group-flush">
                                    {
                                        supervisors?.map((supervisor: any) => {
                                            return <li className={"list-group-item text-dark"} >
                                                <div className='d-flex justify-content-between'>
                                                    <div className="d-flex justify-content-start">
                                                      
                                                        <div className='pl-6 pr-6 align-self-center text-dark' >
                                                            <p  className='text-dark' style={{ marginBottom: 0 }}>{supervisor?.first_name + ' ' + supervisor?.last_name}</p>
                                                            <p  className='text-dark' style={{ marginBottom: 0 }}>{supervisor?.phone}</p>
                                                        </div>

                                                    </div>
                                                    {
                                                        isCalling && clickedSupervisor == supervisor?.id ?
                                                            <div><Spinner animation={'border'} /></div>
                                                            :
                                                            <div className='call-button' onClick={() => CallSupervisor('supervisorid', supervisor?.id)}><KTSVG path='/media/icons/duotune/Info/call002.svg' className='svg-icon-2 ml-1' /></div>

                                                    }
                                                </div>
                                            </li>

                                        })
                                    }
                                </ul>
                                :
                                <div className='d-flex justify-content-center'>
                                    <Spinner animation={'border'} />
                                </div>
                        }
                    </div>

                </div>
            </Drawer>
        </>
    )
}

export default CallSupervisors