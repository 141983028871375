const ChatHeaderChips = ({ SelectedString, classes }: any) => {
    return (
        <>
            <div className={`badge badge-light-${classes} fw-bolder cursor-pointer me-1 mt-2 mb-2`} >
                {
                    SelectedString
                }
            </div>
        </>
    )
}
export default ChatHeaderChips
