import React, { useEffect, useState } from 'react'
import "./faqs.css"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faBackward, faPlusCircle, faStepBackward } from '@fortawesome/free-solid-svg-icons'
import { addCategoryData } from './faqsData'
import { getAllFaqCategoryListData, getAllFaqsListDataByCategory } from '../../../Redux/actions/faqActions'
import { initialPayload } from '../DynamicFilteration/components/CommonData'
import AdminForm from '../../modules/admins/adminForms/AdminForm'
import { REDIRECT_BACK_TO_FAQ_FETCH_CURRENT_CATEGORY_PAYLOAD } from '../../../Redux/actionsType/faqActionTypes'
import { AdminToolTip } from '../../modules/admins/adminComponents/AdminToolTipModal'
const CategoryPage = ({ selectedCategory, isRedirect, faqPrevCategoryPayload }: any) => {
    const dispatch = useDispatch()
    const history = useNavigate();
    const [showAddCategory, setShowAddCategory] = useState(false)
    const faqCategoryListData: any = useSelector((store: any) => store.faqReducer.faqCategoryListData)

    async function getFaqList(payload?: any) {
        dispatch(getAllFaqsListDataByCategory(payload) as any)
    }

    async function getFaqCategoryList(payload?: any) {
        dispatch(getAllFaqCategoryListData() as any)
    }

    useEffect(() => {
        getFaqCategoryList()
    }, [])

    const categoryFilterHandler = (ele: any) => {
        let newPayload = ele?.id > 0 ? { ...initialPayload(false, {}), ["filterData"]: ele?.filterData } : initialPayload(false, {})
        dispatch({ type: REDIRECT_BACK_TO_FAQ_FETCH_CURRENT_CATEGORY_PAYLOAD, payload: newPayload })
        if (isRedirect) {
            history(`/faq/frequently-asked-question`)
            getFaqList(newPayload)
        }
        else {
            getFaqList(newPayload)
        }

    }

    const categoryFilterHandlerForPrevPayload = (isRedirect: any, faqPrevCategoryPayload: any) => {
        if (isRedirect) {
            history(`/faq/frequently-asked-question`)
            getFaqList(faqPrevCategoryPayload)
        }
    }

    return (
        <div className='col-lg-3 col-md-3 col-md-order-1 col-sm-order-1 col-xl-3 mb-2  p-4 '>
            <div className='d-flex w-125 justify-content-between  p-1 px-0'>
                <FontAwesomeIcon className={`fs-1 fw-bold text-${isRedirect ? "dark" : "muted"} px-1 cursor-pointer `} id='faqBackButton121' icon={faArrowLeftLong} onClick={() => categoryFilterHandlerForPrevPayload(isRedirect, faqPrevCategoryPayload)}
                    style={{ pointerEvents: isRedirect ? 'auto' : 'none' }}
                />
                <AdminToolTip anchorId="faqBackButton121" title="Back to FAQ list" place={"bottom"} />

                <h2 className='fw-semibold cursor-pointer mb-10 me-2'>Categories</h2>
                <FontAwesomeIcon className='fs-1 ms-4 fw-bold text-muted px-1 cursor-pointer ' icon={faPlusCircle} onClick={() => setShowAddCategory(true)} />
            </div>
            <div className='d-flex flex-column p-1 px-2 ' style={{ borderLeft: "2px solid #e3e4e9" }}>
                {
                    faqCategoryListData?.map((ele: any) => {
                        return <div className='d-flex justify-content-between align-items-center mb-2 text-hover-primary cursor-pointer' onClick={() => categoryFilterHandler(ele)}>
                            <h2 className='cursor-pointer'
                                onMouseEnter={(e) => e.currentTarget.classList.add("text-primary")}
                                onMouseLeave={(e) => e.currentTarget.classList.remove("text-primary")}
                                style={{
                                    fontFamily: 'Inter, Helvetica, "sans-serif"',
                                    lineHeight: '24.375px',
                                    color: selectedCategory?.id == ele?.id ? "#7cc8ff" : '#5e5e6d',
                                    fontWeight: "500",
                                    position: 'relative'
                                }}
                            >
                                {ele?.name}
                            </h2>
                            <span className='fw-bold text-muted'>{ele?.count ? ele?.count : 0}</span>
                        </div>
                    })
                }
            </div>

            {
                showAddCategory ? <AdminForm
                    show={showAddCategory}
                    setShow={setShowAddCategory}
                    refreshNow={() => { }}
                    toastMessage="msg"
                    formData={addCategoryData}
                    staticFormData={[]}
                    initialData={{
                        name: "",
                        description: "",
                    }}
                    keyName="createCategoryForm"
                    formTitle={`Add Category`}
                    formSubmitApiUrl="addFAQCategory"
                /> : <></>
            }


        </div >
    )
}

export default CategoryPage
