import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { KTCardBody } from '../../../_metronic/helpers'
import { postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import RenewalSubscriptionsListMain from './RenewalSubscriptionListMain'
import { setStoreLastStatusListData } from '../../../Redux/actions/cxAction'
import { catchCurrentOpenedUrl } from '../../consts/components/Utility'
const NewRenewalSuscriptionControllerRoute = ({ filterDataOutsource }: any) => {
    const dispatch = useDispatch()
    const getRenewalData = async () => {
        const response = await postRequest(URL_EndPoints(null)?.getSubscriptionRenewalReason,
            {
                "category_id": 1
            }
            , null)
        const response2 = await postRequest(URL_EndPoints(null)?.getMasterReasonsForSubscription,
            {
            }
            , null)
        const updatedData = response2?.data?.data?.map((ele: any, i: number) => {
            if (ele["reason"] != null) {
                const newData = {
                    name: ele["reason"],
                    id: ele["id"],
                }
                return newData
            }
        })
        dispatch({ type: "RENEWAL_REASON", payload: response?.data?.data })
        dispatch({ type: "MASTER_REASON", payload: updatedData })
        dispatch(setStoreLastStatusListData({ keyName: catchCurrentOpenedUrl(), data: response?.data?.data }) as any)
    }
    useEffect(() => {
        getRenewalData()
    }, [])
    return (
        <KTCardBody className='card py-4'>
            <RenewalSubscriptionsListMain filterDataOutsource={filterDataOutsource}></RenewalSubscriptionsListMain>
        </KTCardBody>
    )
}
export default NewRenewalSuscriptionControllerRoute
