import React, { memo, useState } from 'react'
import ChatDashboardBody from './ChatHistoryController/ChatDashboardBody'
import "./custom.css"



const ChatDashboardController = ({ TicketListDatas, customerDetailData, loading, quickResponseData, subscriptionHistory, activeSubscrptions, vehicleData, coinHistory, offersData, supervisorData, TransactionListData, walletTransactions, setWhatsappMessage, jobRating, ticketDataRedux, currenOpenedTicketData }: any) => {

    return (
        <div className='border border-muted rounded-0' id='kt_drawer_chat2_messenger'>
            <div className='chat-hostory-body-content'>
                <ChatDashboardBody TicketListDatas={TicketListDatas} customerDetailData={customerDetailData} loading={loading} quickResponseData={quickResponseData} activeSubscrptions={activeSubscrptions} subscriptionHistory={subscriptionHistory} vehicleData={vehicleData} coinHistory={coinHistory} offersData={offersData} supervisorData={supervisorData} TransactionListData={TransactionListData} walletTransactions={walletTransactions} jobRating={jobRating} ticketDataRedux={ticketDataRedux} currenOpenedTicketData={currenOpenedTicketData}></ChatDashboardBody>
            </div>
        </div>
    )
}
export default memo(ChatDashboardController)
