import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRequest, postRequest } from '../Server/Requests'
import { URL_EndPoints } from '../Server/URL_EndPoints'
import ChatDashboardController from './ChatDashboardController'
import Chats from './Chats'
import Split from 'react-split'
import { ChatTicketID } from '../../../Redux/Action/Chats/ChatAction'
import { timeCaptureCloseChat } from '../timeCaptureOpenCloseChat'
import { setIsChatOpenedOrNot } from '../../modules/admins/adminUtils'

const MessengerChatBoxDrawerController = () => {
    const ticketDataRedux = useSelector((store: any) => store.ChatReducers.TicketData)
    const initialValue = useSelector((store: any) => store.ChatReducers.initialValue)
    const chatboxRefresh = useSelector((store: any) => store.RefreshPage.chatbox)
    const ticketData: any = useSelector((store: any) => store.ChatReducers.TicketData)
    const Change_Status_rEFRESH = useSelector((store: any) => store.RefreshPage.Change_Status)
    const Intiate_Refund_rEfresh = useSelector((store: any) => store.RefreshPage.Intiate_Refund)
    const dublicateVehicleremove = useSelector((store: any) => store.RefreshPage.dublicateVehicleremove)
    const vehicleDeleted = useSelector((store: any) => store.RefreshPage.vehicleDeleted)
    const offerListItem = useSelector((store: any) => store.RefreshPage.offerListItem)
    const vehicleUpdated = useSelector((store: any) => store.RefreshPage.vehicleUpdated)
    const completSubscription = useSelector((store: any) => store.RefreshPage.completSubscription)
    const CurrentMessageSource = useSelector((store: any) => store.RefreshPage.CurrentMessageSource)

    const [loading, setloading] = React.useState(true)
    const [currenOpenedTicketData, setcurrenOpenedTicketData] = React.useState<any>({})
    const [TicketListDatas, setTicketListDatas] = React.useState([])
    const [customerDetailData, setcustomerDetailData] = React.useState<any>({})
    const [quickResponseData, setQuickResponseData] = React.useState([])
    const [activeSubscrptions, setactiveSubscrptions] = React.useState([])
    const [vehicleData, setvehicleData] = React.useState([])
    const [subscriptionHistory, setsubscriptionHistory] = React.useState([])
    const [coinHistory, setcoinHistory] = React.useState([])
    const [supervisorData, setsupervisorData] = React.useState({})
    const [offersData, setoffersData] = React.useState([])
    const [borderColor, setborderColor] = React.useState("")
    const [ShowImagesToggle, setShowImagesToggle] = React.useState<any>(false)
    const [ShowImagesLink, setShowImagesLink] = React.useState("")
    const [TransactionListData, setTransactionListData] = React.useState([])
    const [walletTransactions, setWalletTransactions] = React.useState([])
    const [WhatsappMessage, setWhatsappMessage] = React.useState([])
    const [MessageSource, setMessageSource] = React.useState("TicketMessageSource")
    const [WhatsappTemplate, setWhatsappTemplate] = React.useState([])
    const [jobRating, setJobRating] = React.useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        setcurrenOpenedTicketData(ticketData)
    }, [ticketData])


    async function invokedApi(customerid: any) {
        setloading(true)
        const ticketListData = await postRequest(URL_EndPoints()?.getAllTicketsByCustomerId, { customerid: customerid }, setloading)

        setsubscriptionHistory(ticketListData?.data?.subscriptionHistory)
        setTicketListDatas(ticketListData?.data?.result)
        setcustomerDetailData(ticketListData?.data?.customer_detail)
        setJobRating(ticketListData?.data?.jobRating)
        setQuickResponseData(ticketListData?.data?.quickResponses)
        setactiveSubscrptions(ticketListData?.data?.activeSubscrptions)
        setvehicleData(ticketListData?.data?.customerVehicle)
        setcoinHistory(ticketListData?.data?.coinHistory)
        setoffersData(ticketListData?.data?.offersData)
        setsupervisorData(ticketListData?.data?.supervisorData)

        const TransactionListData = await postRequest(URL_EndPoints()?.getTransactions, { customerid: ticketData?.customerid }, null)
        const walletTransactions = await postRequest(URL_EndPoints()?.getWalletTransactions, { customerid: ticketData?.customerid }, null)
        setWalletTransactions(walletTransactions?.data?.data)
        setTransactionListData(TransactionListData?.data?.data)
    }

    React.useEffect(() => {
        !ticketDataRedux?.externalTicketSource && ticketData?.customerid && invokedApi(ticketData?.customerid)
    }, [ticketData?.customerid, chatboxRefresh, dublicateVehicleremove.dublicate_Render, vehicleDeleted.vehicle_render, vehicleUpdated.updateVehicle_render, completSubscription.complete_subscription, offerListItem?.offer_render])

    React.useEffect(() => {
        !ticketDataRedux?.externalTicketSource && ticketData?.customerid && Change_Status_rEFRESH?.Change_Statu_Render != null && invokedApi(ticketData?.customerid)
        !ticketDataRedux?.externalTicketSource && ticketData?.customerid && Change_Status_rEFRESH?.Intiate_Refund_Render != null && invokedApi(ticketData?.customerid)
    }, [Change_Status_rEFRESH?.Change_Statu_Render, Intiate_Refund_rEfresh?.Intiate_Refund_Render,])


    React.useEffect(() => {
        async function load() {
            const getWhatsappMessage = await postRequest(URL_EndPoints()?.get_LatestWhatsAppMessageByNumber, { customerphone: customerDetailData?.phone, pageSize: 50, pageNumber: 1 }, null)
            setWhatsappMessage(getWhatsappMessage?.data?.data?.messages?.items)
            const getWhatsappTemplate = WhatsappTemplate?.length == 0 && await postRequest(URL_EndPoints()?.get_WhatsAppTemplate, { wa_number_id: 1 }, null)
            WhatsappTemplate?.length == 0 && setWhatsappTemplate(getWhatsappTemplate?.data?.data || [])
        }

        ticketDataRedux?.customerid && load()
    }, [customerDetailData?.phone])

    useEffect(() => {
        async function load() {
            const getticketbycustomer = await postRequest(URL_EndPoints()?.getticketbycustomer, { customerid: customerDetailData?.id }, null)
            dispatch({ type: "GET_TICKET_BY_CUSTOMER", payload: getticketbycustomer?.data?.data })
        }
        customerDetailData?.id && load()
    }, [customerDetailData?.id])


    React.useEffect(() => {
        setMessageSource(CurrentMessageSource?.from)
    }, [CurrentMessageSource.CurrentMessageSourceRender])

    React.useEffect(() => {
        ticketDataRedux?.customerid == "" && setloading(false)
    }, [ticketDataRedux])


    const [PopOpenClose, setPopOpenClose] = React.useState(false)
    const TicketCustomerInformationPopupBTN = () => {
    }

    const closeHandle = () => {
        dispatch({
            type: "TAB_KEY", payload: {
                keyName: null,
                render: null
            }
        })
        
        dispatch({
            type: "DEAFUALT_CHAT_TAB", payload: {
                DefaultTabChat: 2,
                render: Math.random()
            }
        })

        dispatch(ChatTicketID({
            id: null,
            customerid: null,
            random: null,
        }))

        document.getElementById('kt_content_container')!.style.position = 'initial'
        let classss: any = document.getElementById('kt_drawer_chat3')
        if (classss) {
            classss.classList.remove('drawer-on')
        }
        setIsChatOpenedOrNot(null, true, "customer_chat_opened")
        dispatch({
            type: "NOTIFICATION_DATA", payload: {
                chatName: null,
                id: null,
                data: {}
            }
        })
        timeCaptureCloseChat(JSON.parse(localStorage.getItem("timeCapture") || "0") || "0", null)

    }

    const handleStates = () => {
        setWalletTransactions([])
        setTransactionListData([])
        setTicketListDatas([])
        setcustomerDetailData([])
        setQuickResponseData([])
        setactiveSubscrptions([])
        setsubscriptionHistory([])
        setvehicleData([])
        setcoinHistory([])
        setoffersData([])
        setsupervisorData([])
    }
    return (
        <div className='card border'>
            <div
                // style={{ width: "100%", overflowX: 'scroll', border: borderColor ? borderColor : "" }}
                style={{ width: "100%", border: borderColor ? borderColor : "" }}
                id='kt_drawer_chat2'
                className={`bg-body `}
                data-kt-drawer='true'
                data-kt-drawer-name='chat'
                data-kt-drawer-activate='true'
                data-kt-drawer-overlay='true'
                // data-kt-drawer-width="{default:'300px', 'md': `${widths}`}"
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_drawer_chat2_toggle2'
                data-kt-drawer-close='#kt_drawer_chat2_close'
            >
                {ShowImagesToggle && <div style={{ width: "100%", height: "100%", position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 99999, margin: "auto", boxShadow: "0px 0px 5px red" }} onClick={() => setShowImagesToggle(false)}>
                    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                        <i style={{ textAlign: "right", height: "2%", marginTop: "50px", marginRight: "50px", fontSize: "50px" }} className="bi bi-x-circle-fill text-danger fs-1 text-primary mt-10 me-10 cursor-pointer " onClick={() => setShowImagesToggle(false)} ></i>
                        <img src={ShowImagesLink} alt="" style={{ width: "80%", height: "80%", margin: 'auto' }} />
                    </div>
                </div>}
                {
                    loading ?
                        <div className='d-flex align-items-center justify-content-center flex-column' style={{ margin: "auto" }}>
                            <div className='spinner-border mr-15 fs-2 mb-2' role='status'></div>
                            <h4 className='fw-bold fs-2'>Loading...</h4>
                            <button id='kt_drawer_chat2_close'
                                style={{ position: "absolute", top: "20px", right: "30px", }}
                                onClick={closeHandle} type="button" className="btn btn-sm btn-primary  btn-xl"><i className="fa fa-times fs-2"></i>
                            </button>
                        </div> :
                        <>
                            <Split sizes={[67, 33]}
                                className="split"
                            >
                                <ChatDashboardController TicketListDatas={TicketListDatas} customerDetailData={customerDetailData} loading={loading} quickResponseData={quickResponseData} activeSubscrptions={activeSubscrptions} subscriptionHistory={subscriptionHistory} vehicleData={vehicleData} coinHistory={coinHistory} offersData={offersData} supervisorData={supervisorData} TransactionListData={TransactionListData} walletTransactions={walletTransactions} setWhatsappMessage={setWhatsappMessage} jobRating={jobRating} ticketDataRedux={ticketDataRedux} currenOpenedTicketData={currenOpenedTicketData}></ChatDashboardController>
                                {
                                    loading ? "Loading" : <Chats customerDetailData={customerDetailData} TicketData={ticketData} ticketDataRedux={ticketDataRedux} loading={loading} TicketCustomerInformationPopupBTN={TicketCustomerInformationPopupBTN} PopOpenClose={PopOpenClose} source={ticketData?.source} setShowImagesToggle={setShowImagesToggle} setShowImagesLink={setShowImagesLink} WhatsappMessage={WhatsappMessage} WhatsappTemplate={WhatsappTemplate} MessageSource={MessageSource} handleStates={handleStates} setcurrenOpenedTicketData={setcurrenOpenedTicketData} setWhatsappMessage={setWhatsappMessage} invokedApi={invokedApi} ></Chats>
                                }
                            </Split>
                        </>
                }
            </div>

        </div>
    )
}
export default memo(MessengerChatBoxDrawerController)
