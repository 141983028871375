import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { LocalBaseURL } from '../../../BaseURLmanagement'
import "./CustomCss.css"
import { columns } from './Components/Columns'
import moment from 'moment'
import TopHeader from './Components/InputBox/TopHeader'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import MomentDate from '../../consts/MomentDate'
import { useDispatch, useSelector } from 'react-redux'
import { KTCardBody } from '../../../_metronic/helpers'
import { ShowHideColumns } from '../../consts/ShowHideColumns'
import "./jobCss.css"
import { getSearchDataBykeyword, handleSelectChange, paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../consts/components/Utility';
import { jobListGetData } from './jobListUtils'
import { getPostMultipleApiHandler, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper'
import LeftBox from '../../consts/mediaQueryLayout/LeftBox'
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal'
import RightBox from '../../consts/mediaQueryLayout/RightBox'
import Refresh from '../../consts/Refresh'
import { ReactTableComponentStylesSheet } from '../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet'
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../admins/adminConstant'

const Old_JobList = (props: any) => {
  LocalBaseURL()
  const AllCleanerList = useSelector((store: any) => store.ReasonsListDataReducer.cleanerListData)
  const AllSuperListData = useSelector((store: any) => store.ReasonsListDataReducer.supervisorListData)
  const JobTypesListData = useSelector((store: any) => store.ReasonsListDataReducer.jobTypeListData)

  const filterDataOutSource = props?.filterDataOutSource
  const URLEndPoints = window.location.search
  let timelostArr = URLEndPoints?.split('&')[1]?.split('=')
  let status_nameArr = URLEndPoints?.split('&')[2]?.split('=')
  const job_list_refresh = useSelector((store: any) => store.JobListRefreshData.job_list_refresh)
  const refreshDataTable: any = useSelector((store: any) => store.DailyJobDashboard.refreshTable)
  const [pending, setPending] = React.useState(true)
  const [CleanerAttendanceData, setCleanerAttendanceData] = useState([])
  const [Search, setSearch] = useState<any>('')
  const [filterData, setfilterData] = useState<any>([])
  const [jobStatusId, setJobStatusId] = useState<number>(props?.job_status_id ?? 0)
  const [timeslotId, setTimeslotId] = useState<number>(props?.timeslot_id ?? 0)
  const [parsingDate, setParsingDate] = useState<number>(props?.date ?? MomentDate(0))
  const [selectedType, setSelectedType] = useState<any>(props?.type ?? 0)
  const [supervisorId, setSupervisorId] = useState<any>(props?.supervisorid || 0)
  const [subscriptionsid, setSubscriptionid] = useState<any>(props?.subscriptionid ?? 0)
  const [customerid, setCustomerid] = useState<any>(props?.customerid ?? 0)
  const [cleaner_id, setCleaner_id] = useState<any>(props?.cleaner_id ?? 0)
  const [JobListDate, setJobListDate] = useState<any>(props?.JobListDate ?? {})
  const [rate, setRate] = useState<any>(props?.rate ?? 0)
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(props?.subscription_status ?? 0)
  const [attendanceIds, setattendanceIds] = useState<any>(props?.attendance_ids || false)
  const [sortedColumns, setSortedColumns] = useState<any>(columns())

  const [selectedReportedCategoryId, setSelectedReportedCategoryId] = useState<any>(
    props?.jobStatusCategoryId ?? 0
  )

  const [tag, setTag] = useState<any>(
    props?.tag ?? ""
  )

  const [by, setBy] = useState<any>(
    props?.by ?? ""
  )

  const [payloads, setPayloads] = useState({
    attendencedatefrom: props?.JobListDateTabclick?.attendanceDateFrom ? props?.JobListDateTabclick?.attendanceDateFrom : subscriptionsid ? JobListDate?.startdate : parsingDate,
    attendencedateto: props?.JobListDateTabclick?.attendanceDateTo ? props?.JobListDateTabclick?.attendanceDateTo : subscriptionsid ? JobListDate?.enddate : parsingDate,
    cleanerid: cleaner_id,
    cleanerstatus: null,
    timeslotid: timeslotId,
    jobstatus: jobStatusId,
    reportid: 0,
    customerid: customerid,
    radius: 0,
    supervisorId: supervisorId,
    subscriptionid: subscriptionsid,
    type: selectedType,
    reportedCategoryId: selectedReportedCategoryId,
    rate: rate,
    subscription_status: subscriptionStatus,
    tag: tag,
    by: by,
    attendance_ids: attendanceIds
  })


  useEffect(() => {
    setJobStatusId(props.job_status_id ?? 0)
    setTimeslotId(props.timeslot_id ?? 0)
    setParsingDate(props.date ?? MomentDate(0))
    setSelectedType(props.type ?? 0)
    setSupervisorId(props.supervisorid || 0)
    setSelectedReportedCategoryId(props.jobStatusCategoryId ?? 0)
    setSubscriptionid(props.subscriptionid ?? 0)
    setCustomerid(props.customerid ?? 0)
    setCleaner_id(props.cleaner_id ?? 0)
    setJobListDate(props.JobListDate ?? {})
    setRate(props.rate ?? 0)
    setTag(props.tag ?? "")
    setSubscriptionStatus(props?.subscription_status ?? 0)
    setBy(props?.by || "")
    setattendanceIds(props?.attendance_ids || false)
  }, [
    props.job_status_id,
    props.timeslot_id,
    props.date,
    props.type,
    props.supervisorid,
    props.jobStatusCategoryId,
    props.subscriptionid,
    props.customerid,
    props?.JobListDateTabclick?.attendanceDateFrom,
    props.rate,
    props.reference,
    props.key,
    props.tag,
    props.subscription_status,
    props.by,
    props?.attendance_ids,
    props.cleaner_id
  ])

  const handleChangeInputData = (event: any, name: string) => {
    if (event == null) {
      setPayloads({
        ...payloads,
        [name]:
          name === 'attendencedatefrom'
            ? moment().format('YYYY-MM-DD')
            : name === 'attendencedateto'
              ? moment().format('YYYY-MM-DD')
              : name === 'subscription_status'
                ? ""
                : name === 'tag'
                  ? ""
                  : 0,
      })
    } else {
      const { value } = event
      setPayloads({
        ...payloads,
        [name]:
          name === 'by' ? value : name === 'attendencedateto' ? value : name === 'attendencedatefrom' ? value : name === 'subscription_status' ? value : name === 'tag' ? event.value : +value,
      })
    }
  }

  React.useEffect(() => {
    const updatedData = AllFielSearch(CleanerAttendanceData, Search)
    setfilterData(updatedData)
  }, [Search])

  async function getFetchTableListData() {
    const { isFilter, isExternalApi, ...filterListData } = filterDataOutSource || {}
    isFilter == true && setPayloads({ ...payloads, ...filterListData })
    let payload = isFilter ? { ...payloads, ...filterListData } : payloads
    setPending(true)
    const { data } = await postRequest(URL_EndPoints()?.[isExternalApi] || "allChampsJobs", payload, setPending)
    setCleanerAttendanceData(data.data)
    setfilterData(data.data)
  }

  useEffect(() => {
    getFetchTableListData()
  }, [job_list_refresh, refreshDataTable, subscriptionsid, filterDataOutSource])
  const dispatch = useDispatch()
  async function getFetchInitialData() {
    if (AllCleanerList?.length == 0 && AllSuperListData?.length == 0 && JobTypesListData?.length == 0) {
      const results = await getPostMultipleApiHandler(jobListGetData, null)
      dispatch({ type: "CLEANER_LIST_DATA_JOBLIST", payload: results["getCleanerList"] || [] })
      dispatch({ type: "SUPERVISOR_LIST_DATA_JOBLIST", payload: results["getSupervisorList"] || [] })
      dispatch({ type: "JOB_TYPE_LIST_DATA_JOBLIST", payload: results["getAlJobTypes"] || [] })
    }

  }

  useEffect(() => {
    getFetchInitialData()
  }, [])

  const handleSearchJoblist = async () => {
    setPending(true)
    const { isExternalApi } = filterDataOutSource || {}
    const { data } = await postRequest(URL_EndPoints()?.[isExternalApi] || "allChampsJobs", payloads, setPending)
    const updatedList = data?.data?.sort((a: any, b: any) => a?.c_id - b?.c_id)
    setCleanerAttendanceData(updatedList)
    setfilterData(updatedList)
  }

  useEffect(() => {
    if (filterData) {
      const newColumns = columns(null, filterData?.map((ele: any) => ele?.cleaner?.id), filterData?.map((ele: any) => +ele?.customerids));
      setSortedColumns(newColumns);
    }
  }, [filterData]);

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchDataCleaner, setsearchDataCleaner] = useState<any[]>([])
  const [storeSeachKeywords, setstoreSeachKeywords] = useState<boolean>(false)
  async function getSearchData(storeSeachKeywords: any) {
    getSearchDataBykeyword(storeSeachKeywords, setsearchDataCleaner, setIsLoading)
  }

  const selectSearchValue = (event: any, name: any) => {
    handleSelectChange(event, name, setPayloads, payloads)
  }

  React.useEffect(() => {
    let x = setTimeout(() => {
      storeSeachKeywords && getSearchData(storeSeachKeywords)
    }, 2000)
    return () => {
      setIsLoading(false)
      clearTimeout(x)
    }
  }, [storeSeachKeywords])

  return (

    <KTCardBody className='py-4'>
      <div className='job-list-table' >
        <DataTable
          customStyles={ReactTableComponentStylesSheet}
          columns={reorderColumns(sortedColumns, "jobListReoderedItem")}
          data={filterData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          progressPending={pending}
          subHeader
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "jobListReoderedItem")}
          subHeaderComponent={
            <>
              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={Search} setSearch={setSearch} />
                </LeftBox>
                <RightBox>
                  <Refresh refreshNow={handleSearchJoblist} />

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-show-hide-column"]}>
                    <ShowHideColumns columns={columns()} sortedColumns={sortedColumns} setSortedColumns={setSortedColumns} />
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-show-hide-column"]}>
                    <TopHeader
                      payloads={payloads}
                      subscriptionsid={subscriptionsid}
                      handleChangeInputData={handleChangeInputData}
                      setSearch={setSearch}
                      handleSearchJoblist={handleSearchJoblist}
                      Search={Search}
                      filterationData={{
                        AllCleanerList,
                        AllSuperListData,
                        jobStatusId,
                        timeslotId,
                        selectedType,
                        JobTypesListData,
                        supervisorId,
                        selectedReportedCategoryId,
                        customerid,
                        rate,
                        tag,
                        by,
                        setstoreSeachKeywords: setstoreSeachKeywords,
                        searchDataCleaner: searchDataCleaner,
                        isLoading: isLoading,
                        selectSearchValue: selectSearchValue,
                      }}
                      timelostArray={timelostArr}
                      status_nameArr={status_nameArr}
                    />
                  </PermissionContextProvider>


                </RightBox>
              </MainContainerWrapper>
            </>
          }
        />
      </div>
    </KTCardBody>
  )
}
export default Old_JobList
