import React from 'react';
import { Modal } from 'react-bootstrap';
import { Bars } from 'react-loader-spinner';

const EmailUpdate = ({ FilteredData }: any) => {
  return (
    <>
      {FilteredData?.emailUpdateShow ? (
        <Modal
          show={FilteredData?.emailUpdateShow}
          onHide={FilteredData?.handleClose}
          dialogClassName="modal-small"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Input for Email */}
            <div className="row ms-3 mb-3 mt-2 col-12">
              <input
                type="email"
                className="form-control me-2 border"
                placeholder="Enter Email Address..."
                style={{ height: '50px', width: '100%' }}
                onChange={(e) => FilteredData?.setEmail(e.target.value)}
                value={FilteredData?.email}
              />
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <button
                dir="rtl"
                className="btn btn-success mt-4 text-end"
                onClick={FilteredData?.editEmail}
                disabled={FilteredData?.disable || FilteredData?.loader}
              >
                {FilteredData?.loader ? (
                  <Bars
                    height="20"
                    width="20"
                    color="#00000"
                    ariaLabel="bars-loading"
                    visible={true}
                  />
                ) : (
                  'Update Email'
                )}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default EmailUpdate;
