import React from 'react'
import { ticketStatusToColor } from '../CommonData'

const TicketStatus = ({ ticketStatus, ticketStatusId, marginTops, setShow }: { ticketStatus: string, ticketStatusId: number | string | undefined, marginTops?: boolean | undefined | null, setShow?: any }) => {
    return (
        <span className={`badge badge-light-${ticketStatusToColor[ticketStatusId || 5]} cursor-pointer me-1 ${marginTops ? "" : "mt-2"} mb-2`} onClick={() => setShow ? setShow(true) : {}}>
            {ticketStatus ? ticketStatus : "No Ticket"}
        </span>
    )
}

export default TicketStatus
