import React, { useState } from 'react'
import { LocalBaseURL } from '../../../../../BaseURLmanagement';
import { GetAllImage } from '../API';
import { image_Cloudfront } from '../../../../consts/components/Utility';
interface Props {
    AttendanceID: any
    handleDiloagboxform: any
}
const ImageActionPopup = ({ AttendanceID, handleDiloagboxform }: Props) => {
    const userId = localStorage.getItem("user")
    const imageStartPoint = "https://dic554fk1zcxm.cloudfront.net/"
    const trainingBaseUrl = "http://162.55.63.127:3001/?url="
    LocalBaseURL()
    const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
    const [ImageArr, SetImageArray] = useState<any>([0, 1])
    React.useEffect(() => {
        async function getCityInvoked() {
            const response = await GetAllImage(localKeyCheck, AttendanceID)
            SetImageArray(response?.data?.data)
        }
        getCityInvoked()
    }, [])
    return (
        <div className="p-2">
            <h4 className='text-center'>Gallary : {AttendanceID}</h4>
            {ImageArr.length != 0 ? ImageArr?.map((image: any) => {
                return (
                    <div className="p-5 mr-auto">
                        <a style={{cursor: 'pointer', textAlign: 'right', marginBottom: '5px'}} href={trainingBaseUrl + `${image_Cloudfront}` + `${image.image}&userid=${userId}`} target='_blank'>
                            Train Model <i className="fa-solid fa-flask-vial text-primary"></i>
                        </a>
                        {image?.image && <img src={`${image_Cloudfront}` + `${image.image}`}
                            className="img-fluid" alt="Responsive image" />}
                    </div>
                )
            })
                : <div className="p-5 mr-auto">
                    {<img src="https://banksrl.co.za/wp-content/uploads/2019/10/NoProductImage_600.jpg"
                        className="img-fluid" alt="Responsive image" />}
                </div>
            }
    
        </div>
    )
}
export default ImageActionPopup
