import { useState } from "react";
import DeleteSubTable from "../../../../consts/SweetAlert/DeleteSubTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
export const columns = () => [

    {
        name: "DELETE",
        cell: (row: any) => <div className='d-flex g-4 flex-wrap'>
            <DeleteUserHash row={row} />

        </div>,
        sortField: 'id',
        sortable: true,
        width: "100px",
        id: 1,

    },
    {
        name: "NAME",
        cell: (row: any) => row?.name,
        sortField: 'name',
        sortable: true,
        minWidth: "150px",
        id: 2,

    },
    {
        name: "address",
        cell: (row: any) => row?.address,
        sortField: 'address',
        sortable: true,
        minWidth: "200px",
        id: 3,

    },


];

const DeleteUserHash = ({ row }: any) => {

    const [Renders, setRenders] = useState<any>(null)

    return (
        <>


            <FontAwesomeIcon icon={faDeleteLeft} className="text-danger fw-bold fs-4 cursor-pointer" onClick={() => setRenders(200)} />
            {
                Renders ? <DeleteSubTable
                    confirm='Yes'
                    cancel='No'
                    successTitle='Changes Are Saved Successfully '
                    FailedTitle='Changes Cancelled '
                    render={Renders}
                    payload={{
                        id: row?.id,
                    }}
                    setRenders={setRenders}

                ></DeleteSubTable>
                    : <></>}

        </>
    )
}
