import React, { useState } from 'react'
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import CallWaiting from '../SweetAlert/CallWaiting';

const Calling = ({ title, row_id, row, type, From, isOpen = false, source = "", toCreateTicketForNewListApi, trackData , totalRowDataForChampDashboard }: any) => {

    const [message, setMessage] = useState("Pls wait call connecting")
    const [Re_Render, setRe_Render] = useState<any>(null)
    const [Hide, setHide] = useState<any>(null)
    const [icons, setIcons] = useState<any>("")

    const callToTargetUser = async (type: any, target_id: any) => {
        setHide(20000)
        setRe_Render(Math.random())
        const paylaod: any = { userid: JSON.parse(localStorage.getItem("user") || "0") }
        paylaod[type] = target_id
        setTimeout(async () => {
            const response = await postRequest(URL_EndPoints()?.clickToCallFromTicket, paylaod, null)
            if (response?.data?.status === 200) {
                setMessage("Call connected successfully")
                setIcons("success")
            }
            else {
                setMessage("Call not initiated ")
            }
        }, 3000)
    }

    let res: any = <>
        <From title={title} row_id={row_id} type={type} row={row} callToTargetUser={callToTargetUser} isOpen={isOpen} toCreateTicketForNewListApi={toCreateTicketForNewListApi} trackData={trackData} totalRowDataForChampDashboard={totalRowDataForChampDashboard} />
        {
            Hide === 20000 && <CallWaiting Render={Re_Render} Hide={Hide} message={message} icons={icons} setIcons={setIcons} setMessage={setMessage} setRe_Render={setRe_Render} />
        }
    </>
    return res
}

export default Calling


// <div className='d-flex flex-row'>
//             <div>
//                 {
//                     type == 'cleanerid' &&
//                     <OpenChat title={row?.first_name + " " + row?.last_name} champId={row?.id} />
//                 }
//             </div>

//             <div className='call-button' onClick={() => CallCleaner(type, row.id)}>
//                 <KTSVG path='/media/icons/duotune/Info/call002.svg' className='svg-icon-2 ml-1' />
//             </div>

//             {
//                 Hide === 20000 && <CallWaiting Render={Re_Render} Hide={Hide} message={message} icons={icons} setIcons={setIcons} setMessage={setMessage} setRe_Render={setRe_Render} />
//             }

//         </div>