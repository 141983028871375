import moment from 'moment';
import DeleteOfferListItem from '../../SweetAlert/DeleteOfferListItem';
import { useState } from 'react';
import { TempsDateFormate } from '../../toggleColumns/TempsDateFormate';
import { adminMenuActionPermissionKeys } from '../../../modules/admins/adminConstant';
import PermissionContextProvider from '../../../modules/admins/adminComponents/PermissionContextProvider';

const CustomColumnHeader = () => (
    <div>
        DISCOUNTED<br />
        AMOUNT
    </div>
);

export const offers_columns: any = () => [
    {
        name: 'Action',
        cell: (row: any) => (
            <DeleteOffer row={row} />
        ),
        sortable: true,
        id: "css"
    },

    {
        name: <div>COUPON<br />CODE</div>,
        selector: (row: any) => row.coupon,
        sortable: true,
    },

    {
        name: <div>AMOUNT</div>,
        selector: (row: any) => row?.masterOffer?.amount + (row?.masterOffer?.type == "percentage" ? " %" : " FLAT"),
        sortable: true,
    },

    {
        name: <div>COUPON<br />STATUS</div>,
        selector: (row: any) => row.is_applied === 1 ? <span className="badge badge-success">Applied</span> : <span className="badge badge-danger">Not Applied</span>,
        sortable: true,
        width: "150px"
    },
    {
        name: 'STATUS',
        selector: (row: any) => row.status == 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">InActive</span>,
        sortable: true,
    },
    {
        name: <CustomColumnHeader />,
        selector: (row: any) => row.discounted_amount,
        sortable: true,
        width: "100px"
    },
    {
        name: <div>CREATED<br /> BY USER</div>,
        selector: (row: any) => row?.masterOffer?.createdUser?.first_name + ' ' + row?.masterOffer?.createdUser?.last_name,
        sortable: true,
        width: "100px"
    },
    {
        name: <div>COUPON<br />CREATEDAT</div>,
        selector: (row: any) => TempsDateFormate(row?.createdAt, true),
        // selector: (row: any) => TempsDateFormate(row?.createdAt) moment(row?.createdAt).format("Do MMMM YYYY HH:mm:ss"),
        sortable: true,
        width: "200px"

    },
    {
        name: <div>APPLIED<br />DATE</div>,
        selector: (row: any) => row?.applied_datetime ? TempsDateFormate(row?.applied_datetime, true) : "NA",
        // selector: (row: any) => row?.applied_datetime ? moment(row?.applied_datetime).format("Do MMMM YYYY HH:mm:ss") : "NA",
        sortable: true,
        width: "200px"
    },
];



const DeleteOffer = ({ row }: any) => {

    const [openDeletePopup, setOpenDeletePopup] = useState<any>(null)

    return (
        <>

            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["offer-list-delete"]}>
                <button className='btn btn-sm btn-danger px-2 text-white' onClick={() => setOpenDeletePopup(Math.random())}>Delete</button>
            </PermissionContextProvider>


            {
                openDeletePopup && <DeleteOfferListItem
                    confirm='Yes'
                    cancel='No'
                    setOpenDeletePopup={setOpenDeletePopup}
                    payloads={{
                        render: openDeletePopup,
                        payloadData: {
                            ctOfferId: row?.id,
                        },

                        successTitle: 'Changes Are Saved Successfully ',
                        FailedTitle: 'Changes Cancelled ',
                    }}

                ></DeleteOfferListItem>
            }
        </>
    )
}

export default DeleteOffer




