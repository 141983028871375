import moment from "moment"

/**
 * 
 * @param timestamp Identifies date from this time
 * @returns Relative time. E.g. 3 days ago
 */
const getRelativeTime = (timestamp: number) => {
    return moment(timestamp).fromNow()
}

export {
    getRelativeTime,
}