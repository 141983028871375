import { postRequest } from '../../app/consts/Server/Requests';
import { URL_EndPoints } from '../../app/consts/Server/URL_EndPoints';
import { LOADING, SUCCESS, FAILURE, CHAMPS_CHAT_MESSAGES, CHAMPS_ALL_DATA, CHAMPS_WHATSAPP_MESSAGES, CHAMPS_WHATSAPP_TEMPLATE_MESSAGES, CHAT_SOURCE, CHAT_LOADER, CHAT_SUCCESS, CHAT_FAILURE, TRAINING_TASK_LISTS, WHATS_APP_LOADER, WHATS_APP_SUCCESS, WHATS_APP_FAILURE } from './champsTypes'


export const openChatByChamp = (payloads: any, key:any) => async (dispatch: any) => {

    try {
        dispatch({ type: LOADING, payload: true })
        const response = await postRequest(URL_EndPoints()?.getChampAllDataByChampId, payloads, null)
        if (response?.data?.status == 200) {
            dispatch({ type: CHAMPS_ALL_DATA, payload: {key:key, response:response?.data} })
            dispatch({ type: SUCCESS, payload: false })
        }

    } catch (error) {
        dispatch({ type: FAILURE, payload: false })


    }
}


export const openChatByMessage = (payloads: any,key:any) => async (dispatch: any) => {
    try {
        
        dispatch({ type: CHAT_LOADER, payload: true })
        const response = await postRequest(URL_EndPoints()?.getCleanerTicketReplies, payloads, null)
        if (response?.data?.status == 200) {
            
            dispatch({ type: CHAMPS_CHAT_MESSAGES, payload: {key:key,response: response?.data} })
            dispatch({ type: CHAT_SUCCESS, payload: false })
        }

    } catch (error) {
        dispatch({ type: CHAT_FAILURE, payload: false })


    }
}


export const openChatByWhatsapp = (payloads: any , key:any) => async (dispatch: any) => {
    try {
        dispatch({ type: WHATS_APP_LOADER, payload: true })
        const response = await postRequest(URL_EndPoints()?.getLatestChampWhatsAppMessageByNumber, payloads, null)
        if (response?.data?.status == 200) {
            dispatch({ type: CHAMPS_WHATSAPP_MESSAGES, payload: {key:key, response:response?.data?.data?.messages?.items} })
            dispatch({ type: WHATS_APP_SUCCESS, payload: false })

        }
       

    } catch (error) {
        
        dispatch({ type: WHATS_APP_FAILURE, payload: false })


    }
}
export const getWhatsappTemplate = (key:any) => async (dispatch: any) => {
    try {
        const response2 = await postRequest(URL_EndPoints()?.get_WhatsAppTemplate, {wa_number_id:2}, null)
   
        if (response2?.data?.status == 200) {
            dispatch({ type: CHAMPS_WHATSAPP_TEMPLATE_MESSAGES, payload:{ key:key , response : response2?.data?.data} })
        }

    } catch (error) {
        


    }
}

export const trainingTaskLists = (payloads: any, key:any) => async (dispatch: any) => {
    try {
        const response = await postRequest(URL_EndPoints()?.getChampLeadTrainingTaskList, payloads, null)
        if (response?.data?.status == 200) {
            dispatch({ type: TRAINING_TASK_LISTS, payload: {key:key, response:response?.data?.data}})
        }

    } catch (error) {
        
    }
}



export const openChatBySource = (payloads: any) => {

    return { type: CHAT_SOURCE, payload: payloads }


}

