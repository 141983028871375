import { faAngleDoubleDown, faAngleDoubleRight, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
export const AssigneeDetails = ({ row, name, nameArray, keys = [] }: any) => {
    const [toggle, setToggle] = useState(false);
    return (
        <div className='d-flex justify-content-start align-items-start flex-wrap'>
            <FontAwesomeIcon icon={toggle ? faAngleDoubleDown : faAngleDoubleRight} className='me-2 text-dark cursor-pointer mt-1 fw-bold' onClick={() => setToggle((prev) => !prev)} />
            {
                !toggle ? (
                    name
                ) : (
                    <div className="d-flex flex-column">
                        {
                            nameArray.length > 0
                                ? nameArray?.map((ele: any, index: any) => {
                                    let singleName = keys?.map((key: any) => ele[key])?.join(" ")
                                    return <li key={index} className='me-1'>  {singleName}</li>
                                })
                                : "Not Set"
                        }
                    </div>
                )
            }
        </div>
    );
};