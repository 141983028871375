import { useEffect, useState } from 'react';
import { Button, Col,  Form, Modal, Row, Spinner,} from 'react-bootstrap';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminToastRunner } from '../../admins/adminUtils';

const CreateUpdateWarehouseAlert = ({ show, refreshNow, setShow, setData, initialValue, setInitialValue, apiUrl }: any) => {
    const [loading, setLoading] = useState(false);
    const [warehouseListData, setWareListData] = useState([]);
    const [inventoryListItem, setInventoryListItem] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [getWarehouseListing, getAllInventoryItems] = await Promise.all([
                    getRequest(URL_EndPoints()?.getWarehouseListing, null),
                    getRequest(URL_EndPoints()?.getAllInventoryItems, null),
                ]);
                setWareListData(getWarehouseListing?.data?.data);
                setInventoryListItem(getAllInventoryItems?.data?.data);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchData();
        }
    }, [show]);

    const handleInputChange = (e: any) => {
        setInitialValue({
            ...initialValue,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const response = await postRequest(URL_EndPoints()?.[apiUrl], initialValue, setLoading)
        adminToastRunner(response)
        if (response?.data?.data?.items) {
            setData(response?.data?.data?.items);
        }

        refreshNow && refreshNow();
        setShow && setShow(false);
    };

    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{initialValue?.id ? "Edit" : "Add"} Warehouse Alert Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="warehouse_id">
                                <Form.Label><strong>Select Warehouse</strong></Form.Label>

                                <Form.Control
                                    as="select"
                                    name="warehouse_id"
                                    value={initialValue["warehouse_id"]}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Warehouse</option>
                                    {warehouseListData?.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.warehouse_name ? item.warehouse_name : `${(item.warehouse_name)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="item_id">
                                <Form.Label><strong>Select Inventory Item</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="item_id"
                                    value={initialValue["item_id"]}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Inventory Item</option>
                                    {inventoryListItem?.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.title ? item.title : `${(item.title)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} className="mb-3" controlId="reminder_stock">
                                <Form.Label><strong>Reminder Stocks</strong></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Reminder Stocks"
                                    name="reminder_stock"
                                    value={initialValue['reminder_stock']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="alert_stock">
                                <Form.Label><strong>Alert Stocks</strong></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Alert Stocks"
                                    name="alert_stock"
                                    value={initialValue['alert_stock']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                        </Row>
                    
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CreateUpdateWarehouseAlert;
