
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import { renderToString } from 'react-dom/server';
import Handsontable from 'handsontable';
import { colWidthsHotTable, getFilterSelectedRowsData, handleAfterSelectionRow, hotInstanceLoader, rocordTotalFound, searchFieldKeyupCallback, cellRenderInner, getFilteredRowDataNew2 } from '../admins/adminUtils';

import { toast } from 'react-toastify';
import moment from 'moment';
import MomentDate from '../../consts/MomentDate';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu';
import TicketCategoryFilter from './ticketComponents/TicketCategoryFilter';
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import ShowTicketCountChartModal from './ShowTicketCountChartModal';
import { BulkUtilButton } from '../../consts/reuseModules/BulkUtilButton';
import ShowFilterHint from './ticketComponents/ShowFilterHint';
import ExcelExportButton2 from '../../consts/ExcelExportButton2';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { KTCardBody } from '../../../_metronic/helpers';
import { Tooltip as ReactTooltip } from "react-tooltip";
import SubCategoryListModal from './SubCategoryListModal';

registerAllModules();
const intervalType = [{ name: "Daily", id: "daily" }, { name: "Weekly", id: "weekly" }, { name: "Monthly", id: "monthly" }]

const listConfig: any = {
  category: {
    list_name: "Ticket Count Category Wise",
    list_api: "getTicketsCountBasedOnCategory",
    list_chart_title: "Show Category Chart",
    keyName: "category",
    subCategory: true
  },

  source: {
    list_name: "Ticket Count Source Wise",
    list_api: "getTicketsCountBasedOnSource",
    list_chart_title: "Show Source Chart",
    keyName: "source",
    subCategory: false
  }
}

function convertData(chartData: any, keyName: any) {
  let graphData: any = []
  if (chartData?.includes(undefined)) {
    graphData = chartData?.filter((ele: any) => ele != undefined)
  } else {
    graphData = chartData
  }

  let kyes = Object.keys(graphData[0] || {})
  return {
    categories: kyes,
    series: graphData?.map((element: any) => {
      let keys = Object.keys(element)
      return {
        name: element[keyName],
        data: keys.map((item: any) => element[item])
      }
    })
  }
}

export const TicketCountList = () => {
  const hotTableComponentRef = useRef<any>(null);
  const [search, setSearch] = useState<any>("")
  const [pending, setPending] = useState(false)
  const [filterData, setFilterData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [chartData, setChartData] = useState([]);
  const [payload, setPayload] = useState<any>({
    start_date: moment().startOf("month").format("YYYY-MM-DD"),
    end_date: MomentDate(0),
    type: "daily",
    list_type: "category",
  })

  async function getTicketCountData4List() {
    let newPayload = JSON.parse(JSON.stringify(payload))
    delete newPayload["list_type"]
    if (payload?.list_type) {
      setToggleClearRows(!toggledClearRows)
      setFilteredSelectedRows([])
      setPending(true)
      const ticketCountData = await postRequest(URL_EndPoints(null)?.[listConfig[payload?.list_type]?.list_api], newPayload, setPending)
      setColumns(ticketCountData?.data?.columns)
      setFilterData(ticketCountData?.data?.data)
      setData(ticketCountData?.data?.data)
    } else {
      toast.error("List Type Field Is Required", { position: "top-center" })
    }
  }

  useEffect(() => {
    getTicketCountData4List()
  }, [])

  let firstTimeNotRun = useRef<any>(false)
  useEffect(() => {
    let x = setTimeout(() => {
      const filterSearchData = AllFielSearch(data, search)
      firstTimeNotRun?.current === true && setFilterData(filterSearchData)
      if (firstTimeNotRun?.current === false) {
        firstTimeNotRun.current = true
      }
    }, 2000)
    return () => {
      clearTimeout(x)
    }
  }, [search])

  const searchHandler = () => {
    getTicketCountData4List()
  }

  const onChangeHandler = (event: any, name: any) => {
    setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
  }


  const removeUnderScore = (str: any) => {
    if (str?.includes("_")) {
      return str?.split("_")?.join(" ")?.toUpperCase()
    }
    else {
      return str?.toUpperCase()
    }
  }

  const [showChart, setShowChart] = useState(false)
  const [showSubCategoryModal, setShowSubCategoryModal] = useState(false)

  const showChartHandler = (value: boolean) => {
    if (filteredSelectedRows?.length == 0) {
      toast.error("⚠️ Please select some rows in bulk to see chart", { position: "top-center" })
      return
    }
    setShowChart(value)
  }
  const showSubCategory = () => {
    if (filteredSelectedRows?.length == 1) {
      setShowSubCategoryModal(true)
      return
    }
    toast.error("⚠️ Plese Select Single Row Only ", { position: "top-center" })
  }

  // hot table code ------------------------------------
  useEffect(() => {
    hotInstanceLoader(hotTableComponentRef)
  }, []);

  const cellRenderer = (...args: any) => {
    cellRenderInner(args, Handsontable)
  }

  const shortWords = (sentence: any, index: any) => {
    if (index < 1) { return removeUnderScore(sentence) }
    else if (index == 1 || sentence?.includes("_")){ removeUnderScore(sentence) } 
    return removeUnderScore(sentence?.split(' ')[1])
  }

  const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
    handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
  };

  // const handleAfterDeselect = () => {
  //   setFilteredSelectedRows([]);
  // };

  // useEffect(() => {
  //   let clearTimeoutFunction = getFilterSelectedRowsData(filteredSelectedRows, filterData, setChartData)
  //   return () => {
  //     clearTimeout(clearTimeoutFunction)
  //   }
  // }, [filteredSelectedRows])

  return (
    <>
      <KTCardBody>
        <div className='mb-4 fs-4 fw-semibold'>{listConfig[payload?.list_type]?.list_name ?? "Select List Type"}</div>
        <MainContainerWrapper>
          <LeftBox>
            <SearchBoxModal search={search} setSearch={setSearch} />
          </LeftBox>
          <RightBox>
            <Refresh refreshNow={searchHandler} />
            <ExcelExportButton2 tableName=".ticketCountCategoryList" />
            {
              listConfig[payload?.list_type]?.subCategory && <BulkUtilButton title="Sub Category List" filteredSelectedRows="static" setShow={showSubCategory} />
            }
            <BulkUtilButton title={listConfig[payload?.list_type]?.list_chart_title ?? "Select List Type"} filteredSelectedRows="static" setShow={showChartHandler} />
            {
              <DropDownFilterMenu
                ParentData={{
                  handleActions: searchHandler,
                  payloads: payload,
                  onChangeHandler: onChangeHandler,
                  intervalType: intervalType,
                  defaultInterval: { label: "Daily", value: "daily" },
                  defaultListType: { label: "Category", value: "category" },
                  showListType: true
                }}
                Component={TicketCategoryFilter}
              ></DropDownFilterMenu>
            }
          </RightBox>
        </MainContainerWrapper>
        {/* hint components */}
        <ShowFilterHint payload={payload} />

        {pending ? <IsLoading /> : <div >

          <HotTable
            ref={hotTableComponentRef}
            style={{ zIndex: 1 }}
            data={filterData}
            columns={columns?.map((ele: any, index: any) => {
              return {
                title: renderToString(<div id={ele} >{shortWords(ele, index)} </div>),
                type: 'text',
                data: ele,
                renderer: cellRenderer
              }
            })}
            className='ticketCountCategoryList'
            filters={true}
            dropdownMenu={true}
            colWidths={colWidthsHotTable(columns, 1, 150, 55)}
            width="100%"
            height={"80vh"}
            title='Ticket Count Category Wise'
            rowHeaders={true}
            colHeaders={true}
            fixedColumnsLeft={1}
            contextMenu={true}
            manualColumnFreeze={true}
            autoWrapRow={true}
            autoWrapCol={true}
            licenseKey="non-commercial-and-evaluation"
            autoRowSize={true}
            autoColumnSize={true}
            readOnly={true}
            manualRowResize={true}
            manualColumnResize={true}
            manualRowMove={true}
            multiColumnSorting={true}
            search={{ callback: searchFieldKeyupCallback }}
            afterSelection={handleAfterSelection}
            // afterDeselect={handleAfterDeselect}
          />
        </div>}

      </KTCardBody>

      {columns?.map((ele: any) => (
        <ReactTooltip
          anchorId={ele}
          place={"top"}
          className="bg-dark"
          content={ele}
        />
      ))}

      {
        showChart ? <ShowTicketCountChartModal
          chartData={convertData(getFilteredRowDataNew2(filteredSelectedRows, filterData), listConfig[payload?.list_type]?.keyName)}
          show={showChart}
          setShow={setShowChart}
          payload={payload}
          title={listConfig[payload?.list_type]?.list_name ?? "Select List Type"}
        /> : <></>
      }

      {
        showSubCategoryModal ? <SubCategoryListModal
          show={showSubCategoryModal}
          setShow={setShowSubCategoryModal}
          intervalType={intervalType}
          filteredSelectedRows={getFilteredRowDataNew2(filteredSelectedRows, filterData)}
        /> : <></>
      }
    </>
  );
};

