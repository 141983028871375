import moment from 'moment'
export const GoodItemListColumn = () => [
    {
        name: 'Batch Number',
        cell: (row: any) => row?.batch_number,
        sortable: true,
        reorder: true,
        id: 2,
        width: '120px',
    },
    {
        name: 'Item Id',
        cell: (row: any) => row?.item_id,
        sortable: true,
        reorder: true,
        id: 2,
        width: '120px',
    },
    {
        name: 'Media',
        cell: (row: any) => <img src={row?.goodsReceiptItemsList?.media} alt="" style={{ width: "50px", height: "50px" }} />,
        reorder: true,
        id: 3,
        width: '200px',
        sortable: true
    },
    {
        name: 'Description',
        cell: (row: any) => row?.goodsReceiptItemsList?.description,
        reorder: true,
        id: 3,
        width: '200px',
        sortable: true
    },
    {
        name: 'Total Price',
        cell: (row: any) => row?.total_price,
        reorder: true,
        id: 3,
        width: '120px',
        sortable: true
    },
    {
        name: 'Quantity Ordered',
        cell: (row: any) => row?.quantity_ordered,
        reorder: true,
        id: 3,
        width: '120px',
        sortable: true
    },
    {
        name: 'Quantity Received',
        cell: (row: any) => row?.quantity_received,
        reorder: true,
        id: 3,
        width: '120px',
        sortable: true
    },
    {
        name: 'Expiry Date',
        cell: (row: any) => row?.expiry_date,
        reorder: true,
        id: 3,
        width: '120px',
        sortable: true
    },
    {
        name: 'Unit Price',
        cell: (row: any) => row?.unit_price,
        reorder: true,
        id: 3,
        width: '120px',
        sortable: true
    },
    {
        name: 'Updated At',
        cell: (row: any) => moment(row?.updatedAt).format("DD-MM-YYYY"),
        reorder: true,
        id: 3,
        width: '120px',
        sortable: true
    },
    {
        name: 'Created At',
        cell: (row: any) => moment(row?.createdAt).format("DD-MM-YYYY"),
        reorder: true,
        id: 3,
        width: '120px',
        sortable: true
    },
]
