import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postRequest } from '../../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit } from '@fortawesome/free-solid-svg-icons';
import SingleSelectSearchDetailsDefault from '../../../../consts/Select/SingleSelectSearchDetailsDefault';


const UpdateAreaManager = ({ row }: any) => {

    const isVacancyAssigned = row?.champ_requirement_completed_champ?.first_name + row?.champ_requirement_completed_champ?.last_name

    const supervisorListData = useSelector((store: any) => store.StateMangenementCenter.supervisorListData)

    const [show, setShow] = useState<any>(false)
    const [loader, setLoader] = useState<any>(false)
    const [defaultValue, setDefaultValue] = useState<any>(null)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [payload, setPayload] = useState<any>({
        "id": row?.id,
        "updateFields": {
            assigned_supervisor: null
        }
    })

    useEffect(() => { setStoreRow(row) }, [row])

    useEffect(() => {
        const updatedData = supervisorListData?.find((supers: any) => supers?.id == storeRow?.champ_requirement_assigned_supervisor?.id)
        setDefaultValue(updatedData ? updatedData : null)
        updatedData && setPayload({ ...payload, ["updateFields"]: { ...payload?.updateFields, ["assigned_supervisor"]: updatedData?.id } })

    }, [supervisorListData, storeRow?.requirementType?.name])



    const updateRequirementType = async () => {

        if (payload?.updateFields?.assigned_supervisor) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints(null)?.updateChampRequirement, payload, setLoader)
            if (response?.data?.status === 200) {
                const updatedData = supervisorListData?.find((type: any) => type?.id == payload?.updateFields?.assigned_supervisor)
                row.champ_requirement_assigned_supervisor = row.champ_requirement_assigned_supervisor ? { ...row.champ_requirement_assigned_supervisor } : {}
                row.champ_requirement_assigned_supervisor.first_name = updatedData?.first_name
                row.champ_requirement_assigned_supervisor.last_name = updatedData?.last_name
                row.champ_requirement_assigned_supervisor.phone = updatedData?.phone
               
                setStoreRow(row)
                toast.success(response?.data?.msg, { position: "top-center" })
                setShow(false)

            } else {

                toast.error(response?.data?.msg ? response?.data?.msg : "Seems server side problem", { position: "top-center" })
                setShow(false)


            }
            setLoader(false)
        }
        else {
            toast.error("Please select location ", { position: "top-center" })
            setLoader(false)

        }
    }


    const OnchangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, ["updateFields"]: { ...payload?.updateFields, [name]: event ? event?.value : null } })
    }


    const modalHandler = (isVacancyFilledBy: any) => {

        // if (isVacancyFilledBy) {
        //     toast.success("Vacancy already filled so action is not allowed !", { position: "top-center" })
        //     return
        // }
        setShow(true)

    }


    return (

        <>
            {
                loader ? "Updating..." : <>
                    <FontAwesomeIcon icon={ faEdit} className={` me-2  cursor-pointer text-${ "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} />
                    {/* <FontAwesomeIcon icon={isVacancyAssigned ? faBan : faEdit} className={` me-2  cursor-pointer text-${isVacancyAssigned ? "danger" : "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} /> */}
                    <span>{storeRow?.champ_requirement_assigned_supervisor ? storeRow?.champ_requirement_assigned_supervisor?.first_name + " " + storeRow?.champ_requirement_assigned_supervisor?.last_name : "NA"}</span>
                </>
            }

            <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-small">
                <Modal.Header closeButton>
                    <Modal.Title>Update Area Manager  </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-3">
                        <div className="col-12 mb-2">

                            <SingleSelectSearchDetailsDefault
                                handleChangeInputData={OnchangeHandler}
                                HeaderTitle='Select Area Manager'
                                SelectData={supervisorListData}
                                DynamicKey={'first_name'}
                                DynamicKey2={'last_name'}
                                DynamicKey3={'phone'}
                                id={'id'}
                                name='assigned_supervisor'
                                defaultData={defaultValue ? { label: defaultValue?.first_name + defaultValue?.last_name + defaultValue?.phone  , value: defaultValue?.id, item: defaultValue } : null}

                            ></SingleSelectSearchDetailsDefault>
                        </div>

                    </div>


                    <div className='d-flex flex-end justify-content-end col-12'>
                        <button
                            className={`btn btn-sm text-${loader ? "dark" : "white"} btn-${loader ? "success" : "primary"} mb-5 `}
                            onClick={updateRequirementType}
                            disabled={loader}
                        >
                            {loader ? "Updating..." : "Update"}

                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        </>








    )
}
export default UpdateAreaManager
