import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import CategoryPage from './CategoryPage';
import FaqNavbar from './FaqNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { getRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { adminQueryGenerator } from '../../modules/admins/adminUtils';
import IsLoading from '../IsLoading/IsLoading';
import { AdminToolTip } from '../../modules/admins/adminComponents/AdminToolTipModal';
import { useSelector } from 'react-redux';

const config = {
    readonly: true,
    toolbar: false
};

const ListDetailPage = () => {
    const { category, id } = useParams()
    const [faqData, setFaqData] = useState<any>({})
    let author = faqData?.faq_author ? faqData?.faq_author?.first_name + " " + faqData?.faq_author?.last_name : "No Author"

    const [pending, setPending] = useState<any>(false)
    let isResolved = faqData?.status == 1 ? faCircleCheck : faExclamationCircle
    let isResolvedCss = faqData?.status == 1 ? "success" : "warning"
    let tooltipTitle = faqData?.status == 1 ? "Resolved" : "Not Resolved"
    const faqPrevCategoryPayload = useSelector((store:any)=>store.faqReducer.faqPrevCategoryPayload)

    async function getFaqsById(setPending: any) {
        setPending(true)
        const getFaqsData = await getRequest(URL_EndPoints(adminQueryGenerator("getFAQById", { id: id }))?.getFAQById, setPending)
        setFaqData(getFaqsData?.data?.data)
    }

    useEffect(() => {
        getFaqsById(setPending)
    }, [id])

    return (

        <div className='row'>
            <CategoryPage selectedCategory={faqData?.faq_category_details} isRedirect={true} faqPrevCategoryPayload={faqPrevCategoryPayload} />
            <div className='col-lg-9 col-md-9 col-md-order-1 col-sm-order-1 col-xl-9 '>
                <FaqNavbar />
                <div className='w-100'>
                    <div className={`mb-2 mb-xl-2 p-4`}>
                        {
                            pending
                                ?
                                <IsLoading />
                                :
                                <div className='card-body pb-0'>

                                    <div className='d-flex align-items-center mb-2'>
                                        <div className='d-flex align-items-center flex-grow-1'>
                                            <div className='d-flex flex-column col-11 text-justify'>
                                                <h2 className='text-dark fw-semibold'>{faqData?.question}</h2>
                                            </div>
                                            <div className='d-flex align-items-center col-1  '>
                                                <span id={faqData?.id}> <FontAwesomeIcon icon={isResolved} className={`cursor-pointer me-2 fs-3 text-${isResolvedCss}`} /></span>
                                                <AdminToolTip anchorId={faqData?.id} title={tooltipTitle} place={""} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mb-5'>
                                        <div
                                            className="html-content text-gray-800 fw-normal mb-5 text-justify"
                                            dangerouslySetInnerHTML={{ __html: faqData?.answer }}
                                        />
                                    </div>
                                    {/* <div className='separator mb-4'></div> */}
                                    <div className='position-relative mb-5'>

                                        <div className='symbol symbol-35px me-5 d-flex mt-2'>
                                            <img src="https://play-lh.googleusercontent.com/uRbzB870ArRyc8sem-wryLDtA9ltgnEZjdhCKauUmLqptYQsOx8o09XMMseeFTidMa5N" alt='' className='me-2' />

                                            <div className='d-flex flex-column ' >
                                                <div className='d-flex'>
                                                    <span className='fw-bold me-2'>Carselona</span>
                                                    <span className='badge badge-light-success text-gray'>{author}</span>
                                                </div>
                                                <p className='text-muted fw-bold'>{moment(faqData?.createdAt).fromNow()}</p>
                                            </div>
                                        </div>

                                        <div className='position-absolute top-0 end-0 me-n5 mt-2 d-flex align-items-center '>
                                            {
                                                faqData?.tags && faqData?.tags?.split(",")?.map((tags: any) => {
                                                    return <span className='me-2 p-2 px-4 border round-0 bg-white rounded  text-muted'>
                                                        {tags}
                                                    </span>
                                                })
                                            }
                                            {/* {!faqData?.tags && "NO TAGS"} */}
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ListDetailPage
