
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import Not_Found from '../../../consts/Not_Found'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import "./Cleaner.css"
import { toast } from 'react-toastify'
import ChangeLocationOnMapCleaner from '../../../consts/SweetAlert/ChangeLocationOnMapCleaner'
import { image_Cloudfront } from '../../../consts/components/Utility'
import { FetchAddressPincodeByLatLngUtils } from '../../../consts/googleMapUtility/FetchAddressPincodeByLatLngUtils'
import MapSingleSelect from '../../../consts/Select/MapSingleSelect'
import MapMultiSelect from '../../../consts/Select/MapMultiSelect'
import SearchAreaOnMap from '../CompaanyCleanersMarker/SearchAreaOnMap'

const CompanyCustomerMarker = ({ cityId = null, cleanerID = null, refereshID, champsDetails, from = "" }: any) => {
  const [cityData, setCityData] = useState([])
  const [circleChamps, setCircleChamps] = useState([])
  const [champData, setchampData] = useState<any>([])
  const [remainingcustomers, setRemainingcustomers] = useState<any>([])
  const [notassignedlist, setNotassignedlist] = useState<any>([])
  const [pendingcleaner, setpendingcleaner] = useState<any>([])
  const [updateLocationOpenModal, setUpdateLocationOpenModal] = useState<any>(null)
  const [updateLocationPayload, setUpdateLocationPayload] = useState<any>({})
  const [address, setAddress] = useState<any>({})
  const [pincode, setPincode] = useState<any>({})
  const [cleanerLists, setCleanerLists] = React.useState<any>([])
  const handleClose = () => setShow(false);
  const [centerLatLong, setCenterLatLong] = useState({
    lat: 12.9121181,
    lng: 77.6445548,
  })
  const [newLatLngLocation, setNewLatLngLocation] = useState<any>({
    lat: null,
    lng: null,
  })

  const [markerPositionLatLng, setMarkerPositionLatLng] = useState<any>(null)
  const [customAddressSearch, setCustomAddressSearch] = useState("")

  useEffect(() => {
    markerPositionLatLng?.lat && setCenterLatLong(
      {
        lat: markerPositionLatLng?.lat,
        lng: markerPositionLatLng?.lng,
      }
    )
  }, [markerPositionLatLng?.lat, markerPositionLatLng?.lng])

  const center = {
    lat: 12.9121181,
    lng: 77.6445548,
  };

  useEffect(() => {
    async function fetcheAddress() {
      const isFetch = await FetchAddressPincodeByLatLngUtils(+newLatLngLocation?.lat, +newLatLngLocation?.lng, setAddress, setPincode, {}, () => { });
      if (isFetch) {
        setUpdateLocationOpenModal(Math.random())
      }
      else {
        toast.error("Fetch Address , Pincode throwing error ")
      }
    }
    newLatLngLocation?.lat && newLatLngLocation?.lng && fetcheAddress()
  }, [newLatLngLocation])

  const [cityid, setcityid] = useState<any>(6)
  const [Loader, setLoader] = useState<any>(false)
  const [radius, setRadius] = useState<any>(2 * 1000)
  const [show, setShow] = useState(false);
  const [cleanerId, setCleanerId] = useState<any>([]);
  const [isFoundDeactivateChamp, setIsFoundDeactivateChamp] = useState<any>(true);

  const getChampDetailsForOnlyDeactivatedList = async () => {
    try {
      setLoader(true)
      const getChampDataDeactivated = await postRequest(URL_EndPoints()?.getDeactivatedChampAddressDetail, { champId: cleanerID }, setLoader)
      setchampData([getChampDataDeactivated?.data?.data] || [])
      if (getChampDataDeactivated?.data?.status == 200) {
        setIsFoundDeactivateChamp(true)
      }
      else {
        setchampData([])
        setIsFoundDeactivateChamp(false)
      }
    } catch (error) {
      console.log('error', error);
      setIsFoundDeactivateChamp(false)
    }

  }

  useEffect(() => {
    cityid != 0 && getChampDetailsForOnlyDeactivatedList()
  }, [cityid])

  async function loadChampData(cityId: any, cleanerID: any) {
    setCenterLatLong({ lat: 12.9121181, lng: 77.6445548, })
    const formData = new FormData();
    formData.append("cityid", cityId ?? cityid)
    formData.append("cleanerids", JSON.stringify(cleanerID?.length > 0 ? cleanerID : cleanerId))
    formData.append("cleanerORcustomer", "1")
    if (radius) {
      setLoader(true)
      const result = await axios.post("https://api.carselonadaily.com/api/admin/getCleanersCustomerLocation",
        formData
      ).then((res: any) => {
        return res
      })
      setLoader(false)
      setchampData(result?.data?.data)
      setRemainingcustomers(result?.data?.remainingcustomers)
      setNotassignedlist(result?.data?.notassignedlist)
      setpendingcleaner(result?.data?.pendingcleaner)
    }
    else {
      toast.error("Set Radius first", { position: "top-center" })
    }
  }

  useEffect(() => {
    cityId && setcityid(cityId ?? 0)
    cleanerID && setCleanerId(cleanerID ? [cleanerID] : [])
    cityId && cleanerID && isFoundDeactivateChamp == false && loadChampData(cityId, [cleanerID])
  }, [cityId, cleanerID, refereshID, isFoundDeactivateChamp])


  async function loadInitialData() {
    const result = await getRequest(URL_EndPoints()?.getcity, null)
    setCityData(result?.data?.data)
    const CleanerLists = await getRequest(URL_EndPoints(null)?.cleanerlist, null)
    setCleanerLists(CleanerLists?.data?.data)
  }
  useEffect(() => {
    loadInitialData()
  }, [])


  const handleSearch = () => {
    if (cityid == 0) {
      alert("Please select city!!")
      return false
    }
    cityid != 0 && loadChampData(null, null)
  }

  const cityChangeHandler = (event: any, name: any) => {
    if (event !== null) {
      setcityid(event?.value)
    }
    else {
      setcityid(null)
    }
  }

  let brnchUrl = `${image_Cloudfront}351f2fa6-07b0-4668-9469-dac59255d3d0.png`
  const handleChangeCleaner = (event: any) => {
    event?.length > 0 ? setCleanerId(event) : setCleanerId([])
  }

  const notAssginedListMarkerMaping = (map: any, allMarkers: any, activeInfoWindow: any,) => {
    notassignedlist.forEach((markerInfo: any) => {
      const smallMarkerIcon = {
        url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
        scaledSize: new window.google.maps.Size(20, 20),
      };

      const marker = new window.google.maps.Marker({
        position: {
          lat: +markerInfo?.latitude,
          lng: +markerInfo?.longitude,
        },
        map: map,
        title: "",
        icon: smallMarkerIcon,
        zIndex: 1,
      });

      marker.set('champData', markerInfo);

      allMarkers.push(marker)
      const infowindow = new window.google.maps.InfoWindow({
        content: markerInfo?.name + " " + markerInfo?.phone
      });

      marker.addListener('click', function () {
        if (activeInfoWindow) {
          activeInfoWindow.close();
        }
        infowindow.open(map, marker);
        activeInfoWindow = infowindow;

      });
    });
  }

  const mapZoomingLogic = (map: any, allMarkers: any) => {
    window.google.maps.event.addListener(map, 'zoom_changed', function () {
      var zoom: any = map.getZoom();
      allMarkers.forEach(function (marker: any) {
        if (zoom > 13) {
          marker.setIcon(
            {
              url: marker.getIcon().url,
              size: null,
              origin: null,
              anchor: null,
              scaledSize: new google.maps.Size(34, marker.getIcon().url == brnchUrl ? 45 : 34) //changes the scale
            }
          )
        } else if (zoom == 13) {
          marker.setIcon(
            {
              url: marker.getIcon().url,
              size: null,
              origin: null,
              anchor: null,
              scaledSize: new google.maps.Size(29, marker.getIcon().url == brnchUrl ? 40 : 29) //changes the scale
            }
          )
        } else {
          marker.setIcon(
            {
              url: marker.getIcon().url,
              size: null,
              origin: null,
              anchor: null,
              scaledSize: new google.maps.Size(20, marker.getIcon().url == brnchUrl ? 30 : 20)//changes the scale
            }
          )
        }
      })
    });
  }

  const pendingCleanerMarkerMapping = (map: any, allMarkers: any, activeInfoWindow: any,) => {
    pendingcleaner?.forEach((markerInfo: any) => {
      const smallMarkerIcon = {
        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        scaledSize: new window.google.maps.Size(20, 20),
      };

      const marker = new window.google.maps.Marker({
        position: {
          lat: markerInfo?.worklocation?.length > 0 ? +markerInfo?.worklocation[0]?.latitude : 0,
          lng: markerInfo?.worklocation?.length > 0 ? +markerInfo?.worklocation[0]?.longitude : 0,
        },
        map: map,
        title: "",
        icon: smallMarkerIcon,
        zIndex: 1,
      });

      marker.set('champData', markerInfo);

      allMarkers.push(marker)
      const infowindow = new window.google.maps.InfoWindow({
        content: markerInfo?.first_name + " " + markerInfo?.last_name + " - " + markerInfo?.phone

      });

      marker.addListener('click', function () {
        if (activeInfoWindow) {
          activeInfoWindow.close();
        }
        infowindow.open(map, marker);
        activeInfoWindow = infowindow;

      });
    });

  }


  const customerMarkerMapping = (map: any, allMarkers: any, activeInfoWindow: any, markerInfo: any) => {
    markerInfo?.customerlist.forEach((markerInfoCustomer: any) => {
      const smallMarkerIcon = {
        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        scaledSize: new window.google.maps.Size(20, 20),
      };

      const marker = new window.google.maps.Marker({
        position: {
          lat: +markerInfoCustomer?.latitude,
          lng: +markerInfoCustomer?.longitude,
        },
        map: map,
        title: "",
        icon: smallMarkerIcon,
        zIndex: 2,
      });
      marker.set('champData', markerInfoCustomer);
      allMarkers.push(marker)
      const infowindow = new window.google.maps.InfoWindow({
        content: markerInfoCustomer?.name + " " + markerInfoCustomer?.phone
      });

      marker.addListener('click', function () {
        if (activeInfoWindow) {
          activeInfoWindow.close();
        }
        infowindow.open(map, marker);
        activeInfoWindow = infowindow;

      });
    });

  }

  useEffect(() => {
    let activeInfoWindow: any = null
    function rendered() {
      const map = new window.google.maps.Map(document.getElementById('map') as any, {
        center: centerLatLong,
        zoom: 11
      });

      const smallMarkerIcon = {
        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        scaledSize: new window.google.maps.Size(20, 20),
      };

      const homeLocationMarkerIcon = {
        url: brnchUrl,
        scaledSize: new window.google.maps.Size(20, 20),
      };

      let allMarkers: any = []
      champData.forEach((markerInfo: any) => {
        const marker: any = new window.google.maps.Marker({
          position: {
            lat: markerInfo?.cleanerworklocation?.length > 0 ? +markerInfo?.cleanerworklocation[0]?.latitude : 0,
            lng: markerInfo?.cleanerworklocation?.length > 0 ? +markerInfo?.cleanerworklocation[0]?.longitude : 0,
          },
          map: map,
          title: "",
          icon: smallMarkerIcon,
          zIndex: 3,
          draggable: true,
        });

        const homeLocationMarker: any = new window.google.maps.Marker({
          position: {
            lat: +markerInfo?.latitude ?? 0,
            lng: +markerInfo?.longitude ?? 0,
          },
          map: map,
          title: "",
          icon: homeLocationMarkerIcon ,
          zIndex: 3,
          draggable: true,
        });

        marker.set('champData', markerInfo);
        homeLocationMarker.set('champData', markerInfo);
        allMarkers.push(marker, homeLocationMarker)
        marker.addListener('dragend', function () {
          const newCenter = marker.getPosition();
          setNewLatLngLocation({ ...newLatLngLocation, ["lat"]: newCenter?.lat(), ["lng"]: newCenter?.lng() });
          setUpdateLocationPayload(markerInfo?.cleanerworklocation?.length > 0 ? markerInfo?.cleanerworklocation[0] : {});
          circle.setCenter(newCenter);
        });

        const infowindow = new window.google.maps.InfoWindow({
          content: markerInfo?.first_name + " " + markerInfo?.last_name + " - " + markerInfo?.phone
        });

        marker.addListener('click', function () {
          if (activeInfoWindow) {
            activeInfoWindow.close();
          }
          infowindow.open(map, marker);
          activeInfoWindow = infowindow;
        });

        const circle: any = new window.google.maps.Circle({
          strokeColor: 'red', // Blue stroke color
          fillColor: '#3d74fa',
          strokeOpacity: 0.1,
          fillOpacity: 0.40,
          strokeWeight: 1,
          map: map,
          center: centerLatLong?.lat != center?.lat ? centerLatLong : {
            lat: markerInfo?.cleanerworklocation?.length > 0 ? +markerInfo?.cleanerworklocation[0]?.latitude : 0,
            lng: markerInfo?.cleanerworklocation?.length > 0 ? +markerInfo?.cleanerworklocation[0]?.longitude : 0,
          },
          radius: parseInt(radius),
          zIndex: 1,
          draggable: true,

        });

        if (circle) {
          circle.setRadius(parseInt(radius));
        }

        circle.addListener('dragend', function () {
          var center = circle.getCenter();
          var radius = circle.getRadius();
          var pointsInsideCircle: any = [];
          allMarkers.map(function (mark: any) {
            var distance = google.maps.geometry.spherical.computeDistanceBetween(mark.getPosition(), center);
            if (distance <= radius) {
              pointsInsideCircle.push(mark.get('champData'))
            }
          });
          setCircleChamps(pointsInsideCircle)
        });

        markerInfo?.customerlist?.length > 0 && customerMarkerMapping(map, allMarkers, activeInfoWindow, markerInfo)
      });

      pendingcleaner?.length > 0 && pendingCleanerMarkerMapping(map, allMarkers, activeInfoWindow)
      notassignedlist?.length > 0 && notAssginedListMarkerMaping(map, allMarkers, activeInfoWindow)
      mapZoomingLogic(map, allMarkers)


    }

    const x = setTimeout(() => {
      champData?.length > 0 && rendered()
    }, 1000);
    return () => {
      clearTimeout(x)
    }

  }, [champData, radius, centerLatLong])


  return (
    <>
      <div>
        <div className='d-flex justify-content-between mt-1 w-100' style={{ width: "100%" }}>
          <div className='d-flex align-middle border w-100' style={{ width: "80%", display: "inlineFlex" }}>
            <div className='mt-2' style={{ width: "20%", marginRight: "10px" }}>
              <MapSingleSelect
                handleChangeInputData={cityChangeHandler}
                HeaderTitle='Select City'
                SelectData={cityData}
                DynamicKey={'city_name'}
                id={'id'}
                name='city_name'
                defaultData={cityId ? { label: "Bengalore", id: 6 } : null}
              ></MapSingleSelect>
            </div>
            <div className='d-flex flex-column  flex-wrap g-2 col-3 me-2'>
              <SearchAreaOnMap address={customAddressSearch} setMarkerPositionLatLng={setMarkerPositionLatLng} />
            </div>
            <input type="text" className='border rounded me-3 mt-2 ' placeholder='Radius(in KM)'
              onChange={(event) => setRadius(Number(event.target.value) * 1000)} style={{ border: "none", outline: "none", height: "38px", width: "20%", marginRight: "10px" }}
            />
            <div className='' style={{ width: "40%", marginRight: "10px" }}>
              <MapMultiSelect
                handleChangeInputData={handleChangeCleaner}
                HeaderTitle='Select Cleaners'
                SelectData={cleanerLists}
                DynamicKey={'first_name'}
                DynamicKey2={'last_name'}
                DynamicKey3={'phone'}
                id={'id'}
                name='cleanerids'
                defaultData={cleanerID ? [{ label: champsDetails?.first_name + " " + champsDetails?.last_name, id: champsDetails?.id }] : null}
              ></MapMultiSelect>
            </div>
            <div className='' style={{ width: "40%" }}>
              <button type='button' className='btn btn-sm btn-primary mt-2' onClick={handleSearch}>Search</button>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap w-100">
          <span className='border p-1 bg-white rounded me-3 mt-2'> Selected Champ Home Location - <img style={{ width: "20px", height: "25px" }} src={brnchUrl} alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> Selected Champ Work Location  - <img style={{ width: "25px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> Selected Champs's Customer - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/green-dot.png" alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> Other Champs - <img style={{ width: "25px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png" alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> Not Assgined Customer - <img style={{ width: "25px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/yellow-dot.png" alt="" /></span>
        </div>
        <br />
        <br />
        {
          champData?.length == 0 && !Loader ? <Not_Found headerTitle={champData?.length === 0 ? 'Select City To See Map' : "Select City To See Map"}></Not_Found> :
            Loader ? <IsLoading /> :
              <div id="map" style={{ height: 'calc(100vh - 250px)', width: '100%' }} />
        }

      </div>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Champs Inside Radius</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">NAME</th>
                <th scope="col">POINTS</th>
              </tr>
            </thead>
            <tbody>

              {circleChamps.map((champ: any) => {
                return <tr>
                  <th scope="row">{champ?.id}</th>
                  <td>{champ?.first_name + ' ' + champ?.last_name}({champ?.phone})</td>
                  <td>{champ?.total_points_earned}</td>
                </tr>
              })
              }

            </tbody>
          </table>
        </Modal.Body>
      </Modal>


      {
        updateLocationOpenModal && <ChangeLocationOnMapCleaner
          confirm='Yes'
          cancel='No'
          setUpdateLocationOpenModal={setUpdateLocationOpenModal}
          setNewLatLngLocation={setNewLatLngLocation}
          payloads={{
            render: updateLocationOpenModal,
            payloadData: {
              cleanerid: cleanerId?.length > 0 ? cleanerId[0] : null,
              dayid: 0,
              timeslotid: 0,
              address: address,
              pincode: pincode,
              latitude: newLatLngLocation?.lat,
              longitude: newLatLngLocation?.lng,
              status: 1,
            },
            successTitle: 'Changes Are Saved Successfully ',
            FailedTitle: 'Changes Cancelled ',
          }}
        ></ChangeLocationOnMapCleaner>
      }
    </>
  )
}
export default CompanyCustomerMarker
// Blue marker: http://maps.google.com/mapfiles/ms/icons/blue-dot.png
// Green marker: http://maps.google.com/mapfiles/ms/icons/green-dot.png
// Yellow marker: http://maps.google.com/mapfiles/ms/icons/yellow-dot.png
// Orange marker: http://maps.google.com/mapfiles/ms/icons/orange-dot.png
// Purple marker: http://maps.google.com/mapfiles/ms/icons/purple-dot.png
// Pink marker: http://maps.google.com/mapfiles/ms/icons/pink-dot.png
