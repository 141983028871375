import { faFileExcel, faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getPostMultipleApiHandler, getRequest, postRequest, postRequestWithFile } from './Server/Requests';
import { URL_EndPoints } from './Server/URL_EndPoints';
import { uploadChampHiringData } from './Server/data';
import SingleSelectSearchCategory from './Select/SingleSelectSearchCategory';
import SampleFileUpload from './SampleFileUpload';
import { downloadSampleFileKey } from './components/Utility';

const UploadFiles = ({ show, setShowFileModal }: any) => {

    const [getWhatsAppTemplate, setgetWhatsAppTemplate] = useState<any>([])
    const [getSourceData, setgetSourceData] = useState<any>([])
    const [fileSelected, setFileSelected] = useState<boolean>(false);
    const [isLoadingApi, setIsLoadingApi] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [samplefile, setSamplefileFile] = useState<any>(null);

    const [payload, setPayload] = useState<any>({
        file: file,
        source_id: null,
        create_ticket: false,
        whatsapp: false,
        whatsapp_template_id: null,
    })



    const getData = async () => {
        const results = await getPostMultipleApiHandler(uploadChampHiringData, null)
        setgetWhatsAppTemplate(results["whatsapp/getWhatsAppTemplate"] || [])
        setgetSourceData(results["getTicketSources"] || [])
    }

    useEffect(() => { getData() }, [])

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setFileSelected(true);
        setFile(e?.dataTransfer?.files[0]);
    };

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
 
        setFileSelected(true);
        if (event?.target?.files?.[0]?.name.endsWith('.xlsx')) {
            setFile(event?.target?.files?.[0]);
        }
        else {
            toast.error("Only Excell File Is Allowed ", { position: "top-center" })
        }

    };

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const handleUploadFile = async () => {
        payload.file = file
        
        if (payload?.create_ticket == false) {
            payload.whatsapp = false
            payload.whatsapp_template_id = null
        }

        if (payload?.whatsapp == false) {
            payload.whatsapp_template_id = null
        }
        

        const response = await postRequestWithFile(URL_EndPoints()?.readXLSXFileAndCreateCleaner, payload, null)
        if (response.status == 200) {
            toast(response?.data?.msg, { position: "top-center" })
        }
        else {
            toast(response?.data?.msg, { position: "top-center" })

        }


    };
   

    const handleChangeInputData = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
    }







    const handleDownload = async () => {

        const tokens = localStorage.getItem("auth-token")
        const userId = localStorage.getItem("user")
        const webKit_hash_id = localStorage.getItem("webKit_hash_id")
        const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
        let baseUrl = `${localKeyCheck ? "https://adminapi.carselonadaily.com/api/admin/" : "https://testadminapi.carselonadaily.com/api/admin/"}`

        try {
            const options = {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${tokens ? tokens + "," + "Useragenthash " + webKit_hash_id : ""}`,
                    'Userid': `${userId}`,
                    'Usertype': `${5}`,
                },
                responseType: "blob",
                body: JSON.stringify({ key: downloadSampleFileKey })
            }
            fetch(`${baseUrl}downloadFileFromS3`, options)
                .then(response => response.blob())
                .then((response: any) => {
                    const myBlobURL = URL.createObjectURL(response);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = myBlobURL;
                    downloadLink.download = 'sample_data_file.xlsx';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    // clean up the blob and download link 
                    document.body.removeChild(downloadLink);
                    URL.revokeObjectURL(myBlobURL);
                })
                .catch(err => console.error(err));
        } catch (error) {
            console.error('Error downloading file:', error);
        }

    }







    return (
        <Modal size="lg" show={show} onHide={() => setShowFileModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Import file / Drag And Drop </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <Form onSubmit={}> */}
                <Form.Group className="mb-3" onDragOver={handleDragOver} onDrop={handleDrop}>
                    <input type="file" key={12121} name="imag2" id="imag2" onChange={onChangeFile} style={{ display: 'none' }} />

                    <label htmlFor="imag2" style={{ width: "100%", height: "90%" }}>
                        <div className=" col-12 ">
                            <div className="dz-default dz-message text-center d-flex flex-column g-2 dropzone dz-clickable border rounded bg-light p-3">
                                <i className="bi bi-cloud-arrow-up mb-4" style={{ fontSize: "5rem" }}></i>
                                <span className='fw-bold fs-3 text-muted'>Drop files here or click to upload...</span>
                            </div>
                        </div>
                    </label>
                </Form.Group>


                {fileSelected && (
                    <div className="mt-3 d-flex justify-content-center flex-column align-items-center border" key={40343}>
                        {file?.type?.startsWith('image/') ? (
                            // Display image preview for image files
                            <img src={URL.createObjectURL(file)} alt="Uploaded File" className="img-thumbnail" style={{ maxWidth: "100%", maxHeight: "150px" }} />
                        ) : (
                            // Display appropriate icon or representation for other file types
                            <div className="file-type-icon">
                                {file?.type?.startsWith('application/pdf') ? (
                                    // <FontAwesomeIcon icon={faFilePdf}  className='text-success fw-bold fs-1'/>

                                    <img src="https://play-lh.googleusercontent.com/1EgLFchHT9oQb3KME8rzIab7LrOIBfC14DSfcK_Uzo4vuK-WYFs9dhI-1kDI7J0ZNTDr" alt="Uploaded File" className="img-thumbnail" style={{ maxWidth: "100%", maxHeight: "150px" }} />
                                ) : file?.type?.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? (
                                    // <FontAwesomeIcon icon={faFileExcel}  className='text-success fw-bold fs-1' style={{fontSize :"5rem"}}/>
                                    <img src=" https://cdn-icons-png.flaticon.com/512/4726/4726040.png" alt="Uploaded File" className="img-thumbnail" style={{ maxWidth: "100%", maxHeight: "150px" }} />


                                ) : (

                                    <img src="https://cdn.icon-icons.com/icons2/2570/PNG/512/image_icon_153794.png" alt="Uploaded File" className="img-thumbnail" style={{ maxWidth: "100%", maxHeight: "150px" }} />

                                )}
                            </div>
                        )}

                        <br />

                        {fileSelected && (
                            <div className="d-flex justify-content-between mt-2">
                                <div className="p-1 me-4">
                                    <span className='text-primary fw-bold'> File Name :</span>  {file?.name} ({(file?.size / (1024 * 1024)).toFixed(2)}MB)
                                </div>
                                <div className="p-1" onClick={CancelImage}>
                                    <i className="fa fa-times fs-3 tex-danger" aria-hidden="true"></i>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <br />















                <div className='mb-2'>
                    <SingleSelectSearchCategory
                        handleChangeInputData={handleChangeInputData}
                        HeaderTitle="Select Source"
                        SelectData={getSourceData}
                        DynamicKey={"name"}
                        id={"id"}
                        name="source_id"
                    ></SingleSelectSearchCategory>
                </div>


                <div className="form-check me-4 mb-5 ms-2" >
                    <input className="form-check-input me-5" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={() => setPayload((prev: any) => ({ ...prev, ["create_ticket"]: !prev?.create_ticket }))} checked={payload?.create_ticket} />
                    <label className="form-check-label" >  Do you want to create ticket for user</label>
                </div>

                {
                    payload?.create_ticket && <div className="form-check me-4 mb-5 ms-2" >
                        <input className="form-check-input me-5" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={() => setPayload((prev: any) => ({ ...prev, ["whatsapp"]: !prev?.whatsapp }))} checked={payload?.whatsapp} />
                        <label className="form-check-label" >   Do you want to send whatsapp message filter </label>
                    </div>
                }

                {
                    payload?.create_ticket && payload?.whatsapp && <div className='mb-2'>
                        <SingleSelectSearchCategory
                            handleChangeInputData={handleChangeInputData}
                            HeaderTitle="Select Template"
                            SelectData={getWhatsAppTemplate}
                            DynamicKey={"name"}
                            id={"id"}
                            name="whatsapp_template_id"
                        ></SingleSelectSearchCategory>
                    </div>
                }


                <br />



                <span className='fw-bold ms-2  cursor-pointer' style={{ cursor: "pointer" }} onClick={handleDownload}>
                    <span className='text-danger me-2 fs-4'>  Note :</span>  <span className='text-primary fs-5'> Download Sample  File</span>
                </span>
              
                <Form.Group className="mb-3 p-2 text-end">
                    {isLoadingApi ? (
                        <Spinner animation="border" />
                    ) : (
                        <Button onClick={handleUploadFile} type="submit" variant="primary" disabled={samplefile ? true : false}>
                            Submit
                        </Button>
                    )}
                </Form.Group>
                {/* </Form> */}
            </Modal.Body>
        </Modal>
    );
};

export default UploadFiles;





