
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import "../styles/EstimatePoints.css"
import GenerateNode from './GenerateNode'
import GenerateNode2 from './GenerateNod2'
import GenerateNode3 from './GenerateNod3'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { getRequest, postRequest } from '../../Server/Requests'
import SingleSelectSearchDetails from '../../Select/SingleSelectSearchDetails'

const EstimatePoints = ({cleanerid}:any) => {
  const [resData, setResData] = useState<any>(false)
  const [mainRes, setMainRes] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [summeryModalTitle, setSummeryModalTitle] = useState<any>("")
  const [cleanerList, setCleanerList] = React.useState([])
  const [date, setDate] = React.useState('')
//   const [cleanerid, setCleanerid] = React.useState(0)

  const [weeklyPoints, setWeeklyPoints] = useState<any>(false)
  const [netPayoutName, setNetPayoutName] = useState<any>("")
  const [netPayoutProjected, setNetPayoutProjected] = useState<any>("")
  const [netPayoutTIllNow, setNetPayoutTillNow] = useState<any>("")

  async function fetchData() {
    const getCleanerList = await getRequest(URL_EndPoints(null)?.getCleanerList, null)
    setCleanerList(getCleanerList.data.data)
  }

  const handleChangeInput = (event: any) => {
    if (event?.value) {
    //   setCleanerid(event?.value)
      setSummeryModalTitle(event?.label + ' Jobs')
    } else {

      setDate(event?.target?.value)
    }

  }

  const handleSearch = async () => {
    setIsLoading(true)
    const result1: any = await postRequest(URL_EndPoints(null)?.getCleanerWeeklyPointsV2, {
      cleanerid: cleanerid,
      startDate: date,
    }, null)

    if (result1.status == 200) {
      let data = result1.data
      setResData(data?.data)
      setMainRes(data)

    }

    const result = await postRequest(URL_EndPoints(null)?.getCleanerWeeklyPoints, {
      cleanerid: cleanerid,
      startDate: date,
    }, null)

    if (result.status == 200) {
      setWeeklyPoints(result?.data)
      let data = result.data
      setNetPayoutName(data.data.netPayout.name)
      setNetPayoutProjected(parseInt(data.data.netPayout.projected).toString())
      setNetPayoutTillNow(parseInt(data.data.netPayout.tillNow).toString())
    }

    setIsLoading(false)


  }


  useEffect(() => {
    fetchData()
  }, [])

  const handleGenerateNewReport = async () => {
    setIsLoading(true)
    const data = await getRequest(URL_EndPoints()?.runSchemes + '?cleanerid=' + cleanerid, null)
    if (data.status == 200) {
      handleSearch()
    }
    setIsLoading(false)

  }

  return (
    <>
      <div className='d-flex'>
        <div className='row'>
          <div className=''>
            <input
              type='date'
              style={{ height: '38px' }}
              name='attendencedatefrom'
              onChange={handleChangeInput}
              className='w-100  height-100 form-control me-2 align-start'
            />
          </div>
        </div>
        <SingleSelectSearchDetails
          handleChangeInputData={handleChangeInput}
          HeaderTitle='Select Cleaner'
          SelectData={cleanerList}
          DynamicKey={'first_name'}
          DynamicKey2={'last_name'}
          DynamicKey3={'phone'}
          id={'id'}
          name='cleanerid'
        ></SingleSelectSearchDetails>
        <div>
          <button className='btn btn-primary btn-sm' onClick={handleSearch}>
            Seaarch
          </button>
        </div>
      </div>
      {
        isLoading && <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }

      {
        !resData && <h5>No Data Found</h5>
      }



      <h4 style={{ marginTop: "3rem" }}>New</h4>
      {/* <hr /> */}

      {
        resData &&
        resData?.schemes.map((scheme: any, index: any) => {
          return scheme?.scheme_name == "Main Scheme" ?
            <Accordion>
              <Accordion.Item eventKey='232323'>
                <Accordion.Header>
                  <div className='d-flex justify-content-evenly w-100'>
                    <div className="col-sm">{scheme?.scheme_name}</div>
                    <div className="col-sm">{scheme?.projectedValue}</div>
                    <div className="col-sm">{scheme?.tillNowValue}</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>

                  {
                    scheme?.data?.grossPayout ? <GenerateNode data={scheme?.data?.grossPayout} /> : ""

                  }

                  {
                    scheme?.data?.weekly ? <GenerateNode2 data={scheme?.data?.weekly} /> : ""

                  }

                  {
                    scheme?.data?.extra_jobs ? <GenerateNode data={scheme?.data?.extra_jobs} /> : ""

                  }

                  {
                    scheme?.data?.deductionData ? <GenerateNode3 data={scheme?.data?.deductionData} /> : ""

                  }
                </Accordion.Body>
              </Accordion.Item >
            </Accordion>
            : <Accordion>
              <Accordion.Item eventKey='232323'>
                <Accordion.Header>
                  <div className='d-flex justify-content-evenly w-100'>
                    <div className="col-sm">{scheme?.scheme_name}</div>
                    <div className="col-sm">{scheme?.projectedValue}</div>
                    <div className="col-sm">{scheme?.tillNowValue}</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {
                    scheme?.data.map((sc_data: any) => {
                      return <Accordion>
                        <Accordion.Item eventKey=''>
                          <Accordion.Header>
                            <div className='d-flex justify-content-evenly w-100'>
                              <div className="col-sm">{sc_data?.date}</div>
                              <div className="col-sm">{sc_data?.amount}</div>
                              <div className="col-sm">{sc_data?.amount}</div>
                            </div>
                          </Accordion.Header>

                        </Accordion.Item>
                      </Accordion>

                    })
                  }
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

        })
      }



      {
        resData &&
        <Accordion>
          <Accordion.Item eventKey='23'>
            <Accordion.Header>
              <div className='d-flex justify-content-evenly w-100'>
                <div className="col-sm">{"Total Payout"}</div>
                <div className="col-sm">{resData?.projectedValue}</div>
                <div className="col-sm">{resData?.tillNowValue}</div>
              </div>
            </Accordion.Header>
          </Accordion.Item >
        </Accordion>
      }


      <hr />
      <h4>Old</h4>

      {
        weeklyPoints ? <GenerateNode data={weeklyPoints?.data?.grossPayout} /> : ""

      }


      {
        weeklyPoints ? <GenerateNode2 data={weeklyPoints?.data?.weekly} /> : ""

      }

      {
        weeklyPoints ? <GenerateNode3 data={weeklyPoints?.data?.deductionData} /> : ""

      }

      {
        <Accordion>
          <Accordion.Item eventKey='232323'>
            <Accordion.Header>
              <div className='d-flex justify-content-evenly w-100'>
                <div className="col-sm">{"Net Payout"}</div>
                <div className="col-sm">{netPayoutProjected}</div>
                <div className="col-sm">{netPayoutTIllNow}</div>
              </div>
            </Accordion.Header>
          </Accordion.Item >
        </Accordion>
      }

      <hr />

      <div className="" style={{ marginTop: "5rem" }}>
        <h3>Report</h3>
        <hr />
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Scheme Name</th>
              <th scope="col">Total Points Earned</th>
              <th scope="col">Extra Points Earned</th>
              <th scope="col">Point Value</th>
              <th scope="col">Total Cash Earned</th>
              <th scope="col">Min Weekly Payout</th>
              <th scope="col">Allowance</th>
              <th scope="col">Net Earned</th>
            </tr>
          </thead>
          <tbody>

            {
              mainRes?.weeklyReportTest?.map((weeklyReportTest: any, index: any) => {
                return <tr style={{ backgroundColor: "#dbeadb" }}>
                  <th scope="row">New - {index + 1}</th>
                  <th scope="row">{weeklyReportTest?.scheme?.title}</th>
                  <td>{weeklyReportTest?.total_points_earned}</td>
                  <td>{weeklyReportTest?.temp_points_earned}</td>
                  <td>{weeklyReportTest?.earned_points_value}</td>
                  <td>{weeklyReportTest?.total_cash_earned}</td>
                  <td>{weeklyReportTest?.min_weekly_payout}</td>
                  <td>{weeklyReportTest?.allowance}</td>
                  <td>{weeklyReportTest?.net_earned}</td>
                </tr>
              })
            }

            <tr style={{ backgroundColor: "#f6e3e3" }}>
              <th scope="row">Old</th>
              <th scope="row"></th>
              <td>{mainRes?.weeklyReport?.total_points_earned}</td>
              <td>{mainRes?.weeklyReport?.temp_points_earned}</td>
              <td>{mainRes?.weeklyReport?.earned_points_value}</td>
              <td>{mainRes?.weeklyReport?.total_cash_earned}</td>
              <td>{mainRes?.weeklyReport?.min_weekly_payout}</td>
              <td>{mainRes?.weeklyReport?.allowance}</td>
              <td>{mainRes?.weeklyReport?.net_earned}</td>

            </tr>

          </tbody>
        </table>

        {
          mainRes?.weeklyReportTest && mainRes?.weeklyReportTest?.length == 0 ? <button onClick={handleGenerateNewReport} className='btn btn-sm btn-primary'>Generate New Report</button> : ''
        }
      </div>
    </>
  )
}

export default EstimatePoints