import React, { useState } from 'react'
import 'react-tagsinput/react-tagsinput.css'
import TagsInput from 'react-tagsinput'
const AdminTagsInput = ({ onChangeHandler = () => { }, HeaderTitle = "", payload = {}, name = "", label = "", }: any) => {
    const initialTags = typeof payload[name] === 'string' && payload[name] ? payload[name].split(',') : [];
    const [state, setState] = useState<any>(initialTags)
    const handleChange = (tags: any) => {
        setState(tags)
        onChangeHandler({ value: tags?.join(",") }, name, false)
    }

    // .react-tagsinput-tag do csss in this css name
    return (
        <TagsInput value={state} onChange={handleChange} />
    )
}

export default AdminTagsInput






// import React, { useState } from 'react'
// import 'react-tagsinput/react-tagsinput.css'
// import TagsInput from 'react-tagsinput'
// const AdminTagsInput = ({ onChangeHandler = () => { }, HeaderTitle = "", payload = {}, name = "", label = "", }: any) => {

//     // .react-tagsinput-tag do csss in this css name
//     return (
//         <TagsInput value={payload[name]}  />
//     )
// }

// export default AdminTagsInput

