import React from 'react'
import Select from 'react-select'
import "./styles.css"
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
const SingleSelectSearchDetails = (props: any) => {
  const { handleChangeInputData, HeaderTitle, SelectData, DynamicKey, DynamicKey2, DynamicKey3, id, name, extraValues = [], targetValue, extraClass = '', isLoading } = props

  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {

    const updatedData = SelectData?.map((ele: any, i: number) => {
      let number = ele[DynamicKey3] ? '(' + ele[DynamicKey3] + ')' : "(NA)"
      if (ele[DynamicKey] !== null) {
        let newData = {
          label: ele[DynamicKey] + ' ' + (ele[DynamicKey2] || '') + number,
          value: ele[id],
        }

        let newValue = newData.value;
        for (const key of extraValues) {
          newValue += `-${ele[key]}`
        }
        newData = {
          ...newData,
          value: newValue
        }
        return newData
      }
    })
    // reference2

    setShowData(updatedData)
  }, [SelectData])
  return (
    <div className={'mb-2' + extraClass} >
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        options={showData}
        onChange={(event) => handleChangeInputData(event, name, targetValue)}
        styles={colourStyles2}
        isLoading={isLoading && isLoading}
      />
    </div>
  )
}
export default SingleSelectSearchDetails
