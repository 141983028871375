

import { Dropdown } from "react-bootstrap"
import "./selectBox.css"
import { useEffect, useState } from "react"

export const getShowHideColsLocal = () => {
    return JSON.parse(localStorage.getItem("showHideCols") || "[]")
}

export const setShowHideColLocal = (value: any) => {
    return localStorage.setItem("showHideCols", JSON.stringify(value))
}

const colsHiddenA2z = (setAll: any, columns: any) => {
    if (getShowHideColsLocal()?.length ==  columns?.length) {
        setAll("Clear All")
        return
    }
    else {
        setAll("Select All")
        return
    }
}

export const ShowHideColumnsLocalStorage = ({ columns, sortedColumns, setSortedColumns }: any) => {
    const [all, setAll] = useState("Clear All")
    const [localData, setLocalData] = useState(getShowHideColsLocal())

    useEffect(() => {
        if (getShowHideColsLocal()?.length == 0) {
            setShowHideColLocal(columns?.map((ele: any) => ele?.id))
            colsHiddenA2z(setAll, columns)
        }
        colsHiddenA2z(setAll, columns)
        setLocalData(getShowHideColsLocal())


    }, [columns?.length])

    const handleSortCheckbox = (event: any, isAll: boolean = false) => {

        if (isAll) {
            if (getShowHideColsLocal()?.length == 0) {
                setSortedColumns(columns);
                setShowHideColLocal(columns?.map((ele: any) => ele?.id))
                setLocalData(columns?.map((ele: any) => ele?.id))
                colsHiddenA2z(setAll, columns)

            } else {
                setSortedColumns([]);
                setShowHideColLocal([])
                setLocalData([])
                colsHiddenA2z(setAll, columns)
            }
        }

        else {
            if (event.target.checked) {
                let arrr: any = getShowHideColsLocal()
                const data = columns?.find((ele: any) => ele?.id == event.target.value)
                if (data) {
                    arrr.push(data?.id)
                }
                setLocalData(arrr?.filter((ele: any) => ele != null));
                setShowHideColLocal(arrr?.filter((ele: any) => ele != null))
                const updatedCols = columns?.filter((ele: any) => arrr.includes(ele?.id))
                setSortedColumns(updatedCols);
                colsHiddenA2z(setAll, columns)

            } else {
                let arrr: any = getShowHideColsLocal()
                const data = arrr?.filter((ele: any) => ele != event.target.value)
                setLocalData(data?.filter((ele: any) => ele != null));
                setShowHideColLocal(data?.filter((ele: any) => ele != null))
                const updatedCols = columns?.filter((ele: any) => data.includes(ele?.id))
                setSortedColumns(updatedCols);
                colsHiddenA2z(setAll, columns)

            }
        }
    }

    return <>

        <Dropdown className="d-inline mx-2 mb-2 btn-sm me-3 mx-2 " autoClose="outside" key={112}>
            <Dropdown.Toggle id="dropdown-autoclose-outside"
                className="btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn"
            >
                Show/Hide Cols
            </Dropdown.Toggle>

            <Dropdown.Menu >
                <span className="mb-3 fs-5 cursor-pointer ms-3 text-end" onClick={() => handleSortCheckbox(false, true)}>  {all} </span>
                <br />
                <br />

                {columns.map((column: any, index: any) => {
                    return <li className='dropdown-item' key={column?.id}> <input onChange={(event) => handleSortCheckbox(event, false)} type="checkbox" name="fields" value={column.id} id="" checked={sortedColumns?.find((ele: any) => ele?.id == column.id && localData?.includes(ele?.id)) ? true : false
                    } /> {column.name}</li>
                })}
            </Dropdown.Menu>
        </Dropdown>

    </>
}