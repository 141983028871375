import { Modal } from 'react-bootstrap'
import TicketCountCharts from './ticketComponents/TicketCountCharts'
import Not_Found_Data from '../../consts/Not_Found_Data'
import ShowFilterHint from './ticketComponents/ShowFilterHint'

const ShowTicketCountChartModal = ({ chartData, show, setShow, title, payload }: any) => {

    return (
        <>
            <Modal
                size='xl'
                show={show}
                onHide={() => setShow(false)}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex gap-3'>
                        {title}
                        <ShowFilterHint payload={payload} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        chartData?.categories?.length > 0 ? <TicketCountCharts data={chartData} /> :
                            <Not_Found_Data headerTitle={title}></Not_Found_Data>

                    }

                </Modal.Body>
            </Modal>

        </>
    )
}

export default ShowTicketCountChartModal