import React, { useEffect, useState } from 'react'
import IsLoading from '../../../IsLoading/IsLoading'
import { CheckboxModal, ColModal, GroupModal, RowModal, SelectModal } from '../../../widgets/forms/FormsModals'
import { getRequest, postRequest } from '../../../Server/Requests'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { QueryParams } from '../../../Server/QueryParams'
import { AllFielSearch } from '../../../FieldSearch/AllFieldSearch'
import { toast } from 'react-toastify'
import DataTable from 'react-data-table-component'
import { AllowanceColumn } from './AllowanceColumn'
import { isAllowedUser } from '../../../components/Utility'
import { ReactTableComponentStylesSheet } from '../../../reuseModules/ReactTableComponent/ReactTableComponentStylesSheet'

const ChampsAllowance = ({ row, refereshID }: any) => {

    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<boolean>(false)
    const [filterData, setfilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [loader, setLoader] = useState<boolean>(false)
    const [editLoader, setEditLoader] = useState<boolean>(false)
    const [allowanceRecord, setAllowanceRecords] = useState<any>([])

    const [payload, setPayload] = useState<any>({
        cl_allowance_id: 0,
        allowance_id: row?.id,
        cleanerid: row?.id,
        type: null,
        amount: "",
        status: 0,
    })

    async function cleanerAllowanceData() {
        setPending(true)
        let newQueryParams = { ...QueryParams("getCleanerAllowance", "/") }
        newQueryParams.What += row?.id
        const { data } = await getRequest(URL_EndPoints(newQueryParams)?.getCleanerAllowance, setPending)
        setData(data.data)
        setfilterData(data.data)
    }
    
    async function getAllAllowance() {
        cleanerAllowanceData()
        const getAllAllowance = await getRequest(URL_EndPoints()?.getAllAllowance, setPending)
        setAllowanceRecords(getAllAllowance?.data?.data)
    }

    useEffect(() => {
        getAllAllowance()
    }, [row?.id, refereshID])

    useEffect(() => {
        loader == false && cleanerAllowanceData()
    }, [loader])

    useEffect(() => {
        const updatedData = AllFielSearch(data, search)
        setfilterData(updatedData)
    }, [search])

    const handleSubmitAssign = async () => {
        setLoader(true)

        const { data } = await postRequest(URL_EndPoints()?.assignAllowanceToCleaner, payload, setLoader)
        if (data?.status == 200) {
            toast.success(data?.msg, { position: "top-center" })
        }
        else {
            toast.error(data?.msg, { position: "top-center" })
        }
    }

    return (
        <>

            {

                <DataTable
                    title=""
                    columns={AllowanceColumn(setPayload, payload, setEditLoader, row)} 
                    data={filterData} 
                    pagination  
                    progressPending={pending}
                    fixedHeader
                    fixedHeaderScrollHeight='auto'  
                    selectableRowsHighlight
                    highlightOnHover  
                    subHeader 
                    customStyles={ReactTableComponentStylesSheet}
                    subHeaderComponent={<>
                        {
                            <div className="col-12 " style={{display:isAllowedUser?.includes(Number(localStorage.getItem("user"))) ? "block" : "none"}}>

                                {editLoader ? <IsLoading /> :
                                    <RowModal classes="d-flex flex-wrap justify-content-between ">

                                        <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                            <GroupModal classes="" >
                                                <SelectModal
                                                    classes="mb-2"
                                                    title="Select Allowance"
                                                    name="allowance_id"
                                                    keyName="name"
                                                    id="id"
                                                    isData={allowanceRecord}
                                                    payload={payload}
                                                    setPayload={setPayload}
                                                    defValue={payload?.allowance_id}
                                                />
                                            </GroupModal>
                                        </ColModal>


                                        <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-2">
                                            <GroupModal classes="" >
                                                <SelectModal
                                                    classes="mb-2"
                                                    title="Type"
                                                    name="type"
                                                    keyName="name"
                                                    id="id"
                                                    isData={[{ id: "cash", name: "cash" }, { id: "points", name: "points" }]}
                                                    payload={payload}
                                                    setPayload={setPayload}
                                                    defValue={payload?.type}
                                                />
                                            </GroupModal>
                                        </ColModal>


                                        <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-2">
                                            <GroupModal classes="" >
                                                <input
                                                    placeholder='Amount'
                                                    name='amount'
                                                    type='text'
                                                    value={payload?.amount}
                                                    className='form-control  mb-3 mb-lg-0'
                                                    autoComplete='off'
                                                    onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                                />
                                            </GroupModal>
                                        </ColModal>

                                        <ColModal classes="mb-4 col-12 col-md-2 col-sm-2 col-lg-2">
                                            <GroupModal classes=" mt-5" >
                                                <CheckboxModal
                                                    classes=""
                                                    name="status"
                                                    payload={payload}
                                                    setPayload={setPayload}
                                                    defValue={payload?.status}
                                                />
                                            </GroupModal>
                                        </ColModal>


                                        <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                            <GroupModal classes="" >
                                                <button disabled={loader} className={`btn btn-sm btn-${loader ? "warning" : "primary"} fs-6`} onClick={handleSubmitAssign}>

                                                    {loader ?
                                                        <>

                                                            <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                            <span>Assigning...</span>

                                                        </>

                                                        : " Assgin / Change"}

                                                </button>
                                            </GroupModal>
                                        </ColModal>


                                    </RowModal>}

                            </div>
                        }
                    </>}
                />
            }





        </>
    )
}

export default ChampsAllowance
