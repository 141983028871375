import { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import CustomerDefaultSelect from '../../../consts/Select/CustomerDefaultSelect'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { QueryParams } from '../../../consts/Server/QueryParams'

// const userTypes = [
//     {
//         id: 'champ',
//         name: 'Champ'
//     },
//     {
//         id: 'customer',
//         name: 'Customer'
//     },
// ];

// const getValidData = (data: any) => data?.map((d: any) => ({
//     ...d,
//     first_name: d?.first_name ?? '',
//     last_name: d?.last_name ?? '',
//     phone: d?.phone ?? '',
// }));

const AssignCustomerModal = ({ showModal, handleClose, row }: any) => {
    const [loader, setLoader] = useState<any>(false)
    const [userList, setUserList] = useState<any>([]);
    const [defaultUser, setDefaultUser] = useState<any>({});
    const [loadingUser, setLoadingUser] = useState<any>(false);
    const [storeCustomer, setStoreCustomer] = useState<any>("");
    const [isLoading, setIsLoading] = useState<any>(false);

    const [payload, setPayload] = useState<any>({
        reviewId: row?.id,
        userType: 'customer',
        userId: 0,
        shouldCreateTicket: false
    }); 

    const handleChangeInputHandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event?.value });
    }

    // useEffect(() => {
    //     const loadUsers = async () => {
    //         // setLoadingUser(true);
    //         const typeApiMap: any = {
    //             'customer': 'getActiveCustomerlist',
    //             'champ': 'getActiveChampList',
    //         }
    //         const response: any = await getRequest(URL_EndPoints(null)[typeApiMap[payload?.userType] ?? 'getActiveChampList'], setLoadingUser);
    //         response?.data?.data && setDefaultUser(response?.data?.data[0]);
    //         // response?.data?.data && setUserList(getValidData(response?.data?.data));

    //         setLoadingUser(false);
    //     }
    //     payload?.userType && loadUsers();
    // }, [payload?.userType]);



    useEffect(() => {
        setLoadingUser(true)
    }, [])


    async function getCustomerDataBySearch(storeCustomerSearch: any) {

        setIsLoading(true)
        // let newQueryParams = { ...QueryParams("getActiveCustomerlistRating", "?") }
        // newQueryParams.What += `search=${storeCustomerSearch}`
        const response: any = await postRequest(URL_EndPoints()?.getActiveCustomerlistRating, { search: storeCustomerSearch }, setLoadingUser);
        response?.data?.data && setUserList(response?.data?.data);
        setLoadingUser(false)
        setIsLoading(false)
    }


    useEffect(() => {
        let x = setTimeout(() => {
            getCustomerDataBySearch(storeCustomer)
        }, 2000)
        return () => {
            setLoadingUser(false)
            setIsLoading(false)
            clearTimeout(x)
        }
    }, [storeCustomer])

    const handleAssignUser = async () => {
        setLoader(true);
        if (!payload?.userId) {
            setLoader(false);
            toast.error("Please select valid customer");
            return;
        }
        const response: any = await postRequest(URL_EndPoints(null)?.assignUserToReview, payload, setLoader);
        if (response?.data?.status === 200) {
            toast.success(response?.data?.message);
            handleClose();
        } else {
            toast.info(response?.data?.message);
        }
    }

    return (
        <>
            <Modal size='lg' show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className='col-12 w-100 mt-2 '>
                            {/* {loadingUser ? <Spinner animation="border" variant="success" /> : <CustomerDefaultSelect
                                handleChangeInputData={handleChangeInputHandler}
                                HeaderTitle={"Select Customer"}
                                SelectData={[{first_name:"moin", last_name:"zacck", id:3}]}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name={"userId"}
                                defaultData={defaultUser}
                                setStoreCustomer={setStoreCustomer}
                            />} */}


                            {
                                loadingUser ? <Spinner animation="border" variant="success" className='text-center' /> :
                                    <CustomerDefaultSelect
                                        handleChangeInputData={handleChangeInputHandler}
                                        HeaderTitle='Select Customer'
                                        SelectData={userList}
                                        // SelectData={[{ first_name: "moin", last_name: "zacck", id: 3 }]}
                                        DynamicKey={'first_name'}
                                        DynamicKey2={'last_name'}
                                        DynamicKey3={'phone'}
                                        id={'id'}
                                        name='userId'
                                        defaultData={row?.first_name && { label: row?.first_name + " " + row?.last_name, value: row?.id }}
                                        setSeacrch={setStoreCustomer}
                                        isLoading={isLoading}
                                    ></CustomerDefaultSelect>
                            }
                        </div>
                        <div className='col-12 w-100 mt-5 me-'>
                            <Form.Check // prettier-ignore
                                type="checkbox"
                                id={`default-checkbox`}
                                label={`Check this to create ticket`}
                                checked={payload?.shouldCreateTicket}
                                onChange={(e: any) => handleChangeInputHandler({ value: e.target.checked }, "shouldCreateTicket")}
                            />
                        </div>
                        <div className='col-12 w-100 mt-5'>
                            This review's rating is <span>{row?.rate}</span>
                        </div>
                        <div className='col-12 w-100 text-muted mt-2'>Note: Ticket will always be created for rating less than 3</div>
                        <div className=' col-12 w-100 d-flex justify-content-end mt-8 me-'>
                            <Button variant="primary btn-sm text-end btn-sm " disabled={loader} onClick={handleAssignUser}>
                                {
                                    loader ?
                                        <div className='d-flex'>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Selecting...</h4>
                                        </div>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Select</h4>
                                }
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default AssignCustomerModal
