
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const DateFormateMoment = ({ date = "", from = "" }: any) => {
    // rule to create the key is == from+_+CREEATED_AT 

  const toggleColumn = useSelector((store: any) => store.RefreshPage.toggleColumn)
  const [toggleColumns, setToggleColumns] = useState<any>({})

  useEffect(() => {
    const toggleColumns: any = JSON.parse(localStorage.getItem("toggleColumns") || "{}") || {}
    setToggleColumns(toggleColumns || {})
  }, [toggleColumn])

  const dateObject = moment(date).toDate();
  const formattedDate = moment(dateObject).format(toggleColumns[from] ? "DD-MM-YYYY hh:mm:ss A" : 'DD-MM-YYYY');

  return (<>
    {(!date) ? 'NA' : formattedDate}
  </>
  )
}




// sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA?.customer, rowB?.customer, "first_name", true),

// cell: (row: any) => <DateFormateMoment date={row?.createdAt} from="TICKET_CREATED_AT" />,



{/* <ToggleColumns columns={[{ name: "CREATED AT", id: "CREATED AT" }, { name: "UPDATED AT", id: "UPDATED AT" }]} from="TICKET" /> */}
