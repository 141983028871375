
export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },
  {
    name: 'Media',
    cell: (row: any) => <img src={row?.media} alt="" style={{ width: "50px", height: "50px" }} />,
    reorder: true,
    id: 3,
    width: '100px',
  },

  {
    name: 'Notes',
    cell: (row: any) => row?.notes,
    sortField: 'notes',
    sortable: true,
    reorder: true,
    id: 4,
    width: '150px',
  },
  {
    name: 'Amount',
    cell: (row: any) => row?.amount,
    sortField: 'amount',
    sortable: true,
    reorder: true,
    id: 5,
    width: '120px',
  },
  {
    name: 'Payment Date',
    cell: (row: any) => row?.payment_date,
    sortField: 'payment_date',
    sortable: true,
    reorder: true,
    id: 6,
    width: '200px',
  },
  {
    name: 'Purchase Order Id',
    cell: (row: any) => row?.paymentReceiptPurchaseOrder?.purchase_order_id,
    sortField: 'paymentReceiptPurchaseOrder.purchase_order_id',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Proforma Invoice Id',
    cell: (row: any) => row?.paymentReceiptProformaInvoice?.proforma_invoice_id,
    sortField: 'paymentReceiptProformaInvoice.proforma_invoice_id',
    sortable: true,
    reorder: true,
    id: 8,
    width: '200px',
  },
  {
    name: 'Payment Status',
    cell: (row: any) => row?.PaymentReceiptStatus?.status,
    sortField: 'PaymentReceiptStatus.status',
    sortable: true,
    reorder: true,
    id: 9,
    width: '200px',
  },
  {
    name: 'Payment Method',
    cell: (row: any) => row?.paymentReceiptMethods?.name,
    sortField: 'paymentReceiptMethods.name',
    sortable: true,
    reorder: true,
    id: 10,
    width: '200px',
  },


]
