import React, { memo } from 'react';
import { withSwal as ConvertToFullCleaningDay } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { toast } from 'react-toastify';
export default ConvertToFullCleaningDay((props: any) => {
    const { swal, confirm, cancel, payloads, title, setshow, render }: any = props;
    React.useEffect(() => {
        async function load() {

            const response = await postRequest(URL_EndPoints(null)?.convertToFullCleaning, payloads, null)
            setshow(false)
            if (response?.data?.msg) {
                toast.success("Updated Successfully")

            }
            else if (response?.data?.message) {
                toast.success("Updated Successfully")
                // i am not sure in response it is msg or message that is anyone will run for taoster 

            }
        }



        if (render) {
            swal.fire({
                title: title,
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    swal.fire("Request failed ")
                }
            })
        }
    }, [render])
});
