import moment from 'moment';
import GChatBTN from '../../consts/Chats/ChatButtons/GChatBTN';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallbackAgentForm from './forms/CallbackAgentForm';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { catchCurrentOpenedUrl } from '../../consts/components/Utility';

export const columns = (handleFetchAllCallLogs?: any, customerTrackData?: any) => [

    {
        id: 1,
        reorder: true,
        name: "NAME",
        cell: (row: any) => <GChatBTN
            ticketDataParent={{
                id: null,
                customerid: row?.id,
                random: Math.random(),
                BTNColor: row?.id,
            }}
            Title={row?.first_name || row?.last_name ? row?.first_name + " " + row?.last_name : "NA"}
            Toggleid={"kt_drawer_chat2_toggle2"}
            classes={"text-hover-primary"}
            icons={""}
            keyName={catchCurrentOpenedUrl()}
            trackData={customerTrackData}
        // refs="renewal"
        ></GChatBTN>,
        sortField: 'first_name',
        sortable: true,
        minWidth: "200px",
    },


    {
        id: 2,
        reorder: true,
        name: "LAST CALL DATE",
        cell: (row: any) => moment(row?.last_call_date_time).format("YYYY-MM-DD"),
        sortField: 'last_call_date_time',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 3,
        reorder: true,
        name: "LAST CALL TIME",
        cell: (row: any) => moment(row?.last_call_date_time).format("hh:mm A"),
        sortField: 'last_call_date_time',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 4,
        reorder: true,
        name: "CALL STATUS",
        cell: (row: any) => row?.last_call_status,
        sortField: 'last_call_status',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 5,
        reorder: true,
        name: "CALL COUNT",
        cell: (row: any) => row?.call_count,
        sortField: 'call_count',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 6,
        reorder: true,
        name: "CALLBACK COUNT",
        cell: (row: any) => row?.call_back_count,
        sortField: 'call_back_count',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 7,
        reorder: true,
        name: "LAST CALL TYPE",
        cell: (row: any) => row?.last_call_type,
        sortField: 'last_call_type',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 8,
        reorder: true,
        name: "CUSTOMER SUPPORT AGENT",
        cell: (row: any) => row?.customer_called_data?.customer_support_details?.first_name ? row?.customer_called_data?.customer_support_details?.first_name + " " + row?.customer_called_data?.customer_support_details?.last_name : "",
        // sortField: 'customer_called_data.customer_support_details.first_name',
        // sortable: true,
        minWidth: "200px",
    },
    {
        id: 9,
        reorder: true,
        name: "CALLBACK AGENT",
        cell: (row: any) => <CallbackAgentUpdate row={row} />,
        minWidth: "200px",
    },
];


const CallbackAgentUpdate = ({ row }: any) => {
    const dispatch = useDispatch()
    const adminList = useSelector((store: any) => store.VisitorDataReducer.adminList)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        const updatedData = adminList?.filter((ele: any) => {
            return ele?.id === storeRowP2P?.callback_agent_id
        });
        setshowRes(updatedData?.length > 0 ? updatedData[0] : {
            name: "No Set"
        });
    }, [adminList, storeRowP2P?.callback_agent_id])

    const handleClose = () => {
        dispatch({
            type: "MODAL_OPEN", payload: {
                toggle: false,
                id: null,
            }
        })
        setupdateStatusPop(false)
    }

    return <>
        <span >
            <FontAwesomeIcon onClick={() => setupdateStatusPop(true)} icon={faEdit} /> &nbsp;{showRes?.first_name + ' ' + showRes?.last_name || 'Not Set'}
        </span>
        {storeRowP2P && <CallbackAgentForm
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: handleClose,
                selectedId: {
                    customerId: row?.id,
                },
                adminList,
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
            agentDefaultData={{ label: showRes?.first_name + " " + showRes?.last_name, value: showRes?.id }}
        ></CallbackAgentForm>}
    </>
}
