import { Button } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import { EASEBUZZ_PAYMENT_STATUS } from '../../../DynamicFilteration/components/CommonData';
import { postRequest } from '../../../Server/Requests';
import { URL_EndPoints } from '../../../Server/URL_EndPoints';
import { useState } from 'react';
import { toast } from 'react-toastify';
import InititateTransferWeeklyPayout from '../../../../modules/cleaner/components/weeklyReport/InititateTransferWeeklyPayout';
import moment from 'moment';
import PermissionContextProvider from '../../../../modules/admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../../../../modules/admins/adminConstant';
export const columns = [

    {
        id: 1,
        reorder: true,
        name: 'ID',
        selector: (row: any) => row?.id,
        sortable: true
    },
    {
        id: 13,
        reorder: true,
        name: 'Payout Date time',
        selector: (row: any) => moment(row?.payout_datetime).format("DD-MM-YYYY hh:mm A") ?? "NA",
        sortable: true,
        width: "200px"
    },
    {
        id: 14,
        reorder: true,
        name: 'Payout Amount',
        selector: (row: any) => row?.payout_amount,
        sortable: true
    },
    {
        id: 15,
        reorder: true,
        name: 'Payout Status',
        selector: (row: any) => row?.payout_status,
        sortable: true
    },
    // {
    //     id: 3,
    //     reorder: true,
    //     name: 'Scheme Name',
    //     selector: (row: any) => row?.cleanerPayoutScheme?.scheme_name,
    //     sortable: true
    // },
    {
        id: 3,
        reorder: true,
        name: 'No. Of Attendance',
        selector: (row: any) => row?.week_total_attendance,
        sortable: true
    },
    {
        id: 4,
        reorder: true,
        name: 'Week Start Date',
        selector: (row: any) => row?.week_start_date,
        sortable: true
    },
    {
        id: 5,
        reorder: true,
        name: 'Week End Date',
        selector: (row: any) => row?.week_end_date,
        sortable: true
    },
    {
        id: 6,
        reorder: true,
        name: 'Total Points Earned',
        selector: (row: any) => row?.total_points_earned,
        sortable: true
    },
    {
        id: 7,
        reorder: true,
        name: 'Earned Points Value',
        selector: (row: any) => row?.earned_points_value,
        sortable: true
    },
    {
        id: 8,
        reorder: true,
        name: 'Total Cash Earned',
        selector: (row: any) => row?.total_cash_earned,
        sortable: true
    },
    {
        id: 9,
        reorder: true,
        name: 'Min Weekly Payout',
        selector: (row: any) => row?.min_weekly_payout,
        sortable: true
    },
    {
        id: 10,
        reorder: true,
        name: 'Allowance',
        selector: (row: any) => row?.allowance,
        sortable: true
    },
    {
        id: 11,
        reorder: true,
        name: 'Net Earned',
        selector: (row: any) => row?.net_earned,
        sortable: true
    },
    {
        id: 12,
        reorder: true,
        name: 'Total Deduction',
        selector: (row: any) => row?.total_deduction,
        sortable: true
    },
    // {
    //     id: 16,
    //     reorder: true,
    //     name: 'Phone',
    //     selector: (row: any) => row?.cleaner?.phone,
    //     sortable: true
    // },
    {
        id: 17,
        reorder: true,
        name: 'Tag',
        selector: (row: any) => row?.cleaner?.private_tag,
        sortable: true
    },
    {
        id: 18,
        reorder: true,
        name: 'Action',
        selector: (row: any) =>
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-weekly-payout-report-pay"]}>
                <InititateTransferWeeklyPayout row={row} />
            </PermissionContextProvider>
        ,
        sortable: true,
        width: '200px',
    },

];
