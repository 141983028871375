import React, { memo, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import ReactDropdownMultiSelect from '../../../consts/Select/ReactDropdownMultiSelect';
import ReactDropdownSingleCategory from '../../../consts/Select/ReactDropdownSingleCategory';
import { getRequest, postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { ContainerCss } from '../../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet';
const CreateNewTicketsJobList = ({ handleCloseForm, defaultTicketData }: any) => {
    const userid = JSON.parse(localStorage.getItem("user") || "0")
    const [TicketCategoryData, setTicketCategoryData] = React.useState<any>([])
    const [TicketSources, setTicketSources] = React.useState<any>([])
    const [AdminListData, setAdminListData] = React.useState<any>([])
    const [SupervisorsListData, setSupervisorsListData] = React.useState<any>([])
    const [SuperVisorDefaultData, setSuperVisorDefaultData] = React.useState<any>([])
    const [supervisorids, setsupervisorids] = React.useState<any>([])
    const [adminidsDef, setadminidsDef] = React.useState<any>([])
    const [sourceid, setsourceid] = React.useState<any>(0)
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    
    useEffect(() => {
        async function invokedFilterAPI() {
           
            const getTicketCategoriesData = await getRequest(URL_EndPoints(null)?.getTicketCategories, null)
            setTicketCategoryData(getTicketCategoriesData?.data?.data)
            const getTicketSourcesData = await getRequest(URL_EndPoints(null)?.getTicketSources, null)
            setTicketSources(getTicketSourcesData?.data?.data)
            const getSupervisorListData = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
            setSupervisorsListData(getSupervisorListData?.data?.data)
            const getAdminListData = await getRequest(URL_EndPoints(null)?.getAdminList, null)
            setAdminListData(getAdminListData?.data?.data)
        }
        invokedFilterAPI()
    }, [])
    const [TicketPayloads, setTicketPayloads] = useState<any>({
        title: "Job List Ticket",
        customerid: defaultTicketData?.customerData?.id,
        categoryid: 0,
        source_id: 0,
        visible_id: 0,
        admins: [],
        answer: "",
        cleaners: [],
        userid: 0
    })
 
   
    const AdminListDatas: any = AdminListData?.filter((ele: any) => ele.id === userid)
    const updatedData = AdminListDatas?.map((ele: any, i: number) => {
        let number = ele["first_name"] ? '(' + ele["phone"] + ')' : "(NA)"
        if (ele["first_name"]) {
            const newData = {
                name: ele["first_name"] + ' ' + ele["last_name"] + number,
                id: ele["id"],
            }
            return newData
        }
    })
    useEffect(() => {
        setadminidsDef(AdminListDatas?.length > 0 ? [AdminListDatas[0]?.id] : [])
    }, [AdminListData])
    const AdminListDatasOptions = AdminListData?.map((ele: any, i: number) => {
        let number = ele["first_name"] ? '(' + ele["phone"] + ')' : "(NA)"
        if (ele["first_name"]) {
            const newData = {
                name: ele["first_name"] + ' ' + ele["last_name"] + number,
                id: ele["id"],
            }
            return newData
        }
    })
    const SupervisorDataOptions = SupervisorsListData?.map((ele: any, i: number) => {
        let number = ele["first_name"] ? '(' + ele["phone"] + ')' : "(NA)"
        if (ele["first_name"]) {
            const newData = {
                name: ele["first_name"] + ' ' + ele["last_name"] + number,
                id: ele["id"],
            }
            return newData
        }
    })
    const SourceDefaultData: any = TicketSources?.filter((ele: any) => ele.id === defaultTicketData?.customerData?.source_id)
    const updatedDataSource = SourceDefaultData?.map((ele: any, i: number) => {
        if (ele["name"] != null) {
            const newData = {
                name: ele["name"],
                id: ele["id"],
            }
            return newData
        }
    })
    const SourceUpdateDataOptionss = TicketSources?.map((ele: any, i: number) => {
        if (ele["name"] != null) {
            const newData = {
                name: ele["name"],
                id: ele["id"],
            }
            return newData
        }
    })
    useEffect(() => {
        setsourceid(SourceDefaultData?.length > 0 ? SourceDefaultData[0]?.id : 0)
    }, [TicketSources])
    const TicketCategoryDataOptions = TicketCategoryData?.map((ele: any, i: number) => {
        if (ele["category_name"] != null) {
            const newData = {
                name: ele["category_name"],
                id: ele["id"],
            }
            return newData
        }
    })
    useEffect(() => {
        let arr: any = []
        let ids: any = []
        let temp = defaultTicketData?.cleaner?.supervisors
        for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < SupervisorsListData?.length; j++) {
                if (temp[i]?.supervisorData?.id == SupervisorsListData[j].id) {
                    arr.push(temp[i].supervisorData)
                    ids.push(temp[i].supervisorData?.id)
                }
            }
        }
        const updatedDataSupervisor = arr?.map((ele: any, i: number) => {
            let number = ele["first_name"] ? '(' + ele["phone"] + ')' : "(NA)"
            if (ele["first_name"]) {
                const newData = {
                    name: ele["first_name"] + ' ' + ele["last_name"] + number,
                    id: ele["id"],
                }
                return newData
            }
        })
        setsupervisorids(ids)
        setSuperVisorDefaultData(updatedDataSupervisor)
    }, [SupervisorsListData])
    const handleChangeInputData = (event: any, name: string) => {
        if (name === "admins") {
            setTicketPayloads({ ...TicketPayloads, [name]: event })
        }
        else if (name === "cleaners") {
            setTicketPayloads({ ...TicketPayloads, [name]: event })
        }
        else if (name === "title") {
            setTicketPayloads({ ...TicketPayloads, [name]: event.value })
        }
        else if (name === "answer") {
            setTicketPayloads({ ...TicketPayloads, [name]: event.value })
        }
        else {
            if (event == null) {
                setTicketPayloads({ ...TicketPayloads, [name]  : 0 })
            }
            else {
                setTicketPayloads({ ...TicketPayloads, [name]: "customerid" ? event[0] : +event[0] })
            }
        }
    }
    const handleCreateTicketes = async () => {
        setLoader(true)
        const payload = {
            title: TicketPayloads?.title,
            customerid: TicketPayloads?.customerid ,
            categoryid: TicketPayloads?.categoryid,
            source_id: TicketPayloads?.source_id > 0 ? TicketPayloads?.source_id : sourceid,
            visible_id: TicketPayloads?.visible_id,
            admins: TicketPayloads?.admins?.length > 0 ? TicketPayloads?.admins : adminidsDef,
            answer: TicketPayloads?.answer,
            cleaners: TicketPayloads?.cleaners?.length > 0 ? TicketPayloads?.cleaners : supervisorids,
            userid: userid,
        }
        const result = await postRequest(URL_EndPoints()?.createticketByAdmin, payload, setLoader)
        if (result?.data?.status == 200) {
            toast.success("Your Ticket Created", result?.data?.data)
            handleCloseForm()
            setDisable(200)
        } else {
            toast.error(result.data?.msg)
            setDisable(200)
        }
    }
    return (
        <div className="p-4" style={ContainerCss}>
            <div style={{}} className="d-flex justify-content-between  pt-2">
                <h5 className="ms-4">Create New Ticket </h5>
                <i className="bi bi-x fs-1 text-dark cursor-pointer" onClick={handleCloseForm}></i>
            </div>
            <div className="p-5 mr-auto">
                <div className='modal-content ' >
                    <div className="row mb-5">
                        {/* <hr /> */}
                        <div className="col-12  mb-3">
                            <span className='ms-1 fw-bold'>Title </span>
                            <input
                                placeholder='Title  Here...'
                                type='text'
                                value={TicketPayloads?.title}
                                // defaultValue={"Job List Ticket"}
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={(event) => handleChangeInputData(event.target, "title")}
                            />
                        </div>
                        <div className="col-12 mb-2">
                            <span className='ms-1 fw-bold'> Description</span>
                            <div className="form-outline">
                                <textarea className="form-control" name='description' placeholder='Message' id="textAreaExample1" rows={4}
                                value={TicketPayloads?.answer}
                                    onChange={(event) => handleChangeInputData(event.target, "answer")}
                                ></textarea>
                            </div>
                        </div>
                        {/* <hr /> */}
                    </div>
                    {/* ============================================== section ========================================================= */}
                    <div className="row mb-5">
                        <div className="col-12  mb-3 d-flex flex-column ">
                            <span className='ms-3 fw-bold mb-2'> Customer Name</span>
                        <span className='ms-3 fw-bold  badge badge-primary'>{defaultTicketData?.customerData?.first_name+" " + defaultTicketData?.customerData?.last_name}</span>
                        </div>
                    </div>
                    {/* ============================================== section ========================================================= */}
                    <div className="row mb-5">
                        <div className="col-12  mb-1">
                            <span className='ms-3 fw-bold'>Choose to show to customer or not</span>
                            <ReactDropdownSingleCategory
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select Here"
                                SelectData={[{ name: "No, Don't show to customer", id: "0" }, { name: "Yes, Don't show to customer", id: "1" }]}
                                DynamicKey={"name"}
                                id={"id"}
                                name="visible_id"
                            ></ReactDropdownSingleCategory>
                        </div>
                        {/* <hr /> */}
                    </div>
                    {/* ============================================== section ========================================================= */}
                    <div className="row mb-5">
                        <div className="col-6  mb-3 ">
                            <span className='ms-3 fw-bold'>Select Category</span>
                            <ReactDropdownSingleCategory
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select Category"
                                SelectData={TicketCategoryDataOptions}
                                DynamicKey={"category_name"}
                                id={"id"}
                                name="categoryid"
                            ></ReactDropdownSingleCategory>
                        </div>
                        <div className="col-6  mb-3">
                            <span className='ms-3 fw-bold'>Select Source</span>
                            <ReactDropdownSingleCategory
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select Source"
                                SelectData={SourceUpdateDataOptionss}
                                DynamicKey={"name"}
                                id={"id"}
                                name="source_id"
                                defaultData={updatedDataSource?.length > 0 ? updatedDataSource : {}}
                            ></ReactDropdownSingleCategory>
                        </div>
                        <hr />
                        <span className='ms-3 fw-bold'> Assign To</span>
                    </div>
                    {/* ============================================== section ========================================================= */}
                    <div className="row mb-5">
                        <div className="col-6  mb-3">
                            <span className='ms-3 fw-bold'>Select Admin Users</span>
                            {AdminListData && <ReactDropdownMultiSelect
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select admins"
                                SelectData={AdminListDatasOptions}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name="admins"
                                defaultData={updatedData}
                            ></ReactDropdownMultiSelect>}
                        </div>
                        <div className="col-6  mb-3">
                            <span className='ms-3 fw-bold'>Select Supervisors</span>
                            <ReactDropdownMultiSelect
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select Supervisor"
                                SelectData={SupervisorDataOptions}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name="cleaners"
                                defaultData={SuperVisorDefaultData}
                            ></ReactDropdownMultiSelect>
                        </div>
                        {/* <hr /> */}
                    </div>
                    {/* ==================================================================================================== */}
                </div>
                <div className='modal-footer'>
                    <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={handleCreateTicketes}>
                        {
                            loader ?
                                <>
                                    <div className='spinner-border mr-15' role='status'></div>
                                    <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Creating.....</h4>
                                </>
                                : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Create Ticket</h4>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}
export default memo(CreateNewTicketsJobList)
