/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch, useSelector } from 'react-redux'
import React from "react"
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { getRequest, postRequest, postRequestWithFile } from '../../consts/Server/Requests';
import './chatbox_custom.css'
import TicketChats from './ChatBoxComponents/TicketChats';
import WhatsappChats from './ChatBoxComponents/WhatsappChats';
import moment from 'moment';
import { toast } from 'react-toastify';
import InternalTicketTicketOpenCustomer from './InternalTicketTicketOpenCustomer';
import SweetAlertConfirm from '../SweetAlert/SweetAlertConfirm';
import { MAIN_ADMIN_BASE_API_URL, TEST_ADMIN_BASE_API_URL } from '../../../apiGlobally';
import SweetAlertCloseTimer from '../SweetAlert/SweetAlertCloseTimer';
import { catchCurrentOpenedUrl } from '../components/Utility';
import ChatSubHeaderAction from './ChatSubHeaderAction';
import ClickupTaskComment from '../SweetAlert/ClickupTaskComment';
import { enableFeatureList4CustomerDashbaord } from '../../modules/admins/adminConstant';
import ClickUpCallCustomer from './ClickUpCallCustomer';
import { adminParamsGenerator, adminQueryGenerator } from '../../modules/admins/adminUtils';
type Props = {
    isDrawer?: boolean
    ticketreplies?: any
    tickets?: any
    userData?: any
    TicketDatas?: any
    TicketTepliesData?: any
    setShowImagesToggle?: any
    setShowImagesLink?: any
    WhatsappMessage?: any
    WhatsappTemplate?: any
    MessageSource?: any
    customerDetailData?: any
    setWhatsappMessage?: any
    invokedApi?: any,
    setTicketsData?: any,
    campaign_id?: any,
    setTicketTepliesData?: any,
}

// 4566
const ChatBox: FC<Props> = (props, { isDrawer = false }) => {
    const clickUpTaskComment = useSelector((store: any) => store.RefreshPage.clickUpTaskComment)
    const CallAlertRender = useSelector((store: any) => store.RefreshPage.CallAlertRender)
    const customerDetailData = props?.customerDetailData
    const TicketDatas = props?.TicketDatas
    const trackData = useSelector((store: any) => store.ChatReducers.trackData?.[catchCurrentOpenedUrl()]) || []
    const totalRowDataForCustomerDashboard = useSelector((store: any) => store.ChatReducers.totalRowDataForCustomerDashboard?.[catchCurrentOpenedUrl()]) || []
    const userid: any = JSON.parse(localStorage.getItem("user") || "")
    const WhatsappMessage = props?.WhatsappMessage
    const WhatsappTemplate = props?.WhatsappTemplate
    const MessageSource = props?.MessageSource
    const setWhatsappMessage = props?.setWhatsappMessage
    const invokedApi = props?.invokedApi
    const QuickReplyMessageInput = useSelector((store: any) => store.QuickReplyReducer.quickReplyMsg)
    const QuicKReplyRender = useSelector((store: any) => store.QuickReplyReducer.QuicKReplyRender)
    const refss: any = useRef(null)
    const [ticketreplies, setTicketReplies] = useState<any>(props?.TicketTepliesData)
    const [whatsappMessageReplies, setwhatsappMessageReplies] = useState<any>(WhatsappMessage)
    const [WhatsappTemplateMessage, replyWhatsappTemplateMessage] = useState<any>(null)
    const [isTemplate, setIsTemplate] = useState<any>(false)
    const { setShowImagesToggle, setShowImagesLink } = props
    const [messages, setMessage] = useState<string>("")
    const dispatch: any = useDispatch()
    const [SwitchStatus, setSwitchStatus] = useState<any>("tickets");
    const [customerWhatsappIsActive, setcustomerWhatsappIsActive] = useState<any>(null);
    const [isloading2, setloading2] = useState<boolean>(false);
    const [localUserid, setlocalUserid] = useState<any>(JSON.parse(localStorage.getItem("user") || ""));

    const scrollBottomAuto = () => {
        refss.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const [fileSelected, setFileSelected] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [currentButtonLoaderStatus, setCurrentButtonLoaderStatus] = useState<any>("");
    const [file, setFile] = useState<any>(null);
    const [key, setKey] = useState(0);


    const handleResetClick = () => {
        setFile(null);
        setFileSelected(false);
        setKey(key + 1);
    };

    async function TicketReplies() {
        setloading2(true)
        const response = await postRequest(URL_EndPoints()?.getticketreplies, { "ticketid": props?.TicketTepliesData?.ticket?.id }, setloading2)
        setTicketReplies(response.data)
        setloading2(false)
    }

    React.useEffect(() => {
        clickUpTaskComment?.clcikUp_render && TicketReplies()
    }, [clickUpTaskComment?.clcikUp_render])



    React.useEffect(() => {
        setMessage((PrevMessageInput: any) => PrevMessageInput != "" ? PrevMessageInput + "\n \n" + QuickReplyMessageInput : QuickReplyMessageInput)
    }, [QuicKReplyRender])


    React.useEffect(() => {
        setMessage("")
        setTicketReplies(props?.TicketTepliesData)
    }, [props?.TicketTepliesData?.data])


    React.useEffect(() => {
        setMessage("")
        setwhatsappMessageReplies(WhatsappMessage)
    }, [WhatsappMessage])


    React.useEffect(() => {
        scrollBottomAuto()
    }, [messages, ticketreplies, MessageSource])


    React.useEffect(() => {
        isTemplate && setMessage("")
    }, [isTemplate, messages])


    const sendMessage = async (e: React.MouseEvent) => {

        let newMessage = messages

        newMessage = newMessage.replace(/\r?\n/g, '<br />')
        setButtonLoading(true)
        setCurrentButtonLoaderStatus(e.currentTarget.id)

        const isPrivateMessage = e.currentTarget.id === 'privateMessage';
        e.currentTarget.id == 'privateMessage' ?
            await postRequestWithFile(URL_EndPoints()?.notesonticketbyadmin, { "ticketid": props?.TicketTepliesData?.ticket?.id, "userid": localUserid, "answer": newMessage, "image": file }, setloading2)
            :
            e.currentTarget.id == 'sendToAm' ?
                await postRequestWithFile(URL_EndPoints()?.writeCommentOnTicketForAreaManager, { "ticketid": props?.TicketTepliesData?.ticket?.id, "userid": localUserid, "answer": newMessage, "image": file }, setloading2)
                :
                await postRequestWithFile(URL_EndPoints()?.replyonticketbyadmin, { "ticketid": props?.TicketTepliesData?.ticket?.id, "userid": localUserid, "answer": newMessage, "image": file }, setloading2)


        setButtonLoading(false)
        setCurrentButtonLoaderStatus("")

        setMessage('')
        handleResetClick()
        TicketReplies()
        dispatch(
            {
                type: 'QUICK_REPLY_MESSAGE', payload: ""
            }
        )
    }


    const SwitchToWhatsappMessage = async (from: any) => {
        const CustomerNumberIsActive = WhatsappMessage?.filter((ele: any) => ele.statusString == "SENT")

        const MessageSorted: any = whatsappMessageReplies?.sort((a: any, b: any) => {
            let a1: any = new Date(b.created)
            let a2: any = new Date(a.created)
            return a2 - a1
        })
        const latestMessage = MessageSorted?.length > 0 ? MessageSorted[MessageSorted?.length - 1] : {}

        if (from == "Whatsapp Message") {

            const createdAtTimestamp = latestMessage?.created;

            const createdAtMoment = moment(createdAtTimestamp);

            const currentMoment = moment();

            const duration = moment.duration(currentMoment.diff(createdAtMoment));

            const isLessThan24Hours = duration.asHours() <= 24;

            setMessage(isLessThan24Hours ? "Customer is  active" : "Customer is not active")
            setcustomerWhatsappIsActive(isLessThan24Hours ? "isExpired" : null)

        }
        else {
            setMessage("")
            setcustomerWhatsappIsActive(null)
        }
        setSwitchStatus(from)
    }

    // whatsapp reply message  handler
    const ReplyToWhatappMessageHandler = async () => {


        if (!isTemplate) {
            setButtonLoading(true)
            if (messages) {
                const whatsappMessage = await postRequest(URL_EndPoints()?.sendOpenSessionWhatsApp, {
                    ticketId: props?.TicketTepliesData?.ticket?.id,
                    customerId: customerDetailData?.id,
                    phoneNumber: customerDetailData?.phone,
                    message: messages,
                    userId: userid,
                }, setloading2)
                if (whatsappMessage?.data?.status == 200) {
                    setButtonLoading(false)
                    setTicketReplies({ data: [...ticketreplies?.data, whatsappMessage?.data?.data] })
                }
                setMessage("")
            }
            else {
                setButtonLoading(false)
                toast.error("Text message missing", { position: "top-center" })
            }
            setButtonLoading(false)
            // TicketReplies()
        }

        else {
            if (WhatsappTemplateMessage != null) {
                setButtonLoading(true)
                const whatsappMessageTemplate = await postRequest(URL_EndPoints()?.sendTemplateWhatsApp, {
                    ticketId: props?.TicketTepliesData?.ticket?.id,
                    customerId: customerDetailData?.id,
                    phoneNumber: customerDetailData?.phone,
                    templateId: +WhatsappTemplateMessage?.value,
                    userId: userid,
                }, setloading2)
                // whatsapmesaageReply()
                if (whatsappMessageTemplate?.data?.status == 200) {
                    setButtonLoading(false)
                    toast.success("Message Has been sent successfully", { position: "top-center" })
                    TicketReplies()
                    replyWhatsappTemplateMessage(null)
                }
            }
            else {
                setButtonLoading(false)
                toast.error("Templateid missing", { position: "top-center" })
            }
            setButtonLoading(false)
        }
    }


    const SeeImageFullWidth = (images: any) => {
        setShowImagesLink(images)
        setShowImagesToggle((prev: any) => !prev)
    }


    const textRef: any = React.useRef();
    React.useEffect(() => {
        if (textRef && textRef.current) {
            textRef.current.style.height = "0px";
            const taHeight = textRef.current.scrollHeight;
            textRef.current.style.height = taHeight + "px";
        }
    }, [messages]);

    //image uploading
    const onChangeFile = (event: any) => {
        setFileSelected(true)
        setFile(event?.target?.files[0])
    }


    const WhatSappMessageHandler = async () => {
        const getWhatsappMessage = await postRequest(URL_EndPoints()?.get_LatestWhatsAppMessageByNumber, { customerphone: customerDetailData?.phone, pageSize: 50, pageNumber: 1 }, null)
        setWhatsappMessage(getWhatsappMessage.data.data.messages.items)

        dispatch({
            type: "CURRENT_MESSAGE_SOURCE", payload: {
                CurrentMessageSource: 1,
                CurrentMessageSourceRender: Math.random(),
                from: "whatsappMessageSource"
            }
        })
    }

    const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
    const [Re_Render, setRe_Render] = React.useState<any>(null)
    const [showCallDropdown, setShowCallDropdown] = useState(false)
    const [callDropdownNumber, setCallDropdownNumber] = useState<any>(null)
    const [defaultNumberValue, setDefaultNumberValue] = useState<any>(null)
    const [numberListData, setNumberListData] = useState([])

    const getNumbers = async () => {
        const response = await getRequest(URL_EndPoints(adminQueryGenerator("getCallingNumbers", { userType: 3 }))?.getCallingNumbers, null)
        setNumberListData(response?.data?.data)
        const defValue = response?.data?.data?.find((ele: any) => ele?.is_default == 1)
        setCallDropdownNumber(defValue ? defValue?.phone : defValue)
        setDefaultNumberValue(defValue ? { item: { label: defValue?.phone, value: defValue?.id } } : null)
    }

    useEffect(() => {
        getNumbers()
    }, [])

    const callToCleaner = async () => {
        setShowCallDropdown(true)
    }

    const CallNowButton = () => {
        if (!callDropdownNumber) {
            toast.error("Please select number", { position: "top-center" })
            return
        }
        setRe_Render(Math.random())
    }

    const [isNextOrPrev, setIsNextOrPrev] = useState<any>(null)
    const ticketData = useSelector((store: any) => store.ChatReducers.TicketData)
    const disptchTicketIdWhileInternalTicketOpen = (currentRowElementForChampDashboard: any) => {

        let ticket: any = {
            customerid: ticketData?.customerid ? ticketData?.customerid : "",
            id: null,
            externalTicketSource: false,
        }

        if (currentRowElementForChampDashboard) {
            ticket.id = currentRowElementForChampDashboard?.id || null
            ticket.reasonid = currentRowElementForChampDashboard?.renewal_reason_id
            ticket.subscriptionid = currentRowElementForChampDashboard?.id
        }

        dispatch({ type: "CHAT_DATA", payload: ticket })
        dispatch({
            type: "TAB_KEY", payload: {
                keyName: null,
                render: null
            }
        })

    }

    const [currentIndex, setCurrentIndex] = useState<any>(null);
    let isAllowedToStoreRowDataForCustomerDashboard = catchCurrentOpenedUrl() in enableFeatureList4CustomerDashbaord

    const previuosHandler = () => {

        if (trackData == undefined) return toast.error("Feature is not available for this List !")
        let index = trackData.indexOf(customerDetailData?.id);
        if (index !== -1 && index < trackData.length) {
            let currentIndexValue = currentIndex ? currentIndex - 1 : index - 1
            let prevElement = trackData[currentIndexValue];
            if (prevElement == undefined) return toast.error("Prev Record Not Available", { position: "top-center" })
            let prevCurrentRowElementForChampDashboard = totalRowDataForCustomerDashboard[currentIndexValue]
            dispatch({ type: "CURRENT_ROW_DATA_FOR_CUSTOEMR_DASHBOARD", payload: { keyName: catchCurrentOpenedUrl(), data: prevCurrentRowElementForChampDashboard } })
            setCurrentIndex(currentIndexValue);
            setIsNextOrPrev("Prev")
            invokedApi(prevElement)
            disptchTicketIdWhileInternalTicketOpen(isAllowedToStoreRowDataForCustomerDashboard ? prevCurrentRowElementForChampDashboard : null)
        }

    }

    const nextHandler = () => {

        if (trackData == undefined) return toast.error("Feature is not available for this List !")
        let index = trackData.indexOf(customerDetailData?.id);
        if (index !== -1 && index < trackData.length) {
            let currentIndexValue = currentIndex ? currentIndex + 1 : index + 1
            let prevElement = trackData[currentIndexValue];
            if (prevElement == undefined) return toast.error("Next Record Not Available", { position: "top-center" })
            let nextCurrentRowElementForChampDashboard = totalRowDataForCustomerDashboard[currentIndexValue]
            dispatch({ type: "CURRENT_ROW_DATA_FOR_CUSTOEMR_DASHBOARD", payload: { keyName: catchCurrentOpenedUrl(), data: nextCurrentRowElementForChampDashboard } })

            setCurrentIndex(currentIndexValue);
            setIsNextOrPrev("Next")
            invokedApi(prevElement)
            disptchTicketIdWhileInternalTicketOpen(isAllowedToStoreRowDataForCustomerDashboard ? nextCurrentRowElementForChampDashboard : null)
        }
    }

    const [openModalClickUpTask, setOpenModalClickUpTask] = useState<any>(null)
    const [clickUpCommentLoader, setClickUpCommentLoader] = useState<any>(false)

    return (
        <>

            <InternalTicketTicketOpenCustomer
                WhatSappMessageHandler={WhatSappMessageHandler}
                callToCleaner={callToCleaner}
                previuosHandler={previuosHandler}
                nextHandler={nextHandler}
                isNextOrPrev={isNextOrPrev}
            />



            <ChatSubHeaderAction
                setOpenModalClickUpTask={setOpenModalClickUpTask}
                cleanercode={customerDetailData?.cleanercode}
                campaign_id={TicketDatas?.id && TicketDatas?.campaign_id == 3}
                clickUpCommentLoader={clickUpCommentLoader}
            />

            {
                MessageSource == "whatsappMessageSource" ?
                    <WhatsappChats props={{ isloading2, isDrawer, refss, SeeImageFullWidth, whatsappMessageReplies }} />
                    :
                    <TicketChats props={{ isloading2, isDrawer, ticketreplies, refss, SeeImageFullWidth, fileSelected, file, handleResetClick, onChangeFile, key, textRef, messages, setMessage, sendMessage, buttonLoading, currentButtonLoaderStatus, from: "ticketschat", SwitchStatus, SwitchToWhatsappMessage, customerWhatsappIsActive, WhatsappTemplate, ReplyToWhatappMessageHandler, replyWhatsappTemplateMessage, isTemplate, setIsTemplate, customerDetailData, setTicketReplies }} />
            }

            {
                Re_Render ? <SweetAlertConfirm
                    Render={Re_Render}
                    payloads={{
                        userid: userid,
                        customerid: customerDetailData?.id,
                        callingNumberId: callDropdownNumber,
                    }}
                    localKey={localKeyCheck}
                    Apis={MAIN_ADMIN_BASE_API_URL}
                    TestApis={TEST_ADMIN_BASE_API_URL}
                    ApiEndPoints={"/clickToCallFromTicket"}
                /> : <></>
            }
            {
                CallAlertRender ? <SweetAlertCloseTimer
                    Re_Render={CallAlertRender}
                /> : <></>
            }
            {
                showCallDropdown ? <ClickUpCallCustomer
                    show={showCallDropdown}
                    setShow={setShowCallDropdown}
                    CallNowButton={CallNowButton}
                    listData={numberListData}
                    setCallDropdownNumber={setCallDropdownNumber}
                    defValue={defaultNumberValue}
                /> : <></>
            }



            {
                openModalClickUpTask && <ClickupTaskComment
                    confirm='Yes'
                    cancel='No'
                    payloads={{
                        render: openModalClickUpTask,
                        payloadData: {
                            taskId: customerDetailData?.cleanercode,
                            ticketId: TicketDatas?.id,
                        },
                        successTitle: 'Changes Are Saved Successfully ',
                        FailedTitle: 'Changes Cancelled ',
                    }}
                    setOpenModalClickUpTask={setOpenModalClickUpTask}
                    setClickUpCommentLoader={setClickUpCommentLoader}
                    localKey={true}
                ></ClickupTaskComment>
            }
        </>

    )
}
export { ChatBox }
