import moment from "moment";
import GChatBTN from "../../consts/Chats/ChatButtons/GChatBTN";
import { getName } from "../admins/adminUtils";


export const UnsuccessfulSubsColums = (champTrackData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        selector: (row: any) => row?.id ? row?.id : "NA",
        sortField: 'id',
        sortable: true,
        // width: "80px"
    },
    {
        id: 2,
        reorder: true,
        name: "NAME",
        selector: (row: any) => {
            return <GChatBTN
                ticketDataParent={{
                    id: null,
                    customerid: row?.customerid
                }}
                Title={row?.name}
                Toggleid={"kt_drawer_chat2_toggle2"}
                classes={"text-hover-primary"}
            // icons={"commenting"}
            // from="seacching"
            ></GChatBTN>

        },
        sortField: 'name',
        sortable: true,
        width: "200px"
    },
    {
        id: 4,
        reorder: true,
        name: "POC",
        selector: (row: any) => getName(row?.ctCustomer?.customerAssignedAgent),
        sortField: 'ctCustomer.customerAssignedAgent.first_name',
        sortable: true,
        width: "150px"

    },
    {
        id: 13,
        reorder: true,
        name: "TYPE",
        selector: (row: any) => row?.subscription_type ? row?.subscription_type : "NA",
        sortField: 'subscription_type ',
        sortable: true,
        width: "150px",

    },
    {
        id: 14,
        reorder: true,
        name: "START DATE",
        selector: (row: any) => row?.startdate ? moment(row?.startdate).format("DD-MM-YYYY") : "NA",
        sortField: 'startdate',
        sortable: true,
        // width: "80px"
    },
    {
        id: 5,
        reorder: true,
        name: "END DATE",
        selector: (row: any) => row?.enddate ? moment(row?.enddate).format("DD-MM-YYYY") : "NA",
        sortField: 'enddate',
        sortable: true,
        // width: "80px"
    },
    {
        id: 6,
        reorder: true,
        name: "PAYMENT METHOD",
        selector: (row: any) => row?.paymentMethod?.short_name ? row?.paymentMethod?.short_name : "NA",
        sortField: 'paymentMethod.short_name',
        sortable: true,
        // width: "80px"
    },
    {
        id: 7,
        reorder: true,
        name: "TIMESLOT",
        selector: (row: any) => row?.timeslotData?.name ? row?.timeslotData?.name : "NA",
        sortField: 'timeslotData.name',
        sortable: true,
        // width: "80px"
    },
    {
        id: 8,
        reorder: true,
        name: "CX TIMESLOT",
        selector: (row: any) => row?.timeslotCustomerSelectedDetail?.name ? row?.timeslotCustomerSelectedDetail?.name : "NA",
        sortField: 'timeslotCustomerSelectedDetail.name',
        sortable: true,
        // width: "80px"
    },
    {
        id: 9,
        reorder: true,
        name: "VEHICLE NO.",
        selector: (row: any) => row?.vehicle?.vehicleno ? row?.vehicle?.vehicleno : "NA",
        sortField: 'vehicle.vehicleno',
        sortable: true,
        // width: "80px"
    },
    {
        id: 10,
        reorder: true,
        name: "FREQUENCY",
        selector: (row: any) => row?.masterFrequency?.name ? row?.masterFrequency?.name : "NA",
        sortField: 'masterFrequency.name',
        sortable: true,
        width: "200px"
    },
    {
        id: 11,
        reorder: true,
        name: "MONTHS",
        selector: (row: any) => row?.months ? row?.months : "NA",
        sortField: 'months',
        sortable: true,
        // width: "80px"
    },
    {
        id: 12,
        reorder: true,
        name: "CREATED AT",
        selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format("DD-MM-YYYY") : "NA",
        sortField: 'createdAt',
        sortable: true,
        // width: "80px"
    },


]

