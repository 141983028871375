
export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },
  {
    name: 'Status',
    cell: (row: any) => row?.status?.name,
    reorder: true,
    sortable: true,
    sortField: 'status.name',
    id: 3,
    width: '150px',
  },

  {
    name: 'Sub Status',
    cell: (row: any) => row?.subStatus?.name,
    sortField: 'subStatus.name',
    sortable: true,
    reorder: true,
    id: 4,
    width: '150px',
  },
  {
    name: 'Status Group',
    cell: (row: any) => row?.statusGroup?.name,
    sortField: 'statusGroup.name',
    sortable: true,
    reorder: true,
    id: 5,
    width: '150px',
  },

]
