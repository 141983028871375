import { Dialog } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import moment from "moment";
import MultiSelect from './common/MultiSelect'
import CleanerTableBodyComponent from './common/CleanerTableBodyComponent';
import JobDetailsModal from '../cleaner/cleaner-items/JobDetailsModal';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { KTCardBody } from '../../../_metronic/helpers';
import { ColModal, GroupModal, RowModal } from '../../consts/widgets/forms/FormsModals';
import "./areawise.css"
import IsLoading from '../../consts/IsLoading/IsLoading';
import SingleSelectSearchDetailsDefault from '../../consts/filtersComponents/SingleSelectSearchDetailsDefault';

const AreaWiseAvailibilityByCleaners = ({ champId, champsDetails, refereshID }: any) => {

  const [cleanerStats, setCleanerStats] = React.useState<any>([])
  const [distenceRadeus, setDistenceRadeus] = React.useState<any>(2)
  const [dates, setDates] = React.useState<any>([])
  const [empty] = React.useState<any>([])
  const [timeSlots, setTimeSlots] = React.useState<any>([])
  const [timeSlotsfilter, settimeSlotsfilter] = React.useState([])
  const [SelectedTimingMultiSelect, setSelectedTimingMultiSelect] = React.useState<any>([])
  const [timingSlots, setTimingslots] = React.useState<any>([])
  const [isModalOpen, setModalOpen] = React.useState(false)
  const [isCleanerModelOpen, setCleanerModelOpen] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [attendencedatefrom, setAttendencedatefrom] = React.useState<any>(moment().day("Monday").format('YYYY-MM-DD'))
  const [attendencedateto, setAttendencedateto] = React.useState<any>(moment().day(7).format('YYYY-MM-DD'))
  const [cleanerId, setCleanerId] = React.useState<any>(null)
  const [jobdetailsCustomer, setjobdetailsCustomer] = React.useState({})
  const [loading2, setloading2] = React.useState(false)
  const data = useMemo(() => cleanerStats, [cleanerStats])
  const [cleanerLists, setCleanerLists] = React.useState<any>([])

  useEffect(() => {
    champId && setCleanerId(champId ?? null)
  }, [champId])


  React.useEffect(() => {
    async function loadsData() {
      setLoading(true)
      const payloads = {
        fromDate: attendencedatefrom,
        toDate: attendencedateto,
        champId: cleanerId,
        timeslots: empty || SelectedTimingMultiSelect,
        distenceRadius: +distenceRadeus
      }

      const result = await postRequest(URL_EndPoints()?.getAvalabilitybyChampId, payloads, setLoading)
      setDates(result?.data?.dates)
      settimeSlotsfilter(result?.data?.timeslots)
      setCleanerStats(result?.data?.data)

      const getTimelosts = await getRequest(URL_EndPoints(null)?.gettimeslots, null)
      setTimeSlots(getTimelosts.data.data)

      const CleanerLists = await getRequest(URL_EndPoints(null)?.cleanerlist, null)
      setCleanerLists(CleanerLists?.data?.data)
    }
    loadsData()
  }, [])
  React.useEffect(() => {
    async function loadsData() {
      if (SelectedTimingMultiSelect.length != 0) {
        let arr = []
        for (let i = 0; i < timingSlots.length; i++) {
          let payload = {
            name: timingSlots[i]
          }
          arr.push(payload)
        }
        setTimeSlots(arr)
      }
      setloading2(true)
      const payloads = {
        fromDate: attendencedatefrom,
        toDate: attendencedateto,
        champId: cleanerId,
        timeslots: SelectedTimingMultiSelect,
        distenceRadius: +distenceRadeus
      }
      const result = await postRequest(URL_EndPoints()?.getAvalabilitybyChampId, payloads, setloading2)
      setDates(result?.data?.dates)
      settimeSlotsfilter(result?.data?.timeslots)
      setCleanerStats(result?.data?.data)
    }
    loadsData()
  }, [refereshID])
  const handleFromDateChange = (e: any) => {
    setloading2(true)
    setAttendencedatefrom(e.target.value)
    setloading2(false)
  }
  const handleToDateChange = (e: any) => {
    setloading2(true)
    setAttendencedateto(e.target.value)
    setloading2(false)
  }
  const handleClick = async () => {
    async function loadsData() {
      if (SelectedTimingMultiSelect.length != 0) {
        let arr = []
        for (let i = 0; i < timingSlots.length; i++) {
          let payload = {
            name: timingSlots[i]
          }
          arr.push(payload)
        }
        setTimeSlots(arr)
        setloading2(true)
        const payloads = {
          fromDate: attendencedatefrom,
          toDate: attendencedateto,
          champId: cleanerId,
          timeslots: SelectedTimingMultiSelect,
          distenceRadius: +distenceRadeus
        }
        const result = await postRequest(URL_EndPoints()?.getAvalabilitybyChampId, payloads, setloading2)
        setDates(result?.data?.dates)
        settimeSlotsfilter(result?.data?.timeslots)
        setCleanerStats(result?.data?.data)
      }
      else {
        setloading2(true)
        const payloads = {
          fromDate: attendencedatefrom,
          toDate: attendencedateto,
          champId: cleanerId,
          timeslots: SelectedTimingMultiSelect,
          distenceRadius: +distenceRadeus
        }
        const result = await postRequest(URL_EndPoints()?.getAvalabilitybyChampId, payloads, setloading2)
        setDates(result?.data?.dates)
        settimeSlotsfilter(result?.data?.timeslots)
        setCleanerStats(result?.data?.data)
      }
    }
    loadsData()
  }
  const handleDistanceFormData = (e: any) => {
    setDistenceRadeus(e.target.value)
  }
  const handleJobDetailSubmit = (timeslot: any) => {
    setjobdetailsCustomer(timeslot)
    setModalOpen(true)
  }
  const handleCleanerDetailsSubmit = (id: any) => {
    setCleanerModelOpen(!isCleanerModelOpen)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleChangeCleaner = (event: any, name: any) => {
    setCleanerId(event?.value)
  }

  return (
    <>

      {
        isLoading ?

          <div className='card  mt-7 p-4 mb-2  ' style={{ top: "40px", zIndex: 99, height: "400px", }}>
            <IsLoading></IsLoading>
          </div>
          :
          <>
            <div className='card  p-2 mb-2  isPosition' style={{ top: champId ? "" : "", zIndex: 99, height: "auto", }} >

              <RowModal classes=" d-flex  justify-content-between ">
                <ColModal classes={`col-12 col-md-4 col-sm-6 col-lg-${champId ? "4" : "2"} mb-`}>
                  <GroupModal classes="" >

                    <input
                      type='number'
                      className='form-control bg-secondary me-2 border'
                      placeholder='0/km'
                      onChange={handleDistanceFormData}
                      value={distenceRadeus}
                    />

                  </GroupModal>
                </ColModal>

                <ColModal classes={`col-12 col-md-4 col-sm-6 col-lg-${champId ? "4" : "2"} mb-`}>
                  <GroupModal classes="" >

                    <input
                      type='date'
                      className='form-select  me-2 '
                      onChange={handleFromDateChange}
                      value={attendencedatefrom}
                    />
                  </GroupModal>
                </ColModal>

                <ColModal classes={`col-12 col-md-4 col-sm-6 col-lg-${champId ? "4" : "2"} mb-`}>
                  <GroupModal classes="" >

                    <input
                      type='date'
                      className='form-select  me-2'
                      onChange={handleToDateChange}
                      defaultValue={attendencedateto}
                      value={attendencedateto}
                    />

                  </GroupModal>
                </ColModal>

                <ColModal classes={`col-12 col-md-4 col-sm-6 col-lg-${champId ? "4" : "2"} mb-`}>
                  <GroupModal classes="" >
                    <MultiSelect setSelectedTimingMultiSelect={setSelectedTimingMultiSelect} setTimingslots={setTimingslots} timeSlotsfilter={timeSlotsfilter}></MultiSelect>
                  </GroupModal>
                </ColModal>

                <ColModal classes={`col-12 col-md-4 col-sm-6 col-lg-${champId ? "4" : "2"} mb-`}>
                  <GroupModal classes="" >
                    <SingleSelectSearchDetailsDefault
                      handleChangeInputData={handleChangeCleaner}
                      HeaderTitle='Select Cleaner'
                      SelectData={cleanerLists || []}
                      DynamicKeys={["first_name", "last_name", "phone"]}
                      id={"id"}
                      name={"cleanerids"}
                      targetValue={"value"}
                      defaultData={champId ? { label: champsDetails?.first_name + " " + champsDetails?.last_name, id: champsDetails?.id } : null}
                    ></SingleSelectSearchDetailsDefault>

                  </GroupModal>
                </ColModal>

                <ColModal classes={`col-12 col-md-4 col-sm-6 col-lg-${champId ? "3" : "2"} mb-`}>
                  <button className='btn btn-sm btn-warning col-12' onClick={handleClick}>
                    Search
                  </button>
                </ColModal>
              </RowModal >


            </div>
            <KTCardBody className='card mt-2 '>

              {
                loading2 ?
                  <div className='' style={champId ? {} : { position: "absolute", left: "50%", right: "50%" }}>
                    <IsLoading />
                  </div>
                  :
                  <div className='table-responsive bg-white px-4'>
                    <table
                      id='kt_table_users'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    >

                      <thead>
                        <tr>
                          <th style={{ minWidth: '130px' }}>
                            <div className='bg-secondary text-dark py-2 me-2 text-center fw-bolder rounded'>
                              Cl Name
                            </div>
                          </th>
                          <th>
                            <div style={{ minWidth: "130px" }} className='bg-secondary text-dark py-2 me-2 text-center fw-bolder rounded'>
                              TimeSlots
                            </div>
                          </th>
                          {dates?.map((item: any) => (
                            <th key={item}>
                              <div
                                className='bg-secondary text-success py-2  gy-5  text-center fw-bolder rounded'
                                style={{ maxWidth: '120px', width: '100px' }}
                              >
                                {moment(item).format('DD-MM')}
                                <br />
                                {moment(item).format('dddd')}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>

                      {

                        cleanerStats && <CleanerTableBodyComponent
                          cleanerStats={cleanerStats}
                          timeSlots={timeSlots}
                          handleJobDetailSubmit={handleJobDetailSubmit} handleCleanerDetailsSubmit={handleCleanerDetailsSubmit}
                        />
                      }

                    </table>
                  </div>
              }
              {isModalOpen && (
                <Dialog
                  open={true}
                  onClose={handleCloseModal}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'
                >
                  <JobDetailsModal filteredData={jobdetailsCustomer} data={data} handleCloseModal={handleCloseModal} />
                </Dialog>
              )}

            </ KTCardBody>

          </>
      }

    </>
  )
}
export default AreaWiseAvailibilityByCleaners
