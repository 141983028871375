import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
type Props = {
    data: {
        categories: any;
        series: { name: string; data: number[] }[];
    }
};

const TicketCountCharts: React.FC<Props> = ({ data }) => {
    const chartOptions: ApexOptions = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return val.toString();
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
            },
        },
        legend: {
            show: true,
            position: "top",
            horizontalAlign: 'right',
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        },
        xaxis: {
            categories: data.categories,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        stroke: {
            curve: 'smooth'
        },
        // title: {
        //     text: 'Payout Time Line Chart',
        //     align: 'left'
        // },
        yaxis: {
            //   title: {
            //     text: 'Values'
            //   },
            labels: {
                show: false,
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val.toString();
                },
            },
            // enabled: false
        },
    };

    return (
        <div style={{ width: "100%" }}>
            <Chart
                options={chartOptions}
                series={data.series}
                type="line"
                width="100%"
                height="500px"

            />
        </div>
    );
};

export default TicketCountCharts;
