import axios from 'axios';
import React, { memo } from 'react';
import { withSwal as SweetAlertChat } from 'react-sweetalert2';
import { MAIN_ADMIN_BASE_API_URL, TEST_ADMIN_BASE_API_URL } from '../../../apiGlobally';
export default SweetAlertChat((props: any) => {
    const { swal, confirm, cancel, payloads, localKey }: any = props;
    const { payloadData, render, successTitle, FailedTitle } = payloads
    React.useEffect(() => {
        if (render) {
            swal.fire({
                title: 'Do you want to save the changes?',
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${localKey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/updatecustomeraddresspincode`, payloadData).then((res) => {
                        swal.fire(successTitle)
                    }).catch((error) => {
                        swal.fire('!Failed request')
                    })
                } else if (result.isDenied) {
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});
