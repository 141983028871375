import { useEffect, useState } from 'react'
import {
  faBan,
  faBars,
  faDotCircle,
  faEdit,
  faHamburger,
  faHistory,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateFormate } from '../../consts/toggleColumns/DateFormate'
import Calling from '../../consts/Call/Calling'
import Layout from '../../consts/Call/Layout'
import WhatsppMessageSenderIndividual from '../../consts/components/WhatsppMessageSenderIndividual'
import UpdateArea from './forms/UpdateArea'
import UpdateSubArea from './forms/UpdateSubArea'
import StatusUpdateOldDeactivateChamp from './forms/StatusUpdateOldDeactivateChamp'
import AddRemoveWhatsappGroup from '../ActiveCleaner/Forms/AddRemoveWhatsappGroup'
import ChipsInfos from '../../consts/Chips/ChipsInfos'
import { postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { toast } from 'react-toastify'
import AddChampRequirementType from '../../consts/champChatDashboard/actions/AddChampRequirementType'
import TicketStatus from '../../consts/reuseModules/TicketStatus'
import ReasonUpdateOldChampForm from './forms/ReasonUpdateOldChampForm'
import AdminDueDateRowForm from '../admins/adminForms/AdminDueDateRowForm'
import { adminMomentDueDate } from '../admins/adminUtils'
import MomentDate from '../../consts/MomentDate'
import AdminActiveOrInactive from '../admins/adminComponents/AdminActiveOrInactive'

export const columns = (handleFetchAllCallLogs?: any, data?: any) => [
  {
    id: 14,
    reorder: true,
    name: 'ID',
    cell: (row: any) =>
      // <Action row={row} setRefresh={null}></Action>
      row?.id,
    sortable: true,
    sortField: 'id',
    minWidth: '100px',
  },
  {
    id: 0,
    reorder: true,
    name: 'WHATSAPP GROUP',
    cell: (row: any) => <WhatsappGroups row={row} />,
    sortFunction: (rowA: any, rowB: any) => {
      let a = rowA?.champ_whatsapp_group?.group_name
      let b = rowB?.champ_whatsapp_group?.group_name
      if (a > b) {
        return 1
      }
      if (b > a) {
        return -1
      }
      return 0
    },
    sortable: true,
    sortField: 'champ_whatsapp_group.group_name',
  },
  {
    id: 1,
    reorder: true,
    name: 'CHAMP NAME',
    cell: (row: any) => (
      <div className='d-flex g-4 flex-wrap'>
        <WhatsappMessageSender row={row} />
        <Calling
          title={row?.first_name + ' ' + row?.last_name}
          row_id={row?.id}
          row={row}
          type='cleanerid'
          From={Layout}
          isOpen={true}
          source='old_champ'
          toCreateTicketForNewListApi='getInactiveChampTicket'
          trackData={data}
        />
      </div>
    ),

    sortField: 'first_name',
    sortable: true,
    minWidth: '200px',
  },
  {
    id: 2,
    reorder: true,
    name: 'Area',
    cell: (row: any) => <UpdateAreaList row={row} />,
    sortField: 'cleanerArea.areaDetail.areaname',
    sortable: true,
    minWidth: '150px',
  },
  {
    id: 9,
    reorder: true,
    name: 'Sub Area',
    cell: (row: any) => <UpdateSubAreaList row={row} />,
    sortField: 'cleanerArea.areaDetail.areaname',
    sortable: true,
    minWidth: '150px',
  },
  {
    id: 10,
    reorder: true,
    name: "Ticket Status",
    cell: (row: any) => {
      let data;
      if (!row?.cleanerTicketsSingle) {
        data = <ChipsInfos SelectedString={"No Tickets"} classes="danger" />
      } else {
        data = <ChipsInfos SelectedString={row?.cleanerTicketsSingle?.ticketstatus?.name || 'Not Set'} classes={row?.cleanerTicketsSingle?.ticketstatus?.id == 0 ? "warning" : row?.cleanerTicketsSingle?.ticketstatus?.id == 1 ? "danger" : row?.cleanerTicketsSingle?.ticketstatus?.id == 2 ? "warning" : row?.cleanerTicketsSingle?.ticketstatus?.id == 3 ? "success" : row?.cleanerTicketsSingle?.ticketstatus?.id == 4 ? "dark" : row?.cleanerTicketsSingle?.ticketstatus?.id == 5 ? "primary" : row?.cleanerTicketsSingle?.ticketstatus?.id == 6 ? "primary" : "primary"} />
      }
      return data
    },
    sortField: 'cleanerTicketsSingle.ticketstatus.name',
    sortable: true,
    minWidth: "150px",
  },
  {
    id: 3,
    reorder: true,
    name: "Area Manager",
    cell: (row: any) => row?.cleanerSuper?.supervisor?.first_name ? row?.cleanerSuper?.supervisor?.first_name + " " + row?.cleanerSuper?.supervisor?.last_name : "NA",
    sortField: 'cleanerSuper.supervisor.first_name',
    sortable: true,
    minWidth: "150px",
  },
  {
    id: 13,
    reorder: true,
    name: 'Reason',
    cell: (row: any) => <ReasonUpdateOldChamp row={row} />,
    sortField: 'champHistory.note',
    sortable: true,
    minWidth: '150px',
  },
  {
    id: 16,
    reorder: true,
    name: 'Category',
    cell: (row: any) => row?.champDeactivatedHistory?.champInactiveReason?.notesCategory?.name ? row?.champDeactivatedHistory?.champInactiveReason?.notesCategory?.name : "NA",
    sortField: 'champDeactivatedHistory.champInactiveReason.notesCategory.name',
    sortable: true,
    minWidth: '150px',
  },
  {
    id: 12,
    reorder: true,
    name: "Activation Date",
    cell: (row: any) => row?.champHistory?.date ? row?.champHistory?.date : "",
    sortField: 'champHistory.date',
    sortable: true,
    minWidth: "150px",
  },
  {
    id: 4,
    reorder: true,
    name: "De-activation Date",
    cell: (row: any) => row?.champDeactivatedHistory?.date ? row?.champDeactivatedHistory?.date : "",
    sortField: 'champDeactivatedHistory.date',
    sortable: true,
    minWidth: "150px",
  },
  {
    id: 5,
    reorder: true,
    name: "Rating",
    cell: (row: any) => row?.rating,
    sortField: 'rating',
    sortable: true,
    minWidth: "150px",
  },
  {
    id: 6,
    reorder: true,
    name: "Due Date",
    cell: (row: any) => <OldChampDueDateUpdate row={row} />,
    sortField: 'cleanerTicketsSingle.last_date_resolution',
    sortable: true,
    minWidth: "150px",
  },
  {
    id: 7,
    reorder: true,
    name: "Status",
    cell: (row: any) => <StatusUpdateOldChamp row={row} />,
    sortField: 'cleanerMasterOperationReasons.name',
    sortable: true,
    minWidth: "150px",
  },
  {
    id: 8,
    reorder: true,
    name: "Assignee",
    cell: (row: any) => row?.cleanerTicketsSingle?.ticketAssignsSingle?.userDetail?.first_name ? row?.cleanerTicketsSingle?.ticketAssignsSingle?.userDetail?.first_name + " " + row?.cleanerTicketsSingle?.ticketAssignsSingle?.userDetail?.last_name : "",
    // sortField: 'reason',
    // sortable: true,
    minWidth: "150px",
  },
  {
    id: 11,
    reorder: true,
    name: "Batch",
    cell: (row: any) => row?.champBatch?.name ?? 'Not Set',
    sortField: "champBatch.name",
    minWidth: "200px"
  },
];



const WhatsappGroups = ({ row }: any) => {
  const [storeRow, setStoreRow] = useState<any>(row)
  const [pending, setPending] = useState<any>(false)
  const [showModal, setShowModal] = useState<any>(false)
  useEffect(() => {
    setStoreRow(row)
  }, [row])
  return (
    <>
      <FontAwesomeIcon icon={faEdit} className='fs-4 me-2 cursor-pointer' onClick={() => setShowModal(true)} />  {storeRow?.champ_whatsapp_group?.group_name || "Null"}
      {
        showModal ? <AddRemoveWhatsappGroup showModal={showModal} setShowModal={setShowModal} setPending={setPending} row={row} setStoreRow={setStoreRow} /> : <></>
      }
    </>
  )
}

const StatusUpdateOldChamp = ({ row }: any) => {
  const [storeRowP2P, setStoreRowP2p] = useState(row)
  const [updateStatusPop, setUpdateStatusPop] = useState(false)

  return (
    <>
      <span onClick={() => setUpdateStatusPop(true)} className='cursor-pointer'>
        <FontAwesomeIcon className='cursor-pointer' icon={faEdit} /> &nbsp;
        {storeRowP2P?.cleanerMasterOperationReasons?.name || 'Not Set'}
      </span>
      {updateStatusPop ? (
        <StatusUpdateOldDeactivateChamp
          FilteredData={{
            show: updateStatusPop,
            setUpdateStatusPop: setUpdateStatusPop,
          }}
          setStoreRowP2p={setStoreRowP2p}
          row={row}
        ></StatusUpdateOldDeactivateChamp>
      ) : (
        <></>
      )}
    </>
  )
}

const ReasonUpdateOldChamp = ({ row }: any) => {
  const [storedRow, setStoredRow] = useState(row)
  const [updateReasonPop, setUpdateReasonPop] = useState(false)

  return (
    <>
      <span onClick={() => setUpdateReasonPop(true)} className='cursor-pointer'>
        <FontAwesomeIcon className='cursor-pointer' icon={faEdit} /> &nbsp;
        {storedRow?.champDeactivatedHistory?.note || 'Not Set'}
      </span>
      {updateReasonPop ? (
        <ReasonUpdateOldChampForm
          FilteredData={{
            show: updateReasonPop,
            setUpdateReasonPop: setUpdateReasonPop,
          }}
          setStoredRow={setStoredRow}
          row={row}
        ></ReasonUpdateOldChampForm>
      ) : (
        <></>
      )}
    </>
  )
}

const OldChampDueDateUpdate = ({ row }: any) => {
  const [showDueDatePopup, setShowDueDatePopup] = useState<any>(false)
  const [storeRow, setStoreRow] = useState<any>(row)
  useEffect(() => {
    setStoreRow(row)
  }, [row])

  const updateDueDateInRow = (payload: any) => {
    if (payload?.isSet) {
      row['cleanerTicketsSingle'] = {}
      row.cleanerTicketsSingle.last_date_resolution = payload?.due_date
      setStoreRow(row)
    }
  }

  let dueDate = storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.last_date_resolution : ""
  let ticket_id = storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.id : 0

  return (
    <>
      <span>
        <FontAwesomeIcon onClick={() => setShowDueDatePopup(true)} icon={faEdit} /> &nbsp;
        {storeRow?.cleanerTicketsSingle?.last_date_resolution ? (
          <DateFormate
            date={storeRow?.cleanerTicketsSingle?.last_date_resolution}
            from='OldChampDeactivate_Due_Date'
          />
        ) : (
          'Not Set'
        )}
      </span>

      {
        showDueDatePopup ? <AdminDueDateRowForm
          show={showDueDatePopup}
          setShow={setShowDueDatePopup}
          setStoreRow={updateDueDateInRow}
          initialData={{
            champId: storeRow?.id,
            ticket_id: ticket_id,
            prev_due_date: dueDate,
            due_date: dueDate ? dueDate : adminMomentDueDate(0),
            for: 'inactive_champ',
          }}
          dueDateKeyName="due_date"
          toastMessage="message"
          keyName="oldChampDueDate"
          formTitle="Update Due Date"
          formSubmitApiUrl="updateDueDateOldChamp"
          label=""
        /> : <></>
      }

    </>
  )
}

const UpdateAreaList = ({ row }: any) => {
  const [show, setShow] = useState(false)
  const [rowData, setRowData] = useState(row)
  useEffect(() => {
    setRowData(row)
  }, [row])

  return (
    <>
      <FontAwesomeIcon
        icon={faEdit}
        className='me-2 fs-5 cursor-pointer'
        onClick={() => setShow(true)}
      />{' '}
      <span>{rowData?.cleanerArea?.areaDetail?.areaname ?? 'NA'}</span>
      {show ? <UpdateArea show={show} setShow={setShow} row={row} setRowData={setRowData} /> : ''}
    </>
  )
}

const UpdateSubAreaList = ({ row }: any) => {
  const [show, setShow] = useState(false)
  const [rowData, setRowData] = useState(row)
  useEffect(() => {
    setRowData(row)
  }, [row])

  return (
    <>
      <FontAwesomeIcon
        icon={faEdit}
        className='me-2 fs-5 cursor-pointer'
        onClick={() => setShow(true)}
      />{' '}
      <span>{rowData?.cleaner_sub_area_details?.name ?? 'NA'}</span>
      {show ? (
        <UpdateSubArea show={show} setShow={setShow} row={row} setRowData={setRowData} />
      ) : (
        ''
      )}
    </>
  )
}

const WhatsappMessageSender = ({ row }: any) => {
  const [show, setShow] = useState(false)
  const handleNotifyPopUpClose = () => {
    setShow(false)
  }
  return (
    <>
      <span className='cursor-pointer me-4 fs-5 text-primary'>
        {row?.isWhatsApp == 2 ? (
          <span className='cursor-pointer me-2 fs-7 text-danger' style={{ cursor: 'not-allowed' }}>
            <FontAwesomeIcon icon={faBan} className='text-danger' />{' '}
          </span>
        ) : (
          <i
            className={`cursor-pointer fab fa-whatsapp text-${row?.isWhatsApp == 3 ? 'danger' : 'success'
              }`}
            onClick={() => setShow(true)}
          ></i>
        )}
      </span>

      {show ? (
        <WhatsppMessageSenderIndividual
          showNotifyPopUp={show}
          handleNotifyPopUpClose={handleNotifyPopUpClose}
          row={row}
        />
      ) : (
        ''
      )}
    </>
  )
}

const Action = ({ row, setRefresh }: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showRequirementTypeModal, setShowRequirementTypeModal] = useState(false)

  const handleReactiveChamp = async () => {
    setIsLoading(true)

    try {
      const response = await postRequest(
        URL_EndPoints(null)?.makeactivecleaner,
        {
          cleanerid: row?.id,
        },
        setIsLoading
      )

      if (response?.data?.status == 200 && response?.data?.isRequirement == false) {
        toast.success(response?.data?.msg, { position: 'top-center' })
        setRefresh && setRefresh()
      } else if (response?.data?.isRequirement == true) {
        toast.error(response?.data?.msg, { position: 'top-center' })
        setShowRequirementTypeModal(true)
      } else {
        toast.error(response?.data?.msg, { position: 'top-center' })
      }
    } catch (error) { }
  }

  const closeBtn = () => {
    setShowRequirementTypeModal(false)
  }

  return (
    <>
      {
        <button
          className={`btn btn-light btn-active-light-primary btn-sm ${isLoading && 'btn-danger'} `}
          onClick={handleReactiveChamp}
        >
          {isLoading ? 'Updating...' : 'Re-Activate'}
        </button>
      }

      {showRequirementTypeModal ? (
        <AddChampRequirementType
          setRowData={null}
          row={row}
          show={showRequirementTypeModal}
          handleCloseForm={closeBtn}
        />
      ) : (
        <></>
      )}
    </>
  )
}
