
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';

const AddBankDetailsChamp = ({ show, setShow, champsDetails, getChampBankDetails, setIsOldOrNewBankDetails }: any) => {
    const [apiLoader, setApiLoader] = useState<any>(false)
    const [isVerifyApiLoader, setIsVerifyApiLoader] = useState<any>(false)
    // const [isVerifyUpiApiLoader, setIsVerifyUPIApiLoader] = useState<any>(false)
    const [isErrors, setIsErrors] = useState<any>({})
    let isPrimaryAccount: boolean = false
    const validationRules: any = {
        upi_id: 'UPI ID is required',
        account_no: 'Account number is required',
        holderName: 'Account Holder name is required',
        ifsc: 'IFSC code is required',
    };

    const [isAccountVerified, setIsAccountVerified] = useState<any>({
        isTrue: false,
        account_name: "",
    })
    const [isUpiVerified, setIsUpiVerified] = useState<any>({
        isTrue: false,
        vpa_name: "",
    })

    const [payload, setPayload] = useState<any>({
        "champId": champsDetails?.id,
        "account_type": "bank",
        "upi_id": "",
        "account_no": "",
        "holderName": "",
        "ifsc": "",
        // "isPrimary": isPrimaryAccount
    })


    useEffect(() => {
        if (payload?.account_type == "upi") {
            setPayload(
                {
                    "champId": champsDetails?.id,
                    "account_type": "upi",
                    "upi_id": "",
                    "beneficieryName": "",

                }
            )
            setIsErrors({})
            setIsAccountVerified({
                isTrue: false,
                account_name: "",
            })
            setIsUpiVerified({
                isTrue: false,
                vpa_name: "",
            })
        }

        if (payload?.account_type == "bank") {
            setPayload(
                {
                    "champId": champsDetails?.id,
                    "account_type": "bank",
                    "account_no": "",
                    "holderName": "",
                    "ifsc": "",
                    "beneficieryName": "",
                }
            )

            setIsErrors({})
            setIsAccountVerified({
                isTrue: false,
                account_name: "",
            })
            setIsUpiVerified({
                isTrue: false,
                vpa_name: "",
            })
        }

    }, [payload?.account_type])

    const validatePayload = () => {
        let isValid = true;
        const newErrors: any = {};

        Object.keys(payload).forEach((key) => {
            if (!payload[key] && validationRules[key] && payload?.account_type == "upi") {
                newErrors[key] = validationRules[key];
                isValid = false;
            }
            else if (!payload[key] && validationRules[key] && payload?.account_type == "bank") {
                newErrors[key] = validationRules[key];
                isValid = false;
            }
            return
        });

        setIsErrors(newErrors);

        return isValid;
    };

    // const isVerifyAccountNumber = async () => {
    //     setIsVerifyApiLoader(true)
    //     const response = await postRequest(URL_EndPoints()?.verifyBankAccount, {
    //         accountNumber: payload?.account_no,
    //         ifsc: payload?.ifsc,
    //     }, setIsVerifyApiLoader)

    //     if (response?.data?.status == 200) {
    //         if (response?.data?.data?.success == true) {
    //             if (response?.data?.data?.data?.is_valid == true) {
    //                 setPayload(
    //                     {
    //                         ...payload,
    //                         ["beneficieryName"]: response?.data?.data?.data?.account_name,
    //                     }
    //                 )
    //                 setIsAccountVerified({
    //                     ...isAccountVerified,
    //                     ["isTrue"]: true,
    //                     ["account_name"]: response?.data?.data?.data?.account_name,
    //                 })

    //             }
    //             else {
    //                 setIsAccountVerified({
    //                     ...isAccountVerified,
    //                     ["isTrue"]: false,
    //                     ["account_name"]: "Please enter valid account number/IFSC!",
    //                 })
    //             }
    //         }else {
    //             toast.error("Something went wrong")
    //         }
    //     }else {
    //         toast.error("Something went wrong")
    //     }
    // }

    // const isVerifyUpiNumber = async () => {
    //     setIsVerifyUPIApiLoader(true)
    //     const response = await postRequest(URL_EndPoints()?.verifyVPA, {
    //         vpa: payload?.upi_id,
    //     }, setIsVerifyUPIApiLoader)

    //     if (response?.data?.status == 200) {
    //         if (response?.data?.data?.success == true) {
    //             if (response?.data?.data?.data?.vpa_status != "failure") {
    //                 setPayload(
    //                     {
    //                         ...payload,
    //                         ["beneficieryName"]: response?.data?.data?.data?.vpa_name,
    //                     }
    //                 )

    //                 setIsUpiVerified({
    //                     ...isUpiVerified,
    //                     ["isTrue"]: true,
    //                     ["vpa_name"]: response?.data?.data?.data?.vpa_name,
    //                 })


    //             }
    //             else {
    //                 setIsUpiVerified({
    //                     ...isUpiVerified,
    //                     ["isTrue"]: false,
    //                     ["vpa_name"]: "Please enter valid UPI Id !",
    //                 })
    //             }
    //         }
    //     }
    // }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsUpiVerified({
            isTrue: false,
            vpa_name: "",
        })

        setIsAccountVerified({
            isTrue: false,
            account_name: "",
        })

        if (!validatePayload()) {
            return
        }
        // if (payload?.account_type == "bank" && isAccountVerified?.isTrue == false && payload?.holderName && payload?.account_no && payload?.ifsc) {
        //     isVerifyAccountNumber()
        // }
        else if (payload?.account_type == "bank" 
        // && isAccountVerified?.isTrue == true && 
        && payload?.holderName && payload?.account_no && payload?.ifsc
        ) {
            setApiLoader(true)
            const response = await postRequest(URL_EndPoints()?.addAccountDetail, payload, setApiLoader)
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
            if (response?.data?.status == 200) {
                setIsOldOrNewBankDetails(1)
                getChampBankDetails(-1)
                setShow(false)
              }
        }

        // else if (payload?.account_type == "upi" && isUpiVerified?.isTrue == false && payload?.upi_id) {
        //     isVerifyUpiNumber()
        // }

        else if (payload?.account_type == "upi" 
        // && isUpiVerified?.isTrue == true 
        && payload?.upi_id) {
            setApiLoader(true)
            const response = await postRequest(URL_EndPoints()?.addAccountDetail, payload, setApiLoader)
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
            if (response?.data?.status == 200) {
                setIsOldOrNewBankDetails(1)
                getChampBankDetails(-1)
                setShow(false)
              }
        }

    }

    const buttonColor = payload?.account_type == "bank" 
    // && isAccountVerified?.isTrue == true 
    && apiLoader == false || payload?.account_type == "upi" 
    // && isUpiVerified?.isTrue == true 
    && apiLoader == false


    return (
        <Modal size="lg" show={show} onHide={() => setShow(false)} >
            <Modal.Header closeButton>
                <Modal.Title>Add Bank Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    <>
                        <Form onSubmit={handleSubmit}>

                            <Form.Group className="mb-3" controlId="cleaner">

                                <div className='mb-2'>
                                    <Form.Label className='text-muted'>Account Type</Form.Label>
                                    <select
                                        name='date_typer'
                                        aria-label='Select a Timezone'
                                        data-control='select2'
                                        data-placeholder='Period'
                                        className='form-select'
                                        id='account_type'
                                        value={payload?.account_type}
                                        onChange={(event) => setPayload({ ...payload, [event?.target.id]: event?.target?.value })}
                                    >
                                        < option className='text-muted' value={""}>  Select Account Type</option>
                                        {
                                            ["upi", "bank"]?.map((ele: any) => {
                                                return (
                                                    <>
                                                        < option value={ele} className='text-muted'>{ele?.toUpperCase()}</option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                {
                                    payload?.account_type ? <>

                                        {
                                            payload?.account_type == "bank" ? <>
                                                <div className='mb-2'>
                                                    <Form.Label className='text-muted'>Account Holder Name</Form.Label>
                                                    <input
                                                        placeholder='Account Holder Name'
                                                        type='text'
                                                        className='form-control mb-lg-0'
                                                        value={payload?.holderName}
                                                        name="holderName"
                                                        onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                                    />

                                                    {
                                                        isErrors?.holderName &&
                                                        <Form.Label className='text-danger'>              {isErrors?.holderName}</Form.Label>


                                                    }

                                                </div>

                                                <div className='mb-2'>
                                                    <Form.Label className='text-muted'>Account Number </Form.Label>
                                                    <input
                                                        placeholder='Account Number'
                                                        type='number'
                                                        className='form-control mb-lg-0'
                                                        value={payload?.account_no}
                                                        name="account_no"
                                                        onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                                    />
                                                    {
                                                        isErrors?.account_no && <Form.Label className='text-danger'>{isErrors?.account_no}</Form.Label>
                                                    }
                                                    
                                                    {/* {
                                                        isAccountVerified?.account_name ? <div className={`mb-4 d-flex flex-column`}>
                                                            <Form.Label className={`text-${isAccountVerified?.isTrue ? "success" : "danger"}`}>
                                                                {
                                                                    isAccountVerified?.isTrue && <span className='me-2'>✅</span>
                                                                }
                                                                {isAccountVerified?.account_name}</Form.Label>
                                                        </div> : <></>
                                                    } */}


                                                </div>

                                                <div className='mb-4'>
                                                    <Form.Label className='text-muted'>IFSC Code</Form.Label>
                                                    <input
                                                        placeholder='IFSC Code'
                                                        type='text'
                                                        className='form-control mb-lg-0'
                                                        value={payload?.ifsc}
                                                        name="ifsc"
                                                        onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value.toUpperCase() })}
                                                    />
                                                    {
                                                        isErrors?.ifsc && <Form.Label className='text-danger'>{isErrors?.ifsc}</Form.Label>
                                                    }

                                                </div>

                                            </> : <>

                                                <div className='mb-4'>
                                                    <Form.Label className='text-muted'>UPI ID</Form.Label>
                                                    <input
                                                        placeholder='UPI ID'
                                                        type='text'
                                                        className='form-control mb-lg-0'
                                                        value={payload?.upi_id}
                                                        name="upi_id"
                                                        onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                                    />
                                                    {
                                                        isErrors?.upi_id && <Form.Label className='text-danger'>{isErrors?.upi_id}</Form.Label>
                                                    }

                                                </div>

                                                {/* {
                                                    isUpiVerified?.vpa_name ? <div className='mb-4 d-flex flex-column'>
                                                        <Form.Label className={`text-${isUpiVerified?.isTrue ? "success" : "danger"}`}>
                                                            {
                                                                isUpiVerified?.isTrue&& <span className='me-2'>✅</span>
                                                            }
                                                            {isUpiVerified?.vpa_name}
                                                        </Form.Label>
                                                    </div> : <></>
                                                } */}


                                            </>
                                        }

                                        {/* 
                                        <div className="d-flex justify-content-start aligh-items-center mb-3">
                                            <input type="checkbox" name="isPrimaryAccount" id="isPrimaryAccount" onClick={() => {
                                                isPrimaryAccount = isPrimaryAccount == true ? false : true
                                            }} style={{ height: "20px", width: "20px" }} className='me-2 cursor-pointer' />
                                            <label className="form-check-label ml-1 fw-bold text-muted" htmlFor="isPrimaryAccount">Save As Primary Account</label>
                                        </div> */}



                                    </> : <></>
                                }
                            </Form.Group>


                            {
                                payload?.account_type ? <div className='d-flex justify-content-end'>
                                    <Button variant={buttonColor ? "success" : "primary"} size='sm' type="submit" disabled={apiLoader
                                    //  || payload?.account_type == "bank" 
                                    // && isAccountVerified?.isTrue == false && isVerifyApiLoader == true ? true : false
                                    }>
                                        {

                                            payload?.account_type == "bank" 
                                            // && isAccountVerified?.isTrue == true 
                                            && apiLoader == false ? "Add Bank Detail " :

                                            
                                            // payload?.account_type == "bank" && isAccountVerified?.isTrue == false && isVerifyApiLoader == false && apiLoader == false ? "Verify Account Detail" :

                                               
                                            // payload?.account_type == "bank" && isAccountVerified?.isTrue == false && isVerifyApiLoader == true && apiLoader == false ? "Verifying..." :

                                                  
                                            payload?.account_type == "bank" 
                                            // && isAccountVerified?.isTrue == true && isVerifyApiLoader == false 
                                            && apiLoader == true ? "Adding..." :

                                                     
                                            payload?.account_type == "upi" 
                                            // && isUpiVerified?.isTrue == true 
                                            && apiLoader == false ? "Add Bank Detail " :

                                                          
                                            // payload?.account_type == "upi" 
                                            // && isUpiVerified?.isTrue == false && isVerifyUpiApiLoader == false 
                                            // && apiLoader == false ? "Verify Account Detail " :

                                                               
                                            // payload?.account_type == "upi" && isUpiVerified?.isTrue == false && isVerifyUpiApiLoader == true && apiLoader == false ? "Verifying... " :

                                                                     
                                            payload?.account_type == "upi" 
                                            // && isUpiVerified?.isTrue == true && isVerifyUpiApiLoader == false 
                                            && apiLoader == true ? "Adding... " :
                                                                            ""
                                        }
                                    </Button>
                                </div> : <></>
                            }
                        </Form>
                    </>}
            </Modal.Body>
        </Modal>

    )
}

export default AddBankDetailsChamp



