import React from 'react'

const ShowChartHint = ({ title, color }: { title: string, color: string }) => {
    return <div className='d-flex me-4 justify-content-start align-items-center'>
        <div className={`d-flex justify-content-start badge badge-${color ?? "secondary"} text-dark rounded-0 me-2`} style={{ width: "40px", height: "20px" }}> </div>
        {title}
    </div>
}

export default ShowChartHint
