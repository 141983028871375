import { useState } from 'react'
import { KTCardBody } from '../../../../_metronic/helpers'
import { Tabs, Tab } from 'react-bootstrap'
import { InventoryOrderItemsCounts } from './intervalsByDate/InventoryOrderItemsCounts'
import { InventoryOrderItemsByManager } from './intervalsByAreaManager/InventoryOrderItemsByManager'

export const InventoryOrderItemsMain = () => {
  const [activeTab, setActiveTab] = useState('byDate')

  return (
    <KTCardBody>
      <div className='mb-4 fs-4 fw-semibold'>Inventory Ordered Items Counts</div>
      
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k || 'byDate')}
        className='mb-4'
      >
        <Tab eventKey='byDate' title='Count by Date'>
          <InventoryOrderItemsCounts />
        </Tab>
        <Tab eventKey='byManager' title='Count by Area Manager'>
          <InventoryOrderItemsByManager />
        </Tab>
      </Tabs>
    </KTCardBody>
  )
}