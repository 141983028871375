import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { columns } from './Components/Columns'
import { useSelector } from 'react-redux'
import TopHeader from './Components/TopHeader'
import { ReactTableComponentStylesSheet } from '../../../app/consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet'
const ActiveStatsData = () => {
  const ActiveStatsData = useSelector((store: any) => store.ActiveStatsReducer.ActiveStats)
  const [Search, setSearch] = useState<any>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [filterData, setfilterData] = useState<any>(ActiveStatsData)

  React.useEffect(() => {
    const updatedData = ActiveStatsData?.filter((data: any) => {
      return data?.name?.toLowerCase()?.match(Search.toLowerCase())
    })
    setfilterData(updatedData)
  }, [Search])

  React.useEffect(() => {
    setLoading(true)
    ActiveStatsData && setfilterData(ActiveStatsData)
    ActiveStatsData && setLoading(false)
  }, [ActiveStatsData])
  return (
    <div>
      { (
        <DataTable
          customStyles={ReactTableComponentStylesSheet}
          progressPending={isLoading}
          columns={columns}
          data={filterData} 
          pagination 
          fixedHeader 
          fixedHeaderScrollHeight='auto' 
          selectableRowsHighlight 
          highlightOnHover
          subHeader 
          subHeaderComponent={
            <>
              <TopHeader setSearch={setSearch} Search={Search} ></TopHeader>
            </>
          }
        />
      )}
    </div>
  )
}
export default ActiveStatsData
