import React, { FC, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MenuComponent } from '../../../../_metronic/assets/ts/components';
import ChangeWhatsappMessage from './ChangeWhatsappMessage';
import CreateTicketFromWhatsappMessage from './CreateTicketFromWhatsappMessage';
import AssignToTicket from './AssignToTicket';
export const WhatsappMessageSwitchAction = ({ customerDetailData, WhatsappMessageid }: any) => {
	const userid: any = JSON.parse(localStorage.getItem("user") || "")

	useEffect(() => {
		MenuComponent.reinitialization()
	}, [])


	const [show, setShow] = useState(false);
	const [assignTicketModal, setAssignTicketModal] = useState(false);
	const [createNewTicketShow, setcreateNewTicketShow] = useState(false);
	const [Renders, setRenders] = useState<any>(null);


	const handleClose = () => {
		setShow(false)
		setAssignTicketModal(false)
		setcreateNewTicketShow(false)
	};


	const ChangeMessageWhatsapp = () => {
		setShow(true)
	}


	const AssignToTicketHandler = () => {
		setAssignTicketModal(true)
	}


	const CreateNewticketFromWhatsappMessage = () => {
		setcreateNewTicketShow(true)
		setRenders(Math.random())
	}


	return (
		<>
			<button
				className='btn btn-sm px-1 py-1 btn-active-success  fs-8 '
				data-kt-menu-trigger='click'
				data-kt-menu-placement='bottom-end'
			// style={{ borderRadius: "100%", width: "40px", height: "40px" }}
			>
				<i className="bi bi-three-dots fs-2 p-0" aria-hidden="true"></i>
			</button>
			<div
				className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 me-12'
				data-kt-menu='true'
				style={{
					width: "500px",
					zIndex: '105',
					position: 'fixed',
					inset: '0px 0px auto auto',
					margin: '0px',
					transform: 'translate(-59px, 440px)',
				}}
			>
				<div className='menu-item px-3 fw-bold'>
					<a
						// href='_blank'
						className='menu-link  px-3 fw-bold'
						data-kt-users-table-filter='delete_row'
						onClick={ChangeMessageWhatsapp}
					>
						Shift message
					</a>
				</div>
				<div className='menu-item px-3 fw-bold'>
					<a
						// href='_blank'
						className='menu-link  px-3 fw-bold'
						data-kt-users-table-filter='delete_row'
						onClick={CreateNewticketFromWhatsappMessage}
					>
						Create new ticket
					</a>
				</div>
				<div className='menu-item px-3 fw-bold'>
					<a
						// href='_blank'
						className='menu-link  px-3 fw-bold'
						data-kt-users-table-filter='delete_row'
						onClick={AssignToTicketHandler}
					>
						Assign to ticket
					</a>
				</div>
			</div>
		
			<ChangeWhatsappMessage FilteredData={{
				handleClose: handleClose,
				show,
			}} customerDetailData={customerDetailData} WhatsappMessageid={WhatsappMessageid}></ChangeWhatsappMessage>


			<AssignToTicket FilteredData={{
				handleClose: handleClose,
				show : assignTicketModal,
			}} WhatsappMessageid={WhatsappMessageid}></AssignToTicket>


			{
				createNewTicketShow &&
				<CreateTicketFromWhatsappMessage render={Renders} confirm={'Yes'} cancel={'No'} payloads={{
					userId: userid,
					whatsAppMessageId: WhatsappMessageid?.whatsappMessageId,

				}}
					handleCloseModalCleaner={handleClose}
					title={`Are you sure want to Assign `} />
			}


		</>
	)
}
