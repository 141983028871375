import { Dropdown } from "react-bootstrap"
import "./selectBox.css"
export const ShowHideColumns = ({ columns, sortedColumns, setSortedColumns }: any) => {
    
    const handleSortCheckbox = (event: any, isAll:boolean = false) => {

        if(isAll){
            setSortedColumns([]);
        }
        else{
            if (event.target.checked) {
                const commonObjects = columns.filter((obj1: any) => {
                    return sortedColumns.find((ele: any) => ele.id == obj1.id) || obj1.id == event.target.value
                });
    
                setSortedColumns(commonObjects);
            } else {
                const newArray = sortedColumns.filter((obj: any) => obj.id != event.target.value);
                setSortedColumns(newArray);
            }
        }
    }
    // mx-2
    return <>
    
    <Dropdown className="d-inline mx-2 mb-2 btn-sm me-3 mx-2 " autoClose="outside" key={112}>
        <Dropdown.Toggle id="dropdown-autoclose-outside" 
        className="btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn"
        >
            Show/Hide Cols
        </Dropdown.Toggle>

        <Dropdown.Menu >
           <span className="mb-3 fs-5 cursor-pointer ms-3 text-end" onClick={()=>handleSortCheckbox(false, true)}>  Clear all </span>
           <br />
           <br />
            {columns.map((column: any, index:any) => {
                return <li className='dropdown-item' key={index}> <input onChange={(event)=>handleSortCheckbox(event, false)} type="checkbox" name="fields" value={column.id} id="" checked={sortedColumns.find((el: any) => el.id == column.id) ? true : false} /> {column.name}</li>

            })}
        </Dropdown.Menu>
    </Dropdown>
    
    </>
}
























// previous working code dont delte this before id  


// import { Dropdown } from "react-bootstrap"
// import "./selectBox.css"
// export const ShowHideColumns = ({ columns, sortedColumns, setSortedColumns }: any) => {

//     const handleSortCheckbox = (event: any) => {
//         if (event.target.checked) {

//             const commonObjects = columns.filter((obj1: any) => {
//                 return sortedColumns.find((ele: any) => ele.name == obj1.name) || obj1.name == event.target.value
//             });
//             setSortedColumns(commonObjects);
//         } else {
//             const newArray = sortedColumns.filter((obj: any) => obj.name != event.target.value);
//             setSortedColumns(newArray);
//         }
//     }
//     // mx-2
//     return <>
    
//     <Dropdown className="d-inline mx-2 mb-2 btn-sm me-3 mx-2 " autoClose="outside" key={112}>
//         <Dropdown.Toggle id="dropdown-autoclose-outside" 
//         className="btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn"
//         // className="btn btn-sm btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-2 px-3   filterBtn "
//         >
//             Show/Hide Cols
//         </Dropdown.Toggle>

//         <Dropdown.Menu >
//             {columns.map((column: any, index:any) => {
//                 return <li className='dropdown-item' key={index}> <input onChange={handleSortCheckbox} type="checkbox" name="fields" value={column.name} id="" checked={sortedColumns.find((el: any) => el.name == column.name) ? true : false} /> {column.name}</li>

//             })}
//         </Dropdown.Menu>
//     </Dropdown>
    
//     </>
// }