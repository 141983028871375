import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const MediaPreview = ({ fileSelected, file, CancelImage, defaultUrl }: any) => {
    console.log('file', typeof (file));
    return (
        <div className='container p-2'>
            {
                fileSelected && <div className="row  p-0">
                    <div key={""} className="col-12 p-0">
                        <div className="d-flex align-items-center justify-content-between border border-gray p-3">
                            {/* Image Preview */}
                            <div className="d-flex align-items-center">
                                {file.type.startsWith('image/') ? (
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={file?.name}
                                        className="img-thumbnail me-3"
                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center bg-secondary text-white rounded-circle me-3"
                                        style={{ width: '50px', height: '50px' }}
                                    >
                                        {file?.type?.split('/')?.[0]}
                                    </div>
                                )}

                                {/* File Details */}
                                <div>
                                    <p className="mb-1 fw-bold">{file.name}</p>
                                    <small className="text-muted">
                                        {file?.type} ({(file?.size / (1024 * 1024))?.toFixed(2)} MB)
                                    </small>
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="d-flex align-items-center">
                                {/* <FontAwesomeIcon   onClick={() => handleEdit(file)} icon={faEdit} className='text-success fw-bold me-2 fs-2 p-2 cursor-pointer' /> */}
                                <FontAwesomeIcon onClick={() => CancelImage()} icon={faXmarkCircle} className='text-danger fw-bold me-4 fs-2 p-2 cursor-pointer' />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                !fileSelected && <div className='text-center text-muted italic text-italic mb-4 fs-5'> ☝️ Please upload media from local...</div>
            }
            
            {
                defaultUrl && !file || typeof (file) == "string" && <div className='d-flex justify-content-center align-items-center '>
                    <img src={defaultUrl} alt="default image" style={{ width: "200px", height: "200px" }} />
                </div>
            }
        </div>
    )
}

export default MediaPreview