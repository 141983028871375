import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { getPostMultipleApiHandler, postRequest } from '../../../consts/Server/Requests'
import { adminGetFilterDataBySearch, adminKeyWiseComponents, adminToastRunner, validateForm } from '../adminUtils'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faFileExcel } from '@fortawesome/free-solid-svg-icons';
const AdminForm = ({ show, setShow, setUpdatedData, formData, staticFormData, initialData, prevInitialData, refreshNow, toastMessage, keyName, formTitle, formSubmitApiUrl, dynamicApiData = {} }: any) => {
    const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
    const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
    const [filterDataList, setFilterDataList] = useState<any>({})
    const [payload, setPayload] = useState<any>(initialData);
    const [deFaultPayload, setDeFaultPayload] = useState<any>({});
    const [formErrors, setFormErrors] = useState<any>({});

    async function getFetchInitialData() {
        setIsLoadingFilter(true)
        const results = await getPostMultipleApiHandler(formData, setIsLoadingFilter, true, dynamicApiData)
        setFilterDataList(results)
    }
    useEffect(() => {
        show && getFetchInitialData()
    }, [show])

    const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
    const [dynFilter, setDynFilter] = useState<any>({})

    const setSearchFilter = (event: any, filters: any) => {
        setDynFilter(filters)
        setStoreSearchKeywords(event)
    }

    useEffect(() => {
        let x = setTimeout(async () => {
            setIsLoadingFilter(true)
            storeSearchKeywords && await adminGetFilterDataBySearch(dynFilter, setFilterDataList, filterDataList, setIsLoadingFilter, { keyword: storeSearchKeywords })
            setIsLoadingFilter(false)
        }, 2000)
        return () => {
            setIsLoadingFilter(false)
            clearTimeout(x)
        }
    }, [storeSearchKeywords, dynFilter])

    const onChangeHandler: any = (event: any, name: any, isDate?: any) => {
        setPayload({
            ...payload,
            [name]: event ? event?.value : event,
        })
        setDeFaultPayload({
            ...deFaultPayload,
            [name + "_" + "item"]: event ? event?.item : {},
        })
    }

    const submitForm = async (e: any) => {
        e.preventDefault()
        if (validateForm(formData, payload, setFormErrors)) {
            setIsBulkAssignApiLoading(true)
            const response: any = await postRequest(URL_EndPoints(null)?.[formSubmitApiUrl], payload, setIsBulkAssignApiLoading)
            adminToastRunner(response, toastMessage)
            refreshNow && refreshNow()
            if (response?.data?.status == 200) {
                setUpdatedData && setUpdatedData(payload)
                setShow(false)
            }
        } else {
            toast.error("Missing some required fields", { position: 'top-center' })
        }
    }

    useEffect(() => {
        let removeErrors: any = { ...formErrors }
        Object.keys(payload)?.forEach((element) => {
            if (payload[element]) {
                removeErrors[element] = ""
            }
        })
        setFormErrors(removeErrors)
    }, [payload])


    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="small-modal"
                // scrollable={true}
                // aria-labelledby="contained-modal-title-vcenter"
                centered
                key={keyName}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitForm}>
                        {
                            formData?.map((element: any, index: any) => {
                                let valueType = element?.valueType
                                let filterApi = element?.filterApi
                                let CurrentComponent: any = adminKeyWiseComponents()?.[valueType]
                                let searchable = element?.searchable ? true : false
                                let storeFilterList = filterDataList[filterApi] ? filterDataList[filterApi] : []
                                let dateError = formErrors[element?.from] ?? formErrors[element?.to]
                                let errMessage = element.valueType === 'multiDate' && (formErrors[element?.from] || formErrors[element?.to]) ? dateError : formErrors[element?.name]
                                let isRquiredToggleColor = element.valueType === 'multi' &&
                                    Array.isArray(payload[element.name]) &&
                                    payload[element.name].length > 0 || element.valueType == 'multiDate' && (payload[element.from] && payload[element.to]) || element.valueType != 'multi' && element.name && payload[element.name]
                                let label = element?.label || {}
                                let uniqueKey = keyName + valueType + "_" + index

                                return <Form.Group className="mb-3" key={uniqueKey} >
                                    {label?.show ? <Form.Label className='text-muted'>{label?.name}</Form.Label> : <></>}
                                    <div className='mb-3' style={{ position: "relative" }}>
                                        <CurrentComponent
                                            onChangeHandler={onChangeHandler}
                                            HeaderTitle={element?.HeaderTitle}
                                            payload={payload}
                                            deFaultPayload={deFaultPayload}
                                            SelectData={filterApi == "staticFormData" ? staticFormData : storeFilterList}
                                            displayFields={element?.displayFields}
                                            id={element?.valueField}
                                            name={element?.name}
                                            defaultData={prevInitialData ? prevInitialData : null}
                                            searchable={searchable}
                                            setSeacrch={setSearchFilter}
                                            isLoading={dynFilter?.filterApi == element?.filterApi ? isLoadingFilter : false}
                                            filters={element}
                                            from={element?.from}
                                            to={element?.to}
                                            inputType={element?.inputType}
                                            label={element?.label}
                                            isRquiredToggleColor={isRquiredToggleColor}
                                            valueType={valueType}
                                            isRequired={element?.isRequired}
                                        />
                                        {/* {element?.isRequired && element?.valueType != "checkbox" && <IsReuiredFieldIcon color={isRquiredToggleColor && "green"} marginTop={element?.valueType == "textArea" ? "-10px" : element?.valueType == "multiDate" ? "-3px" : ""} />} */}
                                        {errMessage && <Form.Label className='text-danger fs-8'><FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger fs-fs-8' /> {errMessage}</Form.Label>}
                                    </div>
                                </Form.Group>
                            })
                        }
                        <Form.Group className="mb-3 p-2 d-flex justify-content-end" >
                            {
                                <Button type='submit' variant={isBulkAssignApiLoading ? "success" : "primary"} >
                                    {isBulkAssignApiLoading ? "Submitting..." : "Submit"}
                                </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default AdminForm



// const FormData :any = [
//     {
//         HeaderTitle: 'Title Here...',
//         filterApi: '',
//         label: {
//           show: false,
//           name: 'LabelNameHere',
//         },
//         valueType: 'textBox',
//         inputType: 'text',
//         displayFields: [],
//         valueField: '',
//         name: 'title',
//         from: '',
//         to: '',
//         isRequired: true,
//         errMessage: 'Title is required !',
//         searchable: false,
//         wa_number_id: null,
//         userType: null,
//       },
// ]

// {
//     show ? <AdminForm
//       show={show}
//       setShow={setShow}
//       refreshNow={() => { }}
//       toastMessage="message"
//       formData={FormData}
//       staticFormData={chooseToShowCustomerData || []}
//       initialData={{
//         title: "",
//         customerid: null,
//         categoryid: null,
//         subcategoryid: [],
//         source_id: null,
//         visible_id: 0,
//         duedate: "",
//         admins: [],
//         cleaners: [],
//         answer: "",
//         userid: localStorage.getItem("user")
//       }}
//       keyName="createTicketFromNewTicketList"
//       formTitle={`Create New Ticket`}
//       formSubmitApiUrl="createticketByAdmin"
//     /> : <></>
//   }




// ✅ pass this data when dynamically want to send body like query , params, body


// dynamicApiData={{
//     getAreaManagerActiveChamps: {
//         url: 'getAreaManagerActiveChamps',
//         method: 'GET',
//         isQuery: `champId=${ChampDetail?.id}`,
//         isParams: null,
//         isBody: null,
//     }
// }}