/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef, useState } from 'react'
import clsx from 'clsx'
import React from "react"
import Not_Found_Data from '../../Not_Found_Data'
import DOMPurify from 'dompurify';
import { WhatsappMessageSwitchAction } from '../ChatBoxComponents/WhatsappMessageSwitchAction';
import ExtotelAudioUrl from '../ChatBoxComponents/ExtotelAudioUrl';

const ChatHistoryBodyNew = (props: any, { isDrawer = true }) => {
    const isloading2 = false
    const { ticketreplies } = props
    const { customerDetailData } = props
    const refss: any = useRef(null)
    const SeeImageFullWidth = (images: any) => {
    }


    const [whatsappImages, setWhatsImages] = useState<any>({})
    const [whatsappImageRefsid, setwhatsappImageRefsid] = useState<any>({})
    const [loader, setLoader] = useState(false)

    
    function getImage(endUrl: any, whatsappMessageId: any) {
        setLoader(true)
        const options = {
            method: "GET",

            headers: {
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2MWZkZWJhZS0xYTBmLTQ4ZDMtYWUyNS0xM2I1MjczZWNjZGEiLCJ1bmlxdWVfbmFtZSI6ImNhcnNlbG9uYWNhcnNAZ21haWwuY29tIiwibmFtZWlkIjoiY2Fyc2Vsb25hY2Fyc0BnbWFpbC5jb20iLCJlbWFpbCI6ImNhcnNlbG9uYWNhcnNAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDYvMDkvMjAyMiAxMjozMToyOCIsImRiX25hbWUiOiI5ODI0IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.8SQ5R0D86qJo7e-2wOOMzRf6xMH8EKsz7B7Pc-NnWJk',
            },
            responseType: "blob"

        }

        fetch(`https://live-server-9824.wati.io/api/v1/getMedia?fileName=${endUrl && endUrl?.split(".net/")[1]}`, options)
            .then(response => response.blob())
            .then((response: any) => {
                const myBlobURL = URL.createObjectURL(response);
                setwhatsappImageRefsid({ ...whatsappImageRefsid, [whatsappMessageId]: whatsappMessageId })
                setWhatsImages({ ...whatsappImages, [whatsappMessageId]: myBlobURL })
                setLoader(false)


            })
            .catch(err => console.error(err));
    }


    const [fetchedUrls, setFetchedUrls] = useState<any>({});


    return (
        <>
            {
                isloading2 ? <div className='d-flex align-items-center justify-content-center h-75 flex-column'>
                    <div className='spinner-border mr-15' role='status'></div>
                    <h4 className='fw-bold'>Loading...</h4>
                </div> :
                    <div
                        className='card p-2'
                        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
                    >
                        <div
                            style={{ maxHeight: !isloading2 ? "400px" : "" }}
                            // className={clsx('scroll-y me-n5 pe-5', { ' h-600px ': !isDrawer })}
                            className={clsx('scroll-y me-n5 pe-5  max', { 'h-lg-auto': !isDrawer })}
                            data-kt-element='messages'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies={
                                isDrawer
                                    ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                                    : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                            }
                            data-kt-scroll-wrappers={
                                isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
                            }
                            data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                        >





                             {ticketreplies?.length > 0 ? ticketreplies?.map((message: any, index: number, arr: any) => {
                                let whatsappMessageIds = message.whatsappMessageId
                                let newMessage = message?.answers
                                newMessage = newMessage?.replace(/\r?\n/g, '<br />')
                                const state = message?.whatsappMessageId && message.usertype === 3 ? "danger text-start" : message?.whatsappMessageId && message.usertype !== 3 ? "danger text-end" : message.userid == 0 ? 'info text-start' : 'primary text-end'
                                const whatsppTicketChangeActionRefs = message?.whatsappMessageId && message.userid == 0 ? "whatsapp" : message?.whatsappMessageId && message.userid != 0 ? "whatsapp" : message.userid == 0 ? "tickets" : "tickets"
                                const templateAttr = {}
                                if (message.template) {
                                    Object.defineProperty(templateAttr, 'data-kt-element', {
                                        value: `template-${message.userid}`,
                                    })
                                }

                                const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message?.whatsappMessageId && message.usertype === 3 || !message?.whatsappMessageId && message.userid == 0 ? 'start' : 'end'} mb-5`

                           
                                return (
                                    <>
                                        {
                                            message?.type == 1 ?
                                                <div
                                                    ref={refss}
                                                    key={`message${index}`}
                                                    className={clsx('d-flex', contentClass, 'mb-5', { 'd-none': message.template })}
                                                    {...templateAttr}
                                                >

                                                    <div
                                                        className={clsx(
                                                            'd-flex flex-column align-items',
                                                            `align-items-${message?.whatsappMessageId && message.usertype === 3 || !message?.whatsappMessageId && message.userid == 0 ? 'start' : 'end'}`
                                                        )}
                                                    >
                                                        <div className='d-flex align-items-center '>
                                                            {message?.whatsappMessageId && message.usertype === 3 ? (
                                                                <>
                                                                    <div className='ms-3'>
                                                                        <span className='text-muted fs-7 mb-1 me-2'>
                                                                            {(message.createdAt)}
                                                                            <small>
                                                                                {message.status == 1 ? '' : ' ( private message ) '}
                                                                            </small>
                                                                        </span>
                                                                        <a
                                                                            href='#'
                                                                            className=' text-gray-900 text-hover-primary me-1'
                                                                        >
                                                                            ( {
                                                                                message?.usertype == 1 ? "Cleaner" :
                                                                                    message?.usertype == 2 ? "Supervisor" :
                                                                                        message?.usertype == 3 ? "Customer" :
                                                                                            message?.usertype == 4 ? "Watchman" :
                                                                                                message?.usertype == 5 ? "Admin" :
                                                                                                    message?.usertype == 6 ? "Telecaller" :
                                                                                                        message?.usertype == 7 ? "Vendor" :
                                                                                                            message?.usertype == 8 ? "Operation Head" :
                                                                                                                "Unknown"
                                                                            } )
                                                                            {message.name}
                                                                        </a>
                                                                        <span className='me-5'> {whatsppTicketChangeActionRefs == "whatsapp" && <WhatsappMessageSwitchAction customerDetailData={customerDetailData} WhatsappMessageid={message} />} </span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className='me-3'>
                                                                        <span className='text-muted fs-7 mb-1'>
                                                                            {message.createdAt}
                                                                            <small>
                                                                                {message.status == 1 ? '' : '(private message)'}
                                                                            </small>
                                                                        </span>
                                                                        <a
                                                                            href='#'
                                                                            className='fs-8 fw-bold  text-primary text-hover-dark me-1'
                                                                        >
                                                                            ( {
                                                                                message?.usertype == 1 ? "Cleaner" :
                                                                                    message?.usertype == 2 ? "Supervisor" :
                                                                                        message?.usertype == 3 ? "Customer" :
                                                                                            message?.usertype == 4 ? "Watchman" :
                                                                                                message?.usertype == 5 ? "Admin" :
                                                                                                    message?.usertype == 6 ? "Telecaller" :
                                                                                                        message?.usertype == 7 ? "Vendor" :
                                                                                                            message?.usertype == 8 ? "Operation Head" :
                                                                                                                "Unknown"
                                                                            } )
                                                                            {message.name}
                                                                        </a>
                                                                        <span className='me-5'> {whatsppTicketChangeActionRefs == "whatsapp" && <WhatsappMessageSwitchAction customerDetailData={customerDetailData} WhatsappMessageid={message} />} </span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={clsx(
                                                                'p-2 rounded',
                                                                `bg-light-${message?.image?.includes(".mp3") ? "" : state}`,
                                                                'text-dark  mw-lg-400px',
                                                                // `text-end`,
                                                            )}
                                                            data-kt-element='message-text'
                                                            style={{ width: '80%' }}
                                                        >
                                                            {
                                                                message?.image ?
                                                                    <>
                                                                        {
                                                                            message?.image?.includes(".mp3") ? 
                                                                            // <audio src={message.image} controls /> 
                                                                            <ExtotelAudioUrl recordingUrl={message?.image} setFetchedUrls={setFetchedUrls} fetchedUrls={fetchedUrls} />
                                                                            :
                                                                                <>
                                                                                    {
                                                                                        message?.whatsappMessageId ?
                                                                                            <>
                                                                                                {
                                                                                                    <div>
                                                                                                        {
                                                                                                            whatsappImageRefsid[whatsappMessageIds] == message?.whatsappMessageId ?
                                                                                                                <>
                                                                                                                    <img className='w-100' src={`${whatsappImages[whatsappMessageIds]}`} onClick={() => SeeImageFullWidth(whatsappImages[whatsappMessageIds])} />
                                                                                                                    <p style={{ width: "100%", textAlign: "start", padding: '5px' }}>
                                                                                                                        {message.answers ? message.answers : ""}
                                                                                                                    </p>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <button onClick={() => getImage(message?.image, whatsappMessageIds)} style={{ width: "100%", height: "200px", border: "1px solid pink" }}>
                                                                                                                        <div className="bg-image" style={{ backgroundImage: `url(${"https://pbs.twimg.com/profile_images/1340989495219347456/b1op9SGE_400x400.jpg"})` }}></div>
                                                                                                                        {/* <div className="bg-text"> */}
                                                                                                                        <div className='img-text'>
                                                                                                                            <div className='d-flex align-items-center justify-content-center  flex-column'>
                                                                                                                                {
                                                                                                                                    loader ? <>
                                                                                                                                        <div className='spinner-border mr-15' role='status'></div>
                                                                                                                                        <h4 className='fw-bold mt-2 text-white'>Loading...</h4>
                                                                                                                                    </> : <i style={{ fontSize: "30px" }} className="bi bi-arrow-down-circle text-white "></i>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {/* </div> */}
                                                                                                                    </button>
                                                                                                                    <p style={{ width: "100%", textAlign: "start", padding: '1px' }}>
                                                                                                                        {message.answers ? message.answers : ""}
                                                                                                                    </p>
                                                                                                                </>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </> : <>
                                                                                                <img className='w-100' src={message?.image} onClick={() => SeeImageFullWidth(message?.image)} />
                                                                                                <p style={{ width: "100%", textAlign: "start", padding: '5px' }}>
                                                                                                    {message.answers ? message.answers : ""}
                                                                                                </p>
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>

                                                                        <div className='text-start' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.answers ? newMessage : "No Message") }}></div>
                                                                        <div className='mt-4 text-end text-success'> {message?.wa_statusString} </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    
                                                    </div>



                                                </div>

                                                :

                                                <div className='d-flex justify-content-center' >
                                                    <p className='text-muted text-center'><small>{message?.answers}</small></p>
                                                </div>
                                        }

                                    </>
                                )

                            })
                                :
                                <Not_Found_Data headerTitle={"No Chat Messsage Found"} size={25}></Not_Found_Data>
                            }
                        </div>
                    </div>
            }
        </>
    )
}
export { ChatHistoryBodyNew }






// {ticketreplies.length > 0 ? ticketreplies?.map((message: any, index: number) => {
//     let whatsappMessageIds = message.whatsappMessageId

//     let newMessage = message?.answers


//     newMessage = newMessage?.replace(/\r?\n/g, '<br />')


//     const state = message.userid == 0 ? 'info text-start' : 'primary text-end'
//     const templateAttr = {}
//     if (message.template) {
//         Object.defineProperty(templateAttr, 'data-kt-element', {
//             value: `template-${message.userid}`,
//         })
//     }
//     const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.userid == 0 ? 'start' : 'end'
//         } mb-5`
//     return (
//         <div
//             ref={refss}
//             key={`message${index}`}
//             className={clsx('d-flex', contentClass, 'mb-5', { 'd-none': message.template })}
//             {...templateAttr}
//         >
//             <div
//                 className={clsx(
//                     'd-flex flex-column align-items',
//                     `align-items-${message.userid == 0 ? 'start' : 'end'}`
//                 )}
//             >
//                 <div className='d-flex align-items-center'>
//                     {message.userid === 0 ? (
//                         <>
//                             <div className='ms-3'>
//                                 <span className='text-muted fs-7 mb-1'>{message.createdAt}<small>{message.status == 1 ? '' : '(private message)'}</small></span>
//                                 <a
//                                     href='#'
//                                     className=' fw-bolder text-gray-900 text-hover-primary me-1'
//                                 >

//                                     ( {
//                                         message?.usertype == 1 ? "Cleaner" :
//                                             message?.usertype == 2 ? "Supervisor" :
//                                                 message?.usertype == 3 ? "Customer" :
//                                                     message?.usertype == 4 ? "Watchman" :
//                                                         message?.usertype == 5 ? "Admin" :
//                                                             message?.usertype == 6 ? "Telecaller" :
//                                                                 message?.usertype == 7 ? "Vendor" :
//                                                                     message?.usertype == 8 ? "Operation Head" :
//                                                                         "Unknown"
//                                     } )

//                                     {message.name}
//                                 </a>
//                             </div>
//                         </>
//                     ) : (
//                         <>
//                             <div className='me-3'>
//                                 <span className='text-muted fs-7 mb-1'>{message.createdAt} <small>{message.status == 1 ? '' : '(private message)'}</small></span>
//                                 <a
//                                     href='#'
//                                     className=' fs-8 fw-bold  text-primary text-hover-dark me-1'
//                                 >

//                                     ( {
//                                         message?.usertype == 1 ? "Cleaner" :
//                                             message?.usertype == 2 ? "Supervisor" :
//                                                 message?.usertype == 3 ? "Customer" :
//                                                     message?.usertype == 4 ? "Watchman" :
//                                                         message?.usertype == 5 ? "Admin" :
//                                                             message?.usertype == 6 ? "Telecaller" :
//                                                                 message?.usertype == 7 ? "Vendor" :
//                                                                     message?.usertype == 8 ? "Operation Head" :
//                                                                         "Unknown"
//                                     } )


//                                     {message.name}
//                                 </a>
//                             </div>
//                         </>
//                     )}
//                 </div>
//                 <div
//                     className={clsx(
//                         'p-2 rounded',
//                         `bg-light-${message?.image?.includes(".mp3") ? "" : state}`,
//                         'text-dark  mw-lg-400px',
//                         // `text-end`
//                     )}
//                     data-kt-element='message-text'
//                     style={{ width: '96%' }}
//                 >


//                     {
//                         message?.image ?
//                             <>
//                                 {
//                                     message?.image?.includes(".mp3") ? <audio src={message.image} controls /> :
//                                         <>
//                                             {
//                                                 message?.whatsappMessageId ? <>
//                                                     {
//                                                         <>
//                                                             {
//                                                                 whatsappImageRefsid[whatsappMessageIds] == message?.whatsappMessageId ?
//                                                                     <>
//                                                                         <img className='w-100' src={`${whatsappImages[whatsappMessageIds]}`} onClick={() => SeeImageFullWidth(whatsappImages[whatsappMessageIds])} />
//                                                                         <p style={{ width: "100%", textAlign: "start", padding: '5px' }}>
//                                                                             {message.answers ? message.answers : ""}
//                                                                         </p>

//                                                                     </>
//                                                                     :
//                                                                     <>
                                                                




//                                                                         <button onClick={() => getImage(message?.image, whatsappMessageIds)} style={{ width: "100%", height: "200px", border: "1px solid pink" }}>
//                                                                             <div className="bg-image" style={{ backgroundImage: `url(${"https://pbs.twimg.com/profile_images/1340989495219347456/b1op9SGE_400x400.jpg"})` }}></div>


//                                                                             <div className='img-text'>

//                                                                                 <div className='d-flex align-items-center justify-content-center  flex-column'>


//                                                                                     {
//                                                                                         loader ? <>
//                                                                                             <div className='spinner-border mr-15' role='status'></div>
//                                                                                             <h4 className='fw-bold mt-2 text-white'>Loading...</h4>
//                                                                                         </> : <i style={{ fontSize: "30px" }} className="bi bi-arrow-down-circle text-white "></i>
//                                                                                     }
//                                                                                 </div>

//                                                                             </div>
//                                                                         </button>

//                                                                         <p style={{ width: "100%", textAlign: "start", padding: '1px' }}>
//                                                                             {message.answers ? message.answers : ""}
//                                                                         </p>
//                                                                     </>
//                                                             }
//                                                         </>
//                                                     }
//                                                 </> : <>
//                                                     <img className='w-100' src={message?.image} onClick={() => SeeImageFullWidth(message?.image)} />
//                                                     <p style={{ width: "100%", textAlign: "start", padding: '5px' }}>
//                                                         {message.answers ? message.answers : ""}
//                                                     </p>
//                                                 </>
//                                             }
//                                         </>
//                                 }
//                             </>
//                             :
//                             <>
//                                 <div className='text-start' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.answers ? newMessage : "No Message") }}></div>
//                                 <div className='mt-4 text-end text-success'> {message?.wa_statusString} </div>
//                             </>
//                     }







//                 </div>
//             </div>
//         </div>
//     )
// })