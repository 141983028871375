import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postRequest } from '../../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit, faTruckFieldUn } from '@fortawesome/free-solid-svg-icons';
import SingleSelectSearchCategoryDefault from '../../../../consts/Select/SingleSelectSearchCategoryDefault';


const UpdateChampRequirementType = ({ row }: any) => {
    const isVacancyAssigned = row?.champ_requirement_completed_champ?.first_name + row?.champ_requirement_completed_champ?.last_name

    const locationData: any = useSelector((store: any) => store.StateMangenementCenter.updateTrainingLocationData) || []
    const areaListData: any = useSelector((store: any) => store.StateMangenementCenter.areaListData) || []
    const [loader, setLoader] = useState<any>(false)
    const [show, setShow] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [defaultLocationData, setDefaultLocationData] = useState<any>(null)
    const [defaultAreaData, setdefaultAreaData] = useState<any>(null)

    const [payload, setPayload] = useState<any>({
        "id": row?.id,
        "updateFields": {
            location: {
                training_location_id: null,
                area_id: null,
            }
        }
    })


    useEffect(() => {
        const currentData = locationData?.find((ele: any) => ele?.id == storeRow?.trainingLocation?.id)
        setDefaultLocationData(currentData ? currentData : null)
        currentData && setPayload((prev:any)=>({ ...prev, ["updateFields"]: {  ["location"]: { ...prev?.updateFields?.location, ["training_location_id"]: currentData?.id } } }))

        // currentData && setPayload({ ...payload, ["updateFields"]: { ...payload?.updateFields, ["training_location_id"]: currentData?.id } })
    }, [locationData, storeRow?.trainingLocation?.locationname])


    useEffect(() => {
        const currentData = areaListData?.find((ele: any) => ele?.id == storeRow?.champ_requirement_area?.id)
        setdefaultAreaData(currentData ? currentData : null)
        currentData && setPayload((prev:any)=>({ ...prev, ["updateFields"]: {  ["location"]: { ...prev?.updateFields?.location, ["area_id"]: currentData?.id } } }))
       
       
        // currentData && setPayload({ ...payload, ["updateFields"]: { ...payload?.updateFields, ["area_id"]: currentData?.id } })
    }, [areaListData, storeRow?.champ_requirement_area?.areaname])



    useEffect(() => { setStoreRow(row) }, [row])

    const updateRequirementType = async () => {

        setLoader(true)

        if (payload?.updateFields?.location?.training_location_id && payload?.updateFields?.location?.area_id) {
            const response = await postRequest(URL_EndPoints(null)?.updateChampRequirement, payload, setLoader)
            if (response?.data?.status === 200) {
                toast.success(response?.data?.msg, { position: "top-center" })
                const updatedData = locationData?.find((location: any) => location?.id == payload?.updateFields?.location?.training_location_id)
                row.trainingLocation = row.trainingLocation ? { ...row.trainingLocation } : {}
                row.trainingLocation.locationname = updatedData?.locationname
                setStoreRow(row)
                setLoader(false)
                setShow(false)

            } else {
                toast.error(response?.data?.msg ? response?.data?.msg : "Seems server side problem", { position: "top-center" })
                setLoader(false)
                setShow(false)

            }
            setLoader(false)
        }
        else {
            toast.error("Please select location ", { position: "top-center" })
            setLoader(false)

        }
    }


    const OnchangeHandler = (event: any, name: any) => {
        setPayload((prev:any)=>({ ...prev, ["updateFields"]: {  ["location"]: { ...prev?.updateFields?.location, [name]: event ? event?.value : null } } }))
    }


    const modalHandler = (isVacancyFilledBy: any) => {

        if (isVacancyFilledBy) {
            toast.success("Vacancy already filled so action is not allowed !", { position: "top-center" })
            return
        }
        setShow(faTruckFieldUn)

    }

    return (


        <>

            {
                loader ? "Updating..." :
                    <>
                        <FontAwesomeIcon icon={isVacancyAssigned ? faBan : faEdit} className={` me-2 cursor-pointer text-${isVacancyAssigned ? "danger" : "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} />
                        <span>{storeRow?.trainingLocation?.locationname ? storeRow?.trainingLocation?.locationname : "NA"}</span>
                    </>

            }


            <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-small">
                <Modal.Header closeButton>
                    <Modal.Title>Update Champ Training Location </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-3">
                        <div className="col-12 mb-2">
                         

                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={OnchangeHandler}
                                HeaderTitle={"Select Location"}
                                SelectData={locationData}
                                DynamicKey={"locationname"}
                                id={"id"}
                                name={"training_location_id"}
                                defaultData={defaultLocationData ? { label: defaultLocationData?.locationname, value: defaultLocationData?.id, item: defaultLocationData } : null}
                            />


                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={OnchangeHandler}
                                HeaderTitle={"Select Area Name"}
                                SelectData={areaListData}
                                DynamicKey={"areaname"}
                                id={"id"}
                                name={"area_id"}
                                defaultData={defaultAreaData ? { label: defaultAreaData?.areaname, value: defaultLocationData?.id, item: defaultAreaData } : null}
                            />


                        </div>

                    </div>


                    <div className='d-flex flex-end justify-content-end col-12'>
                        <button
                            className={`btn btn-sm text-${loader ? "dark" : "white"} btn-${loader ? "success" : "primary"} mb-5 `}
                            onClick={updateRequirementType}
                            disabled={loader}

                        >
                            {loader ? "Updating..." : "Update"}

                        </button>
                    </div>
                </Modal.Body>
            </Modal >

        </>







    )
}
export default UpdateChampRequirementType
