import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import AddEditTermCondition from "./AddEditTermCondition"
import AdminReadOnlyEditor from "../admins/adminForms/adminFormComponents/AdminReadOnlyEditor"
import { isHtmlContent } from "../admins/adminUtils"
import ViewTermRowWise from "./ViewTermRowWise"
export const TermsColumn = (refreshNow: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        cell: (row: any) => row?.id ?? "NA",
        sortField: 'id',
        sortable: true,
        width: "80px"
    },
    {
        id: 2,
        reorder: true,
        name: "Action",
        cell: (row: any) => <UpdateTermsCondition row={row} refreshNow={refreshNow} />,
        width: "100px"
    },

    {
        id: 3,
        reorder: true,
        name: "Text",
        cell: (row: any) => <ViewTandC row={row} />,
        sortField: 'text',
        sortable: true,
        width: "150px"
    },
    {
        id: 3,
        reorder: true,
        name: "Language",
        cell: (row: any) => row?.lang_text,
        sortField: 'lang_text',
        sortable: true,
        width: "100px"
    },
    {
        id: 3,
        reorder: true,
        name: "Status",
        cell: (row: any) => <ActiveInactive row={row} />,
        sortField: 'status',
        sortable: true,
        width: "100px"
    },
    {
        id: 3,
        reorder: true,
        name: "Is Important",
        cell: (row: any) => <YesNo row={row} />,
        sortField: 'is_checkbox_required',
        sortable: true,
        width: "100px"
    },
]


const ViewTandC = ({ row }: any) => {
    const [htmlContent, setHtmlContent] = useState("")
    const [show, setShow] = useState(false)
    const viewTerms = (row: any) => {
        setHtmlContent(row?.text)
        setShow(true)
    }
    return (
        <>
            {
                isHtmlContent(row?.text) ?
                    <>
                        <div className="d-flex justify-content-start align-items-center cursor-pointer text-primary fw-semibold" onClick={() => viewTerms(row)}>View T&C <FontAwesomeIcon icon={faEye} className="text-primary ms-3 fs-2" /></div>
                    </>
                    :
                    row?.text
            }
            {
                show ? <ViewTermRowWise show={show} setShow={setShow} keyName="ViewTermsCondition" htmlContent={htmlContent} /> : <></>

            }
        </>
    )
}
const YesNo = ({ row }: any) => {
    const isImportant = row?.is_checkbox_required
    return (
        <>
            <span className={`badge badge-${isImportant ? "success" : "danger"}`}>{isImportant ? "Yes" : "No"}</span>
        </>
    )
}

const ActiveInactive = ({ row }: any) => {
    const isActive = row?.status == 1 ? true : false
    return (
        <>
            <span className={`badge badge-${isActive ? "success" : "danger"}`}>{isActive ? "Active" : "Inactive"}</span>
        </>
    )
}


const UpdateTermsCondition = ({ row, refreshNow }: any) => {
    const [setShowAddForm, setSetShowAddForm] = useState(false)

    return (
        <>
            <span>T.C <FontAwesomeIcon className='me-2 ms-4 text-muted fs-4' icon={faEdit} onClick={() => setSetShowAddForm(true)} /></span>
            {
                setShowAddForm ?
                    <AddEditTermCondition
                        show={setShowAddForm}
                        setShow={setSetShowAddForm}
                        refreshNow={refreshNow}
                        keyName="AddTerms"
                        formTitle="Add Term Condition"
                        defaultLanguage={{ label: row?.lang_text, value: row?.lang }}
                        initialPayload={{
                            lang: row?.lang,
                            lang_text: row?.lang_text,
                            text: row?.text,
                            is_checkbox_required: row?.is_checkbox_required,
                            status: row?.status,
                        }}
                        isEditAllowed={true}
                        row={{
                            id: row?.id
                        }}
                    />
                    : <></>
            }

        </>
    )
}