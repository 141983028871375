
// import "react-tooltip/dist/react-tooltip.css";
// import { Tooltip as ReactTooltip } from "react-tooltip";
const InformationChip = ({ SelectedString, classes, items, Toggleid, Id = null, toolTipName = false, isClosedCss }: any) => {
    return (
        <>
            <div className={`badge badge-light-${classes} text-${isClosedCss} cursor-pointer me-lg-1`} >
                {
                    SelectedString
                }
            </div>
        </>
    )
}
export default InformationChip
