import DataTable from "react-data-table-component"
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from "../../../consts/components/Utility"
import { KTCardBody } from "../../../../_metronic/helpers"
import MainContainerWrapper from "../../../consts/mediaQueryLayout/MainContainerWrapper"
import LeftBox from "../../../consts/mediaQueryLayout/LeftBox"
import SearchBoxModal from "../../../consts/reuseModules/SearchBoxModal"
import RightBox from "../../../consts/mediaQueryLayout/RightBox"
import { FilterHandler } from "../../../consts/DynamicFilteration/FilterHandler"
import { ReactTableComponentStylesSheet } from "../../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet"
import ExpandedComponent from "./ExpandedComponent"
// () => {
//   const data = tableDataItems;
//   data[0].defaultExpanded = true;
//   return <DataTable title="Movie List - First row expanded" columns={columns} data={data} expandableRows expandableRowExpanded={row => row.defaultExpanded} expandableRowsComponent={ExpandedComponent} pagination />;
// }

interface AdminServerSidePaginationListsInterface {
  title: string
  reorderColumnTitle: string | ""
  conditionalRowStyles: any
  sortedColumns: any
  filterData: any
  pending: boolean
  clearSelectedRows: boolean
  totalRows: number
  onChangeRowsPerPage: (newPerPageSize: number, page: number) => void
  onChangePage: (page: number) => void
  onSelectedRowsChange: (selectedRows: any) => void
  keyName: string
  search: string
  setSearch: any
  showFilter?: any,
  expandableRows?: boolean
  paginationPerPage?: any
  showPagination?: any
  paginationServer?: any
  sortServer?: any
  handleSort: (column: any, sortDirection: string) => void
  SubHeaderComponentRightSection: React.ComponentType<any>
  SubHeaderComponentActionButtons: React.ComponentType<any>
  ExpandedComponent: React.ComponentType<any>
  // filter handler props 
  filterHandler: {
    onChangeHandler: any,
    actionHandler: any,
    getCurrentFilter: any,
    removeFilter: any,
    payload: any,
    Component: any,
    filterData: any,
    OperatorsData: any,
    selectedFilterData: any,
    filterComponents: any,
    storeFilterList: any,
    filterkey: any,
    setSeacrch: any,
    isLoadingFilter: boolean,
    listApiUrl: any,
  }
}

// ✅ Note : Create new table list using this code for dynamic  or server side pagination
export const AdminServerSidePaginationLists: React.FC<AdminServerSidePaginationListsInterface> = ({ title, reorderColumnTitle, conditionalRowStyles, sortedColumns, filterData, pending, clearSelectedRows, totalRows, onChangeRowsPerPage, onChangePage, onSelectedRowsChange, handleSort, keyName, search, setSearch, SubHeaderComponentRightSection, SubHeaderComponentActionButtons, filterHandler, showFilter, expandableRows = true, ExpandedComponent, paginationPerPage, showPagination = true, sortServer = true, paginationServer = true }) => {

  return (
    <KTCardBody className="card py-4">
      <DataTable
        title={title}
        selectableRows
        progressPending={pending}
        columns={reorderColumns(sortedColumns, reorderColumnTitle)}
        data={filterData}
        onColumnOrderChange={cols => storeReorderIndexes(cols, reorderColumnTitle)}
        customStyles={ReactTableComponentStylesSheet}
        fixedHeader
        fixedHeaderScrollHeight='auto'
        selectableRowsHighlight
        highlightOnHover
        subHeader
        clearSelectedRows={clearSelectedRows}
        pagination={showPagination}
        sortServer={sortServer}
        paginationServer={paginationServer}
        conditionalRowStyles={conditionalRowStyles}
        onSort={handleSort}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onSelectedRowsChange={onSelectedRowsChange}
        paginationComponentOptions={paginationRowsPerPageOptionsAll}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        paginationPerPage={paginationPerPage}
        key={keyName}
        expandableRows={expandableRows}
        expandableRowsComponent={ExpandedComponent}
        subHeaderComponent={
          <>
            <MainContainerWrapper>
              <LeftBox>
                {
                  setSearch && <SearchBoxModal search={search} setSearch={setSearch} />
                }
              </LeftBox>
              <RightBox>
                <SubHeaderComponentRightSection />
                {
                  showFilter && <FilterHandler
                    onChangeHandler={filterHandler?.onChangeHandler}
                    actionHandler={filterHandler?.actionHandler}
                    getCurrentFilter={filterHandler?.getCurrentFilter}
                    removeFilter={filterHandler?.removeFilter}
                    payload={filterHandler?.payload}
                    Component={filterHandler?.Component}
                    filterData={filterHandler?.filterData}
                    OperatorsData={filterHandler?.OperatorsData}
                    selectedFilterData={filterHandler?.selectedFilterData}
                    filterComponents={filterHandler?.filterComponents()}
                    storeFilterList={filterHandler?.storeFilterList}
                    filterkey={filterHandler?.filterkey}
                    setSeacrch={filterHandler?.setSeacrch}
                    isLoadingFilter={filterHandler?.isLoadingFilter}
                    listApiUrl={filterHandler?.listApiUrl}
                  />
                }
              </RightBox>
            </MainContainerWrapper>
            <SubHeaderComponentActionButtons />
          </>
        }
      />
    </KTCardBody>
  )
}

// <KTCardBody className='card py-4'>
//             <AdminServerSidePaginationLists
//                 title="xxxxxxxxxxx"
//                 reorderColumnTitle="xxxxxxxxxxxReorderColumnItem"
//                 sortedColumns={sortedColumns}
//                 filterData={filterData}
//                 pending={pending}
//                 clearSelectedRows={toggledClearRows}
//                 totalRows={totalRows}
//                 onChangeRowsPerPage={handlePerRowsChange}
//                 onChangePage={handlePageChange}
//                 onSelectedRowsChange={handleSelectedRowsChange}
//                 conditionalRowStyles={{}}
//                 handleSort={handleSort}
//                 keyName={"xxxxxxxxxxx"}
//                 search={search}
//                 setSearch={setSearch}
//                 paginationPerPage={perPageSize}
//                 ExpandedComponent={()=><></>}
//                 filterHandler={{
//                     onChangeHandler: onChangeHandler,
//                     actionHandler: actionHandler,
//                     getCurrentFilter: getCurrentFilter,
//                     removeFilter: removeFilter,
//                     payload: payload,
//                     Component: DynamicFilter,
//                     filterData: filter,
//                     OperatorsData: operators,
//                     selectedFilterData,
//                     filterComponents: KeywiseComponents,
//                     storeFilterList: storeFilterList,
//                     filterkey: filterkey,
//                     setSeacrch: setSearchFilter,
//                     isLoadingFilter: isLoadingFilter
//                     listApiUrl: listApiUrl("XXXXXXX")
//                 }}
//                 SubHeaderComponentRightSection={() => (<>
//                         {/* Other Action buttons */}
//                 </>)}
//                 SubHeaderComponentActionButtons={() => (
//                     <>
//                         {
//                             filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
//                                 <BulkUtilButton title="Bulk Assign  Champs" filteredSelectedRows={filteredSelectedRows} setShow={setOpenAssignModal} />
//                             </BulkUtilButtonWrapper> : <></>
//                         }
//                     </>
//                 )}
//             />
//         </KTCardBody>