import React, { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import FileUploadEdit from './FileUploadEdit'
const EditDocuments = ({ row, show, setShow, documentData, documentTypeList, loadInitialData, isAciveOrInactiveDocument }: any) => {

  return (
    <>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="small-modal"
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-muted'>Edit Document / Make Document Inactive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileUploadEdit url="editChampDocument" payload={{ upload: false }} btnTitle="Make Document Inactive" setShow={setShow} row={row} documentData={documentData} documentTypeList={documentTypeList} loadInitialData={loadInitialData} isAciveOrInactiveDocument={isAciveOrInactiveDocument} />
        </Modal.Body>
      </Modal>

    </>
  )
}

export default EditDocuments
