import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ColModal, GroupModal, RowModal } from '../../../widgets/forms/FormsModals'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { getRequest, postRequest } from '../../../Server/Requests'
import { AllFielSearch } from '../../../FieldSearch/AllFieldSearch'
import { toast } from 'react-toastify'
import { QueryParams } from '../../../Server/QueryParams'
import { columns } from './columns'
import { isAllowedUser } from '../../../components/Utility'
import { ReactTableComponentStylesSheet } from '../../../reuseModules/ReactTableComponent/ReactTableComponentStylesSheet'
import { Form } from 'react-bootstrap'
import clsx from 'clsx'
import SingleSelectSearchCategory from '../../../Select/SingleSelectSearchCategory'
import SingleSelectSearchCategoryDefault from '../../../Select/SingleSelectSearchCategoryDefault'
import { adminMenuActionPermissionKeys } from '../../../../modules/admins/adminConstant'
import PermissionContextProvider from '../../../../modules/admins/adminComponents/PermissionContextProvider'


const SchemeInputBox = ({ classes = "", title = "Not set", name = "", keyName = "", id = "", isData = [], payload, setPayload, defValue = "" }: any) => {
    return <Form.Select className={clsx(classes)} name={name} required onChange={(event: any) => setPayload({ ...payload, [event.target.name]: event.target.value })} defaultValue={defValue}>
        <option value="">{title}</option>
        {isData?.map((ele: any) => { return <option value={ele[id]}>{ele[keyName]}</option> })}

    </Form.Select>
}


const ChampSchemes = ({ row, refereshID }: any) => {

    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<boolean>(false)
    const [filterData, setfilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [loader, setLoader] = useState<boolean>(false)
    const [schemeLists, setSchemeLists] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        slab_id: 0,
        cleanerid: row?.id,
        cleaner_payout_scheme_id: null,
        min_earning_week: null,
        scheme_assigned_date: ""
        // 2023 -08 - 25
    })

    async function getSchemeRecord() {
        setPending(true)
        let newQueryParams = { ...QueryParams("getSchemeRecord", "/") }
        newQueryParams.What += row?.id
        const { data } = await getRequest(URL_EndPoints(newQueryParams)?.getSchemeRecord, setPending)
        setData(data.data)
        setfilterData(data.data)

    }
    useEffect(() => {
        async function loadSchemeRecord() {
            getSchemeRecord()
            const getSchemesList = await getRequest(URL_EndPoints()?.getAllActiveSchemes, setPending)
            setSchemeLists(getSchemesList?.data?.data)
        }
        loadSchemeRecord()

    }, [row?.id, refereshID])

    useEffect(() => {
        const updatedData = AllFielSearch(data, search)
        setfilterData(updatedData)
    }, [search])

    const handleSubmitAssign = async () => {
        setLoader(true)
        const { data } = await postRequest(URL_EndPoints()?.assignSchemeToCleaner, payload, setLoader)
        if (data?.status == 200) {
            toast.success(data?.msg, { position: "top-center" })
            getSchemeRecord()
        }
        else {
            toast.error(data?.msg, { position: "top-center" })
        }
    }

    const handleChangeInput = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event.value : null, ["payout_type"]: event ? event?.item?.payout_type : null })
    }
    return (
        <>

            {

                <DataTable
                    title=""
                    columns={columns}
                    data={filterData}
                    pagination
                    progressPending={pending}
                    fixedHeader
                    fixedHeaderScrollHeight='auto'
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    customStyles={ReactTableComponentStylesSheet}
                    subHeaderComponent={<>


                        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-scheme-records-assign-change"]}>
                            <div className="col-12 " style={{ display: isAllowedUser?.includes(Number(localStorage.getItem("user"))) ? "block" : "none" }}>
                                <RowModal classes="d-flex flex-wrap justify-content-between ">

                                    <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                        <GroupModal classes="" >
                                            {/* <SchemeInputBox
                                            classes="mb-2"
                                            title="Select Scheme Name"
                                            name="cleaner_payout_scheme_id"
                                            keyName="scheme_name"
                                            id="id"
                                            isData={schemeLists}
                                            payload={payload}
                                            setPayload={setPayload}
                                            defValue={[]}
                                        /> */}

                                            {/* <SingleSelectSearchCategory
                                            handleChangeInputData={handleChangeInput}
                                            HeaderTitle='Select Scheme Name'
                                            SelectData={schemeLists}
                                            DynamicKey={'scheme_name'}
                                            id={'id'}
                                            name='cleaner_payout_scheme_id'
                                        ></SingleSelectSearchCategory> */}


                                            <SingleSelectSearchCategoryDefault
                                                handleChangeInputData={handleChangeInput}
                                                HeaderTitle='Select Scheme Name'
                                                SelectData={schemeLists}
                                                DynamicKey={'scheme_name'}
                                                id={'id'}
                                                name='cleaner_payout_scheme_id'
                                                defaultData={null}
                                            />
                                        </GroupModal>
                                    </ColModal>


                                    <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                        <GroupModal classes="" >
                                            <input
                                                placeholder='Min Earning Point'
                                                name='min_earning_week'
                                                type='text'
                                                value={payload?.min_earning_week}
                                                className='form-control  mb-3 mb-lg-0'
                                                autoComplete='off'
                                                onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                            />
                                        </GroupModal>
                                    </ColModal>


                                    <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                        <GroupModal classes="" >
                                            <input
                                                placeholder='Parking no. '
                                                name='scheme_assigned_date'
                                                type='date'
                                                value={payload.scheme_assigned_date}
                                                className='form-control  mb-3 mb-lg-0'
                                                autoComplete='off'
                                                onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                            />
                                        </GroupModal>
                                    </ColModal>


                                    <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                        <GroupModal classes="" >
                                            <button disabled={loader} className={`btn btn-sm btn-${loader ? "warning" : "primary"} fs-6`} onClick={handleSubmitAssign}>

                                                {loader ?
                                                    <>

                                                        <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                        <span>Assigning...</span>

                                                    </>

                                                    : " Assgin / Change"}

                                            </button>
                                        </GroupModal>
                                    </ColModal>


                                </RowModal>

                            </div>
                        </PermissionContextProvider>



                    </>}
                />
            }









        </>
    )
}

export default ChampSchemes
