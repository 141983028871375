import React, { useEffect, useState } from 'react'
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import IsLoading from '../../../consts/IsLoading/IsLoading';

const WhatsappNotification = ({ showNotifyPopUp, setShowNotifyPopUp, filteredSelectedRows, toggledClearRows, setToggleClearRows }: any) => {
    const [whatsappTemplates, setWhatsappTemplates] = useState([]);
    const [isBulkNotifyLoading, setIsBulkNotifyLoading] = useState(false);
    const [inititalLoader, setInititalLoader] = useState(false);
    const [whatsappNotifyPayload, setWhatsappNotifyPayload] = useState<any>({
        templateName: '',
    });

    const whatsTempFetch = async () => {
        setInititalLoader(true)
        const whatsappTemplates: any = await postRequest(URL_EndPoints(null)?.get_WhatsAppTemplate, {
            wa_number_id: 2
        }, setInititalLoader);
        setWhatsappTemplates(whatsappTemplates?.data?.data);
    }

    useEffect(() => {
        whatsTempFetch()
    }, [])

    const handleWhatsappInputChange = (e: any) => {
        setWhatsappNotifyPayload({
            ...whatsappNotifyPayload,
            [e.target.name]: e.target.value
        });
    }

    const handleBulkWhatsappNotifySubmit = async (e: any) => {
        e.preventDefault();
        let champIds = filteredSelectedRows?.map((row: any) => row?.id);
        whatsappNotifyPayload['champIds'] = champIds || [];
        setIsBulkNotifyLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.sendBulkWhatsAppTemplateToChamp, whatsappNotifyPayload, setIsBulkNotifyLoading)
        setIsBulkNotifyLoading(false)
        if (response?.data?.status == 200) {
            toast.success(response?.data?.msg, { position: "top-center" })
            setShowNotifyPopUp(false)
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
            setShowNotifyPopUp(false)
        }
        setToggleClearRows(!toggledClearRows)  
    }

    return (
        <>
            <Modal size='lg' show={showNotifyPopUp} onHide={() => setShowNotifyPopUp(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Send WhatsApp Notification to {filteredSelectedRows.length} Customers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleBulkWhatsappNotifySubmit}>
                        {
                            inititalLoader ? <IsLoading /> :
                                <Form.Group className="mb-3" >
                                    <Form.Select aria-label="Default select example" value={whatsappNotifyPayload.templateName} required name='templateName' onChange={handleWhatsappInputChange}>
                                        <option value="">Select Template</option>
                                        {whatsappTemplates?.map((template: any) => <option value={template.name}>{template.name}</option>)}
                                    </Form.Select>
                                </Form.Group>
                        }

                        <Form.Group className="mb-3 p-2" >
                            {
                                isBulkNotifyLoading ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary">
                                        Bulk Send
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default WhatsappNotification
