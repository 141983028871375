import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { QueryParams } from '../../../consts/Server/QueryParams'
const BulkStatusUpdateChamp = ({ showUpdateStatus, setShowUpdateStatus, filteredSelectedRows,toggledClearRows, setToggleClearRows }: any) => {
    const [loader, setLoader] = useState(false)
    const [statusUpdateDataList, setStatusUpdateDataList] = useState<any>([])

    const [payload, setPayload] = useState<any>({
        "cleaner_ids": [],
        "status_id": null
    })

    useEffect(() => {
        async function loadInitialData() {
            let newQeueryParams = { ...QueryParams("getMasterReasonForVisitors", "?") }
            newQeueryParams.What += `categoryId=11`
            const { data } = await getRequest(URL_EndPoints(newQeueryParams)?.getMasterReasonForVisitors, null)
            setStatusUpdateDataList(data?.data)
        }
        loadInitialData()
    }, [])

    const handleChange = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event, item: event ? event?.item : event })
    }

    const bulkStatusUpdateHandler = async () => {
        setLoader(true)
        let cleaner_ids = filteredSelectedRows?.map((row: any) => row?.id);
        let newPayload = {
            "cleaner_ids": payload?.cleaner_ids,
            "status_id": payload?.status_id
        }
        newPayload['cleaner_ids'] = cleaner_ids || [];

        if (payload?.status_id) {
            const response = await postRequest(URL_EndPoints(null)?.updateChampColumnsInBulk, newPayload, setLoader)
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
            setShowUpdateStatus(false)

        } else {
            toast.error("Status id missing")
            setShowUpdateStatus(false)
        }
        setToggleClearRows(!toggledClearRows) 
    }

    return (
        <>
            {showUpdateStatus ? <Modal show={showUpdateStatus} onHide={() => setShowUpdateStatus(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Bulk Status Update </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div className={`col-12  mb-1`}>
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChange}
                                HeaderTitle={"Select  Status"}
                                SelectData={statusUpdateDataList}
                                DynamicKey={"name"}
                                id={"id"}
                                name={"status_id"}
                                defaultData={null}
                            />
                        </div>
                    }
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => bulkStatusUpdateHandler()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Status</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default BulkStatusUpdateChamp
