import React, { useState, useEffect, useRef } from 'react'
import { LocalBaseURL } from '../../../../BaseURLmanagement'
import { getColumns } from './GetColumns'
import '../custom.css'
import { KTCardBody } from '../../../../_metronic/helpers'
import { AdminServerSidePaginationLists } from '../../admins/adminServerSidePaginationLists/AdminServerSidePaginationLists'
import {
  getCurrentFilterHandler,
  getFilterDataBySearch,
  initialPayload,
  operators,
  removeFilterHandler,
} from '../../../consts/DynamicFilteration/components/CommonData'
import {
  adminParamsGenerator,
  useAdminListApiUrl,
  useAdminManagementPermission,
  useAdminSmartFilterList,
} from '../../admins/adminUtils'
import { KeywiseComponents } from '../../../consts/DynamicFilteration/KeywiseComponents'
import { DynamicFilter } from '../../../consts/DynamicFilteration/FilterHandler'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import AddPurchaseOrdersForm from './addPurchaseOrder/AddPurchaseOrdersForm'
import Refresh from '../../../consts/Refresh'
import ViewInventoryModal from '../viewItemsList/ViewInventoryModal'
import GoodReceipt from '../goodReceipt/GoodReceipt'
import PaymentReceipts from '../paymentReceipt/PaymentReceipts'
import VendorInvoice from '../vendorInvoice/VendorInvoice'
import PerformInvoice from '../performInvoice/PerformInvoice'
import { PurchaseActions } from './PurchaseActions'
import ViewGoodReceiptItems from '../viewItemsList/ViewGoodReceiptItems'
import { ViewOrderItemColumns } from './columns/ViewOrderItemColumns'

const PurchaseOrderMain = () => {
  LocalBaseURL()
  const { permissionWrapperHideColsUtil } = useAdminManagementPermission()
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])

  const [totalRows, setTotalRows] = useState<any>(0)
  const [perPageSize, setPerPageSize] = useState(10)
  const [direction, setdirections] = useState<any>('')
  const [payload, setPayload] = useState<any>(initialPayload())
  const [filter, setFilter] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [toggledClearRows, setToggleClearRows] = React.useState(false)
  const [search, setSearch] = useState<any>('')
  const [filterkey, setFilterKey] = useState<any>({})
  const [sortby, setSortBy] = useState<any>('')
  const [page, setPages] = useState<any>(1)
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>('')
  const [dynFilter, setDynFilter] = useState<any>('')
  const [sortedColumns, setSortedColumns] = useState<any>(getColumns())
  const [popUpPostFormOpen, setPopUpPostFormOpen] = useState(false);
  let initialAddEditPayload = {
    vendor_id: null,
    // status: null,
    warehouse_id: null,
    item_list: [],
    expected_delivery_date: null,
  }
  const [initialValue, setInitialValue] = useState<any>(initialAddEditPayload);
  const [editId, setEditId] = useState(0);

  async function InvokedRendered(payloads: any, setloading: any) {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    setPending(true)
    const { data } = await postRequest(
      URL_EndPoints(null)?.getAllPurchaseOrdersPagination,
      payloads,
      setloading
    )

    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.purchaseOrderPagination)
    setPending(false)
  }

  const handleEdit = async (row: any) => {
    const { data } = await getRequest(URL_EndPoints(adminParamsGenerator("getPurchaseOrdersToEdit", row))?.getPurchaseOrdersToEdit, null)
    const newData = data?.data?.[0]
    setPopUpPostFormOpen(true);
    setEditId(newData.id);
    setInitialValue({
      vendor_id: newData.vendor_id || null,
      status: newData.status || '',
      warehouse_id: newData.warehouse_id || null,
      item_list: newData.purchaseOrderItemsId
        .map((item: any) => ({
          id: item.item_id,
          title: item.purchaseOrderInventoryItems.title,
          quantity: item.quantity_ordered,
          total_price: Number(item.total_price),
          price: Number(item.unit_price),
          unit: item.purchaseOrderInventoryItems.unit
        })),
      expected_delivery_date: newData.expected_delivery_date || null,
    });

  };

  const [orderItemLists, setOrderItemLists] = useState<any>([])
  const [goodRecieptId, setGoodReceiptId] = useState<any>([])
  const [paymentId, setPaymenId] = useState<any>([])
  const [vendorId, setVendorId] = useState<any>([])
  const [proformaId, setProformaId] = useState<any>([])

  const [showOrderItems, setShowOrderItems] = useState<boolean>(false)
  const [showGoodReceipt, setShowGoodReceipt] = useState<boolean>(false)
  const [showPaymentReceipt, setShowPaymentReceipt] = useState<boolean>(false)
  const [showVendorInvoice, setShowVendorInvoice] = useState<boolean>(false)
  const [showProforma, setShowProforma] = useState<boolean>(false)
  const [modalLoader, setModalLoader] = useState(false);


  const viewOrderItems = async (purchase_order_id: any) => {
    setShowOrderItems(true);
    getRowSpecificListData(setOrderItemLists, setModalLoader, purchase_order_id, "getAllPurchaseOrdersItems")
  };

  const viewGoodReceipt = async (purchase_order_id: any) => {
    setShowGoodReceipt(true);
    setGoodReceiptId(purchase_order_id)
  };

  const viewPaymentReceipt = async (purchase_order_id: any) => {
    setShowPaymentReceipt(true);
    setPaymenId(purchase_order_id)

  };

  const viewVendorInvoice = async (purchase_order_id: any) => {
    setShowVendorInvoice(true);
    setVendorId(purchase_order_id)

  };

  const viewProformaInvoice = async (purchase_order_id: any) => {
    setShowProforma(true);
    setProformaId(purchase_order_id)

  };

  const getRowSpecificListData = async (setListData: any, setModalLoader: any, rowId: any, urlEndPoint: any,) => {
    try {
      setModalLoader(true);
      const { data } = await getRequest(URL_EndPoints(adminParamsGenerator(urlEndPoint, rowId))?.[urlEndPoint], setPending)
      setListData(data.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setModalLoader(false);
    }
  }



  useEffect(() => {
    if (filterData) {
      const newCol = getColumns(viewOrderItems, viewGoodReceipt, viewPaymentReceipt, viewVendorInvoice, viewProformaInvoice);
      setSortedColumns(newCol);
    }
  }, [filterData]);


  const onChangeHandler = (
    event: any,
    key: any,
    value: any,
    defVal: any = false,
    timestamp: any,
    multiVal: any
  ) => {
    setPayload({
      ...payload,
      filterData: {
        ...payload.filterData,
        [key]: {
          ...payload.filterData[key],
          [value]: event?.value,
          [key + '_' + value]: defVal ? event : '',
          isDate: defVal ? false : true,
          timestamp: timestamp === 'null' ? undefined : timestamp,
          multiVal: multiVal,
        },
      },
    })
  }

  const handlePagination = (
    search: any = '',
    sortBy = '',
    sortDirection: any = 'desc',
    page: any = 1,
    perPageSize: any = 10
  ) => {

    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const actionHandler = async () => {
    await InvokedRendered(
      handlePagination(search, sortby, direction, page, perPageSize),
      setPending
    )
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(
      key,
      currentItemRemovedOrAdded,
      setFilterKey,
      setSelectedFilterData,
      selectedFilterData,
      setStoreFilterList,
      storeFilterList,
      payload,
      setPayload
    )
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)
    InvokedRendered(
      handlePagination(search, column.sortField, sortDirection, page, perPageSize),
      setPending
    )
  }

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  }

  useEffect(() => {
    let timer = setTimeout(async function () {
      search && setPending(true)
      search &&
        InvokedRendered(
          handlePagination(search && search, sortby, direction, page, perPageSize),
          setPending
        )
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [search])

  useEffect(() => {
    !search &&
      InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [search])

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    selectedFilterData?.length === 0 &&
      InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [selectedFilterData?.length])

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  }

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }
  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords &&
        (await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
          keyword: storeSearchKeywords,
        }))
      setIsLoadingFilter(false)
    }, 2000)
    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList('getAllPurchaseOrdersPagination')
  }, [])

  const refreshNow = () => {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
  }

  const handleAddPurchaseOrder = () => {
    setPopUpPostFormOpen(true);
    setInitialValue(initialAddEditPayload);
    setEditId(0);

  };

  const handleClose = () => {
    setInitialValue(initialAddEditPayload);
    setEditId(0);
    setPopUpPostFormOpen(false);
  }

  return (
    <>
      <KTCardBody className='card py-4' key='getAllPurchaseOrdersPagination'>

        <AdminServerSidePaginationLists
          title='Purchase Order List'
          reorderColumnTitle='getAllPurchaseOrdersPagination'
          sortedColumns={[
            {
              name: 'Action',
              cell: (row: any) => <PurchaseActions
                row={row}
                handleEdit={handleEdit}
                viewOrderItems={viewOrderItems}
                viewGoodReceipt={viewGoodReceipt}
                viewPaymentReceipt={viewPaymentReceipt}
                viewVendorInvoice={viewVendorInvoice}
                viewProformaInvoice={viewProformaInvoice}
              />,
              reorder: true,
              id: 1,
            }, ...sortedColumns]}
          filterData={filterData}
          pending={pending}
          clearSelectedRows={toggledClearRows}
          totalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          conditionalRowStyles={{}}
          handleSort={handleSort}
          keyName={'getAllPurchaseOrdersPagination'}
          search={search}
          setSearch={setSearch}
          paginationPerPage={perPageSize}
          showFilter={true}
          ExpandedComponent={() => <></>}
          expandableRows={false}
          filterHandler={{
            onChangeHandler: onChangeHandler,
            actionHandler: actionHandler,
            getCurrentFilter: getCurrentFilter,
            removeFilter: removeFilter,
            payload: payload,
            Component: DynamicFilter,
            filterData: filter,
            OperatorsData: operators,
            selectedFilterData,
            filterComponents: KeywiseComponents,
            storeFilterList: storeFilterList,
            filterkey: filterkey,
            setSeacrch: setSearchFilter,
            isLoadingFilter: isLoadingFilter,
            listApiUrl: listApiUrl('getAllPurchaseOrdersPagination'),
          }}

          SubHeaderComponentRightSection={() => <>
            <Refresh refreshNow={refreshNow} />
            {
              <button
                className="btn btn-sm btn-primary"
                onClick={handleAddPurchaseOrder}
              >
                <i className="fa fa-plus-circle mr-2"></i>Add Purchase Order
              </button>
            }
          </>
          }

          SubHeaderComponentActionButtons={() => <></>}
        />
      </KTCardBody>

      {/* {
        showOrderItems ? <ViewProductModal
          title="Order Item Lists"
          show={showOrderItems}
          listData={orderItemLists}
          setShow={setShowOrderItems}
          modalLoader={modalLoader}
          columns={ViewOrderItemColumns}
        /> : <></>
      } */}

      {
        showOrderItems ? <ViewGoodReceiptItems
          title="Purchase Order Items"
          show={showOrderItems}
          listData={orderItemLists}
          setShow={setShowOrderItems}
          modalLoader={modalLoader}
          columns={ViewOrderItemColumns()}
        /> : <></>
      }

      {

        <ViewInventoryModal
          title="Good Receipt Lists"
          show={showGoodReceipt}
          setShow={setShowGoodReceipt}
          filterList={{
            goodFilter: {
              purchase_order_id: {
                operator: "==",
                value: goodRecieptId,
              },
            },
            isFilter: true,
            showFilter: true,
          }}
          Component={GoodReceipt}
        />
      }

      {

        <ViewInventoryModal
          title="Payment Receipt Lists"
          show={showPaymentReceipt}
          setShow={setShowPaymentReceipt}
          filterList={{
            paymentFilter: {
              purchase_order_id: {
                operator: "==",
                value: paymentId,
              },
            },
            isFilter: true,
            showFilter: true,
          }}
          Component={PaymentReceipts}

        />
      }

      {

        <ViewInventoryModal
          title="Vendor Invoice Lists"
          show={showVendorInvoice}
          setShow={setShowVendorInvoice}
          filterList={{
            vendorFilter: {
              purchase_order_id: {
                operator: "==",
                value: vendorId,
              },
            },
            isFilter: true,
            showFilter: true,
          }}
          Component={VendorInvoice}

        />
      }

      {

        <ViewInventoryModal
          title="Proforma Invoice Lists"
          show={showProforma}
          setShow={setShowProforma}
          filterList={{
            proformaFilter: {
              purchase_order_id: {
                operator: "==",
                value: proformaId,
              },
            },
            isFilter: true,
            showFilter: true,
          }}
          Component={PerformInvoice}

        />
      }

      {
        popUpPostFormOpen ? <AddPurchaseOrdersForm
          popUpPostFormOpen={popUpPostFormOpen}
          refreshNow={refreshNow}
          handleClose={handleClose}
          setData={() => { }}
          initialValue={initialValue}
          setInitialValue={setInitialValue}
          editId={editId}
          setEditId={setEditId}
        /> : <></>
      }

    </>
  )
}

export default PurchaseOrderMain
