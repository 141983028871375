


import React from 'react'
import { Modal } from 'react-bootstrap'
import { CallDetailsBody } from './CallDetailsBody'
const ChampCallDetailsLogs = ({ FilteredData }: any) => {
    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleClose} dialogClassName="modal-large"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Champ Name : <span className='text-primary'>{FilteredData?.champ_name}</span>    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1 overflow-auto`} style={{ height: "400px", margin: 10, width: "99%", padding: "5px" }}>
                        <CallDetailsBody champ_call_log_id={FilteredData?.champ_call_log_id}></CallDetailsBody>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default ChampCallDetailsLogs




// <ChampCallDetailsLogs FilteredData={{
//     champ_call_log_id: null,
//     show: show,
//     handleClose,
//     champ_name
// }} >
// </ChampCallDetailsLogs>