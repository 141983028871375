import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { columns } from './Components/Columns'
import { useSelector } from 'react-redux'
import TopHeader from './Components/TopHeader'
import { BankAccountInterface } from '../interfaces'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import DialogBoxGlobal from '../../../consts/Dialogbox/DialogBoxGlobal'
import AddNewAccountForm from './Components/AddNewAccountForm'
import { ReactTableComponentStylesSheet } from '../../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet'
const AddAccountTable = () => {
  const cleanerDetails = useSelector((store: any) => store?.ActiveStatsReducer?.addBank)
  const refreshAfterAssigning = useSelector((store: any) => store.RefreshPage.refreshbankAccount)
  const [Search, setSearch] = useState<any>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [MainData, SetMainData] = useState([])
  const [isAccountModelOpen, setisAccountModelOpen] = useState<boolean>(false)
  const [filterData, setfilterData] = useState([])
  React.useEffect(() => {
    const updatedData = MainData.filter((data: any) => {
      return data?.name?.toLowerCase().match(Search?.toLowerCase())
    })
    setfilterData(updatedData)
  }, [Search])
  async function invokedApi() {
    setLoading(true)
    const result = await postRequest(URL_EndPoints()?.getcleanerbankdetail, { cleanerid: cleanerDetails?.id }, setLoading)

    SetMainData(result?.data?.result)
    setfilterData(result?.data?.result)
    return result?.data?.result as BankAccountInterface[];
  }
  React.useEffect(() => {
    invokedApi()
  }, [cleanerDetails?.id, refreshAfterAssigning])
  const handleCleateAccount = () => {
    setisAccountModelOpen(true)
  }
  const handlcloseModel = () => {
    setisAccountModelOpen(false)
  }
  return (
    <div style={{ width: "850px", margin: "auto" }}>
      {
        (
          <DataTable
            customStyles={ReactTableComponentStylesSheet}
            columns={columns}
            data={filterData}
            pagination
            fixedHeader
            progressPending={isLoading}
            fixedHeaderScrollHeight='auto'
            selectableRowsHighlight
            highlightOnHover
            subHeader
            subHeaderComponent={
              <>
                <TopHeader setSearch={setSearch} Search={Search} handleCleateAccount={handleCleateAccount} ></TopHeader>
              </>
            }
          />
        )}
      {isAccountModelOpen && <DialogBoxGlobal handleCloseForm={handlcloseModel} PopUpPostFormOpen={isAccountModelOpen} ParentData={cleanerDetails} selectedId={null} Components={AddNewAccountForm} />}
    </div>
  )
}
export default AddAccountTable
