import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../../../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet'
const AdminSingleSelectFilter = (props: any) => {
  const {
    onChangeHandler,
    HeaderTitle,
    SelectData,
    displayFields,
    id,
    name,
    defaultData,
    searchable,
    setSeacrch,
    isLoading = false,
    filters
  } = props

  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      const newData = {
        label: displayFields?.map((key: any) => !ele[key] ? "NA" : ele[key]).join(" "),
        value: ele[id],
        item: ele
      }
      return newData
    })
    setShowData(updatedData)
  }, [SelectData])

  const debouncing = (event: any) => {
    setSeacrch(event, filters)
  }

  return (
    <Select
      placeholder={HeaderTitle}
      isClearable
      isSearchable
      onInputChange={setSeacrch && searchable ? (e: any) => debouncing(e) : undefined}
      options={showData}
      onChange={(event) => onChangeHandler({ value: event ? event?.value : null, item: event }, name, false)}
      defaultValue={defaultData && defaultData?.item}
      styles={colourStyles2}
      isLoading={isLoading}


      
    />
  )
}


export default AdminSingleSelectFilter

