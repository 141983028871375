import axios from "axios";
import React, { FC, useState } from "react";
import clsx from "clsx";
import { isTestOrAdminApi } from "../../../consts/Server/Requests";

type Props = {
    selectedId: any
    ParentData: any
}

const UpdateStaus: FC<Props> = ({ ParentData, selectedId }) => {
    const [responseData, setResponseData] = React.useState(Object);
    const [isLoading, setLoading] = React.useState(false);

    React.useEffect(() => {
        axios.post(`${isTestOrAdminApi}/api/admin/getMasterReasonsForSubscription`).then((response) => {
            setResponseData(response.data);
            setLoading(false);
        }).catch(error => {
            console.error("ERROR", error);
            setLoading(false);
        });
    }, []);

    const handleSubmit: any = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    if (isLoading)
        return (
            <h1>Loading...</h1>
        )

    return (
        <form id='kt_modal_add_user_form' className='form mt-4 p-3' onSubmit={handleSubmit} noValidate>
            {/* begin::Scroll */}
            <div
                className='d-flex flex-column scroll-y me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
            >
                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Select Reason</label>
                    <select
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        id="reason_id"
                        // onChange={inputsHandler}
                        className={clsx(
                            'form-select form-select-solid bg-white form-control form-control-solid mb-3 mb-lg-0',
                        )}
                    >
                        <option>Select Reason</option>
                        {responseData.data?.map((item: any) => {
                            return <option value={item.id}
                                key={item.id}>{item.reason}</option>
                        })}
                    </select>
                    {(
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'></span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Start Date</label>
                    <input
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        id="startdate"
                        type="date"
                        // onChange={inputsHandler}
                        className={clsx(
                            'form-select form-select-solid bg-white form-control form-control-solid mb-3 mb-lg-0',

                        )}
                    />
                    {(
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>Error Show Here</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>End Date</label>
                    <input
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        id="enddate"
                        type="date"
                        // onChange={inputsHandler}
                        className={clsx(
                            'form-select form-select-solid bg-white form-control form-control-solid mb-3 mb-lg-0',

                        )}
                    >
                    </input>
                    {(
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>Error Show Here</span>
                            </div>
                        </div>
                    )}
                </div>


                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Stop Type</label>
                    <select
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        id="isactivestatus"
                        // onChange={inputsHandler}
                        className={clsx(
                            'form-select form-select-solid bg-white form-control form-control-solid mb-3 mb-lg-0',

                        )}
                    >
                        <option value="">Stop Type</option>
                        {[]?.map((item: any) => {
                            return <option value={item.id}
                                key={item.id}>{item.name}</option>
                        })}
                    </select>
                    {(
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>Error Show Here</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Initiate Refund</label>
                    <input
                        data-placeholder='Initiate Refund'
                        id="refund"
                        type="checkbox"
                    // onChange={inputsHandler}

                    />
                </div>

                {<div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Enter Refund Amount</label>
                    <input
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        id="refund_amount"
                        type="text"
                        // onChange={inputsHandler}
                        className={clsx(
                            'form-select form-select-solid bg-white form-control form-control-solid mb-3 mb-lg-0',

                        )}
                    />
                </div>}

            </div>
            <div className='text-center pt-15'>
                {/* <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                >
                    Discard
                </button> */}

                <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                >
                    <span className='indicator-label'>Submit</span>
                    {(1 === 1 &&
                        <span className='indicator-progress'>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Actions */}
        </form>
    )
}

export default UpdateStaus;