import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Bars } from 'react-loader-spinner'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { postRequest } from '../../Server/Requests'
import { toast } from 'react-toastify'
const ChampEmailUpdate = ({ champDetails,openModal, setRowData=null, handleCloseForm }: any) => {

    const [payload, setPayload] = useState<any>({
        champId: champDetails?.id,
        email:""
    })
    useEffect(() => {
        champDetails && setPayload({ ...payload, ["email"]: champDetails.email })
    }, [champDetails.email])

    const [loader, setLoader] = useState(false)


    const updateEmailHandler = async () => {
        setLoader(true)

        const result = await postRequest(URL_EndPoints()?.updateChampData, payload, setLoader)

        if (result?.data?.status == 200) {
            toast.success(result?.data?.message, { position: "top-center" })
            champDetails.email = payload?.email
            setRowData(champDetails)
            handleCloseForm()

        } else {
            setLoader(false)
            toast.error(result?.data?.message, { position: "top-center" })
            handleCloseForm()
        }


    }



    return (
        <>
            {openModal ? <Modal show={openModal} onHide={handleCloseForm} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Email </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row  ms-3 mb-3 mt-2 col-12'>
                        <input
                            type='text'
                            className='form-control  me-2  border'
                            placeholder='Enter email Here...'
                            style={{ height: "50px", width: "100%" }}
                            onChange={(e) => setPayload({...payload, ["email"] : e.target.value})}
                            value={payload.email}
                        />
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button dir="rtl" className="btn btn-success mt-4 text-end" onClick={updateEmailHandler} disabled={ loader ? true : false}>
                            {loader ?
                                <Bars
                                    height="20"
                                    width="20"
                                    color="#00000"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                : 'Update Email'}
                        </button>
                    </div>
                   
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default ChampEmailUpdate
