import { useEffect, useState } from "react";
import JsonEditor from "./JsonEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import axios from "axios";
import { AdminBaseUrl, adminParamsGenerator } from "../admins/adminUtils";
import IsLoading from "../../consts/IsLoading/IsLoading";
import Refresh from "../../consts/Refresh";
import SearchBoxModal from "../../consts/reuseModules/SearchBoxModal";
import { AllFielSearch } from "../../consts/FieldSearch/AllFieldSearch";
import { getRequest } from "../../consts/Server/Requests";
import { URL_EndPoints } from "../../consts/Server/URL_EndPoints";

const DeveloperTools = () => {
  const [filterData, setFilterData] = useState([])
  const [mainData, setMainData] = useState([])
  const [show, setShow] = useState(false)
  const [pending, setPending] = useState(false)
  const [addJsonData, setAddJsonData] = useState(false)
  const [selectedJsonData, setSelectedJsonData] = useState<any>(null)
  const [path, setPath] = useState<any>("");
  const [search, setSearch] = useState<any>("");
  const setClose = () => {
    setSelectedJsonData(null)
    setPath("")
    setShow(false)
    setAddJsonData(false)
  }

  const getJsonDataList = async () => {
    setPending(true)
    const result = await getRequest(URL_EndPoints()?.getAllPathList, setPending)
    setFilterData(result?.data?.data)
    setMainData(result?.data?.data)

  }

  useEffect(() => {
    getJsonDataList()
  }, [])

  const onSuggestionClick = (path: any) => {
    setSelectedJsonData(path)
    setShow(true)
  }

  const [singleJsonData, setSingleJsonData] = useState<any>({})
  const [pendingSingle, setPendingSingle] = useState(false)

  const getSingleJsonData = async (dynaUrl: any) => {
    setPendingSingle(true)
    const singleJson = await getRequest(URL_EndPoints(adminParamsGenerator("getAllPathList", dynaUrl))?.getAllPathList, setPendingSingle)
    setPath(singleJson?.data?.data?.path)
    setSingleJsonData(singleJson?.data?.data)

  }

  useEffect(() => {
    show && getSingleJsonData(selectedJsonData?.path)
  }, [selectedJsonData, show])

  const refreshNow = () => {
    getJsonDataList()
  }


  useEffect(() => {
    const updatedData = AllFielSearch(mainData, search)
    setFilterData(updatedData)
  }, [search])

  return (
    <>
      <div className='d-flex flex-column g-2 text-dark cursor-pointer  col-12'>
        <div className="text-decoration-underline mb-4">DEVELOPER TOOLS</div>
        <div className="mb-8 fs-4 fw-semibold  d-flex justify-content-between">
          <div>
            <SearchBoxModal search={search} setSearch={setSearch} />
          </div>
          <div>
            <Refresh refreshNow={refreshNow} />
            <button className="btn btn-sm btn-primary" onClick={() => setAddJsonData(true)} >Add JSON</button>
          </div>
        </div>

        {
          pending ? <IsLoading /> : filterData?.map((ele: any) => {
            return <div className='mb-3 text-dark d-flex justify-content-start align-items-center text-hover-success  col-12 px-2 hovercss ' onClick={() => onSuggestionClick(ele)} title="Click To Edit">
              <FontAwesomeIcon className='fs-7 me-2 text-success' icon={faCircle} />
              &nbsp;
              {ele?.path}
            </div>
          })
        }

      </div>

      {
        show ? <Modal
          size='xl'
          show={show}
          onHide={setClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Json Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JsonEditor btnTitle="Update Json" singleJsonData={singleJsonData?.payload} setClose={setClose} setPath={setPath} path={path} pendingSingle={pendingSingle} url="update" refreshNow={refreshNow} />
          </Modal.Body>
        </Modal> : <></>
      }

      {
        addJsonData ? <Modal
          size='xl'
          show={addJsonData}
          onHide={setClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Json Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JsonEditor btnTitle="Add Json" singleJsonData={{}} setClose={setClose} setPath={setPath} path={path} pendingSingle={false} url="create" refreshNow={refreshNow} />
          </Modal.Body>
        </Modal> : <></>
      }

    </>
  );
};

export default DeveloperTools;
