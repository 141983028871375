import React, { memo } from 'react';
import { withSwal as ClickupTaskComment } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { useDispatch } from 'react-redux';
export default ClickupTaskComment((props: any) => {

    const dispatch = useDispatch()
    const { swal, confirm, cancel, payloads, setOpenModalClickUpTask, setClickUpCommentLoader }: any = props;
    const { payloadData, render, successTitle, FailedTitle } = payloads

    React.useEffect(() => {
        async function load() {
            setOpenModalClickUpTask(true)
            const response = await postRequest(URL_EndPoints(null)?.getClickUpTaskComments, payloadData, setOpenModalClickUpTask)
            if (response?.data?.status == 200) {
                swal.fire(successTitle)
                dispatch({
                    type: "CLICK_UP_TASK_COMMENT", payload: {
                        clickUp: 1,
                        clcikUp_render: Math.random()
                    }
                })
            }
            setClickUpCommentLoader(null)
        }
        if (render) {
            swal.fire({
                title: 'Do you want get click up task comment ?',
                showDenyButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});




{/* <DeleteVehicle
confirm='Yes'
cancel='No'
payloads={{
    render: VehicleDeleteShowModel,
    payloadData: {
        customerid: subscriptions?.customer_id,
        vehicleid: VehicleDeleteNumberid,
        userid: userid,

    },

    successTitle: 'Changes Are Saved Successfully ',
    FailedTitle: 'Changes Cancelled ',
}}
localKey={true}
></DeleteVehicle> */}