export const newDailyJobDashboardStates = {
  // Static Data Variable
  DateFilter: ['createdAt', 'updatedAt'],
  // fundamental comman variables
  pending: false,
  loader: false,
  filterData: [],
  // filter variable
  ticketfrom: null,
  ticketto: null,
  categoryid: null,
  status: null,
  assignedadminuserid: null,
  assignedsupervisorid: null,
  agent_id: null,
  

  date: null,
  supervisorid: null,
  type: null,
  customerVintage: null,

  // filter variable

  filterAreaManagerId: null,
  filterAdminId: null,
  filterCategoryId: null,
  filterIsVisibleId: null,
  filterTagId: null,
  filterStatusId: null,
  filterTicketfrom: null,
  filterTicketto: null,
  filterAgentId: null,

  // FILTER LIST DATA

  adminListData: [],
  supervisorListData: [],
  statusListData: [],
  categoryListData: [],
  jobTypeListData: [],
  ticketListOpenFilterData: {},
  newDailyDashboardOpenFilterData: {},
  helpTicketData: [],
  renewalTicketData: [],
  welcomeCallTicketData: [],
  agentProductivityData: [],
  prospectTicketsData: [],

  // CHAMP DATA
  champCardData: null,
}

export const newDailyJobDashboardStatesReducer = (
  newDailyJobDashboardStates: any,
  {type, payload}: any
) => {
  switch (type) {
    case 'RTL_PENDING':
      return {...newDailyJobDashboardStates, pending: payload}
    case 'RTL_LOADER':
      return {...newDailyJobDashboardStates, loader: payload}
    case 'RTL_FILTERED_DATA':
      return {...newDailyJobDashboardStates, filterData: payload}
    case 'TDL_TICKET_FROM':
      return {...newDailyJobDashboardStates, ticketfrom: payload}
    case 'TDL_TICKET_TO':
      return {...newDailyJobDashboardStates, ticketto: payload}
    case 'TDL_CATEGORY':
      return {...newDailyJobDashboardStates, categoryid: payload}
    case 'TDL_STATUS':
      return {...newDailyJobDashboardStates, status: payload}
    case 'TDL_ASSIGNED_ADMIN_USER_ID':
      return {...newDailyJobDashboardStates, assignedadminuserid: payload}
    case 'TDL_ASSIGNED_SUPERVISOR_ID':
      return {...newDailyJobDashboardStates, assignedsupervisorid: payload}
    case 'TDL_FILTER_CUSTOMER_VINTAGE':
      return {...newDailyJobDashboardStates, customerVintage: payload}
    case 'TDL_FILTER_DATE':
      return {...newDailyJobDashboardStates, date: payload}
    case 'TDL_FILTER_JOB_TYPE':
      return {...newDailyJobDashboardStates, type: payload}
    case 'TDL_FILTER_SUPERVISOR':
      return {...newDailyJobDashboardStates, supervisorid: payload}
    case 'RTL_ADMIN_LIST_DATA':
      return {...newDailyJobDashboardStates, adminListData: payload}
    case 'RTL_SUPER_VISOR_LIST_DATA':
      return {...newDailyJobDashboardStates, supervisorListData: payload}
    case 'RTL_CATEGORY_LIST_DATA':
      return {...newDailyJobDashboardStates, categoryListData: payload}
    case 'RTL_JOB_TYPE_LIST_DATA':
      return {...newDailyJobDashboardStates, jobTypeListData: payload}
    case 'RTL_STATUS_LIST_DATA':
      return {...newDailyJobDashboardStates, statusListData: payload}
    case 'TDL_FILTER_AREA_MANAGER_ID':
      return {...newDailyJobDashboardStates, filterAreaManagerId: payload}
    case 'TDL_FILTER_ADMIN_ID':
      return {...newDailyJobDashboardStates, filterAdminId: payload}
    case 'TDL_FILTER_CATEGORY_ID':
      return {...newDailyJobDashboardStates, filterCategoryId: payload}
    case 'TDL_FILTER_CATEGORY_ID':
      return {...newDailyJobDashboardStates, filterCategoryId: payload}
    case 'TDL_FILTER_IS_VISIBLE':
      return {...newDailyJobDashboardStates, filterIsVisibleId: payload}
    case 'TDL_FILTER_TAGS':
      return {...newDailyJobDashboardStates, filterTagId: payload}
    case 'TDL_FILTER_STATUS_ID':
      return {...newDailyJobDashboardStates, filterStatusId: payload}
    case 'TDL_FILTER_TICKET_FROM':
      return {...newDailyJobDashboardStates, filterTicketfrom: payload}
    case 'TDL_FILTER_TICKET_TO':
      return {...newDailyJobDashboardStates, filterTicketto: payload}
    case 'TDL_FILTER_AGENT_ID':
      return {...newDailyJobDashboardStates, agent_id: payload}
    case 'TDL_AGENT_ID':
      return {...newDailyJobDashboardStates, filterAgentId: payload}
    case 'TICKET_LIST_OPEN_FILTER_DATA':
      return {...newDailyJobDashboardStates, ticketListOpenFilterData: payload}
    case 'NEW_DAILY_DASHBOARD_OPEN_FILTER_DATA':
      return {...newDailyJobDashboardStates, newDailyDashboardOpenFilterData: payload}
    case 'TDL_CHAMP_CARD_DATA':
      return {...newDailyJobDashboardStates, champCardData: payload}
    case 'TDL_WELCOM_TICKET_DATA':
      return {...newDailyJobDashboardStates, welcomeCallTicketData: payload}
    case 'TDL_RENEWAL_TICKET_DATA':
      return {...newDailyJobDashboardStates, renewalTicketData: payload}
    case 'TDL_HELP_TICKET_DATA':
      return {...newDailyJobDashboardStates, helpTicketData: payload}
    case 'TDL_AGENT_PRODUCTIVITY_DATA':
      return {...newDailyJobDashboardStates, agentProductivityData: payload}
    case 'TDL_PROSPECT_TICKETS_DATA':
      return {...newDailyJobDashboardStates, prospectTicketsData: payload}
    default:
      return newDailyJobDashboardStates
  }
}

// filterDataOutsource?.ticketfrom,
// filterDataOutsource?.ticketto,
// filterDataOutsource?.categoryid,
// filterDataOutsource?.status,
// filterDataOutsource?.assignedadminuserid
