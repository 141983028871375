import TagsShow from "../../consts/TagsShow/TagsShow";
import { getRelativeTime } from "../../utils/date.utils";
import { getName } from "../admins/adminUtils";

export const columns = () => [
    {
        id: 1,
        reorder: true,
        name: "id",
        cell: (row: any) => row?.id,
        sortable: true,
        sortField: 'id',
    },
    {
        id: 2,
        reorder: true,
        name: "Customer",
        cell: (row: any) => row?.first_name + ' ' + row?.last_name,
        sortable: true,
        sortField: 'first_name',
    },
    {
        id: 2,
        reorder: true,
        name: "POC",
        cell: (row: any) => row?.customerAssignedAgent?.first_name ?? 'Not Assigned',
        sortable: true,
        sortField: 'customerAssignedAgent.first_name',
    },
    {
        id: 2,
        reorder: true,
        name: "TL/AM",
        cell: (row: any) => getName(row?.customerAreaManager) ?? 'Not Assigned',
        sortable: true,
        sortField: 'customerAreaManager.first_name',
    },
    {
        id: 3,
        reorder: true,
        name: "Status",
        cell: (row: any) => row?.customerActiveStatus?.name ?? 'Prospect',
        sortable: true,
        sortField: 'customerActiveStatus.name',
    },
    {
        id: 4,
        reorder: true,
        name: "Total Open Tickets",
        cell: (row: any) => row?.tickets?.length,
    },
    {
        id: 5,
        reorder: true,
        name: "Customer Age",
        cell: (row: any) => getRelativeTime(row?.createdAt),
    },
    {
        id: 6,
        reorder: true,
        name: "Customer Tags",
        cell: (row: any) => <div className=' d-flex text-dark fs-8 fw-bold flex-wrap ms-2 mt-2 flex-wrap'>
            <TagsShow Tag={row?.private_tag} />
        </div>
    },
];


