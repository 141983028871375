import React, { useState } from "react"
import { Modal } from "react-bootstrap";
import { CalendarView } from "../../../../consts/Calendar/CalendarView";
const HandleCalendarPopUp = ({customerid, vehicleid}:any) => {
    const [show, setShow] = useState(false);

    function handleShow(breakpoint: any) {
        setShow(true);
    }

    return (
        <div className="old-jobs-cal">
            <div className='ml-2'>
                <div onClick={() => handleShow(true)}>
                    <i className="fa fa-calendar text-danger" aria-hidden="true"></i>
                </div>
            </div>
            <Modal show={show} fullscreen onHide={() => setShow(false)} className="old-jobs-cal">
                <Modal.Header closeButton>
                    <Modal.Title>Old JobList</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CalendarView customerId={customerid} vehicleid={vehicleid}></CalendarView>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default HandleCalendarPopUp