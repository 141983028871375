import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ShowFilterHint = ({ payload, showSubcategory }: any) => {
    return (
        <div className='mb-4 d-flex justify-content-start align-items-center gap-3'>
            {
                "list_type" in payload && < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>List Type: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.list_type ? payload?.list_type : "Select List Type"}</span>
                </div>
            }
            {
                "category" in payload && < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>List Type: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.category ? payload?.category : "Select List Type"}</span>
                </div>
            }

            {
                "leads_type" in payload && < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Leads Type: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.leads_type ? payload?.leads_type : "Not Set"}</span>
                </div>
            }

            {
                showSubcategory && showSubcategory?.category && <div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>List Type: 👉 </span>
                    <span className='fw-bold fs-7 '>{showSubcategory?.category ? showSubcategory?.category : "No Category"}</span>
                </div>
            }

            {
               "start_date" in payload &&  <div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Date: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.start_date} <span className='fw-bold text-danger ms-2 me-3'><FontAwesomeIcon icon={faArrowRight} className='text-danger' /></span> {payload?.end_date}</span>
                </div>
            }
            {
              "type" in payload &&   <div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Interval: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.type} {payload?.type == "weekly" && `( Monday-Sunday )`}</span>
                </div>
            }
            {
              "area_manager" in payload &&  payload.area_manager && <div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Area Manager: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.area_manager}</span>
                </div>
            }
            {
              "champ_id" in payload && payload.champ_id && <div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Champ: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.champ_id}</span>
                </div>
            }
            {
              "status" in payload && payload.status && <div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Status: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.status}</span>
                </div>
            }
            {
              "data_type" in payload && payload.data_type && <div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Data Type: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.data_type}</span>
                </div>
            }
            
        </div >
    )
}

export default ShowFilterHint
