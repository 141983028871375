import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { getRequest, postRequest } from '../Server/Requests';
import InputSearchBox from '../TagInputs/InputSearchBox';
import "./styles.css"

const AddCustomerLeadForm = ({ handleCloseForm, show, row, prevData }: any) => {
    const [sourceArray, setSourceArray] = useState<any>([])
    const [societyArray, setSocietyArray] = useState<any>([])
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [showErrorMessage, setshowErrorMessage] = useState<any>(false)

    const [payload, setPayload] = useState<any>({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        sourceId: null,
        societyId: null,
    })
    useEffect(() => {
        payload?.phone?.length >= 10 && setshowErrorMessage(false)
    }, [payload?.phone])
    const getData = async () => {
        const result = await getRequest(URL_EndPoints(null)?.getTicketSources, null)
        setSourceArray(result?.data?.data)

        const { data } = await getRequest(URL_EndPoints(null)?.getsociety, null)
        setSocietyArray(data.data)
    }

    useEffect(() => {
        getData()

    }, [])

    const handleChangeInputData = (event: any, name: any) => {
        if (event == null || !event) {
            setPayload({ ...payload, [name]: null })
        }
        else {
            setPayload({ ...payload, [name]: event?.value })
        }
    }

    const createNewcleanerLeadsHandler = async () => {

        if (payload?.phone) {
            setLoader(true)
            const result = await postRequest(URL_EndPoints()?.createNewCustomerLead, payload, setLoader)
            if (result?.data?.status == 200) {
                toast.success(result?.data?.msg, { position: "top-center" })
                handleCloseForm()
            } else {
                setLoader(false)
                setDisable(null)
                toast.error(result?.data?.msg, { position: "top-center" })
            }
        }
        else {
            toast.error("Phone number is mendatory", { position: "top-center" })

        }
    }

    const handleClose = () => {
        handleCloseForm()
    }

    const showErrorOvers = () => {
        setshowErrorMessage(true)
    }
    const showErrorLeave = () => {
        setshowErrorMessage(false)
    }
    return (
        <>
            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal-small"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='fs-5 text-muted'>Create Customer Leads</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="col-12  mb-3">
                            {/* <h5>First Name </h5> */}
                            <input
                                type='text'
                                name="firstName"
                                data-kt-user-table-filter='search'
                                className='form-control  col-12 me-3 '
                                style={{ height: "38px" }}
                                placeholder='First name...'
                                value={payload.firstName}
                                onChange={(event: any) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                            />
                        </div>

                        <div className="col-12  mb-3">
                            {/* <h5>Last Name </h5> */}
                            <input
                                type='text'
                                name="lastName"
                                data-kt-user-table-filter='search'
                                className='form-control  col-12 me-3 '
                                style={{ height: "38px" }}
                                placeholder='Last name...'
                                value={payload.lastName}
                                onChange={(event: any) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                            />
                        </div>

                        <div className="col-12  mb-3">
                            {/* <h5>Email </h5> */}
                            <input
                                type='text'
                                name="email"
                                data-kt-user-table-filter='search'
                                className='form-control  col-12 me-3 '
                                style={{ height: "38px" }}
                                placeholder='Email id...'
                                value={payload.email}
                                onChange={(event: any) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                            />
                        </div>

                        <div className="col-12  mb-3 parentscss">
                            <div className='phone_number_mandatory' onMouseOver={showErrorOvers} onMouseOut={showErrorLeave}>
                                *
                                <div className='phone_number_mandatory2'></div>
                            </div>
                            {/* {showErrorMessage && <span className='mt-2 ms-8 text-danger' >Phone number is mandatory</span>} */}
                            <input
                                type='text'
                                name="phone"
                                data-kt-user-table-filter='search'
                                className='form-control  col-12 me-3 '
                                style={{ height: "38px" }}
                                placeholder='Phone number...'
                                value={payload.phone}
                                onChange={(event: any) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                            />
                        </div>

                        <div className="col-12  mb-3">
                            {/* <h5> Source </h5> */}
                            <InputSearchBox
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select Source"
                                SelectData={sourceArray}
                                DynamicKey={"name"}
                                id={"id"}
                                name="sourceId"
                                defaultData={prevData?.length > 0 ? prevData : []}
                            />
                        </div>

                        <div className="col-12  mb-3">
                            {/* <h5> Source </h5> */}
                            <InputSearchBox
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select Society"
                                SelectData={societyArray}
                                DynamicKey={"name"}
                                id={"id"}
                                name="societyId"
                                defaultData={prevData?.length > 0 ? prevData : []}
                            />
                        </div>

                        <button className="col-12 border border-none  mb-3 bg-primary p-2 py-3 rounded text-center mt-8 cursor-pointer"
                            onClick={() => createNewcleanerLeadsHandler()}
                            disabled={disable || loader ? true : false}
                        >
                            {
                                loader ?
                                    <>
                                        <div>
                                            <span className='fw-bold text-white fs-5'>
                                                <i className="fa-solid fa-database text-white fs-2 me-4"></i>
                                                Creating
                                                <span className="spinner-grow spinner-grow-sm  text-white me-2 ms-2" role="status" aria-hidden="true"></span>
                                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                            </span>
                                        </div>
                                    </>
                                    : <span className='fw-bold text-white fs-5 me-3'>
                                        <i className="bi bi-save2 text-white fs-4 me-2"> </i>
                                        Create customer leads
                                    </span>
                            }

                        </button>
                    </>

                </Modal.Body>
            </Modal> : ''}
        </>

    )
}
export default AddCustomerLeadForm
