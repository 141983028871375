import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { adminToastRunner } from '../../admins/adminUtils'
import MomentDate from '../../../consts/MomentDate'
const getDueDate = (setDueDate: any, statusUpdatedData: any, id: any) => {
    const updatedData = statusUpdatedData?.find((ele: any) => {
        return ele?.id == id
    })
    updatedData && setDueDate(MomentDate(updatedData["inactive_due_date_days"]))
}
const ReasonStatusFrom = ({ FilteredData, setStoreRowP2p = null, row, defRes , refreshNow}: any) => {

    const statusUpdatedData = useSelector((store: any) => store.ReasonsListDataReducer.Master_Reason)
    const [reasonId, setReasonId] = React.useState<any>(null);
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [dueDate, setDueDate] = useState<any>(MomentDate(defRes["inactive_due_date_days"]))

    const handleChange = (event: any, name: any) => {
        event && getDueDate(setDueDate,statusUpdatedData, event?.value)
        setReasonId(event ? event.value : null)
    }

    const handleInactiveReason = async () => {
        setLoader(true)
        if (reasonId) {
            let newPayload = {
                notathome_id: FilteredData?.selectedId?.notathome_id,
                reason_id: +reasonId,
                dueDate: dueDate
            }
            const response = await postRequest(URL_EndPoints(null)?.updateNotAtHomeReason, newPayload, setLoader)

            adminToastRunner(response)
            if (response?.data?.status == 200) {
                if (setStoreRowP2p) {
                    refreshNow()
                    row.vehicleInactiveSubscription.ctNotAtHome.masterreason["id"] = +reasonId
                    setStoreRowP2p(row)
                    setDisable(200)
                }
                FilteredData?.handleCloseForm()
            }
        } else {
            toast.error("Reason id missing")
            FilteredData?.handleCloseForm()
        }
    }

    useEffect(() => {
        FilteredData?.selectedId?.reason_id && setReasonId(FilteredData?.selectedId?.reason_id)
    }, [FilteredData?.selectedId?.reason_id])

    return (
        <>
            {
                FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Reason Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={`col-12  mb-1`}>
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChange}
                                HeaderTitle={"Select  Reason"}
                                SelectData={statusUpdatedData}
                                DynamicKey={"name"}
                                id={"id"}
                                name={"name"}
                                defaultData={defRes ? { label: defRes?.name, value: defRes?.id } : null}
                            />
                        </div>
                        {
                            < div className={`col-12  mb-1`}>
                                <span className='ms-1 text-muted mb-2'>Due Date</span>
                                <input
                                    placeholder='Enter Rating..'
                                    name='rating'
                                    type='date'
                                    value={dueDate}
                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                    autoComplete='off'
                                    onChange={(e) => setDueDate(e.target.value)}
                                />
                            </div>
                        }

                        {
                            <div className='d-flex  align-items-center justify-content-end '>
                                <button className="btn btn-sm px-6 p-1 text-center btn-success mt-4 d-flex" disabled={disable || loader ? true : false} onClick={() => handleInactiveReason()}>
                                    {
                                        loader ?
                                            <>
                                                <div className='spinner-border mr-15' role='status'></div>
                                                <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                            </>
                                            : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                                    }
                                </button>
                            </div>
                        }
                    </Modal.Body>
                </Modal > : ''
            }
        </>
    )
}
export default ReasonStatusFrom
