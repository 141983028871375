import React, { Fragment, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { postRequest } from '../../../consts/Server/Requests'
import { paginationRowsPerPageOptions } from '../../../consts/components/Utility'
import SingleSelectSearchCategory from '../../../consts/Select/SingleSelectSearchCategory'
import FetchExotelCampaign from './adminFormComponents/FetchExotelCampaign'

const toastRunner = (message: string) => {
    return toast.error(message, { position: "top-center" })
}
const AdminBulkAddContactsToCampaignList = ({ show, setCloseModal, filteredSelectedRowsIds, setToggleClearRows, toggledClearRows, handleRefresh, userType, setFilteredSelectedRows, actionAfterSuccessResponse }: any) => {
    const [loader, setLoader] = useState<boolean>(false)
    const [apiLoader, setApiLoader] = useState<boolean>(false)
    const [toNull, setToNull] = useState<boolean>(false)
    const [exotelCampaignList, setExotelCampaignList] = useState<any>([])
    const [offset, setOffset] = useState<any>(0)
    const [metData, setMetaData] = useState<any>({})
    const [isNextOrPrev, setIsNextOrPrev] = useState<any>(null)
    const [limit, setLimit] = useState<any>(20)
    const [payload, setPayload] = useState<any>({
        ids: [],
        list_sid: null,
        create_list: false,
        list_name: "",
        user_type: "",
        campaign_name: "",
    })

    useEffect(() => {
        setToNull(true)
        setTimeout(() => {
            setToNull(false)
        }, 50)
    }, [payload?.create_list])

    async function getFetchInitialData(offset?: number, limit?: number) {
        setApiLoader(true)
        const results = await postRequest(URL_EndPoints()?.getExotelCampaignContactListDetailsInBulk, {
            "offset": offset,
            "limit": limit
        }, setApiLoader)
        setExotelCampaignList(results?.data?.data?.response || [])
        setMetaData(results?.data?.data?.metadata)
    }

    useEffect(() => {
        show && getFetchInitialData(offset, limit)
    }, [show, limit])

    const nextPrevHandler = (value: any) => {
        let newOffset = ((offset == 0 ? limit : offset) + (value))
        if (newOffset == 0 && offset == 0 && paginationRowsPerPageOptions?.includes(limit)) {
            return toastRunner("No Previous Record !")
        }
        if (payload?.create_list) {
            return toastRunner("Not Allowed!")
        }
        if (newOffset > metData?.total) {
            return toastRunner("Not Allowed To Select More than Total Records")
        }

        getFetchInitialData(newOffset, limit)
        setOffset(newOffset)
        setIsNextOrPrev(value < 0 ? "Prev" : "Next")
    }

    const handleSubmitAssign = async () => {
        payload['ids'] = filteredSelectedRowsIds;
        payload['user_type'] = userType
        let isCondition = !payload?.create_list && payload?.ids.length > 0 && payload?.user_type && payload?.list_sid || payload?.create_list && payload?.ids.length > 0 && payload?.user_type && !payload?.list_sid
        if (isCondition) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.addContactsAndCreateCampaignList, payload, setLoader)
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
            setCloseModal && setCloseModal(false)
            handleRefresh && handleRefresh()
            setFilteredSelectedRows && setFilteredSelectedRows([])
            setToggleClearRows && setToggleClearRows(!toggledClearRows)
            actionAfterSuccessResponse && actionAfterSuccessResponse(response?.data?.status == 200 ? true : false)
        }
        else {
            toast.error("Something  are  missing", { position: "top-center" })
        }
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
    }

    const setLimitHandler = (value: any) => {
        if (value > metData?.total) {
            return toastRunner("Not Allowed To Select More than Total Records")
        }
        setOffset(0)
        setLimit(value)
    }


    const throwErrorSpaceNotAllowed = () => {
        toast.error("🚫 Space not allowed, ✅ use underscore or alphanumeric combination", { position: "top-center" })
    }

    return (
        <>
            {show ? <Modal
                show={show} onHide={() => setCloseModal(false)}
                dialogClassName="modal-small"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='text-muted'>Add contacts to campaign list  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <Form.Group className="mb-3" >
                            <div className='col-12 mb-4 d-flex flex-column'>

                                <p className='text-muted '><span className='text-dark'>Note:</span> 🚫Space not allowed but ✅ underscore and alphanumeric are allowed for 👉 <span className='text-danger'>`Campaign Name`</span>  & 👉 <span className='text-danger'> `List Name`</span>  </p>


                                {
                                    toNull ?
                                        <Fragment key={"notSpace"}><SingleSelectSearchCategory /></Fragment>
                                        : <SingleSelectSearchCategory
                                            z handleChangeInputData={onChangeHandler}
                                            HeaderTitle="Select Campaign List"
                                            SelectData={payload?.create_list ? [] : exotelCampaignList?.map((ele: any) => (ele?.data)) || []}
                                            DynamicKey={"name"}
                                            id={"sid"}
                                            name="list_sid"
                                            disableValue={payload?.create_list}
                                            defaultValue={payload?.create_list && null}
                                        />
                                }
                                <div className='d-flex justify-content-between'>
                                    <div className='mt-1'>
                                        <select className="actual-select" style={{ borderColor: "#ccc", borderRadius: "5px", outline: "none" }}
                                            defaultValue={limit}
                                            value={limit}
                                            onChange={(event) => setLimitHandler(+event?.target?.value)}
                                            disabled={payload?.create_list}
                                        >
                                            {
                                                paginationRowsPerPageOptions?.map((page) => <option value={page}>{page}</option>)
                                            }
                                        </select>
                                    </div>
                                    <FetchExotelCampaign
                                        loadings={apiLoader}
                                        nextPrevHandler={nextPrevHandler}
                                        isNextOrPrev={isNextOrPrev}
                                        offset={offset}
                                        limit={limit}
                                        total={metData?.total}
                                        payload={payload}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    }
                    <Form.Group className="mb-5" controlId="">
                        <input
                            type='text'
                            style={{ height: '38px' }}
                            value={payload?.campaign_name}
                            name='campaign_name'
                            placeholder='Campaign Name ( optional )'
                            onChange={(event) => event?.target?.value?.includes(' ') ? throwErrorSpaceNotAllowed() : setPayload({ ...payload, [event?.target?.name]: event?.target?.value })}
                            className='w-100  height-100 form-control me-2 align-start text-muted'
                        />
                    </Form.Group>

                    <Form.Group className="mb-5" controlId="">
                        <input
                            type='text'
                            style={{ height: '38px' }}
                            value={payload?.list_name}
                            name='list_name'
                            placeholder='List Name ( optional )'
                            onChange={(event) => event?.target?.value?.includes(' ') ? throwErrorSpaceNotAllowed() : setPayload({ ...payload, [event?.target?.name]: event?.target?.value })}
                            className='w-100  height-100 form-control me-2 align-start text-muted'
                        />
                    </Form.Group>

                    <Form.Group className="mb-5" >
                        <Form.Check
                            className='text-muted fw-bold'
                            type='checkbox'
                            label='Create New Campaign List'
                            name="create_list"
                            checked={payload.create_list}
                            onChange={(event) => setPayload({ ...payload, [event?.target?.name]: event?.target?.checked, ["list_sid"]: null })}
                        />
                    </Form.Group>
                    <button disabled={loader} className={`btn btn-sm btn-${loader ? " border border-dark text-dark" : " border border-primary text-primary"} fs-6 col-12 w-100 fw-bold `} onClick={handleSubmitAssign}>
                        {loader ?
                            <>
                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                <span className='text-dark'>Your request is being processed...</span>
                            </>
                            : "Add Contacts To Campaign List"}
                    </button>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default AdminBulkAddContactsToCampaignList
