
  
  export const jobListGetData = [
    {
      url: 'getSupervisorList',
      method: 'GET',
      isQuery: null,
      isParams: null,
      isBody: null,
    },

    {
      url: 'getCleanerList',
      method: 'GET',
      isQuery: null,
      isParams: null,
      isBody: null,
    },
    {
      url: 'getAlJobTypes',
      method: 'GET',
      isQuery: null,
      isParams: null,
      isBody: null,
    },

  ]