import {
  FAILURE,
  FAQ_FAILURE,
  FAQ_LOADING,
  FAQ_SUCCESS,
  FAQ_TOTAL_RECORDS,
  GET_ALL_CATEGORY_LIST,
  GET_ALL_FAQS_LIST,
  GET_ALL_FAQS_LIST_BY_CATEGORY,
  LOADING,
  REDIRECT_BACK_TO_FAQ_FETCH_CURRENT_CATEGORY_PAYLOAD,
  SELECTED_FAQ_CATEGORY,
  SUCCESS,
} from '../actionsType/faqActionTypes'

const initialState = {
  faqListData: [],
  faqCategoryListData: [],
  selectedCategory: {},
  faqTotalRecords: 0,
  faqPrevCategoryPayload: null,
  loading: false,
  faqLoading: false,
}

export const faqReducer = (state = initialState, {type, payload}: any) => {
  switch (type) {
    case GET_ALL_FAQS_LIST:
      return {...state, faqListData: payload}
    case LOADING:
      return {...state, loading: payload}
    case SUCCESS:
      return {...state, loading: payload}
    case FAILURE:
      return {...state, loading: payload}
    case GET_ALL_FAQS_LIST_BY_CATEGORY:
      return {...state, faqListData: payload}
    case FAQ_LOADING:
      return {...state, faqLoading: payload}
    case FAQ_SUCCESS:
      return {...state, faqLoading: payload}
    case FAQ_FAILURE:
      return {...state, faqLoading: payload}
    case GET_ALL_CATEGORY_LIST:
      return {...state, faqCategoryListData: payload}
    case SELECTED_FAQ_CATEGORY:
      return {...state, selectedCategory: payload}
    case FAQ_TOTAL_RECORDS:
      return {...state, faqTotalRecords: payload}
    case REDIRECT_BACK_TO_FAQ_FETCH_CURRENT_CATEGORY_PAYLOAD:
      return {...state, faqPrevCategoryPayload: payload}
    default:
      return state
  }
}

// const faqListData = useSelector((store:any)=>store.faqReducer.faqListData)
// const loading = useSelector((store:any)=>store.faqReducer.loading)
// const faqLoading = useSelector((store:any)=>store.faqReducer.faqLoading)
// const faqLoading = useSelector((store:any)=>store.faqReducer.faqLoading)
// const faqTotalRecords = useSelector((store:any)=>store.faqReducer.faqTotalRecords)
// const faqPrevCategoryPayload = useSelector((store:any)=>store.faqReducer.faqPrevCategoryPayload)
