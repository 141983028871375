import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
const AlertStatusUpdate = ({ FilteredData, setStoreRowP2p = null, row }: any) => {
    const aletReasonData = useSelector((store: any) => store?.ReasonsListDataReducer?.Alert_reason)
    const [aletReasonList, setaletReasonList] = React.useState<any>([]);
    const [alertReasonId, setalertReasonId] = React.useState<any>(null);
    const [aletReasonDefaultData, setaletReasonDefaultData] = React.useState<any>({});
    const [alertReasonListActive, setAlertReasonListActive] = React.useState<any>([]);
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    useEffect(() => {
        setaletReasonList(aletReasonData)
    }, [aletReasonData])
    const handleChange = (event: any, name: any) => {
        if (event === null) {
            setalertReasonId(null)
        }
        else {
            setalertReasonId(event.value)
        }
    }
    const handleAlertReason = async () => {
        if (alertReasonId) {
            setLoader(true)

            let payloads = { cleanerid: FilteredData?.selectedId?.cleaner_id, date: FilteredData?.selectedId?.attendance_date, alert_reason_id: +alertReasonId }
            const response = await postRequest(URL_EndPoints(null)?.updateCleanerReasonStatus, payloads, setLoader)
            if (response?.data?.message) {
                toast.success(response?.data?.message)
                if (setStoreRowP2p) {
                    if (row?.attendance?.length > 0) {
                        row.attendance[0].alert_reason_id = +alertReasonId
                        setStoreRowP2p(row)
                        setDisable(200)

                    }

                }
                FilteredData?.handleCloseForm()

            }
            else {
                toast.success("Something is wrong")

                FilteredData?.handleCloseForm()

            }
        } else {
            toast.error("Reason id missing")
            FilteredData?.handleCloseForm()

        }
    }
    useEffect(() => {
        const updated = aletReasonList?.filter((ele: any) => ele?.id === FilteredData?.selectedId?.alert_reason_id)
        const alertReasonListUpdate = aletReasonList?.filter((ele: any) => ele?.status === 1)
        setAlertReasonListActive(alertReasonListUpdate)
        FilteredData?.selectedId?.alert_reason_id && setalertReasonId(FilteredData?.selectedId?.alert_reason_id)
        FilteredData?.selectedId?.alert_reason_id && setaletReasonDefaultData(updated?.length > 0 ? updated[0] : "")
    }, [aletReasonList, FilteredData?.selectedId?.alert_reason_id])
    return (
        <>
            {FilteredData.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select  Reason"}
                            SelectData={alertReasonListActive}
                            DynamicKey={"name"}
                            id={"id"}
                            name={"name"}
                            defaultData={{ label: aletReasonDefaultData?.name, value: aletReasonDefaultData?.id }}
                        />
                    </div>
                    <div className='d-flex  align-items-center justify-content-end '>
                        {/* <a className="btn btn-success mt-4" onClick={() => handleAlertReason()}>
                            {'Save'}
                        </a> */}



                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={disable || loader ? true : false} onClick={() => handleAlertReason()}>


                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>



                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default AlertStatusUpdate
