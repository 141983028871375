import React, { memo, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import MultiSelectSearchDetailsDefault from '../../../Select/MultiSelectSearchDetailsDefault'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { postRequest } from '../../../Server/Requests'
import { adminToastRunner } from '../../../../modules/admins/adminUtils'
const AssigningFormToUser = ({ setShow, show, AdminData, Supervisordata,TicketData, invokedTicket, TicketDatas }: any) => {
    const [loader, setLoader] = useState<any>(false)

    const [payload, setPayload] = React.useState<any>({
        ticketid: TicketData?.id,
        admins: [],
        cleaners: [],
        last_date_resolution: '',
        last_date_feedback: '',
    })

    const handleChangeInputData = (event: any, name: string) => {
        if (event == null) {
            setPayload({
                ...payload,
                [name]: name === 'admins' ? [] : name === 'cleaners' ? [] : "",
            })
        }
        else {
            setPayload({
                ...payload,
                [name]: name === 'admins' ? event : name === 'cleaners' ? event : event.value,
            })
        }

    }
    const HandleAssigning = async () => {
        let payloads = {
            ticketid: TicketData?.id,
            admins: payload?.admins,
            cleaners: payload?.cleaners,
            last_date_resolution: payload?.last_date_resolution,
            last_date_feedback: payload?.last_date_feedback,
        }

        if (
            (payload?.admins?.length == 0 && !TicketData?.id) ||
            (payload?.cleaners?.length == 0 && !TicketData?.id)
        ) {
            toast.error('may be id is missing', { position: "top-center" })
            setLoader(false)
            return
        }

        setLoader(true)
        const response = await postRequest(URL_EndPoints()?.updateAssignedUsers, payloads, setLoader)
        adminToastRunner(response)
        invokedTicket()
        setShow(false)
    }

    const AdminsTicketAssigns = TicketDatas?.ticket_assigns?.filter((ele: any) => ele.user_table === "ad_admin")
    const CleanerTicketAssigns = TicketDatas?.ticket_assigns?.filter((ele: any) => ele.user_table === "cl_cleaner")

    const updateAdminsTicketAssigns = AdminsTicketAssigns?.map((ele: any, i: number) => {
        if (ele["name"]) {
            const newData = {
                label: ele["name"],
                value: ele["userid"]
            }
            return newData
        }
    })

    const updateCleanerTicketAssigns = CleanerTicketAssigns?.map((ele: any, i: number) => {
        if (ele["name"]) {
            const newData = {
                label: ele["name"],
                value: ele["userid"],
            }
            return newData
        }
    })


    useEffect(() => {

        const AdminsTicketAssigns = TicketDatas?.ticket_assigns?.filter((ele: any) => ele.user_table === "ad_admin")
        const CleanerTicketAssigns = TicketDatas?.ticket_assigns?.filter((ele: any) => ele.user_table === "cl_cleaner")

        const defaultAdminsId = AdminsTicketAssigns?.map((ele: any, i: number) => {
            return ele?.userid
        })

        const defaultCleanersId = CleanerTicketAssigns?.map((ele: any, i: number) => {
            return ele?.userid
        })

        setPayload({ ...payload, ['admins']: defaultAdminsId, ['cleaners']: defaultCleanersId })

    }, [TicketDatas?.ticket_assigns])


    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Assign To User</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <MultiSelectSearchDetailsDefault
                            handleChangeInputData={handleChangeInputData}
                            HeaderTitle="Select admins"
                            SelectData={AdminData}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name="admins"
                            defaultData={updateAdminsTicketAssigns}
                        ></MultiSelectSearchDetailsDefault>
                    </div>
                    <div className={`col-12  mb-1`}>
                        <MultiSelectSearchDetailsDefault
                            handleChangeInputData={handleChangeInputData}
                            HeaderTitle="Select supervisor"
                            SelectData={Supervisordata}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name="cleaners"
                            defaultData={updateCleanerTicketAssigns}
                        ></MultiSelectSearchDetailsDefault>
                    </div>

                    <span className='fs-7 ms-4 mb-2'>Last Date for Resolution</span>
                    <div className="col-12  mb-4 px-2 ms-1">
                        <input type="date" onChange={(event) => handleChangeInputData(event.target, "last_date_resolution")} className='form-select form-select-solid me-2' />
                    </div>
                    <span className='fs-7 ms-4 mb-2'>Last Date for feedback</span>
                    <div className="col-12  mb-3 px-2 ms-1">
                        <input type="date" onChange={(event) => handleChangeInputData(event.target, "last_date_feedback")} className='form-select form-select-solid me-2' />
                    </div>
                    {/* <div className='d-flex  align-items-center  '>
                    </div> */}
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => HandleAssigning()}>

                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Assinging...</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Assing User</h4>
                            }
                        </button>
                    </div>


                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default memo(AssigningFormToUser)
