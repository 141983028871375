import {getRequest, postRequest} from '../../app/consts/Server/Requests'
import {URL_EndPoints} from '../../app/consts/Server/URL_EndPoints'
import {
  FAILURE,
  FAQ_FAILURE,
  FAQ_LOADING,
  FAQ_SUCCESS,
  FAQ_TOTAL_RECORDS,
  GET_ALL_CATEGORY_LIST,
  GET_ALL_FAQS_LIST,
  LOADING,
  SELECTED_FAQ_CATEGORY,
  SUCCESS,
} from '../actionsType/faqActionTypes'

export const getAllFaqsListData =
  (payload: any, faqPrevCategoryPayload?: any) => async (dispatch: any) => {
    try {
      dispatch({type: LOADING, payload: true})
      const response = await postRequest(
        URL_EndPoints()?.getAllFAQs,
        faqPrevCategoryPayload ? faqPrevCategoryPayload : payload,
        null
      )
      if (response?.data?.status == 200) {
        dispatch({type: GET_ALL_FAQS_LIST, payload: response?.data?.data})
        dispatch({type: SELECTED_FAQ_CATEGORY, payload: response?.data?.category})
        dispatch({type: FAQ_TOTAL_RECORDS, payload: response?.data?.recordsTotal})
        dispatch({type: SUCCESS, payload: false})
      }
      dispatch({type: FAILURE, payload: false})
    } catch (error) {
      dispatch({type: FAILURE, payload: false})
    }
  }

export const getAllFaqsListDataByCategory = (payload: any) => async (dispatch: any) => {
  try {
    dispatch({type: FAQ_LOADING, payload: true})
    const response = await postRequest(URL_EndPoints()?.getAllFAQs, payload, null)
    if (response?.data?.status == 200) {
      dispatch({type: GET_ALL_FAQS_LIST, payload: response?.data?.data})
      dispatch({type: SELECTED_FAQ_CATEGORY, payload: response?.data?.category})
      dispatch({type: FAQ_TOTAL_RECORDS, payload: response?.data?.recordsTotal})
      dispatch({type: FAQ_SUCCESS, payload: false})
    }
    dispatch({type: FAQ_FAILURE, payload: false})
  } catch (error) {
    dispatch({type: FAQ_FAILURE, payload: false})
  }
}

export const getAllFaqCategoryListData = (payload?: any) => async (dispatch: any) => {
  try {
    // dispatch({type: LOADING, payload: true})
    const response = await getRequest(URL_EndPoints()?.getAllFAQsCategories, null)
    if (response?.data?.status == 200) {
      dispatch({
        type: GET_ALL_CATEGORY_LIST,
        payload: response?.data?.data,
      })
      //   dispatch({type: SUCCESS, payload: false})
    }
    // dispatch({type: FAILURE, payload: false})
  } catch (error) {
    // dispatch({type: FAILURE, payload: false})
  }
}
