import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import { QueryParams } from '../../../consts/Server/QueryParams'

const ReasonUpdateOldChampForm = ({ FilteredData, setStoredRow = null, row }: any) => {
    const [loader, setLoader] = useState(false)
    const [defaultInputLoader, setDefaultInputLoader] = useState<any>(false)
    const [defalutStatus, setDefaultStatus] = useState<any>(null)
    const [reasonUpdateDataList, setReasonUpdateDataList] = useState<any>([])

    const [payload, setPayload] = useState<any>({
        "note": ""
    })

    const handleChange = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event, item: event ? event?.item : event })
    }

    const handleInactiveReason = async () => {
        setLoader(true)

        let newPayload = {
            "champ_history_id": row?.champDeactivatedHistory?.id,
            "note": payload?.note
        }

        if (payload?.note) {
            const response = await postRequest(URL_EndPoints(null)?.updateDeactivationReason, newPayload, setLoader)
            if (response?.data?.status == 200) {
                row.champDeactivatedHistory.note = payload?.note
                setStoredRow(row)
                toast.success(response?.data?.msg, { position: "top-center" })
                FilteredData?.setUpdateReasonPop(false)
            }
            else {
                toast.error(response?.data?.msg, { position: "top-center" })
            }
        } else {
            toast.error("Note is missing")
        }
    }

    useEffect(() => {
        async function loadInitialData() {
            const { data } = await getRequest(URL_EndPoints()?.getMasterNotes, null)
            setReasonUpdateDataList(data?.data)
            setDefaultStatus(row?.champDeactivatedHistory?.note)
        }
        loadInitialData()
    }, [])

    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={() => FilteredData?.setUpdateReasonPop(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        defaultInputLoader ? <IsLoading /> : <div className={`col-12  mb-1`}>
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChange}
                                HeaderTitle={"Select Reason"}
                                SelectData={reasonUpdateDataList}
                                DynamicKey={"note"}
                                id={"note"}
                                name={"note"}
                                defaultData={{ label: row?.champDeactivatedHistory?.note, value: row?.champDeactivatedHistory?.id }}
                            />
                        </div>
                    }
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => handleInactiveReason()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default ReasonUpdateOldChampForm
