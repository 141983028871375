import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { postRequest } from '../../Server/Requests';
import { toast } from 'react-toastify';
const AssignToTicket = ({ FilteredData, WhatsappMessageid }: any) => {

    const [loader, setloader] = useState(false);
    const [newTicketId, setNewTicketId] = useState<any>(null)

    const AssignToTicketHandler = async () => {
        setloader(true)
        if (newTicketId) {
            const response = await postRequest(URL_EndPoints()?.assignToTicket, {
                ticketId: newTicketId,
                whatsAppMessageId: WhatsappMessageid?.whatsappMessageId

            }, setloader)
            if (response?.data?.status == 200) {
                setloader(false)
                toast.success(response?.data?.msg, { position: "top-center" })
                FilteredData?.handleClose()

            }
            else{
                toast.success(response?.data?.msg, { position: "top-center" })
            }
        }
        else {

            toast.error("Value is missing", { position: "top-center" })
            setloader(false)
        }
    }


    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title> Assign to ticket </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <input
                        placeholder="Enter here..."
                        name="ticketid"
                        type="number"
                        style={{ height: "38px" }}
                        value={newTicketId}
                        className='me-2 col-12 form-control align-start'
                        autoComplete='off'
                        onChange={(e) => setNewTicketId(e.target.value)}
                    />

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => AssignToTicketHandler()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Assigning...</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Assign to ticket</h4>
                            }
                        </button>
                    </div>

                </Modal.Body>
            </Modal> : ''}
        </>
    )
}


export default AssignToTicket
