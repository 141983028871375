import React from 'react'
import JoditEditor from 'jodit-react'
import "../../Styles/adminFormJodEditor.css"
const AdminSelectJodEditor = ({ onChangeHandler = () => { }, HeaderTitle = "", payload = {}, name = "", label = "", }: any) => {
  return (
    <JoditEditor
      value={payload[name]}
      onChange={(newContent) => onChangeHandler({ value: newContent }, name, false)}
    />
  )
}
export default AdminSelectJodEditor

