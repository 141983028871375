import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../consts/components/Utility';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import BulkAssigAgentCustomerAgent from './BulkAssigAgentCustomerAgent';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import LeftBox2 from '../../consts/mediaQueryLayout/LeftBox2';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList';
import { useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils';

const CustomerAssignedAgents = () => {

  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])

  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  // server side
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [modelSearchMap, setModelSearchMap] = useState<any>([]);
  const [model, setModel] = useState<any>('');
  const [fieldsToSearch, setFieldsToSearch] = useState<any>([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload())

  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.getCustomerListForAgentTransfer, payload, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.customerAssignedAgentList)
    setModelSearchMap((item: any) => item?.length ? item : data?.modelSearchMap)
    setModel((model: string) => model || data?.modelSearchMap[0]?.model)
    setFieldsToSearch((fields: string[]) => fields?.length ? fields : data?.modelSearchMap[0]?.fieldsToSearch)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    payload.model = model
    payload.fieldsToSearch = fieldsToSearch

    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  };

  useEffect(() => {
    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [search, model])

  useEffect(() => {
    !search && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [search])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);
  }, [selectedFilterData?.length]);

  const handleModelChange = (event: any) => {
    const model = event.target.value;
    setModel(model);
    setFieldsToSearch(modelSearchMap?.find((item: any) => item.model === model)?.fieldsToSearch);
  }

  const [sortedColumns, setSortedColumns] = useState<any>(columns());
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const handleSelectedRowsChange = (selectedRows: any) => {

    setFilteredSelectedRows(selectedRows?.selectedRows);
    const count = [...new Set(selectedRows?.selectedRows?.map((row: any) => row?.id)) as any]?.length;
    setSelectedRowsCount(count);
  };

  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);

  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])
  const refreshNow = () => {
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }
  const [showCampaignContact, setShowCampaignContact] = useState(false);



  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let {listApiUrl  } = useAdminListApiUrl()
  
  useEffect(() => {
    adminGetSmartFilterList("getCustomerListForAgentTransfer")
  }, [])

  return (
    <>
      <div>

        <DataTable
          title="Customer POC"
          selectableRows
          progressPending={pending}
          columns={reorderColumns(sortedColumns, "customerAssignedAgentListReorderedItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "customerAssignedAgentListReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          clearSelectedRows={toggledClearRows}
          // server 
          pagination
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          // server 
          subHeaderComponent={
            <>
              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={search} setSearch={setSearch} />
                  <Form.Select onChange={handleModelChange} value={model} className='ms-2'>
                    {modelSearchMap?.map((item: any) => (<option value={item.model}>{item.label}</option>))}
                  </Form.Select>
                </LeftBox>

                <RightBox>
                  <Refresh refreshNow={refreshNow} />
                    <FilterHandler
                      onChangeHandler={onChangeHandler}
                      actionHandler={actionHandler}
                      getCurrentFilter={getCurrentFilter}
                      removeFilter={removeFilter}
                      payload={payload}
                      Component={DynamicFilter}
                      filterData={filter}
                      OperatorsData={operators}
                      selectedFilterData={selectedFilterData}
                      filterComponents={KeywiseComponents()}
                      storeFilterList={storeFilterList}
                      filterkey={filterkey}
                      setSeacrch={setSearchFilter}
                      isLoadingFilter={isLoadingFilter}
                      listApiUrl={listApiUrl("getCustomerListForAgentTransfer")}

                    />
                </RightBox>
              </MainContainerWrapper>
              {
                filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                  <BulkUtilButton title="Transfer Agent" filteredSelectedRows={filteredSelectedRows} setShow={setIsTransferModalOpen} />
                  {/* <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={filteredSelectedRows} setShow={setShowCampaignContact} /> */}
                </BulkUtilButtonWrapper> : <></>
              }

            </>
          }
        />
      </div>

      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={filteredSelectedRows?.map((row: any) => row && row?.id)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={null}
          userType={"cleaner"}
        /> : <></>
      }


      {
        isTransferModalOpen ? <BulkAssigAgentCustomerAgent
          showAssignPopUp={isTransferModalOpen}
          setShowAssignPopUp={setIsTransferModalOpen}
          filteredSelectedRows={filteredSelectedRows?.map((row: any) => row?.id) || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          selectedRowsCount={selectedRowsCount}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={refreshNow}
        /> : <></>

      }
    </>
  )
}


export default CustomerAssignedAgents
