import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatTicketID, tableDataList } from "../../../../Redux/Action/Chats/ChatAction";
import BTNbody from "./BTNbody";
import { timeCaptureOpenChat } from "../../timeCaptureOpenCloseChat";
import { setIsChatOpenedOrNot } from "../../../modules/admins/adminUtils";
const GChatBTN = ({ ticketDataParent, Title, Toggleid, classes, icons, tableData, refs, externalCss, ExternalTitle, from, trackData, keyName, isGlobalSearch, IsGlobalSearchComponent, totalRowDataForCustomerDashboard, currentRowDataFromList }: any) => {
  const dispatch = useDispatch()
  const TicketData = useSelector((store: any) => store.ChatReducers.TicketData)
  const [backgroundColors, setBackgroundColors] = useState(0)

  const HandleChipActivePaid = (from: any) => {
    localStorage.setItem("BTNbody", JSON.stringify(ticketDataParent?.BTNColor ? ticketDataParent?.BTNColor : ticketDataParent?.id))
    const BG_color = JSON.parse(localStorage.getItem("BTNbody") || "0")
    setBackgroundColors(BG_color)
    
    dispatch({
      type: "DEAFUALT_CHAT_TAB", payload: {
        DefaultTabChat: 2,
        render: Math.random()
      }
    })

    if (from == "external") {
      ticketDataParent.externalTicketSource = true
    } else {
      ticketDataParent.externalTicketSource = false
      timeCaptureOpenChat(ticketDataParent?.id ? ticketDataParent?.id : ticketDataParent?.customerid, ticketDataParent?.id ? "ticket_id" : "customer_id")
      ticketDataParent?.id && setIsChatOpenedOrNot(ticketDataParent?.id, false, "customer_chat_opened")
    }

    dispatch({ type: "TRACK_DATA", payload: { key: keyName, trackData: trackData } })
    dispatch({ type: "STORE_ROW_DATA_FOR_CUSTOMER_DASHBOARD", payload: { key: keyName, totalRowDataForCustomerDashboard: totalRowDataForCustomerDashboard } })
    dispatch(ChatTicketID(ticketDataParent))
    dispatch(tableDataList(tableData))
    currentRowDataFromList && keyName && dispatch({ type: "CURRENT_ROW_DATA_FOR_CUSTOEMR_DASHBOARD", payload: { keyName: keyName, data: currentRowDataFromList } })
  }

  useEffect(() => {
    if (refs === "renewal" && ticketDataParent?.random) {
      dispatch(ChatTicketID(ticketDataParent))
      dispatch({ type: "TRACK_DATA", payload: { key: keyName, trackData: trackData } })

    }
  }, [ticketDataParent?.id])

  return (
    <>
      <BTNbody ticketid={TicketData} ticketID={ticketDataParent} backgroundColors={backgroundColors} HandleChipActivePaid={HandleChipActivePaid} Title={Title} Toggleid={Toggleid} classes={classes} icons={icons} externalCss={externalCss} ExternalTitle={ExternalTitle} isGlobalSearch={isGlobalSearch} IsGlobalSearchComponent={IsGlobalSearchComponent}></BTNbody>
    </>
  )
}

export default memo(GChatBTN)
