import React, { FC, useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { MenuComponent } from '../../../_metronic/assets/ts/components';
import { Dialog } from '@mui/material';
import CreateNewTickets from './Components/CreateNewTickets';
import AddNewMasterOffersForm from './Components/AddNewMasterOffersForm';
export const ChatActions = ({ customerDetailData }: any) => {
	useEffect(() => {
		MenuComponent.reinitialization()
	}, [])
	const [showticketPop, setshowticketPop] = useState(false);
	const [showOffersPop, setshowOffersPop] = useState(false);
	const Create_Tickets = () => {
		setshowticketPop(true)
	}
	const handleClose = () => {
		setshowticketPop(false)
		setshowOffersPop(false)
	};
	const Create_Offers = () => {
		setshowOffersPop(true)
	}

	

	return (
		<>
			<button
				className='btn btn-sm btn-light btn-active-primary  fs-8 '
				data-kt-menu-trigger='click'
				data-kt-menu-placement='bottom-end'
				style={{ borderRadius: "100%", width: "40px", height: "40px" }}
			>
				<i className="fa fa-ellipsis-v fs-3 " aria-hidden="true"></i>
			</button>
			<div
				className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 me-12'
				data-kt-menu='true'
				style={{
					width: "500px",
					zIndex: '105',
					position: 'fixed',
					inset: '0px 0px auto auto',
					margin: '0px',
					transform: 'translate(-59px, 440px)',
				}}
			>
				<div className='menu-item px-3 fw-bold'>
					<a
						// href='_blank'
						className='menu-link  px-3 fw-bold'
						data-kt-users-table-filter='delete_row'
						onClick={Create_Tickets}
					>
						Create Ticket
					</a>
				</div>
				<div className='menu-item px-3'>
					<a
						// href='_blank'
						className='menu-link  px-3 fw-bold'
						data-kt-users-table-filter='delete_row'
						onClick={Create_Offers}
					>
						Create Offers
					</a>
				</div>
			</div>
			{/* {show ? <Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Payment Link</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex flex-row">
						<div className="p-2">{link}</div>
						<div className="p-2">
							<FontAwesomeIcon icon={faCopy} onClick={() => {
								navigator.clipboard.writeText(link)
								setCopied(true)
							}
							} />
						</div>
					</div>
					<div className="d-flex flex-row">
						<div className="p-2">
							{copied ? <span style={{ color: '#6f6f70', fontSize: '10px' }}>Copied.</span> : null}
						</div>
					</div>
				</Modal.Body>
			</Modal> : ''} */}
			{/* <ToastContainer /> */}
			{
				showticketPop &&
				<Dialog
					open={true}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<CreateNewTickets handleCloseForm={handleClose} customerid={customerDetailData?.id} />
				</Dialog>
			}
			{
				showOffersPop && <AddNewMasterOffersForm PopUpPostFormOpen={showOffersPop} handleClose={handleClose} customerDetailData={customerDetailData} />
			}
		</>
	)
}
