import React from 'react'
import Select from 'react-select'
import "./styles.css"
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
const SingleSelectSearchDetailsDefault = (props: any) => {
  const { handleChangeInputData, HeaderTitle, SelectData, DynamicKey, DynamicKey2, DynamicKey3, id, name, defaultData, setSeacrch, isLoading,targetValue } = props

  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {

    const updatedData = SelectData?.map((ele: any, i: number) => {
      let number = ele[DynamicKey3] ? '(' + ele[DynamicKey3] + ')' : "(NA)"
      if (ele[DynamicKey] !== null) {
        const newData = {
          label: ele[DynamicKey] + ' ' + ele[DynamicKey2] + number,
          value: ele[id],
          item : ele
        }
        return newData
      }
    })
    // reference2

    setShowData(updatedData)
  }, [SelectData])
  return (
    <div className='mb-2' >
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        onInputChange={(e: any) => setSeacrch && setSeacrch(e)}
        options={showData}
        onChange={(event) => handleChangeInputData(event, name,targetValue,true)}
        defaultValue={defaultData && defaultData}
        styles={colourStyles2}
        isLoading={isLoading}

      />
    </div>
  )
}
export default SingleSelectSearchDetailsDefault
