import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import SingleSelectSearchCategory from '../../../consts/Select/SingleSelectSearchCategory'
import AssignForm from './AssignForm'
import "./PopCss.css"
import Updateform from './Updateform'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
interface PayloadsAssignform {
    ticketid: number | string
    admins: any[]
    cleaners: any[]
    last_date_resolution: string
    last_date_feedback: string
    value: string | number
}
interface PayloadsUdateform {
    ticket_id: number | string
    status: number | string
    jobid: number | string
    value: string | number
}
const Customer_Information = ({ }: any) => {
    const ticketData = useSelector((store: any) => store.ChatReducers.TicketData)
    const [JobStatusData, setJobStatusData] = React.useState<any>([])
    const [AdminData, setAdminData] = React.useState<any>([])
    const [Supervisordata, setSupervisordata] = React.useState<any>([])
    const [TicketCategoryData, SetTicketCategoryData] = React.useState<any>([])
    const [loading, setloading2] = React.useState<any>([])
    const [SourcesData, SetSourcesData] = React.useState<any>([])
    const [PayloadsAssign, setPayloadsAssign] = React.useState<any>({
        ticketid: ticketData?.id,
        admins: [],
        cleaners: [],
        last_date_resolution: "",
        last_date_feedback: ""
    })
    const [PayloadsUpdate, setPayloadsUpdate] = React.useState<any>({
        ticket_id: ticketData?.id,
        status: 0,
        jobid: 0,
    })
    const [PayloadsStatus, setPayloadsStatus] = React.useState<any>({
        ticketid: ticketData?.id,
        categoryid: 0,
        source_id: 0,
    })
    async function invokedReplyes() {
        const getticketrepliesData = await postRequest(URL_EndPoints()?.getticketreplies, { "ticketid": ticketData?.id }, setloading2)

        setJobStatusData(getticketrepliesData?.data?.ticket?.customerLastSubscription?.attendanceList)
    }
    React.useEffect(() => {
        async function invoked() {
            const adminsData = await getRequest(URL_EndPoints(null)?.getAdminList, null)
            setAdminData(adminsData?.data?.data)

            // const supervisorData = await getSupervisorList(localKeyCheck)
            const supervisorData = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)

            setSupervisordata(supervisorData?.data?.data)

            const TicketCategory = await getRequest(URL_EndPoints(null)?.getTicketCategories, null)
            SetTicketCategoryData(TicketCategory?.data?.data)


            const TicketSource = await getRequest(URL_EndPoints(null)?.getTicketSources, null)
            SetSourcesData(TicketSource?.data?.data)

        }
        invoked()
        ticketData?.id && invokedReplyes()
    }, [])
    const handleChnageInputAssign = (event: PayloadsAssignform, name: string) => {
        setPayloadsAssign({ ...PayloadsAssign, [name]: name === "admins" ? event : name === "cleaners" ? event : event.value })
    }
    const handleChnageOnchangeStatus = (event: PayloadsAssignform, name: string) => {
        if (event == null) {
            setPayloadsStatus({ ...PayloadsStatus, [name]: 0 })
        }
        else {
            setPayloadsStatus({ ...PayloadsStatus, [name]: +event.value })
        }
    }
    const handleChnageInputUpdate = (event: PayloadsUdateform, name: string) => {
        if (event == null) {
            setPayloadsUpdate({ ...PayloadsUpdate, [name]: 0 })
        }
        else {
            setPayloadsUpdate({ ...PayloadsUpdate, [name]: +event.value })
        }
    }
    const HandleAssigning = async () => {
        // const responseBackAssigned = await updateAssignedUsers(localKeyCheck, PayloadsAssign)
        const responseBackAssigned = await postRequest(URL_EndPoints()?.updateAssignedUsers, PayloadsAssign, null)

        if (responseBackAssigned?.data?.status == 200) {
            toast.success('Changes Are saved successfully')
        }
        else {
            toast.error('changes are not saved')
        }
    }
    const HandleUpdate = async () => {
        // const responseBackupate = await updateTicketStatus(localKeyCheck, PayloadsUpdate)
        const responseBackupate = await postRequest(URL_EndPoints()?.updateTicketStatus, PayloadsUpdate, null)


        if (responseBackupate?.data?.status == 200) {
            toast.success('Changes Are saved successfully')
        }
        else {
            toast.error('changes are not saved')
        }
    }
    React.useEffect(() => {
        async function invoked() {
            // const response = await updateSources(localKeyCheck, PayloadsStatus, setloading2)
            const response = await postRequest(URL_EndPoints()?.updateSource, { ticketid: PayloadsStatus.ticketid, source_id: PayloadsStatus.source_id }, setloading2)

            if (response?.data?.status == 200) {
                toast.success('Changes Are saved successfully')
            }
            else {
                toast.error('changes are not saved')
            }
        }
        PayloadsStatus?.source_id && invoked()
    }, [PayloadsStatus?.source_id])
    React.useEffect(() => {
        async function invoked() {
            // const response = await updateCategory(localKeyCheck, PayloadsStatus, setloading2)
            const response = await postRequest(URL_EndPoints()?.updateCategory, PayloadsStatus, setloading2)

            if (response?.data?.status == 200) {
                toast.success('Changes Are saved successfully')
            }
            else {
                toast.error('changes are not saved')
            }
        }
        PayloadsStatus?.categoryid && invoked()
    }, [PayloadsStatus?.categoryid])
    return (
        <>
            <div className="page-content page-container " id="page-content" style={{ width: "720px" }}>
                <div className="padding" style={{ width: "100%", height: "100%" }}>
                    <div className="row container d-flex justify-content-center" style={{ width: "100%", height: "100%", marginLeft: '-60px' }}>
                        <h3> Actions </h3>
                        <div className="col-xl-6 col-md-12" style={{ width: "100%", height: "100%" }}>
                            <div className="card user-card-full" style={{ width: "100%", height: "100%" }}>
                                <div className="row m-l-0 m-r-0" style={{ width: "100%", height: "100%", }}>
                                    <div className="col-sm-12 mb-4 mt-4 bg-dark text-white">
                                        <div className="col-12  mb-3">
                                            <h5 className='text-white'>Select Category</h5>
                                            {TicketCategoryData &&
                                                <SingleSelectSearchCategory
                                                    handleChangeInputData={handleChnageOnchangeStatus}
                                                    HeaderTitle="Select Category"
                                                    SelectData={TicketCategoryData}
                                                    DynamicKey={"category_name"}
                                                    id={"id"}
                                                    name="categoryid"
                                                ></SingleSelectSearchCategory>
                                            }
                                        </div>
                                        <div className="col-12  mb-8">
                                            <h5 className='text-white'>Select Source</h5>
                                            <SingleSelectSearchCategory
                                                handleChangeInputData={handleChnageOnchangeStatus}
                                                HeaderTitle="Select Source"
                                                SelectData={SourcesData}
                                                DynamicKey={"name"}
                                                id={"id"}
                                                name="source_id"
                                            ></SingleSelectSearchCategory>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-4 mt-4 bg-warning">
                                        <AssignForm ParentData={{
                                            AdminData,
                                            Supervisordata,
                                        }} handleChnageInputAssign={handleChnageInputAssign} />
                                        <button
                                            style={{ textAlign: "right" }}
                                            type='submit'
                                            className='btn btn-white mb-2   text-align-right '
                                            data-kt-users-modal-action='submit'
                                        >
                                            <span className='indicator-label text-right' onClick={() => HandleAssigning()}>Assign</span>
                                        </button>
                                    </div>
                                    <div className="col-sm-6 mb-4 mt-4 bg-primary">
                                        <Updateform ParentData={{
                                            JobStatusData,
                                        }} handleChnageInputUpdate={handleChnageInputUpdate} />
                                        <button
                                            style={{ textAlign: "right" }}
                                            type='submit'
                                            className='btn btn-white mb-2   text-align-right'
                                            data-kt-users-modal-action='submit'
                                        >
                                            <span className='indicator-label' onClick={() => HandleUpdate()}>Update</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Customer_Information
