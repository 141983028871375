import { Dialog } from "@mui/material";
import AddRatingForm from "./../AddRatingForm";
interface Props {
    handlePopUpClose: () => void
    PopUpPostFormOpen: boolean
}
const DialogBox = ({ handlePopUpClose, popUpPostFormOpen }: any) => {
    
    return (  
        <>

            {
                popUpPostFormOpen &&
                <Dialog
                    open={true}
                    onClose={handlePopUpClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <AddRatingForm handlePopUpClose={handlePopUpClose} ></AddRatingForm>
                </Dialog>
            }
        </>
    )
}
export default DialogBox
