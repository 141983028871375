import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
const SingleSelectSearchOnclick = ({ setSearchvalue, HeaderTitle, SelectData, DynamicKey, id, name, isdisable }: any) => {
  const [showData, setShowData] = React.useState<any>("")
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      if (ele[DynamicKey] != null) {
        const newData = {
          label: ele[DynamicKey] + " - " + ele[id],
          value: ele[id],
        }
        return newData
      }
    })
    setShowData(updatedData)
  }, [SelectData])
  return (
    // <div className='' style={{width: "50%", height: "36px",}}>
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        options={showData}
        onChange={(event) => setSearchvalue(event)}
        defaultValue={showData}
        styles={colourStyles2}
        isDisabled={isdisable}
      />
    // </div>
  )
}
export default SingleSelectSearchOnclick
