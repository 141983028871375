import { bulkTicketPayloadData } from "./constants"

export const champMissedCallStates = {
    // fundamental comman variables  

    pending: false,
    loader: false,
    filterData: [],
    filterDataTemp: [],
    filteredSelectedRows: [],

    // varies based on requirement variable 
    masterReasonData: [],
    pausePaidData: [],
    renewalReasonData: [],
    adminListData: [],
    supervisorListData: [],
    vehicleReasonData: [],
    whatsAppTemplatesData: [],
    fetchCallToggler: null,
    storeFilterList: null,

    // common states dont need to make every time changes 
    totalRows: 0,
    perPageSize: 10,
    sortby: "",
    direction: "desc",
    page: 1,
    search: "",
    filterkey: {},
    filter: [],

    // bulk ticket assing  common variable  

    bulkTicketModalOpen: false,
    isBulkAssignApiLoading: false,
    amountModalOpen: false,
    isLoadingSubmitAmount: false,
    ticketPayload: bulkTicketPayloadData(["dueDate"]),


    // bulk Whatsapp Notification send common variable 
    bulkWhatsAppNotificationModalOpen: false,
    isBulkWhatsAppApiLoading: false,
    whatsAppPayload: {
        templateName: '',
    },
    showBulkAgentAssign: false,
    adminList: [],
    selectedAdminId: 0,
    isBulkAssignLoading: false,

}


export const champMissedCallStatesReducer = (champMissedCallStates: any, { type, payload }: any) => {

    switch (type) {
        case "RTL_PENDING":
            return { ...champMissedCallStates, pending: payload }
        case "RTL_LOADER":
            return { ...champMissedCallStates, loader: payload }
        case "RTL_FILTERED_DATA":
            return { ...champMissedCallStates, filterData: payload }
        case "RTL_TEMP_FILTERED_DATA":
            return { ...champMissedCallStates, filterDataTemp: payload }
        case "RTL_FILTERED_SELECTED_ROWS":
            return { ...champMissedCallStates, filteredSelectedRows: payload }
        case "RTL_MASTER_REASON":
            return { ...champMissedCallStates, masterReasonData: payload }
        case "RTL_PAUSE_PAID":
            return { ...champMissedCallStates, pausePaidData: payload }
        case "RTL_RENEWAL_REASON":
            return { ...champMissedCallStates, renewalReasonData: payload }
        case "RTL_ADD_ADMIN_LIST":
            return { ...champMissedCallStates, adminListData: payload }
        case "RTL_ADD_SUPERVISOR_LIST":
            return { ...champMissedCallStates, supervisorListData: payload }
        case "RTL_ADD_VEHICLE_REASONS":
            return { ...champMissedCallStates, vehicleReasonData: payload }
        case "RTL_WHATSAPP_TEMPLATE":
            return { ...champMissedCallStates, whatsAppTemplatesData: payload }
        case "RTL_FETCH_CALL_LOG_TOGGLER":
            return { ...champMissedCallStates, fetchCallToggler: payload }
        case "RTL_STOLRE_FILTER_LISTS":
            return { ...champMissedCallStates, storeFilterList: payload }
        case "RTL_TOTAL_ROWS":
            return { ...champMissedCallStates, totalRows: payload }
        case "RTL_PER_PAGE_SIZE":
            return { ...champMissedCallStates, perPageSize: payload }
        case "RTL_SORT_BY":
            return { ...champMissedCallStates, sortby: payload }
        case "RTL_DIRECTION":
            return { ...champMissedCallStates, direction: payload }
        case "RTL_PAGE":
            return { ...champMissedCallStates, page: payload }
        case "RTL_SEARCH":
            return { ...champMissedCallStates, search: payload }
        case "RTL_FILTER_KEY":
            return { ...champMissedCallStates, filterkey: payload }
        case "RTL_FILTER":
            return { ...champMissedCallStates, filter: payload }
        case "RTL_BULK_TICKET_ASSIGN_MODAL":
            return { ...champMissedCallStates, bulkTicketModalOpen: payload }
        case "RTL_BULK_ASSINING_API_LOADING":
            return { ...champMissedCallStates, isBulkAssignApiLoading: payload }
        case "RTL_BULK_TICKET_PAYLOAD":
            return { ...champMissedCallStates, ticketPayload: payload }
            // Whatsapp notificaion case here 
        case "RTL_BULK_WHATS_APP_MODAL_OPEN":
            return { ...champMissedCallStates, bulkWhatsAppNotificationModalOpen: payload }
        case "RTL_BULK_WHATS_APP_API_LOADING":
            return { ...champMissedCallStates, isBulkWhatsAppApiLoading: payload }
        case "RTL_BULK_WHATS_APP_PAYLOAD":
            return { ...champMissedCallStates, whatsAppPayload: payload }
        case "OPEN_AMOUNT_MODAL":
            return { ...champMissedCallStates, amountModalOpen: payload }
        case "API_LOADING_AMOUNT":
            return { ...champMissedCallStates, isLoadingSubmitAmount: payload }
        case "UPDATE_SHOW_BULK_ASSIGN_AGENT":
            return { ...champMissedCallStates, showBulkAgentAssign: payload }
        case "UPDATE_ADMIN_LIST":
            return { ...champMissedCallStates, adminList: payload }
        case "UPDATE_SELECTED_ADMIN_ID":
            return { ...champMissedCallStates, selectedAdminId: payload }
        case "UPDATE_BULK_ASSIGN_LOADING":
            return { ...champMissedCallStates, isBulkAssignLoading: payload }
        default:
            return champMissedCallStates
    }
}





