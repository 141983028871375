import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import MultiSelectSearchDetailsDefault from '../../../consts/Select/MultiSelectSearchDetailsDefault';
import IsLoading from '../../../consts/IsLoading/IsLoading';

const BulkAssignOldChamp = ({ showAssignPopUp, setShowAssignPopUp, filteredSelectedRows , toggledClearRows, setToggleClearRows}: any) => {

    const [inititalLoader, setInititalLoader] = useState(false);
    const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
    const [adminListData, setAdminListData] = useState<any>([])
    const [allSupervisroList, setAllSupervisroList] = useState<any>([])
    const [assignPayload, setAssignPayload] = useState<any>({
        admins: [],
        cleaners: [],
        should_unassign: false,
        admins_to_unassign: [],
        cleaners_to_unassign: [],
    });


    const initialData = async () => {
        setInititalLoader(true)
        const adminList = await getRequest(URL_EndPoints(null)?.getAdminList, null)
        const supervisorList = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
        setAdminListData(adminList?.data?.data);
        setAllSupervisroList(supervisorList?.data?.data);
        setInititalLoader(false)
        
    }


    useEffect(() => {
        initialData()
    }, [])

    const handleAssignSubmit = async (e: any) => {
        e.preventDefault()

        let ids: any = []
        let c_ids: any = []

        filteredSelectedRows?.forEach((row: any) => {
            if (row?.cleanerTicketsSingle && row?.cleanerTicketsSingle?.id) {
                ids.push(row?.cleanerTicketsSingle?.id)
            }
            else {
                c_ids.push(row?.id)
            }
        })

        assignPayload['ids'] = ids;
        assignPayload['c_ids'] = c_ids;
        assignPayload['deactive'] = true;

        setIsBulkAssignApiLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.assignToBulkTickets, assignPayload, null)

        if (response?.data?.status == 200) {
            toast.success(response?.data?.msg, { position: "top-center" })
            setShowAssignPopUp(false)
        } else {
            toast.error(response?.data?.msg, { position: "top-center" })
            setShowAssignPopUp(false)
        }
        setToggleClearRows(!toggledClearRows)  
        setIsBulkAssignApiLoading(false)

    }

    const handleAssignPayloadUpdate = (event: any, name: any) => {
        setAssignPayload({ ...assignPayload, [name]: event })
    }

    return (
        <>
            <Modal size='lg' show={showAssignPopUp} onHide={() => setShowAssignPopUp(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Assign {filteredSelectedRows.length} Tickets to Admins/Supervisors</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleAssignSubmit}>
                        {
                            inititalLoader ? <IsLoading /> :
                                <>
                                    {
                                        assignPayload?.should_unassign == false ? <Form.Group className="mb-3" >
                                            <MultiSelectSearchDetailsDefault
                                                handleChangeInputData={handleAssignPayloadUpdate}
                                                HeaderTitle="Select admins to assign"
                                                SelectData={adminListData}
                                                DynamicKey={"first_name"}
                                                DynamicKey2={"last_name"}
                                                DynamicKey3={"phone"}
                                                id={"id"}
                                                name="admins"
                                            ></MultiSelectSearchDetailsDefault>
                                        </Form.Group> : <></>
                                    }
                                    {
                                        assignPayload?.should_unassign == false ? <Form.Group className="mb-3" >
                                            <MultiSelectSearchDetailsDefault
                                                handleChangeInputData={handleAssignPayloadUpdate}
                                                HeaderTitle="Select supervisors to assign"
                                                SelectData={allSupervisroList}
                                                DynamicKey={"first_name"}
                                                DynamicKey2={"last_name"}
                                                DynamicKey3={"phone"}
                                                id={"id"}
                                                name="cleaners"
                                            ></MultiSelectSearchDetailsDefault>
                                        </Form.Group> : <></>
                                    }

                                    {assignPayload?.should_unassign && <Form.Group className="mb-3" >

                                        <MultiSelectSearchDetailsDefault
                                            handleChangeInputData={handleAssignPayloadUpdate}
                                            HeaderTitle="Select admins to un-assign"
                                            SelectData={adminListData}
                                            DynamicKey={"first_name"}
                                            DynamicKey2={"last_name"}
                                            DynamicKey3={"phone"}
                                            id={"id"}
                                            name="admins_to_unassign"
                                        ></MultiSelectSearchDetailsDefault>
                                    </Form.Group>}
                                    {assignPayload?.should_unassign && <Form.Group className="mb-3" >

                                        <MultiSelectSearchDetailsDefault
                                            handleChangeInputData={handleAssignPayloadUpdate}
                                            HeaderTitle="Select supervisors to un-assign"
                                            SelectData={allSupervisroList}
                                            DynamicKey={"first_name"}
                                            DynamicKey2={"last_name"}
                                            DynamicKey3={"phone"}
                                            id={"id"}
                                            name="cleaners_to_unassign"
                                        ></MultiSelectSearchDetailsDefault>
                                    </Form.Group>}


                                    <Form.Group className="mb-3 ms-2" >
                                        <Form.Check
                                            type='checkbox'
                                            label='Do you want to bulk unassign'
                                            id='bulk_unassign'
                                            className={` ${assignPayload ? "ms-2" : ""} `}
                                            checked={assignPayload.should_unassign}
                                            onChange={(e: any) => {
                                                setAssignPayload({
                                                    ...assignPayload,
                                                    should_unassign: e.target.checked,
                                                })
                                            }}
                                        />
                                    </Form.Group>


                                </>
                        }
                        <Form.Group className="mb-3 p-2" >
                            {
                                isBulkAssignApiLoading ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant={assignPayload.should_unassign == false ? "primary" : "danger"} >
                                        {assignPayload.should_unassign == false ? "Bulk Assign" : "Bulk Unassign"}
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BulkAssignOldChamp
