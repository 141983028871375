const initState = {
    inventoryOrderStatusMaster: []
}
export const InventoryDataReducer = (state = initState, { type, payload }: any) => {
    switch (type) {
        case "ADD_ORDER_STATUS_MASTER":
            return { ...state, inventoryOrderStatusMaster: payload }
        default:
            return state
    }
}