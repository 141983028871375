import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import MultiSelectSearchDetailsDefault from '../../consts/Select/MultiSelectSearchDetailsDefault'
import { toast } from 'react-toastify'
import { getPostMultipleApiHandler, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { champLeadAssign } from './champLeadsUtil'

const BulkChampLeadAssignForm = ({ showAssignPopUp, setShowAssignPopUp, filteredSelectedRows, toggledClearRows, setToggleClearRows, handleRefresh, setFilteredSelectedRows }: any) => {
    const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
    const [AdminListData, setAdminListData] = React.useState<any>([])
    const [SupervisorsListData, setSupervisorsListData] = React.useState<any>([])
    const [loader, setLoader] = React.useState<any>(false)

    const [assignPayload, setAssignPayload] = useState<any>({
        admins: [],
        cleaners: [],
        should_unassign: false,
        admins_to_unassign: [],
        cleaners_to_unassign: [],
    });

    async function getFetchInitialData() {
        setLoader(true)
        const results = await getPostMultipleApiHandler(champLeadAssign, setLoader)
        setSupervisorsListData(results["getSupervisorList"] || [])
        setAdminListData(results["getAlladminlist"] || [])
    }

    useEffect(() => {
        showAssignPopUp && getFetchInitialData()
    }, [showAssignPopUp])

    useEffect(() => {

        if (assignPayload?.should_unassign == true) {
            setAssignPayload({ ...assignPayload, ["admins"]: [], ["cleaners"]: [] })
        }
        if (assignPayload?.should_unassign == false) {
            setAssignPayload({ ...assignPayload, ["admins_to_unassign"]: [], ["cleaners_to_unassign"]: [] })
        }

    }, [assignPayload?.should_unassign])


    const handleAssignPayloadUpdate = (event: any, name: any) => {
        setAssignPayload({ ...assignPayload, [name]: event })
    }


    const handleAssignSubmit = async (e: any) => {
        e.preventDefault()
        let ids: any = []
        let c_ids: any = []
        filteredSelectedRows?.forEach((row: any) => {
            if (row?.cleanerTicketsSingle && row?.cleanerTicketsSingle?.id) {
                ids.push(row?.cleanerTicketsSingle?.id)
            }
            else {
                c_ids.push(row?.id)
            }
        })
        assignPayload['ids'] = ids;
        assignPayload['c_ids'] = c_ids;
        setIsBulkAssignApiLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.assignToBulkTickets, assignPayload, setIsBulkAssignApiLoading)
        setToggleClearRows(!toggledClearRows)
        handleRefresh()
        setFilteredSelectedRows&& setFilteredSelectedRows([])
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
        setShowAssignPopUp(false)


    }
    return (
        <>

            <Modal size='lg' show={showAssignPopUp} onHide={() => setShowAssignPopUp(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Assign {filteredSelectedRows.length} Tickets to Admins/Supervisors</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleAssignSubmit}>


                        {
                            assignPayload?.should_unassign == false ? <Form.Group className="mb-3" >
                                <MultiSelectSearchDetailsDefault
                                    handleChangeInputData={handleAssignPayloadUpdate}
                                    HeaderTitle="Select admins to assign"
                                    SelectData={AdminListData}
                                    DynamicKey={"first_name"}
                                    DynamicKey2={"last_name"}
                                    DynamicKey3={"phone"}
                                    id={"id"}
                                    name="admins"
                                    loader={loader}
                                ></MultiSelectSearchDetailsDefault>
                            </Form.Group> : <></>
                        }
                        {
                            assignPayload?.should_unassign == false ? <Form.Group className="mb-3" >
                                <MultiSelectSearchDetailsDefault
                                    handleChangeInputData={handleAssignPayloadUpdate}
                                    HeaderTitle="Select supervisors to assign"
                                    SelectData={SupervisorsListData}
                                    DynamicKey={"first_name"}
                                    DynamicKey2={"last_name"}
                                    DynamicKey3={"phone"}
                                    id={"id"}
                                    name="cleaners"
                                    loader={loader}

                                ></MultiSelectSearchDetailsDefault>
                            </Form.Group> : <></>
                        }

                        {assignPayload?.should_unassign && <Form.Group className="mb-3" >

                            <MultiSelectSearchDetailsDefault
                                handleChangeInputData={handleAssignPayloadUpdate}
                                HeaderTitle="Select admins to un-assign"
                                SelectData={AdminListData}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name="admins_to_unassign"
                                loader={loader}

                            ></MultiSelectSearchDetailsDefault>
                        </Form.Group>}
                        {assignPayload?.should_unassign && <Form.Group className="mb-3" >

                            <MultiSelectSearchDetailsDefault
                                handleChangeInputData={handleAssignPayloadUpdate}
                                HeaderTitle="Select supervisors to un-assign"
                                SelectData={SupervisorsListData}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name="cleaners_to_unassign"
                                loader={loader}

                            ></MultiSelectSearchDetailsDefault>
                        </Form.Group>}


                        <Form.Group className="mb-3" >
                            <Form.Check
                                type='checkbox'
                                label='Do you want to bulk unassign'
                                id='bulk_unassign'
                                checked={assignPayload.should_unassign}
                                onChange={(e: any) => {
                                    setAssignPayload({
                                        ...assignPayload,
                                        should_unassign: e.target.checked,
                                    })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 p-2 text-end" >
                            {
                                isBulkAssignApiLoading ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant={assignPayload.should_unassign == false ? "primary" : "danger"} >
                                        {assignPayload.should_unassign == false ? "Bulk Assign" : "Bulk Unassign"}
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default BulkChampLeadAssignForm
