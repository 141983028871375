import React, { useState } from 'react'
import HandleDropDown from './DropBox/HandleDropDown'
import DialogBox from './DialogBox/DialogBox'
import GS_Chips from '../../../consts/GS_Chips'
import DOMPurify from 'dompurify';
import './custom.css';
import HandleCalendarPopUp from './DropBox/HandleCalendarPopUp'
import Call_logs from './InputBox/Call_logs'
import TagsShow from '../../../consts/TagsShow/TagsShow'
import GChatBTN from '../../../consts/Chats/ChatButtons/GChatBTN'
import OpenChat from '../../../consts/champChatDashboard/OpenChat'
import Modal from 'react-bootstrap/Modal';
import ShowAddressMap from './ShowAddressMap/ShowAddressMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { catchCurrentOpenedUrl, customSortingCoumn } from '../../../consts/components/Utility'
import JobHistoryList from './JobHistoryList'
import JobEditHistoryList from './JobEditHistoryList'
import moment from 'moment'
import PermissionContextProvider from '../../admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../../admins/adminConstant';


export const columns = (unknownData?: any, data?: any, customerData?: any) => [
  {
    name: "ACTION",
    cell: (row: any) => (
      <div className="d-flex flex-row align-items-center">
        <HandleDropDown props={row}></HandleDropDown>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-calender"]}>
          <HandleCalendarPopUp customerid={row?.customerids} vehicleid={row?.subscriptionMonth?.vehicleid}></HandleCalendarPopUp>
        </PermissionContextProvider>


      </div>
    ),
    reorder: true,
    id: 1,
  },
  {
    name: "MAP ADDRESS",
    cell: (row: any) => (
      <>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-map-address"]}>
          <OpenMapAddress row={row} />
        </PermissionContextProvider>
      </>
    ),
    reorder: true,
    id: 2,

  },
  {
    name: "GALLARY",
    cell: (row: any) => {
      return (
        <>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-gallary"]}>
            <ViewImages individualID={row?.id} timeslotid={row?.timeslot} cleanerid={row?.cleanerid}></ViewImages>
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-call-recording"]}>
            <CallLogsDetails customerid={row?.customerids} customer_name={row?.customerData?.first_name + ' ' + row?.customerData?.last_name}></CallLogsDetails>
          </PermissionContextProvider>

        </>
      )
    },
    sortable: true,
    reorder: true,
    id: 3,
    grow: 2,
  },
  {
    name: "ID",
    selector: (row: any) => row?.id,
    sortable: true,
    reorder: true,
    id: 4,
  },
  {
    name: "VEHICLE No.",
    selector: (row: any) => row?.subscriptionMonth?.vehicle?.vehicleno,
    sortable: true,
    reorder: true,
    id: 25,
  },
  {
    name: "FOUND No.",
    selector: (row: any) => row?.attendenceImage?.found_number,
    sortable: true,
    reorder: true,
    id: 26,
  },
  {
    name: "JOB TYPE",
    selector: (row: any) => {
      const words = row?.servicetypename?.split(' ');
      const ShortWord = words?.map((word: any) => {
        let res = word[0]
        if (word) {
          return res
        }
      })?.join("")?.toUpperCase()
      return ShortWord
    },
    sortable: true,
    reorder: true,
    id: 5,
  },
  {
    name: "CLEANER NAME",
    cell: (row: any) => {
      return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <OpenChat title={row?.cleaner?.first_name + " " + row?.cleaner?.last_name} champId={row?.cleaner?.id} trackData={data} />
          <div className=''>
            <TagsShow Tag={row?.cleaner?.private_tag}></TagsShow>
          </div>
          {(row?.cash_deductions > 0 || row?.point_deductions > 0) ? <h4 style={{ color: "#ea0404" }}>FINE</h4> : ""}

        </div>
      )
    },

    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA?.cleaner, rowB?.cleaner, "first_name", true),


    sortable: true,
    reorder: true,
    id: 6,
    minWidth: "200px",
    center: true,
  },
  {
    name: "CUSTOMER NAME",
    cell: (row: any) => {
      return (
        <div className='text-left'>
          <GChatBTN ticketDataParent={{
            customerid: row?.customerids,
            random: Math.random(),
            BTNColor: row?.customerids,
            referenceTicket: null
          }}
            Title={row?.customerData?.first_name + ' ' + row?.customerData?.last_name} Toggleid={"kt_drawer_chat2_toggle2"}
            icons={""}
            tableData={row}
            refs=""
            table=""
            trackData={customerData}
            keyName={catchCurrentOpenedUrl()}
          ></GChatBTN>
          <br />
          <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1'>
            <TagsShow Tag={row?.customerData?.private_tag}></TagsShow>
          </div>
        </div>
      )
    },
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA?.customerData, rowB?.customerData, "first_name", true),

    sortable: true,
    reorder: true,
    id: 7,
    minWidth: "200px"
  },
  {
    name: "SUPERVISORS",
    cell: (row: any) => {

      return row?.cleaner?.supervisors?.length > 0 ? <div className='text-left'>
        {row?.cleaner?.supervisors?.map((ele: any, index: number) => {
          if (index == 0) {
            return <>
              <span className='badge text-dark fs-8 fw-bold'> {ele?.supervisorData?.first_name + ' ' + ele?.supervisorData?.last_name}</span>
            </>
          }
        })}
      </div> : <span className='badge text-dark fs-8 fw-bold'> {row?.cleaner?.champSupervisor?.first_name + ' ' + row?.cleaner?.champSupervisor?.last_name}</span>

      // return result
      // return (
      //   <div className='text-left'>
      //     {row?.cleaner?.supervisors?.map((ele: any, index: number) => {
      //       if (index == 0) {
      //         return <>
      //           <span className='badge text-dark fs-8 fw-bold'> {ele?.supervisorData?.first_name + ' ' + ele?.supervisorData?.last_name}</span>
      //         </>
      //       }
      //     })}
      //   </div>
      // )
    },
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA?.supervisorData, rowB?.supervisorData, "first_name", true),

    reorder: true,
    id: 8,
    minWidth: "150px"
  },
  {
    name: "DATE",
    selector: (row: { attendencedate: any }) => row?.attendencedate,
    sortable: true,
    reorder: true,
    id: 9,
    grow: 1,
    minWidth: "150px"
  },
  {
    name: "TIMESLOTS",
    selector: (row: any) => row?.timeslotDetail?.name,
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA?.timeslotDetail, rowB?.timeslotDetail, "name", true),

    sortable: true,
    reorder: true,
    id: 10,
    minWidth: "150px"
  },
  {
    name: "CUSTOMER TIMESLOTS",
    selector: (row: any) => row?.customerTimeslot?.name,
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA?.customerTimeslot, rowB?.customerTimeslot, "name", true),

    sortable: true,
    reorder: true,
    id: 10,
    minWidth: "150px"
  },
  {
    name: "CHAMP JOB STATUS",
    cell: (row: any) => {
      return (
        <div className='d-flex'>

          {
            row?.job_status_id > 0 ?
              <>
                <div className='badge badge-danger text-danger border border-danger  bg-transparent fw-bold  p-2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row?.job_status + "<br/>" + row?.attendencedatedone) }} ></div>
              </> :

              row?.job_status_select === "Overdue" ?
                <>
                  <span className='badge badge-danger fs-8 fw-bold border'>{"Overdue"}</span>
                </> :

                row?.job_status_select === "Early" ? <>
                  <span className='badge badge-warning text-warning border border-warning  bg-transparent fw-bold  p-2'>
                    {"Early"}
                    <br />
                    {row?.attendencedatedone}
                  </span>
                </> :

                  row?.job_status_select === "Ontime" ? <>
                    <span className='badge badge-success text-success border border-success  bg-transparent fw-bold p-2'>
                      {"Ontime"}
                      <br />
                      {row?.attendencedatedone}
                    </span>
                  </> :

                    row?.job_status_select === "Late" ? <>

                      <span className='badge badge-info text-info border border-info  bg-transparent fw-bold p-2'>
                        {"Late"}
                        <br />
                        {row?.attendencedatedone}
                      </span>


                    </> :

                      row?.job_status_select === "NotAtHome" ? <>
                        <div className='badge fs-8 fw-bold border text-dark'>{row?.job_status}</div>
                      </> : ""
          }

        </div>
      )
    },

    sortable: true,
    reorder: true,
    id: 11,
    grow: 6,
  },
  {
    name: "CUSTOMER JOB STATUS",
    cell: (row: any) => {
      return (
        <div className='d-flex'>
          {
            row?.customerJobStatus === "Early" ? <>
              <span className='badge badge-warning text-warning border border-warning  bg-transparent fw-bold  p-2'>
                {"Early"}
                <br />
                {row?.attendencedatedone}
              </span>
            </> :
              row?.customerJobStatus === "Ontime" ? <>
                <span className='badge badge-success text-success border border-success  bg-transparent fw-bold p-2'>
                  {"Ontime"}
                  <br />
                  {row?.attendencedatedone}
                </span>
              </> :
                row?.customerJobStatus === "Late" ? <>
                  <span className='badge badge-info text-info border border-info  bg-transparent fw-bold p-2'>
                    {"Late"}
                    <br />
                    {row?.attendencedatedone}
                  </span>
                </> : "NA"
          }

        </div>
      )
    },

    sortable: true,
    reorder: true,
    id: 21,
    grow: 6,
  },

  {
    name: "PRIVATE TAGS",
    selector: (row: any) => row?.customerData?.private_tag ? row?.customerData?.private_tag : <span className=''>NTA</span>,
    sortable: true,
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA?.customerData, rowB?.customerData, "private_tag", true),

    reorder: true,
    id: 12,
    grow: 1,
    minWidth: "150px"
  },
  {
    name: "CLEANER. STATUS",
    cell: (row: any) => {
      return (
        <div className='text-left'>
          {
            row?.cleaner_onwork === "InActive" ? <span className='badge badge-danger fs-8 fw-bold'>{row?.cleaner_onwork}</span> : <span className='badge badge-success fs-8 fw-bold'>{row?.cleaner_onwork}</span>
          }
        </div>
      )
    },
    sortable: true,
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA, rowB, "cleaner_onwork", true),
    reorder: true,
    id: 13,
  },
  {
    name: "DISTANCE",
    cell: (row: any) => {
      return (
        <div className='text-left'>
          {
            row?.distance >= 0.4 ? <span className='badge badge-danger fs-8 fw-bold'>{row?.distance ? row?.distance + "KM" :
              "No Distance"} </span> :
              <span className='fs-8 fw-bold'>{row?.distance ? row?.distance + "KM" : "No Distance"} </span>
          }
        </div>
      )
    },
    sortable: true,
    reorder: true,
    id: 14,
  },
  {
    name: "SOCIETY",
    selector: (row: any) => row?.societyname,
    sortable: true,
    reorder: true,
    id: 15,
    minWidth: "150px"
  },

  {
    name: "RATING/COMMENT",
    selector: (row: any) => row?.ratings,
    sortable: true,
    reorder: true,
    id: 16,
  },
  {
    name: "VEHICLE INFO",
    cell: (row: any) => {
      return (
        <GS_Chips row={row} reference={"vehicle-info"}></GS_Chips>
      )
    },
    reorder: true,
    id: 17,
    minWidth: "200px"
  },
  {
    name: "CHAMP JOB P-SCORE",
    cell: (row: any) => row?.champ_job_punctuality_score,
    reorder: true,
    id: 28,
  },
  {
    name: "CX JOB P-SCORE",
    cell: (row: any) => row?.customer_job_punctuality_score,
    reorder: true,
    id: 29,
  },
  {
    name: "CHAMP CALL P-SCORE",
    cell: (row: any) => row?.champ_call_punctuality_score,
    reorder: true,
    id: 30,
  },
  {
    name: "CX JOB P-SCORE",
    cell: (row: any) => row?.customer_call_punctuality_score,
    reorder: true,
    id: 31,
  },
  {
    name: "IS VERIFIED",
    cell: (row: any) => {
      return (
        row?.is_verified == 0 ? "Not Verified" : "Verified"
      )
    },
    reorder: true,
    id: 19,
    minWidth: "200px"
  },
  {
    name: "VERIFICATION DATE",
    cell: (row: any) => {
      return (
        row?.verification_date ? moment(row?.verification_date).format("YYYY-MM-DD") : "NA"
      )
    },
    reorder: true,
    id: 20,
    minWidth: "200px"
  },
  {
    name: "VERIFICATION HISTORY",
    cell: (row: any) => {
      return (
        <JobHistoryListRow row={row} />
      )
    },
    reorder: true,
    id: 18,
    minWidth: "200px"
  },
  {
    name: "JOB EDIT HISTORY",
    cell: (row: any) => {
      return (
        <JobEditHistoryListRow row={row} />
      )
    },
    reorder: true,
    id: 18,
    minWidth: "200px"
  },
]


const JobHistoryListRow = ({ row }: any) => {
  const [show, setShow] = useState(false)
  return <>
    <FontAwesomeIcon icon={faHistory} className='fs-2 ms-2 text-primary' style={{ cursor: "pointer" }} onClick={() => setShow(true)} />
    {
      show ? <JobHistoryList row={row} show={show} setShow={setShow} /> : <></>
    }

  </>
}

const JobEditHistoryListRow = ({ row }: any) => {
  const [show, setShow] = useState(false)
  return <>
    <FontAwesomeIcon icon={faHistory} className='fs-2 ms-2 text-primary' style={{ cursor: "pointer" }} onClick={() => setShow(true)} />
    {
      show ? <JobEditHistoryList row={row} show={show} setShow={setShow} /> : <></>
    }
  </>
}


const ViewImages = ({ individualID, timeslotid, cleanerid }: any) => {
  const [PopupUpdateStatusOpenClose, setPopupUpdateStatusOpenClose] = useState(false)
  const [destination, setDestination] = useState("")
  const [AttendanceID, setAttendanceID] = useState(0)
  const handleID = (individualID: number) => {
    setDestination("imageAction")
    setPopupUpdateStatusOpenClose(!PopupUpdateStatusOpenClose)
    setAttendanceID(individualID)
  }
  return (
    <>
      <span className='badge badge-primary fs-10 fw-bold text-center' onClick={() => handleID(individualID)}>
        <i className="bi bi-images  fs-2 text-white"></i>
      </span>
      {PopupUpdateStatusOpenClose && <DialogBox AttendanceID={AttendanceID} destination={destination} handleDiloagboxform={handleID} PopupUpdateStatusOpenClose={PopupUpdateStatusOpenClose} attendancestatusList={{
        user_id: "",
        attendence_id: "",
        customerid: "",
        job_status_id: 0,
        job_action_id: 1,
        field_type_id: 1,
      }} timeslotid={timeslotid} cleanerid={cleanerid} />}
    </>
  )
}
export default ViewImages

const CallLogsDetails = ({ customerid, customer_name }: any) => {
  const [show, setshow] = useState(false)
  const [Cusomerid, setCusomerid] = useState(0)
  const handleFetchCallDetails = (customerid: number) => {
    setshow(true)
    setCusomerid(customerid)
  }
  const handleClose = () => {
    setshow(false)
  }
  return (
    <>
      <span className='badge badge-success fs-10 fw-bold text-center ms-5 cursor-pointer' onClick={() => handleFetchCallDetails(customerid)}>
        <i className="bi bi-headset  fs-2 text-white"></i>
      </span>
      <Call_logs FilteredData={{
        call_log_cusomerid: Cusomerid,
        show: show,
        handleClose,
        customer_name
      }} >
      </Call_logs>
    </>
  )
}
const OpenMapAddress = ({ row }: any) => {
  const [openAdressModal, setOpenAddressModal] = useState(false)

  return (
    <div>

      <button className='border border-none' onClick={() => setOpenAddressModal(!openAdressModal)} >
        <FontAwesomeIcon icon={faMapLocationDot} color='#009ef7' size='xl' />
      </button>
      {
        openAdressModal ? <Modal size="lg" show={openAdressModal} onHide={() => setOpenAddressModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Addresses</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ShowAddressMap jobId={row?.id} />
          </Modal.Body>
        </Modal> : <></>
      }

    </div>
  )
}

