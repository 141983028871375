import moment from 'moment'

export const filterApiList: any = {
  getSupervisorList: {
    url: 'getSupervisorList',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getAllFAQsCategories: {
    url: 'getAllFAQsCategories',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getAlladminlist: {
    url: 'getAlladminlist',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getAdminList: {
    url: 'getAdminList',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getAlJobTypes: {
    url: 'getAlJobTypes',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },

  getAllMasterTags: {
    url: 'getAllMasterTags',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {userType: 1},
  },
  getMasterSubReason: {
    url: 'getMasterSubReason',
    method: 'GET',
    isQuery: 'categoryId=1',
    isParams: null,
    isBody: null,
  },

  getMasterReasonForVisitors: {
    url: 'getMasterReasonForVisitors',
    method: 'GET',
    isQuery: 'categoryId=7',
    isParams: null,
    isBody: null,
  },

  getAllBatches: {
    url: 'getAllBatches',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },

  getBatches: {
    url: 'getBatches',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },

  'jobStatus/getAll': {
    url: 'jobStatus/getAll',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getAllCustomerListBySearch: {
    url: 'getAllCustomerListBySearch',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getActiveCustomers: {
    url: 'getActiveCustomers',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },

  getCustomersList: {
    url: 'getCustomersList',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {
      keyword: '',
    },
  },

  getChampsList: {
    url: 'getChampsList',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {
      keyword: '',
    },
  },
  getJobStatuses: {
    url: 'getJobStatuses',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getTicketCategories: {
    url: 'getTicketCategories',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getTicketSubcategories: {
    url: 'getTicketSubcategories',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getTicketSubCategories: {
    url: 'getTicketSubCategories',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getTicketSources: {
    url: 'getTicketSources',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getTicketStatus: {
    url: 'getTicketStatus',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  jobStatus: {
    url: 'jobStatus',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getjobtypes: {
    url: 'getjobtypes',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getNotesCategories: {
    url: 'getNotesCategories',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getRequirementTypes: {
    url: 'getRequirementTypes',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {},
  },
  getarea: {
    url: 'getarea',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getMapBlocksSubAreasListForFilter: {
    url: 'getMapBlocksSubAreasListForFilter',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  searchableSubAreasList: {
    url: 'searchableSubAreasList',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {
      sub_area_id: null,
      keyword: '',
    },
  },
  getSubscriptionRenewalReason: {
    url: 'getSubscriptionRenewalReason',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {
      category_id: null,
    },
  },
  getvehicle: {
    url: 'getvehicle',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getMasterOperationReasons: {
    url: 'getMasterOperationReasons',
    method: 'GET',
    isQuery: 'category_id=null',
    isParams: null,
    isBody: null,
  },

  getMasterReasonsForSubscription: {
    url: 'getMasterReasonsForSubscription',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {},
  },
  'whatsapp/getWhatsAppTemplate': {
    url: 'whatsapp/getWhatsAppTemplate',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {
      wa_number_id: 1,
    },
  },
  getAvailabilityTimeslots: {
    url: 'getAvailabilityTimeslots',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  getLoanTypes: {
    url: 'getLoanTypes',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
}

export const conditionalRowStyles = [
  {
    when: (row: any) => row?.cleanerid == 0 && row?.startdate === moment().format('YYYY-MM-DD'),
    style: {
      backgroundColor: 'red',
      color: 'white',
    },
  },
  {
    when: (row: any) =>
      row?.cleanerid == 0 && row?.startdate === moment().add(1, 'days').format('YYYY-MM-DD'),
    style: {
      backgroundColor: 'yellow',
      color: 'black',
    },
  },
  {
    when: (row: any) =>
      row?.cleanerid == 0 && row?.startdate === moment().add(2, 'days').format('YYYY-MM-DD'),
    style: {
      backgroundColor: '#7ECA9C',
      color: 'white',
    },
  },
]

export const adminBulkAssignApi = [
  {
    url: 'getSupervisorList',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  {
    url: 'getAlladminlist',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
]

export const adminWatiNotifyTemplatFormData = (watiTemplate: any) => {
  return [
    {
      HeaderTitle: watiTemplate?.HeaderTitle || 'Select Whatsapp Template',
      filterApi: 'whatsapp/getWhatsAppTemplate',
      label: watiTemplate?.label || '',
      valueType: watiTemplate?.valueType || 'select',
      inputType: '',
      displayFields: ['name'],
      valueField: 'name',
      name: watiTemplate?.name || 'templateName',
      from: '',
      to: '',
      isRequired: true,
      errMessage: watiTemplate?.errMessage || 'Whatsapp template name is required !',
      searchable: false,
      wa_number_id: watiTemplate?.wa_number_id || null,
      userType: null,
    },
  ]
}

export const adminMasterReasonTemplateFormData = (catTemplate: any) => {
  return [
    {
      HeaderTitle: catTemplate?.HeaderTitle || 'Select Master Category',
      filterApi: 'getMasterReasonForVisitors',
      label: catTemplate?.label || '',
      valueType: catTemplate?.valueType || 'select',
      inputType: '',
      displayFields: ['name'],
      valueField: 'id',
      name: catTemplate?.name || 'categoryId',
      from: '',
      to: '',
      isRequired: true,
      errMessage: catTemplate?.errMessage || 'Category name is required !',
      searchable: false,
      wa_number_id: null,
      userType: null,
      categoryId: catTemplate?.category_id || null,
    },
  ]
}

export const adminBatcheFormData = [
  {
    HeaderTitle: 'Select Batch',
    filterApi: 'getBatches',
    label: '',
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'batch_id',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Batch name is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
]

export const adminAdminSuperFormData = [
  {
    HeaderTitle: 'Select Admin',
    filterApi: 'getAlladminlist',
    label: '',
    valueType: 'multi',
    inputType: '',
    displayFields: ['first_name', 'last_name', 'phone'],
    valueField: 'id',
    name: 'adminIds',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Admin name is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
    categoryId: null,
  },

  {
    HeaderTitle: 'Select Supervisor',
    filterApi: 'getSupervisorList',
    label: '',
    valueType: 'multi',
    inputType: '',
    displayFields: ['first_name', 'last_name', 'phone'],
    valueField: 'id',
    name: 'areaManagerIds',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Supervisor name is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
    categoryId: null,
  },
]

export const enableFeatureList: any = {
  '/champs/champ-leads': {
    champLeadDueDate: true,
    champLeadSubArea: true,
    champLeadPrivateTag: true,
    champLeadTicketTag: false,
    champLeadStatus: true,
    champLeadTicketOwner: false,
  },
  '/task-lists': {
    taskListStatus: true,
    taskListTaskDate: true,
    taskListPrivateTag: false,
    taskListTrainingLocation: false,
    taskListTrainer: false,
  },
}

export const enableFeatureList4CustomerDashbaord: any = {
  '/new-renewal-subscriptions': {
    renewalSubsDueDate: false,
    renewalSubsSubArea: false,
    renewalSubsPrivateTag: false,
    renewalSubsTicketTag: false,
    renewalSubsLastStatus: true,
    renewalSubsTicketOwner: false,
  },
  '/admin/new-tickets': {
    ticketListPrivateTag: true,
    ticketListSubArea: false,
    ticketListTicketTag: false,
    ticketListLastStatus: false,
    ticketListTicketOwner: false,
  },

  '/welcome-call-1-list': {
    wlecomeCall1CampaignStatus: true,
    wlecomeCall1SubArea: false,
    wlecomeCall1TicketTag: false,
    wlecomeCall1LastStatus: false,
    wlecomeCall1TicketOwner: false,
  },
}

export const champColorStatus: any = {
  58: 'success',
  94: 'danger',
  normal: 'dark',
}

export const AdminChamLeadStatusFormApi = [
  {
    url: 'getMasterReasonForVisitors',
    method: 'GET',
    isQuery: 'categoryId=7',
    isParams: null,
  },
  {
    url: 'getMasterSubReason',
    method: 'GET',
    isQuery: 'categoryId=1',
    isParams: null,
  },

  {
    url: 'getActiveChampList',
    method: 'GET',
    isQuery: null,
    isParams: null,
  },
  {
    url: 'getChampRequirementWithChampCount',
    method: 'GET',
    isQuery: null,
    isParams: null,
  },
]

export const weekDays = [
  {id: null, name: ''},
  {id: 1, name: 'Sunday'},
  {id: 2, name: 'Monday'},
  {id: 3, name: 'Tuesday'},
  {id: 4, name: 'Wednesday'},
  {id: 5, name: 'Friday'},
  {id: 6, name: 'Saturday'},
  {id: null, name: 'NA'},
]

export const callActions = ['', 'Completed Call', 'Action taken', 'Not Set']

export const roleData = [
  {id: 1, name: 'Admin'},
  {id: 2, name: 'Super_admin'},
  {id: 3, name: 'Supervisor'},
]

export const couponOffer = [
  {
    name: '10% Off',
    type: 10,
  },
  {
    name: '15% Off',
    type: 15,
  },
  {
    name: '20% Off',
    type: 20,
  },
  {
    name: '25% Off',
    type: 25,
  },
  {
    name: '30% Off',
    type: 30,
  },
  {
    name: '35% Off',
    type: 35,
  },
  {
    name: '40% Off',
    type: 40,
  },
  {
    name: '45% Off',
    type: 45,
  },
  {
    name: '50% Off',
    type: 50,
  },
  {
    name: '55% Off',
    type: 55,
  },
  {
    name: '60% Off',
    type: 60,
  },
  {
    name: '65% Off',
    type: 65,
  },
  {
    name: '70% Off',
    type: 70,
  },
  {
    name: '75% Off',
    type: 75,
  },
  {
    name: '80% Off',
    type: 80,
  },
  {
    name: '85% Off',
    type: 85,
  },
  {
    name: '90% Off',
    type: 90,
  },
  {
    name: '95% Off',
    type: 95,
  },
  {
    name: '100% Off',
    type: 100,
  },
]

export const adminMenuActionPermissionKeys = {
  dashboard: 'dashboard',
  'dashboard-statics': 'dashboard-statics',
  'ticket-dashboard': 'ticket-dashboard',
  'new-ticket-dashboard': 'new-ticket-dashboard',
  'new-daily-dashboard': 'new-daily-dashboard',
  'reward-and-penalties': 'reward-and-penalties',
  'admin-panel-carselona': 'admin-panel-carselona',
  'device-login-allowed': 'device-login-allowed',
  'daily-dashboard': 'daily-dashboard',
  'iframe-dashboard': 'iframe-dashboard',
  'new-deactivated-champ-list': 'new-deactivated-champ-list',
  'active-paid': 'active-paid',
  'new-active-paid': 'new-active-paid',
  'on-demand': 'on-demand',
  'kit-subscriptions': 'kit-subscriptions',
  inactive: 'inactive',
  'server-inactive': 'server-inactive',
  'renewal-list': 'renewal-list',
  'date-wise-onboarding': 'date-wise-onboarding',
  'onboarding-subscriptions': 'onboarding-subscriptions',
  'un-assigned': 'un-assigned',
  'no-job-subscription-list': 'no-job-subscription-list',
  'job-list': 'job-list',
  'search-availibility': 'search-availibility',
  'cleaner-availibilty': 'cleaner-availibilty',
  'requirement-type': 'requirement-type',
  'create-batch': 'create-batch',
  'whatsapp-groups': 'whatsapp-groups',
  'tag-lists': 'tag-lists',
  'whatsapp-template': 'whatsapp-template',
  'master-source': 'master-source',
  services: 'services',
  products: 'products',
  packages: 'packages',
  'fuel-type': 'fuel-type',
  'vehicle-type': 'vehicle-type',
  'vehicle-category': 'vehicle-category',
  'vehicle-brand': 'vehicle-brand',
  'vehicle-model': 'vehicle-model',
  problem: 'problem',
  'sub-problem': 'sub-problem',
  't-shirt': 't-shirt',
  'job-sites': 'job-sites',
  'job-type': 'job-type',
  'user-type': 'user-type',
  'points-table': 'points-table',
  'credit-reasons': 'credit-reasons',
  'job-status': 'job-status',
  'master-reasons': 'master-reasons',
  'master-resource': 'master-resource',
  'master-sources': 'master-sources',
  'master-offers': 'master-offers',
  'master-tickets-category': 'master-tickets-category',
  'master-tickets-sub-category': 'master-tickets-sub-category',
  country: 'country',
  state: 'state',
  city: 'city',
  pincode: 'pincode',
  'campaign-dashboard': 'campaign-dashboard',
  'create-campaign': 'create-campaign',
  'all-campaigns': 'all-campaigns',
  rewards: 'rewards',
  redeemtions: 'redeemtions',
  'company-cleaner-m-a-ps': 'company-cleaner-m-a-ps',
  'customer-cleaner-m-a-ps': 'customer-cleaner-m-a-ps',
  'comany-requirement-map': 'comany-requirement-map',
  'sub-location-map': 'sub-location-map',
  'cleaner-routes-m-a-ps': 'cleaner-routes-m-a-ps',
  'society-m-a-ps': 'society-m-a-ps',
  'cleaner-m-a-ps': 'cleaner-m-a-ps',
  'pincode-marker-m-a-ps': 'pincode-marker-m-a-ps',
  'all-customer-cleaner-m-a-ps': 'all-customer-cleaner-m-a-ps',
  'customer-stats': 'customer-stats',
  'outbound-result': 'outbound-result',
  'inbound-result': 'inbound-result',
  items: 'items',
  'order-status': 'order-status',
  'order-list': 'order-list',
  'new-order-list': 'new-order-list',
  warehouse: 'warehouse',
  'warehouse-alert-status': 'warehouse-alert-status',
  vendor: 'vendor',
  'purchase-order': 'purchase-order',
  'inventory-ordered-items-counts': 'inventory-ordered-items-counts',
  'warehouse-type': 'warehouse-type',
  tickets: 'tickets',
  'new-tickets': 'new-tickets',
  'renewal-subscription': 'renewal-subscription',
  'new-renewal-subscription': 'new-renewal-subscription',
  'resumed-list': 'resumed-list',
  'pause-paid': 'pause-paid',
  'pause-un-paid': 'pause-un-paid',
  'inactive-autometically': 'inactive-autometically',
  'inactive-agent': 'inactive-agent',
  'future-pause': 'future-pause',
  'visitors-list': 'visitors-list',
  'champ-referral-list': 'champ-referral-list',
  'customer-leads': 'customer-leads',
  'champ-leads': 'champ-leads',
  'task-lists': 'task-lists',
  'renewal-supervisor-list': 'renewal-supervisor-list',
  active: 'active',
  attendance: 'attendance',
  'champ-missed-call-list': 'champ-missed-call-list',
  'absent-list': 'absent-list',
  'leave-list': 'leave-list',
  deactivated: 'deactivated',
  schemes: 'schemes',
  'deduction-jobs': 'deduction-jobs',
  'onboarding-pending': 'onboarding-pending',
  leads: 'leads',
  league: 'league',
  'training-schedules': 'training-schedules',
  'training-locations': 'training-locations',
  'champ-requirement': 'champ-requirement',
  'training-topics': 'training-topics',
  areas: 'areas',
  'hiring-dashboard': 'hiring-dashboard',
  'hiring-dashboard2': 'hiring-dashboard2',
  'requirement-map': 'requirement-map',
  'notification-template': 'notification-template',
  'notification-dashboard': 'notification-dashboard',
  'welcome-list': 'welcome-list',
  'weekly-payout-report': 'weekly-payout-report',
  'new-weekly-payout-report': 'new-weekly-payout-report',
  'in-app': 'in-app',
  'admin-users': 'admin-users',
  'working-on': 'working-on',
  'work-in-progress': 'work-in-progress',
  'role-modules': 'role-modules',
  societies: 'societies',
  invoices: 'invoices',
  'subscription-month': 'subscription-month',
  settings: 'settings',
  'lucky-draw': 'lucky-draw',
  'menu-column-toggle-hander': 'menu-column-toggle-hander',
  'approval-lists': 'approval-lists',
  'customer-vehicle-list': 'customer-vehicle-list',
  'vehicle-subscription-history-list': 'vehicle-subscription-history-list',
  'renewal-task-list': 'renewal-task-list',
  'welcome-call1-list': 'welcome-call1-list',
  'welcome-call2-list': 'welcome-call2-list',
  'champ-device-login': 'champ-device-login',
  'customer-missed-call-lists': 'customer-missed-call-lists',
  'access-area-manager': 'access-area-manager',
  'cron-history-list': 'cron-history-list',
  'customer-assigned-agents': 'customer-assigned-agents',
  'easebuzz-transaction-hiistory': 'easebuzz-transaction-hiistory',
  'deactivation-notes': 'deactivation-notes',
  'loan-lists': 'loan-lists',
  'loan-transaction': 'loan-transaction',
  'frequently-asked-question': 'frequently-asked-question',
  'campaign-list-sid': 'campaign-list-sid',
  'training-list': 'training-list',
  'payout-timeline': 'payout-timeline',
  'ticket-count-list': 'ticket-count-list',
  'ticket-count-source-wise': 'ticket-count-source-wise',
  'user-call-status': 'user-call-status',
  'champ-hiring-automation': 'champ-hiring-automation',
  'new-champ-assignment-list': 'new-champ-assignment-list',
  'developer-tools': 'developer-tools',
  'leads-count-source-wise': 'leads-count-source-wise',
  'payment-counts': 'payment-counts',
  'sales-counts': 'sales-counts',
  'source-vs-leads-status': 'source-vs-leads-status',
  'roles-and-permissions': 'roles-and-permissions',
  'material-transactions': 'material-transactions',
  'warehouse-stock-transfer': 'warehouse-stock-transfer',

  // Champ Leads Keys

  'champ-leads-export-list': 'champ-leads-export-list',
  'champ-leads-date-toggle-format': 'champ-leads-date-toggle-format',
  'champ-leads-show-hide-columns': 'champ-leads-show-hide-columns',
  'champ-leads-global-filter': 'champ-leads-global-filter',
  'champ-leads-bulk-ticket-update': 'champ-leads-bulk-ticket-update',
  'champ-leads-bulk-owner-update': 'champ-leads-bulk-owner-update',
  'champ-leads-bulk-status-update': 'champ-leads-bulk-status-update',
  'champ-leads-bulk-batch-update': 'champ-leads-bulk-batch-update',
  'champ-leads-bulk-free-form-update': 'champ-leads-bulk-free-form-update',
  'champ-leads-bulk-area-name-update': 'champ-leads-bulk-area-name-update',
  'champ-leads-bulk-sub-area-name-update': 'champ-leads-bulk-sub-area-name-update',
  'champ-leads-bulk-lead-status-update': 'champ-leads-bulk-lead-status-update',
  'champ-leads-bulk-sub-lead-status-update': 'champ-leads-bulk-sub-lead-status-update',
  'champ-leads-bulk-add-contact-to-campaign': 'champ-leads-bulk-add-contact-to-campaign',
  'champ-leads-bulk-whatsapp-notification-send': 'champ-leads-bulk-whatsapp-notification-send',
  'champ-leads-add-note': 'champ-leads-add-note',
  'champ-leads-whatsapp-notification-send-single': 'champ-leads-whatsapp-notification-send-single',
  'champ-leads-fetch-call-detail': 'champ-leads-fetch-call-detail',
  'champ-leads-due-date-update': 'champ-leads-due-date-update',
  'champ-leads-private-tag-update': 'champ-leads-private-tag-update',
  'champ-leads-public-tag-update': 'champ-leads-public-tag-update',
  'champ-leads-status-update': 'champ-leads-status-update',
  'champ-leads-sub-lead-status-update': 'champ-leads-sub-lead-status-update',
  'champ-leads-ticket-tag-update': 'champ-leads-ticket-tag-update',
  'champ-leads-call-connect': 'champ-leads-call-connect',
  'champ-leads-sub-area-name-update': 'champ-leads-sub-area-name-update',
  'champ-leads-owner-update': 'champ-leads-owner-update',

  // champ dashbaord keys
  'champ-management-dashboard-profile': 'champ-management-dashboard-profile',
  'champ-management-dashboard-chat-history': 'champ-management-dashboard-chat-history',
  'champ-management-dashboard-call-logs': 'champ-management-dashboard-call-logs',
  'champ-management-dashboard-training-lists': 'champ-management-dashboard-training-lists',
  'champ-management-dashboard-action-training': 'champ-management-dashboard-action-training',
  'champ-management-dashboard-weekly-reports': 'champ-management-dashboard-weekly-reports',
  'champ-management-dashboard-weekly-payout-report':
    'champ-management-dashboard-weekly-payout-report',
  'champ-management-dashboard-bank-details': 'champ-management-dashboard-bank-details',
  'champ-management-dashboard-documents': 'champ-management-dashboard-documents',
  'champ-management-dashboard-map': 'champ-management-dashboard-map',
  'champ-management-dashboard-area-availability': 'champ-management-dashboard-area-availability',
  'champ-management-dashboard-scheme-records': 'champ-management-dashboard-scheme-records',
  'champ-management-dashboard-allowance': 'champ-management-dashboard-allowance',
  'champ-management-dashboard-job-list': 'champ-management-dashboard-job-list',
  'champ-management-dashboard-hash-list': 'champ-management-dashboard-hash-list',
  'champ-management-dashboard-fine-list': 'champ-management-dashboard-fine-list',
  'champ-management-dashboard-reward-list': 'champ-management-dashboard-reward-list',
  'champ-management-dashboard-reward-and-penalties':
    'champ-management-dashboard-reward-and-penalties',
  'champ-management-dashboard-attendance-list': 'champ-management-dashboard-attendance-list',
  'champ-management-dashboard-weekly-payout-report-pay':
    'champ-management-dashboard-weekly-payout-report-pay',
  'champ-management-dashboard-bank-details-contact-create':
    'champ-management-dashboard-bank-details-contact-create',
  'champ-management-dashboard-bank-details-payment-transfer':
    'champ-management-dashboard-bank-details-payment-transfer',
  'champ-management-dashboard-bank-details-beneficiary-createtion':
    'champ-management-dashboard-bank-details-beneficiary-createtion',
  'champ-management-dashboard-bank-details-edit-bank-detail':
    'champ-management-dashboard-bank-details-edit-bank-detail',
  'champ-management-dashboard-bank-details-add-bank-details':
    'champ-management-dashboard-bank-details-add-bank-details',
  'champ-management-dashboard-documents-add-documents':
    'champ-management-dashboard-documents-add-documents',
  'champ-management-dashboard-documents-edit-documents':
    'champ-management-dashboard-documents-edit-documents',
  'champ-management-dashboard-scheme-records-assign-change':
    'champ-management-dashboard-scheme-records-assign-change',

  // main menus keys
  subscriptions: 'subscriptions',
  schedules: 'schedules',
  masters: 'masters',
  address: 'address',
  campaign: 'campaign',
  maps: 'maps',
  statistics: 'statistics',
  'call-results': 'call-results',
  inventory: 'inventory',
  dashboards: 'dashboards',
  'all-tickets': 'all-tickets',
  'data-lists': 'data-lists',
  champs: 'champs',
  hiring: 'hiring',
  notifications: 'notifications',
  'all-ratings': 'all-ratings',
  'user-management': 'user-management',
  'invoice-list': 'invoice-list',
  access: 'access',
  easebuzz: 'easebuzz',
  cron: 'cron',
  loan: 'loan',
  faq: 'faq',
  'champ-campaign': 'champ-campaign',
  'model-training': 'model-training',
  'tracking-list': 'tracking-list',

  // customer dashboard keys
  'cx-management-dashboard-ticket-reply-section': 'cx-management-dashboard-ticket-reply-section',
  'cx-management-dashboard-customer-information': 'cx-management-dashboard-customer-information',
  'cx-management-dashboard-chat-history': 'cx-management-dashboard-chat-history',
  'cx-management-dashboard-call-logs': 'cx-management-dashboard-call-logs',
  'cx-management-dashboard-active-subscription': 'cx-management-dashboard-active-subscription',
  'cx-management-dashboard-subscription-history': 'cx-management-dashboard-subscription-history',
  'cx-management-dashboard-addresses': 'cx-management-dashboard-addresses',
  'cx-management-dashboard-vehicles': 'cx-management-dashboard-vehicles',
  'cx-management-dashboard-new-coin-history': 'cx-management-dashboard-new-coin-history',
  'cx-management-dashboard-job-list': 'cx-management-dashboard-job-list',
  'cx-management-dashboard-offer-list': 'cx-management-dashboard-offer-list',
  'cx-management-dashboard-transaction-list': 'cx-management-dashboard-transaction-list',
  'cx-management-dashboard-gallary': 'cx-management-dashboard-gallary',
  'cx-management-dashboard-quick-reply': 'cx-management-dashboard-quick-reply',

  'customer-information-poc-update': 'customer-information-poc-update',
  'customer-information-phone-number-update': 'customer-information-phone-number-update',
  'customer-information-whatsapp-number-update': 'customer-information-whatsapp-number-update',
  'customer-information-society-name-update': 'customer-information-society-name-update',
  'customer-information-block-status-update': 'customer-information-block-status-update',
  'customer-information-approve-coin-usage': 'customer-information-approve-coin-usage',
  'customer-information-private-tag-update': 'customer-information-private-tag-update',
  'customer-information-public-tag-update': 'customer-information-public-tag-update',
  'customer-information-ip-block': 'customer-information-ip-block',
  'customer-information-create-ticket-dropdown': 'customer-information-create-ticket-dropdown',
  'customer-information-create-offer-dropdown': 'customer-information-create-offer-dropdown',
  'chat-history-ticket-reply': 'chat-history-ticket-reply',
  'chat-history-copy-ticket-number': 'chat-history-copy-ticket-number',
  'active-subscription-show-addresses': 'active-subscription-show-addresses',
  'active-subscription-show-addresses-update-address':
    'active-subscription-show-addresses-update-address',
  'active-subscription-assign-cleaner': 'active-subscription-assign-cleaner',
  'active-subscription-remove-dublicate-vahicle': 'active-subscription-remove-dublicate-vahicle',
  'active-subscription-change-customer-timeslot': 'active-subscription-change-customer-timeslot',
  'active-subscription-get-razorpay-payment': 'active-subscription-get-razorpay-payment',
  'active-subscription-change-full-cleaning-day': 'active-subscription-change-full-cleaning-day',
  'active-subscription-update-address-pincode': 'active-subscription-update-address-pincode',
  'active-subscription-job-list': 'active-subscription-job-list',
  'active-subscription-disable-auto-pay': 'active-subscription-disable-auto-pay',
  'active-subscription-get-refund-status': 'active-subscription-get-refund-status',
  'active-subscription-extend-subscription': 'active-subscription-extend-subscription',
  'active-subscription-change-status': 'active-subscription-change-status',
  'active-subscription-change-address': 'active-subscription-change-address',
  'active-subscription-update-payment-status': 'active-subscription-update-payment-status',
  'active-subscription-initiate-refund': 'active-subscription-initiate-refund',
  'active-subscription-credit-coin': 'active-subscription-credit-coin',
  'active-subscription-regenerate-job': 'active-subscription-regenerate-job',
  'active-subscription-change-start-date': 'active-subscription-change-start-date',
  'addresses-update-address': 'addresses-update-address',
  'vehicles-calendar': 'vehicles-calendar',
  'vehicles-remove-dublicate-vehicle': 'vehicles-remove-dublicate-vehicle',
  'vehicles-delete-vehicle': 'vehicles-delete-vehicle',
  'vehicles-update-vehicle': 'vehicles-update-vehicle',
  'offer-list-delete': 'offer-list-delete',
  'ticket-reply-section-ticket-status-update': 'ticket-reply-section-ticket-status-update',
  'ticket-reply-section-assign-to-user-update': 'ticket-reply-section-assign-to-user-update',
  'ticket-reply-section-ticket-category-update': 'ticket-reply-section-ticket-category-update',
  'ticket-reply-section-sub-ticket-category-update':
    'ticket-reply-section-sub-ticket-category-update',
  'ticket-reply-section-source-update': 'ticket-reply-section-source-update',
  'ticket-reply-section-due-date-update': 'ticket-reply-section-due-date-update',
  'ticket-reply-section-last_status-update': 'ticket-reply-section-last_status-update',
  'ticket-reply-section-call-connect': 'ticket-reply-section-call-connect',
  'ticket-reply-section-fetch-whatsapp': 'ticket-reply-section-fetch-whatsapp',
  'ticket-reply-section-prev-ticket-open': 'ticket-reply-section-prev-ticket-open',
  'ticket-reply-section-next-ticket-open': 'ticket-reply-section-next-ticket-open',
  'ticket-reply-section-upload-image-chat': 'ticket-reply-section-upload-image-chat',
  'ticket-reply-section-send-to-cx': 'ticket-reply-section-send-to-cx',
  'ticket-reply-section-send-to-am': 'ticket-reply-section-send-to-am',
  'ticket-reply-section-send-to-notes': 'ticket-reply-section-send-to-notes',
  'ticket-reply-section-switch-chat-reply': 'ticket-reply-section-switch-chat-reply',
  'new-coin-history-generate-coin-history': 'new-coin-history-generate-coin-history',
  'new-coin-history-export-list': 'new-coin-history-export-list',
  'customer-information-assign-package': 'customer-information-assign-package',

  // active paid list keys
  'active-paid-filter': 'active-paid-filter',
  'active-paid-get-link': 'active-paid-get-link',
  'active-paid-change-cleaner': 'active-paid-change-cleaner',
  'active-paid-change-timeslot': 'active-paid-change-timeslot',
  'active-paid-change-status': 'active-paid-change-status',
  'active-paid-change-cleaned-vehicle-image-status':
    'active-paid-change-cleaned-vehicle-image-status',
  'active-paid-change-address': 'active-paid-change-address',
  'active-paid-deduct-now': 'active-paid-deduct-now',
  'active-paid-open-chat': 'active-paid-open-chat',

  // job list keys
  'job-list-filter': 'job-list-filter',
  'job-list-show-hide-column': 'job-list-show-hide-column',
  'job-list-re-assign': 'job-list-re-assign',
  'job-list-open-chat': 'job-list-open-chat',
  'job-list-edit-attendance': 'job-list-edit-attendance',
  'job-list-create-ticket': 'job-list-create-ticket',
  'job-list-update-status-report': 'job-list-update-status-report',
  'job-list-revert-reported-attendance': 'job-list-revert-reported-attendance',
  'job-list-change-date-scheduling': 'job-list-change-date-scheduling',
  'job-list-fine-for-wrong-reporting': 'job-list-fine-for-wrong-reporting',
  'job-list-give-rewards': 'job-list-give-rewards',
  'job-list-update-job-verification-status': 'job-list-update-job-verification-status',
  'job-list-calender': 'job-list-calender',
  'job-list-map-address': 'job-list-map-address',
  'job-list-gallary': 'job-list-gallary',
  'job-list-call-recording': 'job-list-call-recording',
  'job-list-verification-history': 'job-list-verification-history',
  'job-list-job-edit-history': 'job-list-job-edit-history',
  'job-list-convert-to-full-cleaning-day': 'job-list-convert-to-full-cleaning-day',
  'job-list-not-at-home': 'job-list-not-at-home',

  // active cleaner list
  'active-filter': 'active-filter',
  'active-show-hide-column': 'active-show-hide-column',
  'active-export-list': 'active-export-list',
  'active-send-notification': 'active-send-notification',
  'active-re-assign-permanent': 'active-re-assign-permanent',
  'active-re-assign-temporary': 'active-re-assign-temporary',
  'active-assign-remove-supervisor': 'active-assign-remove-supervisor',
  'active-add-update-tag': 'active-add-update-tag',
  'active-add-bank-detail': 'active-add-bank-detail',
  'active-view-profile': 'active-view-profile',
  'active-deactivate-champ': 'active-deactivate-champ',
  'active-revert-job-left': 'active-revert-job-left',
  'active-make-full-timer': 'active-make-full-timer',
  'active-availability': 'active-availability',
  'active-assign-update-slab': 'active-assign-update-slab',
  'active-assign-update-allowance': 'active-assign-update-allowance',
  'active-give-reward': 'active-give-reward',
  'active-fine-for-wrong-reporting': 'active-fine-for-wrong-reporting',
  'active-view-loans': 'active-view-loans',
  'active-assign-supervisor': 'active-assign-supervisor',
  'active-security-deposit': 'active-security-deposit',
  'active-new-champ-assign': 'active-new-champ-assign',
  'active-whatsapp-group': 'active-whatsapp-group',

  // customer leads keys
  'customer-leads-filter': 'customer-leads-filter',
  'customer-leads-show-hide-column': 'customer-leads-show-hide-column',
  'customer-leads-toggle-date-format': 'customer-leads-toggle-date-format',
  'customer-leads-bulk-assign-ticket': 'customer-leads-bulk-assign-ticket',
  'customer-leads-bulk-whatsapp-notification': 'customer-leads-bulk-whatsapp-notification',
  'customer-leads-bulk-reset-customer': 'customer-leads-bulk-reset-customer',
  'customer-leads-bulk-batch-assign-lead': 'customer-leads-bulk-batch-assign-lead',
  'customer-leads-bulk-add-to-contact-to-list': 'customer-leads-bulk-add-to-contact-to-list',
  'customer-leads-whatsapp-notification-single': 'customer-leads-whatsapp-notification-single',
  'customer-leads-call-connect': 'customer-leads-call-connect',
  'customer-leads-due-date-update': 'customer-leads-due-date-update',
  'customer-leads-source-update': 'customer-leads-source-update',
  'customer-leads-update-society': 'customer-leads-update-society',
  'customer-leads-update-status': 'customer-leads-update-status',
  'customer-leads-fetch-call-logs': 'customer-leads-fetch-call-logs',
  'customer-leads-fetch-transaction': 'customer-leads-fetch-transaction',

  // task list keys
  'task-lists-filter': 'task-lists-filter',
  'task-lists-show-hide-column': 'task-lists-show-hide-column',
  'task-lists-toggle-date-format': 'task-lists-toggle-date-format',
  'task-lists-update-to-missed-training': 'task-lists-update-to-missed-training',
  'task-lists-bulk-whatsapp-notification': 'task-lists-bulk-whatsapp-notification',
  'task-lists-bulk-is-paid-status': 'task-lists-bulk-is-paid-status',
  'task-lists-bulk-add-to-contact-to-list': 'task-lists-bulk-add-to-contact-to-list',
  'task-lists-selfie': 'task-lists-selfie',
  'task-lists-call-connect': 'task-lists-call-connect',
  'task-lists-task-reminder-alert': 'task-lists-task-reminder-alert',
  'task-lists-update-private-tag': 'task-lists-update-private-tag',
  'task-lists-update-task-date': 'task-lists-update-task-date',
  'task-lists-update-trainer': 'task-lists-update-trainer',
  'task-lists-update-training-location': 'task-lists-update-training-location',
  'task-lists-update-status': 'task-lists-update-status',
  'task-lists-fetch-call-logs': 'task-lists-fetch-call-logs',
  'task-lists-is-paid': 'task-lists-is-paid',
  'task-lists-job-edit-history': 'task-lists-job-edit-history',
  'task-lists-call-connect-trainer': 'task-lists-call-connect-trainer',
  'task-lists-call-connect-trainer-area-manager': 'task-lists-call-connect-trainer-area-manager',
  'am-job-dashboard': 'am-job-dashboard',

  // renewal subscription list keys
  'renewal-subscription-filter': 'renewal-subscription-filter',
  'renewal-subscription-show-hide-column': 'renewal-subscription-show-hide-column',
  'renewal-subscription-toggle-date-format': 'renewal-subscription-toggle-date-format',
  'renewal-subscription-bulk-assign-ticket': 'renewal-subscription-bulk-assign-ticket',
  'renewal-subscription-bulk-whatsapp-notification':
    'renewal-subscription-bulk-whatsapp-notification',
  'renewal-subscription-bulk-add-to-contact-to-list':
    'renewal-subscription-bulk-add-to-contact-to-list',
  'renewal-subscription-call-connect': 'renewal-subscription-call-connect',
  'renewal-subscription-fetch-call-logs': 'renewal-subscription-fetch-call-logs',
  'renewal-subscription-fetch-transaction': 'renewal-subscription-fetch-transaction',
  'renewal-subscription-get-easebuzz-payment-link':
    'renewal-subscription-get-easebuzz-payment-link',
  'renewal-subscription-get-razorpay-payment-link':
    'renewal-subscription-get-razorpay-payment-link',
  'renewal-subscription-create-renewal-job': 'renewal-subscription-create-renewal-job',
  'renewal-subscription-whatsapp-notification-single':
    'renewal-subscription-whatsapp-notification-single',
  'renewal-subscription-open-chat': 'renewal-subscription-open-chat',
  'renewal-subscription-update-p2p': 'renewal-subscription-update-p2p',
  'renewal-subscription-update-due-date': 'renewal-subscription-update-due-date',
  // new ticket list keys
  'new-tickets-filter': 'new-tickets-filter',
  'new-tickets-show-hide-column': 'new-tickets-show-hide-column',
  'new-tickets-toggle-date-format': 'new-tickets-toggle-date-format',
  'new-tickets-create-new-ticket': 'new-tickets-create-new-ticket',
  'new-tickets-open-chat': 'new-tickets-open-chat',
  'new-tickets-update-tag': 'new-tickets-update-tag',
  'new-tickets-bulk-ticket-assign': 'new-tickets-bulk-ticket-assign',
  'new-tickets-bulk-add-to-contact-to-list': 'new-tickets-bulk-add-to-contact-to-list',
  // pause paid list keys
  'pause-paid-filter': 'pause-paid-filter',
  'pause-paid-show-hide-column': 'pause-paid-show-hide-column',
  'pause-paid-toggle-date-format': 'pause-paid-toggle-date-format',
  'pause-paid-send-notification': 'pause-paid-send-notification',
  'pause-paid-open-chat': 'pause-paid-open-chat',
  'pause-paid-bulk-ticket-assign': 'pause-paid-bulk-ticket-assign',
  'pause-paid-bulk-add-to-contact-to-list': 'pause-paid-bulk-add-to-contact-to-list',
  'pause-paid-fetch-call-log': 'pause-paid-fetch-call-log',
  'pause-paid-update-reason': 'pause-paid-update-reason',
  'pause-paid-update-last-status': 'pause-paid-update-last-status',
  'pause-paid-update-due-date': 'pause-paid-update-due-date',
  // pause un paid keys
  'pause-un-paid-filter': 'pause-un-paid-filter',
  'pause-un-paid-show-hide-column': 'pause-un-paid-show-hide-column',
  'pause-un-paid-toggle-date-format': 'pause-un-paid-toggle-date-format',
  'pause-un-paid-send-notification': 'pause-un-paid-send-notification',
  'pause-un-paid-open-chat': 'pause-un-paid-open-chat',
  'pause-un-paid-bulk-ticket-assign': 'pause-un-paid-bulk-ticket-assign',
  'pause-un-paid-bulk-add-to-contact-to-list': 'pause-un-paid-bulk-add-to-contact-to-list',
  'pause-un-paid-fetch-call-log': 'pause-un-paid-fetch-call-log',
  'pause-un-paid-update-reason': 'pause-un-paid-update-reason',
  'pause-un-paid-update-last-status': 'pause-un-paid-update-last-status',
  'pause-un-paid-update-due-date': 'pause-un-paid-update-due-date',
  'pause-un-paid-wave-off': 'pause-un-paid-wave-off',
  // pause automatically keys
  'inactive-autometically-filter': 'inactive-autometically-filter',
  'inactive-autometically-show-hide-column': 'inactive-autometically-show-hide-column',
  'inactive-autometically-toggle-date-format': 'inactive-autometically-toggle-date-format',
  'inactive-autometically-send-notification': 'inactive-autometically-send-notification',
  'inactive-autometically-open-chat': 'inactive-autometically-open-chat',
  'inactive-autometically-bulk-ticket-assign': 'inactive-autometically-bulk-ticket-assign',
  'inactive-autometically-bulk-add-to-contact-to-list':
    'inactive-autometically-bulk-add-to-contact-to-list',
  'inactive-autometically-fetch-call-log': 'inactive-autometically-fetch-call-log',
  'inactive-autometically-update-reason': 'inactive-autometically-update-reason',
  'inactive-autometically-update-last-status': 'inactive-autometically-update-last-status',
  'inactive-autometically-update-due-date': 'inactive-autometically-update-due-date',
  'inactive-autometically-wave-off': 'inactive-autometically-wave-off',
  // pause auto
  'inactive-agent-filter': 'inactive-agent-filter',
  'inactive-agent-show-hide-column': 'inactive-agent-show-hide-column',
  'inactive-agent-toggle-date-format': 'inactive-agent-toggle-date-format',
  'inactive-agent-send-notification': 'inactive-agent-send-notification',
  'inactive-agent-open-chat': 'inactive-agent-open-chat',
  'inactive-agent-bulk-ticket-assign': 'inactive-agent-bulk-ticket-assign',
  'inactive-agent-bulk-add-to-contact-to-list': 'inactive-agent-bulk-add-to-contact-to-list',
  'inactive-agent-fetch-call-log': 'inactive-agent-fetch-call-log',
  'inactive-agent-update-reason': 'inactive-agent-update-reason',
  'inactive-agent-update-last-status': 'inactive-agent-update-last-status',
  'inactive-agent-update-due-date': 'inactive-agent-update-due-date',
  'inactive-agent-wave-off': 'inactive-agent-wave-off',
  // subscription
  'unsuccessful-subscriptions': 'unsuccessful-subscriptions',
  'agent-offer-allotment': 'agent-offer-allotment',
  'agent-offers-list': 'agent-offers-list',
  'create-offer-allotment': 'create-offer-allotment',
  'add-on-coupon-update': 'add-on-coupon-update',
  'not-at-home-list': 'not-at-home-list',
  'agent-daily-dashboard': 'agent-daily-dashboard',
  'user-logout-status': 'user-logout-status',
  'ads-insights': 'ads-insights',
  'long-nah': 'long-nah',
  'terms-and-condition': 'terms-and-condition',
  'vendor-invoice': 'vendor-invoice',
  'proforma-invoice': 'proforma-invoice',
  'payment-receipt': 'payment-receipt',
  'goods-receipt': 'goods-receipt',
  configurations: 'configurations',
  'common-leads': 'common-leads',
}
