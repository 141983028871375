import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { getRequest, postRequest } from '../../Server/Requests'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { adminToastRunner } from '../../../modules/admins/adminUtils'
import AdminSingleSelectFilter from '../../../modules/admins/adminForms/adminFormComponents/AdminSingleSelectFilter'
import AdminTextBoxReuse from '../../../modules/admins/adminForms/adminFormComponents/AdminTextBoxReuse'
import { getFilterDataBySearchClientSide } from '../../DynamicFilteration/components/CommonData'
import IsLoading from '../../IsLoading/IsLoading'
import AdminSelectSearchable from '../../../modules/admins/adminForms/adminFormComponents/AdminSelectSearchable'
const MarkChampAsLead = ({ show, setShow, refreshNow, keyName, row }: any) => {

    const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
    const [vehicleList, setVehicleList] = useState([])
    const [defValue, setDefValue] = useState<any>(null)
    const [pending, setPending] = useState<any>(false)
    const [storeFilterListItem, setStoreFilterListItem] = useState<any>({
        subAreaList: [],
    })
    const [payload, setPayload] = useState<any>({
        customerID: row?.id,
        subAreaID: null,
        ownVehicle: null,
        address: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await getRequest(URL_EndPoints()?.getvehicle, null)
            setVehicleList(response?.data?.data)

        };
        fetchData()
    }, [])

    const getDefData = async () => {
        setPending(true);

        const response = await postRequest(URL_EndPoints()?.getChampDetails, { customerID: row?.id }, setPending)
        setDefValue({
            address: response?.data?.data?.address,
            ownVehicle: response?.data?.data?.ownVehicle,
            subArea: response?.data?.data?.subArea?.name ? {
                item: {
                    label: response?.data?.data?.subArea?.name, value: response?.data?.data?.subArea?.id
                }
            } : null,
        });
        setPayload({ ...payload, ["address"]: response?.data?.data?.address, ["ownVehicle"]: response?.data?.data?.ownVehicle, ["subAreaID"]: response?.data?.data?.subArea?.id })
    }

    useEffect(() => {
        getDefData()
    }, [])

    const vehicleData: any = vehicleList?.find((ele: any) => ele?.id == defValue?.ownVehicle)

    const onChangeHandler: any = (event: any, name: any, isDate?: any) => {
        setPayload({
            ...payload,
            [name]: event ? event?.value : event,
        })
    }

    const submitForm = async (e: any) => {
        e.preventDefault()
        setIsBulkAssignApiLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.markCustomerAsChampLead, payload, setIsBulkAssignApiLoading)
        adminToastRunner(response)
        refreshNow && refreshNow()
        if (response?.data?.status == 200) {
            setShow(false)
        }
    }

    const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
    const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
    const [dynFilter, setDynFilter] = useState<any>("")


    const setSearchFilter = (event: any, filters: any) => {
        setDynFilter(filters)
        setStoreSearchKeywords(event)
    }

    useEffect(() => {
        let x = setTimeout(async () => {
            setIsLoadingFilter(true)
            await getFilterDataBySearchClientSide(dynFilter, setStoreFilterListItem, storeFilterListItem, setIsLoadingFilter, { keyword: storeSearchKeywords })
            setIsLoadingFilter(false)
        }, 2000)

        return () => {
            setIsLoadingFilter(false)
            clearTimeout(x)
        }
    }, [storeSearchKeywords, dynFilter])

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="small-modal"
                centered
                key={keyName}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Make Champ Lead </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        pending ? <IsLoading /> :
                            <Form onSubmit={submitForm}>
                                <Form.Group className="mb-3" key={"MarkChampAsLead"} >
                                    {<Form.Label className='text-muted'>Select Sub Area</Form.Label>}
                                    <div className='mb-3' style={{ position: "relative" }}>
                                        <AdminSelectSearchable
                                            HeaderTitle='Select Sub Area'
                                            onChangeHandler={onChangeHandler}
                                            selectData={storeFilterListItem["subAreaList"]}
                                            displayFields={["name"]}
                                            id="id"
                                            name="subAreaID"
                                            defaultData={defValue ? defValue?.subArea : null}
                                            searchable={true}
                                            setSeacrch={setSearchFilter}
                                            isLoading={isLoadingFilter}
                                            filters={{
                                                reqApi: "searchableSubAreasList",
                                                storeKeyName: "subAreaList",
                                            }}
                                            isMulti={false}
                                        />
                                    </div>
                                    {<Form.Label className='text-muted'>Select Vehicle</Form.Label>}
                                    <div className='mb-3' style={{ position: "relative" }}>
                                        <AdminSingleSelectFilter
                                            onChangeHandler={onChangeHandler}
                                            HeaderTitle="Select Vehicle"
                                            SelectData={vehicleList}
                                            displayFields={["vehicle_type"]}
                                            id="id"
                                            name="ownVehicle"
                                            defaultData={vehicleData ? {
                                                item: { label: vehicleData?.vehicle_type, value: vehicleData?.id }
                                            } : null}
                                            searchable={false}
                                        />
                                    </div>
                                    {<Form.Label className='text-muted'>Free Form Location</Form.Label>}
                                    <div className='mb-3' style={{ position: "relative" }}>
                                        <AdminTextBoxReuse
                                            onChangeHandler={onChangeHandler}
                                            HeaderTitle="Free Form Location"
                                            payload={payload}
                                            name="address"
                                            inputType="text"
                                            label=""
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3 p-2 d-flex justify-content-end" >
                                    {
                                        <Button type='submit' variant={isBulkAssignApiLoading ? "success" : "primary"} >
                                            {isBulkAssignApiLoading ? "Submitting..." : "Submit"}
                                        </Button>
                                    }
                                </Form.Group>
                            </Form>
                    }



                </Modal.Body>
            </Modal >
        </>
    )
}
export default MarkChampAsLead
