
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import useOwnMoinState from '../RewardAndPenaltiesList/useOwnMoinState';
import { initialPayload } from '../../consts/DynamicFilteration/components/CommonData';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { KTCardBody } from '../../../_metronic/helpers';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { renderToString } from 'react-dom/server';
import Handsontable from 'handsontable';

registerAllModules();

export const FreezeTables = () => {
  const [pending, setPending] = useState(false);
  const [columns, setColumns] = useState([]);
  const [payload, setPayload] = useOwnMoinState(initialPayload(false, {}));
  const [filterData, setFilterData] = useState<any>([]);
  const [mainData, setMainData] = useState([]);

  async function fetchData(payloads:any, setLoading:any) {
    setLoading(true);
    const { data } = await postRequest(URL_EndPoints(null)?.getChampHiringDashboardData, payloads, setLoading);
    setFilterData(data?.data);
    setMainData(data?.data);
    setColumns(data?.columns);
    setLoading(false);
  }

  const handlePagination = (search = "", sortBy = "", sortDirection = "desc", page = 1, perPageSize = 10) => {
    const updatedPayload = {
      ...payload,
      search,
      start: page,
      length: perPageSize,
      sortby: sortBy,
      orders: sortDirection,
      direction: sortDirection
    };
    setPayload(updatedPayload);
    return updatedPayload;
  }

  useEffect(() => {
    fetchData(handlePagination("", "", "desc", 1, 10), setPending);
  }, []);

  const hotTableComponentRef = useRef<any>(null);

  const searchFieldKeyupCallback = (event: any) => {
    const searchTerm = event.target.value;
    const filteredData = mainData.filter(row => {
      // Adjust this logic based on your data structure and search requirements
      return Object.values(row).some((val: any) => 
        // value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        {
          if (typeof (val) == typeof ("str") && val !== "") {
            if (val.toLowerCase().includes(searchTerm.toLowerCase())) {
                return row
            }
        }
        if (typeof (val) == typeof (6)) {
            let newStr = val.toString()
            if (typeof (newStr) == typeof ("str") && newStr !== "") {
                if (newStr.includes(searchTerm)) {
                    return row
                }
            }
        }
        }
        
       );
    });
    setFilterData(filteredData);
  };

  // const shortSente = (sentence:any, index:any) => {
  //   return index <3 ? sentence?.split("_")?.join(" ")?.toUpperCase() :  sentence?.split('_')?.map((word:any) => word[0]).join('_');
  // }

  const shortSente = (sentence: any, index:any) => {
    if(index < 3){
      return sentence?.split("_")?.join(" ")?.toUpperCase()
    }

    
    const words = sentence?.split('_');
    const ShortWord = words?.map((word: any) => {
      let res = word?.split(" ")
      let shortWordsConersation: any = []
      res.forEach((ele: any, index: any) => {
        shortWordsConersation.push(ele[0])
      })

      if (word) {
        return shortWordsConersation
      }
    }).join("_")

    return ShortWord

  }
  const arrayWithFifty = Array.from({ length: columns?.length }, (_, index) => index < 3 ? 100 : 50);
const totalCount = filterData?.reduce((total:any, obj:any) => total + obj.positions_required, 0);

  useEffect(() => {
    const hotInstance = hotTableComponentRef.current.hotInstance;
    hotInstance.getPlugin('multiColumnSorting').sort({ column: 0, sortOrder: 'asc' });

    hotInstance.getPlugin('multiColumnSorting').sort([
      { column: 1, sortOrder: 'asc' },
      { column: 0, sortOrder: 'desc' },
    ]);
  }, []);
  
  const cellRenderer = (...args: any) => {
    const [instance, td, row, col, prop, value, cellProperties] = args;
    Handsontable.renderers.TextRenderer.apply(this, args); 
    if (value > 0) {
      td.style.backgroundColor = '#e8f0fe';
      // td.style.color = 'black';
    }
  };
  return (
<>
    <KTCardBody>
      {pending && <IsLoading />}
   {
       !pending &&  <div className="controls">
       <input id="search_field" type="search" placeholder="Search" onKeyUp={searchFieldKeyupCallback} className='form form-control mb-2 w-25' />
     </div>
   }
   {
 <div className="controls p-2 fw-bold">
     Total requirements : {totalCount || 0}
     </div>
   }
      <HotTable
        ref={hotTableComponentRef}
        style={{zIndex:1}}
        data={filterData}
        columns={columns?.map((ele:any, index:any) => {
          return {
            title: renderToString(<div id={ele} >{shortSente(ele?.split("_")?.join(" ")?.toUpperCase(), index)} </div>),
            type: 'text',
            data: ele,
            renderer: cellRenderer
          }
        })}
        filters={true}
        dropdownMenu={true}
        colWidths={arrayWithFifty}
        width="100%"
        height={"80vh"}
        rowHeaders={true}
        colHeaders={true}
        fixedColumnsLeft={2}
        contextMenu={true}
        manualColumnFreeze={true}
        autoWrapRow={true}
        autoWrapCol={true}
        licenseKey="non-commercial-and-evaluation"
        autoRowSize={true}
        autoColumnSize={true}
        readOnly={true}
        manualRowResize={true}
        manualColumnResize={true}
        manualRowMove={true}
        multiColumnSorting={true}
        search={{
          callback: searchFieldKeyupCallback
        }}
      />
    </KTCardBody>
    {columns?.map((ele: any) => (
    <ReactTooltip
    anchorId={ele}
    place={"top"}
    className="bg-dark"
    content={ele?.split("_")?.join(" ")?.toUpperCase()}
  />
  ))}

</>
  );
};

















// import { useEffect, useRef, useState } from 'react';
// import { HotTable } from '@handsontable/react';
// import { registerAllModules } from 'handsontable/registry';
// import 'handsontable/dist/handsontable.full.min.css';
// import { HotCoumns } from './HoColumns';
// import useOwnMoinState from '../RewardAndPenaltiesList/useOwnMoinState';
// import { initialPayload } from '../../consts/DynamicFilteration/components/CommonData';
// import { postRequest } from '../../consts/Server/Requests';
// import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
// import IsLoading from '../../consts/IsLoading/IsLoading';
// // register Handsontable's modules
// import "react-tooltip/dist/react-tooltip.css";
// import { Tooltip as ReactTooltip } from "react-tooltip";


// registerAllModules();

// export const FreezeTables = () => {


//   const [pending, setPending] = useState<any>(false)
//   const [columns, setColumns] = useState([])


//   const [payload, setPayload] = useOwnMoinState(initialPayload(false, {}))
//   const [filterData, setfilterData] = useState<any>([])
//   const [mainData, setMainData] = useState<any>([])


//   async function InvokedRendered(payloads: any, setloading: any) {
//     setloading(true)
//     const { data } = await postRequest(URL_EndPoints(null)?.getChampHiringDashboardData, payload, setloading)
//     setfilterData(data?.data)
//     setMainData(data?.data)
//     setColumns(data?.columns)
//   }



//   const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {

//     payload.search = search
//     payload.start = page
//     payload.length = perPageSize
//     payload.sortby = sortBy
//     payload.orders = sortDirection
//     payload.direction = sortDirection
//     setPayload(payload)
//     return payload

//   }



//   useEffect(() => {
//     InvokedRendered(handlePagination("", "", "desc", 1, 10), setPending);
//   }, []);















//   const hotTableComponentRef: any = useRef(null);

//   const filterBelow200 = () => {
//     // get the `Filters` plugin, so you can use its API
//     const filters = hotTableComponentRef.current.hotInstance.getPlugin('filters');

//     // clear any existing filters
//     filters.clearConditions();
//     // filter data by the 'Price' column (column at index 2)
//     // to display only items that are less than ('lt') $200
//     filters.addCondition(2, 'lt', [200]);
//     filters.filter();
//   };

//   const filterAbove200 = () => {
//     // get the `Filters` plugin, so you can use its API
//     const filters = hotTableComponentRef.current.hotInstance.getPlugin('filters');

//     filters.clearConditions();
//     // display only items that are more than ('gt') $200
//     filters.addCondition(2, 'gt', [200]);
//     filters.filter();
//   };

//   const clearAllFilters = () => {
//     // get the `Filters` plugin, so you can use its API
//     const filters = hotTableComponentRef.current.hotInstance.getPlugin('filters');

//     // clear all filters
//     filters.clearConditions();
//     filters.filter();
//   };

//   useEffect(() => {
//     const hotInstance = hotTableComponentRef.current.hotInstance;

//     // Sort ascending the first visual column
//     hotInstance.getPlugin('multiColumnSorting').sort({ column: 0, sortOrder: 'asc' });

//     // Sort the first two visual columns in the defined sequence
//     hotInstance.getPlugin('multiColumnSorting').sort([
//       { column: 1, sortOrder: 'asc' },
//       { column: 0, sortOrder: 'desc' },
//     ]);
//   }, []);







//   const [output, setOutput] = useState('');


//   let searchResultCount = 0;
//   let searchFieldKeyupCallback: any;

//   //  define your custom callback function
//   function searchResultCounter(this: any, instance: any, row: any, col: any, value: any, result: any) {
//     const DEFAULT_CALLBACK = function (instance: any, row: any, col: any, data: any, testResult: any) {
//       instance.getCellMeta(row, col).isSearchResult = testResult;
//     } as any;

//     DEFAULT_CALLBACK.apply(this, arguments)

//     if (result) {
//       searchResultCount++;
//     }
//   }



//   useEffect(() => {
//     const hot4 = hotTableComponentRef.current.hotInstance;

//     searchFieldKeyupCallback = function (event: any) {
//       searchResultCount = 0;

//       const search = hot4.getPlugin('search');
//       const queryResult = search.query(event.target.value);

//       setOutput(`${searchResultCount} results`);
//       // if (event.target.value) {
//       //   setfilterData(queryResult);
//       // }
//       // else {
//       //   setfilterData(mainData);

//       // }
//       hot4.render();
//     };
//   });

//   const shortSente = (sentence: any) => {

//     const words = sentence?.split('_');
//     const ShortWord = words?.map((word: any) => {
//       let res = word?.split(" ")
//       let shortWordsConersation: any = []
//       res.forEach((ele: any, index: any) => {
//         shortWordsConersation.push(ele[0])
//       })

//       if (word) {
//         return shortWordsConersation
//       }
//     }).join("_")

//     return ShortWord

//   }


//   const arrayWithFifty :any= Array.from({ length: columns?.length }).fill(100);
//   return (
//     <>

//       <div className="controls">
//         <input id="search_field4" type="search" placeholder="Search" onKeyUp={(...args) => searchFieldKeyupCallback(...args)} />
//       </div>
//       <output className="console" id="output">{output}</output>

//       <HotTable
//         ref={hotTableComponentRef}
//         data={filterData}
//         columns={
//           columns?.map((ele: any, index: any) => ({
//           title: shortSente(ele?.split("_")?.join(" ")?.toUpperCase()) ,
//           type: 'text',
//           data: ele,
        




//         }))
        

//           // id: ele?.id,
//           // reorder: true,
//           // // name: ele?.split("_")?.join(" ")?.toUpperCase(),
//           // name: <>
//           //   <span id={ele}>{shortSente(ele?.split("_")?.join(" ")?.toUpperCase())}</span>
//           //   <ReactTooltip
//           //     anchorId={ele}
//           //     place={"top"}
//           //     className="bg-dark"
//           //     content={ele?.split("_")?.join(" ")?.toUpperCase()}
//           //   />


//           // </>,
//           // // name: shortSente(ele?.split("_")?.join(" ")?.toUpperCase()),
//           // selector: (row: any) => row[ele] ?? "NA",
//           // sortable: true,
//           // sortField: ele,
//           // minWidth: index > 2 || index == 0 ? "60px" : "150px",
//         }
//         filters={true}
//         dropdownMenu={true}
//         colWidths={arrayWithFifty}
//         // colWidths={[50, 150, 100, 120, 180, 130, 160, 140, 170, 110, 190, 210, 250, 230, 220, 200, 240, 270, 260, 280, 320]}
//         width="100%"
//         height={"80vh"}
//         rowHeaders={true}
//         colHeaders={true}
//         // fixedColumnsStart={2}
//         fixedColumnsLeft={2}
//         contextMenu={true}
//         manualColumnFreeze={true}
//         autoWrapRow={true}
//         autoWrapCol={true}
//         licenseKey="non-commercial-and-evaluation"
//         autoRowSize={true}
//         autoColumnSize={true}
//         readOnly={true}
//         manualRowResize={true}
//         manualColumnResize={true}
//         manualRowMove={true}
//         multiColumnSorting={true}
//         // search={true}
//         search={{
//           // add your custom callback function
//           callback: searchResultCounter
//         }}

//       />

//       {/* 
//       <div className="controls">
//         <button onClick={filterBelow200}>Show items &lt; $200</button>
//         <br />
//         <br />
//         <button onClick={filterAbove200}>Show items &gt; $200</button>
//         <br />
//         <br />
//         <button onClick={clearAllFilters}>Clear filters</button>
//       </div> */}
//     </>
//   );
// };




