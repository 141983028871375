
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';

const UpdateBankDetails = ({ show, setShow, initialData, champsDetails, getChampBankDetails }: any) => {
    const [apiLoader, setApiLoader] = useState<any>(false)
    const [payload, setPayload] = useState<any>({
        "champId": champsDetails?.id,
        "account_type": "",
        "upi_id": "",
        "holder_name": "",
        "account_no": "",
        "ifsc": ""
    })

    useEffect(() => {
        payload["account_type"] = initialData["account_type"] ?? ""
        payload["upi_id"] = initialData["upi_id"] ?? ""
        payload["holder_name"] = initialData["holder_name"] ?? ""
        payload["account_no"] = initialData["account_no"] ?? ""
        payload["ifsc"] = initialData["ifsc"] ?? ""
        setPayload({ ...payload })
    }, [
        initialData?.account_type,
        initialData?.upi_id,
        initialData?.holder_name,
        initialData?.account_no,
        initialData?.ifsc,
    ])

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setApiLoader(true)
        const response = await postRequest(URL_EndPoints()?.updateChampBankDetails, payload, setApiLoader)
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
        getChampBankDetails(-1)
        setShow(false)
    }



    return (
        <Modal size="lg" show={show} onHide={() => setShow(false)} >
            <Modal.Header closeButton>
                <Modal.Title>Update Bank Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    <>
                        <Form onSubmit={handleSubmit}>

                            <Form.Group className="mb-3" controlId="cleaner">
                                <div className='mb-2'>
                                    <Form.Label>Account Type</Form.Label>
                                    <select
                                        name='date_typer'
                                        aria-label='Select a Timezone'
                                        data-control='select2'
                                        data-placeholder='Period'
                                        className='form-select'
                                        id='account_type'
                                        value={payload?.account_type}
                                        defaultValue={initialData?.account_type}
                                        onChange={(event) => setPayload({ ...payload, [event?.target.id]: event?.target?.value })}
                                    >
                                        < option className='text-muted' value={""}>  Select Account Type</option>
                                        {
                                            ["upi", "bank"]?.map((ele: any) => {
                                                return (
                                                    <>
                                                        < option value={ele} className='text-muted'>{ele?.toUpperCase()}</option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                </div>


                                <div className='mb-2'>
                                    <Form.Label>Account Holder Name</Form.Label>
                                    <input
                                        placeholder='Account Holder Name'
                                        type='text'
                                        className='form-control mb-lg-0'
                                        value={payload?.holder_name}
                                        name="holder_name"
                                        onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                    />
                                </div>

                                <div className='mb-2'>
                                    <Form.Label>Account Number</Form.Label>
                                    <input
                                        placeholder='Account Number'
                                        type='number'
                                        className='form-control mb-lg-0'
                                        value={payload?.account_no}
                                        name="account_no"
                                        onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                    />
                                </div>

                                <div className='mb-2'>
                                    <Form.Label>IFSC Code</Form.Label>
                                    <input
                                        placeholder='IFSC Code'
                                        type='text'
                                        className='form-control mb-lg-0'
                                        value={payload?.ifsc}
                                        name="ifsc"
                                        onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                    />
                                </div>

                                <div className='mb-4'>
                                    <Form.Label>UPI ID</Form.Label>
                                    <input
                                        placeholder='UPI ID'
                                        type='text'
                                        className='form-control mb-lg-0'
                                        value={payload?.upi_id}
                                        name="upi_id"
                                        onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                    />
                                </div>

                            </Form.Group>


                            <div className='d-flex justify-content-end'>
                                <Button variant="primary" size='sm' type="submit" disabled={apiLoader}>
                                    {apiLoader ? "Updating..." : "Update"}
                                </Button>
                            </div>
                        </Form>
                    </>}
            </Modal.Body>
        </Modal>

    )
}

export default UpdateBankDetails



