import axios from 'axios'
import uuid from 'react-uuid'
import {URL_EndPoints} from '../../Server/URL_EndPoints'
import {postRequest} from '../../Server/Requests'

const operators = [
  {
    name: 'AND',
    id: 'AND',
  },
  {
    name: 'OR',
    id: 'OR',
  },
]

const removeFilterHandler = (
  index: any,
  key: any,
  setPayload: any,
  payload: any,
  setSelectedFilterData: any,
  selectedFilterData: any
) => {
  let updatedData = selectedFilterData?.filter((ele: any, ind: number) => ind != index)
  delete payload?.filterData[key]
  let nextJunction = selectedFilterData[index + 1]
  if (index == 0 && nextJunction?.key) {
    delete payload?.filterData[nextJunction?.key]?.junction
  }
  setSelectedFilterData(updatedData)
  setPayload(payload)
}

const getCurrentFilterHandler = async (
  key: any,
  currentItemRemovedOrAdded: any,
  setFilterKey: any,
  setSelectedFilterData: any,
  selectedFilterData: any,
  setStoreFilterList: any,
  storeFilterList: any,
  payload: any,
  setPayload: any
) => {
  if ((key != null && !key?.smartFilter) || (key && !key?.smartFilter)) {
    if (key?.item?.operator?.length > 0) {
      let isSmartFilterApplied = {}

      let smartFilterApplied = {
        [key.item.key]: {
          ...payload.filterData[key.item.key],
          ['operator']: key?.item?.operator[0]?.subKey,
        },
      }
      let dynamicFilterApplied = {
        ...payload.filterData,
        [key.item.key]: {
          ...payload.filterData[key.item.key],
          ['operator']: key?.item?.operator[0]?.subKey,
        },
      }

      if (payload?.isSmartFilter) {
        if (key?.exernalFilterIsChecked == true) {
          isSmartFilterApplied = dynamicFilterApplied
        } else {
          isSmartFilterApplied = smartFilterApplied
        }
      } else {
        isSmartFilterApplied = dynamicFilterApplied
      }

      setPayload((payload: any) => ({
        ...payload,
        ['isSmartFilter']: false,
        ['filterData']: isSmartFilterApplied,
      }))
    }

    currentItemRemovedOrAdded?.action == 'select-option' &&
      setFilterKey({key: key?.item?.key, value: 'filter is added'})
    if (selectedFilterData?.length == 0) {
      key.item.position = false
    } else {
      key.item.position = true
    }

    const alreadyPresent = selectedFilterData?.filter((ele: any) => ele?.key == key?.value)
    if (payload?.isSmartFilter) {
      if (key?.exernalFilterIsChecked == true) {
        if (alreadyPresent?.length == 0) {
          key.item.uuidReact = uuid()
          setSelectedFilterData([...selectedFilterData, key?.item])
        }
      } else {
        setSelectedFilterData([key?.item])
      }
    } else {
      if (alreadyPresent?.length == 0) {
        key.item.uuidReact = uuid()
        setSelectedFilterData([...selectedFilterData, key?.item])
      }
    }

    let reqMethod = key?.item?.valueConfig?.apiConfig?.method
    let reqApi = key?.item?.valueConfig?.callApi ? key?.item?.valueConfig?.apiConfig?.APIurl : null
    let reqPayload = key?.item?.valueConfig?.apiConfig?.requestData
    let reqFrom = key?.item?.key
    if (reqApi) {
      const result: any = (await axios[reqMethod == 'POST' ? 'post' : 'get']?.(
        `${reqApi}`,
        reqPayload
      )) as any

      setStoreFilterList({...storeFilterList, [reqFrom]: result?.data?.data})
    }
  } else {
    if (key != null && key?.smartFilter) {
      if (key?.item?.length > 0) {
        setSelectedFilterData(key?.item)
      }

      if (key?.value) {
        setStoreFilterList({...storeFilterList, ...key?.value})
      }

      if (key?.filter_obj) {
        let newPaylod = {
          ...payload,
          ['isSmartFilter']: true,
          ['filterData']: key?.filter_obj,
        }

        setPayload(newPaylod)
      }
    }
    if (key == null) {
      setPayload(initialPayload(false, {}))
    }
  }
}

const getFilterDataBySearch = async (
  key: any,
  setStoreFilterList: any,
  storeFilterList: any,
  payload: any
) => {
  let reqMethod = key?.valueConfig?.apiConfig?.method
  let reqApi = key?.valueConfig?.callApi ? key?.valueConfig?.apiConfig?.APIurl : null
  let reqFrom = key?.key
  if (reqApi) {
    const result: any = (await axios[reqMethod == 'POST' ? 'post' : 'get']?.(
      `${reqApi}`,
      payload
    )) as any

    let newData = result?.data?.data?.map((ele: any) => {
      ele.first_name = ele?.first_name ?? 'NA'
      ele.last_name = ele?.last_name ?? 'NA'
      ele.phone = ele?.phone ?? 'NA'
      return ele
    })
    setStoreFilterList((storeFilterList: any) => ({...storeFilterList, [reqFrom]: newData}))
  }
}

const getFilterDataBySearchClientSide = async (
  key: any,
  setStoreFilterList: any,
  storeFilterList: any,
  setIsLoadingFilter: any,
  payload: any
) => {
  let reqApi = key?.reqApi ? key?.reqApi : null
  let storeKeyName = key?.storeKeyName
  if (reqApi) {
    const result = await postRequest(URL_EndPoints()?.[reqApi], payload, setIsLoadingFilter)
    let newData = result?.data?.data?.map((ele: any) => {
      ele.first_name = ele?.first_name ?? 'NA'
      ele.last_name = ele?.last_name ?? 'NA'
      ele.phone = ele?.phone ?? 'NA'
      return ele
    })
    setStoreFilterList({...storeFilterList, [storeKeyName]: newData})
  }
}

const initialPayload = (isDefault: any = false, defaultFilter: any = {}) => {
  let payload = {
    start: 1,
    length: 10,
    search: '',
    orders: 'desc',
    columns: 'id',
    sortby: '',
    direction: 'desc',
    searchType: 'any',
    filterData: {},
    supervisorid: 489,
  }

  if (isDefault) {
    payload = {...payload, filterData: {...payload?.filterData, ...defaultFilter}}
  }

  return payload
}

const presentKey = ['range']

export const EASEBUZZ_PAYMENT_STATUS = [
  {
    id: 'accepted',
    name: 'Accepted',
    description: 'Transfer Request is sent to the bank.',
  },
  {
    id: 'in_process',
    name: 'In Process',
    description: 'Bank is processing your transfer request.',
  },
  {
    id: 'success',
    name: 'Success',
    description: 'Funds are successfully credited to the beneficiary account.',
  },
  {
    id: 'failure',
    name: 'Failure',
    description: 'Transfer Request could not be processed due to any reason.',
  },
  {
    id: 'pending',
    name: 'Pending',
    description: 'Transfer Request is accepted by easebuzz but yet not sent to the bank.',
  },
  {
    id: 'rejected',
    name: 'Rejected',
    description: 'Transfer Request is rejected by the beneficiary bank.',
  },
  {
    id: 'reversed',
    name: 'Reversed',
    description:
      'Transfer Request was successful but the beneficiary bank made a reverse entry to your account.',
  },
]

export {
  operators,
  removeFilterHandler,
  getCurrentFilterHandler,
  initialPayload,
  presentKey,
  getFilterDataBySearch,
  getFilterDataBySearchClientSide,
}
