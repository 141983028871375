export const columns = (handleViewButtonClick?: any, data?: any,) => [


  {
    name: "Warehouse Name",
    cell: (row: any) => row?.warehouseId?.warehouse_name,
    sortField: 'warehouseId.warehouse_name',
    sortable: true,
    reorder: true,
    id: 4,
    width: "200px"
  },
  {
    name: "Item Name",
    cell: (row: any) => row?.warehouseInventoryItem?.title,
    sortField: 'warehouseInventoryItem.title',
    sortable: true,
    reorder: true,
    id: 5,
    width: "200px"
  },
  {
    name: 'Reminder Stock',
    cell: (row: any) => row?.reminder_stock,
    sortField: 'reminder_stock',
    sortable: true,
    reorder: true,
    id: 6,
    width: '120px',
  },
  {
    name: 'Alert Stock',
    cell: (row: any) => row?.alert_stock,
    sortField: 'alert_stock',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Status',
    cell: (row: any) => (
      <span className={`badge badge-light-${row?.status == 1 ? 'success' : 'danger'}`}>
        {row?.status == 1 ? 'Active' : 'Inactive'}
      </span>
    ),
    sortField: 'status',
    sortable: true,
    reorder: true,
    id: 3,
    width: '150px',
  },

]
