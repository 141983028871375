import React, { useEffect, useState } from 'react'
import Not_Found_Data from '../../Not_Found_Data'
import uuid from 'react-uuid';

import { VehicleInfoBody } from './VehicleInfoBody'
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { postRequest } from '../../Server/Requests';
const VehicleInfo = ({ VehicleInfo }: any) => {
  const [vehicleCategory, setVehicleCategory] = useState<any>([])
  const [vehicleModels, setVehicleModels] = useState<any>([])
  const [vehicleBrands, setVehicleBrands] = useState<any>([])
  const [vehicleTypes, setVehicleTypes] = useState<any>([])
  const [colorsType, setColorsType] = useState<any>([])


  const getOtherData = async () => {
    await Promise.allSettled([
      postRequest(URL_EndPoints()?.getVehicleModels, {}, null),
      postRequest(URL_EndPoints()?.getVehicleBrands, {}, null),
      postRequest(URL_EndPoints()?.getVehicleCategories, {}, null),
      postRequest(URL_EndPoints()?.getVehicleTypes, {}, null),
      postRequest(URL_EndPoints()?.getVehicleColors, {}, null)
    ]).then(([getVehicleModels, getVehicleBrands, getVehicleCategories, getVehicleTypes, getVehicleColors]: any) => {
      setVehicleModels(getVehicleModels?.value?.data?.data)
      setVehicleBrands(getVehicleBrands?.value?.data?.data)
      setVehicleCategory(getVehicleCategories?.value?.data?.data)
      setVehicleTypes(getVehicleTypes?.value?.data?.data)
      setColorsType(getVehicleColors?.value?.data?.data)
    })
    // setLoaderApi(false)
  }

  useEffect(() => {
    // setLoaderApi(true)
    getOtherData()
  }, [])

  return (
    <>
      <div className='mb-5 mr-4 px-4 mt-3 p-3' key={uuid()}>
        <div className='container px-1'>
          <div className='row '>
            {VehicleInfo?.length > 0 ? (
              VehicleInfo?.map((element: any, index: any) => {
                return (
                  <div className=' col mb-3 me-2 h-100' key={uuid()}>
                    <VehicleInfoBody
                      className='card-xl-stretch mb-xl-8'
                      image='abstract-4.svg'
                      title={element.vehicleBrand?.name}
                      vehicleData={element}
                      description='Create a headline that is informative<br/>and will capture readers'
                      formData={{
                        vehicleCategory,
                        vehicleModels,
                        vehicleBrands,
                        vehicleTypes,
                        colorsType,
                      }}
                    />
                  </div>
                )
              })
            ) : (
              <Not_Found_Data headerTitle={'No vehicleData Found'}></Not_Found_Data>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default VehicleInfo