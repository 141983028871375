const initState = {
    refreshTable: 0,

}
export const DailyJobDashboard = (state = initState, { type, payload }: any) => {

    switch (type) {
        case "REFRESH_TABLE":
            return { ...state, refreshTable: payload }
        default:
            return state
    }
}


