import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getRequest, postRequest, } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminQueryGenerator, adminToastRunner } from '../../admins/adminUtils';
import AdminSelectSearchable from '../../admins/adminForms/adminFormComponents/AdminSelectSearchable';
import AdminSelectCheckbox from '../../admins/adminForms/adminFormComponents/AdminSelectCheckbox';

const AddCommonLeadsStatusConfig = ({ show, refreshNow, setShow, initialValue, setInitialValue, apiUrl, defaultValue, setDefaultValue, originalValues }: any) => {
    const [loading, setLoading] = useState(false);
    const [customerStatusListData, setcustomerStatusListData] = useState([]);
    const [customerSubStatusListData, setCustomerSubStatusListData] = useState([]);
    const [champStatusListData, setChampStatusListData] = useState([]);
    const [champSubStatusListData, setChampSubStatusListData] = useState([]);
    const [statusGroupListData, setStatusGroupListData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [cxStatus, cxSubStatus, chStatus, chSubStatus, statusGroup] = await Promise.all([
                    getRequest(URL_EndPoints(initialValue?.userType == "all" ? null : adminQueryGenerator("getMasterStatuses", { category_id: 0 }))?.getMasterStatuses, null),
                    getRequest(URL_EndPoints(initialValue?.userType == "all" ? null : adminQueryGenerator("getMasterSubStatuses", { category_id: 0 }))?.getMasterSubStatuses, null),
                    getRequest(URL_EndPoints(initialValue?.userType == "all" ? null : adminQueryGenerator("getMasterStatuses", { category_id: 7 }))?.getMasterStatuses, null),
                    getRequest(URL_EndPoints(initialValue?.userType == "all" ? null : adminQueryGenerator("getMasterSubStatuses", { category_id: 1 }))?.getMasterSubStatuses, null),
                    getRequest(URL_EndPoints()?.getMasterStatusGroups, null),
                ]);

                setcustomerStatusListData(cxStatus?.data?.data || []);
                setCustomerSubStatusListData(cxSubStatus?.data?.data || []);
                setChampStatusListData(chStatus?.data?.data || []);
                setChampSubStatusListData(chSubStatus?.data?.data || []);
                setStatusGroupListData(statusGroup?.data?.data || []);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                // setStatusLoader(false);
            }
        };

        if (show) {
            fetchData();
        }

    }, [show, initialValue?.userType]);

    const onChangeHandler: any = (event: any, name: any, isDate?: any) => {
        setInitialValue({
            ...initialValue,
            [name]: event ? event?.value : event,
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        
        const { editForm, userType, id, ...newPayloadValue } = initialValue

        let changedPayload;
        if (id) {
            changedPayload = Object.keys(newPayloadValue).reduce((acc: any, key: any) => {
                if (newPayloadValue[key] !== originalValues[key]) {
                    acc[key] = newPayloadValue[key];
                }
                return acc;
            }, {});
        }
        else {
            changedPayload = newPayloadValue
        }
        
        if(id){
            changedPayload.id = id
        }

        const response = await postRequest(URL_EndPoints()?.[apiUrl], changedPayload, setLoading)
        adminToastRunner(response)
        refreshNow && refreshNow();
        // if(response?.data?.status == 200){
        //     setShow && setShow(false);
        //    }
        setDefaultValue && setDefaultValue(null)
    };


    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Common Leads Status Config</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">

                            <Form.Group as={Col} className="mb-3" controlId="customer_status_group_id">
                                <Form.Label>< >Select customer Status Group</></Form.Label>
                                <AdminSelectSearchable
                                    HeaderTitle='Select customer Status Group'
                                    onChangeHandler={onChangeHandler}
                                    selectData={statusGroupListData}
                                    displayFields={["name"]}
                                    id="id"
                                    name="customer_status_group_id"
                                    defaultData={defaultValue ? defaultValue?.customerStatusGroup : null}
                                    searchable={false}
                                    setSeacrch={null}
                                    isLoading={null}
                                    isMulti={false}
                                    isDisabled={initialValue?.editForm ? true : false}
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="champ_status_group_id">
                                <Form.Label>< >Select Champ Status Group</></Form.Label>
                                <AdminSelectSearchable
                                    HeaderTitle='Select Champ Status Group'
                                    onChangeHandler={onChangeHandler}
                                    selectData={statusGroupListData}
                                    displayFields={["name"]}
                                    id="id"
                                    name="champ_status_group_id"
                                    defaultData={defaultValue ? defaultValue?.champStatusGroup : null}
                                    searchable={false}
                                    setSeacrch={null}
                                    isLoading={null}
                                    isMulti={false}
                                    isDisabled={initialValue?.editForm ? true : false}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="customer_status_id">
                                <Form.Label>< >Select Customer Status</></Form.Label>
                                <AdminSelectSearchable
                                    HeaderTitle='Select Customer Status'
                                    onChangeHandler={onChangeHandler}
                                    selectData={customerStatusListData}
                                    displayFields={["name"]}
                                    id="id"
                                    name="customer_status_id"
                                    defaultData={defaultValue ? defaultValue?.customerStatus : null}
                                    searchable={false}
                                    setSeacrch={null}
                                    isLoading={null}
                                    isMulti={false}
                                />
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="customer_sub_status_id">
                                <Form.Label>< >Select Customer Sub Status</></Form.Label>
                                <AdminSelectSearchable
                                    HeaderTitle='Select Customer Sub Status'
                                    onChangeHandler={onChangeHandler}
                                    selectData={customerSubStatusListData}
                                    displayFields={["name"]}
                                    id="id"
                                    name="customer_sub_status_id"
                                    defaultData={defaultValue ? defaultValue?.customerSubStatus : null}
                                    searchable={false}
                                    setSeacrch={null}
                                    isLoading={null}
                                    isMulti={false}
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="champ_status_id">
                                <Form.Label>< >Select Champ Status</></Form.Label>
                                <AdminSelectSearchable
                                    HeaderTitle='Select Champ Status'
                                    onChangeHandler={onChangeHandler}
                                    selectData={champStatusListData}
                                    displayFields={["name"]}
                                    id="id"
                                    name="champ_status_id"
                                    defaultData={defaultValue ? defaultValue?.champStatus : null}
                                    searchable={false}
                                    setSeacrch={null}
                                    isLoading={null}
                                    isMulti={false}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="champ_sub_status_id">
                                <Form.Label>< >Select Champ Sub Status</></Form.Label>
                                <AdminSelectSearchable
                                    HeaderTitle='Select Champ Sub Status'
                                    onChangeHandler={onChangeHandler}
                                    selectData={champSubStatusListData}
                                    displayFields={["name"]}
                                    id="id"
                                    name="champ_sub_status_id"
                                    defaultData={defaultValue ? defaultValue?.champSubStatus : null}
                                    searchable={false}
                                    setSeacrch={null}
                                    isLoading={null}
                                    isMulti={false}
                                />
                            </Form.Group>

                        </Row>

                        <Form.Group as={Col} className="mb-3" controlId="status_group_id">
                            <Form.Label>< >Common Lead Flag</></Form.Label>
                            <AdminSelectCheckbox
                                onChangeHandler={onChangeHandler}
                                HeaderTitle="Common Lead Flag"
                                payload={initialValue}
                                name="isCommonCheck"
                                label=""
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddCommonLeadsStatusConfig;
