import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Modal } from 'react-bootstrap'
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { getRequest, postRequest, postRequestWithFile } from '../Server/Requests';
import InputSearchBox from '../TagInputs/InputSearchBox';
import "./styles.css"

const ImportFacebookLeadsForm = ({ handleCloseForm, show, row, prevData }: any) => {
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [file, setFile] = useState<any>(null);
    const [fileSelected, setFileSelected] = useState<boolean>(false);

    const [payload, setPayload] = useState<any>({
        file: file,
    })

    // useEffect(() => {
    //     payload?.phone?.length >= 10 && setshowErrorMessage(false)
    // }, [payload?.phone])
    // const getData = async () => {
    //     const result = await getRequest(URL_EndPoints(null)?.getTicketSources, null)
    //     setSourceArray(result?.data?.data)

    //     const { data } = await getRequest(URL_EndPoints(null)?.getsociety, null)
    //     setSocietyArray(data.data)
    // }

    // useEffect(() => {
    //     getData()

    // }, [])

    // const handleChangeInputData = (event: any, name: any) => {
    //     if (event == null || !event) {
    //         setPayload({ ...payload, [name]: null })
    //     }
    //     else {
    //         setPayload({ ...payload, [name]: event?.value })
    //     }
    // }

    const handleClose = () => {
        handleCloseForm()
    }

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setFileSelected(true);
        setFile(e?.dataTransfer?.files[0]);
    };
    
    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileSelected(true);
        const name = event?.target?.files?.[0]?.name
        if (name?.endsWith('.xlsx') || name?.endsWith('.xls')) {
            setFile(event?.target?.files?.[0]);
        }
        else {
            toast.error("Only Excel sheet is allowed ", { position: "top-center" })
        }

    };

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const importFacebookLeadsHandler = async () => {
        payload.file = file
        
        setLoader(true)
        const response = await postRequestWithFile(URL_EndPoints()?.readXLSXFileAndCreateFBLead, payload, null)
        if (response.status == 200) {
            toast(response?.data?.message, { position: "top-center" })
            handleCloseForm()
        }else {
            toast(response?.data?.message, { position: "top-center" })
        }
        setLoader(false)
    }

    return (
        <>
            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal-small"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='fs-5 text-muted'>Import Facebook Leads</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Form.Group className="mb-3" onDragOver={handleDragOver} onDrop={handleDrop}>
                            <input type="file" key={12121} name="imag2" id="imag2" onChange={onChangeFile} style={{ display: 'none' }} />

                            <label htmlFor="imag2" style={{ width: "100%", height: "90%" }}>
                                <div className=" col-12 ">
                                    <div className="dz-default dz-message text-center d-flex flex-column g-2 dropzone dz-clickable border rounded bg-light p-3">
                                        <i className="bi bi-cloud-arrow-up mb-4" style={{ fontSize: "5rem" }}></i>
                                        <span className='fw-bold fs-3 text-muted'>Drop files here or click to upload...</span>
                                    </div>
                                </div>
                            </label>
                        </Form.Group>

                        {fileSelected && (
                            <div className="mt-3 d-flex justify-content-center flex-column align-items-center border" key={40343}>
                                {file?.type?.startsWith('image/') ? (
                                    // Display image preview for image files
                                    <img src={URL.createObjectURL(file)} alt="Uploaded File" className="img-thumbnail" style={{ maxWidth: "100%", maxHeight: "150px" }} />
                                ) : (
                                    // Display appropriate icon or representation for other file types
                                    <div className="file-type-icon">
                                        {file?.type?.startsWith('application/pdf') ? (
                                            // <FontAwesomeIcon icon={faFilePdf}  className='text-success fw-bold fs-1'/>

                                            <img src="https://play-lh.googleusercontent.com/1EgLFchHT9oQb3KME8rzIab7LrOIBfC14DSfcK_Uzo4vuK-WYFs9dhI-1kDI7J0ZNTDr" alt="Uploaded File" className="img-thumbnail" style={{ maxWidth: "100%", maxHeight: "150px" }} />
                                        ) : file?.type?.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? (
                                            // <FontAwesomeIcon icon={faFileExcel}  className='text-success fw-bold fs-1' style={{fontSize :"5rem"}}/>
                                            <img src=" https://cdn-icons-png.flaticon.com/512/4726/4726040.png" alt="Uploaded File" className="img-thumbnail" style={{ maxWidth: "100%", maxHeight: "150px" }} />


                                        ) : (

                                            <img src="https://cdn.icon-icons.com/icons2/2570/PNG/512/image_icon_153794.png" alt="Uploaded File" className="img-thumbnail" style={{ maxWidth: "100%", maxHeight: "150px" }} />

                                        )}
                                    </div>
                                )}

                                <br />

                                {fileSelected && (
                                    <div className="d-flex justify-content-between mt-2">
                                        <div className="p-1 me-4">
                                            <span className='text-primary fw-bold'> File Name :</span>  {file?.name} ({(file?.size / (1024 * 1024)).toFixed(2)}MB)
                                        </div>
                                        <div className="p-1" onClick={CancelImage}>
                                            <i className="fa fa-times fs-3 tex-danger" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <button className="col-12 border border-none  mb-3 bg-primary p-2 py-3 rounded text-center mt-8 cursor-pointer"
                            onClick={() => importFacebookLeadsHandler()}
                            disabled={disable || loader ? true : false}
                        >
                            {
                                loader ?
                                    <>
                                        <div>
                                            <span className='fw-bold text-white fs-5'>
                                                <i className="fa-solid fa-database text-white fs-2 me-4"></i>
                                                Importing
                                                <span className="spinner-grow spinner-grow-sm  text-white me-2 ms-2" role="status" aria-hidden="true"></span>
                                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                            </span>
                                        </div>
                                    </>
                                    : <span className='fw-bold text-white fs-5 me-3'>
                                        <i className="bi bi-save2 text-white fs-4 me-2"> </i>
                                        Import
                                    </span>
                            }

                        </button>
                    </>

                </Modal.Body>
            </Modal> : ''}
        </>

    )
}
export default ImportFacebookLeadsForm
