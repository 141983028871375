const initState = {
    welcomeCall1CampaignStatuses: null,
    welcomeCall2CampaignStatuses: null,
    customerSentiments: null,
}

export const TicketDataReducer = (state = initState, { type, payload }: any) => {
    switch (type) {
        case "SET_WELCOME_CALL_1_CAMPAIGN":
            return { ...state, welcomeCall1CampaignStatuses: payload }
        case "SET_WELCOME_CALL_2_CAMPAIGN":
            return { ...state, welcomeCall2CampaignStatuses: payload }
        case "SET_WELCOME_CALL_CUSTOMER_SENTIMENT":
            return { ...state, customerSentiments: payload }
        default:
            return state
    }
}
