import React, { useEffect, useState } from "react"
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import DeleteChampRequirmentType from "../../../consts/SweetAlert/DeleteChampRequirmentType";
import { isAllowedUserAction } from "../../../consts/components/Utility";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ApproveChampRequirment from "../../../consts/SweetAlert/ApproveChampRequirment";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChampActions = ({ row }: any) => {
  const isVacancyAssigned = row?.champ_requirement_completed_champ?.first_name ||  row?.champ_requirement_completed_champ?.last_name

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const [openDeleteModal, setOpenDeleteModal] = useState<any>(null)
  const [openApproveModal, setopenApproveModal] = useState<any>(null)


  const modalHandlerIsDeleted = (isVacancyFilledBy: any) => {

    // if (isVacancyFilledBy) {
    //   toast.success("Vacancy already filled so action is not allowed !", { position: "top-center" })
    //   return
    // }

    setOpenDeleteModal(Math.random())

  }

  const modalHandlerIsApproved = (isVacancyFilledBy: any) => {

    // if (isVacancyFilledBy) {
    //   toast.success("Vacancy already filled so action is not allowed !", { position: "top-center" })
    //   return
    // }
    setopenApproveModal(Math.random())

  }




  return (
    <div className='primary'>
      <>

        <button
          className='btn btn-sm btn-light btn-active-primary  fs-8 '
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        // onClick={() => onClicks()}
        >
          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </button>


        <div
          className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
          style={{
            minWidth: "200px",
            zIndex: '105',
            position: 'fixed',
            inset: '0px 0px auto auto',
            margin: '0px',
            transform: 'translate(-59px, 440px)',
            overflowY: "scroll",
            maxHeight: "calc(100vh - 20px)",
          }}
        >

          <div className='menu-item px-3'>
            <a
              className='menu-link  px-3'
              data-kt-users-table-filter='delete_row'
              // onClick={() => setOpenDeleteModal(Math.random())}
              onClick={() => modalHandlerIsDeleted(isVacancyAssigned)}
            >

              {/* {isVacancyAssigned ? <FontAwesomeIcon icon={faBan} className={` me-2 text-danger`} /> : <></>} */}
              
              
               Delete
            </a>
          </div>



          <div className='menu-item px-3'>
            <a
              className='menu-link  px-3'
              data-kt-users-table-filter='delete_row'
              // onClick={() => setopenApproveModal(Math.random())}
              onClick={() => modalHandlerIsApproved(isVacancyAssigned)}
            >
              {/* {isVacancyAssigned ? <FontAwesomeIcon icon={faBan} className={` me-2 text-danger`} /> : <></>} */}



              Approve
            </a>
          </div>


        </div>


        {
          openDeleteModal ? < DeleteChampRequirmentType
            confirm='Yes'
            cancel='No'
            payloads={{
              render: openDeleteModal,
              payloadData: {
                requimentId: row?.id,
              },
              successTitle: 'Changes Are Saved Successfully ',
              FailedTitle: 'Changes Cancelled ',
            }}
            localKey={true}
            setOpenDeleteModal={setOpenDeleteModal}
          ></DeleteChampRequirmentType > : <></>
        }

        {
          openApproveModal && < ApproveChampRequirment
            confirm='Yes'
            cancel='No'
            payloads={{
              render: openApproveModal,
              payloadData: {
                "id": row?.id,
                "updateFields": {
                  "approved": true,
                }
              },
              successTitle: 'Changes Are Saved Successfully ',
              FailedTitle: 'Changes Cancelled ',
            }}
            localKey={true}
          ></ApproveChampRequirment >
        }

      </>
    </div>
  )
}
export default ChampActions