import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { adminToastRunner } from '../../../modules/admins/adminUtils'
import AdminSingleDateFilter from '../../../modules/admins/adminForms/adminFormComponents/AdminSingleDateFilter'
import MomentDate from '../../MomentDate'
import { useDispatch } from 'react-redux'
const MarkNotAtHome = ({ show, setShow, refreshNow, keyName, row }: any) => {
    const dispatch = useDispatch()

    const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
    const [payload, setPayload] = useState<any>({
        subscription_id: row?.id,
        startdate: MomentDate(0),
        enddate: MomentDate(0),
    });
    const onChangeHandler: any = (event: any, name: any, isDate?: any) => {
        setPayload({
            ...payload,
            [name]: event ? event?.value : event,
        })
    }
    const submitForm = async (e: any) => {
        e.preventDefault()
        const isConfirmed = window.confirm("Are you sure you want to proceed?");
        if (!isConfirmed) {
            return;
        }
        setIsBulkAssignApiLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.markLongNotAtHome, payload, setIsBulkAssignApiLoading)
        adminToastRunner(response)
        refreshNow && refreshNow()
        if (response?.data?.status == 200) {
            dispatch({
                type: 'CHANGE_STATUS', payload: {
                    Change_Status: row?.id,
                    Change_Statu_Render: Math.random()
                }
            })
            setShow(false)
        }
    }
    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="small-modal"
                centered
                key={keyName}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Mark Not At Home</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitForm}>
                        <Form.Group className="mb-3" key={"marknotathome"} >
                            {<Form.Label className='text-muted'>Start Date</Form.Label>}
                            <div className='mb-3' style={{ position: "relative" }}>
                                <AdminSingleDateFilter
                                    onChangeHandler={onChangeHandler}
                                    payload={payload}
                                    name="startdate"
                                />
                            </div>
                            {<Form.Label className='text-muted'>End Date</Form.Label>}
                            <div className='mb-3' style={{ position: "relative" }}>
                                <AdminSingleDateFilter
                                    onChangeHandler={onChangeHandler}
                                    payload={payload}
                                    name="enddate"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 p-2 d-flex justify-content-end" >
                            {
                                <Button type='submit' variant={isBulkAssignApiLoading ? "success" : "primary"} >
                                    {isBulkAssignApiLoading ? "Submitting..." : "Submit"}
                                </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal >
        </>
    )
}
export default MarkNotAtHome
