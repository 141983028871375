export const timelotsData = [
    { name: "06AM-07AM", id: "2" },
    { name: "07AM-08AM", id: "3" },
    { name: "08AM-09AM", id: "4" },
    { name: "09AM-10AM", id: "5" },
    { name: "10AM-11AM", id: "6" },
    { name: "11AM-12AM", id: "7" },
    { name: "12PM-01PM", id: "8" },
    { name: "01PM-02PM", id: "9" },
    { name: "02PM-03PM", id: "10" },
    { name: "03PM-04PM", id: "11" },
    { name: "04PM-05PM", id: "12" },
    { name: "05PM-06PM", id: "13" },
    { name: "06PM-07PM", id: "14" }
]
export const JobStatusData = [
    { keys: "all", name: "All", id: "0" },
    { keys: "notathome", name: "Not At Home", id: "1" },
    { keys: "reported", name: "Reported", id: "2" },
    { keys: "ontime", name: "On Time", id: "3" },
    { keys: "early", name: "Early", id: "4" },
    { keys: "late", name: "Late", id: "5" },
    { keys: "overdue", name: "Overdue", id: "6" },
    { keys: "rated_commented", name: "Rated/Commented", id: "7" },
    { keys: "notdue", name: "Not Due", id: "8" }
]
export const CleanerstatusData = [
    { name: "Inactive", id: 0 },
    { name: "Active", id: 1 },
    { name: "All", id: null }
]
export const filterdataCleanerAttendace = [
    { name: "Pending", id: "pending" },
    { name: "Marked", id: "marked" },
    { name: "Job Done", id: "job_done" },
    { name: "Admin Verified", id: "admin_verified" },
    { name: "Absent", id: "absent" },
    { name: "Any Job Reassigned to other cleaner", id: "any_job_reassign" }
]
export const SatusListData = [
    { name: "New Ticket", id: 0 },
    { name: "Replied", id: 1 },
    { name: "Customer Replied", id: 2 },
    { name: "Closed", id: 3 },
    { name: "Feedback Received", id: 4 },
    { name: "Un-Answered", id: 5 },
    { name: "Follow Up", id: 6 },
]
export const Days = [{ name: "Sunday", id: 1 }, { name: "Monday", id: 2 }, { name: "Tuesday", id: 3 }, { name: "Wednesday", id: 4 }, { name: "Friday", id: 5 }, { name: "Saturday", id: 6 }]

export const reported_categories = [
    { name: "Job Missed Late", id: 1 },
    { name: "Job Missed Absent", id: 2 },
    { name: "Job Missed Customer", id: 3 }
]

export function getAverage(numbers: Array<any>) {
    let sum = 0;
    for (let i = 0; i < numbers.length; i++) {
        sum += numbers[i];
    }
    return Number(sum / numbers.length).toFixed(2);
}

export const RatingsData = [
    { name: "1", id: 1 },
    { name: "2", id: 2 },
    { name: "3", id: 3 },
    { name: "4", id: 4 },
    { name: "5", id: 5 },
    { name: "1-2", id: 6 },
    { name: "4-5", id: 7 },
]

export const tags = [{ label: "Missed Service", id: "missed service" }, { label: "New Customer", id: "new" }, { label: "Unhappy Customer", id: "unhappy" }]

export const vehicleTypes = [
    {
        id: 0,
        name: "Not Set",
        isLicence: 0,
        isJobAvailable: 0,
    },
    {
        id: 1,
        name: "Motor Cycle / Scooter",
        isLicence: 1,
        isJobAvailable: 1,
    },
    {
        id: 2,
        name: "Bicycle",
        isLicence: 0,
        isJobAvailable: 0,
    },
    {
        id: 3,
        name: "Yulu Bike",
        isLicence: 0,
        isJobAvailable: 1,
    },
    {
        id: 4,
        name: "I don't have vehicle",
        isLicence: 0,
        isJobAvailable: 1,
    },
];

export const createCouponCode = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const getGoogleMapAddressLink = (latitude: any, longitude: any) => {
    var mapLink = "https://www.google.com/maps/search/?api=1&query=" + latitude + "," + longitude;
    return mapLink;
}

export const languageFullName: any = {
    en: "English",
    hi: "Hindi",
    kn: "Kannada",
}



export const languageDataList: any = [{ name: "English", id: "en" }, { name: "Hindi", id: "hi" }, { name: "Kannada", id: "kn" }]

export const ticketStatusToColor: any = ["warning", "danger", "warning", "success", "dark", "primary", "primary", "primary", "primary", "primary", "primary", "dark", "primary"];

export const newDailyJobListTabwiseKeyValue: any = {
    2: "IS_ALERT",
    3: "IS_ABSENT",
    4: "IS_LEAVE",
    5: "IS_INACTIVE",
}
