import React, { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { initial_renewal_states, renewalTaskStates } from '../../admins/ad_states/renewalTaskStates';
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import { bulkTicketPayloadData } from '../../admins/ad_states/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { initial_rewards_states, rewardsTaskStates } from '../../admins/ad_states/rewardsAndPenalties';


const AmountEditable = ({ row }: any) => {


    const [storeRow, setStoreRow] = useState(row)

    useEffect(() => {
        setStoreRow(row)
    }, [row])
    const [rewardNdPenaltiesStates, setLocalStateDispatch] = useReducer(rewardsTaskStates, initial_rewards_states);
    const amountModalClose = () => {
        setLocalStateDispatch({ type: "OPEN_AMOUNT_MODAL", payload: false })
    }

    const [payload, setPayload] = useState<any>({
        "id": storeRow?.id,
        "amount": 0,
        "service_date": ""
    })

    const handleAssignSubmit = async (e: any) => {
        if (payload?.id && payload?.amount > 0) {
            setLocalStateDispatch({ type: "API_LOADING_AMOUNT", payload: true })

            const response: any = await postRequest(URL_EndPoints(null)?.updateRequestApprovalTrackerColumns, payload, null)

            if (response?.data?.status === 200) {
                toast.success(response?.data?.msg, { position: "top-center" })
                setLocalStateDispatch({ type: "OPEN_AMOUNT_MODAL", payload: false })
                row.amount = payload?.amount
                setStoreRow(row)
            }
            else {
                toast.error(response?.data?.msg, { position: "top-center" })
                setLocalStateDispatch({ type: "OPEN_AMOUNT_MODAL", payload: false })
            }
            setLocalStateDispatch({ type: "API_LOADING_AMOUNT", payload: false })

        }
    }


    return (
        <>

            {
                <span className="" onClick={() => setLocalStateDispatch({ type: "OPEN_AMOUNT_MODAL", payload: true })}> <FontAwesomeIcon icon={faEdit} className='cursor-pointer text-danger me-3' /> {storeRow?.amount}</span>
            }

            <Modal size='lg' show={rewardNdPenaltiesStates?.amountModalOpen} onHide={amountModalClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Amount Editable</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className="mb-3" >
                        <Form.Group className="mb-3" controlId="formAmount">
                            <Form.Label>Amount Update</Form.Label>
                            <Form.Control type="Amount" name='amount' value={payload?.amount} placeholder="Enter Amount" onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })} />
                            <Form.Text className="text-muted">
                                If Update option is disable, means you cannot update amount, because once already approved
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formAmount">
                            <Form.Label>Amount Update</Form.Label>
                            <input
                                placeholder='Service Date'
                                name='service_date'
                                type='date'
                                value={payload.service_date}
                                className='form-control  mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                            />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className="mb-3 p-2" >
                        {
                            rewardNdPenaltiesStates?.isLoadingSubmitAmount ?
                                <Spinner animation={'border'} />
                                : <Button type='submit' variant="primary" disabled={storeRow?.status == "approved" ? true : false} onClick={handleAssignSubmit}>
                                    Add/Edit Amount
                                </Button>
                        }
                    </Form.Group>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default AmountEditable