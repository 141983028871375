import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import DialogBox from './DialogBox/DialogBox';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../consts/components/Utility';
import IsPaidRatingYesNo from '../../consts/SweetAlert/IsPaidRatingYesNo';
import Refresh from '../../consts/Refresh';
import { useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils';

let lastAppStoreClicked = 0;
let lastPlayStoreClicked = 0;
const delay = 10000;

const RatingList = ({ filterDataOutsource }: any) => {
  const [filteredSelectedRows, setfilteredSelectedRows] = useState<any>([])
  const [isPaidStatusModal, setIsPaidStatusModal] = useState<any>(false)
  const [renders, setRender] = useState<any>(null)
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  // to search data here 
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  // server side
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  // server sidefilterData
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload())
  // api calling here logic  here +++++++++++++++++++++++++++++++
  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.getRatingsV2, payloads, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.ratings)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection

    setPayload(payload)
    return payload
  }

  // api calling here logic  here +++++++++++++++++++++++++++++++
  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  // handle pagination here 
  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)
    InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  };

  // deboucing  
  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }

  }, [search])

  useEffect(() => {
    let { isFilter, ratinglist2Filter } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: ratinglist2Filter }, null)
    isFilter && setPayload({ ...payload, ["filterData"]: ratinglist2Filter })
  }, [
    search,
    filterDataOutsource?.ratinglist2Filter,
    filterDataOutsource?.isFilter,
  ])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);

  }, [selectedFilterData?.length]);

  const [playLoading, setPlayLoading] = useState(false);
  const [appLoading, setAppLoading] = useState(false);
  const [popUpPostFormOpen, setPopUpPostFormOpen] = useState(false);

  const handleAddRatingPopUp = () => {
    setPopUpPostFormOpen(!popUpPostFormOpen);
  }

  const handlePopUpClose = () => {
    setPopUpPostFormOpen(false);
  }

  const syncAppStoreReviews = async () => {
    const now = Date.now();
    if (now - lastAppStoreClicked >= delay) {
      lastAppStoreClicked = now;
      setAppLoading(true);
      const response: any = await getRequest(URL_EndPoints(null)?.syncAppStoreRating, setAppLoading);
      if (response?.data?.status === 200)
        toast.success(response?.data?.message);
      setAppLoading(false);
    } else {
      toast.info("Please sync after sometime");
    }
  }

  const syncPlayStoreReviews = async () => {
    const now = Date.now();
    if (now - lastPlayStoreClicked >= delay) {
      lastPlayStoreClicked = now;
      setPlayLoading(true);
      const response: any = await getRequest(URL_EndPoints(null)?.syncPlayStoreRating, setPlayLoading);
      if (response?.data?.status === 200)
        toast.success(response?.data?.message);
      setPlayLoading(false);
    } else {
      toast.info("Please sync after sometime");
    }
  }

  const handleSelectedRowsChange = (selectedRows: any) => {
    let isPaidIds = selectedRows?.selectedRows?.map((row: any) => row?.id) || [];
    setfilteredSelectedRows(isPaidIds)

  };

  const openIsPaidModalHandler = () => {
    setIsPaidStatusModal(true)
    setRender(Math.random())
  }


  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")
  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }
  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)
    }, 2000)
    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getRatingsV2")
  }, [])


  return (
    <>
      <div>

        <DataTable
          title={!filterDataOutsource?.isFilter && "Rating List"}
          selectableRows
          progressPending={pending}
          columns={reorderColumns(columns(), "ratingInappReoderedItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "ratingInappReoderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          // server 
          pagination
          sortServer
          paginationServer
          onSort={handleSort}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}

          // server 
          subHeaderComponent={
            <>
              <div className='w-100 d-flex justify-content-between mb-3 '>
                <div className='d-flex align-items-start justify-content-start '>

                  <input
                    type="text"
                    placeholder='Search Here'
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                    className='w-100 form-control align-start'
                  />
                </div>
                <div className='d-flex align-items-center justify-content-end '>
                  <div className='d-flex align-items-center justify-content-center my- me-2' style={{ cursor: "pointer" }} >
                    <Refresh refreshNow={actionHandler} />
                  </div>

                  <div className='d-flex align-items-center justify-content-center my-2'>
                    <button className='btn' onClick={syncPlayStoreReviews} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}><span className='sync-review-btn' style={{
                      marginRight: '8px',
                    }}>Play Store</span> {playLoading ? <Spinner animation="border" variant="primary" /> : <i className="fa fa-refresh" aria-hidden="true"></i>} </button>
                    <button className='btn' onClick={syncAppStoreReviews} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}><span className='sync-review-btn' style={{
                      marginRight: '8px',
                    }}>App Store</span> {appLoading ? <Spinner animation="border" variant="primary" /> : <i className="fa fa-refresh" aria-hidden="true"></i>}</button>
                  </div>
                  <div className='d-flex align-items-center justify-content-center my-2' >
                    <a className="btn btn-sm btn-primary  cursor-pointer" id="kt_toolbar_primary_button" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" onClick={handleAddRatingPopUp}>Add Rating</a>
                  </div>
                  <div className='d-flex align-items-center justify-content-center my-2'>
                    <FilterHandler
                      onChangeHandler={onChangeHandler}
                      actionHandler={actionHandler}
                      getCurrentFilter={getCurrentFilter}
                      removeFilter={removeFilter}
                      payload={payload}
                      Component={DynamicFilter}
                      filterData={filter}
                      OperatorsData={operators}
                      selectedFilterData={selectedFilterData}
                      filterComponents={KeywiseComponents()}
                      setSeacrch={setSearchFilter}
                      isLoadingFilter={isLoadingFilter}
                      // filter data  -----
                      storeFilterList={storeFilterList}
                      filterkey={filterkey}
                      listApiUrl={listApiUrl("getRatingsV2")}

                    />

                  </div>
                </div>
              </div>


              <div className="w-100  d-flex">
                {
                  filteredSelectedRows?.length > 0 && <div className='fiteredSelectedRows w-80 ml-2'>
                    {/* <UpdateIsPaidBulk filteredSelectedRows={filteredSelectedRows} payoutStatusData={[]} /> */}

                    <div className=''>
                      <Button className='btn-sm' style={{ minWidth: "70px" }} onClick={() => openIsPaidModalHandler()}>Bulk Update Is Paid Status {filteredSelectedRows.length}</Button>
                    </div>

                  </div>
                }
              </div>
            </>
          }
        />

        {popUpPostFormOpen && <DialogBox handlePopUpClose={handlePopUpClose} popUpPostFormOpen={popUpPostFormOpen} />}
      </div>



      {
        isPaidStatusModal ?
          <IsPaidRatingYesNo
            render={renders}
            confirm={'Yes'}
            cancel={'No'}
            payload={{
              "ratingIds": filteredSelectedRows,
              "approver_id": localStorage.getItem("user"),
              "updateFields": {
                "ispaid": 1
              }
            }}
            setIsPaidStatusModal={setIsPaidStatusModal}
            title={`Are you sure want to Paid ?`}
          /> : ""
      }









    </>
  )
}

export default RatingList
