import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { adminToastRunner } from '../admins/adminUtils'
import AdminSingleSelectFilter from '../admins/adminForms/adminFormComponents/AdminSingleSelectFilter'
import AdminSelectCheckbox from '../admins/adminForms/adminFormComponents/AdminSelectCheckbox'
import AdminSelectTextAreaBox from '../admins/adminForms/adminFormComponents/AdminSelectTextAreaBox'
import { toast } from 'react-toastify'

export const language = [{ name: "English", id: "en" }, { name: "Hindi", id: "hi" }, { name: "Kannada", id: "kn" }]
const AddEditTermCondition = ({ show, setShow, refreshNow, formTitle = "Unknown", keyName, initialPayload, isEditAllowed = false, row = null, defaultLanguage }: any) => {

    const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
    const [payload, setPayload] = useState<any>(JSON.parse(JSON.stringify(initialPayload)));

    const onChangeHandler: any = (event: any, name: any, isDate?: any) => {
        if (name == "lang") {
            setPayload({
                ...payload,
                ["lang"]: event ? event?.value : event,
                ["lang_text"]: event ? event?.item?.label : event,
            })
        }
        else {
            setPayload({
                ...payload,
                [name]: event ? event?.value : event,
            })
        }
    }

    const submitForm = async (e: any) => {
        e.preventDefault();

        let newPayload: any = {};

        if (row?.id && isEditAllowed) {
            for (const key in payload) {
                if (payload[key] !== initialPayload[key]) {
                    newPayload[key] = payload[key];
                }
            }
            newPayload.id = row?.id;
        }
        else {
            newPayload = { ...payload }
        }

        if (Object.keys(newPayload).length === 0 && isEditAllowed) {
            toast.error("No Change detected !")
            return;
        }

        setIsBulkAssignApiLoading(true);
        const response: any = await postRequest(URL_EndPoints(null)?.addOrUpdateTermsCondition, newPayload, setIsBulkAssignApiLoading);
        adminToastRunner(response);
        refreshNow && refreshNow();
        if (response?.data?.status === 200) {
            setShow(false);
        }
    };


    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                // dialogClassName="modal"
                size="lg"
                centered
                key={keyName}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={submitForm}>
                        <Form.Group className="mb-3" key={"AddTermForm"} >
                            <Form.Label className='text-muted'>Language</Form.Label>
                            <div className='mb-3' style={{ position: "relative" }}>
                                <AdminSingleSelectFilter
                                    onChangeHandler={onChangeHandler}
                                    HeaderTitle="Select Language"
                                    SelectData={language}
                                    displayFields={["name"]}
                                    id="id"
                                    name="lang"
                                    defaultData={{ item: defaultLanguage }}
                                    searchable={false}
                                />
                            </div>

                            <Form.Label className='text-muted'>Text</Form.Label>
                            <div className='mb-3' style={{ position: "relative" }}>
                                <AdminSelectTextAreaBox
                                    onChangeHandler={onChangeHandler}
                                    HeaderTitle="Text"
                                    payload={payload}
                                    name="text"
                                    inputType="text"
                                    label=""
                                />
                            </div>

                            <div className='mb-3' style={{ position: "relative" }}>
                                <AdminSelectCheckbox
                                    onChangeHandler={onChangeHandler}
                                    HeaderTitle="Is Important"
                                    payload={payload}
                                    name="is_checkbox_required"
                                    label=""
                                />
                            </div>

                            <div className='mb-3' style={{ position: "relative" }}>
                                <AdminSelectCheckbox
                                    onChangeHandler={onChangeHandler}
                                    HeaderTitle="Is Active"
                                    payload={payload}
                                    name="status"
                                    label=""
                                />
                            </div>

                        </Form.Group>
                        <Form.Group className="mb-3 p-2 d-flex justify-content-end" >
                            {
                                <Button type='submit' variant={isBulkAssignApiLoading ? "success" : "primary"} >
                                    {isBulkAssignApiLoading ? "Submitting..." : "Submit"}
                                </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal >
        </>
    )
}
export default AddEditTermCondition
