import Body from "./Body";
import { memo, useState } from "react";
import React from "react"
import TagsShow from "../../../consts/TagsShow/TagsShow";
const CleanerTableBodyComponent = (props: any) => {
  const { timeSlots, cleanerStats, handleJobDetailSubmit, handleCleanerDetailsSubmit, ActiveChmapId } = props

  const udpatedCleanerStats = cleanerStats.sort((a: any, b: any) => a.cleaner_details.distanceinnumber - b.cleaner_details.distanceinnumber)
  const udpatedCleanerStats2 = cleanerStats.filter((ele: any) => ele.cleaner_details.id !== ActiveChmapId)
  const udpatedCleanerStatstopPriority = cleanerStats.filter((ele: any) => ele.cleaner_details.id == ActiveChmapId)
  udpatedCleanerStats2.unshift(...udpatedCleanerStatstopPriority)

  const servicetype1ClassName = 'badge badge-light-success fw-bolder mb-2 rounded d-flex justify-content-center'
  const servicetype2ClassName = 'badge badge-light-danger fw-bolder mb-2 rounded d-flex justify-content-center'
  const noJobClassName = 'badge badge-light-info fw-bolder mb-2 rounded d-flex justify-content-center'
  const dateChangeClassName = 'badge badge-light-warning fw-bolder mb-2 rounded d-flex justify-content-center'
  if (!udpatedCleanerStats2) {
    return (
      <>Loading</>
    )
  }

  return udpatedCleanerStats2?.map((cleanerStat: any) => {

    return (
      <tbody  >
        {timeSlots?.map((timeslots: any) => {
          let isJobPresent = cleanerStat?.attendence_data?.some((attendance: any) => {
            let timesloteData = attendance.timeslot_data
            let timeSloteName = timeslots.name
            return attendance.timeslot_data[timeSloteName]?.length > 0 || timeSloteName in timesloteData
          })
          if (isJobPresent) {
            return (
              <tr  >
                <td
                  style={{ maxWidth: '230px', width: '130px', cursor: 'pointer' }}
                  data-bs-toggle='tooltip'
                  data-bs-placement='bottom'
                  data-bs-html="true"
                // title={`<span>Phone</span><span>${cleanerStat.cleaner_details.phone}</span>`}
                // title={`${cleanerStat?.cleaner_details?.phone}`}
                >
                  <div
                    className='bg-secondary p-2 text-center rounded text-black-50 '
                    style={{ whiteSpace: 'pre-wrap' }
                    }
                    onClick={() => handleCleanerDetailsSubmit(cleanerStat?.cleaner_details, timeslots)}
                  >
                    {cleanerStat?.cleaner_details?.first_name + " " + cleanerStat?.cleaner_details?.last_name + " - " + ((cleanerStat?.cleaner_details?.distance + " Away"))}
                    <br />
                    Supervisor: {
                      cleanerStat?.cleaner_details?.supervisors[0]?.supervisorcleaner.first_name + ' ' + cleanerStat?.cleaner_details?.supervisors[0]?.supervisorcleaner.last_name
                    }
                    <div className='d-flex flex-row mb-1  justify-content-between align-items-center position-sticky' >
                      <div className='w-100 mt-2'>
                        <div className='d-flex mb-2'>
                          <span className='badge badge-light-dark fs-8 fw-bold me-2 '> ( PW:  {cleanerStat?.cleaner_details?.projected_points?.prevweek.total_point ? cleanerStat?.cleaner_details?.projected_points?.prevweek.total_point : 0} )</span>
                          <span className='badge badge-light-dark fs-8 fw-bold me-2 '> ( CW: {cleanerStat?.cleaner_details?.projected_points?.currweek.total_point ? cleanerStat?.cleaner_details?.projected_points?.currweek.total_point : 0} ) </span>
                          <span className='badge badge-light-dark fs-8 fw-bold me-2 '> ( NW: {cleanerStat?.cleaner_details?.projected_points?.nextweek.total_points ? cleanerStat?.cleaner_details?.projected_points?.nextweek.total_points : 0} )</span>
                        </div>
                        <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1'>
                          Champ Age: {cleanerStat?.cleaner_details?.champAge ? cleanerStat?.cleaner_details?.champAge : "Not Set"}
                        </div>
                        <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1'>
                          <TagsShow Tag={cleanerStat?.cleaner_details?.private_tag} />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ maxWidth: '230px', width: '130px' }}>
                  <div
                    className='bg-secondary p-2 text-center rounded text-black-50'
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {timeslots?.name}
                  </div>
                </td>
                {cleanerStat?.attendence_data?.map((attendance: any) => {
                  return (<>
                    <td>
                      <div
                        className='p-3 d-flex flex-column'
                        style={{ maxWidth: '150px', width: '100px' }}
                      >
                        {attendance && (
                          <Body attendance={attendance} timeslots={timeslots} noJobClassName={noJobClassName} servicetype2ClassName={servicetype2ClassName} cleanerStat={cleanerStat} handleJobDetailSubmit={handleJobDetailSubmit} dateChangeClassName={dateChangeClassName} servicetype1ClassName={servicetype1ClassName}></Body>
                        )}
                        {attendance?.timeslot_data[timeslots.name]?.length <= 0 && (
                          <>
                            {
                              <div className={noJobClassName} style={{ cursor: ' not-allowed' }}>
                                NJ
                              </div>
                            }
                            {
                              <div className={noJobClassName} style={{ cursor: ' not-allowed' }}>
                                NJ
                              </div>
                            }
                            {
                              <div className={noJobClassName} style={{ cursor: ' not-allowed' }}>
                                NJ
                              </div>
                            }
                            {
                              <div className={noJobClassName} style={{ cursor: ' not-allowed' }}>
                                NJ
                              </div>
                            }
                          </>
                        )}
                      </div>
                    </td>
                  </>)
                }
                )}
              </tr>
            )
          }




        })}
      </tbody>
    )


  })
}
export default CleanerTableBodyComponent
