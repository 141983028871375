import React, { FC, useEffect } from 'react'
import ChampDeviceHashList from './ChampDeviceHashList'
import { KTCardBody } from '../../../../../_metronic/helpers'
const ChampDeviceHashListController: any = ({filterDataOutsource}:any) => {
    return (
        <KTCardBody className='card py-4' >
            <ChampDeviceHashList filterDataOutsource={filterDataOutsource} />
       </KTCardBody>
    )
}
export default ChampDeviceHashListController
