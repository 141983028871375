
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { data2 } from './dataTypes';
import { AdminStackBarToolTip } from '../AdminStackBarToolTip';
import ShowChartHint from './ShowChartHint';
import ShowPercentage from './ShowPercentage';
import { getOnOffAvgPercentage } from '../../adminUtils';
import { getRequest, postRequest } from '../../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints';
import MomentDate from '../../../../consts/MomentDate';
import IsLoading from '../../../../consts/IsLoading/IsLoading';
import Not_Found from '../../../../consts/Not_Found';
import AdminMultiSelectFilter from '../../adminForms/adminFormComponents/AdminMultiSelectFilter';
import { KTCardBody } from '../../../../../_metronic/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import SkeletonLoader from '../../../../consts/IsLoading/SkeletonLoader';


const getTotalHourOnOff = (on: any, off: any) => {
    const onTotalHour = on.reduce((sum: any, ele: any) => sum + parseFloat(ele), 0);
    const offTotalHour = off.reduce((sum: any, ele: any) => sum + parseFloat(ele), 0);

    return {
        onTotalHour: `${onTotalHour.toFixed(2)}`,
        offTotalHour: `${offTotalHour.toFixed(2)}`,
    }
}

interface Data {
    callStatus: string;
    hourDiff: string;
}

const StackBarCharts: React.FC<any> = ({ onlyDisplay = false, defaultData }: any) => {
    const MIN_WIDTH_PERCENTAGE = 7;

    const getTotalHours = (data: Data[]): number => {
        return data.reduce((sum, d) => sum + parseFloat(d.hourDiff), 0);
    };

    const getBarWidth = (value: number, total: number): number => {
        return (value / total) * 100;
    };

    const [chartData, setChatData] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        dateFrom: "",
        dateTo: "",
        hourFrom: "",
        hourTo: "",
        agentIDs: defaultData?.agentIDs ?? [],
        roleIDs: [],
    })

    const [pending, setPending] = useState(true)
    const [search, setSearch] = useState("")
    const [agentListData, setAgentListData] = useState<any>([])
    const [roleListData, setRoleListData] = useState<any>([])
    const [defaultHours, setDefaultHours] = useState<any>([])
    const [hourFromOnly, setHourFromOnly] = useState<any>([])
    const [hourToOnly, setHourToOnly] = useState<any>([])

    useEffect(() => {
        const hourTo = hourFromOnly?.filter((ele: any) => ele?.id >= payload?.hourFrom)
        hourFromOnly?.length > 0 && setHourToOnly(hourTo)
    }, [payload?.hourFrom, hourFromOnly])

    const get24Hours = async () => {
        const get24Hours = await getRequest(URL_EndPoints()?.get24Hours, setPending)
        const getAllRoles = await getRequest(URL_EndPoints()?.getAllRoles, setPending)
        const getAdminList = await getRequest(URL_EndPoints()?.getAdminList, setPending)
        setHourFromOnly(get24Hours?.data?.data)
        setRoleListData(getAllRoles?.data?.data)
        setAgentListData(getAdminList?.data?.data)
    }

    useEffect(() => {
        get24Hours()
    }, [])

    const getChartData = async () => {
        setPending(true)
        const agentIDs = defaultData?.agentIDs?.length > 0 ? defaultData?.agentIDs : payload?.agentIDs
        const result = await postRequest(URL_EndPoints()?.getAgentCallOnOffHistory, {...payload, agentIDs}, setPending)
        setPayload({
            ...payload,
            ["dateFrom"]: result?.data?.data?.date,
            ["dateTo"]: result?.data?.data?.date,
            ["hourFrom"]: result?.data?.data?.hours[0],
            ["hourTo"]: result?.data?.data?.hours[result?.data?.data?.hours?.length - 1],
        })
        setChatData(result?.data?.data?.agentWiseData)
        setDefaultHours(result?.data?.data?.hours)
    }

    useEffect(() => {
        getChartData()
    }, [defaultData?.agentIDs?.join(",")])

    const submitHandler = () => {
        getChartData()
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload({
            ...payload,
            [name]: event ? event?.value : event,
        })
    }

    return (
        <KTCardBody >

            <Container className='p-1'>
                {!onlyDisplay && <Row className='d-flex justify-content-start align-items-center p-0 gap-1px col-12 mb-4' >
                    {/* <Refresh refreshNow={getChartData} /> */}
                    <div className='col-3 p-1'>
                        <AdminMultiSelectFilter
                            onChangeHandler={onChangeHandler}
                            HeaderTitle="Filter By Agents"
                            SelectData={agentListData}
                            displayFields={["first_name", "last_name", "phone"]}
                            id="id"
                            name="agentIDs"
                            defaultData={null}
                            searchable={false}
                            setSeacrch={false}
                            isLoading={false}
                            filters={null}
                        />
                    </div>
                    <div className='col-3 p-1'>
                        <AdminMultiSelectFilter
                            onChangeHandler={onChangeHandler}
                            HeaderTitle="Filter By Role"
                            SelectData={roleListData}
                            displayFields={["Role_name"]}
                            id="id"
                            name="roleIDs"
                            defaultData={null}
                            searchable={false}
                            setSeacrch={false}
                            isLoading={false}
                            filters={null}
                        />
                    </div>
                    <div className='col-2 p-1'>
                        <input
                            type='date'
                            style={{ height: '38px' }}
                            name="dateFrom"
                            value={payload?.dateFrom}
                            onChange={(event: any) => setPayload({ ...payload, ["dateFrom"]: event?.target?.value, ["dateTo"]: event?.target?.value })}
                            className=' form-control align-start'
                        />
                    </div>

                    <div className='col-3 d-flex  position-relative p-1'>
                        <div className='col-6 me-2 position-relative'>
                            <span style={{
                                position: 'absolute',
                                top: '-8px',
                                left: '50%',
                                width: "50%",
                                transform: 'translateX(-50%)',
                                fontSize: '12px',
                                textAlign: 'center'
                            }}
                                className='rounded badge badge-light-dark fs-semibold'
                            >
                                Start Hour
                            </span>
                            <select
                                name='hourFrom'
                                aria-label='Select a Timezone'
                                data-control='select2'
                                data-placeholder='Period'
                                className='form-select'
                                value={payload?.hourFrom}
                                style={{ height: '38px' }}
                                onChange={(event) => setPayload({ ...payload, [event?.target?.name]: +event?.target?.value })}
                            >
                                < option >Start Hour </option>
                                {
                                    hourFromOnly?.map((ele: any) => {
                                        return < option value={ele?.id}>{ele?.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-6 me-2 position-relative'>
                            <span style={{
                                position: 'absolute',
                                top: '-8px',
                                left: '50%',
                                width: "50%",
                                transform: 'translateX(-50%)',
                                fontSize: '12px',
                                textAlign: 'center'
                            }}
                                className='rounded badge badge-light-dark fs-semibold'
                            >
                                End Hour
                            </span>
                            <select
                                name='hourTo'
                                aria-label='Select a Timezone'
                                data-control='select2'
                                data-placeholder='Period'
                                className='form-select '
                                value={payload?.hourTo}
                                style={{ height: '38px' }}
                                onChange={(event) => setPayload({ ...payload, [event?.target?.name]: +event?.target?.value })}
                            >
                                < option >End Hour </option>
                                {
                                    hourToOnly?.map((ele: any) => {
                                        return < option value={ele?.id}>{ele?.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-1 me-2' >
                            <Button type='submit' variant="primary" style={{ height: "38px" }} onClick={submitHandler} >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Row>}
                <div className='d-flex justify-content-end align-items-center w-100 mb-5'>
                    <div className='d-flex justify-content-start align-items-center me-4'>
                        <div className='d-flex justify-content-start align-items-center'>
                            <span>
                                <FontAwesomeIcon icon={faCircleCheck} className={`text-success fs-2 ms-2 me-2`} />
                            </span>
                            Current Call Status ( ON )
                        </div>
                        <div className='d-flex justify-content-start align-items-center'>
                            <span>
                                <FontAwesomeIcon icon={faXmarkCircle} className={`text-danger fs-2 ms-2 me-2`} />
                            </span>
                            Current Call Status ( OFF )
                        </div>
                    </div>
                    <ShowChartHint title="ON" color="success" />
                    <ShowChartHint title="OFF" color="danger" />
                </div>
                {
                    pending ? <SkeletonLoader rowCount={1}  /> : chartData?.length == 0 ? <Not_Found headerTitle="No activity found for the day!" showImage={false} /> : <>
                        {chartData?.map((agentData: { data: any[]; callStatus: string; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => {

                            const totalHours = getTotalHours(agentData.data);
                            const onOff = agentData?.data?.reduce((obj: any, ele: any) => {
                                if (ele?.callStatus == "ON") {
                                    obj["on"] = obj["on"] || [];
                                    obj["on"].push(+ele?.hourDiff);
                                }
                                else {
                                    obj["off"] = obj["off"] || [];
                                    obj["off"].push(+ele?.hourDiff);
                                }
                                return obj;
                            }, { on: [], off: [] })

                            const { onAvgPer, offAvgPer } = getOnOffAvgPercentage(onOff?.on, onOff?.off)
                            const { onTotalHour, offTotalHour } = getTotalHourOnOff(onOff?.on, onOff?.off)
                            const widths = agentData.data.map((item) => {
                                const barWidth = getBarWidth(parseFloat(item.hourDiff), totalHours);
                                return Math.max(barWidth, MIN_WIDTH_PERCENTAGE);
                            });

                            const sumWidths = widths.reduce((sum, width) => sum + width, 0);
                            const adjustedWidths = widths.map((width) => Math.floor((width / sumWidths) * 100));
                            const isCallStatusOn = agentData?.callStatus == "ON" ? true : false
                            return (
                                <Row>
                                    <Col key={index} className="mb-4">
                                        <div className='d-flex justify-content-start align-items-center mb-3 '>
                                            <div className='me-4 fw-bold d-flex col-3 justify-content-start align-items-center'>
                                                <FontAwesomeIcon icon={isCallStatusOn ? faCircleCheck : faXmarkCircle} className={`text-${isCallStatusOn ? "success" : "danger"} fs-4 me-2`} />
                                                <span >
                                                    {agentData.name}
                                                </span>
                                                {/* ( <ShowPercentage title="Current Status" value={agentData?.callStatus} color= margin={false} /> ) */}
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <ShowPercentage title="ON" value={onTotalHour} color="success" margin={true} />
                                                <ShowPercentage title="OFF" value={offTotalHour} color="danger" margin={true} />

                                            </div>
                                        </div>
                                        <div className="d-flex" style={{ width: "100%" }}>
                                            {agentData.data.map((item, itemIndex) => {
                                                const barWidth = adjustedWidths[itemIndex];
                                                let unidqueId = item?.hourDiff + item?.callStatus + itemIndex + index
                                                return (
                                                    <div
                                                        key={itemIndex}
                                                        className={`cursor-pointer  ${item.callStatus === 'ON' ? 'bg-success' : 'bg-danger'}`}
                                                        style={{ width: `${barWidth}%`, height: '30px', textAlign: 'center', color: 'white', lineHeight: '30px', borderLeft: "1.5px solid white", borderRight: "1.5px solid white" }}
                                                        id={unidqueId}
                                                    >
                                                        {/* {item.hourDiff} H */}
                                                        {item.hourText} 
                                                        <AdminStackBarToolTip anchorId={unidqueId} title={[`${item.statusText}`]} place={""} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}

                        <div className='text-center w-100 fw-bold fs-4 mb-2 d-flex justify-content-between align-items-center'>
                            {defaultHours?.map((ele: any) => <span>{ele}</span>)}
                        </div>
                        <div className='text-center w-100 fw-bold fs-4 mb-2'>Time ( 24 Hours )</div>


                    </>
                }
            </Container>

        </KTCardBody>
    );
};

export default React.memo(StackBarCharts);



