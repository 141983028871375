import React, { useEffect, useState } from 'react'
import { Button, FloatingLabel, Form, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'

const ReplyReviewModal = ({ showModal, handleClose, row }: any) => {
    const [loader, setLoader] = useState<any>(false)

    const [payload, setPayload] = useState<any>({
        reviewId: row?.id,
        reply: '',
    });

    const handleReplyReview = async () => {
        setLoader(true);
        if(!payload?.reply?.trim()) {
            setLoader(false);
            toast.error("Please enter some reply");
            return;
        }
        const response: any = await postRequest(URL_EndPoints(null)?.replyToReview, payload, setLoader);
        if(response?.data?.status === 200) {
            toast.success(response?.data?.message);
        }else {
            toast.info(response?.data?.message);
        }
    }

    return (
        <>
            <Modal size='lg' show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reply To {row?.sourceDetails?.name ?? ''} Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FloatingLabel controlId="floatingTextarea2" label="Leave a reply here">
                            <Form.Control
                            as="textarea"
                            placeholder="Leave a reply here"
                            style={{ height: '100px' }}
                            value={payload.reply}
                            onChange={(e: any) => setPayload({
                                ...payload,
                                "reply": e.target.value,
                            })}
                            />
                        </FloatingLabel>
                        <div className=' col-12 w-100 d-flex justify-content-end mt-8 me-'>
                            <Button variant="primary btn-sm text-end btn-sm " disabled={loader} onClick={handleReplyReview}>
                                {
                                    loader ?
                                        <div className='d-flex'>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Replying...</h4>
                                        </div>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Reply</h4>
                                }
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ReplyReviewModal
