import moment from 'moment';
import OpenChat from '../../consts/champChatDashboard/OpenChat';
let arr = ["Pending", "Approved", "Denied"]

export const HistoryColumn = (handleFetchAllCallLogs?: any) => [
    {
        id: 1,
        name: "ACTION",
        cell: (row: any) => row?.status ?? "NA",
        minWidth: "200px",
    },
    {
        id: 2,
        reorder: true,
        name: "CHAMP NAME",
        cell: (row: any) => <OpenChat title={row?.beneficiar?.first_name ? row?.beneficiar?.first_name + " " + row?.beneficiar?.last_name + " " + row?.beneficiar?.phone : "NA"} champId={row?.beneficiar?.id} />,
        sortField: 'beneficiar.first_name',
        sortable: true,
        minWidth: "200px",
    },


    {
        id: 3,
        reorder: true,
        name: "AMOUNT",
        cell: (row: any) => row?.amount ?? "NA",
        sortField: 'amount',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 11,
        reorder: true,
        name: "TRANSACTION TYPE",
        cell: (row: any) => <span className={`${row?.transaction_type == "credit" ? "success" : "danger"}`} >{row?.transaction_type}</span>,
        sortField: 'transaction_type',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 10,
        reorder: true,
        name: "REASON",
        cell: (row: any) => row?.reason ? row?.reason?.name || row?.reason?.reason : "",
        sortField: 'reason',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 8,
        reorder: true,
        name: "REQUESTE BY",
        cell: (row: any) => row?.requested_user?.first_name?row?.requested_user?.first_name + " " + row?.requested_user?.last_name  :"NA",
        sortField: 'requested_user?.first_name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 4,
        reorder: true,
        name: "APPROVED BY",
        cell: (row: any) => row?.approver?.first_name ? row?.approver?.first_name + " " + row?.approver?.last_name : "NA",
        sortField: 'approver?.first_name',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 9,
        reorder: true,
        name: "REQUESTE TYPE",
        cell: (row: any) => row?.request?.name ?? "NA",
        sortField: 'request?.name',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 5,
        reorder: true,
        name: "STATUS",
        cell: (row: any) => row?.status ?? "NA",
        sortField: 'status',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 6,
        reorder: true,
        name: "CREATED AT ",
        cell: (row: any) => moment(row?.createdAt).format("DD-MM-YYYY"),
        sortField: 'createdAt',
        sortable: true,
        minWidth: "200px",
    },




];


