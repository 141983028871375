import React, { memo } from 'react';
import { withSwal as DeleteChampRequirmentType } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { useDispatch } from 'react-redux';
export default DeleteChampRequirmentType((props: any) => {

    const dispatch = useDispatch()
    const { swal, confirm, cancel, payloads }: any = props;
    const { payloadData, render, row, setStoreRow = null,  successTitle, FailedTitle , setOpenDeleteModal} = payloads


    React.useEffect(() => {
        let currentStatus = row?.is_deleted
        async function loadData() {
            const response = await postRequest(URL_EndPoints(null)?.deleteChampRequirement, payloadData, null)
            if (response?.data?.status == 200) {
                swal.fire(response?.data?.msg)
                if (setStoreRow) {
                    row.is_deleted = payloadData?.is_deleted
                    setStoreRow(row)
                }
                if (!setStoreRow) {
                    dispatch({
                        type: "CHAMP_REQUIREMENT_TYPE", payload: Math.random()
                    })
                }
                setOpenDeleteModal(null)
            }
            else{
                swal.fire(response?.data?.msg)
                setOpenDeleteModal(null)

            }
        }



        if (render) {
            swal.fire({
                title: `Do you want to ${currentStatus == 1 ? "Re-Activate" : "Delete"} ? ( Warning :  This action is Irreversible)`,
                showDenyButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    loadData()
                } else if (result.isDenied) {
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});
