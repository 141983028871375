import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../../Server/URL_EndPoints';
import { getRequest, postRequest } from '../../../Server/Requests';
import { QueryParams } from '../../../Server/QueryParams';
import SingleSelectSearchCategoryDefault from '../../../Select/SingleSelectSearchCategoryDefault';
import { adminToastRunner } from '../../../../modules/admins/adminUtils';

const shouldRenderSubreason = (StatusUpdateDataList: any, reasonId: any) => StatusUpdateDataList?.find((ele: any) => ele?.id === reasonId && ele?.sub_reason_exists)?.name;

const ProspectStatus = ({ show, setShow, row, defaultData, statusData, prospectSubStatus}: any) => {

    const [_subReasons, setSubReasons] = React.useState<any>(null);
    const [isSubreasonLoading, setIsSubreasonLoading] = useState(false);
    const [loader, setLoader] = useState(false)
    const [payload, setPayload] = useState<any>({
        prospect_status_id: null,
        customerID: row?.id,
        prospect_sub_status_id: null
    })



    const handleChange = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
    }

    const handleVisitorStatus = async () => {
        setLoader(true)
        if (payload?.prospect_status_id) {
            const response = await postRequest(URL_EndPoints(null)?.updateCustomerProspectData, payload, setLoader)
            adminToastRunner(response)
            setShow(false)
        } else {
            toast.error("Status id missing")
            setShow(false)
        }
    }

    const fetchSubReasons = async () => {
        setIsSubreasonLoading(true);
        let newQeueryParams = { ...QueryParams("getMasterSubReason", "?") }
        newQeueryParams.What += "reasonId=" + payload?.prospect_status_id;

        const res = await getRequest(URL_EndPoints(newQeueryParams)?.getMasterSubReason, null);
        res?.data?.data && setSubReasons(res?.data?.data);

        res?.data?.data && setPayload({ ...payload, ["prospect_sub_status_id"]: res?.data?.data[0]?.id });
        setIsSubreasonLoading(false);
    }

    useEffect(() => {
        show && payload?.prospect_status_id && fetchSubReasons();
    }, [payload?.prospect_status_id, show])

    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Status Prospect </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select  Reason"}
                            SelectData={statusData}
                            DynamicKey={"name"}
                            id={"id"}
                            name={"prospect_status_id"}
                            defaultData={defaultData}
                        />
                    </div>

                    {shouldRenderSubreason(statusData, payload?.prospect_status_id) && <div className={`col-12  mb-1`}>
                        {isSubreasonLoading ?
                            <div className='d-flex justify-content-center text-center w-100 border text-success'>
                                <span className="spinner-border spinner-border-sm mt-2 me-3 fw-bold fs-2" role="status" aria-hidden="true"></span>
                                <span className='mt-1 fw-bold'>Loading...</span>
                            </div> :
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChange}
                                HeaderTitle={"Select Sub Status"}
                                SelectData={_subReasons}
                                DynamicKey={"name"}
                                id={"id"}
                                name={"prospect_sub_status_id"}
                                defaultData={{ label: _subReasons?.length ? _subReasons[0]?.name : "", value: _subReasons?.length ? _subReasons?.id : 0 }}
                            />
                        }
                    </div>}

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => handleVisitorStatus()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default ProspectStatus
