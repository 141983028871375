import React, { useState, useEffect, useReducer, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, presentKey, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes, toastNotifier } from '../../consts/components/Utility';
import useOwnMoinState from './useOwnMoinState';
import { initial_rewards_states, rewardsTaskStates } from '../admins/ad_states/rewardsAndPenalties';
import WhatsappNotification from './forms/WhatsappNotification';
import { ToggleColumns } from '../../consts/toggleColumns/ToggleColumns';
import { ShowHideColumns } from '../../consts/ShowHideColumns';
import BulkAssignOldChamp from './forms/BulkAssignOldChamp';
import BulkStatusUpdateChamp from './forms/BulkStatusUpdateChamp';
import BulkAssignBatchDeactivatedForm from './forms/BulkAssignBatchDeactivatedForm';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import Refresh from '../../consts/Refresh';
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList';
import { useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils';


const OldDeactivateChampLists = () => {
  const [storeFilterList, setStoreFilterList] = useOwnMoinState({})
  const [filterkey, setFilterKey] = useOwnMoinState({})
  const [selectedFilterData, setSelectedFilterData] = useOwnMoinState([])
  const [payload, setPayload] = useOwnMoinState(initialPayload(false, {}))
  const [sortedColumns, setSortedColumns] = useOwnMoinState(columns(handleFetchAllCallLogs));
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [centerelliezedComponentStates, setLocalStateDispatch] = useReducer(rewardsTaskStates, initial_rewards_states);

  async function InvokedRendered(payloads: any, setloading: any) {
    setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
    const { data } = await postRequest(URL_EndPoints(null)?.getDeactivatedChampslist, payload, null)
    setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: data?.data })
    setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: data?.recordsTotal })
    setLocalStateDispatch({ type: "RTL_FILTER", payload: data?.filter?.DeactivatedChampsList })
    setLocalStateDispatch({ type: "RTL_PENDING", payload: false })
  }

  useEffect(() => {
    if (centerelliezedComponentStates?.filterData) {
      const newColumns = columns(handleFetchAllCallLogs, centerelliezedComponentStates?.filterData?.map((ele: any) => ele?.id));
      setSortedColumns(newColumns);
    }
  }, [centerelliezedComponentStates?.filterData]);


  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: 10 })
    await InvokedRendered(handlePagination(centerelliezedComponentStates?.search, centerelliezedComponentStates?.sortby, centerelliezedComponentStates?.direction, centerelliezedComponentStates?.page, centerelliezedComponentStates?.perPageSize), null)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  // handle pagination here 
  const handleSort = async (column: any, sortDirection: any) => {
    setLocalStateDispatch({ type: "RTL_SORT_BY", payload: column.sortField })
    setLocalStateDispatch({ type: "RTL_DIRECTION", payload: sortDirection })
    InvokedRendered(handlePagination(centerelliezedComponentStates?.search, column.sortField, sortDirection, centerelliezedComponentStates?.page, centerelliezedComponentStates?.perPageSize), null)
  };

  const handlePageChange = (page: any) => {
    setLocalStateDispatch({ type: "RTL_PAGE", payload: page })
    InvokedRendered(handlePagination(centerelliezedComponentStates?.search, centerelliezedComponentStates?.sortby, centerelliezedComponentStates?.direction, page, centerelliezedComponentStates?.perPageSize), null)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setLocalStateDispatch({ type: "RTL_PER_PAGE_SIZE", payload: newPerPageSize })
  };
  // deboucing  
  useEffect(() => {

    let timer = setTimeout(async function () {
      centerelliezedComponentStates?.search && setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
      centerelliezedComponentStates?.search && InvokedRendered(handlePagination(centerelliezedComponentStates?.search && centerelliezedComponentStates?.search, centerelliezedComponentStates?.sortby, centerelliezedComponentStates?.direction, centerelliezedComponentStates?.page, centerelliezedComponentStates?.perPageSize), null)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [centerelliezedComponentStates?.search])

  useEffect(() => {
    !centerelliezedComponentStates?.search && InvokedRendered(handlePagination(centerelliezedComponentStates?.search, centerelliezedComponentStates?.sortby, centerelliezedComponentStates?.direction, centerelliezedComponentStates?.page, centerelliezedComponentStates?.perPageSize), null)
  }, [centerelliezedComponentStates?.search])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(centerelliezedComponentStates?.search, centerelliezedComponentStates?.sortby, centerelliezedComponentStates?.direction, 1, centerelliezedComponentStates?.perPageSize), null);
  }, [selectedFilterData?.length]);

  function handleFetchAllCallLogs() {
    setLocalStateDispatch({ type: "RTL_FETCH_CALL_LOG_TOGGLER", payload: Math.random() })
  }

  useEffect(() => {
    centerelliezedComponentStates?.fetchCallToggler && setLocalStateDispatch({ type: "RTL_TEMP_FILTERED_DATA", payload: centerelliezedComponentStates?.filterData })
  }, [centerelliezedComponentStates?.fetchCallToggler])


  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setLocalStateDispatch({ type: "RTL_PENDING", payload: true });
      const clone = centerelliezedComponentStates?.filterDataTemp?.slice();
      const customerIds = clone.map((e: any) => e.customer_id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, null);

      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e?.vehicleCustomer?.phone || ele[0]?.To === e?.vehicleCustomer?.phone) || [];
        clone[i].callLogs = foundLog;
      }
      setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: clone })
      setLocalStateDispatch({ type: "RTL_PENDING", payload: false });
    }
    centerelliezedComponentStates?.filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [centerelliezedComponentStates?.filterDataTemp])

  const [showNotifyPopUp, setShowNotifyPopUp] = useState(false);
  const [showAssignPopUp, setShowAssignPopUp] = useState(false)
  const [showStatusPopUp, setShowStatusPopUp] = useState(false);
  const [showAssignBatchPopUp, setShowAssignBatchPopUp] = useState(false);

  const handleSelectedRowsChange = (selectedRows: any) => {
    setLocalStateDispatch({ type: "RTL_FILTERED_SELECTED_ROWS", payload: selectedRows?.selectedRows })
  };

  const handleRefresh = () => {
    InvokedRendered(handlePagination(centerelliezedComponentStates?.search, centerelliezedComponentStates?.sortby, centerelliezedComponentStates?.direction, centerelliezedComponentStates?.page, centerelliezedComponentStates?.perPageSize), null)
  }


  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  const setSearch = (value: any) => {
    setLocalStateDispatch({ type: "RTL_SEARCH", payload: value })
  }


  const [showCampaignContact, setShowCampaignContact] = useState(false);


  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getDeactivatedChampslist")
  }, [])
  
  return (
    <>
      <div>
        <DataTable
          title="New Deactivated Champ Lists"
          progressPending={centerelliezedComponentStates?.pending}
          selectableRows
          columns={reorderColumns(sortedColumns, "oldChampDeactivatedListReorderedItem")}
          data={centerelliezedComponentStates?.filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "oldChampDeactivatedListReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          clearSelectedRows={toggledClearRows}
          // server 
          pagination
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={centerelliezedComponentStates?.totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}

          // server 
          subHeaderComponent={
            <>
              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={centerelliezedComponentStates?.search} setSearch={setSearch} />
                </LeftBox>
                <RightBox>
                  <Refresh refreshNow={handleRefresh} />
                  <ShowHideColumns columns={columns(null)} sortedColumns={sortedColumns} setSortedColumns={setSortedColumns} />
                  <ToggleColumns columns={[{ name: "Due Date", id: "Due Date" }]} from="OldChampDeactivate" />
                  <FilterHandler
                    onChangeHandler={onChangeHandler}
                    actionHandler={actionHandler}
                    getCurrentFilter={getCurrentFilter}
                    removeFilter={removeFilter}
                    payload={payload}
                    Component={DynamicFilter}
                    filterData={centerelliezedComponentStates?.filter}
                    OperatorsData={operators}
                    selectedFilterData={selectedFilterData}
                    filterComponents={KeywiseComponents()}
                    storeFilterList={storeFilterList}
                    filterkey={filterkey}
                    setSeacrch={setSearchFilter}
                    isLoadingFilter={isLoadingFilter}
                    listApiUrl={listApiUrl("getDeactivatedChampslist")}

                  />
                </RightBox>
              </MainContainerWrapper>

              {
                centerelliezedComponentStates?.filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                  <BulkUtilButton title="Bulk Assign Ticket" filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setShow={setShowAssignPopUp} />
                  <BulkUtilButton title="Bulk WhatsApp Notify Champs" filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setShow={setShowNotifyPopUp} />
                  <BulkUtilButton title="Bulk Assign Batch To Leads" filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setShow={setShowAssignBatchPopUp} />
                  <BulkUtilButton title="Bulk Status Update Champs" filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setShow={setShowStatusPopUp} />
                  <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setShow={setShowCampaignContact} />
                </BulkUtilButtonWrapper> : <></>
              }

            </>
          }
        />
      </div>


      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={centerelliezedComponentStates?.filteredSelectedRows?.map((row: any) => row && row?.id)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={handleRefresh}
          userType={"cleaner"}
        /> : <></>
      }

      {
        showNotifyPopUp ? <WhatsappNotification showNotifyPopUp={showNotifyPopUp} setShowNotifyPopUp={setShowNotifyPopUp} filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setToggleClearRows={setToggleClearRows} toggledClearRows={toggledClearRows} /> : <></>
      }
      {
        showAssignPopUp ? <BulkAssignOldChamp showAssignPopUp={showAssignPopUp} setShowAssignPopUp={setShowAssignPopUp} filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setToggleClearRows={setToggleClearRows} toggledClearRows={toggledClearRows} /> : <></>
      }
      {
        showStatusPopUp ? <BulkStatusUpdateChamp showUpdateStatus={showStatusPopUp} setShowUpdateStatus={setShowStatusPopUp} filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setToggleClearRows={setToggleClearRows} toggledClearRows={toggledClearRows} /> : <></>
      }
      {/* {
        showStatusPopUp ? <BulkStatusUpdateChamp showUpdateStatus={showStatusPopUp} setShowUpdateStatus={setShowStatusPopUp} filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows} setToggleClearRows={setToggleClearRows} toggledClearRows={toggledClearRows} /> : <></>
      } */}

      {
        showAssignBatchPopUp ? <BulkAssignBatchDeactivatedForm
          showAssignPopUp={showAssignBatchPopUp}
          setShowAssignPopUp={setShowAssignBatchPopUp}
          filteredSelectedRows={centerelliezedComponentStates?.filteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>

      }
    </>
  )
}

export default OldDeactivateChampLists
