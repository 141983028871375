import React from 'react'
import DropDownFilterMenu from '../../../../consts/BootrapDropdown/DropDownFilterMenu'
import {
  CleanerstatusData,
  JobStatusData,
  RatingsData,
  reported_categories,
  timelotsData,
} from '../../../../consts/CommonData'
import { JobListFilteration } from '../../../../consts/FilterMenuItem/JobListFilteration'
export interface topHeaders {
  Search: any
  setSearch: any
  handleSearchJoblist: any
  handleChangeInputData: any
  payloads: any
  filterationData: any
  status_nameArr: any
  timelostArray: any
  subscriptionsid: any
}
const TopHeader: React.FC<topHeaders> = ({
  handleSearchJoblist,
  subscriptionsid,
  handleChangeInputData,
  Search,
  setSearch,
  payloads,
  filterationData,
  status_nameArr,
  timelostArray,
}) => {
  var selectedSupervisor = ''
  if (filterationData?.supervisorId && filterationData?.supervisorId != '0' && filterationData) {
    let sup: any = filterationData?.AllSuperListData.find((obj: any) => {
      if (+obj.id == +filterationData?.supervisorId) {
        return obj
      }
    })
    selectedSupervisor = sup ? `${sup.first_name} ${sup.last_name}(${sup.phone})` : ''
  }

  var selectedCustomer = ''
  if (filterationData?.customerid && filterationData?.customerid != '0' && filterationData) {
    let customer: any = filterationData?.AllCustomerList?.find((obj: any) => {
      if (+obj.id == +filterationData?.customerid) {
        return obj
      }
    })
    selectedCustomer = customer
      ? `${customer.first_name} ${customer.last_name}(${customer.phone})`
      : ''
  }

  const DefaultDataSupervisorOnly = {
    label:
      !filterationData?.supervisorId || filterationData?.supervisorId == '0'
        ? 'All Supervisor'
        : selectedSupervisor,
    value: filterationData?.supervisorId ?? 0,
  }

  const DefaultCustomerOnly = {
    label:
      !filterationData?.customerid || filterationData?.customerid == 0
        ? 'All Customers'
        : selectedCustomer,
    value: filterationData?.customerid ?? 0,
  }

  const DefaultRateOnly = {
    label:
      !filterationData?.rate || filterationData?.rate == 0
        ? 'All Ratings'
        : RatingsData.find((obj) => obj.id.toString() == filterationData?.rate.toString())?.name,
    value: filterationData?.rate ?? 0,
  }

  const DefaultDataTimslotOnly = {
    label:
      filterationData?.timeslotId == 0
        ? 'Select Timeslot'
        : timelotsData.find((obj) => obj.id.toString() == filterationData?.timeslotId.toString())
          ?.name,
    value: filterationData?.timeslotId,
  }
  const DefaultDataCategoryOnly = {
    label: JobStatusData[filterationData.jobStatusId].name,
    value: filterationData.jobStatusId,
  }
  const DefaultDataServiceTypeOnly = {
    label:
      filterationData?.selectedType == 0
        ? 'Service Type'
        : filterationData?.JobTypesListData.find(
          (obj: { id: any }) => obj.id.toString() == filterationData?.selectedType.toString()
        )?.name,
    value: filterationData?.selectedType,
  }
  const DefaultDataReportedategoryOnly = {
    label:
      filterationData?.selectedReportedCategoryId == 0
        ? 'Reported Category'
        : reported_categories.find(
          (obj: { id: any }) =>
            obj.id.toString() == filterationData?.selectedReportedCategoryId.toString()
        )?.name,
    value: filterationData?.selectedReportedCategoryId,
  }
  return (

    <>

      {!subscriptionsid && (
        <DropDownFilterMenu
          ParentData={{
            handleChangeInput: handleChangeInputData,
            handleActions: handleSearchJoblist,
            payloads: payloads,
            DefaultDataTimeSlot: DefaultDataTimslotOnly,
            DefaultDataSupervisorOnly: DefaultDataSupervisorOnly,
            DefaultDataServiceTypeOnly: DefaultDataServiceTypeOnly,
            DefaultDataReportedategoryOnly: DefaultDataReportedategoryOnly,
            DefaultDataCategoryOnly: DefaultDataCategoryOnly,
            DefaultCustomerOnly: DefaultCustomerOnly,
            DefaultRateOnly: DefaultRateOnly,
            timelotsData: timelotsData,
            SelectedFilterTicketCateogryDefaultData: JobStatusData,
            AllSuperListDefaultData: filterationData?.AllSuperListData,
            JobTypesListData: filterationData?.JobTypesListData,
            CleanerstatusData: CleanerstatusData,
            reported_categories: reported_categories,
            CleanerfilterDataList: filterationData?.AllCleanerList,
            CustomerfilterData: filterationData?.AllCustomerList,
            RatingsData: RatingsData,
            PaidUnPaidData: [{ name: "Paid", id: 1 }, { name: "Unpaid", id: 2 }],
            tag: filterationData?.tag,
            by: filterationData?.by,
            setstoreSeachKeywords: filterationData?.setstoreSeachKeywords,
            searchDataCleaner: filterationData?.searchDataCleaner,
            isLoading: filterationData?.isLoading,
            selectSearchValue: filterationData?.selectSearchValue,
          }}
          Component={JobListFilteration}
        ></DropDownFilterMenu>
      )}


    </>


  )
}
export default TopHeader
