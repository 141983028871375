import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { KTCardBody } from '../../../_metronic/helpers'
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import IsLoading from '../../consts/IsLoading/IsLoading'
import Refresh from '../../consts/Refresh'
import { postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal'
import { ModelTrainingFilteration } from './ModelTrainingFilteration'
import { BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton'

let modelTrainedCountColumns: any = {
    "car_count": "Car Count",
    "bike_count": "Bike Count",
    "fake_or_real_true_count": "Real Count",
    "fake_or_real_false_count": "Fake Count",
    "door_open_true_count": "Door Open Count",
    "door_open_false_count": "Door Closed Count"
}

const ModelTrainingList = () => {

    const [filterData, setFilterData] = useState<any>([])
    const [mainData, setMainData] = useState([])
    const [modelTrainedTotalCountData, setModelTrainedTotalCountData] = useState<any>({})
    const [adminList, setAdminList] = useState([])
    const [pending, setPending] = useState(false)
    const [search, setSearch] = useState("")
    const [payload, setPayload] = useState({
        date: "",
        userid: ""
    })

    const getAdminList = async () => {
        const { data } = await postRequest(URL_EndPoints(null)?.getMasterAdminUserList, {}, null)
        setAdminList(data?.data || [])
    }

    const getTrainingListData = async () => {
        setPending(true)
        const trainingListData = await axios.get("http://162.55.63.127:3001/training_data").then((res) => {
            return res?.data
        }).catch((error) => setPending(false))

        const totalModelTrainedListData = await axios.get("http://162.55.63.127:3001/total").then((res) => {
            return res?.data
        }).catch((error) => setPending(false))

        setFilterData(trainingListData || [])
        setMainData(trainingListData || [])
        setModelTrainedTotalCountData(totalModelTrainedListData || {})
        setPending(false)

    }

    useEffect(() => {
        getAdminList()
        getTrainingListData()
    }, [])

    useEffect(() => {
        const searchData = AllFielSearch(mainData, search)
        setFilterData(searchData)
    }, [search])

    let colummsModelTraining: any = filterData?.length > 0 && filterData[0]?.model_trained || {}
    let colummsModelTrainingKeys: any = Object.keys(colummsModelTraining || {})

    const removeUnderScroe = (str: any) => {
        return str.split("_")?.join(" ")
    }

    const getUserDetail = (userId: any) => {
        const user: any = adminList?.find((ele: any) => ele?.id == userId)
        return user ? user?.first_name + " " + user?.last_name : "NA"
    }

    const refreshNow = () => {
        getTrainingListData()
    }

    const handleChangeInputData = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
    }

    const searchHandler = () => {
        let payDate = payload?.date ? moment(payload?.date).format("DD-MM-YYYY") : ""
        let payUser = payload?.userid

        const dateFilterUpdatedData = mainData?.filter((filter: any) => {
            let eleDate = moment(filter?.date).format("DD-MM-YYYY")
            let eleUser = filter?.userid
            let filterCondition = payDate && payUser ? eleDate === payDate && eleUser === payUser : payDate ? eleDate === payDate : payUser ? eleUser === payUser : filter
            return filterCondition
        })

        setFilterData(dateFilterUpdatedData)
    }

    let totalModelTrainedCountKeys = Object.keys(modelTrainedTotalCountData || {})

    return (
        <KTCardBody className='text-start mt-4'>
          <div className='mb-2 fw-semibold fs-4'>Model Training List</div>
            <div className='container-fluid px-3'>
                <div className='row'>
                    <div className='px-0 col-xl-3 col-lg-3 col-md-4 col-sm-12 d-flex justify-content-start align-items-center'>
                        <SearchBoxModal search={search} setSearch={setSearch} />
                    </div>
                    <div className='px-0 col-xl-9 col-lg-9 col-md-4 col-sm-12 d-flex justify-content-end align-items-center'>
                        <Refresh refreshNow={refreshNow} />
                        <DropDownFilterMenu
                            ParentData={{
                                handleChangeInputData: handleChangeInputData,
                                handleActions: searchHandler,
                                payloads: payload,
                                adminList: adminList,
                            }}
                            Component={ModelTrainingFilteration}
                        ></DropDownFilterMenu>
                    </div>

                </div>
            </div>
            <div className='container-fluid px-3 mb-4 d-flex justify-content-start align-items-center gap-2'>
                <BulkUtilButtonWrapper>
                    {
                        totalModelTrainedCountKeys?.map((ele: any) =>
                            <div className=' d-flex me-2 cursor-pointer '>
                                <span >{modelTrainedCountColumns[ele] ?
                                    modelTrainedCountColumns[ele] :
                                    removeUnderScroe(ele)}:  &nbsp;</span>
                                <span className='badge badge-light-primary text-dark rounded-0'> {modelTrainedTotalCountData[ele]} </span>
                            </div>
                        )
                    }
                </BulkUtilButtonWrapper> : <></>
            </div>
            {
                pending ? <IsLoading /> : <table className="table table-bordered table-hover table-sm">
                    <thead>
                        <tr className='border px-3 bg-secondary'>
                            <th scope="col p-2">ID</th>
                            <th scope="col">USER ID</th>
                            <th scope="col">TRAINING DATE</th>
                            <th scope="col">TOTAL TRAININGS</th>
                            {
                                colummsModelTrainingKeys?.map((ele: any) => <th scope="col">{removeUnderScroe(ele?.toUpperCase())}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {filterData?.map((element: any) => {
                            let modelTrainingData = Object.keys(element?.model_trained)
                            return (
                                <tr className={"border"}>
                                    <td className='p-2'>{element?.id} </td>
                                    <td >{getUserDetail(element?.userid)}  </td>
                                    <td >{moment(element?.date).format("DD-MM-YYYY")}  </td>
                                    <td >{element?.total_trainings_on_date}  </td>
                                    {
                                        modelTrainingData?.map((ele: any) => <th scope="col">{element?.model_trained[ele]}</th>)
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }

        </KTCardBody>
    )
}

export default ModelTrainingList
