

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { postRequest } from '../../../Server/Requests'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import SingleSelectSearchDetailsDefault from '../../../filtersComponents/SingleSelectSearchDetailsDefault'
import { CheckboxModal } from '../../../widgets/forms/FormsModals'
import { useDispatch } from 'react-redux'
import { openChatByChamp } from '../../../../../Redux/champChatDashboardReducer/champsActions'
const UpdateAreaNameList = ({ show, setCloseModal, row, AreaList, updateAreaDetails }: any) => {
    const dispatch = useDispatch()


    const [loader, setLoader] = useState<boolean>(false)
    const [payload, setPayload] = useState<any>({
        id: updateAreaDetails?.id,
        champId: row?.id,
        areaId: null,
        status: updateAreaDetails?.status
    })

    const handleSubmitAssign = async () => {
        if (payload?.areaId) {
            setLoader(true)

            const { data } = await postRequest(URL_EndPoints()?.updateCleanerArea, payload, setLoader)
            if (data?.status == 200) {
                toast.success(data?.msg, { position: "top-center" })
                setCloseModal(false)
                dispatch(openChatByChamp({ champId: row?.id }, row?.id) as any)
            }
            else {
                toast.error(data?.msg, { position: "top-center" })
                setCloseModal(false)
            }
        }
        else {
            toast.error("Area id is missing", { position: "top-center" })

        }
    }

    const onChangeHandler = (event: any, name: any) => {
        if (event == null) {
            setPayload({ ...payload, ["areaId"]: null })
        }
        else {
            setPayload({ ...payload, ["areaId"]: event?.value })
        }
    }

    return (
        <>
            {show ? <Modal show={show} onHide={() => setCloseModal(false)} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Area Name </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='col-12 mb-4'>
                        <SingleSelectSearchDetailsDefault
                            handleChangeInputData={onChangeHandler}
                            HeaderTitle='Select Area Name'
                            SelectData={AreaList || []}
                            DynamicKeys={["areaname"]}
                            id={"id"}
                            name={"areaId"}
                            targetValue={"value"}
                            defaultData={updateAreaDetails ? { label: updateAreaDetails?.areaDetail?.areaname, value: updateAreaDetails?.areaDetail?.id } : null}
                        // defaultData={null}
                        ></SingleSelectSearchDetailsDefault>
                    </div>

                    <div className='col-12 mb-4'>
                        <CheckboxModal
                            classes=""
                            name="status"
                            payload={payload}
                            setPayload={setPayload}
                            // defValue={null}
                            defValue={payload?.status}
                        />
                    </div>

                    <button disabled={loader} className={`btn btn-sm btn-${loader ? "warning" : "primary"} fs-6 col-12 w-100 `} onClick={handleSubmitAssign}>
                        {loader ?
                            <>

                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                <span>Updating...</span>

                            </>

                            : " Update Area Name"}
                    </button>

                </Modal.Body>
            </Modal> : ''}
        </>
    )
}


export default UpdateAreaNameList
