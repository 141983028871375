import ChampActions from './ChampActions';
import ChampBonusAmountUpdate from './forms/ChampBonusAmountUpdate';
import IsDeleted from './forms/IsDeleted';
import MinPayout from './forms/MinPayout';
import PriorityForm from './forms/PriorityForm';
import ReferralBonusAmountUpdate from './forms/ReferralBonusAmountUpdate';
import UpdateAreaManager from './forms/UpdateAreaManager';
import UpdateChampRequirementTypForArea from './forms/UpdateChampRequirementTypForArea';
import UpdateChampRequirementType from './forms/UpdateChampRequirementType';
import UpdateReplacementFor from './forms/UpdateReplacementFor';
import UpdateType from './forms/UpdateType';


export const columns = () => [
    {
        id: 1,
        reorder: true,
        name: 'ACTIONS',
        cell: (row: any) => (
            <>
                <ChampActions row={row} />
            </>
        ),
        sortable: true,
        width: "100px"
    },
    {
        id: 16,
        reorder: true,
        name: 'APPROVED',
        selector: (row: any) => row?.approved_by ? "Approved" : "Not Approved",
        sortable: true,
        sortField: "approved_by",
        // id: "css"
    },
    {
        id: 2,
        reorder: true,
        name: 'ID',
        selector: (row: any) => row?.id,
        sortable: true,
        sortField: "id",
        // id: "css"
    },
    {
        id: 3,
        reorder: true,
        name: 'TYPE',
        // selector: (row: any) => row?.requirementType?.name,
        selector: (row: any) => <UpdateType row={row} />,

        sortable: true,
        sortField: "requirementType.name",
        // id: "css"
    },
    {
        id: 4,
        reorder: true,
        width: "200px",
        name: 'TRAINING AREA',
        // selector: (row: any) => row?.champ_requirement_area ? row?.champ_requirement_area?.areaname : "NA",
        // selector: (row: any) => row?.champ_requirement_area ? row?.champ_requirement_area?.areaname : "NA",
        selector: (row: any) => <UpdateChampRequirementTypForArea row={row} />,
        sortField: "champ_requirement_area.areaname",
        minWidth: "300px",
        sortable: true,
        // id: "css"
    },
    {
        id: 18,
        reorder: true,
        width: "200px",
        name: 'TRAINING LOCATION',
        // selector: (row: any) => row?.trainingLocation ? row?.trainingLocation?.areaname : "NA",
        selector: (row: any) => <UpdateChampRequirementType row={row} />,
        sortField: "trainingLocation.locationname",
        minWidth: "300px",
        sortable: true,
        // id: "css"
    },
    {
        id: 5,
        reorder: true,
        width: "200px",
        name: 'PRIORITY', // UPDATE HOGA
        selector: (row: any) => <PriorityForm row={row} />,
        sortable: true,
        sortField: "priority_level.priority",
        // id: "css"
    },
    {
        id: 6,
        reorder: true,
        width: "200px",
        name: 'MIN PAYOUT', // UPDATE HOGA
        // selector: (row: any) => row?.min_payout ? row?.min_payout : "NA",
        selector: (row: any) => <MinPayout row={row} />,
        sortField: "min_payout",

        sortable: true,
        // id: "css"
    },
    {
        id: 7,
        reorder: true,
        width: "200px",
        name: 'REFERRAL  BONUS AMOUNT', // UPDATE HOGA,
        // selector: (row: any) => row?.referral_bonus_amount ? row?.referral_bonus_amount : "NA",
        selector: (row: any) => <ReferralBonusAmountUpdate row={row} />,
        sortField: "referral_bonus_amount",

        sortable: true,
        // id: "css"
    },
    {
        id: 8,
        reorder: true,
        width: "200px",

        name: 'CHAMP BONUS AMOUNT',// UPDATE HOGA
        // selector: (row: any) => row?.champ_bonus_amount ? row?.champ_bonus_amount : "NA",
        selector: (row: any) => <ChampBonusAmountUpdate row={row} />,
        sortField: "champ_bonus_amount",
        sortable: true,
        // id: "css"
    },
    {
        id: 9,
        reorder: true,
        width: "200px",
        name: 'VACANCY CREATED BY ',
        selector: (row: any) => row?.champ_requirement_added_by?.first_name ? row?.champ_requirement_added_by?.first_name + " " + row?.champ_requirement_added_by?.last_name : "NA",
        sortField: "champ_requirement_added_by.first_name",
        sortable: true,
        // id: "css"
    },
    {
        id: 17,
        reorder: true,
        width: "200px",
        name: 'ASSIGNED AREA MANAGER',
        // selector: (row: any) => row?.champ_requirement_assigned_supervisor?.first_name ? row?.champ_requirement_assigned_supervisor?.first_name + " " + row?.champ_requirement_assigned_supervisor?.last_name : "NA",
        selector: (row: any) => <UpdateAreaManager row={row} />,

        sortable: true,
        sortField: "champ_requirement_assigned_supervisor.first_name",
        // id: "css"
    },
    {
        id: 10,
        reorder: true,
        width: "200px",
        name: 'VACANCY APPROVED BY ',
        selector: (row: any) => row?.champ_requirement_approver_admin?.first_name ? row?.champ_requirement_approver_admin?.first_name + " " + row?.champ_requirement_approver_admin?.last_name : "NA",
        sortable: true,
        sortField: "champ_requirement_approver_admin.first_name",
        // id: "css"
    },
    {
        id: 11,
        reorder: true,
        width: "200px",

        name: 'VACANCY FILLED BY ',
        selector: (row: any) => row?.champ_requirement_completed_champ?.first_name ? row?.champ_requirement_completed_champ?.first_name + " " + row?.champ_requirement_completed_champ.last_name : "NA",
        sortable: true,
        sortField: "champ_requirement_completed_champ.first_name",
        // id: "css"
    },

    {
        id: 12,
        reorder: true,
        width: "200px",
        name: 'VACANCY FILLED DATE',
        selector: (row: any) => row?.completed_date_time ? row?.completed_date_time : "NA",
        sortable: true,
        sortField: "completed_date_time",
        // id: "css"
    },
    {
        id: 13,
        reorder: true,
        width: "200px",
        name: 'REPLACEMENT FOR',
        // selector: (row: any) => row?.replacement_champ?.first_name ? row?.replacement_champ?.first_name + " " + row?.replacement_champ?.last_name : "NA",
        selector: (row: any) => <UpdateReplacementFor row={row} />,

        sortable: true,
        sortField: "replacement_champ.first_name",
        // id: "css"
    },
    // {
    //     id: 14,
    //     reorder: true,
    //     width: "200px",
    //     name: 'IS DELETED', // UPDATE HOGA BUT YES NO POP 0/1 SEND
    //     selector: (row: any) => <IsDeleted row={row} />,
    //     sortable: true,
    //     sortField: "is_deleted",
    //     // id: "css"
    // },
    {
        id: 15,
        reorder: true,
        width: "200px",
        name: 'VACANCY CREATED ON',
        selector: (row: any) => row?.added_date_time ? row?.added_date_time : "NA",
        sortable: true,
        sortField: "added_date_time",
        // id: "css"
    },


];
