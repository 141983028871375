import React, { useState } from 'react'
import { ColorRing } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LocalBaseURL } from '../../../../../BaseURLmanagement';
import { getAllJobStatusActionsV2, PostRequestUpdateStatus } from '../API';
import { attendenceStatusInterfaces } from '../Interfaces';
import { ContainerCss, PopCloseFormNotification, PopCloseFormNotificationPtag, Create_BtnCss } from '../../../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet';

interface Props {
    handleDiloagboxform: (event: number | string) => void
    attendancestatusList: attendenceStatusInterfaces
}
const UpdateStatusform = ({ handleDiloagboxform, attendancestatusList }: Props) => {
    LocalBaseURL()
    const dispatch = useDispatch()

    const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
    const [JobStatusList, SetJobStatusList] = useState<any>([])
    const [notAttHOme, setnotAttHOme] = useState<boolean>(true)
    const [markAttend, setmarkAttend] = useState<boolean>(false)
    const [reportAttendance, setReportAttendance] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [payloads, setPayloads] = useState({
        user_id: attendancestatusList?.user_id,
        customerid: attendancestatusList?.customerid,
        attendence_id: attendancestatusList?.attendence_id,
        job_status_id: "",
        job_action_id: "",
        field_type_id: "",
        notathome: notAttHOme,
        mark_attendence: markAttend,
        time: "",
        field_value: "",
    })
    const handleChangeFormData = (event: any) => {
        if (event.target.name === "notathome") {
            if (notAttHOme === true && markAttend == false) {
                setnotAttHOme(!notAttHOme)
            }
            else if (notAttHOme === false && markAttend == false) {
                setnotAttHOme(!notAttHOme)
            }
            else {
                setmarkAttend(!markAttend)
                setnotAttHOme(!notAttHOme)
            }
            setReportAttendance(false)

        }
        else if (event.target.name === "mark_attendence") {
            if (markAttend === true && notAttHOme == false) {
                setmarkAttend(!markAttend)
            }
            else if (markAttend === false && notAttHOme == false) {
                setmarkAttend(!markAttend)
            }
            else {
                setnotAttHOme(!notAttHOme)
                setmarkAttend(!markAttend)
            }
            setReportAttendance(false)

        } else if (event.target.name === "report_attendance") {
            setmarkAttend(false)
            setReportAttendance(!reportAttendance)
            setnotAttHOme(false)
            setmarkAttend(false)
        } else {
            setPayloads({ ...payloads, [event.target.name]: event.target.value })
        }
    }
    const handleDiloagboxformHere = async () => {
        setIsLoading(true)
        const payloads2 = {
            user_id: attendancestatusList?.user_id,
            customerid: attendancestatusList?.customerid,
            attendence_id: attendancestatusList?.attendence_id,
            job_status_id: notAttHOme === false && markAttend === false ? payloads.job_status_id : attendancestatusList?.job_status_id,
            job_action_id: attendancestatusList?.job_action_id,
            field_type_id: attendancestatusList?.field_type_id,
            notathome: notAttHOme ? 1 : 0,
            mark_attendence: markAttend ? 1 : 0,
            time: markAttend === true && notAttHOme === false ? payloads.time : "",
            field_value: notAttHOme === false && markAttend === false ? payloads.field_value : "",
        }

        if (notAttHOme || markAttend || reportAttendance) {
            const SubmittedRequest = await PostRequestUpdateStatus(localKeyCheck, payloads2)
            if (SubmittedRequest.status === 200) {
                toast.success("Attendance status updated successfully")
                dispatch({ type: "JOB_LIST_REFRESH", payload: Math.random() })

            } else {
                toast.error("!Updatation failed")
            }
        } else {
            toast.error("Select any checkbox!")
        }
        setIsLoading(false)

    }

    const handleClose = () => {
        handleDiloagboxform(0)
    }
    React.useEffect(() => {
        async function getCityInvoked() {
            const jobstatus = await getAllJobStatusActionsV2(localKeyCheck)
            SetJobStatusList(jobstatus?.data?.data)
        }
        getCityInvoked()
    }, [])
    return (
        <div className="p-8" style={ContainerCss}>
            <div style={PopCloseFormNotification}><p style={PopCloseFormNotificationPtag} onClick={handleClose} >X</p></div>
            <h4 className='text-center'>Update Status</h4>
            <div className="p-5 mr-auto">
                <div className='modal-content ' >
                    <div className="row mb-2">
                        <div className="col-12  mb-3 d-flex  align-items-center">
                            <input
                                name='notathome'
                                type='checkbox'
                                className='me-3'
                                autoComplete='off'
                                id="notathome"
                                checked={notAttHOme}
                                onChange={handleChangeFormData}
                            />
                            <h6 className='mt-2'>Mark Not At Home</h6>
                        </div>
                        <div className="col-12  mb-3  d-flex  align-items-center">
                            <input
                                name='mark_attendence'
                                id="mark_attendence"
                                type='checkbox'
                                className='me-3'
                                autoComplete='off'
                                checked={markAttend}
                                onChange={handleChangeFormData}
                            />
                            <h6 className='mt-2'>Mark Attendance</h6>
                        </div>
                        <div className="col-12  mb-3  d-flex  align-items-center">
                            <input
                                name='report_attendance'
                                id="report_attendance"
                                type='checkbox'
                                className='me-3'
                                autoComplete='off'
                                checked={reportAttendance}
                                onChange={handleChangeFormData}
                            />
                            <h6 className='mt-2'>Report Attendance</h6>
                        </div>
                    </div>
                    {
                        markAttend ? <div className="row mb-5">
                            <h6 >Enter Attendence Date</h6>
                            <div className="col-12  mb- d-flex  align-items-center">
                                <input type="datetime-local" name="time" onChange={handleChangeFormData} />
                            </div>
                        </div>
                            : <></>
                    }
                    {
                        reportAttendance ?
                            <div className="row mb-5">
                                <h6 >Select Stuats</h6>
                                <div className="col-12  mb- d-flex  align-items-center">
                                    <select
                                        className='form-select form-select-solid me-2'
                                        name="job_status_id"
                                        onChange={handleChangeFormData}
                                    >
                                        <option value="" className="bg-primary text-white">Select Job Status</option>
                                        {
                                            JobStatusList?.map((ele: any) => {
                                                return (
                                                    <>
                                                        <option value={ele.id} className="bg-primary text-white" disabled={ele?.children?.length > 0 ? true : false}>{ele.status_name}</option>
                                                        {ele?.children?.map((ele: any, index: number) => {
                                                            return (
                                                                <>
                                                                    <option value={ele.id} className="bg-light-success text-dark" disabled={ele?.children?.length > 0 ? true : false}>      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {ele.status_name}</option>
                                                                    {
                                                                        ele?.children?.map((ele: any) => {
                                                                            return (
                                                                                <>
                                                                                    <option value={ele.id}  > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {ele.status_name}</option>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        reportAttendance ? <div className="col-12  mb-3">
                            <h5>Write</h5>
                            <input
                                placeholder='Messsage Here...'
                                name='field_value'
                                type='text'
                                value={payloads.field_value}
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={handleChangeFormData}
                            />
                        </div> : <></>
                    }
                </div>
                {isLoading ?

                    <div className="d-flex justify-content-end">
                        <ColorRing
                            visible={true}
                            height="35"
                            width="35"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    </div> :


                    <button
                        style={Create_BtnCss}
                        type='submit'
                        className='btn btn-sm btn-primary '
                        data-kt-users-modal-action='submit'
                    >
                        <span className='indicator-label' onClick={handleDiloagboxformHere}>Update Status</span>
                    </button>}

            </div>
        </div>
    )
}
export default UpdateStatusform
