import { useEffect, useState } from 'react';
import moment from 'moment';
import OpenChat from '../../consts/champChatDashboard/OpenChat';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { postRequest } from '../../consts/Server/Requests';
import { toast } from 'react-toastify';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit } from '@fortawesome/free-solid-svg-icons';
import AdminForm from '../admins/adminForms/AdminForm';
import MaskedPhoneNumber from '../../consts/MaskedPhoneNumber';
let arr = ["Pending", "Approved", "Denied"]

export const columns = (handleFetchAllCallLogs?: any, data?: any) => [
    {
        id: 1,
        name: "ACTION",
        cell: (row: any) => <Actions row={row} />,
        minWidth: "200px",
    },
    {
        id: 2,
        reorder: true,
        name: "CHAMP NAME",
        cell: (row: any) => <OpenChat title={row?.cleanerReferBy?.first_name || row?.cleanerReferBy?.last_name ? row?.cleanerReferBy?.first_name + " " + row?.cleanerReferBy?.last_name : "NA"} champId={row?.cleanerReferBy?.id} phone={<MaskedPhoneNumber phoneNumber={row?.cleanerReferBy?.phone} />} />,
        sortField: 'cleanerReferBy.first_name',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 3,
        reorder: true,
        name: "CANDIDATE NAME",
        cell: (row: any) => <OpenChat title={row?.first_name || row?.last_name ? row?.first_name + " " + row?.last_name : "NA"} champId={row?.id} phone={<MaskedPhoneNumber phoneNumber={row?.phone} />} trackData={data} />,
        sortField: 'first_name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 4,
        reorder: true,
        name: "CANDIDATE STATUS",
        cell: (row: any) => row?.cleanerMasterOperationReasons?.name ?? "Null",
        sortField: 'cleanerMasterOperationReasons.name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 12,
        reorder: true,
        name: "CHAMP STATUS",
        cell: (row: any) => row?.champ_status ? row?.champ_status : "Null",
        sortField: 'champ_status',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 5,
        reorder: true,
        name: "LEAD CREATION DATE",
        cell: (row: any) => moment(row?.createdAt).format("YYYY-MM-DD") ?? "Null",
        sortField: 'createdAt',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 6,
        reorder: true,
        name: "ACTIVATION DATE",
        cell: (row: any) => row?.champHistory?.date ?? "Null",
        sortField: 'champHistory.date',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 7,
        reorder: true,
        name: "NO. OF DAYS PASSED",
        cell: (row: any) => row?.numOfDaysPassed ?? "Null",
        // sortField: 'numOfDaysPassed',
        // sortable: true,
        minWidth: "200px",
    },

    {
        id: 8,
        reorder: true,
        name: "PAYMENT AMOUNT",
        cell: (row: any) => <PaymentAmount row={row} />,
        sortField: 'champReferralRequest.amount',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 9,
        reorder: true,
        name: "PAYMENT STATUS",
        cell: (row: any) => row?.champReferralRequest?.status ?? "Null",
        sortField: 'champReferralRequest.status',
        sortable: true,
        minWidth: "200px",
    },

];

const PaymentAmount = ({ row }: any) => {
    let formData = [
        {
            HeaderTitle: 'Amount ',
            filterApi: '',
            label: {
                show: true,
                name: 'Amount',
            },
            valueType: 'textBox',
            inputType: 'number',
            displayFields: [""],
            valueField: '',
            name: 'amount',
            from: '',
            to: '',
            isRequired: true,
            errMessage: 'Amount is required !',
            searchable: false,
            wa_number_id: null,
            userType: null,
        }
    ]
    const [show, setShow] = useState(false)
    const [storeRow, setStoreRow] = useState(row)
    useEffect(() => setStoreRow(row), [row])

    const updatedAmount = (payload: any) => {
        row.champReferralRequest.amount = payload?.amount
        setStoreRow(row)
    }

    let isUpdateAllowed = row.champReferralRequest ? true : false
    return (
        <>
            <span className='me-2'>
                {storeRow?.champReferralRequest?.amount}
            </span> <FontAwesomeIcon icon={isUpdateAllowed ? faEdit : faBan} className='text-muted' onClick={() => isUpdateAllowed ? setShow(true) : {}} />


            {
                show ? <AdminForm
                    show={show}
                    setShow={setShow}
                    refreshNow={() => { }}
                    toastMessage="message"
                    formData={formData}
                    staticFormData={null}
                    setUpdatedData={updatedAmount}
                    initialData={{
                        refferalRequestId: row?.champReferralRequest?.id,
                        amount: storeRow?.champReferralRequest ? storeRow?.champReferralRequest?.amount : 0,
                    }}
                    keyName="updateReferalAmount"
                    formTitle={`Update Champ Refferal Request Amount`}
                    formSubmitApiUrl="updateChampRefferalRequestAmount"
                /> : <></>
            }
        </>
    )
}

const Actions = ({ row }: any) => {
    const [storeRow, setStoreRow] = useState<any>(row)

    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const [pending, setPending] = useState<any>(false)
    const hnadleUpdate = async (value: any) => {
        setPending(true)
        const response: any = await postRequest(URL_EndPoints(null)?.updateRequestForReward, {
            "id": row?.champReferralRequest?.id,
            "status": value?.toLowerCase()
        }, setPending)
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
        if (response?.data?.status === 200) {
            row.champReferralRequest.status = value?.toLowerCase()
            setStoreRow(row)
        }

    }

    return (
        <>
            {
                pending ? <IsLoading /> : <select
                    name='date_typer'
                    data-control='select2'
                    data-placeholder='Period'
                    className='form-select'
                    defaultValue={storeRow?.champReferralRequest?.status}
                    onChange={(event: any) => hnadleUpdate(event.target.value)}
                    disabled={row?.champReferralRequest == null}
                >
                    {
                        row?.champReferralRequest == null && < option  > No Referral Request </option>
                    }
                    {
                        arr?.map((ele: any) => {
                            return (
                                <>
                                    < option value={ele?.toLowerCase()} >{ele}</option>
                                </>
                            )
                        })
                    }
                </select>
            }
        </>
    )
}
