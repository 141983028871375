import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import { Circles } from 'react-loader-spinner'
import CallWaiting from '../../../consts/SweetAlert/CallWaiting';
import { isAllowedChampNumber } from '../../../consts/components/Utility';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { postRequest } from '../../../consts/Server/Requests';

const RenderData = ({ supervisors }: any) => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [message, setMessage] = useState("Pls wait call connecting")
    const [Re_Render, setRe_Render] = useState<any>(null)
    const [Hide, setHide] = useState<any>(null)
    const [icons, setIcons] = useState<any>("")
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip">
            {props}
        </Tooltip>
    );
    const CallCleaner = async (type: any, cleaner_id: any) => {
        setLoading(true)
        setHide(20000)
        setRe_Render(Math.random())
        const paylaod: any = {
            userid: JSON.parse(localStorage.getItem("user") || "0"),
        }
        paylaod[type] = cleaner_id
        const response = await postRequest(URL_EndPoints()?.clickToCallFromTicket, paylaod, null)
        if (response?.data?.status === 200) {
            setMessage("Call connected successfully")
            setIcons("success")
            setLoading(false)
        }
        else {
            setMessage("Call not initiated ")
            setLoading(false)
        }
    }

    let res: any = []
    if (supervisors && supervisors.length) {
        supervisors.forEach((supervisor: any) => {
            res.push(<OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(isAllowedChampNumber(supervisor?.phone, supervisor?.id))}
            >
                <span className="badge badge-dark mr-1 mb-1 mt-2">
                    {supervisor?.first_name} {supervisor?.last_name}
                    {isLoading ? <Circles
                        height="20"
                        width="20"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    /> : <div className='call-button' onClick={() => CallCleaner('supervisorid', supervisor?.id)}><KTSVG path='/media/icons/duotune/Info/call002.svg' className='svg-icon-2 ml-1' /></div>}
                </span>
            </OverlayTrigger>)
        });
    }
    
    // if (supervisors && supervisors.length) {
    //     supervisors.forEach((supervisor: {
    //         supervisorData: {
    //             id: any; phone: any; first_name: any; last_name: any;
    //         };
    //     }) => {
    //         res.push(<OverlayTrigger
    //             placement="right"
    //             delay={{ show: 250, hide: 400 }}
    //             overlay={renderTooltip(isAllowedChampNumber(supervisor.supervisorData.phone, supervisor.supervisorData?.id))}
    //         >
    //             <span className="badge badge-dark mr-1 mb-1 mt-2">
    //                 {supervisor.supervisorData.first_name} {supervisor.supervisorData.last_name}
    //                 {isLoading ? <Circles
    //                     height="20"
    //                     width="20"
    //                     color="#4fa94d"
    //                     ariaLabel="blocks-loading"
    //                     wrapperStyle={{}}
    //                     wrapperClass=""
    //                     visible={true}
    //                 /> : <div className='call-button' onClick={() => CallCleaner('supervisorid', supervisor.supervisorData.id)}><KTSVG path='/media/icons/duotune/Info/call002.svg' className='svg-icon-2 ml-1' /></div>}
    //             </span>
    //         </OverlayTrigger>)
    //     });
    // }

    return (
        <div className='sup-cell'>
            {res}
            {
                Hide === 20000 && <CallWaiting Render={Re_Render} Hide={Hide} message={message} icons={icons} setIcons={setIcons} setMessage={setMessage} setRe_Render={setRe_Render} />
            }
        </div>
    )
}
export default RenderData