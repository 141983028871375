export const FetchAddressPincodeByLatLngUtils = async (
  lat: any,
  lng: any,
  setAddress: any,
  setPincode: any,
  payload?: any,
  setPayload?: any
) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB1TLe_5fP2B85oU9mvzNnJbN6QuHjdjHE`
    )

    if (response.ok) {
      const data = await response.json()
      const {results} = data

      if (results.length > 0) {
        const addressComponents = results[0].address_components

        const formattedAddress = results[0].formatted_address
        const formattedPincode = addressComponents.find((component: any) =>
          component.types.includes('postal_code')
        )?.long_name

        setAddress(formattedAddress)
        setPincode(formattedPincode)

        setPayload({...payload, ['pincode']: formattedPincode, ['address']: formattedAddress})
        return true
      }
    } else {
      
      return false
    }
  } catch (error) {
    return false
  }
}

// const fetchAddressAndPincode = async (lat:any, lng:any) => {
//   try {
//     const response = await axios.get(
//       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB1TLe_5fP2B85oU9mvzNnJbN6QuHjdjHE`
//     );

//     const { results } = response.data;
//     if (results.length > 0) {
//       const addressComponents = results[0].address_components;

//       const formattedAddress = results[0].formatted_address;
//       const formattedPincode = addressComponents.find((component:any) =>
//         component.types.includes('postal_code')
//       )?.long_name;

//       setAddress(formattedAddress);
//       setPincode(formattedPincode);
//     }
//   } catch (error) {
//     console.error('Error fetching address:', error);
//   }
// };
