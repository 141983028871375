import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const SkeletonLoader = ({ rowCount = 5, title }: any) => {
    return (
        <>
            <h4 className='mt-2 mb-2 text-primary'>{title ? title : ""}</h4>
            <Skeleton />
            <Skeleton count={rowCount} />
        </>
    )
}

export default SkeletonLoader