import React, { useEffect, useRef, useState } from 'react'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { postRequest } from '../../../consts/Server/Requests'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import { areaWallet } from './dataAreaWallet'
import MomentDate from '../../../consts/MomentDate'
import { CardCountWidgetAreaWallet } from './CardCountWidgetAreaWallet'
import Not_Found_Data from '../../../consts/Not_Found_Data'
function convertData(graphData: any) {
    return {
        categories: graphData.map((item: any) => item.title),
        series: [
            {
                name: "Value",
                data: graphData.map((item: any) => item.value)
            }
        ]
    }
}


const AreaManagerWalletList = ({ filterDataOutSource }: any) => {
    const [pending, setPending] = useState<boolean>(true)
    const [data, setData] = useState<any>([])

    const dateFromRef = useRef<any>(null);
    async function getAreaMangerWalletData(payload: { date: string, supervisorid: any }) {
        const result = await postRequest(URL_EndPoints(null)?.getAllAMWalletData, payload, setPending)
        setData(result?.data?.data || [])
    }



    useEffect(() => {

        if (filterDataOutSource?.date) {
            dateFromRef.current.value = filterDataOutSource?.date || MomentDate()
            dateFromRef.current.max = filterDataOutSource?.date || MomentDate()
        }

        if (filterDataOutSource?.date && filterDataOutSource?.supervisorid) {
            getAreaMangerWalletData(filterDataOutSource)
        }
        else if (filterDataOutSource?.date || filterDataOutSource?.supervisorid) {
            getAreaMangerWalletData(filterDataOutSource)
        }
        else {
            const date = dateFromRef.current.value || MomentDate(0)
            setPending(true)
            getAreaMangerWalletData({
                date,
                supervisorid: null,
            })
        }
   
    }, [filterDataOutSource?.date, filterDataOutSource?.supervisorid])

    const handleDateChange = () => {
        const date = dateFromRef.current.value
        setPending(true)
        getAreaMangerWalletData({
            date,
            supervisorid: filterDataOutSource?.supervisorid,
        })
    }

    const handleTabClick = () => {

    }


    return (
        <>
            <div className='mt-2 mb-4 col-12 d-flex justify-content-end '>
                <input type="date" ref={dateFromRef} onChange={handleDateChange} name="date" className='form-select col-4' style={{ width: '200px' }} />
            </div>

            {
                <div className="container-fluid p-0">
                    {
                        pending ?
                            <IsLoading /> : data?.length == 0 ? <Not_Found_Data headerTitle="Area Manager Data Not Found" /> :
                                <div className='row p-0' >
                                    {data.map((element: any) => {
                                        let convertedDataValue = convertData(element?.data?.graphData)
                                        return <div className="col-3 p-1 mb-2 ">
                                            <CardCountWidgetAreaWallet className='border border-primary' color='primary' data={element?.data} chartData={convertedDataValue} handleTabClick={handleTabClick} />
                                        </div>
                                    })}
                                </div>
                    }

                </div>
            }


        </>
    )
}

export default AreaManagerWalletList
