import React, { memo } from 'react';
import { withSwal as RemoveDublicateVehicle } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { useDispatch } from 'react-redux';
export default RemoveDublicateVehicle((props: any) => {
    const dispatch = useDispatch()
    const { swal, confirm, cancel, payloads, localKey }: any = props;
    const { payloadData, render, successTitle, FailedTitle , reference} = payloads
    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.updateDuplicateVehicle, payloadData, null)
            if (response?.data?.msg) {
                swal.fire(successTitle)

                dispatch({type:"DUBLICATE_rEMOVE", payload: {
                    dublicateid:1 ,
                    dublicate_Render:Math.random()
                }})
            }
        }
        if (render) {
            swal.fire({
                title: 'Do you want to save the changes?',
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});
