import React from 'react'
import { AdminToolTip } from '../admins/adminComponents/AdminToolTipModal'
const CardCountWidget = ({ className, color, data, custom_color = null, handleTabClick }: any) => {
  
    let style: any = {
        backgroundColor: "#3AABFF",
        minHeight:"50px"
    }
    if (custom_color) {
        style.backgroundColor = custom_color
    }

    if(data?.style){
        data.style = {...data.style}
        data.style.minHeight = "50px"
    }

    return (
        <div className={` ${className}`}>
            <div className=' p-0'>

                <div className={`rounded-top totalCount`} style={data?.style ?? style} onClick={() => handleTabClick && handleTabClick(data?.filter, data?.tabKey, data?.isRedirect)}>
                    <div className='d-flex justify-content-center text-center'>
                        <h3 className='m-0 mt-1 text-white fw-bold fs-3'>{data?.title}</h3>
                    </div>
                    <div className='d-flex text-center flex-column text-white'>
                        <span className='fw-bold fs-2 pt-1'>{ data?.total}</span>
                    </div>
                </div>

                <div className='p-2'>
                    {
                        data?.subData?.map((sub: any, index: any) => {
                            let toolTipId = sub?.title + "_" + index
                            return <div className='d-flex align-items-center mb-3' style={sub?.style} id={toolTipId}>
                                <div className='d-flex align-items-center flex-wrap w-100 subCount' onClick={() => handleTabClick ? handleTabClick(sub?.filter, sub?.tabKey, data?.isRedirect) : ""}>
                                    <div className='mb-1  flex-grow-1' >
                                        <a href='#' className='fs-7 text-gray-800'>
                                            {sub?.title}
                                        </a>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className=' fs-7 text-gray-800 pe-1'> {sub?.total}</div>
                                    </div>
                                </div>
                                {sub?.titleTooltip && <AdminToolTip anchorId={toolTipId} title={sub?.titleTooltip} place={""} />}
                            </div>
                        })
                    }
                </div>

            </div>
        </div>
    )
}
export { CardCountWidget }
