import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

const AdminPaginationList = ({ setPerPageSize, refreshNow, setNewPaginationValue }: any) => {
    const [paginationValue, setPaginationValue] = useState<any>(null);

    const handlePaginationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value > 0) {
            setPaginationValue(e.target.value);
        } else if (e.target.value === "") {
            setPaginationValue(null);
        }
    };

    const applyHandler = () => {
        if (!paginationValue) return toast.error("Enter custom pagination value", { position: "bottom-center" })
        setPerPageSize && setPerPageSize(+paginationValue)
        refreshNow && refreshNow(+paginationValue)
    }

    const addPaginationHandler = async (newPerPageSize: number) => {
        if (typeof newPerPageSize !== "number") {
            console.error("Invalid pagination size:", newPerPageSize);
            return;
        }
        setNewPaginationValue((prev: number[]) => [...prev, +newPerPageSize].sort((a, b) => a - b));
    }

    return (
        <>
            <div className="d-flex justify-content-start align-items-cetner" style={{ marginTop: "-42px" }}>
                <label htmlFor="custom-pagination" className="me-2 mt-1">
                    Custom Rows Per Page:
                </label>
                <input
                    id="custom-pagination"
                    type="number"
                    min="1"
                    style={{ width: "100px", height: "30px" }}
                    value={paginationValue}
                    onChange={handlePaginationChange}
                    placeholder="pagination"
                    className="form-control d-inline-block outline-none rounded-0 border-end-0"
                />
                <button className='border-start-0 btn btn-sm btn-secondary p-1 px-2 rounded-0' style={{ width: "50px", height: "30px" }} onClick={applyHandler} >Apply</button>
                <button className='border-start-0 btn btn-sm btn-white p-0 px-0 rounded-0' style={{ width: "50px", height: "30px" }} onClick={() => addPaginationHandler(+paginationValue)} ><FontAwesomeIcon icon={faPlusSquare} className='text-primary rounded-0' style={{ fontSize: "32px" }} /></button>
            </div>
        </>
    )
}

export default AdminPaginationList

// call this component  dont make any changes it will work automatically 

// <AdminPaginationList
// setPerPageSize={setPerPageSize}
// refreshNow={refreshNowPagination}
// setNewPaginationValue={setNewPaginationValue}
// />


// paste this in list 

// const [newPaginationValue, setNewPaginationValue] = useState<number[]>(paginationRowsPerPageOptions)

// replce this with previous one 

// paginationRowsPerPageOptions={newPaginationValue}
