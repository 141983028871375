export interface SearchSelectType {
  fieldName: string,
  label: string
}



export const champLeadWhatsappTemplateApi = [
  {
    url: 'whatsapp/getWhatsAppTemplate',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {
      wa_number_id: 2,
    },
  },
]

export const champLeadStatusApi = [
  {
    url: 'getMasterReasonForVisitors',
    method: 'GET',
    isQuery: "categoryId=7",
    isParams: null,
    isBody: null,
  },
]

export const champLeadSubLeadStatusApi = [
  {
    url: 'getMasterSubReason',
    method: 'GET',
    isQuery: "categoryId=1",
    isParams: null,
    isBody: null,
  },
]

export const champLeadBatchApi = [
  {
    url: 'getBatches',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
]


export const champLeadAssign = [
  {
    url: 'getSupervisorList',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  {
    url: 'getAlladminlist',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
]



export const searchSelectOptions: SearchSelectType[] = [
  {
    fieldName: "any",
    label: "Any"
  },
  {
    fieldName: "first_name",
    label: "First name"
  },
  {
    fieldName: "last_name",
    label: "Last name"
  },
  {
    fieldName: "phone",
    label: "Phone"
  },
  {
    fieldName: "pincode",
    label: "Pincode"
  },
  {
    fieldName: "training_location",
    label: "Training Location"
  },
  {
    fieldName: "address",
    label: "Address free form"
  },
]



export const areaNameformData = [
  {
    HeaderTitle: 'Select Area Name',
    filterApi: 'getarea',
    label: '',
    valueType: 'select',
    inputType: '',
    displayFields: ['areaname'],
    valueField: 'id',
    name: 'areaId',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Area name is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
]


export const subAreaNameformData = [
  {
    HeaderTitle: 'Select Area Name',
    filterApi: 'searchableSubAreasList',
    // filterApi: 'getMapBlocksSubAreasListForFilter',
    label: '',
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'sub_area_id',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Sub area name is required !',
    searchable: true,
    wa_number_id: null,
    userType: null,
  },
]

export const subLeadStatusFormData = [
  {
    HeaderTitle: 'Select Sub Lead Status',
    filterApi: 'getMasterSubReason',
    label: '',
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'sub_status_id',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Sub lead status name is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
    categoryId: 1
  },
]