import React from 'react'
const TimingId = (timeslot: any) => {
 
    const timesstmp2 = timeslot == "06AM-07AM" ? "2" :
        timeslot == "07AM-08AM" ? "3" :
            timeslot == "08AM-09AM" ? "4" :
                timeslot == "09AM-10AM" ? "5" :
                    timeslot == "10AM-11AM" ? "6" :
                        timeslot == "11AM-12AM" ? "7" :
                            timeslot == "12PM-01PM" ? "8" :
                                timeslot == "01PM-02PM" ? "9" :
                                    timeslot == "02PM-03PM" ? "10" :
                                        timeslot == "03PM-04PM" ? "11" :
                                            timeslot == "04PM-05PM" ? "12" :
                                                timeslot == "05PM-06PM" ? "13" :
                                                    timeslot == "06PM-07PM" ? "13" :
                                                        "No id found"

    return timesstmp2

}
export default TimingId