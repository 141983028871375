import React, { memo } from 'react';
import { withSwal as DeleteSubTable } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { toast } from 'react-toastify';
export default DeleteSubTable((props: any) => {

    const { swal, render, payload, setRenders }: any = props;

    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.deleteSubArea, payload, null)
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
            setRenders(null)
        }

        if (render) {
            swal.fire({
                title: "Do you want to delete ?",
                // showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Delete",
                // denyButtonText: `Delete`
            }).then((result: any) => {
                if (result.isConfirmed) {
                    load()
                }
                else if (result.isDismissed) {
                    swal.fire('Delete Action Cancelled !')
                    setRenders(null)
                }
            })
        }
    }, [render])
});
