import React from 'react';
import "./selectBox.css"
function Filters({ ParentData, Component, filterTitle }: any) {
  const [filterQty, setfilterQty] = React.useState<any>(0)

  React.useEffect(() => {
    let Filtercount = 0
    const filterValues = Object.values(ParentData?.payloads || {})
    for (let i = 0; i < filterValues.length; i++) {
      if (filterValues[i]) {
        Filtercount = Filtercount + 1
      }

    }
    setfilterQty(Filtercount)
  }, [ParentData?.payloads])
  return (
    <div className='d-inline'>
      <Component ParentData={ParentData} ></Component>
      {ParentData?.hideSubmitButton ? <></> : <button className="btn btn-sm btn-primary w-100 " onClick={() => ParentData.handleActions()}>Search</button>}
    </div>
  );
}
export default Filters;
