import React from 'react'
import { Modal } from 'react-bootstrap'
import AdminReadOnlyEditor from '../admins/adminForms/adminFormComponents/AdminReadOnlyEditor'

const ViewTermRowWise = ({ show, setShow, keyName, htmlContent }: any) => {

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="xl"
                key={keyName}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Terms & Condition</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdminReadOnlyEditor
                        content={htmlContent}
                    />
                </Modal.Body>
            </Modal >
        </>
    )
}
export default ViewTermRowWise
