import React, { useEffect, useState } from "react"
import { Button, Spinner, ToastContainer } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify'
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import AssignChampModal from "./AssignChampModal";
import ReplyReviewModal from "./ReplyReviewModal";
import AssignCustomerModal from "./AssignCustomerModal";

const RatingActions = ({row}: any) => {
    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const [assignChampModal, setAssignChampModal] = useState<any>(false)
    const [assignCustomerModal, setAssignCustomerModal] = useState<any>(false)
    const [replyReviewModal, setReplyReviewModal] = useState<any>(false)

    const closeBtn = () => {
        setAssignChampModal(false)
    }

    const closeReviewBtn = () => {
        setReplyReviewModal(false)
    }

    const closeCustomerBtn = () => {
        setAssignCustomerModal(false)
    }

 
    return (
        <div className='primary'>
            <>
                <button
                    className='btn btn-sm btn-light btn-active-primary  fs-8 '
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                >
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </button>

                <div
                    className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                    data-kt-menu='true'
                    style={{
                        width: "500px",
                        zIndex: '105',
                        position: 'fixed',
                        inset: '0px 0px auto auto',
                        margin: '0px',
                        transform: 'translate(-59px, 440px)',
                    }}
                >
                  
                    <div className='menu-item px-3'>
                        <a
                            className='menu-link  px-3'
                            data-kt-users-table-filter='delete_row'
                            onClick={() => setAssignChampModal(true)}
                        >
                            Select Champ
                        </a>
                    </div>
                    <div className='menu-item px-3'>
                        <a
                            className='menu-link  px-3'
                            data-kt-users-table-filter='delete_row'
                            onClick={() => setAssignCustomerModal(true)}
                        >
                            Select Customer
                        </a>
                    </div>
                    <div className='menu-item px-3'>
                        <a
                            className='menu-link  px-3'
                            data-kt-users-table-filter='delete_row'
                            onClick={() => setReplyReviewModal(true)}
                        >
                            Reply
                        </a>
                    </div>
                </div>
            </>

            {assignChampModal ? <AssignChampModal showModal={assignChampModal} handleClose={closeBtn} row={row} /> : <></>}
            {assignCustomerModal ? <AssignCustomerModal showModal={assignCustomerModal} handleClose={closeCustomerBtn} row={row} /> : <></>}
            {replyReviewModal ? <ReplyReviewModal showModal={replyReviewModal} handleClose={closeReviewBtn} row={row} /> : <></>}
        </div>
    )
}

export default RatingActions;