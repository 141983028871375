import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
const VisitorSocietyForm = ({ FilteredData, setStoreRowP2p = null, row }: any) => {
    // const dispatch = useDispatch()
    const SocietyUpdatedData = useSelector((store: any) => store.VisitorDataReducer.societies)
    const [SocietyUpdateDataList, setSocietyUpdateDataList] = React.useState<any>([]);
    const [StatusUpdatedReason, setStatusUpdatedReason] = React.useState<any>(null);
    const [SourceUpdatedReasonName, setSourceUpdatedReasonName] = React.useState<any>({});
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    useEffect(() => {
        setSocietyUpdateDataList(SocietyUpdatedData)
    }, [SocietyUpdatedData])
    const handleChange = (event: any, name: any) => {
        if (event === null) {
            setStatusUpdatedReason(null)
        }
        else {
            setStatusUpdatedReason(event.value)
        }
    }
    const handleVisitorStatus = async () => {
        setLoader(true)
        if (StatusUpdatedReason) {
            let payloads = { societyid: StatusUpdatedReason, customerid: FilteredData?.selectedId?.customerid }
            const response = await postRequest(URL_EndPoints(null)?.updateVisitorSociety, payloads, setLoader)
            if (response?.data?.msg) {
                toast.success(response?.data?.msg)
                if (setStoreRowP2p) {
                    row['societyid'] = +StatusUpdatedReason
                    setStoreRowP2p(row)
                    setDisable(false)
                }
                FilteredData?.handleCloseForm()
            }
        } else {
            toast.error("Source id missing")
            setDisable(false)
            setLoader(false)
            FilteredData?.handleCloseForm()
        }
    }
    useEffect(() => {
        const updated = SocietyUpdateDataList?.filter((ele: any) => ele?.id === FilteredData?.selectedId?.societyid)
        FilteredData?.selectedId?.societyid && setStatusUpdatedReason(FilteredData?.selectedId?.societyid)
        FilteredData?.selectedId?.societyid && setSourceUpdatedReasonName(updated?.length > 0 ? updated[0] : "")
    }, [SocietyUpdateDataList, FilteredData?.selectedId?.societyid])
    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Society</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select  Society"}
                            SelectData={SocietyUpdateDataList}
                            DynamicKey={"name"}
                            id={"id"}
                            name={"name"}
                            defaultData={{ label: SourceUpdatedReasonName?.name, value: SourceUpdatedReasonName?.id }}
                        />
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={disable || loader ? true : false} onClick={() => handleVisitorStatus()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default VisitorSocietyForm
