import {applyMiddleware, compose, combineReducers, legacy_createStore} from 'redux'
import thunk from 'redux-thunk'
import {NotificationActionReducer} from './Reducer/Notification/NotificationCreateReducer'
import {ChatReducers} from './Reducer/Chat/ChatReducer'
import {ChampChatReducer} from './Reducer/Chat/ChampChatReducer'
import {ActivePaidSubscriptionReducer} from './Reducer/ActivePaidSubscription/ActivePaidSubscriptionReducer'
import {ActiveStatsReducer} from './Reducer/ActiveStatsReducer/ActiveStatsReducer'
import {RefreshPage} from './Reducer/RefreshPage/RefreshPage'
import {StateMangenementCenter} from './Reducer/RefreshPage/StateMangenementCenter'
import {QuickReplyReducer} from './Reducer/QuickReplyReducer/QuickReplyReducer'
import {DailyJobDashboard} from './Reducer/DailyDashboard/DailyJobDashboard'
import {JobListRefreshData} from './Reducer/JobListRefreshData/JobListRefreshData'
import {Schedules} from './Reducer/Schedules/Schedules'
import {ReasonsListDataReducer} from './Reducer/ReasonsListDataReducer/ReasonsListDataReducer'
import {VisitorDataReducer} from './Reducer/VisitorDataReducer/VisitorDataReducer'
import {CallLogReducer} from './Reducer/CallLogReducer'
import {InventoryDataReducer} from './Reducer/InventoryDataReducer/InventoryDataReducer'
import {champsReducer} from './champChatDashboardReducer/champsReducer'
import {VehicleReducer} from './Reducer/Vehicle/VehicleReducer'
import {TicketDataReducer} from './Reducer/Ticket/TicketDataReducer'
import {faqReducer} from './reducers/faqReducer'
import {cxReducer} from './reducers/cxReducer'
import {adminReducer} from './reducers/adminReducer'
import { adminNotification } from './reducers/adminNotification'

const rootReducer = combineReducers({
  NotificationActionReducer,
  ChatReducers,
  ActivePaidSubscriptionReducer,
  RefreshPage,
  ActiveStatsReducer,
  QuickReplyReducer,
  DailyJobDashboard,
  JobListRefreshData,
  Schedules,
  ReasonsListDataReducer,
  CallLogReducer,
  VisitorDataReducer,
  InventoryDataReducer,
  ChampChatReducer,
  champsReducer,
  VehicleReducer,
  TicketDataReducer,
  StateMangenementCenter,
  faqReducer,
  cxReducer,
  adminReducer,
  adminNotification
})
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = [thunk]
const enhancer = composeEnhancers(applyMiddleware(...middleware))
export const store = legacy_createStore(rootReducer, enhancer)

// RefreshPage.DailyReAssign
