import React from 'react'
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { isAllowedChampNumber } from '../components/Utility';

const ToolTips = ({ toolTipId, ToolTipShowData, place, from = "" }: any) => {
    return (
        <>
            {
                from == "cleaner" ?
                    <ReactTooltip
                        anchorId={toolTipId}
                        place={place ? place : "top"}
                        className="bg-primary"
                        // style={{background:"blue"}}
                        content={isAllowedChampNumber(ToolTipShowData, toolTipId)}
                    />

                    : <ReactTooltip
                        anchorId={toolTipId}
                        place={place ? place : "top"}
                        className="bg-primary"
                        // style={{background:"blue"}}
                        content={ToolTipShowData}
                    />
            }


        </>
    )
}

export default ToolTips


{/* <ToolTips  toolTipId={""} ToolTipShowData={""} place={"bottom"} /> */ }
