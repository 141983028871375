import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { postRequest } from '../../../consts/Server/Requests'
import { toast } from 'react-toastify'

let bulkApproveData = ["Cash", "Point"]
const BulkCreateReferralRequest = ({ show, setShow, filteredSelectedRows, setToggleClearRows, toggledClearRows, setFilteredSelectedRows }: any) => {
    const [isLoadingSubmitAmount, setIsLoadingSubmitAmount] = useState(false)
    const [payload, setPayload] = useState<any>({
        "referenceIds": [],
        "comment": "",
        "service_date": "",
        "method": "",
    })

    const submitHandler = async () => {
        setIsLoadingSubmitAmount(true)
        let champIds = filteredSelectedRows?.map((row: any) => row && row?.id)?.filter((id: number) => (id !== undefined) && (id !== 0));
        payload["referenceIds"] = champIds
        if (payload?.referenceIds?.length == 0) {
            toast.error("referenceIds id is missing", { position: "top-center" })
            return
        }
        const response: any = await postRequest(URL_EndPoints(null)?.createChampReferralRequestInBulk, payload, setIsLoadingSubmitAmount)
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
        setToggleClearRows(!toggledClearRows)
        setShow(false)
        setFilteredSelectedRows && setFilteredSelectedRows([])

    }

    return (
        <>
            <Modal size='lg' show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Bulk Create Referral Request {filteredSelectedRows.length} Champs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <Form.Group className="mb-3" >
                            <div className='mb-6'>
                                <textarea
                                    placeholder='Comment...'
                                    name='comment'
                                    value={payload?.comment}
                                    className='form-control  mb-3 mb-lg-0'
                                    autoComplete='off'
                                    onChange={(event: any) => setPayload({ ...payload, [event?.target?.name]: event.target.value })}

                                ></textarea>

                            </div>
                            <div className='mb-4' style={{ position: "relative" }}>
                                <span className='rounded text-dark' style={{ position: "absolute", top: -10, zIndex: 1, left: "5.5%", transform: "translate(-50%, -50%)" }}>Service Date</span>

                                <input
                                    placeholder='Service Date'
                                    name='service_date'
                                    type='date'
                                    value={payload.service_date}
                                    className='form-control  mb-3 mb-lg-0'
                                    autoComplete='off'
                                    onChange={(event: any) => setPayload({ ...payload, [event?.target?.name]: event.target.value })}
                                />
                            </div>
                            <div className='mb-2'>
                                {
                                    <select
                                        name='date_typer'
                                        data-control='select2'
                                        data-placeholder='Period'
                                        className='form-select form-select-sm '
                                        id='method'
                                        onChange={(event: any) => setPayload({ ...payload, [event?.target?.id]: event.target.value?.toLowerCase() })}
                                    >
                                        < option value="" >Select Type </option>

                                        {
                                            bulkApproveData?.map((ele: any) => {
                                                return (
                                                    < option value={ele?.toLowerCase()} >{ele}</option>
                                                )
                                            })
                                        }
                                    </select>
                                }
                            </div>


                        </Form.Group>
                    </Form>


                    <Form.Group className="mb-3 p-2" >
                        {
                            isLoadingSubmitAmount ?
                                <Spinner animation={'border'} />
                                : <Button type='submit' variant="primary" disabled={isLoadingSubmitAmount} onClick={submitHandler}>
                                    Bulk Create Referral Request
                                </Button>
                        }
                    </Form.Group>
                </Modal.Body>
            </Modal>



        </>
    )
}

export default BulkCreateReferralRequest
