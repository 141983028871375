import React, { useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet';
const SingleSelectSearchCategoryDefault: any = ({ handleChangeInputData, HeaderTitle, SelectData, DynamicKey, DynamicKey2 = "", DynamicKey3 = "", id, name, defaultData, targetValue, timestamp, loader }: any) => {
  const [showData, setShowData] = React.useState<any>("")
  const animatedComponents = makeAnimated();
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      if (ele[DynamicKey] != null) {
        let label = ele[DynamicKey];
        if (DynamicKey2) {
          label = label + " " + ele[DynamicKey2] ?? "";
        }
        if (DynamicKey3) {
          label = label + " " + ele[DynamicKey3] ?? "";
        }
        const newData = {
          label: label,
          value: ele[id],
          item: ele
        }
        return newData


      }
    })
    setShowData(updatedData)
  }, [SelectData])


  return (
    <div className='mb-2' >
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        components={animatedComponents}
        options={showData}
        onChange={(event: any) => handleChangeInputData(event, name, targetValue, true, timestamp)}
        defaultValue={defaultData}
        styles={colourStyles2}
        isLoading={loader && loader}
      />
    </div>
  )
}
export default SingleSelectSearchCategoryDefault
