import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import {  faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { createFaqsFormData } from './faqsData';
import { useSelector } from 'react-redux';
import LoaderSpinner from '../../modules/admins/LoaderSpinner';
import AdminJodEditorForm from '../../modules/admins/adminForms/AdminJodEditorForm';
import { initialPayload } from '../DynamicFilteration/components/CommonData';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { postRequest } from '../Server/Requests';
import { FEATURES, isFeatureAllowed } from '../Permissions';

const FaqNavbar = ({ showCat, selectedCategory }: any) => {


    const history = useNavigate();
    const [isSearchOpenFaq, setIsSearchOpenFaq] = useState(false);
    const [showCreateFaq, setShowCreateFaq] = useState(false)
    const [allFAQSData, setAllFAQSData] = useState([])
    const [pending, setPending] = useState(false)
    const [payload, setPayload] = useState(initialPayload(false, {}))
    const faqTotalRecords = useSelector((store: any) => store.faqReducer.faqTotalRecords)

    async function getAllFaqsListData(payload?: any, setPending?: any) {
        setPending(true)
        const { data } = await postRequest(URL_EndPoints(null)?.getAllFAQs, payload, setPending)
        setAllFAQSData(data?.data)
    }

    useEffect(() => {
        let timer = setTimeout(async function () { isSearchOpenFaq && getAllFaqsListData(payload, setPending) }, 1000)
        return () => { clearTimeout(timer) }
    }, [payload?.search, isSearchOpenFaq])

    const handleSuggestionClick = (menuItem: any) => {
        setIsSearchOpenFaq(false);
        history(menuItem)
    };


    return (

        <div className='position-relative p-4 w-100 mb-2 d-flex align-items-center '>
            <div className={`d-flex justify-content-${showCat ? "between" : "end"} w-75`}>
                {
                    showCat ? <h2>{selectedCategory?.name} <span className='fs-5 text-muted'> ( {faqTotalRecords} )</span></h2> : <></>
                }
                {
                    isFeatureAllowed(FEATURES.faqCreateUpdateAction) ? <button className='btn btn-sm btn-primary bg-white border border-gray text-dark' onClick={() => setShowCreateFaq(true)}>Add Faqs</button> : <></>
                }
            </div>

            <div className='text-end position-absolute end-0 d-flex  border justify-content-end w-25' style={{ height: "38px" }} onClick={() => setIsSearchOpenFaq(true)}>
                <div className='text-muted d-flex justify-content-start align-items-center'>
                    <span>
                        <FontAwesomeIcon className='fs-5 ms-2 fw-bold mt-2 text-white px-1 text-muted me-2 ' icon={faSearch} />
                    </span>
                    <span className=''>Search</span>
                </div>
                <input type="text" className='text-end form-control cursor-pointer w-125 outlinecss' placeholder='FAQ' readOnly />
            </div>

            {isSearchOpenFaq ? <Modal
                show={isSearchOpenFaq}
                onHide={() => setIsSearchOpenFaq(false)}
                scrollable={true}
            >
                <div className='d-flex justify-content-between col-12 p-2'>
                    <Modal.Title className='text-muted fs-6'></Modal.Title>
                    <Modal.Title className='cursor-pointer me-2' onClick={() => setIsSearchOpenFaq(false)}><FontAwesomeIcon className='fs-5 text-muted me-2 ' icon={faClose} /></Modal.Title>
                </div>
                <Modal.Body style={{ background: "rgba(226, 226, 226, 0.459)" }}>
                    <input
                        autoFocus
                        type='text'
                        className='form-control col-12 mb-3 outlinecss rounded-0'
                        value={payload?.search}
                        onChange={(event) => setPayload({ ...payload, ["search"]: event?.target?.value })}
                        placeholder='Search Questions...'
                    />
                    <div className='d-flex flex-column g-2 text-dark cursor-pointer  col-12'>
                        {pending && <div className='d-flex mb-4'><LoaderSpinner /> <span>Loading...</span></div>}

                        {

                            allFAQSData?.map((ele: any) => {
                                return <div className='d-flex justify-content-start align-items-center gap-2  p-2 mb-3 card rounded-0' onClick={() => handleSuggestionClick(`/faq/categories/${ele?.faq_category_details?.name}/${ele?.id}`)}>
                                    <div className='d-flex flex-row w-100 position-relative'>
                                        <div className='d-flex justify-content-center align-items-center position-relative me-3 '>
                                            <div className='symbol symbol-25px d-flex'>
                                                <img src="https://play-lh.googleusercontent.com/uRbzB870ArRyc8sem-wryLDtA9ltgnEZjdhCKauUmLqptYQsOx8o09XMMseeFTidMa5N" alt='' className='me-2' />
                                            </div>
                                        </div>
                                        <div className='flex-grow-1'>
                                            <h2 className=''
                                                style={{
                                                    fontFamily: 'Inter, Helvetica, "sans-serif"',
                                                    fontSize: "13.975px",
                                                    fontWeight: 600,
                                                    lineHeight: "20.96px",
                                                    color: '#5e5e6d',
                                                }}
                                                onMouseEnter={(e) => e.currentTarget.classList.add("text-primary")}
                                                onMouseLeave={(e) => e.currentTarget.classList.remove("text-primary")}
                                            >{ele?.question}</h2>
                                            <p className='text-gray-800 fw-normal mb-2'>
                                                {ele?.short_answer}
                                            </p>

                                        </div>
                                        <div className='d-flex align-items-center justify-content-end position-absolute end-0 bottom-0'>
                                            <span className='badge badge-light-secondary fw-semibold text-dark rounded-0' > {ele?.faq_category_details?.name}</span>
                                        </div>
                                    </div>
                                </div>
                            })

                        }
                        {allFAQSData?.length == 0 && <span className='text-muted'>No recent searches</span>}
                    </div>
                </Modal.Body>
            </Modal> : ''}

            {
                showCreateFaq ? <AdminJodEditorForm
                    show={showCreateFaq}
                    setShow={setShowCreateFaq}
                    refreshNow={() => { }}
                    toastMessage="msg"
                    formData={createFaqsFormData}
                    staticFormData={[]}
                    initialData={{
                        question: "",
                        short_answer: "",
                        answer: "",
                        category_id: "",
                        tags: "",
                        pinned: 0,
                    }}
                    keyName="createFagsForm"
                    formTitle={`Add FAQS`}
                    formSubmitApiUrl="addFAQ"
                /> : <></>
            }
        </div>
    )
}

export default FaqNavbar
