import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import SingleSelectSearchCategory from '../../../Select/SingleSelectSearchCategory'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { postRequest } from '../../../Server/Requests'
import { toast } from 'react-toastify'
import { adminToastRunner } from '../../../../modules/admins/adminUtils'
const TicketStatusForm = ({ setShow, show, TicketData, invokedTicket }: any) => {
    const [loader, setloader] = useState<any>(false)
    const [payload, setPayload] = React.useState<any>({
        ticket_id: TicketData?.id,
        status: 0,
        jobid: 0,
    })

    const onChangeHandler = (event: any, name: string) => {
        setPayload({ ...payload, [name]: event ? +event.value : 0 })
    }

    const submitFormHandler = async () => {
        if (payload?.status == 0) {
            setloader(false)
            toast.error('Some field are missing', { position: "top-center" })
            return
        }

        setloader(true)
        const response = await postRequest(URL_EndPoints()?.updateTicketStatus, payload, setloader)
        adminToastRunner(response)
        invokedTicket()
        setShow(false)
        setloader(false)
    }


    return (
        <>
            {show ? <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Ticket Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategory
                            handleChangeInputData={onChangeHandler}
                            HeaderTitle="Select Status"
                            SelectData={[{ name: 'Closed', id: 3 }, { name: 'Feedback Recieved', id: 4 }, { name: "WhatsApp Replied", id: 8 }]}
                            DynamicKey={"name"}
                            id={"id"}
                            name="status"
                        ></SingleSelectSearchCategory>
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => submitFormHandler()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Ticket Status</h4>
                            }
                        </button>
                    </div>

                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default TicketStatusForm
