import React, { memo } from 'react';
import { withSwal as ApproveChampRequirment } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
export default ApproveChampRequirment((props: any) => {

    const dispatch = useDispatch()
    const { swal, confirm, cancel, payloads }: any = props;
    const { payloadData, render, successTitle, FailedTitle } = payloads


    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.updateChampRequirement, payloadData, null)
            if (response?.data?.status == 200) {
                swal.fire(successTitle)
                toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
                dispatch({
                    type: "CHAMP_REQUIREMENT_TYPE", payload: Math.random()
                })
            }
        }
        if (render) {
            swal.fire({
                title: 'Do you want to approve ?',
                showDenyButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});
