import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { adminToastRunner } from '../../admins/adminUtils'

const CallbackAgentForm = ({ FilteredData, setStoreRowP2p = null, row, agentDefaultData = null }: any) => {
    const adminList = FilteredData.adminList
    const [adminId, setAdminId] = React.useState<any>(null);
    const [loader, setLoader] = useState(false)

    const handleChange = (event: any, name: any) => {
        setAdminId(event ? event.value : event)
    }

    const handleAgentUpdate = async () => {
        if (adminId) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints(null)?.updateCallbackAgent, { agentId: adminId, customerId: FilteredData?.selectedId?.customerId }, setLoader)
            adminToastRunner(response, "message")
            if (response?.data?.status === 200 && setStoreRowP2p) {
                row['callback_agent_id'] = +adminId
                setStoreRowP2p(row)
                FilteredData?.handleCloseForm()
            }
        } else {
            toast.error("Form validation has Error !", { position: "top-center" })
        }
    }

    useEffect(() => {
        agentDefaultData && setAdminId(agentDefaultData?.value)
    }, [agentDefaultData])

    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select Agent"}
                            SelectData={adminList}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name={"name"}
                            defaultData={agentDefaultData}
                        />
                    </div>
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader} onClick={() => handleAgentUpdate()}>
                            {loader ? 'Updating...' : 'Update'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default CallbackAgentForm
