import React from 'react'
import uuid from 'react-uuid';
import SentenceShorter from '../SentenceShorter';
import ToolTips from '../Tooltip/ToolTips';
const TagsShow = ({ Tag }: any) => {
    let MT = uuid()
    return (
        <>
            {Tag ?
                Tag?.split(",").map((ele: any, index: any) => {
                    let sentence = ele
                    let id = uuid()
                    let color;
                    let tag;

                    if (ele.includes("_")) {

                        tag = ele?.split("_")[0]
                    }
                    else {
                        tag = ele

                    }

                    if (ele?.trim().toLowerCase() === "unhappy") {
                        color = "danger"
                    }
                    else if (ele?.trim() && ele?.trim()?.split("_")[0] === "unhappy") {
                        color = "danger"
                    }
                    else if (ele?.trim().toLowerCase() === "missed service") {
                        color = "danger"
                    }
                    else if (ele?.trim().toLowerCase() === "new") {
                        color = "success"
                    } else {
                        color = "primary"
                    }
                    if (index <= 1) {
                        return (
                            <span className={`badge badge-light-${color}  me-1 mb-1 `}>
                                {tag}
                            </span>
                        )
                    }
                    else {
                        return (
                            <>
                                <span className={` badge badge-light-${color}  me-1 mb-1 `}><SentenceShorter index={id} sentence={sentence} /></span>
                                <ToolTips toolTipId={id} ToolTipShowData={ele} place={""} />
                            </>
                        )
                    }
                }) :
                <>
                    {/* <span id={MT} className='badge badge-light-danger'> MT</span>
                    <ToolTips toolTipId={MT} ToolTipShowData={"Missing Tag"} place={""} /> */}
                </>
            }
        </>
    )
}
export default TagsShow
