import React, { useEffect,  useState } from 'react'
import {  Modal, } from 'react-bootstrap'
import WrapperLayer from '../../../consts/champChatDashboard/WrapperLayer'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import moment from 'moment'


const JobEditHistoryList = ({ row, show, setShow }: any) => {
    const [loader, setLoader] = useState(false)
    const [filterData, setFilterData] = useState<any>([])
    const laodInitialData = async (JobId: any) => {
        setLoader(true)
        const result = await postRequest(URL_EndPoints()?.getJobEditHistory, {
            job_id: JobId
        }, setLoader)
        setFilterData(result?.data?.data || [])
    }
    useEffect(() => {
        show && laodInitialData(row?.id)
    }, [row?.id, show])

    return (
        <>
            <Modal size='xl' show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Job Edit History List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WrapperLayer>
                        {
                            loader ? <IsLoading /> :
                                <div className='px-3 mt-4  table-responsive'>
                                    <table className="table table-bordered table-hover table-sm">
                                        <thead>
                                            <tr className='border px-3 bg-secondary'>
                                                <th scope="col">CHANGED BY</th>
                                                <th scope="col">CHANGED AT</th>
                                                <th scope="col">USER TYPE</th>
                                                <th scope="col">TYPE</th>
                                                <th scope="col">OLD DATA</th>
                                                <th scope="col">NEW DATA</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterData?.length > 0 && filterData?.map((element: any) => {
                                                return (
                                                    <tr className={"border"} >
                                                        <td >{element?.changed_by ? element?.changed_by : "NA"}</td>
                                                        
                                                        <td >{element?.changed_at ? moment(element?.changed_at).format("DD-MM-YYYY hh:mm:ss A") : "NA"}</td>
                                                        
                                                        <td >{element?.created_by_type ? element?.created_by_type : "NA"}</td>
                                                        
                                                        <td >{element?.type ? element?.type : "NA"}</td>
                                                        
                                                        <td >{element?.old_data ? element?.old_data : "NA"}</td>
                                                        
                                                        <td >{element?.new_data ? element?.new_data : "NA"}</td>
                                                    
                                                    </tr>
                                                )
                                            }
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </WrapperLayer>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default JobEditHistoryList