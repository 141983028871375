import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Bars } from 'react-loader-spinner'
import TagInputs from '../../../TagInputs/TagInputs'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { getRequest } from '../../../Server/Requests'
const TagsInputs = ({ FilteredData }: any) => {
    const updatedData = FilteredData?.states?.map((ele: any, i: number) => {
        if (ele) {
            const newData = {
                label: ele,
                value: i,
            }
            return newData
        }
    })

    const [suggestionTagData, setsuggestionTagData] = useState<any>([])
    const getData = async () => {
        let QeueryParams = {
            To: "getMasterTags",
            What: `?user_type=3`
        }
        const result = await getRequest(URL_EndPoints(QeueryParams)?.getMasterTags, null)
        setsuggestionTagData(result?.data?.data)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update/Create {FilteredData?.selectedTag}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-3`}>
                        <TagInputs
                            handleChangeInputData={FilteredData?.setStates}
                            HeaderTitle="Add Tags"
                            SelectData={suggestionTagData}
                            DynamicKey={"name"}
                            id={"id"}
                            name="public_tags"
                            defaultData={updatedData}
                        />
                    </div>
                    <div className='d-flex  align-items-center  '>
                        <span> {" Add TicketId "}</span>
                        <span className='mt-1'>
                            <input className='ms-2' type="checkbox" checked={FilteredData?.checkboxs} onChange={() => FilteredData?.setcheckbox(
                                !FilteredData?.checkboxs
                            )}
                            /></span>
                    </div>
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button dir="rtl" className="btn btn-success mt-4 text-end" onClick={FilteredData?.Save} disabled={FilteredData?.disable || FilteredData?.loader ? true : false}>
                            {FilteredData?.loader ?
                                <Bars
                                    height="20"
                                    width="20"
                                    color="#00000"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                : 'Save'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default TagsInputs
