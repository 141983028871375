import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminParamsGenerator, adminToastRunner } from '../../admins/adminUtils';
import MediaUploadFile from '../MediaUploadFile';
import MediaPreview from '../MediaPreview';
import IsLoading from '../../../consts/IsLoading/IsLoading';

const AddUpdatePaymentReceipt = ({ show, refreshNow, setShow, setData, initialValue, setInitialValue, apiUrl }: any) => {
    const [loading, setLoading] = useState(false);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [proformaOrderList, setProformaOrderList] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [file, setFile] = useState<any>(null);

    useEffect(() => {
        setFile(initialValue?.media)
    }, [initialValue?.media]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [purchaseOrderList, paymentMethod] = await Promise.all([
                    getRequest(URL_EndPoints()?.getPurchaseOrderListing, null),
                    getRequest(URL_EndPoints()?.getPurchasePaymentMethod , null),
                ]);

                setPurchaseOrderList(purchaseOrderList?.data?.data);
                setPaymentMethods(paymentMethod?.data?.data);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchData();
        }
    }, [show]);

    const [proformaLoader, setProformaLoader] = useState(false)

    const getPeformaData = async () => {
        setProformaLoader(true)
        const proforma = await getRequest(URL_EndPoints(adminParamsGenerator("getProformaByPurchaseOrderId", initialValue?.purchase_order_id))?.getProformaByPurchaseOrderId, setProformaLoader)
        setProformaOrderList(proforma?.data?.data);
    }

    useEffect(() => {
        initialValue?.purchase_order_id && getPeformaData()
    }, [initialValue?.purchase_order_id])

    const handleInputChange = (e: any) => {
        setInitialValue({
            ...initialValue,
            [e.target.name]: e.target.value,
        });
    };
    const [fileSelected, setFileSelected] = useState<boolean>(false);
    // const [file, setFile] = useState<any>(null);

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        if (typeof (file) == "string" || !file) {
            delete initialValue.media
        }
        
        else {
            initialValue.media = file
        }

        const response = await postRequestWithFile(URL_EndPoints()?.[apiUrl], initialValue, setLoading)
        adminToastRunner(response)
        if (response?.data?.data?.items) {
            setData(response?.data?.data?.items);
        }

        refreshNow && refreshNow();
        setShow && setShow(false);
    };


    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{initialValue?.id ? "Edit" : "Add"} Payment Receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="purchase_order_id_controlled">
                                <Form.Label><strong>Select Purchase Order</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="purchase_order_id"
                                    value={initialValue["purchase_order_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Purchase Order</option>
                                    {purchaseOrderList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.purchase_order_id ? item.purchase_order_id : `${(item.purchase_order_id)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            {
                                initialValue?.purchase_order_id && <Form.Group as={Col} className="mb-3" controlId="proforma_invoice_id">
                                    <Form.Label><strong>Select Proforma</strong></Form.Label>
                                    {
                                        proformaLoader ? <IsLoading /> : <Form.Control
                                            as="select"
                                            name="proforma_invoice_id"
                                            value={initialValue["proforma_invoice_id"] || []}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select Proforma</option>
                                            {proformaOrderList.map((item: any) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.proforma_invoice_id ? item.proforma_invoice_id : `${(item.proforma_invoice_id)} | NA`}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    }
                                </Form.Group>
                            }

                            <Form.Group as={Col} className="mb-3" controlId="payment_method">
                                <Form.Label><strong>Select Payment Method</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="payment_method"
                                    value={initialValue["payment_method"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Payment Method</option>
                                    {paymentMethods.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} className="mb-3" controlId="payment_date">
                                <Form.Label><strong>Payment Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Payment Date"
                                    name="payment_date"
                                    value={initialValue['payment_date']}
                                    onChange={handleInputChange}
                                    required
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="amount">
                                <Form.Label><strong>Amount</strong></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Amount"
                                    name="amount"
                                    value={initialValue['amount']}
                                    onChange={handleInputChange}
                                    required
                                // min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>


                            <Form.Group as={Col} className="mb-3" controlId="notes">
                                <Form.Label><strong>Notes</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Notes"
                                    name="notes"
                                    value={initialValue['notes']}
                                    onChange={handleInputChange}
                                    required
                                // min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <MediaUploadFile setFileSelected={setFileSelected} setFile={setFile} file={file} fileSelected={fileSelected} setPayload={setInitialValue} payload={initialValue} />
                            <MediaPreview fileSelected={fileSelected} file={file} CancelImage={CancelImage} defaultUrl={initialValue?.media} />
                        </Row>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddUpdatePaymentReceipt;
