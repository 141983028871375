import axios from "axios"
import { MAIN_ADMIN_BASE_API_URL, MAIN_API_BASE_API_URL, TEST_ADMIN_BASE_API_URL, TEST_API_BASE_API_URL } from "../../../apiGlobally"


export function getCustomerActiveAubscriptions(customerid: number, vehicleid: number) {
    const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
    const result = axios.get(`${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/customeractivesubscriptions/` + customerid + '?vehicleid=' + vehicleid ).then((res) => {
        // setloading(false)
        return res
    }).catch((err) => {
        // setloading(false)
        return err
    })
    return result
}
