import React, { useEffect, useState } from 'react'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useIntl } from 'react-intl'
import { MenuWithSubMenuItems, initialMenuStaus, keyTitle, menuTitle } from './AsidMenuMainSearchList'
import { useDispatch, useSelector } from 'react-redux'
import { checkMenuStatus } from './asidMenuUtil'
import { changedIsActivesToIsActiveKeyName, isAllowed, isAllowedForShubhamMoinRoshan, isAllowedUser } from '../../../../app/consts/components/Utility'
import axios from 'axios'
import { useNavigate } from 'react-router'
import { FEATURES, isFeatureAllowed } from '../../../../app/consts/Permissions'
import { faBucket, faList, faMailBulk, faSubscript, faToriiGate , 
  faRedoAlt, 
  faCalendarAlt, 
  faMapMarkerAlt, 
  faTicketAlt, 
  faChartBar, 
  faPhone, 
  faBook, 
  faAddressCard, 
  faCrown,
  faBullhorn,
  faMap,
  faArchive,
  faDashboard,
  faFile,
  faDice,
  faTicketSimple,
  faBroom,
  faUser,
  faMailForward,
  faUniversalAccess,
  faUserFriends,
  faCalendarWeek,
  faDoorOpen,
  faHandsHelping,
  faFileCode,
  faHome,
  faToggleOn,
  faMobile,
  faUnlock,
  faGear,
  faPerson,
  faStar,
  faUnlockAlt,
  faTrophy,
  faMedal,
  faLaptop,
  faUserGroup,
  faBarsProgress,
  faMarsDouble,
  faMoneyBill,
  faMoneyBillTransfer,
  faMoneyBillWaveAlt,
  faEye,
  faQuestion,
  faTasksAlt,
  faTasks,
  faRecordVinyl,
  faCogs} from '@fortawesome/free-solid-svg-icons'
import PermissionContextProvider from '../../../../app/modules/admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../../../../app/modules/admins/adminConstant'
import { genereteMenuTitle } from '../../../../app/modules/admins/adminUtils'
const AsidMenuMainList = ({ }: any) => {

  const user: any = localStorage.getItem("user")
  const intl = useIntl()
  const [menuKeys, setMenuKeys] = useState<any>(initialMenuStaus)

  return (
    <>
      <AsideMenuItem to='/dashboard' title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} fontIcon={faHome} />
      
      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["roles-and-permissions"]}>
        <AsideMenuItem to='/admin/menuColumnToggleHander' title={genereteMenuTitle(adminMenuActionPermissionKeys["roles-and-permissions"])} fontIcon={faToggleOn} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["device-login-allowed"]}>
        {
          isAllowedUser?.includes(user) &&
          <AsideMenuItem to='/admin/device-login-allowed' title={genereteMenuTitle(adminMenuActionPermissionKeys["device-login-allowed"])} fontIcon={faUnlock} />
        }
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["subscriptions"]}>
        <AsideMenuItemWithSub
          to='/subscriptions'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["subscriptions"])}
          fontIcon={faList}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-paid"]}>
            <AsideMenuItem to='/subscriptions/active-paid' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["active-paid"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-active-paid"]}>
            <AsideMenuItem to='/subscriptions/new-active-paid' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-active-paid"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["on-demand"]}>
            <AsideMenuItem to='/subscriptions/inactive-subscriptions-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["on-demand"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["kit-subscriptions"]}>
            <AsideMenuItem to='/subscriptions/vehicles/new' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["kit-subscriptions"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["inactive"]}>
            <AsideMenuItem to='/subscriptions/renewed' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["inactive"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["server-inactive"]}>
            <AsideMenuItem to='/subscriptions/reactivated' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["server-inactive"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-list"]}>
            <AsideMenuItem to='/subscriptions/future-pause' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["renewal-list"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["date-wise-onboarding"]}>
            <AsideMenuItem to='/subscriptions/monthly-onboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["date-wise-onboarding"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["onboarding-subscriptions"]}>
            <AsideMenuItem to='/subscriptions/pending-onboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["onboarding-subscriptions"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["unsuccessful-subscriptions"]}>
            <AsideMenuItem to='/subscriptions/unsuccessful-subscriptions' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["unsuccessful-subscriptions"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>


      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["schedules"]}>
        <AsideMenuItemWithSub
          to='/schedule/cleaner'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["schedules"])}
          fontIcon={faCalendarAlt}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["un-assigned"]}>
            <AsideMenuItem to='/schedule/cleaner/un-assigned' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["un-assigned"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list"]}>
            <AsideMenuItem to='/schedule/cleaner/job-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["job-list"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["search-availibility"]}>
            <AsideMenuItem to='/schedule/cleaner/area-wise-cleaner' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["search-availibility"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["cleaner-availibilty"]}>
            <AsideMenuItem to='/schedule/cleaner-area-availibilty' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["cleaner-availibilty"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["no-job-subscription-list"]}>
            <AsideMenuItem to='/schedule/no-job-subscription-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["no-job-subscription-list"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["masters"]}>
        <AsideMenuItemWithSub
          to='/master'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["masters"])}
          fontIcon={faCrown}
          isSubMenu={menuKeys}
        // icon='/media/icons/duotune/communication/com012.svg'
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["requirement-type"]}>
            <AsideMenuItem to='/master/requirement-type' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["requirement-type"])} hasBullet={true} />
          </PermissionContextProvider>

          {/* <PermissionContextProvider keyName={adminMenuActionPermissionKeys["terms-and-condition"]}> */}
            <AsideMenuItem to='/master/terms-and-condition' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["terms-and-condition"])} hasBullet={true} />
          {/* </PermissionContextProvider> */}

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["create-batch"]}>
            <AsideMenuItem to='/master/create-batch' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["create-batch"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["whatsapp-groups"]}>
            <AsideMenuItem to='/master/whatsapp-groups' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["whatsapp-groups"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["tag-lists"]}>
            <AsideMenuItem to='/master/tag-lists' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["tag-lists"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["whatsapp-template"]}>
            <AsideMenuItem to='/master/whatsapp-template' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["whatsapp-template"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["deactivation-notes"]}>
            <AsideMenuItem to='/master/deactivation-notes' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["deactivation-notes"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["master-source"]}>
            <AsideMenuItem to='/master/master-source' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["master-source"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["services"]}>
            <AsideMenuItem to='/master/services' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["services"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["products"]}>
            <AsideMenuItem to='/master/products' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["products"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["packages"]}>
            <AsideMenuItem to='/master/packages' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["packages"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["fuel-type"]}>
            <AsideMenuItem to='/master/fueltype' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["fuel-type"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicle-type"]}>
            <AsideMenuItem to='/master/vehicle-type' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["vehicle-type"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicle-category"]}>
            <AsideMenuItem to='/master/vehicle-category' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["vehicle-type"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicle-brand"]}>
            <AsideMenuItem to='/master/vehicle-brand' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["vehicle-brand"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicle-model"]}>
            <AsideMenuItem to='/master/vehicle-model' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["vehicle-model"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["problem"]}>
            <AsideMenuItem to='/master/problem' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["problem"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["sub-problem"]}>
            <AsideMenuItem to='/master/sub-problem' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["sub-problem"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["t-shirt"]}>
            <AsideMenuItem to='/master/t-shirt' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["t-shirt"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-sites"]}>
            <AsideMenuItem to='/master/job-sites' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["job-sites"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-type"]}>
            <AsideMenuItem to='/master/job-type' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["job-type"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["user-type"]}>
            <AsideMenuItem to='/master/user-type' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["user-type"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["points-table"]}>
            <AsideMenuItem to='/master/points-table' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["points-table"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["credit-reasons"]}>
            <AsideMenuItem to='/master/credit-reasons' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["credit-reasons"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-status"]}>
            <AsideMenuItem to='/master/job-status' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["job-status"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["master-reasons"]}>
            <AsideMenuItem to='/master/master-reasons' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["master-reasons"])} hasBullet={true} />
          </PermissionContextProvider>

          {/* <AsideMenuItem to='/master/master-source' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])} hasBullet={true} /> */}

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["master-offers"]}>
            <AsideMenuItem to='/master/master-offers' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["master-offers"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["master-tickets-category"]}>
            <AsideMenuItem to='/master/master-ticket-category' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["master-tickets-category"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["master-tickets-sub-category"]}>
            <AsideMenuItem to='/master/master-ticket-sub-category' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["master-tickets-sub-category"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["address"]}>
        <AsideMenuItemWithSub
          to='/address'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["address"])}
          fontIcon={faAddressCard}
          isSubMenu={menuKeys}
        // icon='/media/icons/duotune/communication/com012.svg'
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["country"]}>
            <AsideMenuItem to='/address/country' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["country"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["state"]}>
            <AsideMenuItem to='/address/state' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["state"])} hasBullet={true} />
          </PermissionContextProvider>


          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["city"]}>
            <AsideMenuItem to='/address/city' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["city"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["pincode"]}>
            <AsideMenuItem to='/address/pincode' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["pincode"])} hasBullet={true} />
          </PermissionContextProvider>


        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["campaign"]}>
        <AsideMenuItemWithSub
          to='/campaigns'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["campaign"])}
          fontIcon={faBullhorn}
          isSubMenu={menuKeys}
        // icon='/media/icons/duotune/communication/com012.svg'
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["dashboard"]}>
            <AsideMenuItem to='/campaigns/dashboards' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["dashboard"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["create-campaign"]}>
            <AsideMenuItem to='/campaigns/create-campaign' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["create-campaign"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["all-campaigns"]}>
            <AsideMenuItem to='/campaigns/all-campaign' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["all-campaigns"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["rewards"]}>
            <AsideMenuItem to='/campaigns/reward' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["rewards"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["redeemtions"]}>
            <AsideMenuItem to='/campaigns/redeemtions' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["redeemtions"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["maps"]}>
        <AsideMenuItemWithSub
          to='/maps'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["maps"])}
          fontIcon={faMap}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["company-cleaner-m-a-ps"]}>
            <AsideMenuItem to='/maps/comapany-cleaner-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["company-cleaner-m-a-ps"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-cleaner-m-a-ps"]}>
            <AsideMenuItem to='/maps/customer-cleaner-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["customer-cleaner-m-a-ps"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["requirement-map"]}>
            <AsideMenuItem to='/maps/requirement-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["requirement-map"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["sub-location-map"]}>
            <AsideMenuItem to='/maps/sub-location-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["sub-location-map"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["cleaner-routes-m-a-ps"]}>
            <AsideMenuItem to='/maps/cleaner-route-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["cleaner-routes-m-a-ps"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["society-m-a-ps"]}>
            <AsideMenuItem to='/maps/society-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["society-m-a-ps"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["cleaner-m-a-ps"]}>
            <AsideMenuItem to='/maps/cleaner-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["cleaner-m-a-ps"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["pincode-marker-m-a-ps"]}>
            <AsideMenuItem to='/maps/pincode-marker-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["pincode-marker-m-a-ps"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["all-customer-cleaner-m-a-ps"]}>
            <AsideMenuItem to='/maps/all-customer-cleaner-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["all-customer-cleaner-m-a-ps"])} hasBullet={true} />
          </PermissionContextProvider>


        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["statistics"]}>
        <AsideMenuItemWithSub
          to='/apps/admin/stats'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["statistics"])}
          fontIcon={faChartBar}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-stats"]}>
            <AsideMenuItem to='/apps/stats/customers' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["customer-stats"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["call-results"]}>
        <AsideMenuItemWithSub
          to='/callresults/outboundresults'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["call-results"])}
          fontIcon={faPhone}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["outbound-result"]}>
            <AsideMenuItem to='/callresults/outboundcalls' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["outbound-result"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["inbound-result"]}>
            <AsideMenuItem to='/callresults/inboundcalls' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["inbound-result"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["inventory"]}>
        <AsideMenuItemWithSub
          to='/inventory/items'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["inventory"])}
          fontIcon={faArchive}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["items"]}>
            <AsideMenuItem to='/inventory/items' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["items"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["order-status"]}>
            <AsideMenuItem to='/inventory/order-status' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["order-status"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["order-list"]}>
            <AsideMenuItem to='/inventory/orders-listing' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["order-list"])} hasBullet={true} />
          </PermissionContextProvider>
          
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-order-list"]}>
            <AsideMenuItem to='/inventory/new-orders-listing' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-order-list"])} hasBullet={true} />
          </PermissionContextProvider>
          
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["warehouse"]}>
            <AsideMenuItem to='/inventory/warehouse' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["warehouse"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["warehouse-type"]}>
            <AsideMenuItem to='/inventory/warehouse-type' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["warehouse-type"])} hasBullet={true} />
          </PermissionContextProvider>
          
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vendor"]}>
            <AsideMenuItem to='/inventory/vendor' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["vendor"])} hasBullet={true} />
          </PermissionContextProvider>
          
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["warehouse-alert-status"]}>
            <AsideMenuItem to='/inventory/warehouse-alert-status' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["warehouse-alert-status"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["purchase-order"]}>
            <AsideMenuItem to='/inventory/purchase-order' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["purchase-order"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["proforma-invoice"]}>
            <AsideMenuItem to='/inventory/proforma-invoice' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["proforma-invoice"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vendor-invoice"]}>
            <AsideMenuItem to='/inventory/vendor-invoice' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["vendor-invoice"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["goods-receipt"]}>
            <AsideMenuItem to='/inventory/goods-receipt' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["goods-receipt"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["payment-receipt"]}>
            <AsideMenuItem to='/inventory/payment-receipt' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["payment-receipt"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["material-transactions"]}>
          <AsideMenuItem to='inventory/material-transactions' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["material-transactions"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["warehouse-stock-transfer"]}>
            <AsideMenuItem to='/inventory/warehouse-stock-transfer' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["warehouse-stock-transfer"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

{/* 
      {checkMenuStatus(menuKeys, [genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])], [genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])]) == false ? <></> :
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Dashboards</span>
          </div>
        </div>} */}

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["dashboards"]}>
        <AsideMenuItemWithSub
          to='/'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["dashboards"])}
          fontIcon={faDashboard}
          isSubMenu={menuKeys}
        // icon='/media/icons/duotune/communication/com012.svg'
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-dashboard"]}>
            <AsideMenuItem to='/ticket-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["ticket-dashboard"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-ticket-dashboard"]}>
            <AsideMenuItem to='/new-ticket-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-ticket-dashboard"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["daily-dashboard"]}>
            <AsideMenuItem to='/admin/job-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["daily-dashboard"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-daily-dashboard"]}>
            <AsideMenuItem to='/new-daily-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-daily-dashboard"])} hasBullet={true} />
          </PermissionContextProvider>
          
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["agent-daily-dashboard"]}>
            <AsideMenuItem to='/agent-daily-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["agent-daily-dashboard"])} hasBullet={true} />
          </PermissionContextProvider>

          {/* <PermissionContextProvider keyName={adminMenuActionPermissionKeys["am-job-dashboard"]}>
            <AsideMenuItem to='/area-manager-job-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])} hasBullet={true} />
          </PermissionContextProvider> */}

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["iframe-dashboard"]}>
            <AsideMenuItem to='/iframe-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["iframe-dashboard"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["dashboard-statics"]}>
            <AsideMenuItem to='/dashboard-statics' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["dashboard-statics"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["tracking-list"]}>
        <AsideMenuItemWithSub
          to='/tracking'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["tracking-list"])}
          fontIcon={faRecordVinyl}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-count-list"]}>
            <AsideMenuItem to='/tracking/ticket-count-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["ticket-count-list"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["leads-count-source-wise"]}>
            <AsideMenuItem to='/tracking/leads-count-source-wise' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["leads-count-source-wise"])} hasBullet={true} />
          </PermissionContextProvider>
          
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["payment-counts"]}>
            <AsideMenuItem to='/tracking/payment-counts' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["payment-counts"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["sales-counts"]}>
            <AsideMenuItem to='/tracking/sales-counts' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["sales-counts"])} hasBullet={true} />
          </PermissionContextProvider>

          <AsideMenuItem to='/tracking/attrition-data' isSubMenu={menuKeys} title={genereteMenuTitle("attrition-data")} hasBullet={true} />

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ads-insights"]}>
            <AsideMenuItem to='/tracking/ads-insights' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["ads-insights"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["long-nah"]}>
            <AsideMenuItem to='/tracking/long-nah' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["long-nah"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["source-vs-leads-status"]}>
            <AsideMenuItem to='/tracking/source-vs-leads' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["source-vs-leads-status"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["user-call-status"]}>
            <AsideMenuItem to='/tracking/user-call-status' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["user-call-status"])} hasBullet={true} />
          </PermissionContextProvider>
          
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["user-logout-status"]}>
            <AsideMenuItem to='/tracking/user-logout-status' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["user-logout-status"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["inventory-ordered-items-counts"]}>
          <AsideMenuItem to='/tracking/inventory-ordered-items-counts' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["inventory-ordered-items-counts"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      {/* <PermissionContextProvider keyName={adminMenuActionPermissionKeys["tracking-list"]}> */}
        <AsideMenuItemWithSub
          to='/configurations'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["configurations"])}
          fontIcon={faCogs}
          isSubMenu={menuKeys}
        >
          {/* <PermissionContextProvider keyName={adminMenuActionPermissionKeys["common-leads"]}> */}
            <AsideMenuItem to='/configurations/common-leads' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["common-leads"])} hasBullet={true} />
          {/* </PermissionContextProvider> */}


        </AsideMenuItemWithSub>
      {/* </PermissionContextProvider> */}

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["lucky-draw"]}>
        <AsideMenuItem
          to='/lucky-draw'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["lucky-draw"])}
          fontIcon={faDice}
          isSubMenu={menuKeys}
        />
      </PermissionContextProvider>

      {/* {checkMenuStatus(menuKeys, [genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])], [genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])]) == false ? <></> :
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Daily</span>
          </div>
        </div>} */}


      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["all-tickets"]}>
        <AsideMenuItemWithSub
          to='/inactive'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["all-tickets"])}
          fontIcon={faTicketSimple}
          isSubMenu={menuKeys}
        // icon='/media/icons/duotune/communication/com012.svg'
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-tickets"]}>
            <AsideMenuItem to='/admin/new-tickets' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-tickets"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["resumed-list"]}>
            <AsideMenuItem to='/resumed-subscriptions' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["resumed-list"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-renewal-subscription"]}>
            <AsideMenuItem to='/new-renewal-subscriptions' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-renewal-subscription"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["pause-paid"]}>
            <AsideMenuItem to='/inactive-pause-paid' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["pause-paid"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["pause-un-paid"]}>
            <AsideMenuItem to='/inactive-pause-unpaid' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["pause-un-paid"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["inactive-autometically"]}>
            <AsideMenuItem to='/inactive-auto' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["inactive-autometically"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["inactive-agent"]}>
            <AsideMenuItem to='/inactive-agent' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["inactive-agent"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["future-pause"]}>
            <AsideMenuItem to='/future-pause' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["future-pause"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["visitors-list"]}>
            <AsideMenuItem to='/visitors-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["visitors-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads"]}>
            <AsideMenuItem to='/customer-leads' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["customer-leads"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads"]}>
            <AsideMenuItem to='/champs/champ-leads' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["champ-leads"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists"]}>
            <AsideMenuItem to='/task-lists' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["task-lists"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-vehicle-list"]}>
            <AsideMenuItem to='/inactive-vehicle-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["customer-vehicle-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicle-subscription-history-list"]}>
            <AsideMenuItem to='/vehicle-subscription-history-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["vehicle-subscription-history-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-task-list"]}>
            <AsideMenuItem to='/renewal-task-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["renewal-task-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["welcome-call1-list"]}>
            <AsideMenuItem to='/welcome-call-1-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["welcome-call1-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["welcome-call2-list"]}>
            <AsideMenuItem to='/welcome-call-2-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["welcome-call2-list"])} hasBullet={true} />
          </PermissionContextProvider>
          {/* <AsideMenuItem to='/admin/tickets' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])} hasBullet={true} /> */}
          {/* <AsideMenuItem to='/renewal-subscriptions' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])} hasBullet={true} /> */}
          {/* <AsideMenuItem to='/ticket/ticket-count-source-wise' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])} hasBullet={true} /> */}
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-assigned-agents"]}>
            {isFeatureAllowed(FEATURES.customerAssignedAgents) && <AsideMenuItem to='/customer-assigned-agents' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["customer-assigned-agents"])} hasBullet={true} />}
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["data-lists"]}>
        <AsideMenuItemWithSub
          to='/data'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["data-lists"])}
          fontIcon={faFile}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-supervisor-list"]}>
            <AsideMenuItem to='/data/renewal-supervisor-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["renewal-supervisor-list"])} hasBullet={true} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champs"]}>
        <AsideMenuItemWithSub
          to='/apps/admin/cleaner'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["champs"])}
          fontIcon={faBroom}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active"]}>
            <AsideMenuItem to='/schedule/cleaner/cleanerlist' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["active"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["attendance"]}>
            <AsideMenuItem to='/apps/cleaner/cleanerattendance' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["attendance"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-missed-call-list"]}>
            <AsideMenuItem to='/apps/cleaner/champ-missed-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["champ-missed-call-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["absent-list"]}>
            <AsideMenuItem to='/absent/cleanerList' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["absent-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["leave-list"]}>
            <AsideMenuItem to='/apps/cleaner/cleanerleaves' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["leave-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["deactivated"]}>
            <AsideMenuItem to='/apps/cleaner/deactivatedcleanerlist' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["deactivated"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["schemes"]}>
            <AsideMenuItem to='/champ-schemes' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["schemes"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["deduction-jobs"]}>
            <AsideMenuItem to='/deduction-jobs' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["deduction-jobs"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["onboarding-pending"]}>
            <AsideMenuItem to='/apps/cleaner/onboardingpendingcleanerlist' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["onboarding-pending"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["leads"]}>
            <AsideMenuItem to='/apps/cleaner/leads' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["leads"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["league"]}>
            <AsideMenuItem to='/apps/cleaner/leagues' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["league"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-deactivated-champ-list"]}>
            <AsideMenuItem to='/old-deactivated-champ' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-deactivated-champ-list"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-referral-list"]}>
            <AsideMenuItem to='/champ-referral' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["champ-referral-list"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["payout-timeline"]}>
            <AsideMenuItem to='/payout/payout-timeline' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["payout-timeline"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-champ-assignment-list"]}>
            <AsideMenuItem to='/champs/new-champ-assignment-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-champ-assignment-list"])} hasBullet={true} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["approval-lists"]}>
            {
              isAllowedUser?.includes(user) &&
              <AsideMenuItem to='/admin/approval-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["approval-lists"])} hasBullet={true} />

            }
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["hiring"]}>
        <AsideMenuItemWithSub
          to='/hiring'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["hiring"])}
          fontIcon={faUser}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["training-schedules"]}>
            <AsideMenuItem to='/hiring/training-schedules' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["training-schedules"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["training-locations"]}>
            <AsideMenuItem to='/hiring/training-location' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["training-locations"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-requirement"]}>
            <AsideMenuItem to='/hiring/champ-requirement' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["champ-requirement"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["training-topics"]}>
            <AsideMenuItem to='/hiring/training-topics' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["training-topics"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["areas"]}>
            <AsideMenuItem to='/hiring/areas' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["areas"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["sub-location-map"]}>
            <AsideMenuItem to='/hiring/sub-location-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["sub-location-map"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["hiring-dashboard"]}>
            <AsideMenuItem to='/hiring/hiring-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["hiring-dashboard"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["hiring-dashboard2"]}>
            <AsideMenuItem to='/hiring/hiring-dashboard-2' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["hiring-dashboard2"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["requirement-map"]}>
            <AsideMenuItem to='/hiring/requirement-map' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["requirement-map"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-hiring-automation"]}>
            <AsideMenuItem to='/hiring/hiring-automation' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["champ-hiring-automation"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["easebuzz"]}>
        <AsideMenuItemWithSub
          to='/easebuzz'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["easebuzz"])}
          fontIcon={faMoneyBillTransfer}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["easebuzz-transaction-hiistory"]}>
            <AsideMenuItem to='/easebuzz/transaction-history' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["easebuzz-transaction-hiistory"])} hasBullet={true} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["notifications"]}>
        <AsideMenuItemWithSub
          to='/apps/notification/same-day'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["notifications"])}
          fontIcon={faMailForward}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["notification-template"]}>
            <AsideMenuItem to='/apps/cleaner/NotificationTemplate' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["notification-template"])} fontIcon={faFileCode} hasBullet={false} />
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["notification-dashboard"]}>
            <AsideMenuItem to='/notification-dashboard' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["notification-dashboard"])} fontIcon={faMailForward} hasBullet={false} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["access"]}>
        <AsideMenuItemWithSub
          to='/access'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["access"])}
          fontIcon={faUniversalAccess}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["access-area-manager"]}>
            <AsideMenuItem to='/access/area-manager' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["access-area-manager"])} hasBullet={true} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      {/* <AsideMenuItemWithSub
    to='/cron'
    title={genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])}
    fontIcon={faEye}
    isSubMenu={menuKeys}
  >
    <AsideMenuItem to='/cron/history' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])} hasBullet={true} />
  </AsideMenuItemWithSub> */}

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["developer-tools"]}>
        <AsideMenuItemWithSub
          to='/developer'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["developer-tools"])}
          fontIcon={faEye}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["developer-tools"]}>
            <AsideMenuItem to='/developer/tools' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["developer-tools"])} hasBullet={true} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-campaign"]}>
        <AsideMenuItemWithSub
          to='/champ-campaign'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["champ-campaign"])}
          fontIcon={faEye}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["campaign-list-sid"]}>
            <AsideMenuItem to='/champ-campaign/list-sid' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["campaign-list-sid"])} hasBullet={true} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["loan"]}>
        <AsideMenuItemWithSub
          to='/loan'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["loan"])}
          fontIcon={faMoneyBill}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["loan-lists"]}>
            <AsideMenuItem to='/loan/history' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["loan-lists"])} hasBullet={true} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["faq"]}>
        <AsideMenuItemWithSub
          to='/faq'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["faq"])}
          fontIcon={faQuestion}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["frequently-asked-question"]}>
            <AsideMenuItem to='/faq/frequently-asked-question' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["frequently-asked-question"])} hasBullet={true} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["model-training"]}>
        <AsideMenuItemWithSub
          to='/model-training'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["model-training"])}
          fontIcon={faTasks}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["training-list"]}>
            <AsideMenuItem to='/model-training/training-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["training-list"])} hasBullet={true} />
          </PermissionContextProvider>
        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["welcome-list"]}>
        <AsideMenuItem to='/welcome-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["welcome-list"])} fontIcon={faHandsHelping} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["weekly-payout-report"]}>
        <AsideMenuItem to='/apps/cleaner/weeklysummery' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["weekly-payout-report"])} fontIcon={faCalendarWeek} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-weekly-payout-report"]}>
        <AsideMenuItem to='/apps/cleaner/weekly-payout-report' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["new-weekly-payout-report"])} fontIcon={faCalendarWeek} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["user-management"]}>
        <AsideMenuItemWithSub
          to='/apps/user-management/users'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["user-management"])}
          fontIcon={faUserFriends}
          isSubMenu={menuKeys}

        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["admin-users"]}>
            <AsideMenuItem to='/master/admin-users' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["admin-users"])} hasBullet={true} />
          </PermissionContextProvider>
          {/* <PermissionContextProvider keyName={adminMenuActionPermissionKeys["agent-offer-allotment"]}>
            <AsideMenuItem to='/user/agent-offer-allotment-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["agent-offer-allotment"])} hasBullet={true} />
          </PermissionContextProvider> */}

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["agent-offers-list"]}>
            <AsideMenuItem to='/user/agent-offers-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["agent-offers-list"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["not-at-home-list"]}>
            <AsideMenuItem to='/not-at-home-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["not-at-home-list"])} hasBullet={true} />
          </PermissionContextProvider>


        </AsideMenuItemWithSub>
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["settings"]}>
        <AsideMenuItem to='/admin/settings' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["settings"])} fontIcon={faGear} />
      </PermissionContextProvider>


      {/* {checkMenuStatus(menuKeys, [genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])], [genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])]) == false ? <></> : <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Completed</span>
        </div>
      </div>} */}

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-missed-call-lists"]}>
        <AsideMenuItem to='/customer/customer-missed-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["customer-missed-call-lists"])} fontIcon={faPerson} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["in-app"]}>
        <AsideMenuItem to='/inapp/ratings' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["in-app"])} fontIcon={faStar} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-device-login"]}>
        <AsideMenuItem to='/champ/device-hash-list' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["champ-device-login"])} fontIcon={faUnlockAlt} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["reward-and-penalties"]}>
        <AsideMenuItem to='/reward-and-penalties' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["reward-and-penalties"])} fontIcon={faMedal} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["admin-panel-carselona"]}>
        <AsideMenuItem to='/admin/AdminPanelCarselona' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["admin-panel-carselona"])} fontIcon={faLaptop} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["societies"]}>
        <AsideMenuItem to='/all/Societies' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["societies"])} fontIcon={faUserGroup} />
      </PermissionContextProvider>


      {/* {checkMenuStatus(menuKeys, [genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"]), genereteMenuTitle(adminMenuActionPermissionKeys["xxxxxxx"])], []) == false ? <></> :
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'> working on </span>
          </div>
        </div>} */}

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["working-on"]}>
        <AsideMenuItem to='/working-on' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["working-on"])} fontIcon={faBarsProgress} />
      </PermissionContextProvider>

{
      isAllowedForShubhamMoinRoshan?.includes(user) &&  <AsideMenuItem to='/WorkingOn2' isSubMenu={menuKeys} title={"In Progress"} fontIcon={faBarsProgress} />

}
      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["role-modules"]}>
        <AsideMenuItem to='/role-module' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["role-modules"])} fontIcon={faBarsProgress} />
      </PermissionContextProvider>

      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["invoices"]}>
        <AsideMenuItemWithSub
          to='/invoice'
          title={genereteMenuTitle(adminMenuActionPermissionKeys["invoices"])}
          fontIcon={faMarsDouble}
          isSubMenu={menuKeys}
        >
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["invoices"]}>
            <AsideMenuItem to='/invoice/invoices' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["invoices"])} hasBullet={true} />
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["subscription-month"]}>
            <AsideMenuItem to='/invoice/subscriptions' isSubMenu={menuKeys} title={genereteMenuTitle(adminMenuActionPermissionKeys["subscription-month"])} hasBullet={true} />
          </PermissionContextProvider>

        </AsideMenuItemWithSub>
      </PermissionContextProvider>

    </>
  )
}

export default AsidMenuMainList




// <AsideMenuItemWithSub
//   to='/subscriptions'
//   title='Subscriptions'
//   fontIcon='bi-chat-left'
// // icon='/media/icons/duotune/communication/com012.svg'
// >

  
 



// </AsideMenuItemWithSub>
//   <AsideMenuItem to='/subscriptions/monthly-onboard' title='Date wise Onboarding' hasBullet={true}/>