import React, { useEffect, useState } from 'react'
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault';
import SingleSelectSearchDetailsDefault from '../../../consts/filtersComponents/SingleSelectSearchDetailsDefault';

const UpdateSubArea = ({ show, setShow, row, setRowData = null }: any) => {
    const [apiLoader, setapiLoader] = useState(false);
    const [inititalLoader, setInititalLoader] = useState(false);
    const [subAreaListData, setSubAreaListData] = useState([])
    const [updateAreaDetails, setUpdateAreaDetails] = useState<any>(null);
    const [subAreaNameDefault, setsubAreaNameDefault] = useState<any>("");
    const [payload, setPayload] = useState<any>({
        champId: [row?.id],
        sub_area_id: null,
    });

    const loadInitialData = async () => {
        setInititalLoader(true)
        const getMapBlocksSubAreasList = await postRequest(URL_EndPoints()?.getMapBlocksSubAreasList, {
            "start": 1,
            "length": -1,
            "search": "",
            "orders": "desc",
            "columns": "id",
            "sortby": "",
            "direction": "desc",
            "searchType": "any",
            "filterData": {},
            "supervisorid": 489
        }, setInititalLoader)
        setSubAreaListData(getMapBlocksSubAreasList?.data?.data)
    }

    useEffect(() => {
        setInititalLoader(true)
        async function filtersss() {
            const updateData: any = subAreaListData?.find((area: any) => area.id == row?.cleaner_sub_area_details?.id)
            updateData && setUpdateAreaDetails({ label: updateData?.name, id: updateData?.id })
        }
        filtersss()
        setTimeout(() => {
            setInititalLoader(false)
        }, 2000)

    }, [row?.cleaner_sub_area_details?.name, subAreaListData])

    useEffect(() => {
        loadInitialData()
    }, [])

    const inputChangehandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
        setsubAreaNameDefault(event ? event?.item?.name : "")

    }

    const updateHandler = async (e: any) => {
        e.preventDefault();

        setapiLoader(true)
        const response: any = await postRequest(URL_EndPoints(null)?.addCleanerAddressInBulk, payload, setapiLoader)
        setapiLoader(false)
        if (response?.data?.status == 200) {
            if (setRowData) {
                // rowData?.cleanerArea?.areaDetail?.areaname
                row.cleaner_sub_area_details = {}
                row.cleaner_sub_area_details.name = subAreaNameDefault
                setRowData(row)
            }
            toast.success(response?.data?.msg, { position: "top-center" })
            setShow(false)
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
            setShow(false)

        }
    }

    return (
        <>
            <Modal size='lg' show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Update Sub Area </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={updateHandler}>


                        {
                            inititalLoader ? <IsLoading /> :
                                <div className={`col-12  mb-1`}>
                                    <SingleSelectSearchDetailsDefault
                                        handleChangeInputData={inputChangehandler}
                                        HeaderTitle='Select Sub Area Name'
                                        SelectData={subAreaListData || []}
                                        DynamicKeys={["name"]}
                                        id={"id"}
                                        name={"sub_area_id"}
                                        targetValue={"value"}
                                        defaultData={updateAreaDetails ? updateAreaDetails : null}
                                    // defaultData={null}
                                    ></SingleSelectSearchDetailsDefault>
                                </div>
                        }




                        <Form.Group className="mb-3 p-2" >
                            {
                                apiLoader ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary">
                                        Update Sub Area
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default UpdateSubArea
