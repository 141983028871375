import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TaskLists from './TaskLists'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { KTCardBody } from '../../../_metronic/helpers'
const TaskListController: any = ({ filterDataOutsource, cleanerid }: any) => {
    const dispatch = useDispatch()
    const getData = async () => {

        const getSubscriptionRenewalReason: any = await postRequest(URL_EndPoints(null)?.getSubscriptionRenewalReason, {}, null)
        let alertReasonData: any = []
        getSubscriptionRenewalReason?.data?.data?.map((reason: any) => {
            if (reason.category_id == 3) {
                alertReasonData.push(reason)
            }
        })

        dispatch({ type: "ALET_REASON", payload: alertReasonData })
        const activeChamps = getRequest(URL_EndPoints(null)?.getActiveChampList, null)
        const result: any[] = await Promise.allSettled([
            activeChamps
        ]);

        dispatch({ type: "ADD_ACTIVE_CHAMP_LIST", payload: result[0]?.value?.data?.data })
    }
    useEffect(() => {
        getData()
    }, [])


    return (
        <KTCardBody className='card py-4' >
            <TaskLists filterDataOutsource={filterDataOutsource} />
        </KTCardBody>
    )
}
export default TaskListController
