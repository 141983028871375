import React, { useState, useEffect, useReducer, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes, toastNotifier } from '../../consts/components/Utility';
import useOwnMoinState from './useOwnMoinState';
import { Button } from 'react-bootstrap';
import BulkApproveForm from './forms/BulkApproveForm';
import { champReferralBonusInitialStates, champReferralBonusReducer } from '../admins/ad_states/champReferralBonusStates';
import BulkCreateReferralRequest from './forms/BulkCreateReferralRequest';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList';
import { useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils';

const ChampReferralBonus = ({ filterDataOutsource }: any) => {

  const [storeFilterList, setStoreFilterList] = useOwnMoinState({})
  const [filterkey, setFilterKey] = useOwnMoinState({})
  const [selectedFilterData, setSelectedFilterData] = useOwnMoinState([])
  const [payload, setPayload] = useOwnMoinState(initialPayload(false, {}))
  const [sortedColumns, setSortedColumns] = useOwnMoinState(columns(handleFetchAllCallLogs));
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [champReferralBonusStates, setLocalStateDispatch] = useReducer(champReferralBonusReducer, champReferralBonusInitialStates);
  // api calling here logic  here +++++++++++++++++++++++++++++++
  async function InvokedRendered(payloads: any, setloading: any) {
    setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
    const { data } = await postRequest(URL_EndPoints(null)?.getChampReferralList, payloads, null)
    setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: data?.data })
    setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: data?.recordsTotal })
    setLocalStateDispatch({ type: "RTL_FILTER", payload: data?.filter?.champReferralListFilter })
    setLocalStateDispatch({ type: "RTL_PENDING", payload: false })
  }


  useEffect(() => {
    if (champReferralBonusStates?.filterData) {
      const newColumns = columns(handleFetchAllCallLogs, champReferralBonusStates?.filterData?.map((ele: any) => ele?.id));
      setSortedColumns(newColumns);
    }
  }, [champReferralBonusStates?.filterData]);
  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {

    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload

  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: 10 })
    await InvokedRendered(handlePagination(champReferralBonusStates?.search, champReferralBonusStates?.sortby, champReferralBonusStates?.direction, champReferralBonusStates?.page, champReferralBonusStates?.perPageSize), null)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  // handle pagination here 
  const handleSort = async (column: any, sortDirection: any) => {
    setLocalStateDispatch({ type: "RTL_SORT_BY", payload: column.sortField })
    setLocalStateDispatch({ type: "RTL_DIRECTION", payload: sortDirection })
    InvokedRendered(handlePagination(champReferralBonusStates?.search, column.sortField, sortDirection, champReferralBonusStates?.page, champReferralBonusStates?.perPageSize), null)
  };

  const handlePageChange = (page: any) => {
    setLocalStateDispatch({ type: "RTL_PAGE", payload: page })
    InvokedRendered(handlePagination(champReferralBonusStates?.search, champReferralBonusStates?.sortby, champReferralBonusStates?.direction, page, champReferralBonusStates?.perPageSize), null)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setLocalStateDispatch({ type: "RTL_PER_PAGE_SIZE", payload: newPerPageSize })
  };

  // deboucing    
  useEffect(() => {

    let timer = setTimeout(async function () {
      champReferralBonusStates?.search && setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
      champReferralBonusStates?.search && InvokedRendered(handlePagination(champReferralBonusStates?.search && champReferralBonusStates?.search, champReferralBonusStates?.sortby, champReferralBonusStates?.direction, champReferralBonusStates?.page, champReferralBonusStates?.perPageSize), null)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [champReferralBonusStates?.search])

  useEffect(() => {

    let { isFilter, champReferralBonusFilter } = filterDataOutsource || {}
    !champReferralBonusStates?.search && !isFilter && InvokedRendered(handlePagination(champReferralBonusStates?.search, champReferralBonusStates?.sortby, champReferralBonusStates?.direction, champReferralBonusStates?.page, champReferralBonusStates?.perPageSize), null)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: champReferralBonusFilter }, null)
    isFilter && setPayload({ ...payload, ["filterData"]: champReferralBonusFilter })
  }, [
    champReferralBonusStates?.search,
    filterDataOutsource?.champReferralBonusFilter,
    filterDataOutsource?.isFilter,
  ])


  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(champReferralBonusStates?.search, champReferralBonusStates?.sortby, champReferralBonusStates?.direction, champReferralBonusStates?.page, champReferralBonusStates?.perPageSize), null);
  }, [selectedFilterData?.length]);

  // fetch call regarding logic  
  function handleFetchAllCallLogs() {
    setLocalStateDispatch({ type: "RTL_FETCH_CALL_LOG_TOGGLER", payload: Math.random() })
  }

  useEffect(() => {
    champReferralBonusStates?.fetchCallToggler && setLocalStateDispatch({ type: "RTL_TEMP_FILTERED_DATA", payload: champReferralBonusStates?.filterData })
  }, [champReferralBonusStates?.fetchCallToggler])


  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setLocalStateDispatch({ type: "RTL_PENDING", payload: true });
      const clone = champReferralBonusStates?.filterDataTemp?.slice();
      const customerIds = clone.map((e: any) => e.customer_id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, null);

      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e?.vehicleCustomer?.phone || ele[0]?.To === e?.vehicleCustomer?.phone) || [];
        clone[i].callLogs = foundLog;
      }

      setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: clone })
      setLocalStateDispatch({ type: "RTL_PENDING", payload: false });

    }
    champReferralBonusStates?.filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [champReferralBonusStates?.filterDataTemp])

  // fetch call regarding logic  

  const handleSelectedRowsChange = (selectedRows: any) => {
    setLocalStateDispatch({ type: "RTL_FILTERED_SELECTED_ROWS", payload: selectedRows?.selectedRows })
  };

  const [showBulkApproveModal, setShowBulkApproveModal] = useState(false)
  const [showBulkCreateReferalRequest, setShowBulkCreateReferalRequest] = useState<any>(false)

  const handleRefresh = async () => {
    InvokedRendered(handlePagination(champReferralBonusStates?.search, champReferralBonusStates?.sortby, champReferralBonusStates?.direction, champReferralBonusStates?.page, champReferralBonusStates?.perPageSize), null)
    setLocalStateDispatch({ type: "RTL_FILTERED_SELECTED_ROWS", payload: [] })

  }


  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  const setSearch = (value: any) => {
    setLocalStateDispatch({ type: "RTL_SEARCH", payload: value })
  }

  const [showCampaignContact, setShowCampaignContact] = useState(false);

  const setFilteredSelectedRows = () => {
    setLocalStateDispatch({ type: "RTL_FILTERED_SELECTED_ROWS", payload: [] })
  }

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getChampReferralList")
  }, [])
  return (
    <>
      <div>

        <DataTable
          title={!filterDataOutsource?.isFilter && "Champ Referral List"}
          selectableRows
          // selectableRowDisabled={(row: any) => row?.champReferralRequest == null ? true : false}
          progressPending={champReferralBonusStates?.pending}
          columns={reorderColumns(sortedColumns, "champReferralBonusListTaskReorderedItem")}
          data={champReferralBonusStates?.filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "champReferralBonusListTaskReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          clearSelectedRows={toggledClearRows}
          // server 
          pagination
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={champReferralBonusStates?.totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}

          // server 
          subHeaderComponent={
            <>
              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={champReferralBonusStates?.search} setSearch={setSearch} />
                </LeftBox>

                <RightBox>
                  <Refresh refreshNow={handleRefresh} />
                  <FilterHandler
                    onChangeHandler={onChangeHandler}
                    actionHandler={actionHandler}
                    getCurrentFilter={getCurrentFilter}
                    removeFilter={removeFilter}
                    payload={payload}
                    Component={DynamicFilter}
                    filterData={champReferralBonusStates?.filter}
                    OperatorsData={operators}
                    selectedFilterData={selectedFilterData}
                    filterComponents={KeywiseComponents()}
                    storeFilterList={storeFilterList}
                    filterkey={filterkey}
                    setSeacrch={setSearchFilter}
                    isLoadingFilter={isLoadingFilter}
                    listApiUrl={listApiUrl("getChampReferralList")}

                  />
                </RightBox>
              </MainContainerWrapper>

              {
                champReferralBonusStates?.filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                  <BulkUtilButton title="Bulk Approve Champs" filteredSelectedRows={champReferralBonusStates?.filteredSelectedRows} setShow={setShowBulkApproveModal} />
                  <BulkUtilButton title="Bulk Create Referral Request Champs" filteredSelectedRows={champReferralBonusStates?.filteredSelectedRows} setShow={setShowBulkCreateReferalRequest} />
                  <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={champReferralBonusStates?.filteredSelectedRows} setShow={setShowCampaignContact} />
                </BulkUtilButtonWrapper> : <></>
              }

            </>
          }
        />

      </div>

      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={champReferralBonusStates?.filteredSelectedRows?.map((row: any) => row?.cleanerReferBy && row?.cleanerReferBy?.id)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={handleRefresh}
          userType={"cleaner"}
        /> : <></>
      }

      {
        showBulkApproveModal ?
          <BulkApproveForm
            show={showBulkApproveModal}
            setShow={setShowBulkApproveModal}
            filteredSelectedRows={champReferralBonusStates?.filteredSelectedRows}
            setFilteredSelectedRows={setFilteredSelectedRows}
            setToggleClearRows={setToggleClearRows}
            toggledClearRows={toggledClearRows}
          /> : <></>
      }
      {
        showBulkCreateReferalRequest ?
          <BulkCreateReferralRequest
            show={showBulkCreateReferalRequest}
            setShow={setShowBulkCreateReferalRequest}
            filteredSelectedRows={champReferralBonusStates?.filteredSelectedRows}
            setFilteredSelectedRows={setFilteredSelectedRows}
            setToggleClearRows={setToggleClearRows}
            toggledClearRows={toggledClearRows}
          /> : <></>
      }




    </>
  )
}

export default ChampReferralBonus
