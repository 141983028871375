import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postRequest } from '../../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import CustomerDefaultSelect from '../../../../consts/Select/CustomerDefaultSelect';

const UpdateReplacementFor = ({ row }: any) => {
    const isVacancyAssigned = row?.champ_requirement_completed_champ?.first_name + row?.champ_requirement_completed_champ?.last_name
    const [show, setShow] = useState<any>(false)
    const [loader, setLoader] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [payload, setPayload] = useState<any>({
        "id": row?.id,
        "updateFields": {
            replacement: null
        }
    })

    useEffect(() => { setStoreRow(row) }, [row])

    const updateRequirementType = async () => {
        if (payload?.updateFields?.replacement) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints(null)?.updateChampRequirement, payload, setLoader)
            if (response?.data?.status === 200) {
                row.replacement_champ = row.replacement_champ ? { ...row.replacement_champ } : {}
                row.replacement_champ.first_name = payload?.updateFields?.item?.first_name
                row.replacement_champ.last_name = payload?.updateFields?.item?.last_name
                row.replacement_champ.phone = payload?.updateFields?.item?.phone
                setStoreRow(row)
                toast.success(response?.data?.msg, { position: "top-center" })
                setShow(false)
            } else {
                toast.error(response?.data?.msg ? response?.data?.msg : "Seems server side problem", { position: "top-center" })
                setShow(false)
            }
            setLoader(false)
        }
        else {
            toast.error("Please select location ", { position: "top-center" })
            setLoader(false)

        }
    }


    const modalHandler = (isVacancyFilledBy: any) => {
        setShow(true)
    }
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchDataCleaner, setsearchDataCleaner] = useState<any[]>([])
    const [storeSeachKeywords, setstoreSeachKeywords] = useState<any>("")


    async function getSearchDataBykeyword(storeSeachKeywords: any) {
        setIsLoading(true)
        const response: any = await postRequest(URL_EndPoints()?.getChampsList, {
            keyword: storeSeachKeywords,
            cleaner_id: 0,
        }, setIsLoading);

        let newData = response?.data?.data?.map((ele: any) => {
            ele.first_name = ele?.first_name ?? "NA"
            ele.last_name = ele?.last_name ?? "NA"
            ele.phone = ele?.phone ?? "NA"
            return ele
        })

        newData && setsearchDataCleaner(newData);
        setIsLoading(false)
    }

    const handleSelectChange = (event: any, name: any) => {
        setPayload({ ...payload, ["updateFields"]: { ...payload?.updateFields, [name]: event ? event?.value : null, item: event ? event?.item : null } })
    }

    useEffect(() => {
        let x = setTimeout(() => {
            storeSeachKeywords && getSearchDataBykeyword(storeSeachKeywords)
        }, 2000)
        return () => {
            setIsLoading(false)
            clearTimeout(x)
        }
    }, [storeSeachKeywords])

    return (

        <>
            {
                loader ? "Updating..." : <>
                    <FontAwesomeIcon icon={faEdit} className={` me-2  cursor-pointer text-${ "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} />
                    <span>{storeRow?.replacement_champ?.first_name || storeRow?.replacement_champ?.last_name ? storeRow?.replacement_champ?.first_name + " " + storeRow?.replacement_champ?.last_name : "NA"}</span>
                </>
            }

            <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-small">
                <Modal.Header closeButton>
                    <Modal.Title>Update requirement name </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-3">
                        <div className="col-12 mb-2">
                            <CustomerDefaultSelect
                                handleChangeInputData={handleSelectChange}
                                HeaderTitle='Replacement for Champ'
                                SelectData={searchDataCleaner}
                                DynamicKey={'first_name'}
                                DynamicKey2={'last_name'}
                                DynamicKey3={'phone'}
                                id={'id'}
                                name='replacement'
                                defaultData={null}
                                setSeacrch={setstoreSeachKeywords}
                                isLoading={isLoading}
                            ></CustomerDefaultSelect>
                        </div>
                    </div>

                    <div className='d-flex flex-end justify-content-end col-12'>
                        <button
                            className={`btn btn-sm text-${loader ? "dark" : "white"} btn-${loader ? "success" : "primary"} mb-5 `}
                            onClick={updateRequirementType}
                            disabled={loader}
                        >
                            {loader ? "Updating..." : "Update"}
                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}
export default UpdateReplacementFor
