import clsx from 'clsx'
import React from 'react'
let isDrawer = false
const WrapperLayer = ({ children }: any) => {
    
    return (
        <>
            <div
                className='customer-detail-bodys'
                id={'kt_chat_messenger_body'}
            >
                <div
                    className={clsx('scroll-y me-n5 pe-5  max', { 'h-lg-auto': !isDrawer })}
                    data-kt-element='messages'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies={
                        '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                    }
                    data-kt-scroll-wrappers={
                        '#kt_content, #kt_chat_messenger_body'
                    }
                >
                    <div className='table  margins'>
                        {
                            children
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default WrapperLayer
