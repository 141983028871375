const initState = {
    visitorStatuses: [],
    societies: [],
    visitorSources: [],
    ticketStatuses: [],
    referByFilter: [],
    trainingLocations: [],
    vehicleTypes: [],
    trainers: [],
    adminList: [],
    supervisorList: [],
    activeChamps: [],
    subReasons: [],
}

const getNotNullData = (data: any) => data?.map((data: any) => {
    if (!data?.first_name) {
      data.first_name = '';
    }
    if (!data?.last_name) {
      data.last_name = '';
    }
    if (!data?.phone) {
      data.phone = '';
    }
    return data;
}) || [];

export const VisitorDataReducer = (state = initState, { type, payload }: any) => {
    switch (type) {
        case "ADD_STATUSES":
            return { ...state, visitorStatuses: getNotNullData(payload) }
        case "FETCH_SOCIETIES":
            return { ...state, societies: [ { id: 0, name: "Individual" }, ...payload ] }
        case "ADD_TICKET_SOURCES":
            return { ...state, visitorSources: [  { id: 0,  name: 'Not Assigned', status: 0}, ...payload] }
        case "ADD_TICKET_STATUSES":
            return { ...state, ticketStatuses: payload }
        case "ADD_REFER_BY_FILTER":
            return { ...state, referByFilter: [  { id: 0,  first_name: 'Not', last_name: ' Available', phone: ''}, ...payload]  }
        case "ADD_TRAINING_LOCATIONS":
            return { ...state, trainingLocations: payload }
        case "ADD_TRAINERS":
            return { ...state, trainers: payload }
        case "ADD_ADMIN_LIST":
            // {id: 7, first_name: "Pritesh", last_name: "Singh", phone: "777"}, 
            return { ...state, adminList: getNotNullData(payload) }
        case "ADD_SUPERVISOR_LIST":
            return { ...state, supervisorList: getNotNullData(payload) }
        case "ADD_ACTIVE_CHAMP_LIST":
            return { ...state, activeChamps: getNotNullData(payload) }
        case "ADD_SUB_REASONS":
            return { ...state, subReasons: payload }
        default:
            return state
    }
}