import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'

const CallbackAgentForm = ({ FilteredData, setStoreRowP2p = null, row }: any) => {
    const adminList = FilteredData.adminList
    const [adminId, setAdminId] = React.useState<any>(null);
    const [adminName, setAdminName] = React.useState<any>({});

    const [loader, setLoader] = useState(false)

    const handleChange = (event: any, name: any) => {
        if (event === null) {
            name === "name" && setAdminId(null)
        }else {
            setAdminId(event.value)
        }
    }
    const handleAgentUpdate = async () => {
        setLoader(true)
        if (adminId !== null) {
            let payloads = { callback_agent_id: adminId, cleaner_id: FilteredData?.selectedId?.cleaner_id, }
            const response = await postRequest(URL_EndPoints(null)?.updateChampColumns, payloads, setLoader)
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
            if (response?.data?.status === 200 && setStoreRowP2p) {
                row['callback_agent_id'] = +adminId
                setStoreRowP2p(row)
            }
            FilteredData?.handleCloseForm()

        } else {
            toast.error("Agent is missing")
            FilteredData?.handleCloseForm()
        }
        setLoader(false)
    }
    useEffect(() => {
        const updated = adminList?.filter((ele: any) => ele?.id === FilteredData?.selectedId?.adminId)
        FilteredData?.selectedId?.adminId && setAdminId(FilteredData?.selectedId?.adminId)
        FilteredData?.selectedId?.adminId && setAdminName(updated?.length > 0 ? updated[0] : "")
    }, [adminList, FilteredData?.selectedId?.adminId])

    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select Agent"}
                            SelectData={adminList}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name={"name"}
                            defaultData={adminName?.id ? { label: adminName?.first_name + ' ' + adminName?.last_name + ' (' + adminName?.phone + ')', value: adminName?.id } : {label: 'Select Agent', value: 0}}
                        />
                    </div>
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader} onClick={() => handleAgentUpdate()}>
                            {loader ? 'Updating...' : 'Update'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default CallbackAgentForm
