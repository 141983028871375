

import React, { memo } from 'react'
const ChampBtn = ({ title, phone, openChatModal, chatBtnBgColor, champ_id }: any) => {
    let changeBtnColor = chatBtnBgColor == champ_id 
    return (
        <span className={`badge  fs-8 fw-bold ${changeBtnColor ? "text-white bg-primary" : "text-dark"} cursor-pointer`} onClick={() => openChatModal()} key={champ_id ? champ_id : 8809448222}>
            {title ?? "NA"}
            <br />
            {phone ? (phone) : <></>}
        </span>
    )
}

export default memo(ChampBtn)
