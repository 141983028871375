import React from 'react';
import { withSwal as AdminYesNoAlertAction } from 'react-sweetalert2';
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminToastRunner } from '../adminUtils';
export default AdminYesNoAlertAction((props: any) => {
    const { swal, confirm, cancel, payload, successTitle, FailedTitle, setShow = null, show = false, setUpdatePrevState = null, refreshNow = null, setPending = null, row = {}, urlEndPoint, isOpenModal=null}: any = props;

    async function sendRequestToServer() {
        setPending && setPending(true)
        const response = await postRequest(URL_EndPoints(null)?.[urlEndPoint], payload, setPending)
        adminToastRunner(response)
        if (response?.data?.status == 200) {
            setUpdatePrevState && setUpdatePrevState(payload, row)
        }
        response?.data?.status == 200 && refreshNow && refreshNow()
        setShow && setShow(null)
    }

    React.useEffect(() => {
        if (show) {
            swal.fire({
                title: successTitle,
                showDenyButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    if(isOpenModal){
                        isOpenModal && isOpenModal(true)
                    }
                    else{
                        sendRequestToServer()
                    }
                } else if (result.isDenied) {
                    setShow(null)
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [show])
});


// const setUpdatePrevState = (updatedState: any, row:any) => {
//     data.pinned = data?.pinned == 1 ? 0 : 1
//     // setStoreRowData(data)
// }



// {
//     showUpdatePinned ? <div key={data?.id + "_" + data?.pinned}>
//         <AdminYesNoAlertAction
//             confirm='Yes'
//             cancel='No'
//             successTitle='Do you want to update the pinned status ?'
//             FailedTitle='Changes Cancelled '
//             payload={{
//                 id: data?.id,
//             }}
//             show={showUpdatePinned}
//             setShow={setShowUpdatePinned}
//             setUpdatePrevState={setUpdatePrevStatePinned}
//             refreshNow={null}
//             setPending={null}
//             urlEndPoint="togglePinUnpinFAQ"
//             row={data}

//         />
//     </div> : <></>
// }