import React from 'react'
import SingleSelectSearchCategory from '../../../consts/Select/SingleSelectSearchCategory'
const Updateform = ({ ParentData, handleChnageInputUpdate }: any) => {
    return (
        <div className="px-5 w-100 ">
            <h5 className='text-center mb-2 text-primary'>     Update </h5>
            <div className="row mb-5">
                <div className="col-12  mb-3">
                    <h5>Select Job</h5>
                    {/* {ParentData?.JobStatusData &&
                        <SingleSelectSearchCategory
                            handleChangeInputData={handleChnageInputUpdate}
                            HeaderTitle="Select Job"
                            SelectData={ParentData?.JobStatusData}
                            DynamicKey={"job_detail?.name"}
                            id={"id"}
                            name="jobid"
                        ></SingleSelectSearchCategory>
                    } */}
                    <select
                        name='date_typer'
                        aria-label='Select a Timezone'
                        data-control='select2'
                        data-placeholder='Period'
                        className='form-select form-select-sm form-select-solid'
                        onChange={(event: any) => handleChnageInputUpdate(event.target, "jobid")}
                    >
                        < option value="0">  {"Select Job"}</option>
                        {
                            ParentData?.JobStatusData?.map((ele: any) => {
                                return (
                                    <>
                                        < option value={ele.id}>{ele?.attendencedate} {" | "} {ele?.job_detail?.name}  { }</option>
                                    </>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-12  mb-3">
                    <h5>Change Status</h5>
                    <SingleSelectSearchCategory
                        handleChangeInputData={handleChnageInputUpdate}
                        HeaderTitle="Select Status"
                        SelectData={[{ name: 'Closed', id: 3 }, { name: 'Feedback Recieved', id: 4 }]}
                        DynamicKey={"name"}
                        id={"id"}
                        name="status"
                    ></SingleSelectSearchCategory>
                </div>
                <hr />
            </div>
            {/* ==================================================================================================== */}
            {/* </div> */}
            {/* </div> */}
        </div >
    )
}
export default Updateform
