import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { QueryParams } from '../../../consts/Server/QueryParams'
import { getRequest, postRequestWithFile } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import AdminTaskListStatusFileUpload from './adminComponentChampDashbaord/AdminTaskListStatusFileUpload'
const AdminTaskListStatusForm = ({ setShow, show, row, setRowData = null, prevInitialData }: any) => {

    const [loader, setLoader] = useState(false)
    const [listData, setListData] = useState([])
    const [payload, setPayload] = useState<any>({
        taskId: row?.id,
        taskStatus: null,
        notes: "",
    })

    useEffect(() => {
        if (prevInitialData?.value) {
            setPayload({ ...payload, ["taskStatus"]: prevInitialData?.value })
        }
    }, [prevInitialData?.value])

    useEffect(() => {
        async function loadInitialData() {
            let newQeueryParams = { ...QueryParams("getTaskTypeStatuses", "?") }
            newQeueryParams.What += `task_type=${row?.task_type}`
            const listData = await getRequest(URL_EndPoints(newQeueryParams)?.getTaskTypeStatuses, null)
            setListData(listData?.data?.data)
        }
        loadInitialData()
    }, [row?.task_type])

    const handleChnageOnSource = async (event: any, name: string) => {
        if (event?.item) {
            let Key = JSON.parse(event.item.input_fields)
            if (event?.item?.input_type == "dropdown") {
                setPayload({ taskStatus: event ? event?.item?.task_status_id : event, taskId: payload?.taskId, ["reasonId"]: null, item: event ? event?.item : event, selected: "reasonId" })
            }
            else {
                setPayload({ taskStatus: event ? event?.item?.task_status_id : event, taskId: payload?.taskId, [Key]: null, item: event ? event?.item : event, selected: Key })
            }
        }
        else {
            setPayload({ taskStatus: event ? event?.item?.task_status_id : event, taskId: payload?.taskId, item: event ? event?.item : event, selected: null })
        }
    }

    const updateHandler = async () => {
        if (payload?.item?.selfie_required == "true") {
            payload.selfie = samplefile
        } else {
            payload.selfie = null
        }

        if (payload?.taskId && payload?.taskStatus) {
            setLoader(true)
            const response = await postRequestWithFile(URL_EndPoints()?.markTrainingTaskStatus, payload, setLoader)
            if (response?.data?.status == 200) {
                if (setRowData) {
                    row.job_status = payload?.taskStatus
                    setRowData(row)
                }
                toast.success(response?.data?.msg, { position: "top-center" })
                setShow(false)
                setLoader(false)
            } else {
                toast.error(response?.data?.msg, { position: "top-center" })
                setLoader(false)
                setShow(false)
            }
        }
        else {
            toast.error("Something is missing", { position: "top-center" })
            setLoader(false)
            setShow(false)
        }
    }

    const [samplefile, setSamplefileFile] = useState<any>(null);

    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Status</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    {
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChnageOnSource}
                            HeaderTitle={"Select Status"}
                            SelectData={listData}
                            DynamicKey={"task_status"}
                            id={"id"}
                            name={"taskStatus"}
                            defaultData={prevInitialData}
                        />
                    }

                    <div className={`col-12  mb-3`}>
                        <input
                            type="text"
                            placeholder='Type here '
                            value={payload?.notes}
                            name="notes"
                            onChange={(event) => setPayload({ ...payload, [event?.target?.name]: event?.target?.value })}
                            className='form-control align-start col-12'
                        />
                    </div>

                    {
                        payload?.selected == "dueDate" ? <div className={`col-12  mb-3`}>

                            <input
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                                name="dueDate"
                                type="date"
                                onChange={(event) => setPayload({ ...payload, [event?.target?.name]: event?.target?.value })}
                                className={clsx(
                                    'form-select form-select-solid bg-white form-control form-control-solid mb-3 mb-lg-0',
                                )}
                            />
                        </div> : <></>
                    }

                    {
                        payload?.selected == "reasonId" ? <div className={`col-12  mb-3`}>
                            <select
                                name='reasonId'
                                aria-label='Select a Timezone'
                                data-control='select2'
                                data-placeholder='Period'
                                className='form-select form-select-sm '
                                value={payload?.reasonId}
                                onChange={(event) => setPayload({ ...payload, [event?.target?.name]: +event?.target?.value })}
                            >
                                < option >  Select </option>
                                {
                                    JSON.parse(payload?.item?.input_fields)?.map((ele: any) => {
                                        return (
                                            <>
                                                < option value={ele.id}>{ele?.reason}</option>
                                            </>
                                        )
                                    })
                                }
                            </select>

                        </div> : <></>
                    }

                    {
                        payload?.item?.selfie_required == "true" ? <div className={`col-12   mb-8 border p-2`}>
                            <AdminTaskListStatusFileUpload setSamplefileFile={setSamplefileFile} samplefile={samplefile} />
                        </div> : <></>
                    }

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 p-1 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => updateHandler()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h6 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating...</h6>
                                    </>
                                    : <h6 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Status</h6>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default AdminTaskListStatusForm
