export const ReactTableComponentStylesSheet = {
    rows: {
      style: {
        minHeight: '50px', 
        border:"none"
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', 
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    
  };


  

  // const ReactComponentStylesSheet = {
  //   headCells: {
  //     style: {
  //       backgroundColor: '#ffffff',
  //       fontWeight: 'bold',
  //       fontSize: '16px',
  //     },
  //   },
  //   headRow: {
  //     style: {
  //       backgroundColor: '#ffffff',
  //     },
  //   },
  // };


 