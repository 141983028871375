import React, { useState } from 'react'
import Not_Found_Data from '../../Not_Found_Data'
import uuid from 'react-uuid';
import { Button, Form, Modal } from 'react-bootstrap';
import UpdateAddresses from './UpdateAddresses';
import { adminMenuActionPermissionKeys } from '../../../modules/admins/adminConstant';
import PermissionContextProvider from '../../../modules/admins/adminComponents/PermissionContextProvider';


const AddressesList = ({ ctAddresses }: any) => {

    const [showModal, setshowModal] = useState<any>(false)
    const [loader, setloader] = useState<any>(false)
    const [disable, setdisable] = useState<any>(null)
    const [addressData, setAddressData] = useState<any>({})

    const updateAddress = (ctAddress: any) => {
        setAddressData(ctAddress)
        setshowModal(true)
    }

    const handleClose = () => {
        setshowModal(false)
    }
 
    return (
        <>


            <div className="mb-5 mr-4 px-4 mt-3 p-3"  >
                {
                    ctAddresses?.length > 0 ? ctAddresses?.map((ctAddress: any) => {

                        return <div className="alert alert-dark d-flex align-items-center p-5 mb-10" key={uuid()}>
                            <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                                {
                                    ctAddress.default ?
                                        <i className="bi bi-check2-square fs-2x"></i>
                                        : ''
                                }
                            </span>
                            <div className="d-flex flex-column w-75">
                                <span className='font-black'>{ctAddress.address}</span>
                            </div>

                            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["addresses-update-address"]}>
                            <div className='d-lex' style={{ position: "absolute", right: "1.5%" }}>
                                <i className="fas fa-edit fs-1  text-success  cursor-pointer" onClick={() => updateAddress(ctAddress)}></i>
                            </div>
                            </PermissionContextProvider>


                          
                        </div>
                    }
                    )
                        :
                        <Not_Found_Data headerTitle={"No Addresses Found"}></Not_Found_Data>

                }


            </div>


            {/* 
            
            <Modal size='lg' show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form>


                        <Form.Group className="mb-3" >
                            <Form.Label>Sub Category Name </Form.Label>
                            <Form.Control type="text" placeholder="Enter Sub Category Name" name="subcategory_name" value={""} onChange={OnchangeHandler} />
                        </Form.Group>



                        <Form.Group className="mb-3" >
                            <Form.Label>In App Text </Form.Label>
                            <Form.Control type="text" placeholder="Enter App Text " name="app_text" value={""} onChange={OnchangeHandler} />
                        </Form.Group>
                        <Button variant="primary" disabled={disable ? true : false} onClick={updateAddressHandler}>
                            {
                                loader ?
                                    <div className='d-flex'>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Creating....</h4>
                                    </div>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Submit </h4>
                            }
                        </Button>
                    </Form>



                </Modal.Body>
            </Modal> */}




            {showModal ? <UpdateAddresses showModal={showModal} handleClose={handleClose} addressData={addressData} /> : <></>}
        </>





    )
}
export default AddressesList
