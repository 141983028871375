import { faAdd, faBan, faEdit, faPenClip, faPlusSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react";
import { postRequest } from "../../Server/Requests";
import { URL_EndPoints } from "../../Server/URL_EndPoints";
import IsLoading from "../../IsLoading/IsLoading";
import UpdateBankDetails from "../actions/UpdateBankDetails";
import AddBankDetailsChamp from "../actions/AddBankDetailsChamp";
import ActiveInactiveStatus from "../../ActiveInactiveStatus";
import { toast } from "react-toastify";
import TransferPaymentChamp from "../actions/TransferPaymentChamp";
import { Button } from "react-bootstrap";
import { adminMenuActionPermissionKeys } from "../../../modules/admins/adminConstant";
import PermissionContextProvider from "../../../modules/admins/adminComponents/PermissionContextProvider";

const ChampBankDetails = ({ champsDetails }: any) => {
  const [isActiveOrInActiveBankDetails, setIsOldOrNewBankDetails] = useState<any>(1);
  const [openAddBankDetailModal, setOpenAddBankDetailModal] = useState<any>(false);
  const [verifyBankDetails, setVerifyBankDetails] = useState<boolean>(true);
  let [filterData, setfilterData] = useState<any[]>([])
  let [loader, setLoader] = useState(false)
  let [show, setShow] = useState(false)
  let [updatingId, setUpdatingId] = useState<any>({ id: null, refName: null })
  let [createBeneficieryLoader, setCreateBeneficieryLoader] = useState(false)
  const [createContactLoader, setCreateContactLoader] = useState(false)
  const [easebuzzWireContactId, setEasebuzzWireContactId] = useState(champsDetails?.easebuzzWireContactId)
  let [initialData, setInitialData] = useState<any>(null)

  async function getChampBankDetails(statusId: any) {
    setLoader(true)
    const response = await postRequest(URL_EndPoints()?.getChampBankDetails, { champId: champsDetails?.id, status: statusId }, setLoader)
    setfilterData(response?.data?.data)
  }

  useEffect(() => {
    getChampBankDetails(-1)
  }, [champsDetails?.id])

  const activeOrInactiveBankDetailHandler = () => {
    setIsOldOrNewBankDetails((prev: any) => prev == 0 ? 1 : 0)
  }
  const bankDetailUpdateModalHandler = (element: any) => {
    setInitialData(element)
    setShow(true)
  }

  const createBeneficieryIdHandler = async (bankDetailId: any) => {
    setUpdatingId({ ["id"]: bankDetailId, ["refName"]: "beneficiery" })
    setCreateBeneficieryLoader(true)
    const response = await postRequest(URL_EndPoints()?.createBankAccountBeneficiary, { bankDetailId: bankDetailId, verifyBankDetails: verifyBankDetails }, setCreateBeneficieryLoader)
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
    if (response?.data?.status == 200) {
      getChampBankDetails(-1)
    }
  }

  const contactHandler = async () => {
    setCreateContactLoader(true)
    const response = await postRequest(URL_EndPoints()?.newContact, { champId: champsDetails?.id }, setCreateContactLoader)
    if (response?.data?.status == 200) {
      setEasebuzzWireContactId('random-text-to-disble-re-create')
    }
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
    if (response?.data?.status == 200) {
      getChampBankDetails(-1)
    }

  }

  let textColor = isActiveOrInActiveBankDetails ? "primary" : "success"
  const [isTransferPaymentModalOpen, setIsTransferPaymentModalOpen] = useState(false)

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2 cursor-pointer mt-3"  >
        <div className="d-flex flex-column">
          <span
            className={`badge badge-light-transparent`}
          >
            <span className="form-check form-switch cursor-pointer  me-20" onClick={() => activeOrInactiveBankDetailHandler()}>
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={isActiveOrInActiveBankDetails == 1 ? false : true} />
              <label className={`form-check-label mt-1 fs-5 text-muted`} >Show inactive bank details</label>
            </span>

          </span>
          <span
            className={`badge badge-light-transparent`}
          >
            <span className="form-check form-switch cursor-pointer me-5" onClick={() => setVerifyBankDetails((prev: boolean) => !prev)}>
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={verifyBankDetails} />
              <label className={`form-check-label fw-bold mt-1  text-muted`} >Verify bank detail before adding beneficiery</label>
            </span>
          </span>
        </div>
        <div>


          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-bank-details-contact-create"]}>
            {
              easebuzzWireContactId ? <Button variant="success" disabled={true} className="btn-sm me-2">Contact Created</Button> : <Button className="btn-sm me-2" variant="primary" onClick={contactHandler}>Create Contact {createContactLoader && '...'}</Button>
            }
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-bank-details-payment-transfer"]}>
            <button className={`btn btn-sm btn-primary me-2`} onClick={() => setIsTransferPaymentModalOpen(true)} >Payment Transfer</button>
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-bank-details-add-bank-details"]}>
            <button className={`btn btn-sm btn-primary me-2`} onClick={() => setOpenAddBankDetailModal(true)} >Add Bank Details</button>
          </PermissionContextProvider>





        </div>
      </div>
      {
        loader ? <IsLoading /> :
          <div className='px-3 mt-2 table-responsive'>

            <table className="table table-bordered table-hover table-sm">
              <thead>
                <tr className='border px-3 bg-secondary'>
                  <th scope="col" className="ms-2">BENEFICIERY CREATED</th>
                  <th scope="col">Edit</th>
                  <th scope="col">NAME</th>
                  <th scope="col">ACCOUNT NO.</th>
                  <th scope="col">UPI ID</th>
                  <th scope="col">IFSC CODE</th>
                  <th scope="col">ACCOUNT TYPE</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">BANK NAME</th>
                  <th scope="col">ACCOUNT NAME</th>
                </tr>
              </thead>
              <tbody>
                {filterData?.length > 0 && filterData?.map((element: any) => {

                  let isEditAllowedAction = element?.beneficiary_id ? false : true
                  let isEditAllowedIcon = element?.beneficiary_id ? faBan : faEdit

                  if (isActiveOrInActiveBankDetails == 1 == element?.status) return (

                    <tr className={"border"} >
                      <td scope="row">

                        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-bank-details-beneficiary-createtion"]}>
                          {
                            createBeneficieryLoader && updatingId?.id == element?.id && updatingId?.refName == "beneficiery" ? "Creating..." : <>
                              {element?.beneficiary_id ? <span className="ms-2 text-success badge badge-badge-dark fs-4">✅</span> :
                                <i className="ms-3 bi bi-plus-square text-danger  fs-3 mt-1 cursor-pointer " onClick={() => createBeneficieryIdHandler(element?.id)}></i>
                              }
                            </>
                          }
                        </PermissionContextProvider>


                      </td>
                      <td >
                        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-bank-details-edit-bank-detail"]}>
                          <FontAwesomeIcon icon={isEditAllowedIcon} className="text-danger fs-4 ms-2 cursor-pointer" onClick={() => isEditAllowedAction ? bankDetailUpdateModalHandler(element) : () => { }} />
                        </PermissionContextProvider>
                      </td>
                      <td >{element?.holder_name ? element?.holder_name : "NA"}</td>
                      <td >{element?.account_no ? element?.account_no : "NA"}</td>
                      <td >{element?.upi_id ? element?.upi_id : "NA"}</td>
                      <td > {element?.ifsc ? element?.ifsc : "NA"} </td>
                      <td >{element?.account_type ? element?.account_type : "NA"}</td>
                      <td ><ActiveInactiveStatus isActive={element?.status} /> </td>
                      <td >{element?.bank_name}</td>
                      <td >{element?.account_name}</td>

                    </tr>
                  )

                  else if (isActiveOrInActiveBankDetails != 1) {
                    return (
                      <tr className={"border"} >
                        <td scope="row">
                          {
                            createBeneficieryLoader && updatingId?.id == element?.id && updatingId?.refName == "beneficiery" ? "Creating..." : <>
                              {element?.beneficiary_id ? <span className="ms-2 text-success badge badge-badge-dark fs-4">✅</span> :
                                <i className="ms-4 bi bi-plus-square text-danger  fs-3 mt-1 cursor-pointer " onClick={() => createBeneficieryIdHandler(element?.id)}></i>

                              }
                            </>
                          }
                        </td>
                        <td ><FontAwesomeIcon icon={isEditAllowedIcon} className="text-danger fs-4 ms-2 cursor-pointer" onClick={() => isEditAllowedAction ? bankDetailUpdateModalHandler(element) : () => { }} /></td>
                        <td >{element?.holder_name ? element?.holder_name : "NA"}</td>
                        <td >{element?.account_no ? element?.account_no : "NA"}</td>
                        <td >{element?.upi_id ? element?.upi_id : "NA"}</td>
                        <td > {element?.ifsc ? element?.ifsc : "NA"} </td>
                        <td >{element?.account_type ? element?.account_type : "NA"}</td>
                        <td ><ActiveInactiveStatus isActive={element?.status} /> </td>
                        <td >{element?.bank_name}</td>
                        <td >{element?.account_name}</td>

                      </tr>
                    )
                  }
                }
                )

                }
              </tbody>
            </table>
          </div>
      }

      {
        show ? <UpdateBankDetails show={show} setShow={setShow} initialData={initialData} champsDetails={champsDetails} getChampBankDetails={getChampBankDetails} setLoader={setLoader} /> : <></>
      }

      {
        openAddBankDetailModal ? <AddBankDetailsChamp show={openAddBankDetailModal} setShow={setOpenAddBankDetailModal} champsDetails={champsDetails} getChampBankDetails={getChampBankDetails} setIsOldOrNewBankDetails={setIsOldOrNewBankDetails} /> : <></>
      }
      {
        isTransferPaymentModalOpen ? <TransferPaymentChamp show={isTransferPaymentModalOpen} setShow={setIsTransferPaymentModalOpen} champsDetails={champsDetails} /> : <></>
      }
    </>
  )
}
export default ChampBankDetails