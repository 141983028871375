import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
const LastStatusform = ({ FilteredData, setStoreRowP2p = null, row, StatusUpdatedData, defaultCampaign }: any) => {
    const dispatch = useDispatch()

    const [StatusUpdateDataList, setStatusUpdateDataList] = React.useState<any>([]);
    const [StatusUpdatedReason, setStatusUpdatedReason] = React.useState<any>(null);
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        setStatusUpdateDataList(StatusUpdatedData)
    }, [StatusUpdatedData])
    const handleChange = (event: any, name: any) => {
        if (event === null) {
            setStatusUpdatedReason(null)
        }
        else {
            setStatusUpdatedReason(event.value)
        }
    }
    const handleInactiveReason = async () => {
        setLoader(true)
        if (StatusUpdatedReason) {
            let payloads = { subscriptionid: FilteredData?.selectedId?.subscriptionid, renewalreasonid: +StatusUpdatedReason }
            const response = await postRequest(URL_EndPoints(null)?.updateSubscriptionRenewalReason, payloads, setLoader)
            if (response?.data?.message) {
                toast.success(response?.data?.message)
                if (setStoreRowP2p) {
                    row.vehicleInactiveSubscription['renewal_reason_id'] = +StatusUpdatedReason
                    setStoreRowP2p(row)
                    dispatch({
                        type: "UPDATE_CHAT_DEFAULT_VALUE", payload: {
                            random: Math.random(),
                            reasonid: row?.vehicleInactiveSubscription?.renewal_reason_id,
                        }
                    })
                }
                FilteredData?.handleCloseForm()
            }
        } else {
            toast.error("Reason id missing")
            FilteredData?.handleCloseForm()
        }
    }
    
    useEffect(() => {
        defaultCampaign?.value && setStatusUpdatedReason(defaultCampaign?.value)
    }, [defaultCampaign ])
    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Status</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select  Reason"}
                            SelectData={StatusUpdateDataList}
                            DynamicKey={"name"}
                            id={"id"}
                            name={"name"}
                            defaultData={defaultCampaign}
                        />
                    </div>
              
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={ loader ? true : false} onClick={() => handleInactiveReason()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default LastStatusform
