import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { postRequest } from '../../Server/Requests'
import { adminToastRunner, useAdminSmartFilterList } from '../../../modules/admins/adminUtils'
import AdminSelectTextAreaBox from '../../../modules/admins/adminForms/adminFormComponents/AdminSelectTextAreaBox'
import AdminSelectCheckbox from '../../../modules/admins/adminForms/adminFormComponents/AdminSelectCheckbox'
import AdminTextBoxReuse from '../../../modules/admins/adminForms/adminFormComponents/AdminTextBoxReuse'
let nullValue: any = {
    title: "",
    description: "",
    is_public: 0,
    list_api_url: "",
    filter_obj: {},
}

const SmartFilterForm = ({ show, setShow, initialData, row = { filterData: {}, listApiUrl: "" }, urlEndPoint = "", formTitle }: any) => {

    let { adminGetSmartFilterList } = useAdminSmartFilterList()
    const [loader, setLoader] = useState<boolean>(false)
    const [payload, setPayload] = useState<any>(initialData)

    const handleSubmitAssign = async () => {
        let filterError: any = {}
        if (!payload?.title) {
            filterError["title"] = "⚠️ Title is missing "
        }
        if (!payload?.list_api_url) {
            filterError["list_api_url"] = "⚠️ List Api Url is missing "
        }

        let isValidedForm = Object.keys(filterError)?.length == 0

        if (isValidedForm) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.[urlEndPoint], payload, setLoader)
            adminToastRunner(response)
            response?.data?.status == 200 && setShow && setShow(false)
            response?.data?.status == 200 && adminGetSmartFilterList(row?.listApiUrl?.list_api_url)

        }
        else {
            toast.error(`${filterError["title"] ? filterError["title"] : ""} ${filterError["list_api_url"] ? filterError["list_api_url"] : ""}`, { position: "top-center" })
        }
    }

    const onChangeHandler = (event: any, name: any) => {
        if (name === "is_public") {
            setPayload({ ...payload, [name]: event?.value == true ? 1 : 0 })
        }
        else {
            setPayload({ ...payload, [name]: event ? event?.value : nullValue[name] })
        }
    }

    return (
        <>
            {
                show ? <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-small"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title> {formTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Note:  <span className='text-muted mb-4'> Before adding or updating smart filter please once cross check that you have selected appropriate filter and tested properly</span>

                        <div className='col-12 mb-2 mt-2' key={11212} >
                            <AdminTextBoxReuse
                                onChangeHandler={onChangeHandler}
                                HeaderTitle="Title..."
                                payload={payload}
                                name="title"
                                label=""
                            />
                        </div>
                        <div className='col-12 mb-4' key={112123} >
                            <AdminSelectTextAreaBox
                                onChangeHandler={onChangeHandler}
                                HeaderTitle="Description..."
                                payload={payload}
                                name="description"
                                label=""
                            />
                        </div>

                        <div className='col-12 mb-4' key={112123} >
                            <AdminSelectCheckbox
                                onChangeHandler={onChangeHandler}
                                HeaderTitle="Make filter as public"
                                payload={payload}
                                name="is_public"
                                label=""
                            />
                        </div>

                        <button disabled={loader} className={`btn btn-sm btn-${loader ? "success" : "primary"} fs-6 col-12 w-100 `} onClick={handleSubmitAssign}>
                            {loader ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                    <span>Processing...</span>
                                </>
                                : "Save"}
                        </button>

                    </Modal.Body>
                </Modal> : ''
            }
        </>
    )
}
export default SmartFilterForm
