import React from 'react'
import { Modal } from 'react-bootstrap'
import { CallogsBody } from './CallLogsComponents/CallogsBody'
const Call_logs = ({ FilteredData }: any) => {
    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleClose} dialogClassName="modal-large"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Customer Name : <span className='text-primary'>{FilteredData?.customer_name}</span>    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1 overflow-auto`} style={{ height: "400px", margin: 10, width: "99%", padding: "5px" }}>
                        <CallogsBody call_log_cusomerid={FilteredData?.call_log_cusomerid}></CallogsBody>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default Call_logs
