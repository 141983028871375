import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


function GenerateNode({ data }: any) {
    const randomId = Math.floor(Math.random() * 1000 + 1);

    return (
        <Accordion defaultActiveKey={randomId.toString()}>
            <Accordion.Item eventKey="0">

                <Accordion.Header>
                    <div className='d-flex justify-content-evenly w-100'>
                        <div className="col-sm">{data.name}</div>

                        <div className="col-sm">{parseInt(data.projected)}</div>
                        <div className="col-sm">{parseInt(data.tillNow)}</div>

                    </div>
                </Accordion.Header>
                <Accordion.Body >
                    {data.data.map((parentNode: any) => {
                        const parentRandomId = Math.floor(Math.random() * 1000 + 1);

                        return (
                            <Accordion>
                                <Accordion.Item eventKey={parentRandomId.toString()}>
                                    <Accordion.Header>
                                        <div className='d-flex justify-content-evenly w-100'>
                                            <div className="col-sm">{parentNode.categoryName}</div>

                                            <div className="col-sm">{parentNode.projectedValue}</div>
                                            <div className="col-sm">{parentNode.projectedValue}</div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {parentNode.details.map((subSubChildNode: any) => {
                                            const subSubRandomId = Math.floor(Math.random() * 1000 + 1);

                                            return (
                                                <Accordion>
                                                    <Accordion.Item eventKey={parentRandomId.toString()}>
                                                        <Accordion.Header>
                                                            <div className='d-flex justify-content-evenly w-100'>
                                                                <div className="col-sm">{subSubChildNode.name}</div>
                                                                <div className="col-sm">{subSubChildNode.projected}</div>
                                                                <div className="col-sm">{subSubChildNode.tillNow}</div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body >
                                                            {subSubChildNode.data.map((day: any) => (
                                                                <Accordion>
                                                                    <Accordion.Item eventKey={parentRandomId.toString()}>
                                                                        <Accordion.Header>
                                                                            <div className='d-flex justify-content-evenly w-100'>
                                                                                <div className="col-sm">{day.day} - {day.name}</div>
                                                                                <div className="col-sm">{day.projected}</div>
                                                                                <div className="col-sm">{day.tillNow}</div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                    </Accordion.Item>
                                                                </Accordion>


                                                            ))}
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                </Accordion>

                                            );
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        )

                    })
                    }



                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default GenerateNode;
