
import React, { useEffect, useState } from 'react'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import "./Cleaner.css"
import { Button, Modal, Row } from 'react-bootstrap'
import { GoogleMap, InfoWindow, LoadScript, Marker, Rectangle, Circle } from '@react-google-maps/api';
import { toast } from 'react-toastify'
import DeleteSubArea from '../../../consts/SweetAlert/DeleteSubArea'
import SubAreaMapListBox from './mapSubAreaListBox/SubAreaMapListBox'
import SearchAreaOnMapBox from './SearchAreaOnMapBox'
import GoogleIcon from "./clipart290500.png"

const CompanyCleanCustBoxMapCustom = () => {
  const [currentClickedLocation, setcurrentClickedLocation] = useState<any>(null)
  const [show, setShow] = useState(false);
  const [customAddressSearch, setCustomAddressSearch] = useState("")
  const [markerPositionLatLng, setMarkerPositionLatLng] = useState<any>(null)
  const [currentSelectedActionData, setCurrentSelectedActionData] = useState<any>({});
  const [currentSelectedAction, setCurrentSelectedAction] = useState<any>(null);
  const [updateSubAreaModal, setUpdateSubAreaModal] = useState<any>(false);
  const [currentSelectedActionToggle, setCurrentSelectedActionToggle] = useState<any>(false);
  const [updateLoder, setUpdateLoder] = useState<any>(false);
  const [pending, setPending] = useState(false);
  const [currentaddress, setcurrentAddress] = useState<any>("");
  const [searchAreaInput, setSearchAreaInput] = useState<any>("");
  const [markerData, setMarkerData] = useState<any>([]);
  const [centerLatLong, setCenterLatLong] = useState<any>(
    {
      lat: 12.97997772023569,
      lng: 77.59481858989614
    }
  )

  async function loadMarkerData() {
    setPending(true)
    const getMapBlocksSubAreasList = await postRequest(URL_EndPoints()?.getMapBlocksSubAreasList, {
      "start": 1,
      "length": -1,
      "search": "",
      "orders": "desc",
      "columns": "id",
      "sortby": "",
      "direction": "desc",
      "searchType": "any",
      "filterData": {},
      "supervisorid": 489
    }, setPending)
    setMarkerData(getMapBlocksSubAreasList?.data?.data)
  }

  useEffect(() => {
    loadMarkerData()
  }, [])

  const [mainRectangleHeights, setMainRectangleHeight] = useState<any>(30);
  const [mainRectangleWidths, setMainRectangleWidth] = useState<any>(30);
  const [circleRadius, setCircleRadius] = useState<any>()
  let mainRectangleHeight = +mainRectangleHeights / 111.32
  let mainRectangleWidth = +mainRectangleWidths / 111.32
  const [smallSquareSize, setSmallSquareSize] = useState<any>(3) 
  const [showMarkerCounts, setShowMarkerCounts] = useState<any>([]);
  const [randomIds, setRandomId] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState<any>(null);


  const numSmallSquaresRows = Math.floor(mainRectangleHeight * 111.32 / smallSquareSize);
  const numSmallSquaresCols = Math.floor(mainRectangleWidth * 111.32 / smallSquareSize);
  const mainRectangleArea = mainRectangleHeight * 111.32 * mainRectangleWidth * 111.32;
  const mainRectangleAspectRatio = mainRectangleWidth / mainRectangleHeight;

  const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 100px)',
  };

  const handleRectangleMouseOver = (rowIndex: any, key: any) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    const newHoverTimeout = setTimeout(() => {
      const updatedShowMarkerCounts = [...showMarkerCounts];
      updatedShowMarkerCounts[rowIndex] = true;
      setShowMarkerCounts(updatedShowMarkerCounts);
    }, 300); 
    setHoverTimeout(newHoverTimeout);
  };

  const handleRectangleMouseOut = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    setShowMarkerCounts([]);
    setRandomId(null)
  };



  const handleMapClick = (e: any) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    setShow(true)
    setcurrentClickedLocation({ lat: latitude, lng: longitude })
    const apiKey = "AIzaSyB1TLe_5fP2B85oU9mvzNnJbN6QuHjdjHE";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const address: any = data.results[0].formatted_address;
        setcurrentAddress(address)
      })
      .catch((error) => {
        console.error('Error fetching address:', error);
      });
  };

  const handleMapClickOnSearchMaker = (e: any) => {
    setShow(true)
    setcurrentClickedLocation({ lat: markerPositionLatLng?.lat, lng: markerPositionLatLng?.lng })
    const apiKey = "AIzaSyB1TLe_5fP2B85oU9mvzNnJbN6QuHjdjHE";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${markerPositionLatLng?.lat},${markerPositionLatLng?.lng}&key=${apiKey}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const address: any = data.results[0].formatted_address;
        setcurrentAddress(address)
      })
      .catch((error) => {
        console.error('Error fetching address:', error);
      });
  };

  const smallMarkerIcon = {
    url: "https://cdn-icons-png.flaticon.com/512/1247/1247580.png",
    scaledSize: new window.google.maps.Size(40, 40),
  }
  const searchMarkerIcon = {
    url:GoogleIcon,
    scaledSize: new window.google.maps.Size(40, 40),
  }

  const [apiloading, setapiloading] = useState(false)

  const handleAddSubmit = async () => {

    setapiloading(true)
    if (currentClickedLocation && searchAreaInput) {

      const response: any = await postRequest(URL_EndPoints()?.addBlocksSubArea,

        {
          "map_block_id": 1,
          "name": searchAreaInput,
          "address": currentaddress,
          "latitude": currentClickedLocation?.lat,
          "longitude": currentClickedLocation?.lng
        }
        , setapiloading)

      if (response?.data?.status == 200) {
        toast.success(response?.data?.msg, { position: "top-center" })
        setapiloading(false)
        loadMarkerData()
        setSearchAreaInput("")
        setShow(false)
        setcurrentClickedLocation(null)
      }
      else {
        toast.error(response?.data?.msg, { position: "top-center" })
        setapiloading(false)
        setSearchAreaInput("")
        setShow(false)
        setcurrentClickedLocation(null)

      }
    }
    else {
      toast.error("lat or lng or search Area name is missing", { position: "top-center" })
      setapiloading(false)

    }
  }

  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerHover = (marker: any) => {
    setSelectedMarker(marker);
  };

  const [updatePayload, setUpdatePayload] = useState<any>({
    "id": currentSelectedActionData?.id,
    "sub_area_status": currentSelectedActionData?.sub_area_status,
    "name": currentSelectedActionData?.name,
  })

  const handleMarkerClick = (marker: any) => {
    setUpdatePayload({ ...updatePayload, ["id"]: marker?.id, ["sub_area_status"]: marker?.sub_area_status, ["name"]: marker?.name, })
    setCurrentSelectedActionData(marker)
    setCurrentSelectedActionToggle(true)
    setCurrentSelectedAction(Math.random())
  };

  const updateSubAreaHandler = async () => {

    setUpdateLoder(true)
    if (updatePayload?.id && updatePayload?.name) {
      const response: any = await postRequest(URL_EndPoints()?.updateSubArea, updatePayload, setUpdateLoder)
      if (response?.data?.status == 200) {
        toast.success(response?.data?.msg, { position: "top-center" })
        setUpdateLoder(false)
        loadMarkerData()
        setSearchAreaInput("")
        setShow(false)

      }
      else {
        toast.error(response?.data?.msg, { position: "top-center" })
        setUpdateLoder(false)
        setSearchAreaInput("")
        setShow(false)
      }
    }
    else {
      toast.error("lat or lng or search Area name is missing", { position: "top-center" })
      setUpdateLoder(false)
    }
  }
  const closeBtnMap = () => {
    setShow(false)
    setcurrentClickedLocation(null)
  }
  const [map, setMap] = useState<any>(null);
  const calculateBounds = () => {
    if (!map || !markerPositionLatLng) return;
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(markerPositionLatLng);
    const center = bounds.getCenter();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, ne);
    const zoomLevel = Math.floor(14 - Math.log2(distance / 4000));
    return zoomLevel;
  };

  useEffect(() => {
    if (!map || !markerPositionLatLng) return;
    const zoomLevel = calculateBounds();
    if (zoomLevel) {
      map.fitBounds(new window.google.maps.LatLngBounds(markerPositionLatLng));
      map.setZoom(zoomLevel); 
    }
  }, [map, markerPositionLatLng]);

  const [circleCenter, setCircleCenter] = useState<any>({})
  const handleCircleDrag = (event: any) => {
    setCircleCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };
  return (
    <>
      <div>
        <div className='d-flex justify-content-between mt-1' style={{ width: "100%" }}>
          <div className='d-flex align-middle' style={{ width: "80%", display: "inlineFlex" }}>
           
          </div>
        </div>

        <div className="d-flex g-2" >

          <div className='d-flex flex-column  flex-wrap g-2'>
            <label className='fw-bold'>Height- KM</label>
            <input type="text" className='border  me-3 mt-2' placeholder='Height(in KM)' value={mainRectangleHeights} onChange={(event: any) => setMainRectangleHeight(event.target.value)} style={{ border: "none", outline: "none", height: "30px", width: "100px" }} />
          </div>

          <div className='d-flex flex-column  flex-wrap g-2'>
            <label className='fw-bold'>Width - KM</label>
            <input type="text" className='border  me-3 mt-2' placeholder='Width(in KM)' value={mainRectangleWidths} onChange={(event: any) => setMainRectangleWidth(event.target.value)} style={{ border: "none", outline: "none", height: "30px", width: "100px" }} />
          </div>

          <div className='d-flex flex-column  flex-wrap g-2'>
            <label className='fw-bold'>Box - KM</label>
            <input type="text" className='border  me-3 mt-2' placeholder='Box (in KM)' value={smallSquareSize} onChange={(event: any) => setSmallSquareSize(+event.target.value)} style={{ border: "none", outline: "none", height: "30px", width: "100px" }} />
          </div>


          <div className='d-flex flex-column  flex-wrap g-2'>
            <label className='fw-bold'>Center - Longitude</label>
            <input type="text" className='border  me-3 mt-2' placeholder='Longitude ' value={centerLatLong?.lat} onChange={(event: any) => setCenterLatLong({ ...centerLatLong, ["lat"]: +event.target.value })} style={{ border: "none", outline: "none", height: "30px", width: "100px" }} />
          </div>

          <div className='d-flex flex-column  flex-wrap g-2 me-5'>
            <label className='fw-bold'>Center - Latitude</label>
            <input type="text" className='border  me-3 mt-2' placeholder='Latitude ' value={centerLatLong?.lng} onChange={(event: any) => setCenterLatLong({ ...centerLatLong, ["lng"]: +event.target.value })} style={{ border: "none", outline: "none", height: "30px", width: "100px" }} />
          </div>

          <div className='d-flex flex-column  flex-wrap g-2 me-5'>
            <label className='fw-bold'>Radius</label>
            <input type="number" className='border  me-3 mt-2' placeholder='Radius (in KM)' onChange={(event) => setCircleRadius(Number(event.target.value) * 1000)} style={{ border: "none", outline: "none", height: "30px", width: "100px" }} />
          </div>


          <div className='d-flex flex-column  flex-wrap g-2 col-3 me-2 mt-4 ms-3'>
            <SearchAreaOnMapBox address={customAddressSearch} setMarkerPositionLatLng={setMarkerPositionLatLng} />
          </div>

        </div>
        <br />
        <br />
        {
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={centerLatLong}
            zoom={10}
            onClick={(e) => handleMapClick(e)}
            onLoad={(map) => setMap(map)}
          >
            {markerData?.map((marker: any, index: any) => {
              const smallMarkerIcon = {
                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                scaledSize: new window.google.maps.Size(40, 40),
              };
              return <Marker
                key={index}
                position={{ lat: +marker?.latitude, lng: +marker?.longitude, }}
                // onClick={() => handleUpdateMarkerPosition(marker)}
                onMouseOver={() => handleMarkerHover(marker)}
                onMouseOut={() => setSelectedMarker(null)}
                onClick={() => handleMarkerClick(marker)}
                icon={smallMarkerIcon}
              >

                {selectedMarker === marker && (
                  <InfoWindow>
                    <div className='d-flex flex-column fw-bold'>
                      <div className='mb-2'>
                        <label>Name : </label> <span>{marker?.name}</span>
                      </div>
                      <div className='mb-2'>
                        <label>Address : </label> <span>{marker?.address}</span>
                      </div>
                      <div className='mb-2'>
                        <label>Status : </label> <span className={`${marker?.sub_area_status == 0 ? "text-danger" : "text-success"}`}>{marker?.sub_area_status == 0 ? "Inactive" : "Active"}</span>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            })}


            {
              markerPositionLatLng && <Marker
                key={452635}
                position={markerPositionLatLng}
                onClick={(e) => handleMapClickOnSearchMaker(e)}
                icon={searchMarkerIcon}
              />
            }

            {
              currentClickedLocation && <Marker
                key={2142}
                position={currentClickedLocation}
                // onClick={() => handleMarkerDragEnd(marker)}
                icon={smallMarkerIcon}
              />
            }


            {circleRadius > 0 && markerPositionLatLng && (
              <Circle
                center={markerPositionLatLng}
                radius={circleRadius}
                // draggable={true}
                onDrag={(event) => handleCircleDrag(event)}
                options={{
                  strokeColor: '#000000',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: 'rgba(0, 255, 0, 0.35)',
                  fillOpacity: 0.35,
                  // clickable: true,
                  draggable: true,
                  // draggable: false,
                  // editable: false,
                  // visible: true,
                  radius: parseInt(circleRadius),
                  zIndex: 9999
                }}
              />
            )}



            <>

              {
                smallSquareSize &&
                Array.from({ length: numSmallSquaresRows }, (_, rowIndex) => (
                  <div key={rowIndex}>
                    {Array.from({ length: numSmallSquaresCols }, (_, colIndex) => {
                      let unique_id_generated: any = rowIndex + "" + colIndex
                      const squareCenter = {
                        lat: centerLatLong.lat + mainRectangleHeight * 0.5 - (rowIndex + 0.5) * smallSquareSize / 111.32,
                        lng: centerLatLong.lng - mainRectangleWidth * 0.5 + (colIndex + 0.5) * smallSquareSize / 111.32,
                      };
                      return (
                        <div key={colIndex} style={{ position: 'relative', }}>
                          <Rectangle
                            bounds={{
                              north: squareCenter.lat + smallSquareSize / 111.32 * 0.5,
                              south: squareCenter.lat - smallSquareSize / 111.32 * 0.5,
                              east: squareCenter.lng + smallSquareSize / 111.32 * 0.5,
                              west: squareCenter.lng - smallSquareSize / 111.32 * 0.5,
                            }}

                            options={{
                              fillOpacity: 0.5,
                              strokeColor: "black",
                              strokeWeight: 2,
                              fillColor: "transparent",
                              zIndex: 9999999999
                            }}
                            onMouseOver={() => handleRectangleMouseOver(rowIndex, unique_id_generated)}
                            onMouseOut={() => handleRectangleMouseOut()}
                            onClick={(e) => handleMapClick(e)}

                          />
                        </div>
                      );
                    })}
                  </div>
                ))
              }
            </>
          </GoogleMap>


        }
        <br />
        <br />
        <br />
        {
          pending ? <IsLoading /> : markerData?.length >= 0 ? <SubAreaMapListBox markerData={markerData} /> : <>No Data Available</>
        }
      </div>
      <Modal size='lg' show={show} onHide={() => closeBtnMap()}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sub Area </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Address : {currentaddress}</span>
          <br />
          <span>Latitude : {currentClickedLocation?.lat}</span>
          <br />
          <span>Longitude : {currentClickedLocation?.lng}</span>
          <br />
          <br />

          <input
            type="text"
            placeholder='Enter Sub Area Name'
            value={searchAreaInput}
            onChange={(e: any) => setSearchAreaInput(e.target.value)}
            className='w-100 form-control me-2 align-start mb-2'
          />
          <div className='text-end'>
            <button className='btn btn-sm  btn-primary' onClick={handleAddSubmit} disabled={apiloading}>
              {
                apiloading ? "Adding..." : "Add sub area"
              }
            </button>
          </div>

        </Modal.Body>
      </Modal>

      <Modal size='lg' show={updateSubAreaModal} onHide={() => setUpdateSubAreaModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Sub Area </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`col-12  mb-2`}>
            <input
              type="text"
              placeholder='Enter Sub Area Name'
              value={updatePayload?.name}
              name='name'
              onChange={(e) => setUpdatePayload({ ...updatePayload, [e.target.name]: e.target.value })}
              className='w-100 form-control me-2 align-start mb-2'
            />
          </div>

          <div className="col-12  mb-2 ms-2">
            <div className="mb-10">
              <div className="form-check form-check-custom form-check-solid form-check-lg">
                <input className="form-check-input" type="checkbox" id="sub_area_status" name='sub_area_status' onChange={(e) => setUpdatePayload((prev: any) => ({ ...prev, ["sub_area_status"]: prev?.sub_area_status == 1 ? 0 : 1 }))} />
                <label className="form-check-label">
                  Status
                </label>
              </div>
            </div>
          </div>

          <div className='text-end'>
            <button className='btn btn-sm  btn-primary' onClick={updateSubAreaHandler} disabled={updateLoder}>

              {
                updateLoder ? "Updating..." : "Update sub area"
              }
            </button>
          </div>

        </Modal.Body>
      </Modal>

      {
        currentSelectedActionToggle ? <DeleteSubArea
          confirm='Yes'
          cancel='No'
          successTitle='Changes Are Saved Successfully '
          FailedTitle='Changes Cancelled '
          render={currentSelectedAction}
          payload={{
            id: currentSelectedActionData?.id,
          }}
          setCurrentSelectedAction={setCurrentSelectedAction}
          setUpdateSubAreaModal={setUpdateSubAreaModal}
          setCurrentSelectedActionToggle={setCurrentSelectedActionToggle}
          setCurrentSelectedActionData={setCurrentSelectedActionData}
          setUpdatePayload={setUpdatePayload}
        ></DeleteSubArea>
          : <></>}



    </>
  )
}
export default CompanyCleanCustBoxMapCustom
// Blue marker: http://maps.google.com/mapfiles/ms/icons/blue-dot.png
// Green marker: http://maps.google.com/mapfiles/ms/icons/green-dot.png
// Yellow marker: http://maps.google.com/mapfiles/ms/icons/yellow-dot.png
// Orange marker: http://maps.google.com/mapfiles/ms/icons/orange-dot.png
// Purple marker: http://maps.google.com/mapfiles/ms/icons/purple-dot.png
// Pink marker: http://maps.google.com/mapfiles/ms/icons/pink-dot.png




















