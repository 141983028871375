import React from 'react';
import { withSwal as CallWaiting } from 'react-sweetalert2';
import "./css.css"
export default CallWaiting((props: any) => {
    const { swal, Render, Hide, message, icons, setIcons, setMessage, setRe_Render } = props;
    React.useEffect(() => {
        if (Render) {
            swal.fire({
                position: 'center',
                icon: icons,
                title: message,
                showConfirmButton: false,
                timer: Hide,
                imageUrl: !icons && 'https://www.carselonadaily.com/wp-content/uploads/2019/06/logo_black.png',
                imageWidth: 400,
                imageHeight: 60,
                didOpen: () => {
                    icons == "" && swal.showLoading()
                },
                customClass: {
                    title: !icons ? 'custom-swal-title' : "custom-swal-title2", // Add the custom class to the title element
                },

            }).then((result: any) => {
                setRe_Render(null)
                setIcons("")
                setMessage("Pls wait call connecting")
            })
        }
    }, [Render, message])
});
