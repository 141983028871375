import React from 'react';
import { withSwal as CallActionYesNoCancel } from 'react-sweetalert2';
import { adminToastRunner } from '../admins/adminUtils';
import axios from 'axios';
import { appBaseUrlSwitched } from '../../consts/Server/Requests';

export default CallActionYesNoCancel((props: any) => {
    const { swal, confirm, cancel, payload, updateNewPayload, successTitle, FailedTitle, setShow = null, show = false, setUpdatePrevState = null, refreshNow = null, setPending = null, row = {}, urlEndPoint, }: any = props;
    async function sendRequestToServer(payload: any) {
        setPending && setPending(true)
        const response: any = await axios.post(`${appBaseUrlSwitched(JSON.parse(localStorage.getItem("API") || "0"))}/${urlEndPoint}`, payload).then((res) => {
            setPending && setPending(false)
            return res
        }).catch((err: any) => {
            setPending && setPending(false)
            return err
        });

        adminToastRunner(response)
        if (response?.data?.status == 200) {
            setUpdatePrevState && setUpdatePrevState(payload, row)
        }
        setShow && setShow(null)
    }

    React.useEffect(() => {
        if (show) {
            swal.fire({
                title: successTitle,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel
            }).then((result: any) => {
                if (result.isConfirmed) {
                    sendRequestToServer(updateNewPayload(payload, true))
                }
                else if (result.isDenied) {
                    sendRequestToServer(updateNewPayload(payload, false))
                }
                else if (result.isDismissed) {
                    setShow(null)
                    swal.fire(FailedTitle)
                }


            })
        }
    }, [show])
});
