import React, { useEffect, useState } from 'react'

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import "./styles/Dashboard.css"
import Split from 'react-split'
import ChatSection from './ChatSection'
import ChatDashboardSection from './ChatDashboardSection'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { getWhatsappTemplate, openChatByChamp, openChatByMessage, trainingTaskLists } from '../../../Redux/champChatDashboardReducer/champsActions'
import { CHAMPS_ALL_DATA, CHAT_BTN_BG_COLOR, LOADING, SHOW_CHAMP_DASHBOARD_FROM_OUTSIDE, STATUS_DATA, STORE_ROW_DATA_FOR_CHAMP_DASHBOARD } from '../../../Redux/champChatDashboardReducer/champsTypes'
import { URL_EndPoints } from '../Server/URL_EndPoints'
import { getRequest, postRequest } from '../Server/Requests'
import ChampBtn from './ChampBtn'
import { timeCaptureCloseChat, timeCaptureOpenChat } from '../timeCaptureOpenCloseChat'
import { catchCurrentOpenedUrl } from '../components/Utility'
import { toast } from 'react-toastify'
import { enableFeatureList } from '../../modules/admins/adminConstant'
import { setIsChatOpenedOrNot } from '../../modules/admins/adminUtils'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3'
const userid = JSON.parse(localStorage.getItem("user") || "0")

const OpenChat = ({ title, phone, champId, toCreateTicketForNewListApi, trackData = [], totalRowDataForChampDashboard = [], tableRowData = {}, showChamp = false, notificationTicketId }: any) => {
    // to call new api to create the ticket if already ticket not exist then send this toCreateTicketForNewListApi="getInactiveChampTicket" by changing getInactiveChampTicket with  the new api url otherwise dont send any api or make pass false value or dont send the toCreateTicketForNewListApi key in props
    const dispatch = useDispatch()
    const [showImagesToggle, setShowImagesToggle] = React.useState<any>(false)
    const [showImagesLink, setShowImagesLink] = React.useState("")
    const [newChampId, setNewChampId] = React.useState<any>(champId)
    const [isNextOrPrev, setIsNextOrPrev] = useState<any>(null)
    const [isOpen, setIsOpen] = useState<any>(showChamp)
    const chatBtnBgColor = useSelector((store: any) => store.champsReducer.chatBtnBgColor)
    useEffect(() => { setNewChampId(champId) }, [champId])
    useEffect(() => { setIsOpen(showChamp) }, [showChamp])
    const [currentIndex, setCurrentIndex] = useState<any>(null);

    const toggleDrawer = () => {
        dispatch({
            type: "CHAT_SOURCE", payload: {
                CurrentMessageSourceRender: Math.random(),
                from: "chatMessages"
            }
        })
        openChatModal()
        dispatch({ type: CHAMPS_ALL_DATA, payload: {} })
    }

    const openChatModal = () => {
        dispatch({ type: CHAT_BTN_BG_COLOR, payload: champId })
        isOpen == true && dispatch({ type: SHOW_CHAMP_DASHBOARD_FROM_OUTSIDE, payload: { champId: null, show: false } })
        setIsOpen((prevState: any) => !prevState)
        isOpen == false && timeCaptureOpenChat(champId, "champ_id")
        isOpen == false && setIsNextOrPrev(null)
        isOpen == true ? timeCaptureCloseChat(JSON.parse(localStorage.getItem("timeCapture") || "0") || "0", "champ_id") : <></>

    }



    async function invoked() {
        const adminData = await getRequest(URL_EndPoints(null)?.getAdminList, null) || []
        const supervisorDAta = await getRequest(URL_EndPoints(null)?.getSupervisorList, null) || []
        const ticketCategoryData = await getRequest(URL_EndPoints(null)?.getTicketCategories, null) || []
        const ticketSubCategoryData = await getRequest(URL_EndPoints(null)?.getTicketSubCategories, null) || []
        const ticketSourceData = await getRequest(URL_EndPoints(null)?.getTicketSources, null) || []

        dispatch({
            type: STATUS_DATA, payload: {
                adminData: adminData?.data?.data,
                supervisorData: supervisorDAta?.data?.data,
                ticketCategoryData: ticketCategoryData?.data?.data,
                ticketSubCategoryData: ticketSubCategoryData?.data?.data,
                ticketSourceData: ticketSourceData?.data?.data,
            }
        })
    }

    async function getInitialDataForDashboard(currentChampId: any) {
        notificationTicketId && setIsChatOpenedOrNot(notificationTicketId, false, "champ_chat_opened")

        async function createdTicketIfDoesNotExists() {
            dispatch({ type: LOADING, payload: true })
            const response: any = await postRequest(URL_EndPoints(null)?.[toCreateTicketForNewListApi ?? "getChampLeadTicket"], {
                cleanderid: currentChampId,
                user_id: userid,
            }, null);
            dispatch(openChatByChamp({ champId: currentChampId }, currentChampId) as any)
            dispatch(openChatByMessage({ ticketid: notificationTicketId ? notificationTicketId : response?.data?.data?.id }, currentChampId) as any)
        }
        isOpen && createdTicketIfDoesNotExists()
        isOpen && dispatch(trainingTaskLists({ champId: currentChampId }, currentChampId) as any) //3639
        isOpen && dispatch(getWhatsappTemplate(currentChampId) as any)

    }

    useEffect(() => {
        isOpen && getInitialDataForDashboard(champId)
        isOpen == true && setNewChampId(champId)
        isOpen == true && setCurrentIndex(null)
        if (isAllowedToStoreRowDataForChampDashboard && isOpen == true) {
            isAllowedToStoreRowDataForChampDashboard && dispatch({ type: STORE_ROW_DATA_FOR_CHAMP_DASHBOARD, payload: { key: champId, response: tableRowData } })
        }
    }, [isOpen, champId])

    useEffect(() => {
        isOpen && invoked()
    }, [isOpen])

    // ✅ next prev button logic ___________________________________
    let isAllowedToStoreRowDataForChampDashboard = catchCurrentOpenedUrl() in enableFeatureList

    const previuosHandler = () => {

        if (trackData == undefined) return toast.error("Feature is not available for this List !")
        let index = trackData.indexOf(newChampId);
        if (index !== -1 && index < trackData.length) {
            let currentIndexValue = currentIndex ? currentIndex - 1 : index - 1
            let prevElement = trackData[currentIndexValue];
            if (prevElement == undefined) return toast.error("Prev Record Not Available", { position: "top-center" })
            let prevCurrentRowElementForChampDashboard = totalRowDataForChampDashboard[currentIndexValue]
            isAllowedToStoreRowDataForChampDashboard && dispatch({ type: STORE_ROW_DATA_FOR_CHAMP_DASHBOARD, payload: { key: prevElement, response: prevCurrentRowElementForChampDashboard } })
            setCurrentIndex(currentIndexValue);
            setIsNextOrPrev("Prev")
            getInitialDataForDashboard(prevElement)
            setNewChampId(prevElement)
        }
    }

    const nextHandler = () => {
        if (trackData == undefined) return toast.error("Feature is not available for this List !")
        let index = trackData.indexOf(newChampId);
        if (index !== -1 && index < trackData.length) {
            let currentIndexValue = currentIndex ? currentIndex + 1 : index + 1
            let nextElement = trackData[currentIndexValue];
            if (nextElement == undefined) return toast.error("Next Record Not Available", { position: "top-center" })
            let prevCurrentRowElementForChampDashboard = totalRowDataForChampDashboard[currentIndexValue]
            isAllowedToStoreRowDataForChampDashboard && dispatch({ type: STORE_ROW_DATA_FOR_CHAMP_DASHBOARD, payload: { key: nextElement, response: prevCurrentRowElementForChampDashboard } })
            setCurrentIndex(currentIndexValue);
            setIsNextOrPrev("Next")
            getInitialDataForDashboard(nextElement)
            setNewChampId(nextElement)
        }

    }
    // ✅ next prev button logic ___________________________________

    return (
        <>

            <div className={clsx('d-flex align-items-center align-content-start', toolbarButtonMarginClass)}>
                <ChampBtn title={title} phone={phone} openChatModal={openChatModal} chatBtnBgColor={chatBtnBgColor} champ_id={newChampId} />
                <Drawer
                    lockBackgroundScroll={true}
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction='right'
                    className='bla bla bla'
                    size={"100vw"}
                    overlayOpacity={0.1}
                >
                    {showImagesToggle && <div style={{ width: "100%", height: "100%", position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 99999, margin: "auto", boxShadow: "0px 0px 5px red" }} onClick={() => setShowImagesToggle(false)}>
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                            <i style={{ textAlign: "right", height: "2%", marginTop: "50px", marginRight: "50px", fontSize: "50px" }} className="bi bi-x-circle-fill text-danger fs-1 text-primary mt-10 me-10 cursor-pointer " onClick={() => setShowImagesToggle(false)} ></i>
                            <img src={showImagesLink} alt="" style={{ width: "80%", height: "80%", margin: 'auto' }} />
                        </div>
                    </div>
                    }

                    {
                        isOpen ?
                            <Split sizes={[67, 33]}
                                className="split"
                                style={{ height: "100%", minHeight: "85%" }}
                            >
                                <ChatDashboardSection cleanerid={newChampId} />
                                <ChatSection toggleDrawer={toggleDrawer} setShowImagesLink={setShowImagesLink} setShowImagesToggle={setShowImagesToggle} champsDetails={{}} cleanerid={newChampId} isNextOrPrev={isNextOrPrev} previuosHandler={previuosHandler} nextHandler={nextHandler} />
                            </Split> : <></>
                    }

                </Drawer>
            </div>
        </>
    )
}

export default OpenChat