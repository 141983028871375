import { useEffect, useState } from 'react';
import moment from 'moment';
import HistoryLists from './forms/HistoryLists';
import OpenChat from '../../consts/champChatDashboard/OpenChat';
import AmountEditable from './forms/AmountEditable';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { postRequest } from '../../consts/Server/Requests';
import { toast } from 'react-toastify';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { faBars, faDotCircle, faHamburger, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
let arr = ["Pending", "Approved", "Denied"]

export const columns = (handleFetchAllCallLogs?: any) => [
    {
        id: 1,
        name: "ACTION",
        cell: (row: any) => <Actions row={row} />,
        minWidth: "200px",
    },
    {
        id: 2,
        reorder: true,
        name: "CHAMP NAME",
        cell: (row: any) => <OpenChat title={row?.beneficiar?.first_name || row?.beneficiar?.last_name ? row?.beneficiar?.first_name + " " + row?.beneficiar?.last_name : "NA"} champId={row?.beneficiar?.id} phone={row?.beneficiar?.phone} />,
        // sortField: 'beneficiar.first_name',
        // sortable: true,
        minWidth: "200px",
    },


    {
        id: 3,
        reorder: true,
        name: "AMOUNT",
        cell: (row: any) => <AmountEditable row={row} />,
        sortField: 'amount',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 11,
        reorder: true,
        name: "TRANSACTION TYPE",
        cell: (row: any) => <span className={`${row?.transaction_type == "credit" ? "success" : "danger"}`} >{row?.transaction_type}</span>,
        sortField: 'transaction_type',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 12,
        reorder: true,
        name: "REASON",
        cell: (row: any) => row?.reason ? row?.reason?.name || row?.reason?.reason : "",
        // sortField: 'reason',
        // sortable: true,
        minWidth: "200px",
    },
    {
        id: 10,
        reorder: true,
        name: "SERVICE DATE",
        cell: (row: any) => row?.service_date ? row?.service_date || row?.service_date : "",
        sortField: 'service_date',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 8,
        reorder: true,
        name: "REQUESTE BY",
        cell: (row: any) => row?.requested_user?.first_name ? row?.requested_user?.first_name + " " + row?.requested_user?.last_name : "NA",
        // sortField: 'requested_user.first_name',
        // sortable: true,
        minWidth: "200px",
    },
    {
        id: 4,
        reorder: true,
        name: "APPROVED BY",
        cell: (row: any) => row?.approver?.first_name ? row?.approver?.first_name + " " + row?.approver?.last_name : "NA",
        // sortField: 'approver.first_name',
        // sortable: true,
        minWidth: "200px",
    },

    {
        id: 9,
        reorder: true,
        name: "REQUESTE TYPE",
        cell: (row: any) => row?.request?.name ? row?.request?.name : "NA",
        sortField: 'request.name',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 5,
        reorder: true,
        name: "STATUS",
        cell: (row: any) => row?.status ? row?.status : "NA",
        sortField: 'status',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 6,
        reorder: true,
        name: "CREATED AT ",
        cell: (row: any) => moment(row?.createdAt).format("DD-MM-YYYY"),
        sortField: 'createdAt',
        sortable: true,
        minWidth: "200px",
    },


    {
        id: 7,
        reorder: true,
        name: "HISTORY",
        cell: (row: any) => <HistoryList row={row} />,
        // sortField: 'history',
        // sortable: true,
        minWidth: "200px",
    },

];

const Actions = ({ row }: any) => {
    const [storeRow, setStoreRow] = useState<any>(row)
    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const [pending, setPending] = useState<any>(false)
    const hnadleUpdate = async (value: any) => {
        setPending(true)
        const response: any = await postRequest(URL_EndPoints(null)?.updateRequestForReward, {
            "id": row?.id,
            "status": value?.toLowerCase()
        }, setPending)

        if (response?.data?.status === 200) {
            toast.success(response?.data?.msg, { position: "top-center" })
            row.status = value?.toLowerCase()
            setStoreRow(row)
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
        }
    }

    return (
        <>

            {
                pending ? <IsLoading /> : <select
                    name='date_typer'
                    data-control='select2'
                    data-placeholder='Period'
                    className='form-select form-select-sm '
                    defaultValue={storeRow?.status}
                    onChange={(event: any) => hnadleUpdate(event.target.value)}
                >
                    {
                        arr?.map((ele: any) => {
                            return (
                                <>
                                    < option value={ele?.toLowerCase()} >{ele}</option>
                                </>
                            )
                        })
                    }
                </select>
            }
        </>
    )
}

const HistoryList = ({ row }: any) => {
    const [show, setShow] = useState<any>(false)
    return (
        <>
            <FontAwesomeIcon icon={faHistory} className='fs-2 ms-2 text-primary' style={{ cursor: "pointer" }} onClick={() => setShow(true)} />
            {
                show ? <HistoryLists show={show} setShow={setShow} row={row} /> : <></>
            }
        </>
    )
}
