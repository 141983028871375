import React from 'react'
import AdminMultiSelectFilter from '../admins/adminForms/adminFormComponents/AdminMultiSelectFilter'
const AMFilter = ({ ParentData }: any) => {

  return (

    <div className='p-6 w-325px w-md-375px'>
      <h3 className='fw-bold text-dark mb-8'>Search & Filteration</h3>
      <div className='row mb-2 mb-md-0'>
        <div className='col-md-12 mb-2'>
          <label htmlFor="date">Date</label>
          <input
            type='date'
            style={{ height: '38px' }}
            name="date"
            value={ParentData?.payloads["date"]}
            onChange={(event: any) =>
              ParentData?.onChangeHandler(event.target, "date", true)
            }
            className='w-100 form-control me-2 align-start'
          />
        </div>

        <div className='col-md-12'>
        <label htmlFor="Customer Vintage">Customer Vintage</label>

          <input
            className='form-control'
            type="number"
            name="customerVintage"
            placeholder='Customer Vintage'
            style={{ height: '38px' }}
            onChange={(event) => ParentData?.onChangeHandler(event.target, "customerVintage", true)}
            value={ParentData?.payloads["customerVintage"]}
          />

        </div>
      </div>



      <div className='col-12 mt-2'>
      <label htmlFor="AM Filter">AM Filter</label>

        <AdminMultiSelectFilter
          onChangeHandler={ParentData?.onChangeHandler}
          HeaderTitle="Select Area Manager"
          SelectData={ParentData?.areaManagerList || []}
          displayFields={["first_name", "last_name", "phone"]}
          id="id"
          name="areaManagerIDs"
          defaultData={null}
          searchable={false}
          setSeacrch={false}
          isLoading={false}
          filters={null}
        />
      </div>

    </div>
  )
}

export default AMFilter

