import { bulkTicketPayloadData } from "./constants"

export const initial_rewards_states = {
    // fundamental comman variables  

    pending: false,
    loader: false,
    filterData: [],
    filterDataTemp: [],
    filteredSelectedRows: [],

    // varies based on requirement variable 
    masterReasonData: [],
    pausePaidData: [],
    renewalReasonData: [],
    adminListData: [],
    supervisorListData: [],
    vehicleReasonData: [],
    whatsAppTemplatesData: [],
    fetchCallToggler: null,
    storeFilterList: null,

    // common states dont need to make every time changes 
    totalRows: 0,
    perPageSize: 10,
    sortby: "",
    direction: "desc",
    page: 1,
    search: "",
    filterkey: {},
    filter: [],

    // bulk ticket assing  common variable  

    bulkTicketModalOpen: false,
    isBulkAssignApiLoading: false,
    amountModalOpen: false,
    isLoadingSubmitAmount: false,
    ticketPayload: bulkTicketPayloadData(["dueDate"]),


    // bulk Whatsapp Notification send common variable 
    bulkWhatsAppNotificationModalOpen: false,
    isBulkWhatsAppApiLoading: false,
    whatsAppPayload: {
        templateName: '',
    }



}


export const rewardsTaskStates = (initial_rewards_states: any, { type, payload }: any) => {

    switch (type) {
        case "RTL_PENDING":
            return { ...initial_rewards_states, pending: payload }
        case "RTL_LOADER":
            return { ...initial_rewards_states, loader: payload }
        case "RTL_FILTERED_DATA":
            return { ...initial_rewards_states, filterData: payload }
        case "RTL_TEMP_FILTERED_DATA":
            return { ...initial_rewards_states, filterDataTemp: payload }
        case "RTL_FILTERED_SELECTED_ROWS":
            return { ...initial_rewards_states, filteredSelectedRows: payload }
        case "RTL_MASTER_REASON":
            return { ...initial_rewards_states, masterReasonData: payload }
        case "RTL_PAUSE_PAID":
            return { ...initial_rewards_states, pausePaidData: payload }
        case "RTL_RENEWAL_REASON":
            return { ...initial_rewards_states, renewalReasonData: payload }
        case "RTL_ADD_ADMIN_LIST":
            return { ...initial_rewards_states, adminListData: payload }
        case "RTL_ADD_SUPERVISOR_LIST":
            return { ...initial_rewards_states, supervisorListData: payload }
        case "RTL_ADD_VEHICLE_REASONS":
            return { ...initial_rewards_states, vehicleReasonData: payload }
        case "RTL_WHATSAPP_TEMPLATE":
            return { ...initial_rewards_states, whatsAppTemplatesData: payload }
        case "RTL_FETCH_CALL_LOG_TOGGLER":
            return { ...initial_rewards_states, fetchCallToggler: payload }
        case "RTL_STOLRE_FILTER_LISTS":
            return { ...initial_rewards_states, storeFilterList: payload }
        case "RTL_TOTAL_ROWS":
            return { ...initial_rewards_states, totalRows: payload }
        case "RTL_PER_PAGE_SIZE":
            return { ...initial_rewards_states, perPageSize: payload }
        case "RTL_SORT_BY":
            return { ...initial_rewards_states, sortby: payload }
        case "RTL_DIRECTION":
            return { ...initial_rewards_states, direction: payload }
        case "RTL_PAGE":
            return { ...initial_rewards_states, page: payload }
        case "RTL_SEARCH":
            return { ...initial_rewards_states, search: payload }
        case "RTL_FILTER_KEY":
            return { ...initial_rewards_states, filterkey: payload }
        case "RTL_FILTER":
            return { ...initial_rewards_states, filter: payload }
        case "RTL_BULK_TICKET_ASSIGN_MODAL":
            return { ...initial_rewards_states, bulkTicketModalOpen: payload }
        case "RTL_BULK_ASSINING_API_LOADING":
            return { ...initial_rewards_states, isBulkAssignApiLoading: payload }
        case "RTL_BULK_TICKET_PAYLOAD":
            return { ...initial_rewards_states, ticketPayload: payload }
            // Whatsapp notificaion case here 
        case "RTL_BULK_WHATS_APP_MODAL_OPEN":
            return { ...initial_rewards_states, bulkWhatsAppNotificationModalOpen: payload }
        case "RTL_BULK_WHATS_APP_API_LOADING":
            return { ...initial_rewards_states, isBulkWhatsAppApiLoading: payload }
        case "RTL_BULK_WHATS_APP_PAYLOAD":
            return { ...initial_rewards_states, whatsAppPayload: payload }
        case "OPEN_AMOUNT_MODAL":
            return { ...initial_rewards_states, amountModalOpen: payload }
        case "API_LOADING_AMOUNT":
            return { ...initial_rewards_states, isLoadingSubmitAmount: payload }
        default:
            return initial_rewards_states
    }
}





