
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import { renderToString } from 'react-dom/server';
import Handsontable from 'handsontable';
import { Modal } from 'react-bootstrap'
import ShowFilterHint from './ticketComponents/ShowFilterHint'
import { colWidthsHotTable, getFilterSelectedRowsData, handleAfterSelectionRow, hotInstanceLoader, rocordTotalFound, searchFieldKeyupCallback, cellRenderInner } from '../admins/adminUtils';
import IsLoading from '../../consts/IsLoading/IsLoading';
import moment from 'moment';
import MomentDate from '../../consts/MomentDate';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import { BulkUtilButton } from '../../consts/reuseModules/BulkUtilButton';
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu';
import TicketCategoryFilter from './ticketComponents/TicketCategoryFilter';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import Refresh from '../../consts/Refresh';
import ExcelExportButton2 from '../../consts/ExcelExportButton2';
import Not_Found_Data from '../../consts/Not_Found_Data';

registerAllModules();

const SubCategoryListModal = ({ show, setShow, intervalType, filteredSelectedRows }: any) => {
    const [pending, setPending] = useState(false)
    const hotTableComponentRef = useRef<any>(null);
    const [search, setSearch] = useState<any>("")
    const [payload, setPayload] = useState<any>({
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: MomentDate(0),
        type: "daily",
        category_id: filteredSelectedRows?.length > 0 ? filteredSelectedRows[0]?.category_id : null
    })
    const [filterData, setFilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [columns, setColumns] = useState<any>([])

    async function getSubTicketCountData4List() {
        if (payload?.category_id) {
            setPending(true)
            const subTicketCountData = await postRequest(URL_EndPoints(null)?.getTicketsCountBasedOnSubCategory, payload, setPending)
            setColumns(subTicketCountData?.data?.columns || [])
            setFilterData(subTicketCountData?.data?.data || [])
            setData(subTicketCountData?.data?.data || [])
        }
    }

    useEffect(() => {
        getSubTicketCountData4List()
    }, [])

    let firstTimeNotRun = useRef<any>(false)
    useEffect(() => {
        let x = setTimeout(() => {
            const filterSearchData = AllFielSearch(data, search)
            firstTimeNotRun?.current === true && setFilterData(filterSearchData)
            if (firstTimeNotRun?.current === false) {
                firstTimeNotRun.current = true
            }
        }, 2000)
        return () => {
            clearTimeout(x)
        }
    }, [search])

    const searchHandler = () => {
        getSubTicketCountData4List()
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
    }

    useEffect(() => {
        hotInstanceLoader(hotTableComponentRef)
    }, []);
    const cellRenderer = (...args: any) => {
        cellRenderInner(args, Handsontable)
    }

    const removeUnderScore = (str: any) => {
        if (str?.includes("_")) {
            return str?.split("_")?.join(" ")?.toUpperCase()
        }
        else {
            return str?.toUpperCase()
        }
    }

    const shortWords = (sentence: any, index: any) => {
        if (index < 1) { return removeUnderScore(sentence) }
        else if (index == 1) { removeUnderScore(sentence?.split('_')[1]) }
        return removeUnderScore(sentence?.split(' ')[1])
    }

    // const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
    //     handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
    // };

    // const handleAfterDeselect = () => {
    //     setFilteredSelectedRows([]);
    // };

    // useEffect(() => {
    //     let clearTimeoutFunction = getFilterSelectedRowsData(filteredSelectedRows, filterData, setChartData)
    //     return () => {
    //         clearTimeout(clearTimeoutFunction)
    //     }
    // }, [filteredSelectedRows])

    return (
        <>
            <Modal
                size='xl'
                show={show}
                onHide={() => setShow(false)}
                fullscreen={true}
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex gap-3'>
                        <div className='mb-4 fs-4 fw-semibold'>Sub Category Count List</div>
                        <ShowFilterHint payload={payload} showSubcategory={filteredSelectedRows?.length > 0 ? filteredSelectedRows[0] : {}} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MainContainerWrapper>
                        <LeftBox>
                            <SearchBoxModal search={search} setSearch={setSearch} />
                        </LeftBox>
                        <RightBox>
                            <Refresh refreshNow={searchHandler} />
                            <ExcelExportButton2 tableName=".ticketSubCountCategoryList" />
                            {
                                <DropDownFilterMenu
                                    ParentData={{
                                        handleActions: searchHandler,
                                        payloads: payload,
                                        onChangeHandler: onChangeHandler,
                                        intervalType: intervalType,
                                        defaultInterval: { label: "Daily", value: "daily" },
                                        defaultListType: { label: "Category", value: "category" },
                                        showListType: false
                                    }}
                                    Component={TicketCategoryFilter}
                                ></DropDownFilterMenu>
                            }
                        </RightBox>
                    </MainContainerWrapper>

                    {
                        !pending && columns?.length == 0 && <Not_Found_Data headerTitle="No Sub Category Data Is Found"></Not_Found_Data>
                    }

                    {pending ? <IsLoading /> : <div >
                        <HotTable
                            ref={hotTableComponentRef}
                            style={{ zIndex: 1 }}
                            data={filterData}
                            columns={columns?.map((ele: any, index: any) => {
                                return {
                                    title: renderToString(<div id={ele} >{shortWords(ele, index)} </div>),
                                    type: 'text',
                                    data: ele,
                                    renderer: cellRenderer
                                }
                            })}
                            className='ticketSubCountCategoryList'
                            filters={true}
                            dropdownMenu={true}
                            colWidths={colWidthsHotTable(columns, 1, 150, 55)}
                            width="100%"
                            height={"80vh"}
                            title='Sub Ticket Count Category Wise'
                            rowHeaders={true}
                            colHeaders={true}
                            fixedColumnsLeft={1}
                            contextMenu={true}
                            manualColumnFreeze={true}
                            autoWrapRow={true}
                            autoWrapCol={true}
                            licenseKey="non-commercial-and-evaluation"
                            autoRowSize={true}
                            autoColumnSize={true}
                            readOnly={true}
                            manualRowResize={true}
                            manualColumnResize={true}
                            manualRowMove={true}
                            multiColumnSorting={true}
                            search={{ callback: searchFieldKeyupCallback }}
                        // afterSelection={handleAfterSelection}
                        // afterDeselect={handleAfterDeselect}
                        />
                    </div>}

                </Modal.Body>
            </Modal>

        </>
    )
}

export default SubCategoryListModal