
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { postRequest } from './app/consts/Server/Requests';
import { URL_EndPoints } from './app/consts/Server/URL_EndPoints';

var firebaseConfig = {
  // apiKey: "AIzaSyD2rlyABrIi5G25e9AJBOfkicP2Ro3M_28",
  // authDomain: "carselonaadmin.firebaseapp.com",
  // projectId: "carselonaadmin",
  // storageBucket: "carselonaadmin.appspot.com",
  // messagingSenderId: "919284456550",
  // appId: "1:919284456550:web:332051f571c7a890a761a7"
  apiKey: "AIzaSyBTrQCzPyFCAUsdwd_wC3G2s03Tm7rP7UU",
  authDomain: "carselona-customer.firebaseapp.com",
  projectId: "carselona-customer",
  storageBucket: "carselona-customer.appspot.com",
  messagingSenderId: "472570645415",
  appId: "1:472570645415:web:dd9a8c7312b045d773c482",
  measurementId: "G-7L0XQVQSMK"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

const saveToken = async (token) => {
  let userid = localStorage.getItem("user") || 0
  await postRequest(URL_EndPoints(null)?.saveAdminToken, {
    userid: userid,
    token: token,
  }, null)
}

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: 'BDV_DvTpBl1xanA30zY6t8wud5U2BUqoAZ8Bzg-1uOhbDXvV_igBzZQqL14VPbVVpJu3Ls1Rpe01BB9P9gQ60kw' })
    .then((currentToken) => {
      if (currentToken) {
        saveToken(currentToken)
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
      }
    })
    .catch((err) => {
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

