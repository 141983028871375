import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { adminToastRunner } from '../admins/adminUtils'
import { toast } from 'react-toastify'

const AddOn = ({ show, setShow, row, setUpatedData }: any) => {
    const [loader, setLoader] = useState(false)
    const [value, setValue] = useState<any>(0)
    useEffect(() => { setValue(row?.add_on) }, [row?.add_on])

    const updateAddOnCoupon = async () => {
        if (+value > row?.add_on) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.provideAddOnOffer, {
                allotmentId: row?.id,
                addOnQuantity: +value
            }, setLoader)
            adminToastRunner(response)
            if (response?.data?.status == 200) {
                setShow(false)
                setUpatedData(+value)
            }
        }
        else {
            toast.error("Value should be greater than current", { position: "top-center" })
        }
    }

    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter-duedate"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add On Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Quantity</span>
                    <input
                        placeholder='Quantity'
                        name='Quantity'
                        type='number'
                        value={value}
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        onChange={(e) => setValue(e.target.value)}
                    />
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => updateAddOnCoupon()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}

export default AddOn