import React, { useState } from "react";
import { toast } from "react-toastify";
import { postRequest } from "../../../consts/Server/Requests";
import { URL_EndPoints } from "../../../consts/Server/URL_EndPoints";
import moment from "moment";
import { Modal } from "react-bootstrap";
const AdminTaskListTaskDateForm = ({ FilteredData, handleCloseForm,  setStoreRow = null, row }: any) => {
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [taskDate, setTaskDate] = useState<any>(moment(FilteredData?.attendencedate).format('YYYY-MM-DD') || moment().add(0, "days").format("YYYY-MM-DD"));
    const handleTaskDateSaveAction = async () => {
        if (taskDate) {
            setLoader(true)
            FilteredData.attendencedate = taskDate
            const response = await postRequest(URL_EndPoints(null)?.updateTaskData, FilteredData, setLoader)
            if (response?.status == 200) {
                if (response?.data?.status !== 200) {
                    toast.info(response?.data?.message)
                } else {
                    toast.success(response?.data?.message)
                    if (setStoreRow) {
                        row['attendencedate'] = taskDate
                        setStoreRow(row)
                        setDisable(false)
                    }
                    handleCloseForm()
                }
            }
        } else {
            toast.error("Date is missing")
            handleCloseForm()
        }
    }
    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={handleCloseForm} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter-duedate"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Task Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        placeholder='Enter Task Date'
                        name='taskDate'
                        type='date'
                        value={moment(taskDate).format('YYYY-MM-DD') || moment().add(0, "days").format("YYYY-MM-DD")}
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        onChange={(e) => setTaskDate(e.target.value)}
                    />

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={disable || loader ? true : false} onClick={() => handleTaskDateSaveAction()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default AdminTaskListTaskDateForm;
