import React, { FC } from 'react'
import AdminSelectSearchable from '../../modules/admins/adminForms/adminFormComponents/AdminSelectSearchable'
const AttendanceRegisterfiltration = ({ ParentData }: any) => {

  return (
    <>
      <div className='p-6 w-325px w-md-375px'>
        <h3 className='fw-bold text-dark mb-7'>Search & Filteration</h3>

        <div className='row mb-1'>

          <div className='col-6'>
            <span className='fw-bold mb-2'>Start Date</span>
            <input
              type='date'
              style={{ height: '38px' }}
              value={ParentData?.payloads?.from}
              name='from'
              onChange={(event: any) =>
                ParentData.onChangeHandler(event.target, 'from')
              }
              className='w-100  height-100 form-control me-2 align-start'
            />
          </div>

          <div className='col-6'>
            <span className='fw-bold mb-2'>End Date</span>

            <input
              type='date'
              style={{ height: '38px' }}
              name='to'
              value={ParentData?.payloads?.to}
              onChange={(event: any) =>
                ParentData.onChangeHandler(event.target, 'to')
              }
              className='w-100 form-control me-2 align-start'
            />
          </div>

        </div>

        <AdminSelectSearchable
          HeaderTitle='Search Champ Name'
          onChangeHandler={ParentData?.onChangeHandler}
          selectData={ParentData?.storeFilterListItem["cleanerListData"]}
          displayFields={["first_name", "last_name", "phone"]}
          id="id"
          name="cleanerid"
          defaultData={null}
          searchable={true}
          setSeacrch={ParentData?.setSearchFilter}
          isLoading={ParentData?.isLoadingFilter}
          filters={{
            reqApi: "getChampsList",
            storeKeyName: "cleanerListData",
          }}
        />
      </div>
    </>
  )
}
export { AttendanceRegisterfiltration }
