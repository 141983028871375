import React, { memo, useEffect, useRef, useState } from 'react';
import JSONEditor, { JSONEditorOptions } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { KTCard } from '../../../_metronic/helpers';
import axios from 'axios';
import { AdminBaseUrl, adminParamsGenerator, adminToastRunner } from '../admins/adminUtils';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { toast } from 'react-toastify';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import AdminTextBoxReuse from '../admins/adminForms/adminFormComponents/AdminTextBoxReuse';

const JsonEditor = ({ btnTitle, setClose, singleJsonData, setPath, path, pendingSingle, url, refreshNow }: any) => {
  const editorRef = useRef(null)
  // let jsonData = typeof (singleJsonData) == "string" ? JSON.parse(singleJsonData) : singleJsonData
  const [jsonData, setJsonData] = useState<any>(typeof (singleJsonData) == "string" ? JSON.parse(singleJsonData) : singleJsonData)
  const [modifiedJsonData, setModifiedJsonData] = useState<any>({})
  const [pending, setPending] = useState(false)

  useEffect(() => {
    singleJsonData && setJsonData(typeof (singleJsonData) == "string" ? JSON.parse(singleJsonData) : singleJsonData)
  }, [singleJsonData])

  useEffect(() => {
    if (editorRef.current) {
      const options: JSONEditorOptions = {
        mode: 'code',
        modes: ['code', 'form', 'text', 'tree', 'view', 'preview'],
        indentation: 2,
        history: true,
        search: true,
        mainMenuBar: true,
        statusBar: true,
        navigationBar: true,
        onError: (error) => console.error('JSON Editor Error:', error),
        onChange: () => {
          const updatedJson = editor.get();
          setModifiedJsonData(JSON.stringify(updatedJson, null, 2));
        },
      };
      const editor = new JSONEditor(editorRef.current, options);
      editor.set(jsonData);
      return () => {
        editor.destroy();
      };
    }
  }, [jsonData]);

  const onChangeHandler = (event: any, name: any) => {
    setPath(event?.value?.toLowerCase())
  }

  const submitHandler = async () => {
    if (path) {
      let newPath = url + `/${path}`
      let parsedData = JSON.parse(modifiedJsonData)
      setPending(true)
      const response = await postRequest(URL_EndPoints(adminParamsGenerator("addUpdateJsonData", newPath))?.addUpdateJsonData, parsedData, setPending)
      response?.data?.status == 200 && setClose && setClose()
      response?.data?.status == 200 && refreshNow()
      adminToastRunner(response)
    }
    else {
      toast.error("Path is missing", { position: "top-center" })
    }

  }

  return (
    <KTCard className="mb-4">
      <h1>JSON Editor</h1>
      {
        pendingSingle ? <IsLoading /> : <>
          <AdminTextBoxReuse
            onChangeHandler={onChangeHandler}
            HeaderTitle="Title..."
            payload={{ path: path }}
            name="path"
            label=""
          />
          <div className='mb-4 mt-4' style={{ width: "100%", height: "400px" }} key="editorjson124232">
            <div ref={editorRef} style={{ height: '100%', width: '100%' }} />
          </div>

          <div className='d-flex justify-content-end align-items-center'>
            <button className='btn btn-sm btn-primary' onClick={submitHandler}>{pending ? "Processing..." : btnTitle}</button>
          </div>
        </>
      }
    </KTCard>
  );
};

export default memo(JsonEditor);



