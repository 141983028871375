import { faBackward, faComment, faForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
let conditionalCss = 'text-danger border border-danger rounded p-1 px-2'
let conditionalCssIcon = 'text-danger me-2 ms-2'

const FetchExotelCampaign = ({ loadings, nextPrevHandler, isNextOrPrev, offset, limit, total, payload }: any) => {
   
    let isMuted = payload?.create_list ? "muted" : "dark"
    let normalCss = `text-${isMuted} border rounded p-1 px-2`
    let normalCssIcon = `text-${isMuted} me-2 ms-2`
    let Next = `Next +${(limit)}`
    let Prev = `Previous -${(limit)}`
    let style = { cursor: payload?.create_list ? "not-allowed" : "pointer" }
    let startOffset = (offset - limit)

    return (
        <div className='d-flex justify-content-end mb-1 bg-white mt-2' key={"exotelCampaign"} >
            <div className="badge badge-round border border-white  cursor-pointer" >
                <div className='me-3' style={style}>
                    <span className={`text-${"muted"} p-1 px-2 fw-bold fs-7`}>
                        {startOffset < 1 ? 0 : startOffset} - {offset || limit} of {total}
                    </span>
                </div>
                <div className='me-3 ' onClick={() => nextPrevHandler(-limit)} style={style}>
                    <span className={loadings && isNextOrPrev == "Prev" ? conditionalCss : normalCss}>
                        <FontAwesomeIcon icon={faBackward} className={loadings && isNextOrPrev == "Prev" ? conditionalCssIcon : normalCssIcon} />
                        {
                            loadings && isNextOrPrev == "Prev" ? "Loading..." : Prev
                        }
                    </span>
                </div>
                <div onClick={() => nextPrevHandler(+limit)} style={style}>
                    <span className={loadings && isNextOrPrev == "Next" ? conditionalCss : normalCss}>
                        {
                            loadings && isNextOrPrev == "Next" ? "Loading..." : Next
                        }
                        <FontAwesomeIcon icon={faForward} className={loadings && isNextOrPrev == "Next" ? conditionalCssIcon : normalCssIcon} />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default FetchExotelCampaign
