import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
const ChampActionLayout = ({ toolTipAnchorId, toolTipTitle, title, setShow, }: any) => {
    return (
        <span className='badge badge-light-primary cursor-pointer mb-2 me-2 text-dark' style={{ height: "20px" }} onClick={() => setShow(true)} id={toolTipAnchorId}>
            {title ? title : "Not Set"}
            <ReactTooltip
                anchorId={toolTipAnchorId}
                className="bg-primary"
                content={toolTipTitle}
            />
        </span>
    )
}

export default ChampActionLayout
