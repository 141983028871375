
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getRequest, postRequest } from '../../../Server/Requests';
import { URL_EndPoints } from '../../../Server/URL_EndPoints';
import SingleSearchCategory from '../../../Select/SingleSearchCategory';

const CreditCoinsForm = (props: any) => {

    const PopUpPostFormOpen = props?.PopUpPostFormOpen
    const handleClose = props?.handleClose
    const [loader, setLoader] = useState(false)
    const [disable, setdisable] = useState<any>(null)
    const [reason_data, setreason_data] = useState<any>([])

    const [payloads, setPayloads] = useState<any>({
        coins: 0,
        reasonid: 0,
        subscriptionid: props?.subscriptionId,
    })

    React.useEffect(() => {
        setPayloads({ ...payloads, ['subscriptionid']: props?.subscriptionId })
    }, [props?.subscriptionId])


    React.useEffect(() => {
        async function Reasons() {
            const response = await getRequest(URL_EndPoints(null)?.getReasons, null)
            setreason_data(response?.data?.data)

        }
        Reasons()
    }, [])


    const OnchangeHandler = (event: any, name: any) => {
        setPayloads({ ...payloads, [name]: event.value })
    }

    // getReasons
    const CreditCoinsHandler = async (e: any) => {
        e.preventDefault()
        payloads.coins = +payloads.coins

        if (payloads.reasonid == 0) {
            toast.error('Please select a valid reason!')
            // handleClose()
            return false
        }
        setLoader(true)
        const result = await postRequest(URL_EndPoints(null)?.creditWalletCoins, payloads, setLoader)
        if (result?.data?.status == 200) {
            toast.success(result.data?.msg)
            setdisable(200)

            handleClose()
        } else {
            toast.error(result?.data.msg)
            handleClose()
        }
    }








    return (
        <Modal size="lg" show={PopUpPostFormOpen} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Credit Coins</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={CreditCoinsHandler}>

                    <Form.Group className="mb-3  col-12" >
                        <SingleSearchCategory
                            handleChangeInputData={OnchangeHandler}
                            HeaderTitle='Select Reason'
                            SelectData={reason_data}
                            DynamicKey={'reason'}
                            id={'id'}
                            name='reasonid'
                        ></SingleSearchCategory>

                    </Form.Group>

                    <Form.Group className="mb-3" >
                        {/* controlId="category_name" */}
                        {/* <Form.Label>Enter Coins </Form.Label> */}
                        <Form.Control type="number" min={1} placeholder="Enter  Coins" name="coins" value={payloads?.coins} onChange={(event) => OnchangeHandler(event?.target, "coins")} required />
                    </Form.Group>

                    <Button type='submit' variant="primary" disabled={disable || loader ? true : false} >
                        {
                            loader ?
                                <div className='d-flex'>
                                    <div className='spinner-border mr-15' role='status'></div>
                                    <h6 className='fw-bold text-white fs-6 ms-2 mt-2'>Creating....</h6>
                                </div>
                                : <h6 className='fw-bold text-white fs-6 ms-2 mt-2 '>Submit </h6>
                        }
                    </Button>
                </Form>

            </Modal.Body>
        </Modal>
    )
}
export default CreditCoinsForm
