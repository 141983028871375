import { memo } from "react";
import GChatBTN from "../ChatButtons/GChatBTN"
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from "react-redux";
import { catchCurrentOpenedUrl } from "../../components/Utility";
const ChatOpenExternalResource = ({ SelectedString, classes, items, Toggleid, externalCss, ExternalTitle, ticketDataRedux }: any) => {

    const trackData = useSelector((store: any) => store.ChatReducers.trackData?.[catchCurrentOpenedUrl()])
    let TicketDetails = { ...items }
    TicketDetails.source_status = ticketDataRedux?.source_status
    TicketDetails.reasonid = ticketDataRedux?.reasonid
    TicketDetails.source_due_date = "chatdashboard_due_date"
    TicketDetails.subscriptionid = ticketDataRedux?.subscriptionid
    TicketDetails.from = "chatdashboard_due_date"
    TicketDetails.isOpen = "dashboard"
    TicketDetails.internalChatHistory = true
    TicketDetails.defaultDueDate = items?.ticketdate ? items?.ticketdate : 'Not Set'

    return (
        <>
            {
                <div className={`badge badge-light-${classes} fw-bolder cursor-pointer me-lg-1`}  >
                    {
                        <GChatBTN
                            ticketDataParent={TicketDetails}
                            Title={SelectedString}
                            Toggleid={Toggleid}
                            icons={"commenting"}
                            externalCss={externalCss}
                            ExternalTitle={ExternalTitle}
                            from="ChatOpenExternalResource"
                            trackData={trackData}
                            keyName={ticketDataRedux.keyName}
                        ></GChatBTN>
                    }
                </div>
            }
        </>
    )
}


export default memo(ChatOpenExternalResource)



// ticketDataParent={{
//     id: ticketidNo,
//     customerid: customerid,
//     random: Math.random(),
//     source_status: "autoInactive",
//     source_due_date: "dueDateAutoInactive",
//     defaultDueDate: row?.tickets?.length > 0 ? (row?.tickets[0].tickethistory?.length > 0 ? row?.tickets[0].tickethistory[0]?.due_date : 'Not Set') : 'Not Set',
//     subscriptionid: subscriptionid,
//     reasonid: row?.renewal_reason_id,
//     BTNColor: row?.customerid,
//     referenceTicket: null
// }}
// Title={row?.name}
// Toggleid={"kt_drawer_chat2_toggle2"}
// classes={"text-hover-primary"}
// icons={""}
// // icons={"commenting"}
// refs="renewal"






// {
//     "id": 39663,
//     "customerid": 19057,
//     "random": 0.2983272622801647,
//     "source_status": "pauseUnpaid",
//     "source_due_date": "dueDatePuasePaid",
//     "defaultDueDate": "2023-04-17T00:00:00.000Z",
//     "subscriptionid": 41593,
//     "reasonid": 5,
//     "BTNColor": 19057,
//     "referenceTicket": null
// }





// random: Math.random(),
//                     defaultDueDate: row?.tickets?.length > 0 ? (row?.tickets[0].tickethistory?.length > 0 ? row?.tickets[0].tickethistory[0]?.due_date : 'Not Set') : 'Not Set',