import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import IsLoading from '../../../consts/IsLoading/IsLoading';

interface Product {
  purchaseOrderInventoryItems: {
    title: string;
  };
  total_price: number;
  quantity_ordered: number;
}

interface ProductModalProps {
  title: string;
  show: boolean;
  listData: Product[];
  setShow: any;
  modalLoader: boolean,
  columns: any,
}

const ViewGoodReceiptItems: React.FC<ProductModalProps> = ({ title, show, listData, setShow, modalLoader, columns }) => {

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {modalLoader ? <IsLoading /> : listData && listData.length > 0 ? (
          <DataTable
            columns={columns}
            data={listData}
            pagination
            highlightOnHover
            striped
          />
        ) : (
          <p>No items available</p>
        )}

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>

    </Modal>
  );
};

export default ViewGoodReceiptItems;
