import axios from 'axios';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { withSwal as SweetAlertChat } from 'react-sweetalert2';
import { MAIN_ADMIN_BASE_API_URL, TEST_ADMIN_BASE_API_URL } from '../../../apiGlobally';

export default SweetAlertChat((props: any) => {
    const { swal, confirm, cancel, payloads, title }: any = props;
    const { payloadData, render, successTitle, FailedTitle } = payloads
    const localKey = JSON.parse(localStorage.getItem("API") || "0")
    const dispatch: any = useDispatch();

    React.useEffect(() => {
        if (render) {
            swal.fire({
                title: title,
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${localKey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/revertUpdateAttendenceStatus`, payloadData).then((res) => {
                        swal.fire(successTitle)
                        dispatch({ type: "REFRESH_TABLE", payload: Math.random() })
                    }).catch((error) => {
                        swal.fire('!Failed request')
                    })
                } else if (result.isDenied) {
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});
