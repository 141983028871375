import React from 'react'
import { LabelModal } from '../../../consts/widgets/forms/FormsModals'
import AutoCompleteAddressGoogleUtils from '../../../consts/googleMapUtility/AutoCompleteAddressGoogleUtils'

const SearchAreaOnMap = ({ address, setMarkerPositionLatLng }: any) => {
    return (
        <>


            {/* <LabelModal show={false} classes="" title="Address" /> */}
            <AutoCompleteAddressGoogleUtils address={address} setMarkerPositionLatLng={setMarkerPositionLatLng} isTitle={true}/>


        </>
    )
}

export default SearchAreaOnMap