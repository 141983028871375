import React, { useState } from 'react';
import ListRowToolTip from '../admins/adminComponents/ListRowToolTip';
import axios from 'axios';
import { adminToastRunner } from '../admins/adminUtils';
import LoaderSpinner from '../admins/LoaderSpinner';
let colors: any = {
    Pending: "#6c757d",
    Failed: "#dc3545",
    Scheduled: "#17a2b8",
    Executed: "#28a745",
}

const CircleComponent = ({ row, ele }: any) => {
    const [pending, setPending] = useState(false)
    const circleStyle: any = {
        width: '70px',
        height: '30px',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors[ele?.message],
        color: 'white',
        lineHeight: "1",
        cursor: "pointer",
        margin: "2px"
    };

    const contentStyle: any = {
        textAlign: "center",
        width: '100%',
        height: '50%',
        backgroundColor: "white",
        color: 'black',
    };

    const runCron = async () => {
        setPending(true)
        const result: any = (await axios['get']?.(
            `${row?.api_url}`
        )) as any
        setPending(false)

        adminToastRunner(result)
    }

    let toolTitle = `${ele?.message} ( ${ele?.time} )`
    return (
        <div style={circleStyle} onClick={() => ele?.message == "Failed" ? runCron() : {}} key={row?.id}>
            {
                pending ? <LoaderSpinner /> :
                    <>
                        <ListRowToolTip anchorId={row?.id} title={toolTitle} subTitle={ele?.message} classes="fw-semibold fs-9" />
                        <div style={contentStyle}>
                            <span className='fs-9 fw-semibold text-dark'>{ele?.time}</span>
                        </div>
                    </>
            }
        </div>
    );
};

export default CircleComponent;
