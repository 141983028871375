import React, { memo } from 'react';
import { withSwal as DeleteSubArea } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { toast } from 'react-toastify';
export default DeleteSubArea((props: any) => {

    const { swal, successTitle, FailedTitle, render, payload, setCurrentSelectedAction, setUpdateSubAreaModal, setCurrentSelectedActionToggle, setCurrentSelectedActionData, setUpdatePayload }: any = props;



    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.deleteSubArea, payload, null)
            if (response?.data?.status == 200) {
                toast.success(response?.data?.msg, { position: "top-center" })
                setCurrentSelectedActionData(null)
                setCurrentSelectedAction(null)
                // swal.fire(successTitle)
                setCurrentSelectedActionToggle(false)
                setUpdateSubAreaModal(false)
                setUpdatePayload({
                    "id": null,
                    "sub_area_status": null,
                    "name": null,
                })
            }
            else {
                toast.success(response?.data?.msg, { position: "top-center" })
                setCurrentSelectedActionData(null)
                setCurrentSelectedAction(null)
                setCurrentSelectedActionToggle(false)
                setUpdateSubAreaModal(false)
            }
        }

        if (render) {
            swal.fire({
                title: "Do you want to delete / updated sub area ?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Update",
                denyButtonText: `Delete`
            }).then((result: any) => {
                if (result.isConfirmed) {
                    setCurrentSelectedAction(null)
                    setCurrentSelectedActionToggle(false)
                    setUpdateSubAreaModal(true)

                }
                else if (result.isDenied) {
                    setUpdateSubAreaModal(false)
                    load()
                }

                else if (result.isDismissed) {
                    setCurrentSelectedAction(null)
                    setCurrentSelectedActionToggle(false)
                    setUpdateSubAreaModal(false)
                }
            })
        }
    }, [render])
});
