export const LocalBaseURL = () => {
    if (window.location.host == "testadmin.carselonadaily.com") {
        localStorage.setItem("API", JSON.stringify(false))
        localStorage.setItem("ischecked", JSON.stringify(false))
    }
    else {
        const localKey = JSON.parse(localStorage.getItem("ischecked") || "0")
        if (localKey) {
            localStorage.setItem("API", JSON.stringify(true))
        } else {

            if (window.location.origin.includes("3011")) {
                localStorage.setItem("API", JSON.stringify(false))
            }
            else {
                localStorage.setItem("API", JSON.stringify(true))
            }
        }
    }
}









