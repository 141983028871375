import React from 'react';
import { withSwal as IsPaidRatingYesNo } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { toast } from 'react-toastify';
export default IsPaidRatingYesNo((props: any) => {
    const { swal, confirm, cancel, payload, title, setIsPaidStatusModal, render, setStoreRow = null, row = {}, setLoader }: any = props;
    React.useEffect(() => {
        async function load() {
            setLoader && setLoader(true)
            const response = await postRequest(URL_EndPoints(null)?.updateJobRatingsColumns, payload, setLoader)
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
            if (response?.data?.status == 200) {
                if (setStoreRow) {
                    row["ispaid"] = 1
                    setStoreRow(row)
                }
            }
            setIsPaidStatusModal && setIsPaidStatusModal(false)

        }



        if (render) {
            swal.fire({
                title: title,
                showDenyButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    swal.fire("Request failed ")
                }
            })
        }
    }, [render])
});
