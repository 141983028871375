import React, { useState } from 'react'
import clsx from 'clsx';
import "../styles/trainingList.css"
import moment from 'moment';
import { useSelector } from 'react-redux';
import { JOB_STATUSES_FORMATTED, JobStatusLists, JobStatusListsColors } from '../constants/JobStatusLists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { image_Cloudfront } from '../../components/Utility';
import Not_Found_Data from '../../Not_Found_Data';
const TrainingLists = ({ cleanerid }: any) => {
  const trainingTaskLists = useSelector((store: any) => store.champsReducer.trainingTaskLists[cleanerid])

  const [showGallary, setShowGallary] = useState(false)
  const [images, setImages] = useState([
    // { image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5vl7JTcT_gToPMsWNDhBdjq6TsA6o9scv-h0oTgpsfw&s" },
  ])

  const imageHandler = (images: any) => {
    setImages(images || [])
    setShowGallary(true)
  }

  const [scale, setScale] = useState(1);

  const handleWheel = (event: any) => {
    event.preventDefault();
    const delta = Math.sign(event.deltaY);
    setScale((prevScale) => Math.min(Math.max(0.5, prevScale + delta * 0.1), 3));
  };

  return (
    <>
      <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
        <div
          className={clsx('scroll-y me-n5 pe-5  max mb-4')}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
        >
          <div className='px-3 mt-4 margins table-responsive'>
            <table className="table table-bordered table-hover table-sm">
              <thead>
                <tr className='border px-3 bg-secondary'>
                  <th scope="col p-2">TRAINER</th>
                  <th scope="col">TASK TYPE</th>
                  <th scope="col">TASK DATE</th>
                  <th scope="col">TASK STATUS</th>
                  <th scope="col">CREATED AT</th>
                  <th scope="col">GALLERY</th>
                </tr>
              </thead>
              <tbody>
                {trainingTaskLists && trainingTaskLists?.map((element: any) => {
                  return (
                    <tr className={"border"} >
                      <td className='p-2'> {element?.attendencecleaner?.first_name ? element?.attendencecleaner?.first_name + element?.attendencecleaner?.last_name : "NA"} </td>
                     
                      <td scope="row">{element?.taskType?.name ? element?.taskType?.name : "NA"}</td>
                      
                      <td >{element?.attendencedate ? element?.attendencedate : "NA"}</td>
                      
                      <td className={`text-${JobStatusListsColors[+element?.job_status]} rounded`}> {element?.job_status ? JOB_STATUSES_FORMATTED[+element?.job_status] : "NA"} </td>
                     
                      <td style={{ "width": "100px" }}>{element?.createdAt ? moment(element?.createdAt).format("YYYY-MM-DD") : "NA"}</td>
                     
                      <td className='text-center'>
                        <FontAwesomeIcon icon={faImage} className='text-success fs-2 fw-bold cursor-pointer' onClick={() => imageHandler(element?.allAttendenceImages)} />
                      </td>
                    </tr>
                  )
                }
                )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={showGallary}
        size='lg'
        onHide={() => setShowGallary(false)}
        dialogClassName="small-modal"
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Training Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row image_gallary " >
            {
              images?.length > 0 ? images?.map((ele: any, index: any) => {
                return <div className="image_gallery col-lg-4 col-md-12 mb-4 mb-lg-0" key={ele?.id + index}>
                  <div className="image-container">
                    {ele?.image && <img
                      src={image_Cloudfront + ele?.image}
                      className="w-100 shadow-1-strong rounded mb-4 img-fluid" alt="No Document"
                      // onWheel={handleWheel}
                     style={{ width: "100%", objectFit: 'cover' }}
                    />}
                  </div>
                </div>
                // <div style={{ overflow: 'hidden', transformOrigin: 'top  bottom ', transform: `scale(${scale})` }} className='d-flex justify-content-center align-items-center'>
                //   {
                //     ele?.image && <img
                //       className=''
                //       src={image_Cloudfront + "5c63b048-6fdd-44ac-bdc6-bd2fe6d47093.jpg"}
                //       alt="No Image/Documents"
                //       style={{ width: "100%", objectFit: 'cover' }}
                //       onWheel={handleWheel}
                //     />
                //   }
                // </div>
              })
                : <Not_Found_Data headerTitle={'Oops! No images added yet...'} />
            }
          </div>
        </Modal.Body>
      </Modal >
    </>
  )
}
export default TrainingLists




