import React, { useEffect, useState } from 'react'
import { MenuItem } from './MenuItem'
import { useIntl } from 'react-intl'
import InformationChip from '../../../../app/consts/Chats/Components/InformationChip'
import { getRequest, postRequest } from '../../../../app/consts/Server/Requests'
import { URL_EndPoints } from '../../../../app/consts/Server/URL_EndPoints'
import { useDispatch } from 'react-redux'
import { getSmartFilterAction } from '../../../../Redux/Reducer/RefreshPage/refreshPageActions'
import { catchCurrentOpenedUrl } from '../../../../app/consts/components/Utility'


export function MenuInner() {
  const currentUrlRoute = catchCurrentOpenedUrl()
  const [champAppVersion, setchampAppVersion] = useState<any>("0")
  const [customerAppVersion, setCustomerAppVersion] = useState<any>("0")
  const dispatch = useDispatch()
  const intl = useIntl()

  const getAppVersion = async () => {
    const response = await getRequest(URL_EndPoints()?.getAppVersion, null)
    if (response?.status == 200) {
      setchampAppVersion(response?.data.data?.champAppVersion)
      setCustomerAppVersion(response?.data.data?.customerAppVersion)
    }
  }


  useEffect(() => {
    getAppVersion()
  }, [])

  // const getSmartFilter = async (list_api_url:any) => {
  //   dispatch(getSmartFilterAction({ list_api_url: list_api_url }) as any)
  // }

  // useEffect(() => {
  //   currentUrlRoute != "/dashboard" && getSmartFilter(currentUrlRoute)
  // }, [currentUrlRoute])

  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />

      <div className="menu-item me-lg-1 d-flex flex-column mt-2 g-3">
        <InformationChip SelectedString={customerAppVersion} classes={"danger"} Id={"customer"} toolTipName="Customer App Version" ></InformationChip>
        <InformationChip SelectedString={champAppVersion} classes={"dark"} Id={"champ"} toolTipName="Champ App Version" ></InformationChip>
      </div>

      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
