import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component'
import DropDownFilterMenu from '../../../consts/BootrapDropdown/DropDownFilterMenu';
import { AllFielSearch } from '../../../consts/FieldSearch/AllFieldSearch';
import { AttendanceRegisterfiltration } from '../../../consts/FilterMenuItem/AttendanceRegisterfiltration';
import { inputEventChanger, cleanerAttendaceInterfaces, } from '../../../consts/Inerfaces4az/CleanerAttendance';
import MomentDate from '../../../consts/MomentDate';
import { getRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import Dialog from '../../../consts/SweetAlert/Dialog';
import AddNewFormAttendance from './AddNewFormAttendance/AddNewFormAttendance';
import { columns } from './Columns';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../../consts/components/Utility';
import { getFilterDataBySearchClientSide } from '../../../consts/DynamicFilteration/components/CommonData';
import { adminQueryGenerator } from '../../admins/adminUtils';
import { KTCardBody } from '../../../../_metronic/helpers';

const CleanerAttendanceLists = ({ filterDataOutSource = { isFilter: false, payload: {} } }: any) => {
  const [pending, setPending] = React.useState(true);
  const [PopUpPostFormOpen, setPopUpPostFormOpen] = useState(false)
  const [filterData, setfilterData] = useState<cleanerAttendaceInterfaces[]>([])
  const [CleanerAttendanceData, setCleanerAttendanceData] = React.useState<cleanerAttendaceInterfaces[]>([])
  const [sortedColumns, setSortedColumns] = useState<any>(columns(null, null));
  const [search, setSearch] = useState<any>("");

  useEffect(() => {
    if (filterData) {
      const newColumns = columns(filterData?.map((ele: any) => ele?.cleaner?.id));
      setSortedColumns(newColumns);
    }
  }, [filterData]);

  const [payload, setPayload] = React.useState<any>({
    from: MomentDate(0),
    to: MomentDate(0),
    cleanerid: 0,
  })

  const onChangeHandler = (event: inputEventChanger, name: string) => {
    setPayload({ ...payload, [name]: event ? event?.value : null })
  }

  async function getCleanerAttendance(payload: any) {


    setPending(true)
    const { data } = await getRequest(URL_EndPoints(adminQueryGenerator("getCleanerAttendance", payload))?.getCleanerAttendance, setPending)
    setCleanerAttendanceData(data.data)
    setfilterData(data.data)
  }

  useEffect(() => {

    if (filterDataOutSource?.isFilter) {
      payload.cleanerid = filterDataOutSource?.payload?.cleanerid ?? null
      payload.from = ""
      payload.to = ""
      setPayload(payload)
    }

    getCleanerAttendance(payload)
  }, [
    filterDataOutSource?.isFilter,
    filterDataOutSource?.payload?.cleanerid,
  ])

  React.useEffect(() => {
    const updatedData = AllFielSearch(CleanerAttendanceData, search)
    setfilterData(updatedData)
  }, [search])

  const handlecleanerAttendanceform = () => {
    setPopUpPostFormOpen(!PopUpPostFormOpen)
  }

  const searchHandler = async () => {
    getCleanerAttendance(payload)
  }

  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")
  const [storeFilterListItem, setStoreFilterListItem] = useState<any>({
    cleanerListData: []
  })

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      await getFilterDataBySearchClientSide(dynFilter, setStoreFilterListItem, storeFilterListItem, setIsLoadingFilter, { keyword: storeSearchKeywords })
      setIsLoadingFilter(false)
    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  return (
    <KTCardBody className='card py-4'>
      <div className='cleaner-attendance-table'>
        {
          <DataTable
            title="Cleaner Attendance"
            columns={reorderColumns(sortedColumns, "cleanerAttendanceReorderedItem")
            }
            data={filterData}
            onColumnOrderChange={cols => storeReorderIndexes(cols, "cleanerAttendanceReorderedItem")}
            paginationPerPage={20}
            progressPending={pending}
            pagination
            fixedHeader
            fixedHeaderScrollHeight='auto'
            selectableRowsHighlight
            highlightOnHover
            subHeader
            paginationComponentOptions={paginationRowsPerPageOptionsAll}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            subHeaderComponent={
              <>
                <div className='w-100 d-flex justify-content-between mb-3 '>
                  <div className='d-flex align-items-start justify-content-start '>
                    <input
                      type='text'
                      data-kt-user-table-filter='search'
                      className='w-100 form-control me-2 align-start'
                      style={{ height: "40px", }}
                      placeholder='Search'
                      value={search}
                      onChange={(event: any) => setSearch(event.target.value)}
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-end '>
                    <div className='d-flex align-items-center justify-content-center my-2'>
                      <DropDownFilterMenu
                        ParentData={{
                          handleActions: searchHandler,
                          payloads: payload,
                          onChangeHandler: onChangeHandler,
                          setSearchFilter: setSearchFilter,
                          isLoadingFilter: isLoadingFilter,
                          storeFilterListItem: storeFilterListItem,

                        }}
                        Component={AttendanceRegisterfiltration}
                      ></DropDownFilterMenu>
                    </div>

                  </div>

                </div>
              </>
            }
          />
        }
        {
          PopUpPostFormOpen &&
          <Dialog
            open={true}
            onClose={handlecleanerAttendanceform}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <AddNewFormAttendance FilteredData={null} handleCloseForm={handlecleanerAttendanceform} />
          </Dialog>
        }
      </div>
    </KTCardBody>
  )
}
export default CleanerAttendanceLists