import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { KTCardBody } from '../../../../../_metronic/helpers';
import { postRequest } from '../../../Server/Requests';
import { URL_EndPoints } from '../../../Server/URL_EndPoints';
import { AllFielSearch } from '../../../FieldSearch/AllFieldSearch';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../../components/Utility';

const ChampWeeklyReportList = ({ champsDetails }: any) => {
  const [Search, setSearch] = useState<any>("")
  const [pending, setPending] = useState<boolean>(false)
  const [filterData, setfilterData] = useState<any>([])
  const [data, setData] = useState<any>([])

  React.useEffect(() => {
    const updatedData = AllFielSearch(data, Search)
    setfilterData(updatedData)
  }, [Search])
  async function getChamWeeklyReportList() {
    setPending(true)
    const result = await postRequest(URL_EndPoints(null)?.getSingleCleanerWeeklyReports, { champId: champsDetails?.id }, setPending)
    setfilterData(result?.data?.data)
    setData(result?.data?.data)
  }

  useEffect(() => {
    // 635
    champsDetails?.id && getChamWeeklyReportList()
  }, [champsDetails?.id])

  return (
    <KTCardBody className='p-4'>
      <DataTable
        columns={reorderColumns(columns, "champTabWeeklyReportReorderedItem")}
        data={filterData}
        onColumnOrderChange={cols => storeReorderIndexes(cols, "champTabWeeklyReportReorderedItem")}
        pagination
        fixedHeader
        progressPending={pending}
        fixedHeaderScrollHeight='auto'
        selectableRowsHighlight
        highlightOnHover
        paginationComponentOptions={paginationRowsPerPageOptionsAll}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        subHeader
        subHeaderComponent={
          <>
            <div className='w-100 d-flex justify-content-between mb-3 '>
              <div className='d-flex align-items-start justify-content-start '>
                <input
                  type="text"
                  placeholder='Search Here'
                  value={Search}
                  onChange={(e: any) => setSearch(e.target.value)}
                  className='w-100 form-control me-2 align-start'
                />
              </div>
              <div className='d-flex align-items-center justify-content-end '>
                {/* <button className='btn btn-sm btn-primary' onClick={() => setOpenAddrequirementModal(!openAddrequirementModal)}>Add Requirement Type</button> */}
              </div>
            </div>
          </>
        }
      />
    </KTCardBody>
  )
}
export default ChampWeeklyReportList

