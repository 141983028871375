import React, { FC, useEffect } from 'react'
import VehicleTypeListMain from './RenewalTaskListPagination'
import { KTCardBody } from '../../../_metronic/helpers'
import { useDispatch } from 'react-redux'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { setStoreLastStatusListData } from '../../../Redux/actions/cxAction'
import { catchCurrentOpenedUrl } from '../../consts/components/Utility'
const RenewalTaskList: any = ({ filterDataOutsource }: any) => {
    const dispatch = useDispatch();
    const getData = async () => {
        const res1 = postRequest(URL_EndPoints(null)?.getSubscriptionRenewalReason,
            {
                "category_id": 4
            }
            , null);
        const res2 = postRequest(URL_EndPoints(null)?.getMasterReasonsForSubscription, {}, null);
        const adminList = getRequest(URL_EndPoints(null)?.getAdminList, null)
        const supervisorList = getRequest(URL_EndPoints(null)?.getSupervisorList, null)

        let vehicleReasonParam = {
            To: URL_EndPoints(null)?.getMasterOperationReasons,
            What: `?category_id=8`, // 8 for vehicle campaign statuses
        }
        const vehicleReasons = getRequest(URL_EndPoints(vehicleReasonParam)?.getMasterOperationReasons, null)

        const result: any[] = await Promise.allSettled([
            res1,
            res2,
            adminList,
            supervisorList,
            vehicleReasons,
        ]);

        const updatedData = result[1]?.value?.data?.data?.map((ele: any, i: number) => {
            if (ele["reason"] != null) {
                const newData = {
                    name: ele["reason"],
                    id: ele["id"],
                }
                return newData
            }
        })
        dispatch(setStoreLastStatusListData({
            keyName: catchCurrentOpenedUrl(),
            data: result[0]?.value?.data?.data 
        }) as any)
        dispatch({ type: "MASTER_REASON", payload: updatedData })
        dispatch({ type: "PAUSE_PAID", payload: result[0]?.value?.data?.data })
        dispatch({ type: "RENEWAL_REASON", payload: result[0]?.value?.data?.data })
        dispatch({ type: "ADD_ADMIN_LIST", payload: result[2]?.value?.data?.data })
        dispatch({ type: "ADD_SUPERVISOR_LIST", payload: result[3]?.value?.data?.data });
        dispatch({ type: "ADD_VEHICLE_REASONS", payload: result[4]?.value?.data?.data });
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <KTCardBody className='card py-4' >
            <VehicleTypeListMain filterDataOutsource={filterDataOutsource} />
        </KTCardBody>
    )
}
export default RenewalTaskList
