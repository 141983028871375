import React, { useEffect, useState } from 'react'
import { CustomerJobGeneratedColumns } from './CustomerJobGeneratedColumns'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import { getRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { AdminClientSidePaginationLists } from '../admins/adminClientSidePaginationLists/AdminClientSidePaginationLists'
import Refresh from '../../consts/Refresh'
import { BulkUtilButton } from '../../consts/reuseModules/BulkUtilButton'
import { JobFilter } from './JobFilter'

const CustomerJobGeneratedList = () => {
    const [sortedColumns, setSortedColumns] = useState<any>(CustomerJobGeneratedColumns())
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [filterData, setFilterData] = useState<any>([])
    // const [showCreateMasterNotes, setShowCreateMasterNotes] = useState<any>(false)

    useEffect(() => {
        const filterSearchData = AllFielSearch(data, search)
        setFilterData(filterSearchData)
    }, [search])

    const getMasterNotesForListing = async () => {
        setPending(true)
        const response = await getRequest(URL_EndPoints()?.getNoJobsSubscriptions, setPending)
        setFilterData(response?.data?.data)
        setData(response?.data?.data)
    }

    useEffect(() => {
        getMasterNotesForListing()
    }, [])

    return (
        <>
            <AdminClientSidePaginationLists
                title="No Job Subscription List"
                reorderColumnTitle="customerJobNotGeneratedListReorderColumnItem"
                sortedColumns={sortedColumns}
                filterData={filterData}
                pending={pending}
                clearSelectedRows={false}
                totalRows={filterData?.length}
                conditionalRowStyles={{}}
                onChangePage={() => { }}
                onChangeRowsPerPage={() => { }}
                onSelectedRowsChange={() => { }}
                search={search}
                setSearch={setSearch}
                showFilter={false}
                pagination={true}
                ExpandedComponent={()=><></>}
                clientSideFilterComponent={JobFilter}
                keyName={"customerJobNotGeneratedList"}
                clientSideFilterData={{
                    // handleChangeInputData: onChangeHandler,
                    // handleActions: searchHandler,
                    // payloads: payload,
                    // supervisorListData: supervisorListData,
                }}
                SubHeaderComponentRightSection={() => (<>
                        <Refresh refreshNow={getMasterNotesForListing} />
                        {/* <BulkUtilButton title="Create Master Notes" filteredSelectedRows="static" setShow={setShowCreateMasterNotes} /> */}
                </>)}
                SubHeaderComponentActionButtons={() => (<></>)}
            />
           

        </>
    )
}

export default CustomerJobGeneratedList
