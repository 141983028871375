import React, { useEffect, useRef, useState } from 'react'
import { KTCardBody } from '../../../_metronic/helpers'
import { AdminServerSidePaginationLists } from '../admins/adminServerSidePaginationLists/AdminServerSidePaginationLists'
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { postRequest } from '../../consts/Server/Requests'
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton'
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler'
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents'
import Refresh from '../../consts/Refresh'
import { useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils'
import { UnsuccessfulSubsColums } from './UnsuccessfulSubsColums'

const UnsuccessfulSubscriptions = () => {
    const [sortedColumns, setSortedColumns] = useState(UnsuccessfulSubsColums())
    const [pending, setPending] = React.useState(false)
    const [filterData, setfilterData] = useState<any>([])
    const [storeFilterList, setStoreFilterList] = useState<any>({})
    const [selectedFilterData, setSelectedFilterData] = useState<any>([])
    const [totalRows, setTotalRows] = useState<any>(0);
    const [perPageSize, setPerPageSize] = useState(10);
    const [sortby, setSortBy] = useState<any>("");
    const [direction, setdirections] = useState<any>("desc");
    const [page, setPages] = useState<any>(1);
    const [search, setSearch] = useState<any>("");
    const [filterkey, setFilterKey] = useState<any>({})
    const [filter, setFilter] = useState<any>([])
    const [payload, setPayload] = useState<any>(initialPayload())
    const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([]);

    useEffect(() => {
        if (filterData) {
            const newColumns = UnsuccessfulSubsColums(filterData?.map((ele: any) => ele?.loan_borrower?.id));
            setSortedColumns(newColumns);
        }
    }, [filterData]);

    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    async function InvokedRendered(payloads: any, setloading: any) {
        setToggleClearRows(!toggledClearRows)
        setFilteredSelectedRows([])
        setPending(true)
        const { data } = await postRequest(URL_EndPoints(null)?.getUnSuccessfulSubscriptions, payload, setloading)
        setfilterData(data?.data)
        setTotalRows(data?.recordsTotal)
        setFilter(data?.filter?.unSuccessfulSubscriptions)
        setPending(false)
    }

    const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
        payload.search = search
        payload.start = page
        payload.length = perPageSize
        payload.sortby = sortBy
        payload.orders = sortDirection
        payload.direction = sortDirection

        setPayload(payload)
        return payload
    }

    const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
        setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
    }

    const actionHandler = async () => {
        // setTotalRows(10)
        await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    }

    const removeFilter = (index: any, key: any) => {
        removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
    }

    const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
        getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
    }

    const handleSort = async (column: any, sortDirection: any) => {
        setSortBy(column.sortField)
        setdirections(sortDirection)
        InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
    };

    const handlePageChange = (page: any) => {
        setPages(page)
        InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    };

    const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
        InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
        setPerPageSize(newPerPageSize)

    };

    useEffect(() => {
        let timer = setTimeout(async function () {
            search && setPending(true)
            search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
        }, 1000)

        return () => {
            clearTimeout(timer)
        }
    }, [search])

    useEffect(() => {
        !search && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    }, [search])

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);
    }, [selectedFilterData?.length]);

    const handleSelectedRowsChange = (selectedRows: any) => {
        setFilteredSelectedRows(selectedRows?.selectedRows)
    }

    const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
    const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
    const [dynFilter, setDynFilter] = useState<any>("")

    const setSearchFilter = (event: any, filters: any) => {
        setDynFilter(filters)
        setStoreSearchKeywords(event)
    }

    useEffect(() => {
        let x = setTimeout(async () => {
            setIsLoadingFilter(true)
            storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
                keyword: storeSearchKeywords,
            })
            setIsLoadingFilter(false)

        }, 2000)
        return () => {
            setIsLoadingFilter(false)
            clearTimeout(x)
        }
    }, [storeSearchKeywords, dynFilter])

    const refreshNow = () => {
        setToggleClearRows(!toggledClearRows)
        setFilteredSelectedRows([])
        InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }


    let { adminGetSmartFilterList } = useAdminSmartFilterList()
    let { listApiUrl } = useAdminListApiUrl()

    useEffect(() => {
        adminGetSmartFilterList("unsuccessfullSubscriptionskey")
    }, [])

    return (
        <KTCardBody className='card py-4' key="unsuccessfullSubscriptions">
            <AdminServerSidePaginationLists
                title="Unsuccessfull Subscription List"
                reorderColumnTitle="unsuccessfullSubscriptions"
                sortedColumns={sortedColumns}
                filterData={filterData}
                pending={pending}
                clearSelectedRows={toggledClearRows}
                totalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSelectedRowsChange={handleSelectedRowsChange}
                conditionalRowStyles={{}}
                handleSort={handleSort}
                keyName={"unsuccessfullSubscriptionskey"}
                search={search}
                setSearch={setSearch}
                paginationPerPage={perPageSize}
                showFilter={true}
                ExpandedComponent={() => <></>}
                expandableRows={false}
                filterHandler={{
                    onChangeHandler: onChangeHandler,
                    actionHandler: actionHandler,
                    getCurrentFilter: getCurrentFilter,
                    removeFilter: removeFilter,
                    payload: payload,
                    Component: DynamicFilter,
                    filterData: filter,
                    OperatorsData: operators,
                    selectedFilterData,
                    filterComponents: KeywiseComponents,
                    storeFilterList: storeFilterList,
                    filterkey: filterkey,
                    setSeacrch: setSearchFilter,
                    isLoadingFilter: isLoadingFilter,
                    listApiUrl: listApiUrl("unsuccessfullSubscriptionskey")
                }}
                SubHeaderComponentRightSection={() => (<>
                    <Refresh refreshNow={refreshNow} />
                    {/* <BulkUtilButton title="Assign Loan" filteredSelectedRows="static" setShow={setOpenAssginLoanToCleaner} /> */}

                </>)}
                SubHeaderComponentActionButtons={() => (
                    <>
                        {/* {
                            filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                                <BulkUtilButton title="Bulk Transfer Amount Champs" filteredSelectedRows={filteredSelectedRows} setShow={setShowTransferAmount} />
                            </BulkUtilButtonWrapper> : <></>
                        } */}
                    </>
                )}
            />





        </KTCardBody>

    )
}

export default UnsuccessfulSubscriptions
