export const GET_ALL_FAQS_LIST = "GET_ALL_FAQS_LIST"
export const GET_ALL_CATEGORY_LIST = "GET_ALL_CATEGORY_LIST"
export const GET_ALL_FAQS_LIST_BY_CATEGORY = "GET_ALL_FAQS_LIST_BY_CATEGORY"
export const SELECTED_FAQ_CATEGORY = "SELECTED_FAQ_CATEGORY"
export const REDIRECT_BACK_TO_FAQ_FETCH_CURRENT_CATEGORY_PAYLOAD = "REDIRECT_BACK_TO_FAQ_FETCH_CURRENT_CATEGORY_PAYLOAD"
export const FAQ_TOTAL_RECORDS = "FAQ_TOTAL_RECORDS"
export const FAQ_LOADING = "FAQ_LOADING"
export const FAQ_SUCCESS = "FAQ_SUCCESS"
export const FAQ_FAILURE = "FAQ_FAILURE"
export const LOADING = "LOADING"
export const SUCCESS = "SUCCESS"
export const FAILURE = "FAILURE"
