import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import Not_Found_Data from '../../Not_Found_Data'
import DOMPurify from 'dompurify';
import "../styles/BlurImage.css"

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { openChatBySource } from '../../../../Redux/champChatDashboardReducer/champsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import ExtotelAudioUrl from '../../Chats/ChatBoxComponents/ExtotelAudioUrl';
const WhatsappChats = ({ props }: any) => {
    const { isDrawer, SeeImageFullWidth, messages, cleanerid } = props

    const champsWhatsappMessages = useSelector((store: any) => store.champsReducer.champsWhatsappMessages[cleanerid])
    const what_loader = useSelector((store: any) => store.champsReducer.what_loader)

    const refs: any = useRef(null)

    const scrollBottomAuto = () => {
        refs.current?.scrollIntoView({ behavior: 'smooth' })
    }

    React.useEffect(() => {
        scrollBottomAuto()
    }, [messages, champsWhatsappMessages])


    const MessageSorted: any = champsWhatsappMessages?.sort((a: any, b: any) => {
        let a1: any = new Date(b.created)
        let a2: any = new Date(a.created)
        return a2 - a1
    })


    const dispatch = useDispatch()
    const [whatsappImages, setWhatsImages] = useState<any>({})
    const [whatsappImageRefsid, setwhatsappImageRefsid] = useState<any>({})
    const [loader, setLoader] = useState(false)

    function getImage(endUrl: any, whatsappMessageId: any) {

        setLoader(true)
        const options = {
            method: "GET",
            headers: {
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2MWZkZWJhZS0xYTBmLTQ4ZDMtYWUyNS0xM2I1MjczZWNjZGEiLCJ1bmlxdWVfbmFtZSI6ImNhcnNlbG9uYWNhcnNAZ21haWwuY29tIiwibmFtZWlkIjoiY2Fyc2Vsb25hY2Fyc0BnbWFpbC5jb20iLCJlbWFpbCI6ImNhcnNlbG9uYWNhcnNAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDYvMDkvMjAyMiAxMjozMToyOCIsImRiX25hbWUiOiI5ODI0IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.8SQ5R0D86qJo7e-2wOOMzRf6xMH8EKsz7B7Pc-NnWJk',
            },
            responseType: "blob"
        }


        fetch(`https://live-server-9824.wati.io/api/v1/getMedia?fileName=${endUrl && endUrl}`, options)
            .then(response => response.blob())
            .then((response: any) => {
                const myBlobURL = URL.createObjectURL(response);
                setwhatsappImageRefsid({ ...whatsappImageRefsid, [whatsappMessageId]: whatsappMessageId })
                setWhatsImages({ ...whatsappImages, [whatsappMessageId]: myBlobURL })
                setLoader(false)
            })
            .catch(err => console.error(err));


    }

    const whatsAppMessageHandler = () => {
        dispatch(openChatBySource("chatMessages"))
    }
    const [fetchedUrls, setFetchedUrls] = useState<any>({});

    return (
        <>
            {
                what_loader ? <div className='d-flex align-items-center justify-content-center h-75 flex-column'>
                    <div className='spinner-border mr-15' role='status'></div>
                    <h4 className='fw-bold'>Loading...</h4>
                </div> :
                    <div
                        className='card-body chat-box-main'
                        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
                    >
                        <div
                            style={{ maxHeight: "calc(100vh - 300px)" }}
                            className={clsx('scroll-y me-n5 pe-5  max', { 'h-lg-auto': !isDrawer })}
                            data-kt-element='messages'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-dependencies={
                                isDrawer
                                    ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                                    : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                            }
                            data-kt-scroll-wrappers={
                                isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
                            }
                            data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                        >
                            {MessageSorted?.length > 0 ? MessageSorted?.map((message: any, index: number, arr: any) => {

                                let whatsappMessageIds = message.id
                                let messages = message?.finalText ? message.finalText : message?.text
                                let newMessage = message?.finalText ? message.finalText : message?.text
                                newMessage = newMessage?.replace(/\r?\n/g, '<br />')

                                const state = message?.whatsappMessageId && message.statusString == "SENT" ? "danger text-start" : message?.whatsappMessageId && message.statusString != "SENT" ? "danger text-end" : message.statusString == "SENT" ? 'info text-start' : 'primary text-end'
                                const templateAttr = {}

                                if (message.template) {
                                    Object.defineProperty(templateAttr, 'data-kt-element', {
                                        value: `template-${message.statusString}`,
                                    })
                                }
                                const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.statusString == "SENT" ? 'start' : 'end'} mb-5`
                                return (
                                    <div
                                        ref={refs}
                                        key={`message${index}`}
                                        className={clsx('d-flex', contentClass, 'mb-5', { 'd-none': message.template })}
                                        {...templateAttr}
                                    >
                                        <div
                                            className={clsx(
                                                'd-flex flex-column align-items',
                                                `align-items-${message.statusString == "SENT" ? 'start' : 'end'}`
                                            )}
                                        >
                                            <div className='d-flex align-items-center '>
                                                {message.statusString == "SENT" ? (
                                                    <>
                                                        <div className='ms-3'>

                                                            <span className='text-muted fs-7 mb-1 me-2'>
                                                                {(moment(new Date(message?.created)).fromNow())}
                                                            </span>
                                                            <a
                                                                href='#'
                                                                className=' text-gray-900 text-hover-primary me-1'
                                                            >
                                                                ( {
                                                                    message?.usertype == 1 ? "Cleaner" :
                                                                        message?.usertype == 2 ? "Supervisor" :
                                                                            message?.usertype == 3 ? "Customer" :
                                                                                message?.usertype == 4 ? "Watchman" :
                                                                                    message?.usertype == 5 ? "Admin" :
                                                                                        message?.usertype == 6 ? "Telecaller" :
                                                                                            message?.usertype == 7 ? "Vendor" :
                                                                                                message?.usertype == 8 ? "Operation Head" :
                                                                                                    "Unknown"
                                                                } )
                                                                {message.name}
                                                            </a>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='me-3'>

                                                            <span className='text-muted fs-7 mb-1 me-2'>
                                                                {(moment(new Date(message?.created)).fromNow())}
                                                            </span>
                                                            <a
                                                                href='#'
                                                                className='fs-8 fw-bold  text-primary text-hover-dark me-1'
                                                            >
                                                                ( {
                                                                    message?.usertype == 1 ? "Cleaner" :
                                                                        message?.usertype == 2 ? "Supervisor" :
                                                                            message?.usertype == 3 ? "Customer" :
                                                                                message?.usertype == 4 ? "Watchman" :
                                                                                    message?.usertype == 5 ? "Admin" :
                                                                                        message?.usertype == 6 ? "Telecaller" :
                                                                                            message?.usertype == 7 ? "Vendor" :
                                                                                                message?.usertype == 8 ? "Operation Head" :
                                                                                                    "Unknown"
                                                                } )
                                                                {message.name}
                                                            </a>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div
                                                className={clsx(
                                                    'p-2 rounded',
                                                    `bg-light-${message?.image?.includes(".mp3") ? "" : state}`,
                                                    'text-dark  mw-lg-400px',
                                                    // `text-end`,
                                                )}
                                                data-kt-element='message-text'
                                                style={{ width: '80%' }}
                                            >
                                                {
                                                    message?.data ?
                                                        <>
                                                            {
                                                                message?.data?.includes(".mp3")
                                                                    ?
                                                                    // <audio src={message.data} controls />

                                                                    <ExtotelAudioUrl recordingUrl={message?.data} setFetchedUrls={setFetchedUrls} fetchedUrls={fetchedUrls} />

                                                                    :
                                                                    <>
                                                                        {
                                                                            whatsappImageRefsid[whatsappMessageIds] == message?.id ?
                                                                                <>
                                                                                    <img className='w-100' src={`${whatsappImages[whatsappMessageIds]}`} onClick={() => SeeImageFullWidth(whatsappImages[whatsappMessageIds])} />
                                                                                    <p style={{ width: "100%", textAlign: "start", padding: '5px' }}>
                                                                                        {messages ? messages : ""}
                                                                                    </p>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button onClick={() => getImage(message?.data, whatsappMessageIds)} style={{ width: "100%", height: "200px", border: "1px solid pink" }}>
                                                                                        <div className="bg-image" style={{ backgroundImage: `url(${"https://pbs.twimg.com/profile_images/1340989495219347456/b1op9SGE_400x400.jpg"})` }}></div>
                                                                                        <div className='img-text'>
                                                                                            <div className='d-flex align-items-center justify-content-center  flex-column'>
                                                                                                {
                                                                                                    loader ? <>
                                                                                                        <div className='spinner-border mr-15' role='status'></div>
                                                                                                        <h4 className='fw-bold mt-2 text-white'>Loading...</h4>
                                                                                                    </> : <i style={{ fontSize: "30px" }} className="bi bi-arrow-down-circle text-white "></i>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* </div> */}
                                                                                    </button>
                                                                                    <p style={{ width: "100%", textAlign: "start", padding: '1px' }}>
                                                                                        {messages ? messages : ""}
                                                                                    </p>
                                                                                </>
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className='text-start' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(messages ? newMessage : "No Message") }}></div>
                                                            <div className='mt-4 text-end text-success'> {message?.wa_statusString} </div>
                                                        </>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                                :
                                <Not_Found_Data headerTitle={"No Chat Message Found"}></Not_Found_Data>
                            }
                        </div>

                        {/* <button className='btn btn-sm btn-success  fs-5  p-1 mt-3 w-100' onClick={whatsAppMessageHandler}>Switch To  Tickets Message</button > */}

                        <div className='me-3 ' onClick={whatsAppMessageHandler}>
                            <span className="text-primary border border-primary rounded p-1 px-2 cursor-pointer">
                                <FontAwesomeIcon icon={faBackward} className="text-primary me-1 ms-1" />
                                Previous
                            </span>
                        </div>
                    </div>
            }
        </>
    )
}
export default WhatsappChats
