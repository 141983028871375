import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { getPostMultipleApiHandler, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { customerLeadAssignApi } from '../../VisitorsList/customerLeadUtil'

const TicketOwnerForm = ({ FilteredData, setStoreRowP2p = null, row }: any) => {
    const [supervisorsListData, setSupervisorsListData] = useState<any>([])
    const [adminListData, setAdminListData] = useState<any>([])

    async function getFetchInitialData() {
        const results = await getPostMultipleApiHandler(customerLeadAssignApi, null)
        setSupervisorsListData(results["getSupervisorList"] || [])
        setAdminListData(results["getAlladminlist"] || [])
    }

    useEffect(() => {
        FilteredData?.show && getFetchInitialData()
    }, [FilteredData?.show])

    const [selectedAdmin, setSelectedAdmin] = useState<any>({
        first_name: "Select",
        last_name: "Admin",
        phone: ""
    });
    const [selectedSupervisor, setSelectedSupervisor] = useState<any>({
        first_name: "Select",
        last_name: "Supervisor",
        phone: ""
    });

    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [payloadData, setPayloadData] = useState<any>({
        ownerId: FilteredData?.selectedId?.owner_user_id,
        ownerType: FilteredData?.selectedId?.owner_user_type === 5 ? "admin" : "supervisor",
        ticketId: FilteredData?.selectedId?.ticket_id,
    });

    const handleChange = (event: any, name: any) => {
        if (event === null) {
            setPayloadData((prev: any) => ({...prev, "ownerId": 0, ownerType: name}));            
        }else {
            setPayloadData((prev: any) => ({...prev, "ownerId": event.value, ownerType: name}));            
        }
    }

    const handleFormSubmit = async () => {
        setLoader(true)
        if (payloadData.ownerId) {
            const response: any = await postRequest(URL_EndPoints(null)?.updateTicketOwnerAssigned, payloadData, setLoader)
            if (response?.data?.message) {
                if(response?.data?.status === 200) {
                    row.cleanerTicketsSingle['owner_user_id'] = +payloadData.ownerId
                    row.cleanerTicketsSingle['owner_user_type'] = payloadData.ownerType === "admin" ? 5 : 2;
                    toast.success(response?.data?.message)
                    if (setStoreRowP2p) {
                        setStoreRowP2p(row)
                    }
                    FilteredData?.handleCloseForm()
                } else {
                    toast.info(response?.data?.message)
                }
                setDisable(false);
                setLoader(false);
            }
        } else {
            toast.error("Owner is missing")
            setLoader(false);
            setDisable(false);
        }
    }

    useEffect(() => {
        if(payloadData?.ownerType === "admin") {
            const admin: any = adminListData?.filter((data: any) => data.id === payloadData?.ownerId);
            admin && admin.length && setSelectedAdmin(admin[0]);
        }else {
            const supervisor: any = supervisorsListData?.filter((data: any) => data.id === payloadData?.ownerId);
            supervisor && supervisor.length && setSelectedSupervisor(supervisor[0]);
        }
    }, [payloadData.ownerId, payloadData.ownerType]);

    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Assign Owner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="row mb-3" >
                        <div className="col-5">
                            <Form.Check
                                type="radio"
                                id="defaultRadio"
                                label="Assign Admin"
                                name="ownerType"
                                checked={payloadData?.ownerType === "admin"}
                                onChange={() => {
                                    setSelectedAdmin({
                                        first_name: "Select",
                                        last_name: "Admin",
                                        phone: ""
                                    });
                                    setPayloadData((prev: any) => ({...prev, ownerId: 0, ownerType: "admin"}));
                                }}
                            />
                        </div>
                        <div className="col-5">
                            <Form.Check
                                type="radio"
                                id="defaultRadio2"
                                label="Assign Supervisor"
                                name="ownerType"
                                checked={payloadData?.ownerType === "supervisor"}
                                onChange={() => {
                                    setSelectedSupervisor({
                                        first_name: "Select",
                                        last_name: "Supervisor",
                                        phone: ""
                                    });
                                    setPayloadData((prev: any) => ({...prev, ownerId: 0, ownerType: "supervisor"}));
                                }}                                
                            />
                        </div>
                    </Form.Group>
                    {payloadData?.ownerType === "admin" && <Form.Group className="mb-3" >
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select Admin"}
                            SelectData={adminListData}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name={"admin"}  
                            defaultData={{ label: selectedAdmin?.first_name+" "+selectedAdmin?.last_name+" "+selectedAdmin?.phone, value: selectedAdmin?.id }}
                        />
                    </Form.Group>}
                    {payloadData?.ownerType === "supervisor" && <Form.Group className="mb-3" >
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChange}
                                HeaderTitle={"Select supervisor"}
                                SelectData={supervisorsListData}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name={"supervisor"}
                                defaultData={{ label: selectedSupervisor?.first_name+" "+selectedSupervisor?.last_name+" "+selectedSupervisor?.phone, value: selectedSupervisor?.id }}
                            />
                    </Form.Group>}
                    <Form.Group className="mb-3 p-2" >
                    {
                        loader ?
                        <Spinner animation={'border'} />
                        : <Button type='submit' variant="primary" onClick={handleFormSubmit}>
                            Assign
                        </Button>
                    }
                    </Form.Group>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default TicketOwnerForm
