import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export const AdminStackBarToolTip = ({ anchorId, title, place }: any) => {
    return (
        <>
            <ReactTooltip
                anchorId={anchorId}
                place={place ? place : "top"}
                className="bg-secondary text-dark badge badge-secondary"
            // content={title}
            >
                {
                    title?.map((ele: string) => <span className='me-2 badge badge-ligt-success fw-bold text-dark'>{ele}</span>)
                }

            </ReactTooltip>
        </>
    )
}



