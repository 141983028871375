import React, { useState } from 'react'
import clsx from 'clsx';
import "./custom.css"
import DataTable from 'react-data-table-component';
import { AllFielSearch } from '../../FieldSearch/AllFieldSearch';
import { offers_columns } from './OfferListColumn';
import { ReactTableComponentStylesSheet } from '../../reuseModules/ReactTableComponent/ReactTableComponentStylesSheet';
import MainContainerWrapper from '../../mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../reuseModules/SearchBoxModal';

const OfferLists = ({ offersData }: any) => {
    const [search, setSearch] = useState<any>("")
    const [filterData, setfilterData] = useState<any>(offersData)
    React.useEffect(() => {
        const updatedData = AllFielSearch(offersData, search)
        setfilterData(updatedData)
    }, [search])

    React.useEffect(() => {
        setfilterData(offersData)
    }, [offersData])

    return (
        <>
            <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
                <div
                    className={clsx('scroll-y me-n5 pe-5  max mb-4')}
                    data-kt-element='messages'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                    data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
                >
                    <div className='px-3 mt-4 margins' >
                        <DataTable
                            columns={offers_columns()}
                            data={filterData}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight='auto'
                            selectableRowsHighlight
                            highlightOnHover
                            subHeader
                            customStyles={ReactTableComponentStylesSheet}
                            subHeaderComponent={<>
                                <MainContainerWrapper>
                                    <LeftBox>
                                        <SearchBoxModal search={search} setSearch={setSearch} />
                                    </LeftBox>
                        
                                </MainContainerWrapper>
                            </>}
                        />
                    </div>

                </div>
            </div>
        </>
    )
}
export default OfferLists
