import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Refresh = ({ refreshNow }: any) => {
    return (
        <FontAwesomeIcon icon={faRefresh} className='fs-1 cursor-pointer me-3 text-muted' onClick={refreshNow && refreshNow} />
    )
}
export default Refresh

{/* <Refresh refreshNow={InvokedRendered} /> */ }

