import moment from 'moment'
export const ViewOrderItemColumns = () => [
    {
        name: 'Title',
        cell: (row: any) => row?.purchaseOrderInventoryItems?.title,
        sortable: true,
        reorder: true,  
        id: 2,
        width: '150px',
    },
    // {
    //     name: 'Description',
    //     cell: (row: any) => row?.purchaseOrderInventoryItems?.description,
    //     sortable: true,
    //     reorder: true,
    //     id: 3,
    //     width: '120px',
    // },
    // {
    //     name: 'Media',
    //     cell: (row: any) => <img src={row?.purchaseOrderInventoryItems?.media} alt="" style={{ width: "50px", height: "50px" }} />,
    //     reorder: true,
    //     id: 3,
    //     width: '200px',
    //     sortable: true
    // },
    // {
    //     name: 'Price',
    //     cell: (row: any) => row?.purchaseOrderInventoryItems?.price,
    //     reorder: true,
    //     id: 3,
    //     width: '200px',
    //     sortable: true
    // },
    // {
    //     name: 'Refundable',
    //     cell: (row: any) => row?.purchaseOrderInventoryItems?.refundable ? "Yes" : "No",
    //     reorder: true,
    //     id: 3,
    //     width: '200px',
    //     sortable: true
    // },
    {
        name: 'Total Price',
        cell: (row: any) => row?.total_price,
        reorder: true,
        id: 4,
        width: '120px',
        sortable: true
    },
    {
        name: 'Unit Price',
        cell: (row: any) => row?.unit_price,
        reorder: true,
        id: 5,
        width: '120px',
        sortable: true
    },
    {
        name: 'Quantity Ordered',
        cell: (row: any) => row?.quantity_ordered,
        reorder: true,
        id: 6,
        width: '200px',
        sortable: true
    },
    {
        name: 'Quantity Received',
        cell: (row: any) => row?.quantity_received,
        reorder: true,
        id: 7,
        width: '200px',
        sortable: true
    },
    {
        name: 'Quantity Remaining',
        cell: (row: any) => row?.quantity_remaining,
        reorder: true,
        id: 8,
        width: '200px',
        sortable: true
    },
    {
        name: 'Last Received Date',
        cell: (row: any) => row?.last_received_date,
        reorder: true,
        id: 9,
        width: '200px',
        sortable: true
    },
    
    {
        name: 'Updated At',
        cell: (row: any) => moment(row?.updatedAt).format("DD-MM-YYYY"),
        reorder: true,
        id: 10,
        width: '120px',
        sortable: true
    },
    {
        name: 'Created At',
        cell: (row: any) => moment(row?.createdAt).format("DD-MM-YYYY"),
        reorder: true,
        id: 11,
        width: '120px',
        sortable: true
    },
]
