import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import './custom.css'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { ShowHideColumns } from '../../consts/ShowHideColumns';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FilterHandler, DynamicFilter } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, presentKey, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { ToggleColumns } from '../../consts/toggleColumns/ToggleColumns';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox2 from '../../consts/mediaQueryLayout/LeftBox2';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes, toastNotifier } from '../../consts/components/Utility';
import UploadFiles from '../../consts/UploadFiles';
import AddAreaNameChampLead from './AddAreaNameChampLead';
import BulkAssignSubArea from './BulkAssignSubArea';
import BulkChampLeadAssignForm from './BulkChampLeadAssignForm';
import BulkChampWhatsappSendNotificationForm from './BulkChampWhatsappSendNotificationForm';
import BulkChampStatusForm from './BulkChampStatusForm';
import BulkAssignBatchForm from './BulkAssignBatchForm';
import BulkChampOwnerAssignForm from './BulkChampOwnerAssignForm';
import BulkAssingSubLeadStatus from './BulkAssingSubLeadStatus';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import Refresh from '../../consts/Refresh';
import { ReactTableComponentStylesSheet } from '../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet';
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import AdminBulkForm from '../admins/adminForms/AdminBulkForm';
import { filteredSelectedRowsIds, useAdminListApiUrl, useAdminManagementPermission, useAdminSmartFilterList } from '../admins/adminUtils';
import { adminBatcheFormData, adminBulkAssignApi, adminMasterReasonTemplateFormData, adminMenuActionPermissionKeys, adminWatiNotifyTemplatFormData } from '../admins/adminConstant';
import { SearchSelectType, areaNameformData, searchSelectOptions, subAreaNameformData, subLeadStatusFormData } from './champLeadsUtil';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';
import SwitchToggleActionButton from '../admins/adminComponents/SwitchToggleActionButton';
import { useParams } from 'react-router';
import PaginationReactTable from '../admins/AdminPaginationList';
import AdminPaginationList from '../admins/AdminPaginationList';


const VisitorListChampMainV2 = ({ payment_status = "", status = "", by = "", selectedButton_ = null, filterDataOutsource }: any) => {
  const { id } = useParams()
  const { permissionWrapperHideColsUtil } = useAdminManagementPermission()

  const [pending, setPending] = React.useState(false)
  const [searchType, setSearchType] = useState<any>("any")
  const [filterData, setfilterData] = useState<any>([])
  const [filterDataTemp, setfilterDataTemp] = useState<any>([])
  const [selectedButon, setSelectedButon] = React.useState<any>(selectedButton_)
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  // server side
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [sortedColumns, setSortedColumns] = useState<any>(permissionWrapperHideColsUtil(columns(handleFetchAllCallLogs)));
  interface toggleProps {
    shouldIncludeOpenLocations: boolean
    shouldExcludeRequirementAutomation: boolean
  }

  const [toggleAction, setToggleActions] = useState<toggleProps>({
    shouldIncludeOpenLocations: getToggleActionValue("shouldIncludeOpenLocations"),
    shouldExcludeRequirementAutomation: getToggleActionValue("shouldExcludeRequirementAutomation"),
  })

  // server side
  useEffect(() => {
    if (filterData) {
      const newColumns = permissionWrapperHideColsUtil(columns(handleFetchAllCallLogs, filterData?.map((ele: any) => ele?.id), filterData))
      setSortedColumns(newColumns);
    }
  }, [filterData]);
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [payload, setPayload] = useState<any>(initialPayload(true, {
    "status": { operator: "!=", value: 4 },
    "automationID": { operator: "==", value: id }
  }))

  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.visitorListChampPagination2New, { ...payloads, searchType, ...toggleAction }, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.champVisitor)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {

    let eValue = event?.value;

    if (value != "operator" && presentKey?.includes(key)) {
      if (isNaN(Number(eValue))) {
        return
      }
    }

    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: eValue, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)

  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)

  }

  // handle pagination here 
  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    await InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  };

  // deboucing 
  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search && await InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }

  }, [search])



  useEffect(() => {
    let { isFilter, champLeadsFilter } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: champLeadsFilter }, setPending)
    isFilter && setPayload({ ...payload, ["filterData"]: champLeadsFilter })
  }, [
    search,
    filterDataOutsource?.champLeadsFilter,
    filterDataOutsource?.isFilter,
  ])



  const handleRefresh = async () => {
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);
  }

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);

  }, [selectedFilterData?.length]);


  function handleFetchAllCallLogs() {
    setfilterData((prev: any) => {
      setfilterDataTemp(prev);
      return prev;
    });
  }

  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setPending(true);
      const clone = filterDataTemp.slice();
      const cleanerIds = clone.map((e: any) => e.id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds: cleanerIds,
        userType: 'cleaner'
      }, setPending);
      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e.phone || ele[0]?.To === e.phone) || [];
        clone[i].callLogs = foundLog;
      }
      setfilterData(clone);
      setPending(false);
    }
    filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [filterDataTemp])


  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [showAssignPopUp, setShowAssignPopUp] = useState(false)

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  };

  const [showNotifyPopUp, setShowNotifyPopUp] = useState(false);
  const [showStatusPopUp, setShowStatusPopUp] = useState(false);
  const [showAssignBatchPopUp, setShowAssignBatchPopUp] = useState(false);
  const [ShowAreaName, setShowAreaName] = useState(false);
  const [showSubArea, setShowSubArea] = useState(false);
  const [showLeadStatus, setShowLeadStatus] = useState(false);
  const [showCampaignContact, setShowCampaignContact] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false)
  const openFileUploadHandler = () => {
    setShowFileModal(true)
  }
  const [isAssignOwnerOpen, setIsAssignOwnerOpen] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])


  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("visitorListChampPagination2New")
  }, [])


  const toggleActionHandler = (keyName: string, event: any) => {
    const value = event.target.checked
    setToggleActions({ ...toggleAction, [keyName]: value })
    setToggleActionValue(keyName, value)
  }

  function setToggleActionValue(keyName: string, value: any): void {
    localStorage.setItem(keyName, value)
  }

  function getToggleActionValue(keyName: string): boolean {
    const value = localStorage.getItem(keyName)
    return value == "true" ? true : false
  }

  function formatString(str: string) {
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, function (match) {
        return match.toUpperCase();
      });
  }

  const refreshNowPagination = async (newPerPageSize: number | string | null) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending);
  }

  const [newPaginationValue, setNewPaginationValue] = useState<number[]>(paginationRowsPerPageOptions)

  return (
    <>

      <div>
        {
          <DataTable
            key="champLeadsReorderedItem2222"
            title="Champ Leads"
            selectableRows
            customStyles={ReactTableComponentStylesSheet}
            columns={reorderColumns(sortedColumns, "champLeadsReorderedItem")}
            data={filterData}
            onColumnOrderChange={cols => storeReorderIndexes(cols, "champLeadsReorderedItem")}
            fixedHeader
            fixedHeaderScrollHeight='auto'
            selectableRowsHighlight
            highlightOnHover
            subHeader
            // server 
            pagination
            sortServer
            paginationServer
            onSort={handleSort}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSelectedRowsChange={handleSelectedRowsChange}
            paginationComponentOptions={paginationRowsPerPageOptionsAll}
            paginationRowsPerPageOptions={newPaginationValue}
            clearSelectedRows={toggledClearRows}
            progressPending={pending}
            actions={
              <>
                <div className='me-5 d-flex justify-content-end align-items-center'>
                  {
                    Object.keys(toggleAction)?.map((ele) => {
                      return ele in toggleAction && <SwitchToggleActionButton
                        title={formatString(ele)}
                        className="fs-6"
                        toggleActionHandler={(event: any) => toggleActionHandler(ele, event)}
                        isChecked={toggleAction[ele as keyof toggleProps]}
                      />
                    })
                  }

                </div>

              </>
            }
            // server 
            subHeaderComponent={
              <>

                <MainContainerWrapper>
                  <LeftBox>
                    <SearchBoxModal search={search} setSearch={setSearch} />
                    <Form.Select aria-label="Search select" className='box1 ms-2' value={searchType} onChange={(e: any) => setSearchType(e.target.value)}>
                      {searchSelectOptions.map((option: SearchSelectType) => <option value={option.fieldName}>{option.label}</option>)}
                    </Form.Select>
                  </LeftBox>

                  <RightBox>
                    <Refresh refreshNow={handleRefresh} />
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-export-list"]}>
                      <button onClick={openFileUploadHandler} className='btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn '>Import</button>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-date-toggle-format"]}>
                      <ToggleColumns columns={[{ name: "Due Date", id: "Due Date" }, { name: "WhatsApp Template Time", id: "WhatsApp Template Time" }]} from="Visitor" />
                    </PermissionContextProvider>
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-show-hide-columns"]}>
                      <ShowHideColumns columns={permissionWrapperHideColsUtil(columns(selectedButon))} sortedColumns={sortedColumns} setSortedColumns={setSortedColumns} />
                    </PermissionContextProvider>
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-global-filter"]}>
                      <FilterHandler
                        onChangeHandler={onChangeHandler}
                        actionHandler={actionHandler}
                        getCurrentFilter={getCurrentFilter}
                        removeFilter={removeFilter}
                        payload={payload}
                        Component={DynamicFilter}
                        filterData={filter}
                        OperatorsData={operators}
                        selectedFilterData={selectedFilterData}
                        filterComponents={KeywiseComponents()}
                        // filter data  -----
                        storeFilterList={storeFilterList}
                        filterkey={filterkey}
                        setSeacrch={setSearchFilter}
                        isLoadingFilter={isLoadingFilter}
                        listApiUrl={listApiUrl("visitorListChampPagination2New")}
                      />
                    </PermissionContextProvider>
                  </RightBox>

                </MainContainerWrapper>

                {
                  filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-ticket-update"]}>
                      <BulkUtilButton title="Bulk Assign Tickets Champ" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignPopUp} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-owner-update"]}>
                      <BulkUtilButton title="Bulk Assign Owners" filteredSelectedRows={filteredSelectedRows} setShow={setIsAssignOwnerOpen} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-whatsapp-notification-send"]}>
                      <BulkUtilButton title="Bulk WhatsApp Notify Champ" filteredSelectedRows={filteredSelectedRows} setShow={setShowNotifyPopUp} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-status-update"]}>
                      <BulkUtilButton title="Bulk Status Update Champ" filteredSelectedRows={filteredSelectedRows} setShow={setShowStatusPopUp} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-batch-update"]}>
                      <BulkUtilButton title="Bulk Assign Batch Champ" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignBatchPopUp} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-free-form-update"]}>
                      <BulkUtilButton title="Bulk Assign Free form Location Champ" filteredSelectedRows={filteredSelectedRows} setShow={setShowAreaName} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-sub-area-name-update"]}>
                      <BulkUtilButton title="Bulk Assign Sub Area Champ" filteredSelectedRows={filteredSelectedRows} setShow={setShowSubArea} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-sub-lead-status-update"]}>
                      <BulkUtilButton title="Bulk Assign Sub Lead Status Champ" filteredSelectedRows={filteredSelectedRows} setShow={setShowLeadStatus} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-bulk-add-contact-to-campaign"]}>
                      <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={filteredSelectedRows} setShow={setShowCampaignContact} />
                    </PermissionContextProvider>

                  </BulkUtilButtonWrapper> : <></>
                }
              </>
            }
          />
        }

        <AdminPaginationList
          setPerPageSize={setPerPageSize}
          refreshNow={refreshNowPagination}
          setNewPaginationValue={setNewPaginationValue}
        />

      </div >

      {
        showAssignPopUp ? <BulkChampLeadAssignForm
          showAssignPopUp={showAssignPopUp}
          setShowAssignPopUp={setShowAssignPopUp}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>

      }

      {/* {
        showNotifyPopUp ? <BulkChampWhatsappSendNotificationForm
          showAssignPopUp={showNotifyPopUp}
          setShowAssignPopUp={setShowNotifyPopUp}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>

      } */}

      {
        showNotifyPopUp ? <AdminBulkForm
          show={showNotifyPopUp}
          setShow={setShowNotifyPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage="msg"
          formData={adminWatiNotifyTemplatFormData({ HeaderTitle: "", valueType: "", name: "", errMessage: "", label: "", wa_number_id: 2 })}
          initialData={{
            champIds: [],
            templateName: "",
          }}
          keyName="champLeadWatiNotify"
          formTitle={`Send WhatsApp Notification to ${filteredSelectedRows?.length} Customers`}
          bulkKeyName="champIds"
          formSubmitApiUrl="sendBulkWhatsAppTemplateToChamp"
        /> : <></>
      }

      {/* {
        showStatusPopUp ? <BulkChampStatusForm
          showAssignPopUp={showStatusPopUp}
          setShowAssignPopUp={setShowStatusPopUp}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>
      } */}


      {
        showStatusPopUp ? <AdminBulkForm
          show={showStatusPopUp}
          setShow={setShowStatusPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage="message"
          formData={adminMasterReasonTemplateFormData(
            {
              HeaderTitle: "Select Status",
              valueType: "select",
              name: "status_id",
              errMessage: "Status name is Required !",
              label: "",
              category_id: 7
            }
          )}
          initialData={{
            champIds: [],
            status_id: 0,
          }}
          keyName="champLeadStatusForm"
          formTitle={`Update Status of ${filteredSelectedRows.length} Champs`}
          bulkKeyName="champIds"
          formSubmitApiUrl="updateChampData"
        /> : <></>
      }




      {/* {
        showAssignBatchPopUp ? <BulkAssignBatchForm
          showAssignPopUp={showAssignBatchPopUp}
          setShowAssignPopUp={setShowAssignBatchPopUp}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>

      } */}

      {
        showAssignBatchPopUp ? <AdminBulkForm
          show={showAssignBatchPopUp}
          setShow={setShowAssignBatchPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage="message"
          formData={adminBatcheFormData}
          initialData={{
            ids: [],
            type: 'champ',
            batch_id: 0,
          }}
          keyName="champLeadBatch"
          formTitle={`Assign batch to ${filteredSelectedRows?.length} Batchs`}
          bulkKeyName="ids"
          formSubmitApiUrl="assignBatch"
        /> : <></>
      }

      {
        isAssignOwnerOpen ? <BulkChampOwnerAssignForm
          showAssignPopUp={isAssignOwnerOpen}
          setShowAssignPopUp={setIsAssignOwnerOpen}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>
      }

      {/* {
        ShowAreaName ? <AddAreaNameChampLead
          show={ShowAreaName}
          setCloseModal={setShowAreaName}
          AreaList={AreaList}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={handleRefresh}
        /> : <></>
      } */}


      {
        ShowAreaName ? <AdminBulkForm
          show={ShowAreaName}
          setShow={setShowAreaName}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage="msg"
          formData={areaNameformData}
          initialData={{
            champId: [],
            areaId: null,
          }}
          keyName="champAreaNameform"
          formTitle={`Bulk Assign  Free Form Location`}
          bulkKeyName="champId"
          formSubmitApiUrl="addCleanerAddressInBulk"
        /> : <></>
      }

      {/* {
        showSubArea ? <BulkAssignSubArea
          show={showSubArea}
          setCloseModal={setShowSubArea}
          subAreaLists={subAreaLists}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={handleRefresh}

        /> : <></>
      } */}


      {
        showSubArea ? <AdminBulkForm
          show={showSubArea}
          setShow={setShowSubArea}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage="msg"
          formData={subAreaNameformData}
          initialData={{
            champId: [],
            sub_area_id: null,
          }}
          keyName="champSubAreaNameform"
          formTitle={`Bulk Assign  Sub Area`}
          bulkKeyName="champId"
          formSubmitApiUrl="addCleanerAddressInBulk"
        /> : <></>
      }

      {/* {
        showLeadStatus ? <BulkAssingSubLeadStatus
          show={showLeadStatus}
          setCloseModal={setShowLeadStatus}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={handleRefresh}

        /> : <></>
      } */}

      {
        showLeadStatus ? <AdminBulkForm
          show={showLeadStatus}
          setShow={setShowLeadStatus}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage="msg"
          formData={subLeadStatusFormData}
          initialData={{
            cleaner_ids: [],
            sub_status_id: null,
          }}
          keyName="champStatusform"
          formTitle={`Bulk Update Sub Lead Status`}
          bulkKeyName="cleaner_ids"
          formSubmitApiUrl="updateChampColumnsInBulk"
        /> : <></>
      }

      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={filteredSelectedRows?.map((row: any) => row && row?.id)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={handleRefresh}
          userType={"cleaner"}
        /> : <></>
      }

      {
        showFileModal ? <UploadFiles
          show={showFileModal}
          setShowFileModal={setShowFileModal}
        /> : <></>
      }

    </>
  )
}
export default VisitorListChampMainV2
// just call this to route file
