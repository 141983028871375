import moment from 'moment'

export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },
  {
    name: 'Media',
    cell: (row: any) => <img src={row?.media} alt="" style={{ width: "50px", height: "50px" }} />,
    reorder: true,
    id: 3,
    width: '100px',
  },
  {
    name: 'Amount',
    cell: (row: any) => row?.amount,
    sortField: 'amount',
    sortable: true,
    reorder: true,
    id: 4,
    width: '150px',
  },

  {
    name: 'Notes',
    cell: (row: any) => row?.notes,
    sortField: 'notes',
    sortable: true,
    reorder: true,
    id: 5,
    width: '150px',
  },
  {
    name: 'Invoice Date',
    cell: (row: any) => row?.invoice_date,
    sortField: 'invoice_date',
    sortable: true,
    reorder: true,
    id: 6,
    width: '120px',
  },
  {
    name: 'Due Date',
    cell: (row: any) => row?.due_date,
    sortField: 'due_date',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Purchase Order Id',
    cell: (row: any) => row?.proformaPurchaseOrder?.purchase_order_id,
    sortField: 'proformaPurchaseOrder.purchase_order_id',
    sortable: true,
    reorder: true,
    id: 8,
    width: '120px',
  },
  {
    name: 'Status',
    cell: (row: any) => row?.proformaStatus?.status,
    sortField: 'proformaStatus.status',
    sortable: true,
    reorder: true,
    id: 9,
    width: '200px',
  },
  {
    name: 'Proforma Invoice Id',
    cell: (row: any) => row?.proforma_invoice_id,
    sortField: 'proforma_invoice_id',
    sortable: true,
    reorder: true,
    id: 10,
    width: '200px',
  },

  {
    name: 'updatedAt',
    cell: (row: any) => moment(row?.updatedAt).format("MM-DD-YYYY"),
    sortField: 'updatedAt',
    sortable: true,
    reorder: true,
    id: 11,
    width: '120px',
  },


]