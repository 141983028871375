import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils';
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import './barChart.css';

type Props = {
  data: {
    categories: any;
    series: any;
  };
  colorCombination: any;
};

const AreaManagerChartCustomerReview: React.FC<Props> = ({
  data, colorCombination
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  const refreshChart = () => {
    if (!chartRef.current) {
      console.error("Chart reference not found");
      return;
    }
    const height = parseInt(getCSS(chartRef.current, 'height'), 10);
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, data, colorCombination));
    if (chart) {
      chart.render().then(() => {
        console.log("Chart rendered successfully");
      }).catch((err) => {
        console.error("Error rendering chart:", err);
      });
    }

    return chart;
  };

  return (
    <>
      <div ref={chartRef} id='kt_charts_widget_1_chart' className='p-2 px-1 col-12 card-xl-stretch' style={{ height: '100px', border:"none", borderBottom:"1px solid gray", }} />
      {/* mb-xl-8 */}
    </>
  );
};

export { AreaManagerChartCustomerReview };

function getChartOptions(height: number, data: any, colorCombination: any = ['#3aabff']): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-700');
  const borderColor = getCSSVariableValue('--kt-gray-200');

  colorCombination = [
    "#4caf50",  // Green
    "#aa2e25",  // Red
    "#aa2e25",  // Red
    "#4caf50",  // Green
    "#aa2e25",  // Red
    "#aa2e25",   // Red
    "#4caf50",  // Green
    "#4caf50",  // Green
    "#4caf50",  // Green
    "#4caf50",  // Green
    "#4caf50",  // Green
    "#4caf50",  // Green
    "#4caf50",  // Green
    "#4caf50",  // Green
    "#4caf50",  // Green
  ];

  return {
    series: data.series,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
        distributed: true // This line ensures different colors for each bar
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toString();
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: [labelColor]
      },
    },
    xaxis: {
      categories: data.categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontFamily: 'inherit',
        },
      },
      tickPlacement: 'on',
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString();
        },
      },
      enabled: false
    },
    colors: colorCombination,
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
