import moment from 'moment';
import RatingActions from './actions/RatingActions';
import ChipsInfos from '../../consts/Chips/ChipsInfos';
import GChatBTN from '../../consts/Chats/ChatButtons/GChatBTN';
import { useEffect, useState } from 'react';
import IsPaidRatingYesNo from '../../consts/SweetAlert/IsPaidRatingYesNo';
import { Button } from 'react-bootstrap';
import OpenChat from '../../consts/champChatDashboard/OpenChat';
import TicketStatus from '../../consts/reuseModules/TicketStatus';

const ticketStatusToColor = ["warning", "danger", "warning", "success", "dark", "primary", "primary"];

export const columns = () => [
    {
        id: 1,
        reorder: true,
        name: 'Actions',
        cell: (row: any) => {
            return <RatingActions row={row} />
        },
    },
    {

        id: 2,
        reorder: true,
        name: "ID",
        cell: (row: any) => row?.id,
        sortField: 'id',
        sortable: true,
    },
    {

        id: 3,
        reorder: true,
        name: "User ID",
        selector: (row: any) => row?.reviewer_nickname ?? '',
        sortField: 'reviewer_nickname',
        sortable: true,
        minWidth: '150px',
    },
    {

        id: 4,
        reorder: true,
        name: "Customer Name",
        cell: (row: any) => {
            return (
                row?.customerid ?
                    <GChatBTN ticketDataParent={{
                        id: "",
                        customerid: row?.customerid,
                        referenceTicket: "renewalLists"
                    }} Title={row?.customerDetails?.customer_name ?? 'NA'} Toggleid={"kt_drawer_chat2_toggle2"}
                        icons={""}
                        tableData={row}
                        refs=""
                        table="tikcet"
                    ></GChatBTN> : "NA"


            )
        },
        sortField: 'customerDetails.first_name',
        sortable: true,
        minWidth: '150px',
    },
    {

        id: 5,
        reorder: true,
        name: "Source",
        selector: (row: any) => row?.sourceDetails?.name,
        sortField: 'sourceDetails.name',
        sortable: true
    },
    {

        id: 6,
        reorder: true,
        name: "Champ",
        cell: (row: any) => row?.cleanerDetails?.cleaner_name ? <OpenChat title={row?.cleanerDetails?.cleaner_name} champId={row?.cleanerid} /> : "",
        sortField: 'cleanerDetails.first_name',
        sortable: true,
        minWidth: '150px',
    },
    {

        id: 7,
        reorder: true,
        name: "Ticket Status",
        // selector: (row: any) => row?.ratingTicket?.ticketstatus?.name ? <ChipsInfos SelectedString={row?.ratingTicket?.ticketstatus?.name || 'Not Set'} classes={ticketStatusToColor[row?.ratingTicket?.ticketstatus?.id]} /> : <ChipsInfos SelectedString={"No Tickets"} classes="danger" />,
        cell: (row: any) => { return <TicketStatus ticketStatus={row?.ratingTicket?.ticketstatus?.name} ticketStatusId={row?.ratingTicket?.ticketstatus?.id} /> },
        sortField: 'ratingTicket.ticketstatus.name',
        sortable: true,
        minWidth: '150px',
    },
    {

        id: 8,
        reorder: true,
        name: "Rating",
        cell: (row: any) => row?.rate,
        sortField: 'rate',
        sortable: true,
    },
    {

        id: 9,
        reorder: true,
        name: "Comment",
        cell: (row: any) => row?.comment,
        sortField: 'comment',
        sortable: true,
        minWidth: '400px',
    },
    {

        id: 10,
        reorder: true,
        name: "Date",
        selector: (row: any) => moment(row?.date_time).format("DD-MM-YYYY"),
        sortField: 'date_time',
        sortable: true,
        minWidth: '200px',
    },
    {

        id: 11,
        reorder: true,
        name: "Created At",
        selector: (row: any) => moment(row?.createdAt).format("DD-MM-YYYY"),
        sortField: 'createdAt',
        sortable: true,
        minWidth: '150px',
    },
    {

        id: 12,
        reorder: true,
        name: "Is Paid",
        selector: (row: any) => <IsPaidModal row={row} />,
        sortField: 'is_paid',
        sortable: true,
        minWidth: '150px',
    },
];



const IsPaidModal = ({ row }: any) => {

    const [isPaidStatusModal, setIsPaidStatusModal] = useState<any>(false)
    const [renders, setRender] = useState<any>(null)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [loader, setLoader] = useState<any>(false)
    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const openIsPaidModalHandler = () => {
        setIsPaidStatusModal(true)
        setRender(Math.random())
    }
    const title = loader ? "In Progress" : storeRow?.ispaid == 0 ? "Not Paid" : "Paid"
    const colorStatus = loader ? "danger" : storeRow?.ispaid == 0 ? "primary" : "success"
    return (
        <>

            <Button disabled={storeRow?.ispaid == 0 ? false : true} className={`btn btn-sm btn-${colorStatus}`} onClick={() => openIsPaidModalHandler()}>{title}</Button>

            {
                isPaidStatusModal ?
                    <IsPaidRatingYesNo
                        render={renders}
                        confirm={'Yes'}
                        cancel={'No'}
                        payload={{
                            "ratingIds": [row?.id],
                            "approver_id": localStorage.getItem("user"),
                            "updateFields": {
                                "ispaid": 1
                            }
                        }}
                        setLoader={setLoader}
                        setIsPaidStatusModal={setIsPaidStatusModal}
                        title={`Are you sure want to Paid ?`}

                        setStoreRow={setStoreRow}
                        row={row}
                    /> : ""
            }
        </>
    )
}
