export const requirementApiUrl = [
  {
    url: 'getRequirementTypes',
    method: 'POST',
    isBody: null,
  },
  {
    url: 'getAllTrainingLocations',
    method: 'GET',
    isQuery: null,
    isParams: null,
  },
  {
    url: 'getarea',
    method: 'GET',
    isQuery: null,
    isParams: null,
  },
  {
    url: 'getSupervisorList',
    method: 'GET',
    isQuery: null,
    isParams: null,
  },
  {
    url: 'getPriorityOptions',
    method: 'GET',
    isQuery: null,
    isParams: null,
  },
]

export const uploadChampHiringData = [
  {
    url: 'whatsapp/getWhatsAppTemplate',
    method: 'POST',
    isBody: {
      wa_number_id: 2,
    },
  },
  {
    url: 'getTicketSources',
    method: 'GET',
    isQuery: null,
    isParams: null,
  },
]

export const approvalListInitialData = [
  {
    url: 'getSupervisorList',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
]

export const newTicketListInititalData = [
  {
    url: 'getSupervisorList',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  {
    url: 'getAlladminlist',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  {
    url: 'getTicketCategories',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  {
    url: 'getTicketSubCategories',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  {
    url: 'getTicketSources',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },

]
