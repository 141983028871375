import {getRequest, postRequest} from '../../app/consts/Server/Requests'
import {URL_EndPoints} from '../../app/consts/Server/URL_EndPoints'
import {STORE_LAST_STATUS_LIST_DATA} from '../actionsType/cxActionTypes'

export const setStoreLastStatusListData = (result: any) => (dispatch: any) => {
  // let result = {data, keyName }
  dispatch({type: STORE_LAST_STATUS_LIST_DATA, payload: result})
}

// export const setStoreListDataToCxStore = (result: any) => (dispatch: any) => {
//   dispatch({type: result?.type, payload: result})
// }

// export const setStoreCxRowListDataToCxStore = (result: any) => (dispatch: any) => {
//   dispatch({type: result?.type, payload: result})
// }

// export const setLastStatusListDataToStore = (payload?: any) => async (dispatch: any) => {
//   try {
//     // dispatch({type: LOADING, payload: true})
//     const response = await getRequest(URL_EndPoints()?.getAllFAQsCategories, null)
//     if (response?.data?.status == 200) {
//       dispatch({
//         type: GET_ALL_CATEGORY_LIST,
//         payload: response?.data?.data,
//       })
//       //   dispatch({type: SUCCESS, payload: false})
//     }
//     // dispatch({type: FAILURE, payload: false})
//   } catch (error) {
//     // dispatch({type: FAILURE, payload: false})
//   }
// }
