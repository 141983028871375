export const faqDummyData = [
  {
    id: 1,
    question: 'How to Reduce CSS/JS Bundle Size By Removing Unused Resource...',
    short_answer:
      "Hi,Metronic's by default includes all its available resources within the gulp config so the bundle size of the compiled CSS/JS files. To reduce the file size of the CSS/JS bundle files follow the below steps: Open theme/tools/gulp.config.js and refer to",
    answer:
      "Hi,Metronic's by default includes all its available resources within the gulp config so the bundle size of the compiled CSS/JS files. To reduce the file size of the CSS/JS bundle files follow the below steps: Open theme/tools/gulp.config.js and refer to What is the correct way for deploying a Metronic-Django web application in a apache Debian server using mod-wsgi What is the correct way for deploying a Metronic-Django web application in a apache Debian server using mod-wsgi",
    category: 'Suggestions',
    tags: 'HTML, CSS, NEW, OLD, POST',
    status: 1,
    createdAt: 1,
    updatedAt: 1,
    pinned: true,
    author_id: 1,
  },
  {
    id: 2,
    question: 'Metronic-Django deploy',
    short_answer:
      "Hi,Metronic's by default includes all its available resources within the gulp config so the bundle size of the compiled CSS/JS files. To reduce the file size of the CSS/JS bundle files follow the below steps: Open theme/tools/gulp.config.js and refer to",
    answer:
      'What is the correct way for deploying a Metronic-Django web application in a apache Debian server using mod-wsgi What is the correct way for deploying a Metronic-Django web application in a apache Debian server using mod-wsgi What is the correct way for deploying a Metronic-Django web application in a apache Debian server using mod-wsgi',
    category: 'Pre-Sale Questions',
    tags: 'JS, JV, NEWS, CLEAR, NEXT',
    status: 1,
    createdAt: 1,
    updatedAt: 1,
    pinned: false,
    author_id: 1,
  },
  {
    id: 3,
    question: 'Change Folder Name and also path of whole react app',
    short_answer:
      "Hi,Metronic's by default includes all its available resources within the gulp config so the bundle size of the compiled CSS/JS files. To reduce the file size of the CSS/JS bundle files follow the below steps: Open theme/tools/gulp.config.js and refer to",
    answer:
      'I want Change src/_metronic folder name and also directory change. oralso, I want to base theme for use all react app. so how can I do this my react app I want Change src/_metronic folder name and also directory change. oralso, I want to base theme for use all react app. so how can I do this my react app I want Change src/_metronic folder name and also directory change. oralso, I want to base theme for use all react app. so how can I do this my react app',
    category: 'Metronics',
    tags: 'CAR, SELO, MAT, ME, YOU',
    status: 1,
    createdAt: 1,
    updatedAt: 1,
    pinned: false,
    author_id: 1,
  },
]

export const createFaqsFormData = [
  {
    HeaderTitle: 'Question',
    filterApi: '',
    label: {
      show: true,
      name: 'Question',
    },
    valueType: 'textBox',
    inputType: '',
    displayFields: [],
    valueField: 'id',
    name: 'question',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Name  is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Short answer',
    filterApi: '',
    label: {
      show: true,
      name: 'Write Short Answer',
    },
    valueType: 'textArea',
    inputType: '',
    displayFields: [],
    valueField: 'id',
    name: 'short_answer',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Short answer  is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Long answer',
    filterApi: '',
    label: {
      show: true,
      name: 'Write Long Answer',
    },
    valueType: 'textEditor',
    inputType: '',
    displayFields: [],
    valueField: '',
    name: 'answer',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Answer  is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Select Category',
    filterApi: 'getAllFAQsCategories',
    label: {
      show: true,
      name: 'Category',
    },
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'category_id',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'category  is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Select Tags',
    filterApi: '',
    label: {
      show: true,
      name: 'Tags',
    },
    valueType: 'textTag',
    inputType: '',
    displayFields: [],
    valueField: '',
    name: 'tags',
    from: '',
    to: '',
    isRequired: false,
    errMessage: 'Tags  is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  // {
  //   HeaderTitle: 'Select Status',
  //   filterApi: '',
  //   label: {
  //     show: false,
  //     name: 'Select Status ',
  //   },
  //   valueType: 'checkbox',
  //   inputType: '',
  //   displayFields: [],
  //   valueField: '',
  //   name: 'status',
  //   from: '',
  //   to: '',
  //   isRequired: false,
  //   errMessage: 'status  is required !',
  //   searchable: false,
  //   wa_number_id: null,
  //   userType: null,
  // },
]

export const addCategoryData = [
  {
    HeaderTitle: 'Name',
    filterApi: '',
    label: {
      show: true,
      name: 'Name',
    },
    valueType: 'textBox',
    inputType: '',
    displayFields: [],
    valueField: '',
    name: 'name',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Name  is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Description',
    filterApi: '',
    label: {
      show: true,
      name: 'Description',
    },
    valueType: 'textArea',
    inputType: '',
    displayFields: [],
    valueField: '',
    name: 'description',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Description  is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
 
]
