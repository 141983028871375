import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Bars } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../Server/URL_EndPoints'
import { postRequest } from '../Server/Requests'
const UpdateAddress = ({ show, handleClose, subscriptionId, customerId }: any) => {

    const [address, setAddress] = useState("")
    const [customerAddress, setCustomerAddress] = useState([])
    const [loader, setLoader] = useState<any>(false)

    useEffect(() => {
        async function load() {
            const result: any = await postRequest(URL_EndPoints()?.getcustomeraddress, {
                customerid: customerId,
            }, null)
            setCustomerAddress(result?.data?.data)
        }
        load()

    }, [])


    const changeAddress = async () => {

        setLoader(true)
        if (address) {
            const result: any = await postRequest(URL_EndPoints()?.updatesubscriptionaddress, {
                subscriptionid: subscriptionId,
                addressid: +address
            }, setLoader)
            if (result?.data?.status == 200) {

                toast.success(result?.data?.msg, { position: "top-center" })
                handleClose()
            }
            else {
                toast.success("Something went wrong", { position: "top-center" })
                
            }

            setLoader(false)
        }
        else {
            toast.error("Plz select the address", { position: "top-center" })
            setLoader(false)

        }

        setLoader(false)



    }
    return (
        <>
            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Customer Address </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row  ms-3 mb-3 mt-2 col-12'>


                        <select
                            className='form-select col-12 '
                            onChange={(e) => setAddress(e.target.value)}
                            style={{ height: "38px" }}
                            value={address}
                        >
                            <option value="">Select Address</option>
                            {
                                customerAddress?.map((ele: any) => {
                                    return <option value={ele?.id}>{ele?.address}</option>
                                })
                            }

                        </select>





                        
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button dir="rtl" className="btn btn-success mt-4 text-end" onClick={changeAddress} disabled={loader}>
                            {loader ?
                                <Bars
                                    height="20"
                                    width="20"
                                    color="#00000"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                : 'Update Address'}
                        </button>
                    </div>

                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default UpdateAddress
