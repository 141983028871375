import React, { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import FileUpload from './FileUpload'

const AddDocuments = ({ row, show, setShow, documentTypeList, loadInitialData, isAciveOrInactiveDocument }: any) => {

  return (
    <>

      <Modal show={show} onHide={() => setShow(false)} >
        <Modal.Header closeButton>
          <Modal.Title>Add Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileUpload url="uploadChampDocument" payload={{}} btnTitle="Upload File" setShow={setShow} documentTypeList={documentTypeList} row={row} loadInitialData={loadInitialData} isAciveOrInactiveDocument={isAciveOrInactiveDocument} />
        </Modal.Body>
      </Modal>

    </>
  )
}

export default AddDocuments
