import React from 'react'
const AudioPlayer = ({ParentData,newAudio   }: any) => {
 
    return (
        <>


            {
              <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-sm ">
                    <audio src={newAudio} controls style={{ background: "transparent" }} autoPlay  onPause={(event)=>ParentData?.hanlePause(event, newAudio)} onPlay={(event)=>ParentData?.handlePlay(event, newAudio)}/>
                </button>
                  
            }
        </>
    )
}
export default AudioPlayer