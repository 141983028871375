import React from 'react'
import { Modal } from 'react-bootstrap'
import { Bars } from 'react-loader-spinner'
import "./Inputs.css"

const ChampPhoneNumberUpdate = ({ FilteredData }: any) => {

    return (
        <>
            {FilteredData?.PhoneNumberUpdateShow ? <Modal show={FilteredData?.PhoneNumberUpdateShow} onHide={FilteredData?.handleClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Phone Number </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row  ms-3 mb-3 mt-2 col-12'>
                        <input
                            type='number'
                            className='form-control  me-2  border'
                            placeholder='Enter Number Here...'
                            style={{ height: "50px", width: "100%" }}
                            onChange={(e) => FilteredData?.setPhoneNumber(e.target.value)}
                            value={FilteredData?.phoneNumber}

                        />
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button dir="rtl" className="btn btn-success mt-4 text-end" onClick={FilteredData?.PhoneNummberUpdate} disabled={FilteredData?.disable || FilteredData?.loader ? true : false}>
                            {FilteredData?.loader ?
                                <Bars
                                    height="20"
                                    width="20"
                                    color="#00000"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                : 'Update Phone Number'}
                        </button>
                    </div>
                   
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default ChampPhoneNumberUpdate
