import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, Table, Dropdown, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { adminParamsGenerator, adminToastRunner } from '../../../admins/adminUtils';
import { getRequest, postRequest } from '../../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints';

const AddPurchaseOrdersForm = (props: any) => {
    const { popUpPostFormOpen, refreshNow, handleClose, setData, initialValue, setInitialValue, editId, setEditId } = props;
    const [loading, setLoading] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [warehouseList, setWarehouseList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [inventoryItems, setInventoryItems] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        setSelectedItems(
            initialValue?.item_list?.map((item: any) => ({
                ...item,
                title: item.title,
                quantity: item?.quantity || 1,
                total_price: Number(item?.total_price) || 0,
                price: Number(item?.price) || 0,
                unit: item.unit || '',
            }))
        );
    }, [initialValue]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [vendorResponse, warehouseResponse, inventoryResponse] = await Promise.all([
                // const [vendorResponse, warehouseResponse, statusResponse, inventoryResponse] = await Promise.all([
                    getRequest(URL_EndPoints()?.getVendorListing, null),
                    getRequest(URL_EndPoints()?.getWarehouseListing, null),
                    // getRequest(URL_EndPoints()?.getPurchaseOrderStatus, null),
                    getRequest(URL_EndPoints()?.getAllInventoryItems, null),
                ]);
                setVendorList(vendorResponse?.data?.data);
                setWarehouseList(warehouseResponse?.data?.data);
                // setStatusList(statusResponse?.data?.data);
                setInventoryItems(inventoryResponse?.data?.data || []);
               
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (popUpPostFormOpen) {
            fetchData();
        }
    }, [popUpPostFormOpen]);

    const handleInputChange = (e: any) => {
        setInitialValue({
            ...initialValue,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearchChange = (e: any) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value) {
            // Filter the inventory items based on the search term
            const filteredResults = inventoryItems.filter((item: any) =>
                item.title.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults(filteredResults);
            setShowDropdown(true);
        } else {
            // If search term is empty, keep searchResults as is and hide dropdown
            setSearchResults([]);
            setShowDropdown(false);
        }
    };

    const handleSelectItem = (item: any) => {
        // Check if the item is already selected
        const isItemAlreadySelected = selectedItems.some(selected => selected.id === item.id);
        if (isItemAlreadySelected) {
            toast.warning('Item is already selected.');
            return;
        }

        // Convert price to a number and set initial total_price
        const price = Number(item.price) || 0; // Fallback to 0 if item.price is not a valid number
        setSelectedItems(prevSelectedItems => [
            ...prevSelectedItems,
            { ...item, quantity: 1, total_price: price }
        ]);
        setShowDropdown(false);
    };


    const handleQuantityChange = (index: number, value: number) => {
        const updatedItems = [...selectedItems];
        updatedItems[index].quantity = value;
        updatedItems[index].total_price = updatedItems[index].price * value; // Recalculate total_price
        setSelectedItems(updatedItems);
    };

    const calculateTotalAmount = () => {
        return selectedItems.reduce((total, item) => total + item.total_price, 0);
    };

    const handleRemoveItem = (index: number) => {
        const updatedItems = selectedItems.filter((_, i) => i !== index);
        setSelectedItems(updatedItems);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!selectedItems || selectedItems.length === 0) {
            toast.warning('Please select at least one item');
            return;
        }
        setLoading(true);

        let payload: any = {};
        for (let key in initialValue) {
            let value = initialValue[key];
            if (value) payload[key] = value;
        }

        payload.total_amount = calculateTotalAmount();

        payload.items = []
        selectedItems.forEach((item) => {
            payload.items.push({ item });
        });

        delete payload.item_list;

        let response;
        if (editId) {
            response = await postRequest(URL_EndPoints(adminParamsGenerator("updatePurchaseOrders", editId))?.updatePurchaseOrders, payload, null)
        } else {
            response = await postRequest(URL_EndPoints()?.createPurchaseOrder, payload, null)
        }

        adminToastRunner(response)
        if (response?.data?.data?.items) {
            setData(response?.data?.data?.items);
        }

        setSelectedItems && setSelectedItems([]);
        setSearchTerm && setSearchTerm('');
        refreshNow && refreshNow();
        setEditId && setEditId(0)
        handleClose && handleClose();
        setLoading && setLoading(false);
    };

    return (
        <Modal size='lg' show={popUpPostFormOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Purchase Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="vendor_id">
                                <Form.Label><strong>Vendor</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="vendor_id"
                                    value={initialValue["vendor_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Vendor</option>
                                    {vendorList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.vendor_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="warehouse_id">
                                <Form.Label><strong>Warehouse</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="warehouse_id"
                                    value={initialValue["warehouse_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Warehouse</option>
                                    {warehouseList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.warehouse_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="expected_delivery_date">
                                <Form.Label><strong>Expected Delivery Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Expected delivery Date"
                                    name="expected_delivery_date"
                                    value={initialValue['expected_delivery_date']}
                                    onChange={handleInputChange}
                                    required
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>
                            {/* <Form.Group as={Col} className="mb-3" controlId="status">
                                <Form.Label><strong>Status</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="status"
                                    value={initialValue["status"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    {statusList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.status}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group> */}
                        </Row>

                        {/* <Row className="mb-3">
                           
                        </Row> */}

                        <Form.Group controlId="searchItems" className="mb-3">
                            <Form.Label><strong>Search Items</strong></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Type to search for an item"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onFocus={() => setShowDropdown(searchResults.length > 0)} // Show dropdown if there are results
                                onBlur={() => setTimeout(() => setShowDropdown(false), 150)} // Hide dropdown on blur after short delay
                            />
                            {showDropdown && (
                                <Dropdown.Menu show style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                                    {searchResults.map((item: any) => (
                                        <Dropdown.Item
                                            key={item.id}
                                            onMouseDown={() => handleSelectItem(item)} // Use onMouseDown to prevent loss of focus
                                        >
                                            <strong>Title:</strong> {item.title} - <strong>Price:</strong> {item.price} - <strong>Unit:</strong> {item.unit}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>

                            )}
                        </Form.Group>

                        {selectedItems?.length > 0 && (
                            <div>
                                <h5><strong>Selected Items</strong></h5>
                                <Table striped bordered hover responsive>
                                    <thead style={{ backgroundColor: '#5cb85c', color: 'white' }}>
                                        <tr>
                                            <th><strong>Title</strong></th>
                                            <th><strong>Price</strong></th>
                                            <th><strong>Quantity</strong></th>
                                            <th><strong>Total Price</strong></th> {/* New column for total_price */}
                                            <th><strong>Unit</strong></th>
                                            <th><strong>Action</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedItems.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.title}</td>
                                                <td>{item.price}</td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        value={item.quantity}
                                                        onChange={(e: any) => handleQuantityChange(index, Number(e.target.value))}
                                                        min={1}
                                                    />
                                                </td>

                                                <td>{typeof item.total_price === 'number' ? item.total_price.toFixed(2) : '0.00'}</td>

                                                <td>{item.unit}</td>
                                                <td>
                                                    <Button variant="danger" onClick={() => handleRemoveItem(index)}>
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </Table>

                                <div style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '16px' }}>
                                    Total Amount: {calculateTotalAmount().toFixed(2)}
                                </div>


                            </div>
                        )}

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddPurchaseOrdersForm;
