import React from 'react';
import Select from 'react-select';
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet';
const TagInputs = ({ handleChangeInputData, HeaderTitle, SelectData, DynamicKey, id, name, defaultData }: any) => {
  
  const [showData, setShowData] = React.useState<any>([])
  const [defaultValue, setdefaultValue] = React.useState<any>(defaultData || [])
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      if (ele[DynamicKey]) {
        const newData = {
          label: ele[DynamicKey] ,
          value: ele[id],
        }
        return newData
      }
    })
    setShowData(updatedData)
  }, [SelectData])

  React.useEffect(() => {
    defaultData?.length > 0 && setdefaultValue(defaultData)
  }, [defaultData])


  
  const HandleSearch = (event: any) => {
    handleChangeInputData(event?.map((ele: any) => ele.label))
  }
  
  return (
    <div className=' col-12 ' style={{outline:"none"}}>
      <Select
        isClearable
        isSearchable
        isMulti
        placeholder={HeaderTitle}
        options={showData}
        onChange={HandleSearch}
        defaultValue={defaultValue?.length > 0 ? defaultValue : []}
        styles={colourStyles2}
      />
    </div>
  )
}
export default TagInputs




// <TagInputs
// handleChangeInputData={handleChangeInputData}
// HeaderTitle="Select Supervisor"
// SelectData={SupervisorsListData}
// DynamicKey={"first_name"}
// DynamicKey2={"last_name"}
// DynamicKey3={"phone"}
// id={"id"}
// name="supervisorIds"
// defaultData={updatedData}
// />