import CircleComponent from "./CircleComponent"

export const CronHistoryColumnsList = (selectedButton?: any, customerData?: any) => [

    {
        id: 1,
        reorder: true,
        name: "NAME",
        cell: (row: any) => row?.name ?? "NA",
        sortField: 'title',
        sortable: true,
        width: "250px"
    },
    {
        id: 2,
        reorder: true,
        name: "CRON JOB TIMING",
        cell: (row: any) => {
            return <div className="d-flex">
                {
                    row?.cron_job_timings?.map((ele: any) => <CircleComponent row={row} ele={ele} />)
                }
            </div>
        },
        sortField: 'column2',
        sortable: true,
        // width: "150px"
    },

]
