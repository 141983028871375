import {getRequest, postRequest} from '../../../app/consts/Server/Requests'
import {URL_EndPoints} from '../../../app/consts/Server/URL_EndPoints'

export const getSmartFilterAction = (payload?: any) => async (dispatch: any) => {
  try {
    const result = await postRequest(URL_EndPoints()?.getSmartFilters, payload, null)

    dispatch({
      type: 'STORE_SMART_FILTER_DATA',
      payload: {key: payload?.list_api_url, data: result?.data?.data},
    })
  } catch (error) {
    dispatch({type: 'STORE_SMART_FILTER_DATA', payload: []})
  }
}
