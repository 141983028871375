import React, { memo } from 'react';
import { withSwal as DeleteOfferListItem } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { useDispatch } from 'react-redux';
export default DeleteOfferListItem((props: any) => {

    const dispatch = useDispatch()
    const { swal, confirm, cancel, payloads }: any = props;
    const { payloadData, render, successTitle, FailedTitle } = payloads
    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.deleteCtOffer, payloadData, null)
            if (response?.data?.msg) {
                swal.fire(successTitle)

                dispatch({
                    type: "OFFER_LIST_DELETED", payload: {
                        offerid: 1,
                        offer_render: Math.random()
                    }
                })

              
            }
        }
        if (render) {
            swal.fire({
                title: 'Do you want to delete the offer list item ?',
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});
