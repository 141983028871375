import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { postRequest } from '../../../consts/Server/Requests'
import TagInputs from '../../../consts/TagInputs/TagInputs'
const UpdatePrivateTagsListTaskList = ({ showModal, handleClose, row, setRowData = null }: any) => {
    const [loader, setloader] = useState<any>(false)
    const [suggestionTagData, setsuggestionTagData] = useState<any>([])

    const [payload, setPayload] = useState<any>({
        cleaner_id: row?.attendenceCleaner?.id ?? null,
        private_tag: []
    })

    const updatedData = row?.attendenceCleaner?.private_tag?.split(",")?.map((ele: any, i: number) => {
        if (ele) {
            const newData = {
                label: ele,
                value: i,
            }
            return newData
        }
    })

    useEffect(() => {
        row?.attendenceCleaner?.private_tag && setPayload({ ...payload, ["private_tag"]: row?.attendenceCleaner?.private_tag?.split(",") })
    }, [row?.attendenceCleaner?.private_tag])


    useEffect(() => {
        async function loadIntitalData() {
            const masterTagList = await postRequest(URL_EndPoints()?.getAllMasterTags, { userType: 1 }, null)
            setsuggestionTagData(masterTagList?.data?.data)
        }
        loadIntitalData()
    }, [])


    const handleChangeInputHandler = (event: any, name: any) => {
        setPayload({ ...payload, ["private_tag"]: event })
    }

    const updateTagHandler = async () => {
        setloader(true)
        const response: any = await postRequest(URL_EndPoints()?.updateChampColumns, payload, setloader)
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })

        if (response?.data?.status == 200 && setRowData) {
            row.attendenceCleaner = row?.attendenceCleaner ? { ...row.attendenceCleaner } : {}
            row.attendenceCleaner.private_tag = payload?.private_tag.join(",")
            setRowData(row)
        }
        handleClose()
        setloader(false)
    }



    return (
        <>
            <Modal size='lg' show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Private Tags</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <TagInputs
                            handleChangeInputData={handleChangeInputHandler}
                            HeaderTitle="Select Tags"
                            SelectData={suggestionTagData}
                            DynamicKey={"name"}
                            id={"id"}
                            name="private_tag"
                            defaultData={updatedData}
                        />

                        <div className=' col-12 w-100 d-flex justify-content-end mt-8 me-'>
                            <Button variant="primary btn-sm text-end btn-sm " disabled={loader} onClick={updateTagHandler}>
                                {
                                    loader ?
                                        <div className='d-flex'>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating....</h4>
                                        </div>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Tag </h4>
                                }
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default UpdatePrivateTagsListTaskList
