import { useDispatch } from "react-redux"
const QuickMessage = ({ classes, msg }: any) => {
    const dispatch: any = useDispatch()
    const QuickMsgHandler = () => {
        dispatch(
            {
                type: 'QUICK_REPLY_MESSAGE', payload: {msg:msg, Render:Math.random()}
            }
        )
    }
    // QUICK_REPLY_MESSAGE
    return (
        <>
            {
                <div className={`badge badge-light-${classes} fw-bolder cursor-pointer me-lg-1`} onClick={QuickMsgHandler}  >
                    {
                        <div className={`badge badge-secondary}`}>
                            <span ><i className="fs-5 text-primary fa-solid fa-share"></i></span>
                        </div>
                    }
                </div>
            }
        </>
    )
}
export default QuickMessage
