import React, { useState } from 'react'
import clsx from 'clsx';
import "./custom.css"
import DataTable from 'react-data-table-component';
import { AllFielSearch } from '../../FieldSearch/AllFieldSearch';
import { postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { GetRazorpaySubscriptionOrderColumns } from './GetRazorpaySubscriptionOrderColumns';
import MainContainerWrapper from '../../mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../reuseModules/SearchBoxModal';
import RightBox from '../../mediaQueryLayout/RightBox';
import Refresh from '../../Refresh';
import { ReactTableComponentStylesSheet } from '../../reuseModules/ReactTableComponent/ReactTableComponentStylesSheet';

const GetRazorpaySubscriptionOrder = ({ subscription_id }: any) => {
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<any>(false)
    const [filterData, setfilterData] = useState<any>()
    const [mainData, setMainData] = useState<any>()

    React.useEffect(() => {
        const updatedData = AllFielSearch(mainData, search)
        setfilterData(updatedData)
    }, [search])

    async function loadData() {
        setPending(true)
        const response = await postRequest(URL_EndPoints().getSubscriptionRazorpayOrders, { subscription_id: subscription_id }, setPending)
        setMainData(response?.data?.data?.items)
        setfilterData(response?.data?.data?.items)
    }

    React.useEffect(() => {
        loadData()
    }, [])

    const refreshNow = ()=>{
        loadData()
    }

    return (
        <>
            <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
                <div
                    className={clsx('scroll-y me-n5 pe-5  max mb-4')}
                    data-kt-element='messages'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                    data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
                >
                    <div className='px-3 mt-4 margins' >
                        <DataTable
                            progressPending={pending}
                            customStyles={ReactTableComponentStylesSheet}
                            columns={GetRazorpaySubscriptionOrderColumns()}
                            data={filterData}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight='auto'
                            selectableRowsHighlight
                            highlightOnHover
                            subHeader
                            subHeaderComponent={<>
                                <MainContainerWrapper>
                                    <LeftBox>
                                        <SearchBoxModal search={search} setSearch={setSearch} />
                                    </LeftBox>
                                    <RightBox>
                                        <Refresh refreshNow={refreshNow} />
                                    </RightBox>
                                </MainContainerWrapper>
                            </>}
                        />
                    </div>

                </div>
            </div>
        </>
    )
}
export default GetRazorpaySubscriptionOrder
