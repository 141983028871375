import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
let normalCss = 'text-primary border border-primary rounded p-1 px-2'
let conditionalCss = 'text-danger border border-danger rounded p-1 px-2'
let normalCssIcon = 'text-primary me-1 ms-1'
let conditionalCssIcon = 'text-danger me-1 ms-1'

const InternalTicketTicketOpenCustomer = ({ WhatSappMessageHandler, callToCleaner, previuosHandler, nextHandler, isNextOrPrev }: any) => {
    const loadings = useSelector((store: any) => store.champsReducer.loading)

    return (
        <div className='d-flex justify-content-between mb-1 bg-white  mr-4 mt-2' key={987654321232} >
            <div className="badge badge-round border border-white  cursor-pointer" >

                <div className="me-3" onClick={(row) => callToCleaner()}>
                    <span className='text-primary border border-primary rounded p-1 px-2' > <i className="bi bi-telephone-x-fill text-primary" ></i> Call Now</span>
                </div>

                <div onClick={WhatSappMessageHandler} className=""  >
                    <span className=' text-success border border-success rounded p-1 px-2' >  <i className="bi bi-whatsapp   text-success" ></i> Whatsapp</span>
                </div>

            </div>

            <div className="badge badge-round border border-white  cursor-pointer" >

                <div className='me-3 ' onClick={previuosHandler}>
                    <span className={loadings && isNextOrPrev == "Prev" ? conditionalCss : normalCss}>
                        <FontAwesomeIcon icon={faBackward} className={loadings && isNextOrPrev == "Prev" ? conditionalCssIcon : normalCssIcon} />
                        {
                            loadings && isNextOrPrev == "Prev" ? "Loading..." : "Previous"
                        }
                    </span>
                </div>

                <div onClick={nextHandler}>
                    <span className={loadings && isNextOrPrev == "Next" ? conditionalCss : normalCss}>
                        {
                            loadings && isNextOrPrev == "Next" ? "Loading..." : "Next"
                        }
                        <FontAwesomeIcon icon={faForward} className={loadings && isNextOrPrev == "Next" ? conditionalCssIcon : normalCssIcon} />
                    </span>
                </div>

            </div>

        </div>
    )
}

export default InternalTicketTicketOpenCustomer
