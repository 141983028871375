import React from 'react'
import Not_Found_Data from '../../Not_Found_Data'
import InformationChip from './InformationChip'
import uuid from 'react-uuid';
import QuickMessage from '../ChatButtons/QuickMessage'
import clsx from 'clsx';
import "./custom.css"

const QuickReply = ({ quickResponseDataFilter }: any) => {
    return (
        <>
            <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
                <div
                    // style={{ maxHeight: quickResponseDataFilter?.length !== 0 ? "550px" : "" }}
                    className={clsx('scroll-y me-n5 pe-5  max mb-4')}
                    data-kt-element='messages'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                    data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
                >
                    <div className="accordion accordion-icon-toggle mt-3" id={`kt_accordion_1`}>
                        {
                            quickResponseDataFilter?.length > 0 ? quickResponseDataFilter?.map((items: any, index: number) => {
                                return (<>
                                    <div className="px-4 margins " key={uuid()}>
                                        <div
                                            className="accordion-header py-1"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#kt_accordion_1_item_${index}`}
                                        >
                                            <div className=' d-flex align-items-center bg-light-secondary rounded p-2' >
                                                <div className='flex-grow-1 me-2'>
                                                    <span className='svg-icon svg-icon-primary me-2'>
                                                        <i className="bi bi-ticket-detailed text-primary"></i>
                                                    </span>
                                                    <span className='fw-bold text-primary text-hover-dark fs-7'>
                                                        {items?.title}
                                                    </span>
                                                    <span className='text-muted fw-semibold d-block'>
                                                        <InformationChip SelectedString={items?.ticketstatus?.name} classes={"primary"}></InformationChip>
                                                        <p>{items?.msg}</p>                                        </span>
                                                </div>
                                                <span className='fw-bold fs-9 text-dark py-1 text-end mb-1'>
                                                    <span className='text-danger'>
                                                        <QuickMessage classes={"primary"} msg={items?.msg}   ></QuickMessage>
                                                    </span>
                                                    <br />
                                                    <span className='text-danger mb-2'>{items?.ticketquickresponsecategory?.name} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })
                                :
                                <Not_Found_Data headerTitle={"Search Result Not Found"} size={25}></Not_Found_Data>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default QuickReply