import React from 'react'
import IsSelectBox from '../DynamicFilteration/components/IsSelectBox'

const TextFilter = ({ onChangeHandler, payload, keyName = "", index, isData, filters, removeFilter, timestamp }: any) => {
    return (
        <>

            <IsSelectBox onChangeHandler={onChangeHandler} payload={payload} index={index} keys={keyName} isData={isData} filters={filters} removeFilter={removeFilter} />

            <div className='row mb-1' key={index+1}>
                <div className='col-12'>
                    <input
                        type='text'
                        style={{ height: '38px' }}
                        value={payload?.filterData[keyName]?.value}
                        name='value'
                        placeholder='Type Here...'
                        onChange={(event: any) =>
                            onChangeHandler(event.target, keyName, 'value', false, timestamp)
                        }
                        className='w-100  height-100 form-control me-2 align-start text-muted'
                    />
                </div>
               
            </div>
        </>
    )
}

export default TextFilter
