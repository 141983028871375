import { useState } from "react";

function useOwnMoinState(initialValue?: any) {
  const [state, setState] = useState(initialValue);
  const updateState = (newValue: any) => {
    if (typeof newValue === 'function') {
      if(newValue(initialValue) == undefined){
        newValue(initialValue)
      }else{
        setState(newValue(initialValue))
      }
    } else {
      setState(newValue);
    }
  };
  
  return [state, updateState];
}

export default useOwnMoinState



/// moin custom hook