import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { getCurrentFilterHandler, initialPayload, operators, removeFilterHandler } from '../../../../consts/DynamicFilteration/components/CommonData';
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints';
import { getRequest } from '../../../../consts/Server/Requests';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns } from '../../../../consts/components/Utility';
import { DynamicFilter, FilterHandler } from '../../../../consts/DynamicFilteration/FilterHandler';
import { KeywiseComponents } from '../../../../consts/DynamicFilteration/KeywiseComponents';
import { AllFielSearch } from '../../../../consts/FieldSearch/AllFieldSearch';

const SubAreaMapListBox = ({markerData}:any) => {
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload(false, {}))
  React.useEffect(() => {
    const updatedData = AllFielSearch(markerData, search)
    setfilterData(updatedData)
  }, [search])


  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await getRequest(URL_EndPoints(null)?.getUniqueUserHashList, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.champApprovalList)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]:  event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {

    // setTotalRows(10)
    await InvokedRendered(handlePagination("", sortby, direction, 1, perPageSize), setPending)

  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  // handle pagination here 

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    InvokedRendered(handlePagination("", column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination("", sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setPerPageSize(newPerPageSize)
  };

  return (
    <>
      <div>
        <DataTable
          title="Sub Area List"
          progressPending={pending}
          columns={reorderColumns(columns(), "subAreaMapList")} 
          data={filterData} 
          fixedHeader   
          fixedHeaderScrollHeight='auto'   
          selectableRowsHighlight 
          highlightOnHover  
          subHeader  
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          subHeaderComponent={
            <>
              <div className='w-100 d-flex justify-content-between mb-3 '>
                <div className='d-flex align-items-start justify-content-start '>
                  <input
                    type="text"
                    placeholder='Search Here'
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                    className='w-100 form-control align-start'
                  />
                </div>
              </div>
            </>
          }
        />
      </div>

    </>
  )
}

export default SubAreaMapListBox
