import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { QueryParams } from '../../../consts/Server/QueryParams'


const shouldRenderSubreason = (StatusUpdateDataList: any,  reasonId: any) => StatusUpdateDataList?.find((ele: any) => ele?.id === reasonId && ele?.sub_reason_exists)?.name;

const VisitorStatusForm = ({ FilteredData, setStoreRowP2p = null, row }: any) => {
    // const dispatch = useDispatch()
    const StatusUpdatedData = useSelector((store: any) => store.VisitorDataReducer.visitorStatuses)
    const [StatusUpdateDataList, setStatusUpdateDataList] = React.useState<any>([]);
    const [StatusUpdatedReason, setStatusUpdatedReason] = React.useState<any>(null);
    const [StatusUpdatedReasonName, setStatusUpdatedReasonName] = React.useState<any>({});
    const [subReason, setSubReason] = React.useState<any>(0);
    const [_subReasons, setSubReasons] = React.useState<any>(null);
    const [isSubreasonLoading, setIsSubreasonLoading] = useState(false);

    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    useEffect(() => {
        setStatusUpdateDataList(StatusUpdatedData)
    }, [StatusUpdatedData])
    const handleChange = (event: any, name: any) => {
        if (event === null) {
            name === "name" && setStatusUpdatedReason(null)
            name === "sub_status_id" && setSubReason(0);
        }
        else if(name === "sub_status_id") {
            setSubReason(event.value);
        }else {
            setStatusUpdatedReason(event.value)
        }
    }
    const handleVisitorStatus = async () => {
        setLoader(true)
        if (StatusUpdatedReason) {
            let payloads = { status_id: StatusUpdatedReason, customerid: FilteredData?.selectedId?.customerid, sub_status_id: subReason }
            const response = await postRequest(URL_EndPoints(null)?.updateVisitorStatus, payloads, setLoader)
            if (response?.data?.msg) {
                toast.success(response?.data?.msg)
                if (setStoreRowP2p) {
                    row['status_id'] = +StatusUpdatedReason
                    setStoreRowP2p(row)
                    setDisable(false)
                }
                FilteredData?.handleCloseForm()
            }
        } else {
            toast.error("Status id missing")
            FilteredData?.handleCloseForm()
        }
    }
    useEffect(() => {
        const updated = StatusUpdateDataList?.filter((ele: any) => ele?.id === FilteredData?.selectedId?.status_id)
        FilteredData?.selectedId?.status_id && setStatusUpdatedReason(FilteredData?.selectedId?.status_id)
        FilteredData?.selectedId?.status_id && setStatusUpdatedReasonName(updated?.length > 0 ? updated[0] : "")
    }, [StatusUpdateDataList, FilteredData?.selectedId?.status_id])

    
    useEffect(() => {
        const fetchSubreasons = async () => {
            setIsSubreasonLoading(true);
            let newQeueryParams = { ...QueryParams("getMasterSubReason", "?") }
            newQeueryParams.What += "reasonId="+StatusUpdatedReason;
            const res = await getRequest(URL_EndPoints(newQeueryParams)?.getMasterSubReason, null);
            res?.data?.data && setSubReasons(res?.data?.data);            
            res?.data?.data && setSubReason(res?.data?.data[0]?.id);
            setIsSubreasonLoading(false);
        }
        FilteredData?.show && StatusUpdatedReason && fetchSubreasons();
    }, [StatusUpdatedReason, FilteredData?.show])

    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Status</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select  Reason"}
                            SelectData={StatusUpdateDataList}
                            DynamicKey={"name"}
                            id={"id"}
                            name={"name"}
                            defaultData={{ label: StatusUpdatedReasonName?.name, value: StatusUpdatedReasonName?.id }}
                        />
                    </div>
                    
                    {shouldRenderSubreason(StatusUpdateDataList, StatusUpdatedReason) && <div className={`col-12  mb-1`}>
                            {isSubreasonLoading ? 
                            <div className='d-flex justify-content-center text-center w-100 border text-success'>         
                                <span className="spinner-border spinner-border-sm mt-2 me-3 fw-bold fs-2" role="status" aria-hidden="true"></span>
                                <span className='mt-1 fw-bold'>Loading...</span>
                            </div> : 
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChange}
                                HeaderTitle={"Select Sub Status"}
                                SelectData={_subReasons}
                                DynamicKey={"name"}
                                id={"id"}
                                name={"sub_status_id"}
                                defaultData={{ label: _subReasons?.length ? _subReasons[0]?.name : "", value: _subReasons?.length ? _subReasons?.id : 0 }}
                            />
                            }
                    </div>}

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={disable || loader ? true : false} onClick={() => handleVisitorStatus()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default VisitorStatusForm
