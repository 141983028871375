import {
  LOADING,
  SUCCESS,
  FAILURE,
  CHAMPS_CHAT_MESSAGES,
  CHAMPS_ALL_DATA,
  CHAMPS_WHATSAPP_MESSAGES,
  CHAMPS_WHATSAPP_TEMPLATE_MESSAGES,
  CHAT_SOURCE,
  CHAT_LOADER,
  CHAT_SUCCESS,
  CHAT_FAILURE,
  STATUS_DATA,
  TRAINING_TASK_LISTS,
  WHATS_APP_LOADER,
  WHATS_APP_SUCCESS,
  WHATS_APP_FAILURE,
  CHAT_BTN_BG_COLOR,
  STORE_ROW_DATA_FOR_CHAMP_DASHBOARD,
  SHOW_CHAMP_DASHBOARD_FROM_OUTSIDE,
} from './champsTypes'

const initState = {
  champChatMessages: {},
  champsHistoryData: {},
  champsWhatsappMessages: {},
  champsWhatsappTemplateMessages: {},
  trainingTaskLists: {},
  MessageSource: {
    CurrentMessageSourceRender: null,
    from: 'chatMessages',
  },
  loading: false,
  loader: false,
  what_loader: false,
  chatBtnBgColor: null,
  statusData: {
    adminData: [],
    supervisorData: [],
    ticketCategoryData: [],
    ticketSubCategoryData: [],
    ticketSourceData: [],
  },
  rowDataForChampDashboard: {},
  showChamp: {show: false, champId: null},
}
export const champsReducer = (state = initState, {type, payload}: any) => {
  switch (type) {
    case LOADING:
      return {...state, loading: payload}
    case SUCCESS:
      return {...state, loading: payload}
    case FAILURE:
      return {...state, loading: payload}
    case CHAT_LOADER:
      return {...state, loader: payload}
    case CHAT_SUCCESS:
      return {...state, loader: payload}
    case CHAT_FAILURE:
      return {...state, loader: payload}
    case WHATS_APP_LOADER:
      return {...state, what_loader: payload}
    case WHATS_APP_SUCCESS:
      return {...state, what_loader: payload}
    case WHATS_APP_FAILURE:
      return {...state, what_loader: payload}
    case CHAMPS_CHAT_MESSAGES:
      // return { ...state, champChatMessages: payload  }
      return {
        ...state,
        champChatMessages: {...state.champChatMessages, [payload.key]: payload?.response},
      }
    // case CHAMPS_ALL_DATA:
    //   return { ...state, champsHistoryData: payload }
    case CHAMPS_ALL_DATA:
      return {
        ...state,
        champsHistoryData: {...state.champsHistoryData, [payload.key]: payload?.response},
      }
    case CHAMPS_WHATSAPP_MESSAGES:
      return {
        ...state,
        champsWhatsappMessages: {...state.champsWhatsappMessages, [payload.key]: payload?.response},
      }
    case CHAMPS_WHATSAPP_TEMPLATE_MESSAGES:
      return {
        ...state,
        champsWhatsappTemplateMessages: {
          ...state.champsWhatsappTemplateMessages,
          [payload.key]: payload?.response,
        },
      }
    case CHAT_SOURCE:
      return {...state, MessageSource: payload}
    case STATUS_DATA:
      return {...state, statusData: payload}
    case TRAINING_TASK_LISTS:
      return {
        ...state,
        trainingTaskLists: {...state.trainingTaskLists, [payload.key]: payload?.response},
      }
    case CHAT_BTN_BG_COLOR:
      return {...state, chatBtnBgColor: payload}
    case STORE_ROW_DATA_FOR_CHAMP_DASHBOARD:
      return {
        ...state,
        rowDataForChampDashboard: {
          ...state.rowDataForChampDashboard,
          [payload.key]: payload?.response,
        },
      }
    case SHOW_CHAMP_DASHBOARD_FROM_OUTSIDE:
      return {
        ...state,
        showChamp: payload,
      }
    default:
      return state
  }
}

// const champChatMessages = useSelector((store:any)=>store.champsReducer.champChatMessages)
// const champsHistoryData = useSelector((store:any)=>store.champsReducer.champsHistoryData)
// const champsWhatsappMessages = useSelector((store:any)=>store.champsReducer.champsWhatsappMessages)
// const champsWhatsappTemplateMessages = useSelector((store:any)=>store.champsReducer.champsWhatsappTemplateMessages)
// const MessageSource = useSelector((store:any)=>store.champsReducer.MessageSource)
// const loading = useSelector((store:any)=>store.champsReducer.loading)
// const loader = useSelector((store:any)=>store.champsReducer.loader)
// const statusData = useSelector((store:any)=>store.champsReducer.statusData)
// const trainingTaskLists = useSelector((store:any)=>store.champsReducer.trainingTaskLists)
// const chatBtnBgColor = useSelector((store:any)=>store.champsReducer.chatBtnBgColor)
// const what_loader = useSelector((store:any)=>store.champsReducer.what_loader)
// const rowDataForChampDashboard = useSelector((store:any)=>store.champsReducer.rowDataForChampDashboard)
// const showChamp = useSelector((store:any)=>store.champsReducer.showChamp)
