export const columns = () => [

    {
        name: "CUSTOMER NAME",
        selector: (row: any) => <>{row?.attendence?.customerData?.first_name || row?.attendence?.customerData?.last_name ? row?.attendence?.customerData?.first_name + " " + row?.attendence?.customerData?.last_name : "NA"}</>,
        sortField: 'first_name',
        sortable: true,
        id: 1,
        width: "200px"


    },
    {
        name: "AMOUNT",
        selector: (row: any) => <>{row?.job_Points}</>,
        sortField: 'job_Points',
        sortable: true,
        id: 2,
    },
    {
        name: "DEDUCTION DATE",
        selector: (row: any) => <>{row?.deduction_date}</>,
        sortField: 'first_name',
        sortable: true,
        id: 3,
    },
    {
        name: "SERVICE DATE",
        selector: (row: any) => <>{row?.serviceDate}</>,
        sortField: 'first_name',
        sortable: true,
        id: 4,
    },
    {
        name: "REASON",
        selector: (row: any) => <>{row?.reason_data?.name  ?? "NA"}</>,
        sortField: 'first_name',
        sortable: true,
        id: 5,
    },
    {
        name: "FINE NAME",
        selector: (row: any) => <>{row?.deduction_reason?.name ?? "NA"}</>,
        sortField: 'first_name',
        sortable: true,
        id: 6,
    },




];
