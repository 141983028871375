import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Swal from 'react-sweetalert2'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { Modal } from 'react-bootstrap';
import SingleSearchCategory from '../../../consts/Select/SingleSearchCategory';
import CustomerDefaultSelect from '../../../consts/Select/CustomerDefaultSelect';
import { QueryParams } from '../../../consts/Server/QueryParams';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import SingleSelectSearchDetailsDefault from '../../../consts/Select/SingleSelectSearchDetailsDefault';


const AddChamprequirementType = ({ closeBtn, show, requirementType, priorityData, locationData, InvokedRendered, areaListData, supervisorListData }: any) => {
    const [loader, setLoader] = useState<any>(false)
    const [traininglocationData, setTraininglocationData] = useState<any>([])
    const [trainingLoader, setTrainingLoader] = React.useState<any>(false)
    const [showConfirm, setShowConfirm] = useState(false)

    const [payload, setPayload] = useState<any>({
        "requirement_id": null,
        "location_id": null,
        "priority": null,
        "min_payout": null,
        "referral_amount": null,
        "champ_amount": null,
        "replacement": null,
        "assigned_supervisor": null,
        "area_id": null,
        "req_count": 1,

    })

    async function getAllTrainingLocations(areaNameId: any) {
        setTrainingLoader(true)
        let newQeueryParams = { ...QueryParams("getAllTrainingLocations", "?") }
        newQeueryParams.What += `area_id=${areaNameId}`
        const result2 = await getRequest(URL_EndPoints(newQeueryParams)?.getAllTrainingLocations, setTrainingLoader)
        setTraininglocationData(result2?.data?.data)
    }


    useEffect(() => {
        payload?.area_id && getAllTrainingLocations(payload?.area_id)
    }, [payload?.area_id])

    const addTypes = async () => {
        setLoader(true)
        if (payload?.requirement_id && payload?.location_id && payload?.priority && payload?.min_payout) {
            const response = await postRequest(URL_EndPoints(null)?.addChampRequirement, payload, setLoader)
            if (response?.data?.status === 200) {
                InvokedRendered()
                toast.success(response?.data?.msg, { position: "top-center" })
                setLoader(false)
                closeBtn()

            } else {
                toast.error(response?.data?.msg ? response?.data?.msg : "Seems server side problem", { position: "top-center" })
                setLoader(false)
                closeBtn()


            }
            setLoader(false)
        }
        else {
            toast.error("Something is missing", { position: "top-center" })
            setLoader(false)

        }
        setShowConfirm(false)
    }
    // to close pop up form 
    const handleClose = () => {
        closeBtn()
    }

    const OnchangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : null })

    }


    // search filter  
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchDataCleaner, setsearchDataCleaner] = useState<any[]>([])
    const [storeSeachKeywords, setstoreSeachKeywords] = useState<any>("")


    async function getSearchDataBykeyword(storeSeachKeywords: any) {
        setIsLoading(true)
        const response: any = await postRequest(URL_EndPoints()?.getChampsList, {
            keyword: storeSeachKeywords,
            cleaner_id: 0,
        }, setIsLoading);

        let newData = response?.data?.data?.map((ele: any) => {
            ele.first_name = ele?.first_name ?? "NA"
            ele.last_name = ele?.last_name ?? "NA"
            ele.phone = ele?.phone ?? "NA"
            return ele
        })

        newData && setsearchDataCleaner(newData);
        setIsLoading(false)
    }

    const handleSelectChange = (event: any, name: any) => {
        setPayload({ ...payload, ["replacement"]: event ? event?.value : null })
    }

    useEffect(() => {
        let x = setTimeout(() => {
            storeSeachKeywords && getSearchDataBykeyword(storeSeachKeywords)
        }, 2000)
        return () => {
            setIsLoading(false)
            clearTimeout(x)
        }
    }, [storeSeachKeywords])

    // search filter  


    return (


        <Modal show={show} onHide={handleClose} dialogClassName="modal-small">
            <Modal.Header closeButton>
                <Modal.Title>Add Champ Requirement Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Swal 
                title="Are you sure?"
                text={`Do you want to create ${payload?.req_count} requirements for this dataset?`}
                icon="warning"
                showCancelButton={true}
                confirmButtonColor="#3085d6"
                cancelButtonColor="#d33"
                confirmButtonText="Confirm"    
                show={showConfirm}
                onConfirm={addTypes}
                onResolve={() => setShowConfirm(false)}                  
            />
                <div className="row mb-3">
                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'> Requirement Type</span>

                        <SingleSearchCategory
                            handleChangeInputData={OnchangeHandler}
                            HeaderTitle='Select Requirement Type'
                            SelectData={requirementType}
                            DynamicKey={'name'}
                            id={'id'}
                            name='requirement_id'
                        ></SingleSearchCategory>

                    </div>


                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'>Select Area Manager </span>

                        <SingleSelectSearchDetailsDefault
                            handleChangeInputData={OnchangeHandler}
                            HeaderTitle='Select Area Manager'
                            SelectData={supervisorListData}
                            DynamicKey={'first_name'}
                            DynamicKey2={'last_name'}
                            DynamicKey3={'phone'}
                            id={'id'}
                            name='assigned_supervisor'
                            defaultData={null}
                        ></SingleSelectSearchDetailsDefault>


                    </div>

                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'>Select Area name</span>

                        <SingleSearchCategory
                            handleChangeInputData={OnchangeHandler}
                            HeaderTitle='Select Area Name'
                            SelectData={areaListData}
                            DynamicKey={'areaname'}
                            id={'id'}
                            name='area_id'
                        ></SingleSearchCategory>
                    </div>

                    {
                        trainingLoader ? <IsLoading /> : <div className="col-12 mb-2">
                            <span className='fw-bold mb-2 text-muted'>Location 👉 Note : First select area name </span>

                            <SingleSearchCategory
                                handleChangeInputData={OnchangeHandler}
                                HeaderTitle='Select Location'
                                SelectData={traininglocationData}
                                DynamicKey={'locationname'}
                                id={'id'}
                                name='location_id'
                            ></SingleSearchCategory>

                        </div>
                    }



                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'>Priority</span>
                        <select
                            name='priority'
                            aria-label='Select a Timezone'
                            data-control='select2'
                            data-placeholder='Period'
                            className='form-select form-select-sm '
                            value={payload?.priority}
                            onChange={(event) => setPayload({ ...payload, [event?.target?.name]: +event?.target?.value })}
                        >
                            < option >  Select Priority</option>
                            {
                                priorityData?.map((ele: any) => {
                                    return (
                                        <>
                                            < option value={ele.id}>{ele?.priority}</option>
                                        </>
                                    )
                                })
                            }
                        </select>


                    </div>


                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'>Min Payout</span>

                        <input
                            placeholder='Enter Min Payout..'
                            name='min_payout'
                            type='text'
                            value={payload?.min_payout}
                            className='form-control  mb-3 mb-lg-0'
                            autoComplete='off'
                            onChange={(event) => setPayload({ ...payload, [event?.target?.name]: +event?.target?.value })}
                        />

                    </div>

                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'>Referral Amount</span>

                        <input
                            placeholder='Enter Referral Amount..'
                            name='referral_amount'
                            type='number'
                            value={payload?.referral_amount}
                            className='form-control  mb-3 mb-lg-0'
                            autoComplete='off'
                            onChange={(event) => setPayload({ ...payload, [event?.target?.name]: +event?.target?.value })}
                        />

                    </div>

                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'>Champ Amount</span>

                        <input
                            placeholder='Enter Champ Amount..'
                            name='champ_amount'
                            type='number'
                            value={payload?.champ_amount}
                            className='form-control  mb-3 mb-lg-0'
                            autoComplete='off'
                            onChange={(event) => setPayload({ ...payload, [event?.target?.name]: +event?.target?.value })}
                        />

                    </div>

                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'>Replacement for Champ</span>

                        {
                            <CustomerDefaultSelect
                                handleChangeInputData={handleSelectChange}
                                HeaderTitle='Replacement for Champ'
                                SelectData={searchDataCleaner}
                                DynamicKey={'first_name'}
                                DynamicKey2={'last_name'}
                                DynamicKey3={'phone'}
                                id={'id'}
                                name='replacement'
                                defaultData={null}
                                setSeacrch={setstoreSeachKeywords}
                                isLoading={isLoading}
                            ></CustomerDefaultSelect>}

                    </div>

                    <div className="col-12 mb-2">
                        <span className='fw-bold mb-1 text-muted'>Number of requirements</span>
                        <input
                            placeholder='Enter number of requirements'
                            name='req_count'
                            type='text'
                            value={payload?.req_count}
                            className='form-control mb-3 mb-lg-0'
                            autoComplete='off'
                            onChange={(event) => setPayload({ ...payload, [event?.target?.name]: +event?.target?.value })}
                        />
                    </div>


                </div>


                <div className='d-flex flex-end justify-content-end col-12'>
                    <button
                        className={`btn btn-sm text-${loader ? "dark" : "white"} btn-${loader ? "success" : "primary"} mb-5 `}
                        onClick={() => setShowConfirm(true)}
                        disabled={loader}

                    >
                        {loader ? "Adding..." : "Add Requirement Type "}

                    </button>
                </div>
            </Modal.Body>
        </Modal >







    )
}
export default AddChamprequirementType
