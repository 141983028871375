
import React, { useEffect, useState } from 'react'
import "./Cleaner.css"
import Not_Found from '../../../../consts/Not_Found'
import IsLoading from '../../../../consts/IsLoading/IsLoading'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import { getRequest, postRequest } from '../../../../consts/Server/Requests'
import { image_Cloudfront } from '../../../../consts/components/Utility'

const ShowAddressMap = ({ jobId }: any) => {
  const [cityData, setCityData] = useState([])
  const [addressData, setAddressData] = useState<any>([])
  const [Loader, setLoader] = useState<any>(false)
  const [center, setCenters] = useState<any>({
    lat:  12.9121181,
    lng:  77.6445548,
  });
  const [radius, setRadius] = useState<any>(1000)
  async function loadInitialData() {
    setLoader(true)
    const result = await getRequest(URL_EndPoints()?.getcity, null)
    setCityData(result?.data?.data)
    setLoader(false)
  }

  useEffect(() => {
    loadInitialData()
  }, [])

  async function loadaddressData() {
    setLoader(true)
    const result = await postRequest(URL_EndPoints()?.getAllLocationByJobID, {
      "jobId": jobId,
    }, setLoader)
    setLoader(false)
    let newArr = [result?.data?.data]
    let attendanceAddress = result?.data?.data ? result?.data?.data : {}
    let BookingAddress = result?.data?.data ? result?.data?.data?.subscriptionMonth : {}
    let AppAddressAvailable = result?.data?.data ? result?.data?.data?.customerData?.ctAddresses : []
    newArr.push(BookingAddress, ...AppAddressAvailable)
        setCenters(
      {
        lat: result?.data?.data?.latitude > 0 ? +result?.data?.data?.latitude: 12.9121181,
        lng: result?.data?.data?.longitude > 0 ? +result?.data?.data?.longitude : 77.6445548,
      }
    )
    setAddressData(newArr || [])
  }


  useEffect(() => {
    jobId && loadaddressData()
    loadaddressData()
  }, [jobId])

  let brnchUrl = `${image_Cloudfront}351f2fa6-07b0-4668-9469-dac59255d3d0.png`
  let prevMarker: any = null; 
 

  useEffect(() => {
    let activeInfoWindow: any = null
    function rendered() {

      const map = new window.google.maps.Map(document.getElementById('map') as any, {
        center: center,
        zoom: 12
      });

      let allMarkers: any = []
      addressData.forEach((markerInfo: any, index: any) => {
        const smallMarkerIcon = {
          url: index == 0 ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png" :
            index == 1 ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png" :
              "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
          scaledSize: new window.google.maps.Size(30, 30),
        };

        const benchIcon = {
          url: brnchUrl,
          scaledSize: new window.google.maps.Size(20, 30),
        };

        const marker: any = new window.google.maps.Marker({
          position: {
            lat: +markerInfo?.latitude,
            lng: +markerInfo?.longitude,
          },
          map: map,
          title: "",
          icon: markerInfo?.private_tag?.toLowerCase()?.includes("bench") ? benchIcon : smallMarkerIcon,
          zIndex: 1,
        });

        marker.set('addressData', markerInfo);
        allMarkers.push(marker)
        marker.addListener('click', function () {


          if (prevMarker) {
            const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
              prevMarker.getPosition(),
              marker.getPosition()
              )/1000;
            if (activeInfoWindow) {
              activeInfoWindow.close();
            }
            let infowindow: any = new window.google.maps.InfoWindow({
              content: `Distance : ${distance.toFixed(2)} - KM`
            });
            
            infowindow.open(map, marker);
            activeInfoWindow = infowindow;
          }
          prevMarker = marker; 
          
        });
      });

      window.google.maps.event.addListener(map, 'zoom_changed', function () {

        var zoom: any = map.getZoom();

        allMarkers.forEach(function (marker: any) {

          if (zoom > 13) {
            marker.setIcon(
              {
                url: marker.getIcon().url, 
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(34, marker.getIcon().url == brnchUrl ? 45 : 34) //changes the scale
              }
            )
          } else if (zoom == 13) {
            marker.setIcon(
              {
                url: marker.getIcon().url, 
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(29, marker.getIcon().url == brnchUrl ? 40 : 29) //changes the scale
              }
            )
          } else {
            marker.setIcon(
              {
                url: marker.getIcon().url,
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(20, marker.getIcon().url == brnchUrl ? 30 : 20)//changes the scale
              }
            )
          }
        })
      });

    }

    const x = setTimeout(() => {
      addressData?.length > 0 && rendered()
    }, 1000);
    return () => {
      clearTimeout(x)
    }

  }, [addressData,center, radius])


  return (
    <>
      <div>

        <div className="d-flex">
          <span className='border p-1 bg-white rounded me-3 mt-2'> Attendance Address - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> Booking Address - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/green-dot.png" alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> App Address - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/yellow-dot.png" alt="" /></span>
        </div>
        <br />
        <br />
        {
          addressData?.length == 0 && !Loader ? <Not_Found headerTitle={addressData?.length === 0 ? 'Select City To See Map' : "Select City To See Map"}></Not_Found> :
            Loader ? <IsLoading /> :
              <div id="map" style={{ height: 'calc(100vh - 250px)', width: '100%' }} />
        }

      </div>
    </>
  )
}
export default ShowAddressMap










