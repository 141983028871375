const initState = {
    quickReplyMsg: "",
    QuicKReplyRender: 0,


}
export const QuickReplyReducer = (state = initState, { type, payload }: any) => {
  
    switch (type) {
      
        case "QUICK_REPLY_MESSAGE":
            return { ...state, quickReplyMsg: payload.msg, QuicKReplyRender: payload.Render }
    
        default:
            return state
    }
}


// const QuickReplyMessageInput = useSelector((store)=>store.QuickReplyReducer.quickReplyMsg)