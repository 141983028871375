import React from 'react'
import IsSelectBox from '../DynamicFilteration/components/IsSelectBox'
import SingleSearchableFilter from './SingleSearchableFilter'

const SelectFilter = ({ onChangeHandler, payload, storeFilterList, keyName, index, isData, filters, removeFilter, setSeacrch, isLoadingFilter = null, timestamp, multiVal , isSearchable}: any) => {

    return (
        <>
            <IsSelectBox onChangeHandler={onChangeHandler} payload={payload} index={index} keys={keyName} isData={isData} filters={filters} removeFilter={removeFilter} />
            <div key={index}>
                <SingleSearchableFilter
                    handleChangeInputData={onChangeHandler}
                    HeaderTitle='Filter By'
                    SelectData={storeFilterList[keyName] ? storeFilterList[keyName] : []}
                    DynamicKeys={filters?.valueConfig?.apiConfig?.displayFields}
                    id={filters?.valueConfig?.apiConfig?.displayValue}
                    name={keyName}
                    targetValue={"value"}
                    setSeacrch={setSeacrch}
                    isLoading={isLoadingFilter}
                    timestamp={timestamp}
                    defaultData={payload?.filterData[keyName] && (payload?.filterData[keyName][keyName + "_value"] ? payload?.filterData[keyName][keyName + "_value"] : [])}
                    index={index}
                    multiVal={multiVal}
                    isSearchable={isSearchable}
                    filters={filters}
                ></SingleSearchableFilter>
            </div>

        </>
    )
}



export default SelectFilter
