import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useReducer, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import IsLoading from '../../consts/IsLoading/IsLoading'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { initial_states_ticket_dashboard, ticketDashboardStates } from '../admins/ad_states/ticketDashboardStates'
import { CardCountWidget } from './CardCountWidget'
import { dummyData } from './dummyData'
import MomentDate from '../../consts/MomentDate'
import NewTicketsList from '../NewTicketsList/NewTicketsList'
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu'
import { NewTicketDashboardFiltration } from './NewTicketDashboardFiltration'
import CustomerMissedCallList from '../CustomerMissedCallLists/CustomerMissedCallList'
const addIsTrueValue = (filterDataList: any, keyName?: any) => {
	if (Object.keys(filterDataList[keyName])?.length > 0) {
		filterDataList[keyName].isFilter = true
	}
}
export const DashboardContainer = () => {
	const [ticketStates, setLocalStateDispatch] = useReducer(ticketDashboardStates, initial_states_ticket_dashboard);
	const [key, setKey] = useState<number>(1)
	const handleSelect = (key: any) => {
		setKey(key);
	}

	const [payload, setPayload] = useState<any>({
		adminUserId: null,
		areaManagerId: null,
		agent_id: null,
		statusId: [],
		categoryId: null,
		campaignId: null,
		is_visible: "",
		tags: null,
		dateKey: "",
		startDate: "",
		endDate: "",
	})

	async function contentApiDataLoad() {
		setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
		payload.is_visible = payload?.is_visible
		const newData = await postRequest(URL_EndPoints()?.ticketDashboardData, payload, null)
		setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: newData?.data?.preProcessedData })
		setLocalStateDispatch({ type: "RTL_PENDING", payload: false })

	}

	async function initialData() {

		const getSupervisorListData = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
		setLocalStateDispatch({ type: "RTL_SUPER_VISOR_LIST_DATA", payload: getSupervisorListData?.data?.data })

		const getAdminListData = await getRequest(URL_EndPoints(null)?.getAdminList, null)
		setLocalStateDispatch({ type: "RTL_ADMIN_LIST_DATA", payload: getAdminListData?.data?.data })

		const getcategoryListData = await getRequest(URL_EndPoints(null)?.getTicketCategories, null)
		setLocalStateDispatch({ type: "RTL_CATEGORY_LIST_DATA", payload: getcategoryListData?.data?.data })

		const getStatusListData = await getRequest(URL_EndPoints(null)?.getTicketStatuses, null)
		setLocalStateDispatch({ type: "RTL_STATUS_LIST_DATA", payload: getStatusListData?.data?.data })

		const getTicketCampaigns = await getRequest(URL_EndPoints(null)?.getTicketCampaigns, null)
		setLocalStateDispatch({ type: "RTL_CAMPAIGN_LIST_DATA", payload: getTicketCampaigns?.data?.data })
	}

	useEffect(() => {
		contentApiDataLoad()
		initialData()
	}, [])

	const handleSearch = async () => {

		setLocalStateDispatch({ type: "TDL_TICKET_FROM", payload: payload?.startDate })
		setLocalStateDispatch({ type: "TDL_TICKET_TO", payload: payload?.endDate })
		setLocalStateDispatch({ type: "TDL_FILTER_AREA_MANAGER_ID", payload: payload?.areaManagerId })
		setLocalStateDispatch({ type: "TDL_FILTER_ADMIN_ID", payload: payload?.adminUserId })
		setLocalStateDispatch({ type: "TDL_FILTER_CATEGORY_ID", payload: payload?.categoryId })
		setLocalStateDispatch({ type: "TDL_FILTER_IS_VISIBLE", payload: payload?.is_visible })
		setLocalStateDispatch({ type: "TDL_FILTER_TAGS", payload: payload?.tags })
		setLocalStateDispatch({ type: "TDL_FILTER_STATUS_ID", payload: payload?.statusId })
		contentApiDataLoad()
	}

	const handleTabClick = (filtereData: any) => {
		let {
			tabKey = 2,
			ticketfrom,
			ticketto,
			categoryid,
			campaignId,
			status,
			assignedadminuserid,
			assignedsupervisorid,
			agent_id,
			tags,
			ticketRepliesUserid,
			callback_agent_id
		} = filtereData

		let filterDataList: any = {
			newTicketList: {},
			customerMissedCallFilter: {},
			isFilter:true,
		}

		if (tabKey == 2) {

			if (assignedsupervisorid || assignedsupervisorid == 0) {
				filterDataList.newTicketList["assignedsupervisorid"] = { value: assignedsupervisorid, multiVal: false, operator: "==", isDate: false, timestamp: false }
			}

			if ((!assignedsupervisorid && payload?.areaManagerId && !ticketRepliesUserid) || (assignedsupervisorid == undefined && payload?.areaManagerId == 0 && !ticketRepliesUserid)) {
				if (payload?.areaManagerId || payload?.areaManagerId == 0) {
					filterDataList.newTicketList["assignedsupervisorid"] = { value: payload?.areaManagerId, multiVal: false, operator: "==", isDate: false, timestamp: false }
				}
			}

			if (assignedadminuserid || assignedadminuserid == 0) {
				filterDataList.newTicketList["assignedadminuserid"] = { value: assignedadminuserid, multiVal: false, operator: "==", isDate: false, timestamp: false }
			}

			if ((!assignedadminuserid && payload?.adminUserId && !ticketRepliesUserid) || (assignedadminuserid == undefined && payload?.adminUserId == 0 && !ticketRepliesUserid)) {
				if (payload?.adminUserId || payload?.adminUserId == 0) {
					filterDataList.newTicketList["assignedadminuserid"] = { value: payload?.adminUserId, multiVal: false, operator: "==", isDate: false, timestamp: false }
				}
			}

			if (ticketfrom && !ticketRepliesUserid) {
				filterDataList.newTicketList[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"] = { from: ticketfrom, to: filterDataList.newTicketList[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"]?.to ?? "", multiVal: false, operator: "between", isDate: true, timestamp: true }
			}

			if (!ticketfrom && payload?.startDate && !ticketRepliesUserid) {
				if (payload?.startDate) {
					filterDataList.newTicketList[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"] = { from: payload?.startDate, to: filterDataList.newTicketList[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"]?.to ?? "", multiVal: false, operator: "between", isDate: true, timestamp: true }
				}
			}

			if (ticketto && !ticketRepliesUserid) {
				filterDataList.newTicketList[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"] = { from: filterDataList.newTicketList[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"]?.from ?? "", to: ticketto, multiVal: false, operator: "between", isDate: true, timestamp: true }
			}

			if (!ticketto && payload?.endDate && !ticketRepliesUserid) {
				if (payload?.endDate) {
					filterDataList.newTicketList[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"] = { from: filterDataList.newTicketList[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"]?.from ?? "", to: payload?.endDate, multiVal: false, operator: "between", isDate: true, timestamp: true }
				}
			}

			if (payload?.startDate && ticketRepliesUserid) {
				filterDataList.newTicketList["$ticketReplies.createdAt$"] = { from: payload?.startDate, to: filterDataList.newTicketList["$ticketReplies.createdAt$"]?.to ?? "", multiVal: false, operator: "between", isDate: true, timestamp: true }
			}

			if (payload?.endDate && ticketRepliesUserid) {
				filterDataList.newTicketList["$ticketReplies.createdAt$"] = { from: filterDataList.newTicketList["$ticketReplies.createdAt$"]?.from ?? "", to: payload?.endDate, multiVal: false, operator: "between", isDate: true, timestamp: true }
			}

			if (ticketRepliesUserid) {
				filterDataList.newTicketList["$ticketReplies.userid$"] = { value: ticketRepliesUserid, multiVal: false, operator: "==", isDate: false, timestamp: false }
			}

			if (agent_id || agent_id == 0) {
				filterDataList.newTicketList["agent_id"] = { value: agent_id, multiVal: false, operator: "==", isDate: false, timestamp: false }
			}

			if ((!agent_id && payload?.agent_id) || (agent_id == undefined && payload?.agent_id == 0)) {
				if (payload?.agent_id || payload?.agent_id == 0) {
					filterDataList.newTicketList["agent_id"] = { value: payload?.agent_id, multiVal: false, operator: "==", isDate: false, timestamp: false }
				}
			}

			if (tags) {
				filterDataList.newTicketList["tags"] = { value: [tags], multiVal: true, operator: "==", isDate: false, timestamp: false }
			}

			if (!tags && payload?.tags) {
				if (payload?.tags) {
					filterDataList.newTicketList["tags"] = { value: [payload?.tags], multiVal: true, operator: "==", isDate: false, timestamp: false }
				}
			}

			if (categoryid || categoryid == 0) {
				filterDataList.newTicketList["category_id"] = { value: [categoryid], multiVal: true, operator: "==", isDate: false, timestamp: false }
			}

			if (!categoryid && payload?.categoryId || categoryid == undefined && payload?.categoryId == 0) {
				filterDataList.newTicketList["category_id"] = { value: [payload?.categoryId], multiVal: true, operator: "==", isDate: false, timestamp: false }
			}

			if (campaignId || campaignId == 0) {
				filterDataList.newTicketList["campaign_id"] = { value: [campaignId], multiVal: true, operator: "==", isDate: false, timestamp: false }
			}

			if (!campaignId && payload?.campaignId || campaignId == undefined && payload?.campaignId == 0) {
				filterDataList.newTicketList["campaign_id"] = { value: [payload?.campaignId], multiVal: false, operator: "==", isDate: false, timestamp: false }
			}

			if (payload?.is_visible) {
				filterDataList.newTicketList["is_visible"] = { value: payload?.is_visible, multiVal: false, operator: "==", isDate: false, timestamp: false }
			}

			if (status || status == 0) {
				filterDataList.newTicketList["status"] = { value: [status], multiVal: true, operator: "==", isDate: false, timestamp: false }
			}

			if (!status && payload?.statusId?.length) {
				filterDataList.newTicketList["status"] = { value: payload?.statusId, multiVal: true, operator: "==", isDate: false, timestamp: false }
			}
	
			// addIsTrueValue(filterDataList, "newTicketList")
			setLocalStateDispatch({ type: "TICKET_LIST_OPEN_FILTER_DATA", payload: filterDataList })
			setLocalStateDispatch({ type: "TDL_CATEGORY", payload: categoryid })
			setLocalStateDispatch({ type: "TDL_STATUS", payload: status })
		}
		
		if (tabKey == 3) {
			if (callback_agent_id || callback_agent_id == 0) {
				filterDataList.customerMissedCallFilter["callback_agent_id"] = { value: [callback_agent_id], multiVal: true, operator: "==", isDate: false, timestamp: false }
			}

			if (payload?.startDate) {
				if (payload?.startDate) {
					filterDataList.customerMissedCallFilter[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"] = { from: payload?.startDate, to: filterDataList.customerMissedCallFilter[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"]?.to ?? "", multiVal: false, operator: "between", isDate: true, timestamp: true }
				}
			}

			if (payload?.endDate) {
				if (payload?.endDate) {
					filterDataList.customerMissedCallFilter[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"] = { from: filterDataList.customerMissedCallFilter[payload?.dateKey == "updatedAt" ? "updatedAt" : "createdAt"]?.from ?? "", to: payload?.endDate, multiVal: false, operator: "between", isDate: true, timestamp: true }
				}
			}
		
			addIsTrueValue(filterDataList, "customerMissedCallFilter")
			setLocalStateDispatch({ type: "TICKET_LIST_OPEN_FILTER_DATA", payload: filterDataList })
			setLocalStateDispatch({ type: "TDL_CATEGORY", payload: categoryid })
			setLocalStateDispatch({ type: "TDL_STATUS", payload: status })
		}

		setKey(tabKey);
	}

	const handleChangeInput = (event: any, name: any) => {
		const multiSelectFields = ['statusId', 'campaignId']

		if (!event) {
			setPayload({ ...payload, [name]: multiSelectFields.includes(name) ? [] : null })
		}
		else if (multiSelectFields.includes(name)) {
			setPayload({ ...payload, [name]: event?.map((ele: any) => ele?.value) })
		} else {
			setPayload({ ...payload, [name]: event?.value })
		}
	}

	return (
		<>
			<div className="">
				{
					<>
						<Tabs activeKey={key} onSelect={handleSelect} id="controlled-tab-example">
							<Tab eventKey={1} title="Daily Ticket Dashboard">
								<div className="row m-2 align-items-center">
									<div className="d-flex justify-content-start refresh-custom mr-4">
										<div onClick={handleSearch}>
											Refresh <FontAwesomeIcon icon={faArrowsRotate} />
										</div>
									</div>
								</div>
								{
									<>
										<div className='d-flex justify-content-end refresh-custom mr-4'>

											<DropDownFilterMenu
												ParentData={{
													handleChangeInputData: handleChangeInput,
													handleActions: handleSearch,
													payloads: payload,
													ticketStates: ticketStates
												}}
												Component={NewTicketDashboardFiltration}
											></DropDownFilterMenu>
										</div>
										{
											ticketStates?.pending ? <IsLoading></IsLoading> :
												<div className='row m-4 g-5 g-xl-8 justify-content-center'>
													{
														ticketStates?.filterData?.map((element: any) => {
															return <div className='col-xl-2 border-jobd job-section'>

																<CardCountWidget className='' color='primary' data={element} handleTabClick={handleTabClick} />
															</div>
														})
													}

												</div>
										}
									</>
								}
							</Tab>
							
							<Tab eventKey={2} title="Ticket List" >
								{false ? <IsLoading></IsLoading> :
									<div className="container container_custom" key={"newTicketList"}>
										{
											key == 2 ? <NewTicketsList filterDataOutsource={ticketStates?.ticketListOpenFilterData} /> : <div></div>
										}
									</div>
								}
							</Tab>

							<Tab eventKey={3} title="Customer Missed Call" >
								{false ? <IsLoading></IsLoading> :
									<div className="container container_custom" key={"customerMissedCall"}>
										{
											key == 3 ? <CustomerMissedCallList filterDataOutsource={ticketStates} /> : <div></div>
										}
									</div>
								}
							</Tab>
						</Tabs>
					</>
				}
			</div>



		</>
	)
}
