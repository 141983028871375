import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SingleSelectSearchCategory from '../../../Select/SingleSelectSearchCategory'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { getRequest, postRequest } from '../../../Server/Requests'
import { toast } from 'react-toastify'
import { QueryParams } from '../../../Server/QueryParams'
import { adminToastRunner } from '../../../../modules/admins/adminUtils'
const UpdateTicketCategory = ({ setShow, show, TicketCategoryData, TicketData, invokedTicket }: any) => {
    const [loader, setLoader] = useState<any>(false)
    const [subTicketCategoryData, setSubTicketCategoryData] = useState<any>([])
    const [isDisable, setIsDisable] = useState<any>(true)

    const [payload, setPayload] = useState<any>({
        ticketid: TicketData?.id,
        categoryid: 0,
        subCategoryId: 0,
    })

    useEffect(() => {
        async function load() {
            let newQeueryParams = { ...QueryParams("getTicketSubCategories", "?") }
            newQeueryParams.What += `categoryid=${payload?.categoryid}`
            const subCategoryData = await getRequest(URL_EndPoints(newQeueryParams)?.getTicketSubCategories, null)
            if (subCategoryData?.data?.data?.length == 0) {
                setIsDisable(false)
            }
            else {
                setIsDisable(true)
            }
            setSubTicketCategoryData(subCategoryData?.data?.data)

        }
        payload?.categoryid && load()
    }, [payload?.categoryid])

    useEffect(() => {
        if (payload?.subCategoryId) {
            setIsDisable(false)
        }
    }, [payload?.subCategoryId])


    const handleChangeTicketCategory = async (event: any, name: string) => {
        setPayload({ ...payload, [name]: event ? event?.value : 0 })
    }

    const updateTicketCategoryStatus = async () => {
        if (!payload?.categoryid && !payload?.subCategoryId) {
            toast.error('Category or Sub Category Id is missing', { position: "top-center" })
            setLoader(false)
            return
        }

        setLoader(true)
        const response = await postRequest(URL_EndPoints()?.updateCategory, payload, setLoader)
        adminToastRunner(response)
        invokedTicket()
        setLoader(false)

    }

    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Category/Subcategoy Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-12  mb-3">
                        <h5>Category </h5>
                        <div className={`col-12  mb-8`}>
                            <SingleSelectSearchCategory
                                handleChangeInputData={handleChangeTicketCategory}
                                HeaderTitle="Select Category"
                                SelectData={TicketCategoryData}
                                DynamicKey={"category_name"}
                                id={"id"}
                                name="categoryid"
                            ></SingleSelectSearchCategory>
                        </div>
                    </div>

                    {
                        <>
                            {
                                subTicketCategoryData?.length > 0 &&
                                <div className="col-12  mb-3">
                                    <h5>Sub Category </h5>

                                    <div className={`col-12  mb-8`}>
                                        <SingleSelectSearchCategory
                                            handleChangeInputData={handleChangeTicketCategory}
                                            HeaderTitle="Select Subcategory"
                                            SelectData={subTicketCategoryData}
                                            DynamicKey={"subcategory_name"}
                                            id={"id"}
                                            name="subCategoryId"
                                            disable={isDisable}
                                        ></SingleSelectSearchCategory>
                                    </div>
                                </div>
                            }

                            <div className='modal-footer'>
                                <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={loader || isDisable ? true : false} onClick={() => updateTicketCategoryStatus()}>
                                    {
                                        loader ?
                                            <>
                                                <div className='spinner-border mr-15' role='status'></div>
                                                <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                            </>
                                            : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Now</h4>
                                    }
                                </button>
                            </div>
                        </>
                    }

                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default UpdateTicketCategory
