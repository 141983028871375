import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
const SingleSearchCategory = ({ handleChangeInputData, HeaderTitle, SelectData, DynamicKey, id, name }: any) => {
  const [showData, setShowData] = React.useState<any>("")
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      if (ele[DynamicKey] != null) {
        const newData = {
          label: ele[DynamicKey] + " - " + ele[id],
          value: ele[id],

          
        }
        return newData
      }
    })
    setShowData(updatedData)
  }, [SelectData])
  return (
    <div className=''>
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        options={showData}
        onChange={(event) => handleChangeInputData(event, name)}
        defaultValue={showData}
        styles={colourStyles2}
      />
    </div>
  )
}
export default SingleSearchCategory
