import { faBackward, faComment, faForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
let normalCss = 'text-primary border border-primary rounded p-1 px-2'
let conditionalCss = 'text-danger border border-danger rounded p-1 px-2'
let normalCssIcon = 'text-primary me-1 ms-1'
let conditionalCssIcon = 'text-danger me-1 ms-1'

const ChatSubHeaderAction = ({ setOpenModalClickUpTask, clickUpCommentLoader, cleanercode, campaign_id }: any) => {

    return (
        <div className='d-flex justify-content-end mb-1 bg-white  mr-4 mt-2' key={98765432123232} >
            {/* <div className="badge badge-round border border-white  cursor-pointer" >

                <div className="me-3" onClick={(row) => callCustomer()}>
                    <span className='text-primary border border-primary rounded p-1 px-2' > <i className="bi bi-telephone-x-fill text-primary" ></i> Call Now</span>
                </div>

            </div> */}

            <div className="badge badge-round border border-white  cursor-pointer" >
                {

                    cleanercode && campaign_id == 3 && <div className='me-3 ' onClick={() => setOpenModalClickUpTask(Math.random())}>
                        <span className={clickUpCommentLoader ? conditionalCss : normalCss}>

                            {
                                clickUpCommentLoader ? "Loading..." : "Fetch click up task comments"
                            }
                            <FontAwesomeIcon icon={faComment} className={clickUpCommentLoader ? conditionalCssIcon : normalCssIcon} />
                        </span>
                    </div>
                }



            </div>

        </div>
    )
}

export default ChatSubHeaderAction
