import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CleanerAvailabilityRoute from '../../../modules/cleaner/CleanerAvailabilityRoute'
import CloseButton from '../CloseButton'
const CleanerAvailibilityDrawer = (props: any, { isDrawer = false }: any) => {
    const champAvailty = useSelector((store: any) => store.Schedules.champAvailty)
    // const champAvailtyRender = useSelector((store: any) => store.Schedules.champAvailtyRender)
    const dispatch = useDispatch()
    const closeHandle = () => {
        document.getElementById('kt_drawer_chat3')?.classList.add("kt_drawer_chat3_close")

        dispatch({
            type: 'CHAMP_AVALITY', payload: {
                champAvailty: null,
                champAvailtyRender: null,
                from :""
            }
        })
    }
    return (
        <div
            style={{ width: "99.6%", overflowX: 'scroll', padding: "5px" }}
            id='kt_drawer_chat3'
            className='bg-body px-2'
            data-kt-drawer='true'
            data-kt-drawer-name='chat'
            // data-kt-drawer-width="{default:'600px', 'md': `${widths}`}"
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_drawer_chat3_toggle3'
            data-kt-drawer-close='#kt_drawer_chat3_close'
        >
            <div className='border border-muted w-100 ml-2 rounded-0' id='kt_drawer_chat3_messenger'>
                <div className='card-header pe-2 d-flex justify-content-end' id='kt_drawer_chat3_messenger_header'>
                    <CloseButton closeHandle={closeHandle} id={"kt_drawer_chat3_close"}></CloseButton>
                </div>
                <>
                    {
                        <div
                            className=' customer-detail-body'
                            id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
                        >
                            <div
                                style={{ maxHeight: !champAvailty ? "500px" : "" }}
                                className={clsx('scroll-y me-n5 pe-5  max', { 'h-lg-auto': !isDrawer })}
                                data-kt-element='messages'
                                data-kt-scroll='true'
                                data-kt-scroll-activate='{default: false, lg: true}'
                                data-kt-scroll-max-height='auto'
                                data-kt-scroll-dependencies={
                                    isDrawer
                                        ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                                        : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                                }
                                data-kt-scroll-wrappers={
                                    isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
                                }
                            >



                                {
                                    champAvailty?.champAvailty ?
                                        <CleanerAvailabilityRoute refrence={"outside"} iscleanerpage={false} champAvailty={champAvailty.champAvailty} champAvailtyRender={champAvailty.champAvailtyRender}  from={champAvailty.from}></CleanerAvailabilityRoute> : <></>
                                }
                            </div>
                        </div>
                    }
                </>
            </div>
        </div>
    )
}
export default CleanerAvailibilityDrawer
