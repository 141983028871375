import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import { CheckboxModal } from '../../../consts/widgets/forms/FormsModals';
import SingleSelectSearchDetailsDefault from '../../../consts/filtersComponents/SingleSelectSearchDetailsDefault';

const UpdateArea = ({ show, setShow, row, setRowData = null }: any) => {
    const [apiLoader, setapiLoader] = useState(false);
    const [inititalLoader, setInititalLoader] = useState(false);
    const [updateAreaDetails, setUpdateAreaDetails] = useState<any>(null);
    const [areaNameDefault, setareaNameDefault] = useState<any>("");
    const [areaList, setAreaList] = useState([])
    const [payload, setPayload] = useState<any>({
        id: row?.id,
        champId: row?.id,
        areaId: null,
        status: row?.status
    });

    useEffect(() => {
        setInititalLoader(true)
        async function filtersss() {
            const updateData: any = areaList?.find((area: any) => area.id == row?.cleanerArea?.areaDetail?.id)
            updateData && setUpdateAreaDetails({ label: updateData?.areaname, id: updateData?.id })
        }
        filtersss()
        setTimeout(() => {
            setInititalLoader(false)
        }, 2000)
    }, [row?.cleanerArea?.areaDetail?.areaname, areaList])

    const loadInitialData = async () => {
        setInititalLoader(true)
        const response = await getRequest(URL_EndPoints()?.getarea, setInititalLoader)
        setAreaList(response?.data?.data)
    }

    useEffect(() => {
        loadInitialData()
    }, [])

    const updateHandler = async (e: any) => {
        e.preventDefault();

        setapiLoader(true)
        const response: any = await postRequest(URL_EndPoints(null)?.updateCleanerArea, payload, setapiLoader)
        setapiLoader(false)
        if (response?.data?.status == 200) {
            if (setRowData) {
                // rowData?.cleanerArea?.areaDetail?.areaname
                row.cleanerArea.areaDetail = {}
                row.cleanerArea.areaDetail.areaname = areaNameDefault
                setRowData(row)
            }
            toast.success(response?.data?.msg, { position: "top-center" })
            setShow(false)
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
            setShow(false)
        }
    }

    const inputChangehandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
        setareaNameDefault(event ? event?.item?.areaname : "")
    }

    return (
        <>
            <Modal size='lg' show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Update Area </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={updateHandler}>
                        {
                            inititalLoader ? <IsLoading /> : <div className='col-12 mb-4'>
                                <SingleSelectSearchDetailsDefault
                                    handleChangeInputData={inputChangehandler}
                                    HeaderTitle='Select Area Name'
                                    SelectData={areaList || []}
                                    DynamicKeys={["areaname"]}
                                    id={"id"}
                                    name={"areaId"}
                                    targetValue={"value"}
                                    defaultData={updateAreaDetails ? updateAreaDetails : null}
                                // defaultData={null}
                                ></SingleSelectSearchDetailsDefault>
                            </div>
                        }

                        <div className='col-12 mb-4'>
                            <CheckboxModal
                                classes=""
                                name="status"
                                payload={payload}
                                setPayload={setPayload}
                                // defValue={null}
                                defValue={payload?.status}
                            />
                        </div>

                        <Form.Group className="mb-3 p-2" >
                            {
                                apiLoader ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary">
                                        Update Area
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UpdateArea
