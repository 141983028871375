import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker,Rectangle } from '@react-google-maps/api';


const GoogleMaps = ({ markerPositionLatLng, handleMarkerDragEnd}: any) => {
  const containerStyle = {
    width: '100%',
    height: '400px',
  };


  const rectangleBounds = {
    north: markerPositionLatLng.lat + 0.135, // 0.135 degrees is approximately 15 km (half of 30 km)
    south: markerPositionLatLng.lat - 0.135,
    east: markerPositionLatLng.lng + 0.135,
    west: markerPositionLatLng.lng - 0.135,
  };

  return (
    // <LoadScript googleMapsApiKey="AIzaSyB1TLe_5fP2B85oU9mvzNnJbN6QuHjdjHE">
    <>
      {
        
          <GoogleMap mapContainerStyle={containerStyle} center={markerPositionLatLng} zoom={15}>
            <Marker position={markerPositionLatLng} draggable={true} onDragEnd={handleMarkerDragEnd} />
            {/* <Rectangle bounds={rectangleBounds} options={{ fillOpacity: 0, strokeColor: '#FF0000', strokeWeight: 2 }} /> */}
          </GoogleMap>
          
      }
    </>



  );
};

export default GoogleMaps;
