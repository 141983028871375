const initState = {
    vehicleReasons: [],
}

export const VehicleReducer = (state = initState, { type, payload }: any) => {
    switch (type) {
        case "ADD_VEHICLE_REASONS":
            return { ...state, vehicleReasons: payload }
        default:
            return state
    }
}

