import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { Bars } from 'react-loader-spinner'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { postRequest } from '../../../consts/Server/Requests'
import { useDispatch } from 'react-redux'
import PermissionContextProvider from '../../admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../../admins/adminConstant'
export const RenewalActions = ({ subscriptionid, vehicleid, customerid, renewalreasonid, row }: any) => {

	useEffect(() => {
		MenuComponent.reinitialization()
	}, [])
	const [show, setShow] = useState(false);
	const [showcustomerAllVehiclesRenewalsModal, setShowcustomerAllVehiclesRenewalsModal] = useState(false);
	const [copied, setCopied] = useState(false);
	const [link, setLink] = useState('');
	const [customerAllVehiclesRenewals, setCustomerAllVehiclesRenewals] = useState([]);
	const [buttomcustomerAllVehiclesRenewalsLoading, setButtomcustomerAllVehiclesRenewalsLoading] = useState(false);
	const [selectedId, setselectedId] = useState<any>({})
	const [dueDatePostDate, setDueDatePostDate] = useState<any>({})
	const [UpdateP2POpen, setUpdateP2POpen] = useState(false)
	const [dueDataPop, setdueDataPop] = useState(false)
	const dispatch = useDispatch()
	const selectedSubIds: any[] = []
	let sendEmail: boolean = false
	let sendSms: boolean = false
	let totalAmount = 0;
	const userid = JSON.parse(localStorage.getItem("user") || "0")
	const handleClose = () => {
		setLink('')
		setUpdateP2POpen(false)
		setdueDataPop(false)
		setShow(false)
		setCopied(false)
		setCustomerAllVehiclesRenewals([])
		setShowcustomerAllVehiclesRenewalsModal(false)
		setButtomcustomerAllVehiclesRenewalsLoading(false)
	};
	const handleShow = () => setShow(true);
	const payNow = async (subscriptionsId: any) => {
		let payloads = {
			subscriptionordermonthid: subscriptionid,
			subscriptionvehicleid: vehicleid,
		}
		const { data } = await postRequest(URL_EndPoints()?.paynow, payloads, null)
		setLink(data.paymentlink)
		setLink('https://dscdscsd.....')
		handleShow()
	}
	const getCustomerAllVehiclesRenewals = async () => {
		let payloads = {
			subscription_id: subscriptionid,
			customerid: customerid,
		}
		const { data } = await postRequest(URL_EndPoints()?.getCustomerAllVehiclesRenewals, payloads, null)
		setCustomerAllVehiclesRenewals(data?.data)
		setShowcustomerAllVehiclesRenewalsModal(true)
	}
	const checkBoxClickHandle = (id: any) => {
		if (!selectedSubIds.includes(id)) {
			selectedSubIds.push(id)
		} else {
			selectedSubIds.splice(selectedSubIds.indexOf(id), 1);
		}
	}
	const updateRenewalReason = () => {

		dispatch({
			type: "MODAL_OPEN", payload: {
				toggle: true,
				id: subscriptionid,
			}
		})
	}
	const DueDataHandle = () => {
		setDueDatePostDate({
			ticket_history_id: row?.tickets?.length > 0 ? (row?.tickets[0].tickethistory?.length > 0 ? row?.tickets[0].tickethistory[0]?.id : 0) : 0,
			ticket_id: row?.tickets?.length > 0 ? row?.tickets[0]?.id : 0,
			subscriptionid,
			customerid: row?.customerid,
			user_id: userid,
			prev_due_date: row?.tickets?.length > 0 ? (row?.tickets[0].tickethistory?.length > 0 ? row?.tickets[0].tickethistory[0]?.due_date : '') : '',
		})

		setdueDataPop(true)
	}
	const handlecustomerAllVehiclesRenewals = async () => {
		//submit
		setButtomcustomerAllVehiclesRenewalsLoading(true)
		if (selectedSubIds.length > 0) {
			const { data } = await postRequest(URL_EndPoints()?.createCustomerPaymentLink, {
				subscription_ids: JSON.stringify(selectedSubIds),
				customerid: customerid,
				sendSms,
				sendEmail
			}, null)
			if (data?.status == 200) {
				let payloads = {
					subscription_id: subscriptionid,
					customerid: customerid,
				}
				const { data } = await postRequest(URL_EndPoints()?.getCustomerAllVehiclesRenewals, payloads, null)
				setCustomerAllVehiclesRenewals(data?.data)
				sendEmail = false
				sendSms = false
				toast("Generated successfully.")
			}
		}
		setButtomcustomerAllVehiclesRenewalsLoading(false)
	}

	const [renewalLoading, setRenewalLoading] = useState(false);
	const createRenewalJob = async () => {
		setRenewalLoading(true);
		const payload = {
			subscriptionId: row?.id,
		};
		const res: any = await postRequest(URL_EndPoints(null).createRenewalTask, payload, setRenewalLoading);
		setRenewalLoading(false);
		toast[res?.data?.status === 200 ? 'success' : 'info'](res?.data?.message);
	}

	return (
		<>
			<button
				className='btn btn-sm btn-light btn-active-primary  fs-8 '
				data-kt-menu-trigger='click'
				data-kt-menu-placement='bottom-end'
			>
				{renewalLoading ? <Spinner animation="border" variant="primary" /> : <i className="fa fa-ellipsis-v" aria-hidden="true"></i>}
			</button>
			<div
				className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
				data-kt-menu='true'
				style={{
					width: "500px",
					zIndex: '105',
					position: 'fixed',
					inset: '0px 0px auto auto',
					margin: '0px',
					transform: 'translate(-59px, 440px)',
				}}
			>


				<PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-get-easebuzz-payment-link"]}>
					<div className='menu-item px-3'>
						<a
							// href='_blank'
							className='menu-link  px-3'
							data-kt-users-table-filter='delete_row'
							onClick={payNow}
						>
							Get EaseBuzz Payment Link
						</a>
					</div>
				</PermissionContextProvider>






				<PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-get-razorpay-payment-link"]}>
					<div className='menu-item px-3'>
						<a
							// href='_blank'
							className='menu-link  px-3'
							data-kt-users-table-filter='delete_row'
							onClick={getCustomerAllVehiclesRenewals}
						>
							Get Razorpay Payment Link
						</a>
					</div>
				</PermissionContextProvider>

				<PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-create-renewal-job"]}>
					<div className='menu-item px-3'>
						<a
							// href='_blank'
							className='menu-link  px-3'
							data-kt-users-table-filter='delete_row'
							onClick={createRenewalJob}
						>
							Create Renewal Job
						</a>
					</div>
				</PermissionContextProvider>


			</div>



			{show ? <Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Payment Link</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex flex-row">
						<div className="p-2">{link}</div>
						<div className="p-2">
							<FontAwesomeIcon icon={faCopy} onClick={() => {
								navigator.clipboard.writeText(link)
								setCopied(true)
							}
							} />
						</div>
					</div>
					<div className="d-flex flex-row">
						<div className="p-2">
							{copied ? <span style={{ color: '#6f6f70', fontSize: '10px' }}>Copied.</span> : null}
						</div>
					</div>
				</Modal.Body>
			</Modal> : ''}
			{showcustomerAllVehiclesRenewalsModal ? <Modal show={showcustomerAllVehiclesRenewalsModal} onHide={handleClose} dialogClassName="modal-large">
				<Modal.Header closeButton>
					<Modal.Title>Razorpay Payment Link</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table customerAllVehiclesRenewalsTable table-responsive">
						<thead>
							<tr>
								<th className='d-th' scope="col">Subscription id</th>
								<th className='d-th' scope="col">Payment Link</th>
								<th className='d-th' scope="col">Model</th>
								<th className='d-th' scope="col">Package</th>
								<th className='d-th' scope="col">StartDate</th>
								<th className='d-th' scope="col">EndDate</th>
								<th className='d-th' scope="col">Fine Amount</th>
								<th className='d-th' scope="col">Discount Amount</th>
								<th className='d-th' scope="col">Final Amount</th>
							</tr>
						</thead>
						<tbody>
							{customerAllVehiclesRenewals?.map((detail: any) => {
								totalAmount += detail?.finalAmount
								return (
									<tr>
										<td><input type="checkbox" className="paymentLinkSubscriptionId me-2" name="paymentLinkSubscriptionId" onClick={() => checkBoxClickHandle(detail?.subscription?.id)} value="${detail.subscription.id}" />&nbsp;&nbsp;{detail.subscription.id}</td>
										<td style={{ "display": "inline-flex" }}>
											<p className="copyValue">{detail.razorpay_payment_link}</p>&nbsp;&nbsp;<a href="javascript:;" className="copyButton" onClick={() => {
												{
													navigator.clipboard.writeText(detail.razorpay_payment_link)
													toast("Copied successful.")
												}
											}}><i className="fa fa-copy"></i></a>
										</td>
										<td>{detail.vehicle.model.name}</td>
										<td>{detail.subscription.packageDetail.name}</td>
										<td>{detail.subscription.startdate}</td>
										<td>{detail.subscription.enddate}</td>
										<td>₹{detail.fineOrExtra.fineAmount}</td>
										<td>₹{detail.fineOrExtra.discountprice}</td>
										<td>₹{detail.finalAmount}</td>
									</tr>
								)
							}
							)}
						</tbody>
					</table>
					<hr />
					<div
						className="d-flex justify-content-end mb-3"
						style={{ marginRight: "2em", fontSize: "20px" }}
					>
						<div className="bd-highlight">Total:</div>
						<div className="bd-highlight" id="totalAmount">&nbsp;₹{totalAmount}</div>
					</div>
					<div className="d-flex justify-content-start aligh-items-center mb-2">
						<input type="checkbox" name="sendSms" id="sendSms" onClick={() => {
							sendSms = sendSms == true ? false : true
						}} style={{ height: "20px", width: "20px" }} className='me-2 cursor-pointer' />
						<label className="form-check-label ml-1" htmlFor="sendSms">Send Link Via SMS</label>
					</div>
					<div className="d-flex justify-content-start aligh-items-center mb-3">
						<input type="checkbox" name="sendEmail" id="sendEmail" onClick={() => {
							sendEmail = sendEmail == true ? false : true
						}} style={{ height: "20px", width: "20px" }} className='me-2 cursor-pointer' />
						<label className="form-check-label ml-1" htmlFor="sendEmail">Send Link Via Email</label>
					</div>
					<a className="btn btn-success mt-4" onClick={() => handlecustomerAllVehiclesRenewals()}>
						{buttomcustomerAllVehiclesRenewalsLoading ?
							<Bars
								height="20"
								width="20"
								color="#00000"
								ariaLabel="bars-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
							: 'Create Payment Link'}
					</a>
				</Modal.Body>
			</Modal> : ''}
		</>
	)
}
