import React from 'react'
import { Modal } from 'react-bootstrap'
import { Bars } from 'react-loader-spinner'
import "./uploadImage.css"
const WhatsAppNumberUpdate = ({ FilteredData }: any) => {

    return (
        <>
            <Modal show={FilteredData?.whatsAppNumberUpdateShow} onHide={FilteredData?.handleWhatsAppUpdateClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update WhatsApp Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row  ms-3 mb-3 mt-2 col-12'>
                        <input
                            type='number'
                            className='form-control  me-2  border'
                            placeholder='Enter WhatsApp number here...'
                            style={{ height: "50px", width: "100%" }}
                            onChange={(e) => FilteredData?.setWhatsAppNumber(e.target.value)}
                            value={FilteredData?.whatsAppNumber}
                        />
                    </div>
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button dir="rtl" className="btn btn-success mt-4 text-end" onClick={FilteredData?.handleWhatsAppUpdate} disabled={FilteredData?.whatsAppUpdateLoading}>
                            {FilteredData?.whatsAppUpdateLoading ?
                                <Bars
                                    height="20"
                                    width="20"
                                    color="#00000"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                : 'Update'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default WhatsAppNumberUpdate
