import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { getPostMultipleApiHandler, postRequest } from '../../../consts/Server/Requests'
import MultiSelectSearchDetailsDefault from '../../../consts/Select/MultiSelectSearchDetailsDefault'
import { adminBulkAssignApi } from '../adminConstant'
import { adminToastRunner, formValidationErrorChecker } from '../adminUtils'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


const AdminBulkAssignUnassignForm = ({ show, setShow, initialData, filteredSelectedRows, setFilteredSelectedRows, toggledClearRows, setToggleClearRows, refreshNow, toastMessage, keyName, formTitle, bulkKeyName, formSubmitApiUrl, validationSchema }: any) => {
    const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
    const [adminListData, setAdminListData] = React.useState<any>([])
    const [supervisorsListData, setSupervisorsListData] = React.useState<any>([])
    const [loader, setLoader] = React.useState<any>(false)
    const [errMessage, setErrMessage] = React.useState<any>({})
    const [payload, setPayload] = useState<any>(initialData);

    async function getFetchInitialData() {
        setLoader(true)
        const results = await getPostMultipleApiHandler(adminBulkAssignApi, setLoader, false)
        setSupervisorsListData(results["getSupervisorList"] || [])
        setAdminListData(results["getAlladminlist"] || [])
    }

    useEffect(() => {
        show && getFetchInitialData()
    }, [show])

    useEffect(() => {

        if (payload?.should_unassign == true) {
            setPayload({ ...payload, ["admins"]: [], ["cleaners"]: [] })

        }
        if (payload?.should_unassign == false) {
            setPayload({ ...payload, ["admins_to_unassign"]: [], ["cleaners_to_unassign"]: [] })
        }

        if (payload.hasOwnProperty("dueDate") == true) {
            setPayload({ ...payload, ["dueDate"]: "" })
        }

    }, [payload?.should_unassign])

    const onChangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event })
    }

    const handleAssignSubmit = async (e: any) => {
        e.preventDefault()

        if (formValidationErrorChecker(payload, validationSchema, setErrMessage)) {
            if (bulkKeyName) {
                payload[bulkKeyName] = filteredSelectedRows
            }
            setIsBulkAssignApiLoading(true)
            const response: any = await postRequest(URL_EndPoints(null)?.[formSubmitApiUrl], payload, setIsBulkAssignApiLoading)
            setFilteredSelectedRows && setFilteredSelectedRows([])
            setToggleClearRows(!toggledClearRows)
            setShow(false)
            refreshNow()
            adminToastRunner(response, toastMessage)
        } else {
            toast.error("Form validation has error !", { position: "top-center" })
        }
    }

    return (
        <>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-small"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleAssignSubmit} key={keyName}>
                        {
                            !payload?.should_unassign ? <>
                                <Form.Group className="mb-3" key={"selectadminsToAssign"}>
                                    <MultiSelectSearchDetailsDefault
                                        handleChangeInputData={onChangeHandler}
                                        HeaderTitle={"Select admins to assign"}
                                        SelectData={adminListData}
                                        DynamicKey={"first_name"}
                                        DynamicKey2={"last_name"}
                                        DynamicKey3={"phone"}
                                        id={"id"}
                                        name="admins"
                                        loader={loader}
                                    ></MultiSelectSearchDetailsDefault>
                                    {errMessage["admins"] && payload["admins"]?.length == 0 && <Form.Label className='text-danger fs-8'><FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger fs-fs-8' />{errMessage["admins"]}</Form.Label>}

                                </Form.Group>
                                <Form.Group className="mb-3" key={"selectSupervisorToAssign"} >
                                    <MultiSelectSearchDetailsDefault
                                        handleChangeInputData={onChangeHandler}
                                        HeaderTitle={"Select supervisor to assign"}
                                        SelectData={supervisorsListData}
                                        DynamicKey={"first_name"}
                                        DynamicKey2={"last_name"}
                                        DynamicKey3={"phone"}
                                        id={"id"}
                                        name="cleaners"
                                        loader={loader}
                                    ></MultiSelectSearchDetailsDefault>
                                    {errMessage["cleaners"] && payload["cleaners"]?.length == 0 && <Form.Label className='text-danger fs-8'><FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger fs-fs-8' />{errMessage["cleaners"]}</Form.Label>}
                                </Form.Group>
                            </> : <>
                                <Form.Group className="mb-3" key={"selectadminsTounAssign"}>
                                    <MultiSelectSearchDetailsDefault
                                        handleChangeInputData={onChangeHandler}
                                        HeaderTitle={"Select admins to unassign"}
                                        SelectData={adminListData}
                                        DynamicKey={"first_name"}
                                        DynamicKey2={"last_name"}
                                        DynamicKey3={"phone"}
                                        id={"id"}
                                        name="admins_to_unassign"
                                        loader={loader}
                                    ></MultiSelectSearchDetailsDefault>
                                    {errMessage["admins_to_unassign"] && payload["admins_to_unassign"]?.length == 0 && <Form.Label className='text-danger fs-8'><FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger fs-fs-8' />{errMessage["admins_to_unassign"]}</Form.Label>}

                                </Form.Group>
                                <Form.Group className="mb-3" key={"selectSupervisorTounAssign"} >
                                    <MultiSelectSearchDetailsDefault
                                        handleChangeInputData={onChangeHandler}
                                        HeaderTitle={"Select supervisor to unassign"}
                                        SelectData={supervisorsListData}
                                        DynamicKey={"first_name"}
                                        DynamicKey2={"last_name"}
                                        DynamicKey3={"phone"}
                                        id={"id"}
                                        name="cleaners_to_unassign"
                                        loader={loader}
                                    ></MultiSelectSearchDetailsDefault>
                                    {errMessage["cleaners_to_unassign"] && payload["cleaners_to_unassign"]?.length == 0 && <Form.Label className='text-danger fs-8'><FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger fs-fs-8' />{errMessage["cleaners_to_unassign"]}</Form.Label>}
                                </Form.Group>
                            </>
                        }

                        {
                            'dueDate' in payload == true ? <Form.Group className="mb-4" key={"dueDate"} >
                                <Form.Label className='text-mutd fs-8'>
                                    <FontAwesomeIcon icon={faCalendarAlt} className='me-2 text-mutd fs-fs-8' />Due Date</Form.Label>
                                <input
                                    type='date'
                                    style={{ height: '38px' }}
                                    value={payload?.dueDate}
                                    name="dueDate"
                                    onChange={(event: any) =>
                                        onChangeHandler(event.target?.value, "dueDate")
                                    }
                                    className='w-100 form-control me-2 align-start'
                                />
                                {errMessage["dueDate"] && !payload["dueDate"] && <Form.Label className='text-danger fs-8'><FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger fs-fs-8' />{errMessage["dueDate"]}</Form.Label>}
                            </Form.Group> : <></>
                        }

                        {
                            'should_unassign' in payload == true ? <Form.Group className="mb-3" key={"unassignToggle"}>
                                <Form.Check
                                    type='checkbox'
                                    label='Do you want to bulk unassign'
                                    id='bulk_unassign'
                                    checked={payload.should_unassign}
                                    onChange={(e: any) => {
                                        setPayload({
                                            ...payload,
                                            should_unassign: e.target.checked,
                                        })
                                    }}
                                />
                            </Form.Group> : <></>
                        }

                        <Form.Group className="mb-3 p-2 text-end"  >
                            {
                                <Button type='submit' variant={isBulkAssignApiLoading ? "success" : !payload.should_unassign ? "primary" : "danger"} >
                                    {isBulkAssignApiLoading ? "Please wait..." : !payload.should_unassign ? "Bulk Assign" : "Bulk Unassign"}
                                </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default AdminBulkAssignUnassignForm
