import { useEffect, useReducer, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { KTCardBody } from '../../../_metronic/helpers'
import Refresh from '../../consts/Refresh'
import StatusConfigurationList from './statusConfigurationList/StatusConfigurationList'
import CommonLeadsStatusConfig from './commonLeadsStatusConfig/CommonLeadsStatusConfig'



export const StatusConfigs = () => {

	const [key, setKey] = useState<number>(1)
	const handleSelect = (key: any) => {
		setKey(key);
	}

	// async function initialData() {
	// 	const getAdminList = await getRequest(URL_EndPoints(null)?.getAdminList, null)
	// }

	// useEffect(() => {
	// 	initialData()
	// }, [])


	return (
		<>
			<KTCardBody className='card py-4'>
				{
					<>
						<Tabs activeKey={key} onSelect={handleSelect} id="controlled-tab-example">

							<Tab eventKey={1} title="Status Group Configuration" >
								<div className='w-125'>

									{
										key == 1 ? <StatusConfigurationList  /> : <></>
									}
								</div>
							</Tab>

							<Tab eventKey={2} title="Common Leads Status Configuration" >
								<div className='w-125'>
									{
										key == 2 ? <CommonLeadsStatusConfig  /> : <></>
									}
								</div>
							</Tab>
							
						</Tabs>
					</>
				}

			</KTCardBody >
		</>
	)
}
