import {getRequest} from '../../Server/Requests'
import {URL_EndPoints} from '../../Server/URL_EndPoints'

export const DefaultPrivatePublic = async (ChampDetail: any, tags: any) => {
  let QeueryParams = {
    To: 'getMasterTags',
    What: `?user_type=${ChampDetail?.userType}`,
  }

  const result = await getRequest(URL_EndPoints(QeueryParams)?.getMasterTags, null)
  let tagsData = result?.data?.data

  const preveData: any = []

  for (let i = 0; i < tags?.length; i++) {
    let current = tagsData?.filter((ele: any) => ele?.name == tags[i])

    if (current?.length > 0) {
      let object = {
        label: current[0]?.name,
        value: current[0]?.id,
      }
      preveData.push(object)
    }
  }

  return preveData as any
}

export const champDashboardSubAreaActionFormData = [
  {
    HeaderTitle: 'Select Sub Area Name',
    filterApi: 'searchableSubAreasList',
    // filterApi: 'getMapBlocksSubAreasListForFilter',
    label: {
      show: false,
      name: 'Select Sub Area Name',
    },
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'sub_area_id',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Sub area name is required !',
    searchable: true,
    wa_number_id: null,
    userType: null,
  },
]

export const champAvailibilityFormData = [
  {
    HeaderTitle: 'Select  Timeslotes',
    filterApi: 'getAvailabilityTimeslots',
    label: {
      show: false,
      name: 'Select Timeslotes',
    },
    valueType: 'multi',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'timeslot_ids',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Timelote Id is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
]

export const champBuddyFormData = [
  {
    HeaderTitle: 'Select Buddy Preference',
    filterApi: 'getAreaManagerActiveChamps',
  
    label: {
      show: false,
      name: 'Select Buddy',
    },
    valueType: 'multi',
    inputType: '',
    displayFields: ['first_name', 'last_name', 'phone'],
    valueField: 'id',
    name: 'buddyIds',
    from: '',
    to: '',
    isRequired: true,
    searchable: false,
    errMessage: 'Buddy Id is required !',
    wa_number_id: null,
    userType: null,
    isBody: false,
    isQuery: false,
    isParam: false,
  },
]
