import React from 'react'
import Select from 'react-select'
import "./styles.css"
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
const CustomerDefaultSelect = (props: any) => {
  const { handleChangeInputData, HeaderTitle, SelectData, DynamicKey, DynamicKey2, DynamicKey3, id, name, defaultData, setSeacrch, isLoading, targetValue } = props



  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {

    const updatedData = SelectData?.map((ele: any, i: number) => {
      let number = ele[DynamicKey3] ? '(' + ele[DynamicKey3] + ')' : "(NA)"
      if (ele[DynamicKey] !== null) {
        const newData = {
          label: ele[DynamicKey] + ' ' + ele[DynamicKey2] + number,
          value: ele[id],
          item: ele
        }
        return newData
      }
    })
    // reference2

    setShowData(updatedData)
  }, [SelectData])
  return (
    <div className='me-2 w-100 form-select-sm ' style={{paddingLeft: 0}} >
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        onInputChange={(e: any) => setSeacrch && setSeacrch(e)}
        options={showData}
        onChange={(event) => handleChangeInputData(event, name, targetValue, true)}
        defaultValue={defaultData && defaultData}
        styles={colourStyles2}
        isLoading={isLoading}

      />
    </div>
  )
}
export default CustomerDefaultSelect




// this is for cleaner logic  


    // // search filter  
    // const [isLoading, setIsLoading] = useState<boolean>(false)
    // const [searchDataCleaner, setsearchDataCleaner] = useState<any[]>([])
    // const [storeSeachKeywords, setstoreSeachKeywords] = useState<boolean>(false)


    // async function getSearchDataBykeyword(storeSeachKeywords: any) {
    //     setIsLoading(true)
    //     const response: any = await postRequest(URL_EndPoints()?.getChampsList, {
    //          keyword: storeSeachKeywords,
    //          cleaner_id: row?.id,
    //          }, setIsLoading);

    //     response?.data?.data && setsearchDataCleaner(response?.data?.data);
    //     setIsLoading(false)
    // }


    // const handleSelectChange = (event: any, name: any) => {
    //     setPayload({ ...payload, ["referred_by"]: event ? event?.value : null, "referred_by_type": event ? event?.item?.userType : null, item: event ? event?.item : null })
    // }

    // useEffect(() => {
    //     let x = setTimeout(() => {
    //         getSearchDataBykeyword(storeSeachKeywords)
    //     }, 2000)
    //     return () => {
    //         setIsLoading(false)
    //         clearTimeout(x)
    //     }
    // }, [storeSeachKeywords])

    // // search filter  














    // this is for customer logic -----------------------------------------------







    // // search filter  
    // const [isLoading, setIsLoading] = useState<boolean>(false)
    // const [searchDataCleaner, setsearchDataCleaner] = useState<any[]>([])
    // const [storeSeachKeywords, setstoreSeachKeywords] = useState<boolean>(false)

    // async function getSearchData(storeSeachKeywords: any) {
    //     getSearchDataBykeyword(storeSeachKeywords, setsearchDataCleaner, setIsLoading)
    // }

    // const selectSearchValue = (event: any, name: any) => {
    //     handleSelectChange(event, name, setTicketPayloads, TicketPayloads)
    // }

    // React.useEffect(() => {
    //     let x = setTimeout(() => {
    //         getSearchData(storeSeachKeywords)
    //     }, 2000)
    //     return () => {
    //         setIsLoading(false)
    //         clearTimeout(x)
    //     }
    // }, [storeSeachKeywords])

    // // search filter  





    // setstoreSeachKeywords: setstoreSeachKeywords,
    // searchDataCleaner: searchDataCleaner,
    // isLoading: isLoading,
    // selectSearchValue: selectSearchValue,



    

    // {
    //   <CustomerDefaultSelect
    //     handleChangeInputData={ParentData?.selectSearchValue}
    //     HeaderTitle='Select Customer'
    //     SelectData={ParentData?.searchDataCleaner}
    //     DynamicKey={'first_name'}
    //     DynamicKey2={'last_name'}
    //     DynamicKey3={'phone'}
    //     id={'id'}
    //     name='customerid'
    //     defaultData={null}
    //     setSeacrch={ParentData?.setstoreSeachKeywords}
    //     isLoading={ParentData?.isLoading}
    //   ></CustomerDefaultSelect>
    // }