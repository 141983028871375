import clsx from "clsx"
import Not_Found_Data from "../../Not_Found_Data"
import { useEffect, useState } from "react"
import { image_Cloudfront } from "../../components/Utility"
import { getRequest, postRequest } from "../../Server/Requests"
import { URL_EndPoints } from "../../Server/URL_EndPoints"
import IsLoading from "../../IsLoading/IsLoading"
import "./uploadImage.css"
import EditDocuments from "./EditDocuments"
import ShowDocumentIndividual from "./ShowDocumentIndividual"
import AddDocuments from "./AddDocuments"
import PermissionContextProvider from "../../../modules/admins/adminComponents/PermissionContextProvider"
import { adminMenuActionPermissionKeys } from "../../../modules/admins/adminConstant"

const ChampDocuments = ({ champsDetails }: any) => {

  let [loader, setLoader] = useState(false)
  let [filterData, setfilterData] = useState([])
  const [isAciveOrInactiveDocument, setIsAciveOrInactiveDocument] = useState<any>(true);

  const [showAddModal, setShowAddModal] = useState<any>(false);
  const [showEditModal, setShowEditModal] = useState<any>(false);
  const [showImageModal, setShowImageModal] = useState<any>(false);
  const [documentData, setDocumentData] = useState<any>({});
  async function loadInitialData(statusId: any) {
    setLoader(true)
    const response = await postRequest(URL_EndPoints()?.getChampDocuments, { champId: champsDetails?.id, status: statusId }, setLoader)
    setfilterData(response?.data?.data)
  }

  useEffect(() => {
    isAciveOrInactiveDocument && loadInitialData(1)
    !isAciveOrInactiveDocument && loadInitialData(0)
  }, [champsDetails?.id, isAciveOrInactiveDocument])


  const viewImageHandler = (ele: any) => {
    setShowImageModal(true)
    setDocumentData(ele)
  }
  const showEditModalHandler = (ele: any) => {
    setShowEditModal(true)
    setDocumentData(ele)
  }


  const [documentTypeList, setDocumentTypeList] = useState<any>([])

  const getDocumentTypeList = async () => {
    const response = await getRequest(URL_EndPoints()?.getDocumentTypes, null)
    setDocumentTypeList(response?.data?.data)
  }
  useEffect(() => {
    showAddModal && getDocumentTypeList()
    showEditModal && getDocumentTypeList()
  }, [showAddModal, showEditModal])



  return (
    <>
      <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
        <div
          className={clsx('scroll-y me-n5 pe-5  max mb-4')}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
        >
          <div className='px-3 mt-4 margins' >

            {
              <>
                <div className="d-flex justify-content-between align-items-center mb-10 cursor-pointer"  >
                  <div>
                    <button className={`btn btn-sm btn-${isAciveOrInactiveDocument ? "success" : " border"} me-2`} onClick={() => setIsAciveOrInactiveDocument(true)} >Active Documents</button>
                    <button className={`btn btn-sm btn-${!isAciveOrInactiveDocument ? "success" : " border"}  me-2`} onClick={() => setIsAciveOrInactiveDocument(false)}>Inactive Documents</button>
                  </div>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-documents-add-documents"]}>
                    <button className={`btn btn-sm btn-primary me-2`} onClick={() => setShowAddModal(!showAddModal)}>Add Documents</button>
                  </PermissionContextProvider>



                </div>
                <div className="row image_gallary " >
                  {
                    loader ? <IsLoading />
                      : filterData?.length > 0 ? filterData?.map((ele: any, index: any) => {
                        return <div className="image_gallery col-lg-4 col-md-12 mb-4 mb-lg-0" key={ele?.id + index}>
                          <div className="image-container">
                            {ele?.docurl && <img src={image_Cloudfront + ele?.docurl} className="w-100 shadow-1-strong rounded mb-4" alt="No Document" style={{ height: "300px", objectFit: 'cover' }} />}
                            <div className="overlay">
                              <div className="editActionButton">
                                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-management-dashboard-documents-edit-documents"]}>
                                <button className="edit-button me-2" onClick={() => showEditModalHandler(ele)}>Edit</button>
                                </PermissionContextProvider>
                                <button className="edit-button" onClick={() => viewImageHandler(ele)}>View Image</button>
                              </div>
                              <span className="editButton2" >{ele?.docname}</span>
                            </div>
                          </div>

                        </div>

                      })
                        : <Not_Found_Data headerTitle={'No Images Found'} />
                  }
                </div>

              </>
            }

          </div>
        </div>
      </div>


      {
        showEditModal ? <EditDocuments show={showEditModal} setShow={setShowEditModal} row={champsDetails} documentData={documentData} documentTypeList={documentTypeList} isAciveOrInactiveDocument={isAciveOrInactiveDocument} loadInitialData={loadInitialData} /> : <></>
      }

      {
        showAddModal ? <AddDocuments show={showAddModal} setShow={setShowAddModal} row={champsDetails} documentTypeList={documentTypeList} isAciveOrInactiveDocument={isAciveOrInactiveDocument} loadInitialData={loadInitialData} /> : <></>
      }
      {
        showImageModal ? <ShowDocumentIndividual show={showImageModal} setShow={setShowImageModal} row={documentData} /> : <></>
      }

    </>
  )
}
export default ChampDocuments