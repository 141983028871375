import React, { useEffect, useState } from 'react'

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { getRequest, postRequest } from './Server/Requests'
import { URL_EndPoints } from './Server/URL_EndPoints'
import { Spinner } from 'react-bootstrap'
import "./custom.css"
import { faBell, faComment, faCommentAlt, faEnvelope, faPerson, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import { ChatTicketID } from '../../Redux/Action/Chats/ChatAction'

const NotificationDrawer = ({ isOpen, setIsOpen }: any) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [notifications, setNotifications] = useState<any>([])
    const [mainNotification, setMainNotification] = useState<any>([])
    const [page, setPage] = useState(1);

    const toggleDrawer = () => {
        setIsOpen((prevState: any) => !prevState)
    }

    let userid = localStorage.getItem("user") || 0

    useEffect(() => {

        setLoading(true)
        async function InvokedRendered() {
            if (isOpen == true) {
                const { data } = await postRequest(URL_EndPoints(null)?.getAllNotifications, {
                    userid: userid,
                }, null)
                setMainNotification(data?.data)
                setNotifications(data?.data?.slice(0, 15))
            }
            setLoading(false)
        }

        InvokedRendered()

    }, [isOpen])

    const handleOpenTicket = async (ticketid: any, customerid: any, id: number) => {
        await postRequest(URL_EndPoints(null)?.markSeenNotification, {
            notification_id: id,
            userid: Number(userid)
        }, null)

        dispatch(ChatTicketID({
            id: ticketid,
            customerid: customerid,
            random: Math.random(),
        }))
    }

    const setPaginationData = (page: any) => {
        if (page > 1) {
            let nextPagination = mainNotification?.slice(notifications?.length - 1, (notifications?.length - 1) + 15)
            let resultAr = [...notifications, ...nextPagination];
            setNotifications(resultAr);
        }
    }

    const handleScrollling = (scroll: any) => {
        let bottom = scroll.target.scrollHeight - scroll.target.clientHeight - scroll.target.scrollTop;
        if (bottom == 0) {
            let page_ = page + 1;
            setPaginationData(page_);
            setPage(page_);
        }
    }


    return (
        <>
            <Drawer
                lockBackgroundScroll={true}
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                className='bla bla bla'
                size={500}
                overlayOpacity={0.1}
                style={{ height: "100%", }}
            >
                <div className='p-4'>
                    <h2>Notifications<button type="button" className="btn btn-icon pulse">
                        <FontAwesomeIcon icon={faBell} color='#009ef7' size='xl' />
                        <span className="pulse-ring"></span>
                    </button></h2>
                    <h4 className='text-end'>{notifications?.length} of {mainNotification?.length}</h4>
                </div>
                <div>

                    <div className='' onScroll={handleScrollling} style={{ height: "90vh", overflowY: "scroll" }}>
                        {
                            !isLoading ?
                                <ul className="list-group list-group-flush">
                                    {
                                        notifications?.map((notification: any) => {
                                            return <li className={"list-group-item " + (notification?.status == 0 ? "notif-read" : "notif-unread")}>
                                                <div className="d-flex justify-content-start">
                                                    <div className='d-flex align-items-center' style={{ paddingRight: '12px', }}>
                                                        <FontAwesomeIcon icon={faCommentAlt} color='#009ef7' className='notification-mail-icon' />
                                                    </div>
                                                    <div className='pl-6 pr-6 align-self-center text-dark px-2' style={{ background: "#f5f4f2" }} onClick={() => handleOpenTicket(notification?.ticketid, notification?.customerid, notification?.id)} id='kt_drawer_chat2_toggle2'>
                                                        <p style={{ marginBottom: 0 }}>{notification?.title}</p>
                                                        <p style={{ marginBottom: 0 }}>{notification?.body}</p>
                                                    </div>

                                                </div>


                                            </li>

                                        })
                                    }
                                </ul>
                                :
                                <div className='d-flex justify-content-center'>
                                    <Spinner animation={'border'} />
                                </div>
                        }
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default NotificationDrawer