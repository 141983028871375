import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getPostMultipleApiHandler, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { champLeadAssign } from './champLeadsUtil'

const BulkChampOwnerAssignForm = ({ showAssignPopUp, setShowAssignPopUp, filteredSelectedRows, toggledClearRows, setToggleClearRows, handleRefresh, setFilteredSelectedRows }: any) => {
    const [isOwnerAssigning, setIsOwnerAssigning] = useState(false);
    const [AdminListData, setAdminListData] = React.useState<any>([])
    const [allSupervisroList, setAllSupervisroList] = React.useState<any>([])
    const [loader, setLoader] = React.useState<any>(false)
    const [ownerAssignPayload, setOwnerAssignPayload] = useState({
        "ownerId": 0,
        "ownerType": 5,
        "ticketIds": [],
        "cleanerIds": [],
    });
    async function getFetchInitialData() {
        setLoader(true)
        const results = await getPostMultipleApiHandler(champLeadAssign, setLoader)
        setAllSupervisroList(results["getSupervisorList"] || [])
        setAdminListData(results["getAlladminlist"] || [])
    }


    useEffect(() => {
        showAssignPopUp && getFetchInitialData()
    }, [showAssignPopUp])


    const handleOwnerAssign = async (e: any) => {
        e.preventDefault();

        let ids: any = []
        let c_ids: any = []

        filteredSelectedRows?.forEach((row: any) => {
            if (row?.cleanerTicketsSingle && row?.cleanerTicketsSingle?.id) {
                ids.push(row?.cleanerTicketsSingle?.id)
            }
            else {
                c_ids.push(row?.id)
            }
        })
        ownerAssignPayload['ticketIds'] = ids;
        ownerAssignPayload['cleanerIds'] = c_ids;
        setIsOwnerAssigning(true);
        const response: any = await postRequest(URL_EndPoints(null)?.bulkAssignOwnerToTickets, ownerAssignPayload, setIsOwnerAssigning)
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
        setFilteredSelectedRows && setFilteredSelectedRows([])
        setIsOwnerAssigning(false)
        setToggleClearRows(!toggledClearRows)
        handleRefresh()
        setShowAssignPopUp(false)


    }
    return (
        <>

            <Modal size='lg' show={showAssignPopUp} onHide={() => setShowAssignPopUp(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Assign owner to {filteredSelectedRows.length} Leads</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleOwnerAssign}>
                        <Form.Group className="mb-3 d-flex flex-column gap-5" >
                            <div className='d-flex gap-3'>
                                <Form.Check
                                    type='radio'
                                    id='admin'
                                    label='Assign Admin'
                                    name='ownerType'
                                    value='5'
                                    checked={ownerAssignPayload?.ownerType === 5}
                                    onChange={() => {

                                        setOwnerAssignPayload((prev) => ({ ...prev, 'ownerType': 5, ownerId: 0, }))
                                    }}
                                />
                                <Form.Check
                                    type='radio'
                                    id='area_manager'
                                    label='Assign Area Manager'
                                    name='ownerType'
                                    value='2'
                                    checked={ownerAssignPayload?.ownerType === 2}
                                    onChange={() => setOwnerAssignPayload((prev) => ({ ...prev, 'ownerType': 2, ownerId: 0, }))}
                                />
                            </div>

                            {ownerAssignPayload?.ownerType === 5 ?
                                (
                                    <Form.Select aria-label="Default select example" value={ownerAssignPayload.ownerId} required name='ownerId' onChange={(e: any) => setOwnerAssignPayload((prev) => ({ ...prev, [e.target.name]: e.target.value }))} >
                                        <option value="0">Select Admin</option>
                                        {AdminListData?.filter((admin: any) => admin?.status === 1)?.map((admin: any) => <option value={admin.id}>{admin.first_name}</option>)}
                                    </Form.Select>
                                )
                                :
                                (
                                    <Form.Select aria-label="Default select example" value={ownerAssignPayload.ownerId} required name='ownerId' onChange={(e: any) => setOwnerAssignPayload((prev) => ({ ...prev, [e.target.name]: e.target.value }))} >
                                        <option value="0">Select Area Manager</option>
                                        {allSupervisroList?.map((am: any) => <option value={am.id}>{am.first_name}</option>)}
                                    </Form.Select>
                                )}
                        </Form.Group>
                        <Form.Group className="mb-3 p-2" >
                            {
                                isOwnerAssigning ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary">
                                        Bulk Assign
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default BulkChampOwnerAssignForm
