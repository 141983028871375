import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postRequest } from '../../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit } from '@fortawesome/free-solid-svg-icons';
import SingleSelectSearchCategoryDefault from '../../../../consts/Select/SingleSelectSearchCategoryDefault';


const UpdateType = ({ row }: any) => {

    const isVacancyAssigned = row?.champ_requirement_completed_champ?.first_name + row?.champ_requirement_completed_champ?.last_name

    const typeDataList = useSelector((store: any) => store.StateMangenementCenter.typeDataList)

    const [show, setShow] = useState<any>(false)
    const [loader, setLoader] = useState<any>(false)
    const [defaultValue, setDefaultValue] = useState<any>(null)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [payload, setPayload] = useState<any>({
        "id": row?.id,
        "updateFields": {
            requirement_id: null
        }
    })

    useEffect(() => { setStoreRow(row) }, [row])

    useEffect(() => {
        const updatedData = typeDataList?.find((types: any) => types?.name == storeRow?.requirementType?.name)
        setDefaultValue(updatedData ? updatedData : null)
        updatedData &&  setPayload({ ...payload, ["updateFields"]: { ...payload?.updateFields, ["requirement_id"]: updatedData?.id} })

    }, [typeDataList, storeRow?.requirementType?.name])



    const updateRequirementType = async () => {

        if (payload?.updateFields?.requirement_id) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints(null)?.updateChampRequirement, payload, setLoader)
            if (response?.data?.status === 200) {
                const updatedData = typeDataList?.find((type: any) => type?.id == payload?.updateFields?.requirement_id)
                row.requirementType = row.requirementType ? {...row.requirementType} : {}
                row.requirementType.name = updatedData?.name
                setStoreRow(row)
                toast.success(response?.data?.msg, { position: "top-center" })
                setShow(false)

            } else {
              
                toast.error(response?.data?.msg ? response?.data?.msg : "Seems server side problem", { position: "top-center" })
                setShow(false)


            }
            setLoader(false)
        }
        else {
            toast.error("Please select location ", { position: "top-center" })
            setLoader(false)

        }
    }


    const OnchangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, ["updateFields"]: { ...payload?.updateFields, [name]: event ? event?.value : null } })
    }


    const modalHandler = (isVacancyFilledBy: any) => {

        // if (isVacancyFilledBy) {
        //     toast.success("Vacancy already filled so action is not allowed !", { position: "top-center" })
        //     return
        // }
        setShow(true)

    }


    return (

        <>
            {
                loader ? "Updating..." : <>
                    <FontAwesomeIcon icon={ faEdit} className={` me-2  cursor-pointer text-${ "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} />
                    {/* <FontAwesomeIcon icon={isVacancyAssigned ? faBan : faEdit} className={` me-2  cursor-pointer text-${isVacancyAssigned ? "danger" : "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} /> */}
                    <span>{storeRow?.requirementType ? storeRow?.requirementType?.name : "NA"}</span>
                </>
            }

            <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-small">
                <Modal.Header closeButton>
                    <Modal.Title>Update requirement name </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-3">
                        <div className="col-12 mb-2">
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={OnchangeHandler}
                                HeaderTitle={"Select Location"}
                                SelectData={typeDataList}
                                DynamicKey={"name"}
                                id={"id"}
                                name={"requirement_id"}
                                defaultData={defaultValue ? { label: defaultValue?.name, value: defaultValue?.id, item: defaultValue } : null}
                            />
                        </div>

                    </div>


                    <div className='d-flex flex-end justify-content-end col-12'>
                        <button
                            className={`btn btn-sm text-${loader ? "dark" : "white"} btn-${loader ? "success" : "primary"} mb-5 `}
                            onClick={updateRequirementType}
                            disabled={loader}
                        >
                            {loader ? "Updating..." : "Update"}

                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        </>








    )
}
export default UpdateType
