import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { toast } from 'react-toastify';
import { URL_EndPoints } from '../../../Server/URL_EndPoints';
import { getRequest, postRequest } from '../../../Server/Requests';
const DeleteReason = ({ show, setshow, row, setStoreRow = null }: any) => {

    const [isLoadingSubmit, setisLoadingSubmit] = useState<any>(false)
    const [reasonSelect, setReasonSelect] = useState<any>("")
    const [reason, setReason] = useState<any>("")
    const [isChecked, setisChecked] = useState<any>(false)
    const [getReasonListData, setgetReasonListData] = useState<any>([])

    async function fetchReasonList() {
        const { data } = await getRequest(URL_EndPoints(null)?.getDeleteReasonList, null)
        setgetReasonListData(data?.data)
    }
    useEffect(() => {
        fetchReasonList()
    }, [])
    const closeBTN = () => {
        setshow(false)
    }
    const subMitResponse = async () => {
        setisLoadingSubmit(true)

        const { data } = await postRequest(URL_EndPoints(null)?.updateUniqueUserHashRow, {
            id: row?.id,
            is_deleted: 1,
            delete_reason: isChecked ? reason : reasonSelect,
        }, setisLoadingSubmit)

        if (data?.status == 200) {
            if (setStoreRow) {  
                row.is_deleted = 1
                setStoreRow(row)
            }
            toast.success(data?.msg, { position: "top-center" })
            closeBTN()

        }
        else {
            toast.error(data?.msg, { position: "top-center" })
            closeBTN()


        }
        setisLoadingSubmit(false)

    }
    return (
        <>


            <Modal size='lg' show={show} onHide={closeBTN} >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Reason </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <Form.Group className="mb-5" >
                            <Form.Select disabled={isChecked} aria-label="Default select example" value={reasonSelect} required name='templateName' onChange={(event) => setReasonSelect(event?.target.value)}>
                                <option value="">Select Reason</option>
                                {getReasonListData?.map((reason: any) => <option value={reason}>{reason}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-5" >
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label=" Either you select reason or write reason youself"
                                checked={isChecked}
                                onChange={() => setisChecked((prev: boolean) => !prev)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail"  >
                            <Form.Label>Write Reason yourself</Form.Label>
                            <Form.Control disabled={!isChecked} type="reason" value={reason} placeholder="Enter Reason here..." onChange={(event) => setReason(event?.target.value)} />

                        </Form.Group>
                        <Form.Group className="mb-3 p-2" >
                            {
                                isLoadingSubmit ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary" onClick={subMitResponse}>
                                        Delete Hash
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>



        </>
    )
}

export default DeleteReason