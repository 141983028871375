import { useState, useRef } from "react";
import { default as ReactSelect, components, InputAction } from "react-select";
import "./styles.css"
import { colourStyles2 } from "../reuseModules/ReactTableComponent/FormStyleCssSheet";
export type Option = {
    value: number | string;
    label: string;
};

export const AdminMultiSelectDefault = (props: any) => {

    const [selectInput, setSelectInput] = useState<string>("");
    const [optionSelected, setSelected] = useState<any>();
    const isAllSelected = useRef<boolean>(false);
    const selectAllLabel = useRef<string>("Select all");
    const allOption = { value: "*", label: selectAllLabel.current };

    const filterOptions = (options: Option[], input: string) =>
        options?.filter(({ label }: Option) =>
            label.toLowerCase().includes(input.toLowerCase())
        );

    const comparator = (v1: Option, v2: Option) =>
        (v1.value as number) - (v2.value as number);

    let filteredOptions = filterOptions(props.options, selectInput);
    let filteredSelectedOptions = filterOptions(optionSelected, selectInput);

    const Option = (props: any) => {
        return (
            <components.Option {...props}>
                {props.value === "*" &&
                    !isAllSelected.current &&
                    filteredSelectedOptions?.length > 0 ? (
                    <input
                        key={props.value}
                        type="checkbox"
                        ref={(input) => {
                            if (input) input.indeterminate = true;
                        }}
                    />
                ) : (
                    <input
                        key={props.value}
                        type="checkbox"
                        checked={props.isSelected || isAllSelected.current}
                        onChange={() => { }}
                    />
                )}
                <label style={{ marginLeft: "5px" }}>{props.label}</label>
            </components.Option>
        )
    };

    const Input = (props: any) => (
        <>
            {selectInput.length === 0 ? (
                <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                    {props.children}
                </components.Input>
            ) : (
                <div style={{ border: "1px dotted gray" }}>
                    <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                        {props.children}
                    </components.Input>
                </div>
            )}
        </>
    );

    const customFilterOption = ({ value, label }: Option, input: string) =>
        (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
        (value === "*" && filteredOptions?.length > 0);

    const onInputChange = (
        inputValue: string,
        event: { action: InputAction }
    ) => {

        if (event.action === "input-change") {
            setSelectInput(inputValue)
        }
        else if (event.action === "menu-close" && selectInput !== "") {
            setSelectInput("");
        }
        props?.setSeacrch && props?.setSeacrch(inputValue)
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if ((e.key === " " || e.key === "Enter") && !selectInput)
            e.preventDefault();
    };

    const handleChange = (selected: Option[]) => {
        if (
            selected.length > 0 &&
            !isAllSelected.current &&
            (selected[selected.length - 1].value === allOption.value ||
                JSON.stringify(filteredOptions) ===
                JSON.stringify(selected.sort(comparator)))
        ) {
            let selectedData: any = [
                ...(optionSelected ?? []),
                ...props.options.filter(
                    ({ label }: Option) =>
                        label.toLowerCase().includes(selectInput?.toLowerCase()) &&
                        (optionSelected ?? []).filter((opt: Option) => opt.label === label)
                            .length === 0
                ),
            ].sort(comparator)
            setSelected(selectedData)
            return props.onChange(
                selectedData, props?.name
            );
        }
        else if (
            selected.length > 0 &&
            selected[selected.length - 1].value !== allOption.value &&
            JSON.stringify(selected.sort(comparator)) !==
            JSON.stringify(filteredOptions)
        ) {
            setSelected(selected)
            return props.onChange(selected, props?.name);
        }
        else {
            let selectedData = [
                ...optionSelected?.filter(
                    ({ label }: Option) =>
                        !label.toLowerCase().includes(selectInput?.toLowerCase())
                ),
            ]
            setSelected(selectedData)
            return props.onChange(selectedData, props?.name);
        }
    };

    // const customStyles = {
    //     multiValueLabel: (def: any) => ({
    //         ...def,
    //         backgroundColor: "lightgray",
    //     }),
    //     multiValueRemove: (def: any) => ({
    //         ...def,
    //         backgroundColor: "lightgray",
    //     }),
    //     valueContainer: (base: any) => ({
    //         ...base,
    //         maxHeight: "65px",
    //         overflow: "auto",
    //     }),
    //     option: (styles: any, { isSelected, isFocused }: any) => {
    //         return {
    //             ...styles,
    //             backgroundColor:
    //                 isSelected && !isFocused
    //                     ? null
    //                     : isFocused && !isSelected
    //                         ? styles.backgroundColor
    //                         : isFocused && isSelected
    //                             ? "#DEEBFF"
    //                             : null,
    //             color: isSelected ? null : null,
    //         };
    //     },
    //     menu: (def: any) => ({ ...def, zIndex: 9999 }),
    // };

    if (props.isSelectAll && props.options.length !== 0) {
        isAllSelected.current =
            JSON.stringify(filteredSelectedOptions) ===
            JSON.stringify(filteredOptions);

        if (filteredSelectedOptions?.length > 0) {
            if (filteredSelectedOptions?.length === filteredOptions?.length)
                selectAllLabel.current = `All (${filteredOptions.length}) selected`;
            else
                selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
        } else selectAllLabel.current = "Select all";

        allOption.label = selectAllLabel.current;

        const customStyles = {
            valueContainer: (base: any) => ({
                ...base,
                maxHeight: "65px",
                overflowY: "auto",
            }),
        };
        return (
            <ReactSelect
                {...props}
                placeholder={props?.title && props?.title}
                value={optionSelected}
                defaultValue={props?.defaultValue ?? []}
                // inputValue={selectInput}
                onInputChange={onInputChange}
                onKeyDown={onKeyDown}
                options={[allOption, ...props.options]}
                onChange={handleChange}
                components={{
                    Option: Option,
                    Input: Input,
                    ...props.components,
                }}
                filterOption={customFilterOption}
                menuPlacement={props.menuPlacement ?? "auto"}
                styles={colourStyles2}
                isMulti
                closeMenuOnSelect={false}
                tabSelectsValue={false}
                backspaceRemovesValue={false}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                style={customStyles}
            />
        );
    }

    return (
        <ReactSelect
            {...props}
            placeholder={props?.title && props?.title}
            defaultValue={props?.defaultValue ?? []}
            // inputValue={selectInput}
            isMulti
            value={optionSelected}
            onInputChange={onInputChange}
            isLoading={props?.isLoading}
            filterOption={customFilterOption}
            components={{
                Input: Input,
                ...props.components,
            }}
            menuPlacement={props.menuPlacement ?? "auto"}
            onKeyDown={onKeyDown}
            tabSelectsValue={false}
            hideSelectedOptions={true}
            backspaceRemovesValue={false}
            blurInputOnSelect={true}
        />
    )
};

