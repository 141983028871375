import React from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG, WithChildren } from '../../../helpers'
import { useLayout } from '../../core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBucket, faList, faMailBulk, faSubscript, faToriiGate , 
  faRedoAlt, 
  faCalendarAlt, 
  faMapMarkerAlt, 
  faTicketAlt, 
  faChartBar, 
  faPhone, 
  faBook, 
  faAddressCard } from '@fortawesome/free-solid-svg-icons'
type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: any
  hasBullet?: boolean
  isSubMenu?: any,
}
const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  isSubMenu,
}
) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config
  let key = ""


  if (title != "Dashboard") {
    key = title?.split(" ")?.join("_")
  }

  return (
    <>
      {
          <div
            className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')}
            data-kt-menu-trigger='click'
          >
            <span className='menu-link'>
              {hasBullet && (
                <span className='menu-bullet'>
                  <span className='bullet bullet-dot'></span>
                </span>
              )}
              {icon && aside.menuIcon === 'svg' && (
                <span className='menu-icon'>
                  <KTSVG path={icon} className='svg-icon-2' />
                </span>
              )}
              {fontIcon && aside.menuIcon === 'font' ? <FontAwesomeIcon icon={fontIcon ?? "fabar"}  className='fs-5'/> :<FontAwesomeIcon icon={fontIcon ?? "fabar" }  className='fs-5'/>}
              {/* {fontIcon && aside.menuIcon === 'font' ? <i className={clsx('bi fs-3', fontIcon)}></i> : <i className="bi bi-menu-button-wide-fill" ></i>} */}
              <span className='menu-title'>&nbsp;&nbsp;{title}</span>
              <span className='menu-arrow'></span>
            </span>
            <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
              {children}
            </div>
          </div>
      }
    </>
  )
}
export { AsideMenuItemWithSub }
