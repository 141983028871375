import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useEffect, useState } from "react"
import DueDateForm from "./DueDateForm";
import 'react-toastify/dist/ReactToastify.css';
import { DateFormate } from "../../toggleColumns/DateFormate";
import PermissionContextProvider from "../../../modules/admins/adminComponents/PermissionContextProvider";
import { adminMenuActionPermissionKeys } from "../../../modules/admins/adminConstant";
import AdminDueDateForm from "../../../modules/admins/adminComponents/AdminDueDateForm";

export const DueDateEditor = ({ row, from, permissionKey }: { row: any, from: string, permissionKey?: keyof typeof adminMenuActionPermissionKeys }) => {
    const [showDueDatePopup, setShowDueDatePopup] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    const userid = JSON.parse(localStorage.getItem("user") || "0")
    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const [ticketDueDate, setTicketDueDate] = useState<any>(storeRow?.tickets?.length > 0 && storeRow?.tickets[0].last_date_resolution  ? storeRow?.tickets[0].last_date_resolution : "")
    const setUpdatedDataDueDate = (payload: any) => {
        setTicketDueDate(payload["due_date"])
    }
    
    return <>
        <span >
            {
                permissionKey ?
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys[permissionKey]}>
                        <FontAwesomeIcon onClick={() => setShowDueDatePopup(true)} icon={faEdit} />
                    </PermissionContextProvider> : <></>
            }
            
            {
                !permissionKey && <FontAwesomeIcon onClick={() => setShowDueDatePopup(true)} icon={faEdit} />
            }

            &nbsp;   {ticketDueDate ?
                // moment(new Date(ticketDueDate)).format('DD-MM-YYYY hh:mm A')
                <DateFormate date={ticketDueDate} from="RenewalList_Due_Date" />
                : "Not Set"}
        </span>

        {
            showDueDatePopup ? <AdminDueDateForm
                show={showDueDatePopup}
                setShow={setShowDueDatePopup}
                setUpdatedData={setUpdatedDataDueDate}
                prevInitialPayload={{
                    ticket_history_id: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0].tickethistory[0]?.id : 0) : 0,
                    ticket_id: storeRow?.tickets?.length > 0 ? storeRow?.tickets[0]?.id : 0,
                    subscriptionid: storeRow?.id,
                    customerid: storeRow?.customerid,
                    user_id: userid,
                    prev_due_date: ticketDueDate,
                    due_date: ticketDueDate,
                }}
                row={row}
                title="Due Date Update"
                apiUrl="updateDueDate"
                dueDateKeyName="due_date"
                isDateTime={true}
                isServerDueDate={false}
                serverApiUrl="xxxxx"
            /> : <></>

        }

        {/* 
        {
            showDueDatePopup ?
                <Dialog
                    open={true}
                    onClose={handleCloseForm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DueDateForm FilteredData={{
                        ticket_history_id: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0].tickethistory[0]?.id : 0) : 0,
                        ticket_id: storeRow?.tickets?.length > 0 ? storeRow?.tickets[0]?.id : 0,
                        subscriptionid: storeRow?.id,
                        customerid: storeRow?.customerid,
                        user_id: userid,
                        prev_due_date: ticketDueDate,
                    }}
                        from={from}
                        handleCloseForm={handleCloseForm} setStoreRow={setStoreRow} row={row} setTicketDueDate={setTicketDueDate} />
                </Dialog>
                : ""
        } */}
    </>
}