import { TableColumn } from 'react-data-table-component';
import ChipsInfos from '../../../consts/Chips/ChipsInfos';
import { cleanerAttendaceInterfaces } from '../../../consts/Inerfaces4az/CleanerAttendance';
import TagsShow from '../../../consts/TagsShow/TagsShow';
import ToolTips from '../../../consts/Tooltip/ToolTips';
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import OpenChat from '../../../consts/champChatDashboard/OpenChat';
export const columns = (unknownData?: any, data?: any) => [
    {
        id: 1,
        reorder: true,
        name: 'ID',
        selector: (row: any) => row.cleaner.id,
        sortable: true,
        minWidth: "70px"
    },

    {
        id: 2,
        reorder: true,
        name: 'STATUS',
        cell: (row: any) => <AttendanceStatus row={row} />,
        grow: 1.5,
        sortable: true,
        minWidth: "200px",
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.attendance_status
            let date2: any = b?.attendance_status
            return date1.toString().toLowerCase().localeCompare(date2.toString().toLowerCase())
        },
    },



    {
        id: 3,
        reorder: true,
        name: <div>CLEAENER <br />NAME</div>,
        cell: (row: any) => {
            return (
                <div className='text-left'>
                    {/* <span id={row.id} className='badge badge-success fs-8 fw-bold'>{row.cleaner.first_name + ' ' + row.cleaner.last_name}</span> */}
                    <OpenChat title={row?.cleaner?.first_name + ' ' + row?.cleaner?.last_name} champId={row?.cleaner?.id} trackData={data} />

                    <ToolTips toolTipId={row?.id} ToolTipShowData={row?.cleaner?.phone ? row?.cleaner?.phone : "NA"} place={""} from="cleaner" />

                    <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-2 mt-2'>
                        <TagsShow Tag={row?.cleaner?.private_tag} />
                    </div>
                </div>
            )
        },
        grow: 1.5,
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.cleaner?.first_name
            let date2: any = b?.cleaner?.first_name
            return date1.toString().toLowerCase().localeCompare(date2.toString().toLowerCase())
        },
        sortable: true,
        minWidth: "200px"
    },


    {
        id: 4,
        reorder: true,
        name: <div>ATTENDANCE <br />DATE</div>,
        selector: (row: { attendance_date: any }) => row.attendance_date ? row.attendance_date : "NA",
        sortable: true,
        minWidth: "150px"
    },

    {
        id: 5,
        reorder: true,
        name: <div>MARK <br />ATTENDANCE DATE</div>,
        selector: (row: { mark_attendance_datetime: any }) => row.mark_attendance_datetime ? row.mark_attendance_datetime : "NA",
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 6,
        reorder: true,
        name: <div>TOTAL <br />JOB DONE</div>,
        cell: (row: any) => (
            <ChipsInfos SelectedString={row?.total_jobs_done} classes={"primary"}></ChipsInfos>
        ),
        sortable: true,
        minWidth: "150px",
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.total_jobs_done
            let date2: any = b?.total_jobs_done
            return date1.toString().toLowerCase().localeCompare(date2.toString().toLowerCase())
        },
    },
    {
        id: 7,
        reorder: true,
        name: <div>TOTAL <br />POINTS EARNED</div>,
        cell: (row: any) => (
            <ChipsInfos SelectedString={row?.total_points_earned} classes={"primary"}></ChipsInfos>
        ),
        sortable: true,
        minWidth: "200px",
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.total_points_earned
            let date2: any = b?.total_points_earned
            return date1.toString().toLowerCase().localeCompare(date2.toString().toLowerCase())
        },
    },
    {
        id: 8,
        reorder: true,
        name: 'LEAVE DATE',
        selector: (row: { leave_date: any }) => row.leave_date ? row.leave_date : "NA",
        sortable: true,
        minWidth: "200px",

    },
    {
        id: 9,
        reorder: true,
        name: 'LEAVE REASON',
        selector: (row: { leave_detail: any }) => row.leave_detail?.name ? row.leave_detail?.name : "NA",
        sortable: true,
        minWidth: "150px"
    },
    {
        id: 10,
        reorder: true,
        name: 'LEAVE STATUS',
        selector: (row: { leave_detail: any }) => row.leave_detail?.absent_status ? row.leave_detail?.absent_status : "NA",
        sortable: true,
        minWidth: "150px"
    },
];

const AttendanceStatus = ({ row }: any) => {

    const handleattendanceStatusChange = async (e: any) => {
        const data = await postRequest(URL_EndPoints(null)?.changeCleanerAttendanceStatus, {
            "id": row.id,
            "attendance_status": e.target.value,
        }, null)

        if (data.status == 200) {
            toast.success("Status changed successfully")
        }
    }
    return (
        <div className='text-left'>
            <select onChange={handleattendanceStatusChange} name="attendance_status" id="attendance_status" className="form-control attendanceStatus" >
                <option value="pending" selected={row.attendance_status == "pending" ? true : false} >Pending</option>
                <option value="marked" selected={row.attendance_status == "marked" ? true : false}>Marked</option>
                <option value="job_done" selected={row.attendance_status == "job_done" ? true : false}>Job Done</option>
                <option value="admin_verified" selected={row.attendance_status == "admin_verified" ? true : false}>✓ Admin Verified</option>
                <option value="absent" selected={row.attendance_status == "absent" ? true : false}>Absent</option>
                <option value="leave" selected={row.attendance_status == "leave" ? true : false}>Leave</option>
                <option value="any_job_reassign" selected={row.attendance_status == "any_job_reassign" ? true : false}>Any Job Reassigned To Other Cleaner</option>
            </select>
        </div>
    )
}