import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getName } from '../../admins/adminUtils'
import ViewGoodReceiptItems from '../viewItemsList/ViewGoodReceiptItems'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { GoodItemListColumn } from '../viewItemsList/GoodItemListColumn'

export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },
  {
    name: 'Media',
    cell: (row: any) => <img src={row?.media} alt="" style={{ width: "50px", height: "50px" }} />,
    reorder: true,
    id: 3,
    width: '100px',
  },

  {
    name: 'Notes',
    cell: (row: any) => row?.notes,
    sortField: 'notes',
    sortable: true,
    reorder: true,
    id: 4,
    width: '150px',
  },
  {
    name: 'Received Date',
    cell: (row: any) => row?.received_date,
    sortField: 'received_date',
    sortable: true,
    reorder: true,
    id: 5,
    width: '120px',
  },
  {
    name: 'Received By',
    cell: (row: any) => getName(row?.goodsReceiptAdAdmin),
    sortField: 'goodsReceiptAdAdmin.first_name',
    sortable: true,
    reorder: true,
    id: 6,
    width: '200px',
  },
  {
    name: 'Purchase Order Id',
    cell: (row: any) => row?.goodsReceiptPurchaseOrder?.purchase_order_id,
    sortField: 'goodsReceiptPurchaseOrder.purchase_order_id',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },

  {
    name: 'Status',
    cell: (row: any) => row?.goodsReceiptStatus?.status,
    sortField: 'goodsReceiptStatus.status',
    sortable: true,
    reorder: true,
    id: 8,
    width: '200px',
  },
  {
    name: 'Vendor invoice Id',
    cell: (row: any) => row?.vendor_invoice_id,
    sortField: 'vendor_invoice_id',
    sortable: true,
    reorder: true,
    id: 9,
    width: '120px',
  },
  {
    name: 'invoice Number',
    cell: (row: any) => row?.goodsReceiptVendorInvoice?.invoice_number,
    sortField: 'goodsReceiptVendorInvoice.invoice_number',
    // sortable: true,
    reorder: true,
    id: 10,
    width: '120px',
  },
  {
    name: 'Good Receipt Items',
    cell: (row: any) => <GoodReceiptItems row={row} />,
    sortField: 'updatedAt',
    sortable: true,
    reorder: true,
    id: 11,
    width: '120px',
  },
  {
    name: 'updatedAt',
    cell: (row: any) => moment(row?.updatedAt).format("MM-DD-YYYY"),
    sortField: 'updatedAt',
    sortable: true,
    reorder: true,
    id: 12,
    width: '120px',
  },


]

const GoodReceiptItems = ({ row }: any) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <span><FontAwesomeIcon className='me-2 ms-2 text-muted fs-4 cursor-pointer' icon={faEye} onClick={() => setShow(true)} /></span>
      {
        show ? <ViewGoodReceiptItems
          title="Good Receipt Items"
          show={show}
          listData={row?.goodsReceiptWithtItems}
          setShow={setShow}
          modalLoader={false}
          columns={GoodItemListColumn()}
        /> : <></>
      }
    </>
  )
}
// export default GoodReceiptItems