import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { getPostMultipleApiHandler, getRequest, postRequest } from '../../../consts/Server/Requests'
import { adminGetFilterDataBySearch, adminKeyWiseComponents, adminToastRunner, validateForm } from '../adminUtils'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import AdminTextBoxReuse from './adminFormComponents/AdminTextBoxReuse'

const AdminBulkForm = ({ show, setShow, formData, initialData, filteredSelectedRows, setFilteredSelectedRows, toggledClearRows, setToggleClearRows, refreshNow, toastMessage, keyName, formTitle, bulkKeyName, formSubmitApiUrl }: any) => {
    const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
    const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
    const [filterDataList, setFilterDataList] = useState<any>({})
    const [payload, setPayload] = useState<any>(initialData);
    const [deFaultPayload, setDeFaultPayload] = useState<any>({});
    const [formErrors, setFormErrors] = useState<any>({});
    const [isWaTemplateLoading, setIsWaTemplateLoading] = useState<boolean>(false)
    const [waTemplateData, setWaTemplateData] = useState<any>([])

    async function getFetchInitialData() {
        setIsLoadingFilter(true)
        const results = await getPostMultipleApiHandler(formData, setIsLoadingFilter, true)
        setFilterDataList(results)
    }
    useEffect(() => {
        show && getFetchInitialData()
    }, [show])

    const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
    const [dynFilter, setDynFilter] = useState<any>({})

    const setSearchFilter = (event: any, filters: any) => {
        setDynFilter(filters)
        setStoreSearchKeywords(event)
    }

    useEffect(() => {
        let x = setTimeout(async () => {
            setIsLoadingFilter(true)
            storeSearchKeywords && await adminGetFilterDataBySearch(dynFilter, setFilterDataList, filterDataList, setIsLoadingFilter, { keyword: storeSearchKeywords })
            setIsLoadingFilter(false)
        }, 2000)
        return () => {
            setIsLoadingFilter(false)
            clearTimeout(x)
        }
    }, [storeSearchKeywords, dynFilter])

    const onChangeHandler = async(event: any, name: any, isDate?: any) => {
        try {
            if(name === "templateName") {
                setIsWaTemplateLoading(true)
                let QeueryParams = {
                    To: "getWhatsAppTemplateSingle",
                    What: `?templateName=${event ? event?.value : event}`
                }
                const res = await getRequest(URL_EndPoints(QeueryParams)?.["getWhatsAppTemplateSingle"], setIsWaTemplateLoading)
                setWaTemplateData(res?.data?.data)
            }

            setPayload({
                ...payload,
                [name]: event ? event?.value : event,
            })
            setDeFaultPayload({
                ...deFaultPayload,
                [name + "_" + "item"]: event ? event?.item : {},
            })
        } catch (error) {
            
        } finally {
            setIsWaTemplateLoading(false)
        }
    }

    const submitForm = async (e: any) => {
        e.preventDefault()
        if (validateForm(formData, payload, setFormErrors)) {
            setIsBulkAssignApiLoading(true)
            if (bulkKeyName) {
                payload[bulkKeyName] = filteredSelectedRows
            }
            const response: any = await postRequest(URL_EndPoints(null)?.[formSubmitApiUrl], payload, setIsBulkAssignApiLoading)
            if (response?.data?.status == 200) {
                setShow(false)
            }
            adminToastRunner(response, toastMessage)
            setFilteredSelectedRows && setFilteredSelectedRows([])
            setToggleClearRows && setToggleClearRows(!toggledClearRows)
            refreshNow && refreshNow()

        } else {
            toast.error("Form has validation errors !", { position: "top-center" })
        }
    }

    useEffect(() => {
        let removeErrors: any = { ...formErrors }
        Object.keys(payload)?.forEach((element) => {
            if (payload[element]) {
                removeErrors[element] = ""
            }
        })
        setFormErrors(removeErrors)
    }, [payload])

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="small-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                key={keyName}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitForm}>
                        {
                            formData?.map((element: any, index: any) => {
                                let valueType = element?.valueType
                                let filterApi = element?.filterApi
                                let CurrentComponent: any = adminKeyWiseComponents()?.[valueType]
                                let searchable = element?.searchable ? true : false
                                let storeFilterList = filterDataList[filterApi] ? filterDataList[filterApi] : []
                                let dateError = formErrors[element?.from] ?? formErrors[element?.to]
                                let errMessage = element.valueType === 'multiDate' && (formErrors[element?.from] || formErrors[element?.to]) ? dateError : formErrors[element?.name]
                                let isRquiredToggleColor = element.valueType === 'multi' &&
                                    Array.isArray(payload[element.name]) &&
                                    payload[element.name].length > 0 || element.valueType == 'multiDate' && (payload[element.from] && payload[element.to]) || element.valueType != 'multi' && element.name && payload[element.name]
                                let uniqueKey = keyName + valueType + "_" + index

                                return <Form.Group className="mb-3" key={uniqueKey} >
                                    <div className='mb-3' style={{ position: "relative" }}>
                                        <CurrentComponent
                                            onChangeHandler={onChangeHandler}
                                            HeaderTitle={element?.HeaderTitle}
                                            payload={payload}
                                            deFaultPayload={deFaultPayload}
                                            SelectData={storeFilterList}
                                            displayFields={element?.displayFields}
                                            id={element?.valueField}
                                            name={element?.name}
                                            defaultData={null}
                                            searchable={searchable}
                                            setSeacrch={setSearchFilter}
                                            isLoading={dynFilter?.filterApi == element?.filterApi ? isLoadingFilter : false}
                                            filters={element}
                                            from={element?.from}
                                            to={element?.to}
                                            inputType={element?.inputType}
                                            label={element?.label}
                                            isRquiredToggleColor={isRquiredToggleColor}
                                            valueType={valueType}
                                            isRequired={element?.isRequired}
                                        />
                                        {/* {element?.isRequired && element?.valueType != "checkbox" && <IsReuiredFieldIcon color={isRquiredToggleColor && "green"} marginTop={element?.valueType == "textArea" ? "-10px" : element?.valueType == "multiDate" ? "-3px" : ""} />} */}
                                        {errMessage && <Form.Label className='text-danger fs-8'>
                                            <FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger fs-fs-8' />{errMessage}</Form.Label>}
                                    </div>
                                </Form.Group>
                            })
                        }
                        
                        {isWaTemplateLoading == false && waTemplateData?.is_params_required > 0 ? <>
                                {(waTemplateData?.params ? JSON.parse(waTemplateData?.params) : [])?.map((element: any, index: any) => {
                                    return <Form.Group key={'wa_template_' + index} >
                                            <div>
                                                <AdminTextBoxReuse
                                                    onChangeHandler={onChangeHandler}
                                                    HeaderTitle={`Enter ${element}`}
                                                    payload={payload}
                                                    name={`params_$${element}`}
                                                />
                                            </div>
                                        </Form.Group>
                                })}
                         </> : null}

                         
                        <Form.Group className="mb-3 p-2 text-end" >
                            {
                                <Button type='submit' variant={isBulkAssignApiLoading ? "success" : "primary"} >
                                    {isBulkAssignApiLoading ? "Please wait..." : "Submit"}
                                </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default AdminBulkForm
