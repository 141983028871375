import React from 'react';
import { withSwal as LogoutWarning } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
export default LogoutWarning(({ swal, confirm, cancel, payload, render, setLogoutWarinig, successTitle, FailedTitle }: any) => {
    React.useEffect(() => {
        if (render) {
            swal.fire({
                title: 'Logout temporary ',
                confirmButtonText: confirm,
            }).then(async (result: any) => {
                if (result.isConfirmed) {
                    let userid = localStorage.getItem("user") || 0
                    const isLogoutSuccess = await postRequest(URL_EndPoints(null)?.logout, {
                        userid: userid,
                    }, null)
                    setLogoutWarinig(null)
                    if (isLogoutSuccess?.data?.status == 200) {
                        localStorage.removeItem("user");
                        localStorage.removeItem("auth-token");
                        swal.fire(isLogoutSuccess?.data?.msg)
                        window.location.reload();
                    }
                    else {
                        swal.fire(isLogoutSuccess?.data?.msg)
                    }
                }
            })
        }
    }, [render])
});
