import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router'
import { checkIsActive, KTSVG, WithChildren } from '../../../helpers'
import { useLayout } from '../../core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { setQueryParameter } from '../../../../app/consts/components/Utility'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: any
  hasBullet?: boolean
  isSubMenu?: any
  sub_menu_id?: any,
  setQueryParameter?: any,
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isSubMenu,
  sub_menu_id,
  setQueryParameter,
}) => {
  const NavigateTo = useNavigate()

  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config

  let key = ""
  if (title != "Dashboard") {
    key = title?.split(" ")?.join("_")
  }

  // onClick={() => setQueryParameter(sub_menu_id)}
  // onClick={() => setQueryParameter(sub_menu_id)}

  return (
    <>

      {
        isSubMenu && isSubMenu[key] == false ? <></> :

        <div className='menu-item' >
          <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}  >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>

            )}
            {/* {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )} */}
            {fontIcon && <FontAwesomeIcon icon={fontIcon ?? "fabar"}  className='fs-5'/>}
            {/* {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>} */}
            <span className='menu-title'>&nbsp; {title}</span>
          </Link>
          {children}
        </div>
      }


    </>
  )
}

export { AsideMenuItem }
