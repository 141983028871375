import {  KTCardBody } from "../../../_metronic/helpers"

function Unauthorized() {
  return (
    <KTCardBody className='text-center fs-4'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <img className="img-fluid" style={{width:"300px", height:"200px"}} src="https://preview.redd.it/f04laeo40w391.gif?width=480&auto=webp&s=54d034eed676bb0d32c80141541d8480f597cc8e" alt="" />
                <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
                  <span className="display-1 fw-bold">4</span>
                  <i className="bi bi-exclamation-circle-fill text-danger display-4"></i>
                  <span className="display-1 fw-bold bsb-flip-h">1</span>
                </h2>
                <h3 className="h2 mb-5 text-danger">Oops! You are unauthorized to access this page!.</h3>
                {/* <p className="mb-5">We are really sorry to welcome like this... 😂😂😂😂😂😂</p> */}
                <a className="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0" href="/" role="button">Back to Home</a>
              </div>
            </div>
          </div>
        </div>
    </KTCardBody>
  )
}

export default Unauthorized