
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import { renderToString } from 'react-dom/server';
import Handsontable from 'handsontable';
import { colWidthsHotTable, handleAfterSelectionRow, hotInstanceLoader, searchFieldKeyupCallback, cellRenderInner } from '../admins/adminUtils';

import moment from 'moment';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { KTCardBody } from '../../../_metronic/helpers';
import { Tooltip as ReactTooltip } from "react-tooltip";
import AdsInsightsFilter from './AdsInsightsFilter';
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import MomentDate from '../../consts/MomentDate';

registerAllModules();
const intervalType = [{ name: "Daily", id: "day" }, { name: "Weekly", id: "week" }, { name: "Monthly", id: "month" }]

const defaultRowsIDs: any = [10, 19, 20]

const dataTypeListData: any = [{ name: "Ad", id: "ad" }, { name: "Adset", id: "adset" }, { name: "Campaign", id: "campaign" }, { name: "Ad Account", id: "account" }]

export const AdsInsightsList = () => {
    const hotTableComponentRef = useRef<any>(null);
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState(false)
    const [filterData, setFilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
    const [agentListData, setAgentListData] = useState<any>([])
    const [adStatusListData, setAdStatusListData] = useState<any>([])
    const [adsListData, setAdsListData] = useState<any>([])
    const [campaignsListData, setCampaignsListData] = useState<any>([])
    const [adsetsListData, setAdsetsListData] = useState<any>([])
    const [adAccountsListData, setAdAccountsListData] = useState<any>([])
    const [rowsFilterData, setRowsFilterData] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        startDate:moment().startOf("month").format("YYYY-MM-DD"),
        endDate: MomentDate(0),
        interval: "day",
        selectedDisplayValues: defaultRowsIDs,
        userType: 3,
        status: "",
        // agent_id: []
    })


    let firstTimeNotRun = useRef<any>(false)
    useEffect(() => {
        let x = setTimeout(() => {
            const filterSearchData = AllFielSearch(data, search)
            firstTimeNotRun?.current === true && setFilterData(filterSearchData)
            if (firstTimeNotRun?.current === false) {
                firstTimeNotRun.current = true
            }
        }, 2000)
        return () => {
            clearTimeout(x)
        }
    }, [search])


    async function getPaymentCountsList() {
        setFilteredSelectedRows([])
        setPending(true)
        const ticketCountData = await postRequest(URL_EndPoints(null)?.getAdsInsightDataInTimeSeriesV2, payload, setPending)
        setColumns(ticketCountData?.data?.columns || [])
        setFilterData(ticketCountData?.data?.data || [])
        setData(ticketCountData?.data?.data || [])
    }

    // getAdInsightsRows

    const getActionsList = async () => {
        const [
            agentList,
            getSalesRows, 
            adStatus,
            ads,
            campaigns,
            adsets,
            adAccounts
        ] = await Promise.all([
            getRequest(URL_EndPoints(null)?.getAdminList, null),
            getRequest(URL_EndPoints(null)?.getAdInsightsRows, null),
            getRequest(URL_EndPoints(null)?.getAdStatuses, null),
            getRequest(URL_EndPoints(null)?.getFacebookAds, null),
            getRequest(URL_EndPoints(null)?.getFacebookCampaigns, null),
            getRequest(URL_EndPoints(null)?.getFacebookAdsets, null),
            getRequest(URL_EndPoints(null)?.getFacebookAdAccounts, null)
        ])

        setAdStatusListData(adStatus?.data?.data)
        setAgentListData(agentList?.data?.data?.filter((ele: any) => ele?.roleid == 2))
        setRowsFilterData(getSalesRows?.data?.data)
        setAdsListData(ads?.data?.data)
        setCampaignsListData(campaigns?.data?.data)
        setAdsetsListData(adsets?.data?.data)
        setAdAccountsListData(adAccounts?.data?.data)
    }

    useEffect(() => {
        getPaymentCountsList()
        getActionsList()
    }, [])

    const searchHandler = () => {
        getPaymentCountsList()
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
    }

    const removeUnderScore = (str: any) => {
        if (str?.includes("_")) {
            return str?.split("_")?.join(" ")?.toUpperCase()
        }
        else {
            return str?.toUpperCase()
        }
    }

    useEffect(() => {
        hotInstanceLoader(hotTableComponentRef)
    }, []);

    const cellRenderer = (...args: any) => {
        cellRenderInner(args, Handsontable)
    }

    const shortWords = (sentence: any, index: any) => {
        if (index < 2) { return removeUnderScore(sentence) }
        else if (index == 1 || sentence?.includes("_")) { removeUnderScore(sentence) }
        return removeUnderScore(sentence?.split(' ')[1])
    }

    const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
        handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
    };


    return (
        <>
            <KTCardBody>
                <div className='mb-4 fs-4 fw-semibold'>Facebook Insights</div>
                <MainContainerWrapper>
                    <LeftBox>
                        <SearchBoxModal search={search} setSearch={setSearch} />
                    </LeftBox>
                    <RightBox>
                        <Refresh refreshNow={searchHandler} />
                        {
                            <DropDownFilterMenu
                                ParentData={{
                                    handleActions: searchHandler,
                                    payloads: payload,
                                    onChangeHandler: onChangeHandler,
                                    intervalType: intervalType,
                                    // agentListData: agentListData,
                                    rowsFilterData: rowsFilterData,
                                    adStatusListData: adStatusListData,
                                    defaultInterval: { label: "Daily", value: "day" },
                                    defaultRows: rowsFilterData?.filter((ele: any) => defaultRowsIDs?.includes(ele.id))?.map((ele: any) => ({ label: ele?.name, value: ele?.id })),
                                    showListType: true,
                                    defaultUserType: null,
                                    defaultAdStatus: null,
                                    adsListData: adsListData,
                                    campaignsListData: campaignsListData,
                                    adsetsListData: adsetsListData,
                                    dataTypeListData,
                                    defaultDataType: { label: "Ad Account", value: "account" },
                                    adAccountsListData: adAccountsListData
                                }}
                                Component={AdsInsightsFilter}
                            ></DropDownFilterMenu>
                        }
                    </RightBox>
                </MainContainerWrapper>
                {/* hint components */}

                {pending ? <IsLoading /> : <div >

                    <HotTable
                        ref={hotTableComponentRef}
                        style={{ zIndex: 1 }}
                        data={filterData}
                        columns={columns?.map((ele: any, index: any) => {
                            return {
                                title: renderToString(<div id={ele} >{shortWords(ele, index)} </div>),
                                type: 'text',
                                data: ele,
                                renderer: cellRenderer
                            }
                        })}
                        className='AdsInsightsList'
                        filters={true}
                        dropdownMenu={true}
                        colWidths={colWidthsHotTable(columns, 2, 150, 55)}
                        width="100%"
                        height={"80vh"}
                        title='Ads Insights'
                        rowHeaders={true}
                        colHeaders={true}
                        fixedColumnsLeft={2}
                        contextMenu={true}
                        manualColumnFreeze={true}
                        autoWrapRow={true}
                        autoWrapCol={true}
                        licenseKey="non-commercial-and-evaluation"
                        autoRowSize={true}
                        autoColumnSize={true}
                        readOnly={true}
                        manualRowResize={true}
                        manualColumnResize={true}
                        manualRowMove={true}
                        multiColumnSorting={true}
                        search={{ callback: searchFieldKeyupCallback }}
                        afterSelection={handleAfterSelection}
                    // afterDeselect={handleAfterDeselect}
                    />
                </div>}

            </KTCardBody>

            {columns?.map((ele: any) => (
                <ReactTooltip
                    anchorId={ele}
                    place={"top"}
                    className="bg-dark"
                    content={ele}
                />
            ))}

        </>
    );
};

