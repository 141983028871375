import { Dropdown } from "react-bootstrap"
import "./toggles.css"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"


export const ToggleColumns = ({ columns, from }: any) => {

    // rule to create the key is == from+_+CREEATED_AT 
    const [toggle, setToggle] = useState<any>({})
    const dispatch = useDispatch()

    const handleSortCheckbox = (event: any) => {

        let actualKey = from + " " + event.target.value
        const toggleColumns: any = JSON.parse(localStorage.getItem("toggleColumns") || "{}") || {}
        
        toggleColumns[actualKey?.split(" ")?.join("_")] = !toggleColumns[actualKey?.split(" ")?.join("_")]
        localStorage.setItem("toggleColumns", JSON.stringify(toggleColumns))

        setToggle(toggleColumns)
        dispatch({ type: "TOGGLE_COLUMNS", payload: toggleColumns })
    }

    useEffect(() => {

        const toggleColumns: any = JSON.parse(localStorage.getItem("toggleColumns") || "{}") || {}
        setToggle(toggleColumns)

    }, [from])
    // mx-2

    return <>
        <Dropdown className="d-inline mx-2 mb-2 btn-sm me-3 mx-2 " autoClose="outside" key={111}>
            <Dropdown.Toggle id="dropdown-autoclose-outside" className="btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn ">
                Toggle Formate
            </Dropdown.Toggle>
            <Dropdown.Menu className="tanslateIt">

                {columns.map((column: any, index: any) => {
                    let actualKeys = from + " " + column?.name
                    return <li className='dropdown-item' key={index}> <input onChange={handleSortCheckbox} type="checkbox" name="fields" value={column.name} id="" checked={toggle[actualKeys?.split(" ")?.join("_")]} /> {column.name}</li>
                })}
                
            </Dropdown.Menu>
        </Dropdown>
    </>
}