import React from 'react';
import { withSwal as RevertFirstTrainingTaskList } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { adminToastRunner } from '../../modules/admins/adminUtils';
export default RevertFirstTrainingTaskList((props: any) => {
    const { swal, confirm, cancel, payload, successTitle, FailedTitle, setShow, show, refreshNow }: any = props;

    async function sendRequestToServer() {
        const response = await postRequest(URL_EndPoints(null)?.revertTrainingTask, payload, null)
        adminToastRunner(response, "message")
        refreshNow && response?.data?.status == 200 && refreshNow()
        setShow && setShow(null)
    }

    React.useEffect(() => {
        if (show) {
            swal.fire({
                title: 'Do you want to revert first training ?',
                showDenyButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    sendRequestToServer()
                } else if (result.isDenied) {
                    setShow(null)
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [show])
});
