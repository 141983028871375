import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { postRequest } from '../../Server/Requests';
import SingleSearchCategory from '../../Select/SingleSearchCategory';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
const ChangeWhatsappMessage = ({ FilteredData, customerDetailData, WhatsappMessageid }: any) => {

const getTicketByCustomer = useSelector((store:any)=>store.ChatReducers.getTicketByCustomer)

    const [loader, setloader] = useState(false);
    const [NewticketId, setNewticketId] = useState<any>(null)

    const handleChangeInputData = (event: any, name: any) => {
        if (event == null) {
            setNewticketId(null)
        }
        else {
            setNewticketId(event?.value)
        }
    }


    const MesageChangeHandler = async () => {
        setloader(true)
        if (NewticketId) {
            const shift_WhatsAppMessageFromOneToAnother = await postRequest(URL_EndPoints()?.shift_WhatsAppMessageFromOneToAnother, {
                ticketId: NewticketId,
                whatsAppMessageId: WhatsappMessageid?.whatsappMessageId
            }, setloader)
            if (shift_WhatsAppMessageFromOneToAnother?.data?.status == 200) {
                setloader(false)
                toast.success("Message Shifted Successfully", { position: "top-center" })
                FilteredData?.handleClose()
            }
        }
        else {
            toast.error("ticket id missing", { position: "top-center" })
            setloader(false)
        }
    }


    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title> Change Whatsapp Message </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SingleSearchCategory
                        handleChangeInputData={handleChangeInputData}
                        HeaderTitle='Select Ticket'
                        SelectData={getTicketByCustomer}
                        DynamicKey={'title'}
                        id={'id'}
                        name=''
                    ></SingleSearchCategory>
                    {/* </div> */}
                    <div className='d-flex  align-items-center justify-content-end ms-3 '>
                        <button dir="rtl" className="btn btn-sm btn-success mt-4 " onClick={MesageChangeHandler} disabled={loader}>
                            {'Shift Message'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}


export default ChangeWhatsappMessage
{/* <ChangeWhatsappMessage FilteredData={{
    loader,
    setClickUpValue,
    ClickUpValue,
    handleClose: closeBtn,
    clcikUpCallHandler,
    clcikUpWhatsappHandler,
    setdisableWhatsappButton,
    disableWhatsappButton,
    setTemplateid,
    ClickUpShow,
  }} handleCloseForm={closeBtn}></ChangeWhatsappMessage> */}