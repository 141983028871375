import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import ChampReferralBonus from '../../../../modules/ChampReferralBonus/ChampReferralBonus'
import TaskListController from '../../../../modules/taskLists/TaskListController'
import RenewalTaskList from '../../../../modules/RenewalTaskList/RenewalTaskList'
import RewardAndPenaltiesList from '../../../../modules/RewardAndPenaltiesList/RewardAndPenaltiesList'
import RatingList from '../../../../modules/RatingsV2/RatingList'

const ChampRewardAndPenalties = ({ cleaner_id }: any) => {
    let taskListFilterData = {
        ["isFilter"]: true,
        ["taskListFilter"]: { "cleanerid": { value: [cleaner_id], multiVal: true, operator: "==", isDate: false, timestamp: false } },
    }

    const [key, setKey] = useState<number>(111)
    const [filterDataOutsource, setFilterDataOutsource] = useState<any>({})

    useEffect(() => {
        if (key == 111) {
            setFilterDataOutsource(taskListFilterData)
        }
    }, [key])

    const handleSelect = (keyValue: any) => {
        if (keyValue == 111) {
            setFilterDataOutsource(taskListFilterData)
        }
        else if (keyValue == 222) {
            setFilterDataOutsource({
                ["isFilter"]: true,
                ["isReference"]: true,
                ["renwalTaskListFilter"]: { "$attendencecleaner.id$": { value: cleaner_id, multiVal: false, operator: "==", isDate: false, timestamp: false } },
            })
        }
        else if (keyValue == 333) {
            setFilterDataOutsource({
                ["isFilter"]: true,
                ["champReferralBonusFilter"]: { "referBy": { value: [cleaner_id], multiVal: true, operator: "==", isDate: false, timestamp: false } },
            })
        }
        else if (keyValue == 444) {
            setFilterDataOutsource({
                ["isFilter"]: true,
                ["rewardAndPenaltiesFilter"]: { "$cleaner.id$": { value: cleaner_id, multiVal: false, operator: "==", isDate: false, timestamp: false } },
            })
        }
        else if (keyValue == 555) {
            setFilterDataOutsource({
                ["isFilter"]: true,
                ["ratinglist2Filter"]: { "cleanerid": { value: cleaner_id, multiVal: false, operator: "==", isDate: false, timestamp: false } },
            })
        }
        else {
            setFilterDataOutsource({})
        }
        setKey(keyValue)
    }


    return (
        <>

            <div className='p-2'>
                <Tabs activeKey={key} onSelect={handleSelect} id='controlled-tab-example2'>

                    <Tab eventKey={111} title='Task Rewards'>
                        {key == 111 && filterDataOutsource?.isFilter == true ? <TaskListController filterDataOutsource={filterDataOutsource} /> : <></>}
                    </Tab>

                    <Tab eventKey={222} title='Renewal Rewards'>
                        {key == 222 && filterDataOutsource?.isFilter == true ? <RenewalTaskList filterDataOutsource={filterDataOutsource} /> : <></>}

                    </Tab>
                    <Tab eventKey={333} title='Referral Rewards'>
                        {key == 333 && filterDataOutsource?.isFilter == true ? <ChampReferralBonus filterDataOutsource={filterDataOutsource} /> : <></>}

                    </Tab>
                    <Tab eventKey={444} title='All Rewards And Penalties'>
                        {key == 444 && filterDataOutsource?.isFilter == true ? <RewardAndPenaltiesList filterDataOutsource={filterDataOutsource} /> : <></>}
                    </Tab>
                    <Tab eventKey={555} title='Rating Rewards'>
                        {key == 555 && filterDataOutsource?.isFilter == true ? <RatingList filterDataOutsource={filterDataOutsource} /> : <></>}
                    </Tab>

                </Tabs>
            </div>


        </>
    )
}

export default ChampRewardAndPenalties
