import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'
import { getRequest, postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import CustomerDefaultSelect from '../../Select/CustomerDefaultSelect';

const UpdateReferByChamp = ({ handleCloseForm, show, row, setRowData }: any) => {
    const [payload, setPayload] = useState<any>({
        "champ_id": row?.id,
        "referred_by": null,
        "referred_by_type": null,
        item: null
    })

    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)



    // search filter  
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchDataCleaner, setsearchDataCleaner] = useState<any[]>([])
    const [storeSeachKeywords, setstoreSeachKeywords] = useState<any>("")


    async function getSearchDataBykeyword(storeSeachKeywords: any) {
        setIsLoading(true)
        const response: any = await postRequest(URL_EndPoints()?.getChampsList, {
            keyword: storeSeachKeywords,
            cleaner_id: row?.id,
        }, setIsLoading);

        let newData = response?.data?.data?.map((ele: any) => {
            ele.first_name = ele?.first_name ?? "NA"
            ele.last_name = ele?.last_name ?? "NA"
            ele.phone = ele?.phone ?? "NA"
            return ele
        })

        newData && setsearchDataCleaner(newData);
        setIsLoading(false)
    }

    const handleSelectChange = (event: any, name: any) => {
        setPayload({ ...payload, ["referred_by"]: event ? event?.value : null, "referred_by_type": event ? event?.item?.userType : null, item: event ? event?.item : null })
    }

    useEffect(() => {
        let x = setTimeout(() => {
            storeSeachKeywords && getSearchDataBykeyword(storeSeachKeywords)
        }, 2000)
        return () => {
            setIsLoading(false)
            clearTimeout(x)
        }
    }, [storeSeachKeywords])

    // search filter  

    const handUpdateReferBy = async () => {
        if (payload?.champ_id && payload?.referred_by && payload?.referred_by_type) {
            setLoader(true)
            const result = await postRequest(URL_EndPoints()?.updateChampReferralXX, payload, setLoader)
            if (result?.data?.status == 200) {
                if (setRowData) {
                    row.cleanerReferBy = {}
                    row.cleanerReferBy["first_name"] = payload?.item?.first_name ?? "NA"
                    row.cleanerReferBy["last_name"] = payload?.item?.last_name ?? "NA"
                    setRowData(row)

                }
                toast.success(result?.data?.msg)
                handleCloseForm()
                setDisable(200)
                setLoader(false)
            } else {
                setLoader(false)
                setDisable(null)
                toast.error(result?.data?.msg)
            }
            setPayload({
                "champ_id": row?.id,
                "referred_by": null,
                "referred_by_type": null
            })
        }
        else {
            toast.error(`${payload?.referred_by, payload?.referred_by_type + " is missing.."}`)
        }
    }


    // by this we closeing form
    const handleClose = () => {
        handleCloseForm()
    }

    return (
        <>

            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal-small"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Refer By</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <>
                        <div className="col-12  mb-3" key={434343}>
                            <h5> Refer By </h5>
                            {/* {
                                cleanerListData?.length == 0 ? <IsLoading /> : <SingleSelectSearchDetailsDefault
                                    handleChangeInputData={handleChangeInputData}
                                    HeaderTitle='Select Cleaner'
                                    SelectData={cleanerListData}
                                    DynamicKey={'first_name'}
                                    DynamicKey2={'last_name'}
                                    DynamicKey3={'phone'}
                                    id={'id'}
                                    name='cleaner_id'
                                    defaultData={null}
                                ></SingleSelectSearchDetailsDefault>
                            } */}


                            {
                                <CustomerDefaultSelect
                                    handleChangeInputData={handleSelectChange}
                                    HeaderTitle='Select Cleaner'
                                    SelectData={searchDataCleaner}
                                    DynamicKey={'first_name'}
                                    DynamicKey2={'last_name'}
                                    DynamicKey3={'phone'}
                                    id={'id'}
                                    name='cleaner_id'
                                    defaultData={null}
                                    setSeacrch={setstoreSeachKeywords}
                                    isLoading={isLoading}
                                ></CustomerDefaultSelect>
                                }

                        </div>

                        <div className='modal-footer'>
                            <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={() => handUpdateReferBy()}>
                                {
                                    loader ?
                                        <>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                        </>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Refer By </h4>
                                }
                            </button>
                        </div>
                    </>


                </Modal.Body>
            </Modal> : ''}








        </>



    )
}
export default UpdateReferByChamp
