export const customerLeadAssignApi = [
  {
    url: 'getSupervisorList',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
  {
    url: 'getAlladminlist',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
]

export const customerLeadWhatsappTemplateApi = [
  {
    url: 'whatsapp/getWhatsAppTemplate',
    method: 'POST',
    isQuery: null,
    isParams: null,
    isBody: {
      wa_number_id: 1,
    },
  },
]

export const customerLeadStatusApi = [
  {
    url: 'getMasterReasonForVisitors',
    method: 'GET',
    isQuery: 'categoryId=7',
    isParams: null,
    isBody: null,
  },
]

export const customerLeadBatchApi = [
  {
    url: 'getBatches',
    method: 'GET',
    isQuery: null,
    isParams: null,
    isBody: null,
  },
]

export const customerLeadsWhatsappNotificationFormData = [
  {
    HeaderTitle: 'Select Whatsapp Template',
    filterApi: 'whatsapp/getWhatsAppTemplate',
    label: '',
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'name',
    name: 'templateName',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Whatsapp Template name is required !',
    searchable: false,
    wa_number_id: 1,
    userType: 1,
  },
]

export const customerLeadsGetBatchesFormData = [
  {
    HeaderTitle: 'Select Batch',
    filterApi: 'getBatches',
    label: '',
    valueType: 'select',
    inputType: '',
    displayFields: ['name'],
    valueField: 'id',
    name: 'batch_id',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Batch name is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
]

export const customerLeadsResetFormData = [
  {
    HeaderTitle: 'Select admins',
    filterApi: 'getAlladminlist',
    label: '',
    valueType: 'multi',
    inputType: '',
    displayFields: ['first_name', 'last_name', 'phone'],
    valueField: 'id',
    name: 'adminIds',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Admin name is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
  {
    HeaderTitle: 'Select Supervisors',
    filterApi: 'getSupervisorList',
    label: '',
    valueType: 'multi',
    inputType: '',
    displayFields: ['first_name', 'last_name', 'phone'],
    valueField: 'id',
    name: 'areaManagerIds',
    from: '',
    to: '',
    isRequired: true,
    errMessage: 'Supervisor name is required !',
    searchable: false,
    wa_number_id: null,
    userType: null,
  },
]
