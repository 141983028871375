import React, { useState, useEffect, useRef } from 'react'
import { LocalBaseURL } from '../../../../BaseURLmanagement'
import { getColumns } from './GetColumns'
import '../custom.css'
import { KTCardBody } from '../../../../_metronic/helpers'
import { AdminServerSidePaginationLists } from '../../admins/adminServerSidePaginationLists/AdminServerSidePaginationLists'
import {
  getCurrentFilterHandler,
  getFilterDataBySearch,
  initialPayload,
  operators,
  removeFilterHandler,
} from '../../../consts/DynamicFilteration/components/CommonData'
import {
  adminParamsGenerator,
  useAdminListApiUrl,
  useAdminManagementPermission,
  useAdminSmartFilterList,
} from '../../admins/adminUtils'
import { KeywiseComponents } from '../../../consts/DynamicFilteration/KeywiseComponents'
import { DynamicFilter } from '../../../consts/DynamicFilteration/FilterHandler'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import axios from 'axios'
// import ProductModal from './ProductModal'
// import * as XLSX from 'xlsx';
import { isAllowedUser } from '../../../consts/components/Utility'
// import AddPurchaseOrders from './addPurchaseOrder/AddPurchaseOrders'
import ViewProductModal from './ViewProductModal'
import Refresh from '../../../consts/Refresh'
import AddUpdatePaymentReceipt from './AddUpdatePaymentReceipt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const PaymentReceipts = ({ filterDataOutsource }: any) => {
  LocalBaseURL()
  const { permissionWrapperHideColsUtil } = useAdminManagementPermission()
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [editData, setEditData] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0)
  const [perPageSize, setPerPageSize] = useState(10)
  const [direction, setdirections] = useState<any>('')
  const [payload, setPayload] = useState<any>(initialPayload())
  const [filter, setFilter] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [toggledClearRows, setToggleClearRows] = React.useState(false)
  const [search, setSearch] = useState<any>('')
  const [filterkey, setFilterKey] = useState<any>({})
  const [sortby, setSortBy] = useState<any>('')
  const [page, setPages] = useState<any>(1)
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>('')
  const [dynFilter, setDynFilter] = useState<any>('')
  const [productData, setProductData] = useState<any>([])
  const [showProductModal, setShowProductModal] = useState<boolean>(false)
  const [sortedColumns, setSortedColumns] = useState<any>(getColumns())
  const [modalLoader, setModalLoader] = useState(false);

  const [editId, setEditId] = useState(0);

  async function InvokedRendered(payloads: any, setloading: any) {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    setPending(true)
    const { data } = await postRequest(
      URL_EndPoints(null)?.getAllPaymentReceiptPagination,
      payloads,
      setloading
    )

    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.paymentPagination)
    setPending(false)
  }

  let initialAddEditPayload = {
    purchase_order_id: null,
    proforma_invoice_id: null,
    payment_date: null,
    amount: [],
    notes: null,
    payment_method: null,
    media: null,
  }

  const [initialValue, setInitialValue] = useState<any>(initialAddEditPayload);
  const [show, setShow] = useState(false);
  const [apiUrl, setApiUrl] = useState("createPaymentReceipt");

  const handleEdit = async (row: any) => {
    setApiUrl("updatePaymentReceipt")
    setShow(true);
    setInitialValue({
      id: row?.id,
      purchase_order_id: row?.purchase_order_id,
      proforma_invoice_id: row?.proforma_invoice_id,
      payment_date: row?.payment_date,
      amount: row?.amount,
      notes: row?.notes,
      payment_method: row?.payment_method,
      media: row?.media,
    });
  };

  const addPayment = () => {
    setShow(true);
    setApiUrl("createPaymentReceipt");
    setInitialValue(initialAddEditPayload);
  };


  const handleViewButtonClick = async (orderid: any) => {
    try {
      setModalLoader(true);
      const { data } = await getRequest(URL_EndPoints(adminParamsGenerator("getAllPurchaseOrdersItems", orderid))?.getAllPurchaseOrdersItems + '/' + orderid, setPending)
      setProductData(data.data);
      setShowProductModal(true);

    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setModalLoader(false);
    }
  };

  useEffect(() => {
    if (filterData) {
      const newCol = getColumns(handleViewButtonClick, filterData?.map((ele: any) => ele?.id));
      setSortedColumns(newCol);
    }
  }, [filterData]);


  const handleCloseModal = () => {
    setShowProductModal(false)
  }

  const onChangeHandler = (
    event: any,
    key: any,
    value: any,
    defVal: any = false,
    timestamp: any,
    multiVal: any
  ) => {
    setPayload({
      ...payload,
      filterData: {
        ...payload.filterData,
        [key]: {
          ...payload.filterData[key],
          [value]: event?.value,
          [key + '_' + value]: defVal ? event : '',
          isDate: defVal ? false : true,
          timestamp: timestamp === 'null' ? undefined : timestamp,
          multiVal: multiVal,
        },
      },
    })
  }

  const handlePagination = (
    search: any = '',
    sortBy = '',
    sortDirection: any = 'desc',
    page: any = 1,
    perPageSize: any = 10
  ) => {

    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const actionHandler = async () => {
    await InvokedRendered(
      handlePagination(search, sortby, direction, page, perPageSize),
      setPending
    )
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(
      key,
      currentItemRemovedOrAdded,
      setFilterKey,
      setSelectedFilterData,
      selectedFilterData,
      setStoreFilterList,
      storeFilterList,
      payload,
      setPayload
    )
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)
    InvokedRendered(
      handlePagination(search, column.sortField, sortDirection, page, perPageSize),
      setPending
    )
  }

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  }

  useEffect(() => {
    let timer = setTimeout(async function () {
      search && setPending(true)
      search &&
        InvokedRendered(
          handlePagination(search && search, sortby, direction, page, perPageSize),
          setPending
        )
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [search])

  // useEffect(() => {
  //   !search &&
  //     InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  // }, [search])


  useEffect(() => {
    let { isFilter, paymentFilter } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: paymentFilter }, setPending)
    isFilter && setPayload({ ...payload, ["filterData"]: paymentFilter })
  }, [
    search,
    filterDataOutsource?.isFilter,
    filterDataOutsource?.paymentFilter,
  ])
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    selectedFilterData?.length === 0 &&
      InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [selectedFilterData?.length])

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  }

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }
  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords &&
        (await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
          keyword: storeSearchKeywords,
        }))
      setIsLoadingFilter(false)
    }, 2000)
    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList('getAllPaymentReceiptPagination')
  }, [])

  const refreshNow = () => {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
  }




  return (
    <>
      <KTCardBody className='card py-4' key='getAllPaymentReceiptPagination'>

        <AdminServerSidePaginationLists
          title={filterDataOutsource?.isFilter ? "" : 'Payment Receipt List'}
          reorderColumnTitle='getAllPaymentReceiptPagination'
          sortedColumns={[
            {
              name: 'Edit',
              cell: (row: any) => (
                <>
                  <span><FontAwesomeIcon className='me-2 ms-2 text-muted fs-4 cursor-pointer' icon={faEdit} onClick={() => handleEdit(row)} /></span>
                </>
              ),
              reorder: true,
              id: 1,
            }, ...sortedColumns]}
          filterData={filterData}
          pending={pending}
          clearSelectedRows={toggledClearRows}
          totalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          conditionalRowStyles={{}}
          handleSort={handleSort}
          keyName={'getAllPaymentReceiptPagination'}
          search={search}
          setSearch={setSearch}
          paginationPerPage={perPageSize}
          showFilter={filterDataOutsource?.isFilter ? false : true}
          ExpandedComponent={() => <></>}
          expandableRows={false}
          filterHandler={{
            onChangeHandler: onChangeHandler,
            actionHandler: actionHandler,
            getCurrentFilter: getCurrentFilter,
            removeFilter: removeFilter,
            payload: payload,
            Component: DynamicFilter,
            filterData: filter,
            OperatorsData: operators,
            selectedFilterData,
            filterComponents: KeywiseComponents,
            storeFilterList: storeFilterList,
            filterkey: filterkey,
            setSeacrch: setSearchFilter,
            isLoadingFilter: isLoadingFilter,
            listApiUrl: listApiUrl('getAllPaymentReceiptPagination'),
          }}

          SubHeaderComponentRightSection={() => <>
            <Refresh refreshNow={refreshNow} />

            {
              <button
                className="btn btn-sm btn-primary"
                onClick={addPayment}
              >
                <i className="fa fa-plus-circle mr-2"></i>Add Payment Receipt
              </button>
            }
          </>
          }

          SubHeaderComponentActionButtons={() => <></>}
        />
      </KTCardBody>

      <ViewProductModal
        show={showProductModal}
        productData={productData}
        handleClose={handleCloseModal}
        modalLoader={modalLoader}
      />

      {
        show ? <AddUpdatePaymentReceipt
          show={show}
          refreshNow={refreshNow}
          setShow={setShow}
          initialValue={initialValue}
          setInitialValue={setInitialValue}
          apiUrl={apiUrl}
        /> : <></>
      }

    </>
  )
}

export default PaymentReceipts
