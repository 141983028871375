import moment from 'moment';
import { JOB_STATUSES_FORMATTED } from '../../consts/champChatDashboard/constants/JobStatusLists';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faBell, faBackward, faBackwardStep, faFastBackward, faUndoAlt, faHistory } from '@fortawesome/free-solid-svg-icons';
import TrainerForm from './Trainer/TrainerForm';
import Calling from '../../consts/Call/Calling';
import Layout from '../../consts/Call/Layout';
import TaskDateForm from './TaskDateUpdate/TaskDateForm';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import ToolTips from '../../consts/Tooltip/ToolTips';
import SentenceShorter from '../../consts/SentenceShorter';
import uuid from 'react-uuid';
import DialogBox from './DialogBox/DialogBox';
import { TempsDateFormate } from '../../consts/toggleColumns/TempsDateFormate';
import ChampCallDetailsLogs from '../../consts/champChatDashboard/components/champCallDetails/ChampCallDetailsLogs';
import { Button } from 'react-bootstrap';
import IsPaidTaskListAndRenewalTaskYesNo from '../../consts/SweetAlert/IsPaidTaskListAndRenewalTaskYesNo';
import { TempsDateFormateTasklist } from '../../consts/toggleColumns/TempsDateFormateTasklist';
import UpdateTrainingLocation from './UpdateTrainingLocation';
import UpdateStatusTaskList from './UpdateStatusTaskList';
import { toast } from 'react-toastify';
import UpdatePrivateTagsListTaskList from './TagUpdate/UpdatePrivateTagsListTaskList';
import TagsShow from '../../consts/TagsShow/TagsShow';
import AdminTaskListStatusForm from '../admins/adminChampDashboardForms/AdminTaskListStatusForm';
import AdminTaskListTaskDateForm from '../admins/adminChampDashboardForms/AdminTaskListTaskDateForm';
import RevertFirstTrainingTaskList from '../../consts/SweetAlert/RevertFirstTrainingTaskList';
import { AdminToolTip } from '../admins/adminComponents/AdminToolTipModal';
import JobEditHistoryTaskList from './JobEditHistoryTaskList';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../admins/adminConstant';
import { useAdminManagementPermission } from '../admins/adminUtils';

export const columns = (handleFetchAllCallLogs?: any, handleRefresh?: any, data?: any, totalRowDataForChampDashboard?: any) => [
    {
        id: 1,
        reorder: true,
        name: "SELFIE",
        cell: (row: any) => <ViewImage row={row} />,
        hideCols: "task-lists-selfie"
    },
    {
        id: 2,
        reorder: true,
        name: "CHAMP LEAD",
        cell: (row: any) => <Calling title={
            row?.attendenceCleaner?.first_name + row?.attendenceCleaner?.last_name ? (row?.attendenceCleaner?.first_name + " " + row?.attendenceCleaner?.last_name) : "Not Set"}
            row_id={row?.attendenceCleaner?.id ? row?.attendenceCleaner?.id : null}
            row={row}
            type="cleanerid"
            From={Layout}
            isOpen={true}
            trackData={data}
            totalRowDataForChampDashboard={totalRowDataForChampDashboard}
        />,

        sortField: 'attendenceCleaner.first_name',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 3,
        reorder: true,
        name: "TASK NAME",
        selector: (row: any) => <TaskName row={row} handleRefresh={handleRefresh} />,
        sortField: 'taskType.name',
        sortable: true,
        minWidth: "225px"
    },
    {
        id: 15,
        reorder: true,
        name: "TASK CREATED BY",
        selector: (row: any) => row?.task_created_by?.activity_created_by_admin?.first_name ? row?.task_created_by?.activity_created_by_admin?.first_name + " " + row?.task_created_by?.activity_created_by_admin?.last_name : "",
        sortField: 'task_created_by.activity_created_by_admin.first_name',
        sortable: true,
        minWidth: "225px"
    },
    {
        id: 18,
        reorder: true,
        name: "MARKED ADMIN",
        selector: (row: any) => row?.markedAdmin?.first_name ? row?.markedAdmin?.first_name + " " + row?.markedAdmin?.last_name : "",
        sortField: 'markedAdmin.first_name',
        sortable: true,
        minWidth: "225px"
    },
    {
        id: 16,
        reorder: true,
        name: "PRIVATE TAGS",
        selector: (row: any) => <UpdatePrivateTags row={row} />,
        sortField: 'attendencecleaner.private_tag',
        sortable: true,
        minWidth: "225px"
    },
    {
        id: 4,
        reorder: true,
        name: "TASK DATE",
        selector: (row: any) => <TaskListTaskDateUpdate row={row} />,
        sortField: 'attendencedate',
        sortable: true
    },
    {
        id: 5,
        reorder: true,
        name: "TASK COMPLETED",
        selector: (row: any) => TempsDateFormate(row?.attendencedatedone, true) === "Invalid date" ? "" : TempsDateFormateTasklist(row?.attendencedatedone, true),
        sortField: 'attendencedatedone',
        sortable: true,
        minWidth: '200px',
    },
    {
        id: 6,
        reorder: true,
        name: "TRAINING LOCATION",
        selector: (row: any) => <TrainingLocation row={row} />,
        sortField: 'taskTrainingLocation.locationname',
        sortable: true,
    },
    {
        id: 7,
        reorder: true,
        name: "DISTANCE",
        selector: (row: any) => (
            <div className='text-left'>
                {
                    row?.distance >= 0.4 ? <span className='badge badge-danger fs-8 fw-bold'>{row?.distance ? row?.distance + "KM" :
                        "No Distance"} </span> :
                        <span className='fs-8 fw-bold'>{row?.distance ? row?.distance + "KM" : "No Distance"} </span>
                }
            </div>
        ),
        sortField: 'taskTrainingLocation.locationname',
        sortable: true,
    },
    {
        id: 8,
        reorder: true,
        name: "TRAINER",
        cell: (row: any) => <TaskTrainerUpdate row={row} />,
        sortField: 'attendencecleaner.first_name',
        sortable: true,
        minWidth: "200px",
    },
    {
        name: 'OPERATION STATUS',
        selector: (row: any) => {
            let attendance: any = row?.attendance
            let render: any = attendance?.alert_reason_id ? <StatusP2PUpdate row={row}></StatusP2PUpdate> : 'NA'
            return render
        },
        sortable: true,
        id: 17,
        reorder: true,
        width: "180px",
        style: {
            whiteSpace: "break-spaces"
        }
    },
    {
        id: 9,
        reorder: true,
        name: "TRAINER AREA MANAGER",
        cell: (row: any) => <Calling
            title={(row?.attendencecleaner?.champSupervisor?.first_name) + " " + (row?.attendencecleaner?.champSupervisor?.last_name) ? (row?.attendencecleaner?.champSupervisor?.first_name + " " + row?.attendencecleaner?.champSupervisor?.last_name) : "Not Set"}
            row_id={row?.attendencecleaner?.champSupervisor?.id ? row?.attendencecleaner?.champSupervisor?.id : null}
            row={row}
            type="cleanerid"
            From={Layout}
            isOpen={false} />,
        sortField: 'attendencecleaner.champSupervisor.first_name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 10,
        reorder: true,
        name: "STATUS",
        selector: (row: any) => <JobStatus row={row} />,
        sortField: 'job_status',
        sortable: true
    },
    {
        name: <CallDetailColumn handleFetchAllCallLogs={handleFetchAllCallLogs} />,
        cell: (row: any) => <CallDtails row={row} />,
        id: 11,
        reorder: true,
        minWidth: "250px",
        hideCols: "task-lists-fetch-call-logs"
    },
    {
        id: 12,
        reorder: true,
        name: "CREATED AT",
        selector: (row: any) => moment(row?.createdAt).format("DD-MM-YYYY"),
        sortField: 'createdAt',
        sortable: true
    },
    {
        id: 14,
        reorder: true,
        name: "Training Call Status",
        selector: (row: any) => row?.exotelOperationStatus?.name || 'Not Set',
        sortField: 'exotelOperationStatus.name',
        sortable: true,
        minWidth: '150px',
    },
    {

        id: 13,
        reorder: true,
        name: "Is Paid",
        selector: (row: any) => <IsPaidModal row={row} />,
        sortField: 'is_paid',
        sortable: true,
        minWidth: '150px',
    },
    {
        id: 21,
        reorder: true,
        name: "Task Edit History",
        selector: (row: any) => <JobEditHistoryTaskListRow row={row} />,
        // sortField: 'is_paid',
        // sortable: true,
        minWidth: '150px',
        hideCols: "task-lists-job-edit-history"
    },
];

const JobEditHistoryTaskListRow = ({ row }: any) => {
    const [show, setShow] = useState(false)
    return <>
        <FontAwesomeIcon icon={faHistory} className='fs-2 ms-2 text-primary' style={{ cursor: "pointer" }} onClick={() => setShow(true)} />
        {
            show ? <JobEditHistoryTaskList row={row} show={show} setShow={setShow} title="Task Edit History" /> : <></>
        }
    </>
}


const UpdatePrivateTags = ({ row }: any) => {
    const [updateTagModal, setUpdateTagModal] = useState<any>(false)
    const [rowData, setRowData] = useState<any>(row)
    useEffect(() => {
        setRowData(row)
    }, [row?.attendenceCleaner?.private_tag])

    const handleClose = () => {
        setUpdateTagModal(false)
    }
    return (
        <>
            <span className='d-flex'>
                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-update-private-tag"]}>
                    <FontAwesomeIcon onClick={() => setUpdateTagModal(true)} icon={faEdit} /> &nbsp;
                </PermissionContextProvider>

                {rowData?.attendenceCleaner?.private_tag ? <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1 cursor-pointer'>
                    <TagsShow Tag={rowData?.attendenceCleaner?.private_tag}></TagsShow>
                </div> : 'Not Set'}
            </span>

            {updateTagModal ? <UpdatePrivateTagsListTaskList showModal={updateTagModal} handleClose={handleClose} row={row} setRowData={setRowData} /> : <></>}

        </>
    )
}

const StatusP2PUpdate = ({ row }: any) => {
    const aletReasonData = useSelector((store: any) => store?.ReasonsListDataReducer?.Alert_reason)
    const [aletReasonList, setaletReasonList] = useState<any>([])
    const [showRes, setshowRes] = useState<any>({})
    useEffect(() => {
        setaletReasonList(aletReasonData)
    }, [aletReasonData])

    useEffect(() => {
        let updatedData: any = aletReasonData?.find((ele: any) => {
            return row?.attendance?.alert_reason_id === ele?.id
        })
        setshowRes(updatedData)
    }, [aletReasonList, row?.attendance?.alert_reason_id])
    return <>
        {showRes?.name ?? "No Status"}
    </>
}


const TaskTrainerUpdate = ({ row }: any) => {
    const dispatch = useDispatch()
    const activeChamps = useSelector((store: any) => store.VisitorDataReducer.activeChamps)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateTrainerPop, setUpdateTrainerPop] = useState(false)
    const [trainerList, setTrainerList] = useState<any>([])
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        setTrainerList(activeChamps)
    }, [activeChamps])

    useEffect(() => {
        const updatedData = trainerList?.filter((ele: any) => {
            return ele?.id === storeRowP2P?.attendencecleaner?.id
        })
        setshowRes(updatedData?.length > 0 ? updatedData[0] : { name: "No Set" })
    }, [trainerList, storeRowP2P?.attendencecleaner?.id])

    const handleClose = () => {
        dispatch({
            type: "MODAL_OPEN", payload: {
                toggle: false,
                id: null,
            }
        })
        setUpdateTrainerPop(false)
    }

    return <>
        <div className='d-flex'>
            <span >

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-update-trainer"]}>
                    <FontAwesomeIcon onClick={() => setUpdateTrainerPop(true)} icon={faEdit} /> &nbsp;
                </PermissionContextProvider>

                {showRes?.first_name + " " + showRes?.last_name || 'Not Set'}
            </span>
            <span>
                <Calling title={""} row_id={showRes?.id ? showRes?.id : null} row={row} type="cleanerid" From={Layout} isOpen={false} />
            </span>
        </div>
        <TrainerForm
            FilteredData={{
                show: updateTrainerPop,
                handleCloseForm: handleClose,
                selectedId: {
                    trainerId: row?.attendencecleaner?.id,
                }
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
        ></TrainerForm>
    </>
}


const TaskListTaskDateUpdate = ({ row }: any) => {
    const [showTaskDatePopUp, setShowTaskDatePopUp] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    const handleCloseForm = () => setShowTaskDatePopUp(false)
    useEffect(() => {
        setStoreRow(row)
    }, [row])

    return <>
        <span >
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-update-task-date"]}>
                <FontAwesomeIcon onClick={() => setShowTaskDatePopUp(true)} icon={faEdit} /> &nbsp;
            </PermissionContextProvider>

            {moment(storeRow?.attendencedate).format("DD-MM-YYYY") === "Invalid date" ? "Not Set" : moment(storeRow?.attendencedate).format("DD-MM-YYYY")}
        </span>
        {/* {
            <TaskDateForm FilteredData={{
                show: showTaskDatePopUp,
                attendencedate: storeRow?.attendencedate,
                taskId: storeRow?.id,
            }} handleCloseForm={handleCloseForm} setStoreRow={setStoreRow} row={row} />
        } */}
        {
            <AdminTaskListTaskDateForm FilteredData={{
                show: showTaskDatePopUp,
                attendencedate: storeRow?.attendencedate,
                taskId: storeRow?.id,
            }} handleCloseForm={handleCloseForm} setStoreRow={setStoreRow} row={row} />
        }
    </>
}

const CallDetailColumn = ({ handleFetchAllCallLogs }: any) => {
    return <>
        Call Details <button className='btn btn-primary btn-sm btn-rounded' onClick={handleFetchAllCallLogs} style={{
            fontSize: '10px',
            padding: '5px 7px',
            marginLeft: '7px'
        }}>Fetch All</button>
    </>
}

const CallDtails = ({ row }: any) => {
    const [storedRow, setStoredRow] = useState<any>(row);

    useEffect(() => {
        setStoredRow(row);
    }, [row]);

    return <>
        <div className='card-title'>
            <div className='symbol-group symbol-hover'>
                {storedRow?.callLogs?.map((ele: any, indexs: any) => {
                    let id = uuid()
                    let StartTime = ele?.FormattedStartTime?.split(" ")
                    let sentence = ele?.FromDetails?.name + ' - ' + ((StartTime[0] + " " + StartTime[1]))
                    if (indexs < 5) {
                        return <div className='symbol symbol-40px symbol-circle' id={id} key={id}  >
                            <span className={`symbol-label bg-light-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : ele?.Status === "no-answer" ? "secondary" : ele?.Status === "canceled" ? "danger" : "primary"} text-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : "primary"} 40px   fs-7`} >{StartTime[0]} <SentenceShorter sentence={StartTime[1]} index={null} /></span>
                            <ToolTips toolTipId={id} ToolTipShowData={sentence} place={""} />
                        </div>
                    }
                })}
                <CallLogsDetails customerid={storedRow?.id} champ_name={storedRow?.attendenceCleaner?.first_name + ' ' + storedRow?.attendenceCleaner?.last_name} lengths={storedRow?.callLogs?.length} row={storedRow} setStoredRow={setStoredRow} ></CallLogsDetails>
            </div>
        </div>
    </>
}



const CallLogsDetails = ({ customerid, champ_name, lengths, row, setStoredRow }: any) => {
    const [show, setshow] = useState(false)
    const [cleanerId, setcleanerId] = useState(0)
    const handleFetchCallDetails = async (customerid: number) => {
        if (lengths === undefined) {
            const response: any = await postRequest(URL_EndPoints(null)?.getCallHistoryByCustomerIds, {
                customerIds: [row?.user_id],
                userType: 'cleaner'
            }, null);
            const callLogs: any = response?.data?.data[0];
            setStoredRow({ ...row, callLogs });
            setcleanerId(row?.attendenceCleaner?.id)
        } else {
            setshow(true)
            setcleanerId(row?.attendenceCleaner?.id)

        }
    }
    const handleClose = () => {
        setshow(false)
    }


    return (
        <>
            <a
                href='#'
                className='symbol symbol-40px symbol-circle'
                id={customerid}
                onClick={(e: any) => {
                    e.preventDefault();
                    handleFetchCallDetails(customerid);
                }}
            >
                <span
                    className='symbol-label fs-8 fw-bolder bg-primary text-white'
                >
                    {lengths === undefined ? 'Fetch' : lengths}
                </span>
                <ToolTips toolTipId={customerid} ToolTipShowData={lengths === undefined ? "View call logs" : "View All Call Details"} place={""} />
            </a>

            <ChampCallDetailsLogs FilteredData={{
                champ_call_log_id: cleanerId,
                show: show,
                handleClose,
                champ_name: champ_name
            }} >
            </ChampCallDetailsLogs>


        </>
    )
}

const ViewImage = ({ row }: any) => {
    const [PopupUpdateStatusOpenClose, setPopupUpdateStatusOpenClose] = useState(false);
    const handleClose = () => {
        setPopupUpdateStatusOpenClose(false);
    }
    return (
        <>
            <span className='badge badge-primary fs-10 fw-bold text-center' onClick={() => setPopupUpdateStatusOpenClose(true)}>
                <i className="bi bi-images  fs-2 text-white"></i>
            </span>
            {PopupUpdateStatusOpenClose && <DialogBox attendenceId={row?.id} handleDiloagboxform={handleClose} PopupUpdateStatusOpenClose={PopupUpdateStatusOpenClose} />}
        </>
    )
}






const IsPaidModal = ({ row }: any) => {
    const { permissionWrapperContextUtil } = useAdminManagementPermission()

    const [isPaidStatusModal, setIsPaidStatusModal] = useState<any>(false)
    const [renders, setRender] = useState<any>(null)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [Loader, setLoader] = useState<any>(false)
    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const openIsPaidModalHandler = () => {
        setIsPaidStatusModal(true)
        setRender(Math.random())
    }
    const title = Loader ? "In Progress" : storeRow?.ispaid == 0 ? "Not Paid" : "Paid"
    const colorStatus = Loader ? "danger" : storeRow?.ispaid == 0 ? "primary" : "success"
    return (
        <>
            <Button disabled={storeRow?.ispaid == 0 ? false : true} className={`btn btn-sm btn-${colorStatus}`} onClick={() => permissionWrapperContextUtil("task-lists-is-paid") ? openIsPaidModalHandler() : {}}>{title}</Button>
            {
                isPaidStatusModal ?
                    <IsPaidTaskListAndRenewalTaskYesNo
                        render={renders}
                        confirm={'Yes'}
                        cancel={'No'}
                        payload={{
                            "jobIds": [row?.id],
                            "approver_id": localStorage.getItem("user"),
                            "requested_by": localStorage.getItem("user"),
                            "requested_user_type": 5,
                            "updateFields": {
                                "ispaid": 1
                            }
                        }}
                        setIsPaidStatusModal={setIsPaidStatusModal}
                        title={`Are you sure want to Paid ?`}
                        setLoader={setLoader}
                        setStoreRow={setStoreRow}
                        row={row}
                    /> : ""
            }

        </>
    )
}

const TrainingLocation = ({ row }: any) => {

    const [show, setShow] = useState(false)
    const [rowData, setRowData] = useState(row)
    useEffect(() => {
        setRowData(row)
    }, [row])
    return (
        <>
            <span >
                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-update-training-location"]}>
                    <FontAwesomeIcon icon={faEdit} onClick={() => setShow(true)} className='me-2 cursor-pointer' />
                </PermissionContextProvider>


                {rowData?.taskTrainingLocation?.locationname}</span>
            {
                show && <UpdateTrainingLocation show={show} setShow={setShow} setRowData={setRowData} row={row} />
            }
        </>
    )
}



const JobStatus = ({ row }: any) => {

    const [show, setShow] = useState(false)
    const [rowData, setRowData] = useState(row)
    useEffect(() => {
        setRowData(row)
    }, [row])

    return (
        <>
            <span >

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-update-status"]}>
                    <FontAwesomeIcon icon={faEdit} onClick={() => setShow(true)} className='me-2 cursor-pointer' /> &nbsp;
                </PermissionContextProvider>
                {JOB_STATUSES_FORMATTED[+rowData?.job_status]}</span>

            {
                show && <AdminTaskListStatusForm show={show} setShow={setShow} setRowData={setRowData} row={row} prevInitialData={JOB_STATUSES_FORMATTED[+rowData?.job_status] ? { label: JOB_STATUSES_FORMATTED[+rowData?.job_status], value: rowData?.job_status } : null} />
            }

        </>
    )
}

const TaskName = ({ row, handleRefresh }: any) => {
    const tooltipId = uuid()
    const [isLoading, setIsLoading] = useState(false)
    const [showRevertFirstTraining, setShowRevertFirstTraining] = useState<any>(null)

    const handleReminderSend = async () => {
        setIsLoading(true)
        const res = await postRequest(URL_EndPoints(null)?.sendChampTrainingReminder, { taskId: row?.id, }, setIsLoading)
        if (res.data.status === 200) {
            return toast.success(res.data.message)
        }
        toast.info(res.data.message)
        setIsLoading(false)
    }


    return <div className='d-flex gap-2 align-items-center'>
        {row?.taskType?.name}
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-task-reminder-alert"]}>
            {
                row?.taskType?.id == 3 ? <FontAwesomeIcon icon={faUndoAlt} color='primary' onClick={() => setShowRevertFirstTraining(200)} className='cursor-pointer text-muted me-2 fw-bold' id={tooltipId} /> : <></>
            }
        </PermissionContextProvider>

        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-task-reminder-alert"]}>
            {isLoading ? <div className='spinner-border mr-15' role='status'></div> : <FontAwesomeIcon icon={faBell} color='primary' onClick={handleReminderSend} className='cursor-pointer text-muted me-2' />}

        </PermissionContextProvider>




        {
            showRevertFirstTraining ? <RevertFirstTrainingTaskList
                confirm='Yes'
                cancel='No'
                successTitle='Changes Are Saved Successfully '
                FailedTitle='Changes Cancelled '
                payload={{
                    taskId: row?.id
                }}
                setShow={setShowRevertFirstTraining}
                show={showRevertFirstTraining}
                refreshNow={handleRefresh}
            /> : <></>
        }
        <AdminToolTip anchorId={tooltipId} title={"Revert Task"} place={""} />


    </div>
}