import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { postRequest } from '../../Server/Requests'
import SingleSelectSearchCategory from '../../Select/SingleSelectSearchCategory'
import { useSelector } from 'react-redux'
const UpdateSourceForm = ({ handleClose,show,ticketId,invokedTicket }: any) => {

    const ticketSourceData = useSelector((store: any) => store.champsReducer.statusData?.ticketSourceData)

    const [loader, setLoader] = useState(false)
    const [payload, setPayload] = useState({
        ticketid: ticketId,
        source_id: 0,
    })


    const handleChnageOnSource = async (event: any, name: string) => {
        if (event == null) {
            setPayload({ ...payload, [name]: 0 })
        }
        else {
            setPayload({ ...payload, [name]: +event.value })

        }
    }

    const updateSourceHadler = async () => {
        setLoader(true)
        if (payload?.ticketid && payload?.source_id) {
            const response = await postRequest(URL_EndPoints()?.updateSource, payload, setLoader)
            if (response?.data?.status == 200) {
                toast.success(response?.data?.msg, { position: "top-center" })
                invokedTicket()
                handleClose()
                setLoader(false)
            } else {
                toast.error(response?.data?.msg, { position: "top-center" })
                setLoader(false)

            }
        }
    }




    return (
        <>
            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Source</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-8`}>
                        <SingleSelectSearchCategory
                            handleChangeInputData={handleChnageOnSource}
                            HeaderTitle="Select Source"
                            SelectData={ticketSourceData || []}
                            DynamicKey={"name"}
                            id={"id"}
                            name="source_id"
                        ></SingleSelectSearchCategory>
                    </div>


                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => updateSourceHadler()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating...</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Source</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default UpdateSourceForm
