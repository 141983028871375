import React, { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import PayoutTimeLIneChart from './PayoutTimeLIneChart'
import Not_Found_Data from '../../consts/Not_Found_Data'

const ShowPayoutChart = ({ chartData, show, setShow }: any) => {

    return (
        <>

            <Modal size='xl' show={show} onHide={() => setShow(false)} fullscreen={true}  >
                <Modal.Header closeButton>
                    <Modal.Title>Payout Time Line Chart</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        chartData?.categories?.length > 0 ? <PayoutTimeLIneChart data={chartData} /> :
                            <Not_Found_Data headerTitle={"No Payout Time Line Data Is Availaible"}></Not_Found_Data>

                    }

                </Modal.Body>
            </Modal>

        </>
    )
}

export default ShowPayoutChart