import React, { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import HistoryListData from '../HistoryListData';


const HistoryLists = ({ row, show, setShow }: any) => {

    return (
        <>
            <Modal size='xl' show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HistoryListData childId={row?.id} />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default HistoryLists