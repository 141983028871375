import React, { useState } from "react";
import axios from "axios";
import { getAudioUrl } from "../../../utils/helper.util";
import { replaceS3_url } from "../../../modules/admins/adminUtils";


interface AudioPlayerProps {
    recordingUrl: string;
    setFetchedUrls: any;
    fetchedUrls: any;
}

const ExtotelAudioUrl: React.FC<AudioPlayerProps> = ({ recordingUrl, setFetchedUrls, fetchedUrls }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchAudioUrl = async (uniqueUrl: string) => {
        if (fetchedUrls[uniqueUrl]) {
            return;
        }

        setIsLoading(true);
        try {
            const audioURL = await getAudioUrl(replaceS3_url(recordingUrl))
            setFetchedUrls((prevUrls: any) => ({
                ...prevUrls,
                [uniqueUrl]: audioURL,
            }));
        } catch (error) {
            console.error("Failed to fetch audio URL:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleIconClick = () => {
        if (!fetchedUrls[recordingUrl]) {
            fetchAudioUrl(recordingUrl);
        }
    };

    return (
        <div className="d-flex justify-content-start align-items-center">
            <audio src={fetchedUrls[recordingUrl] || ""} controls />
            <button onClick={handleIconClick} disabled={isLoading} className="btn btn-sm btn-primary p-1 px-2 ms-4">
                {isLoading ? "Loading..." : "Listen Recording"}
            </button>
        </div>
    );
};

export default ExtotelAudioUrl;
