const CardTemplateThead = () => {
  return (
    <>
      <thead>
        <tr className='fw-bold text-muted'>
      
          <th className='min-w-150px '>Title</th>
          <th className='min-w-140px'>Description</th>
          <th className='min-w-120px'>View</th>
          <th className='min-w-100px text-end'>Actions</th>
        </tr>


     
      </thead>
    </>
  )
}
export default CardTemplateThead
