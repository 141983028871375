import React, { useState, useEffect } from 'react';
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { AdminClientSidePaginationLists } from '../admins/adminClientSidePaginationLists/AdminClientSidePaginationLists';
import Refresh from '../../consts/Refresh';
import MomentDate from '../../consts/MomentDate';
import { getName } from '../admins/adminUtils';
import AMFilter from './AMFilter';
import { getShowHideColsLocal } from '../../consts/ShowHideColumnsLocalStorage';
import IsLoading from '../../consts/IsLoading/IsLoading';
import "./areaManagerCss.css"


const AreaManagerDailyDashboard = ({ areaManagerJobDashboardCallApi, setAreaManagerJobDashboardCallApi }: any) => {
  let areaMangerCol = {
    id: 150,
    reorder: true,
    name: <span id="0">Area Manager</span>,
    cell: (row: any) => getName(row?.area_manager),
    width: "150px",
    sortable: true,
    sortFunction: (rowA: any, rowB: any) => {
      const a = rowA?.area_manager?.first_name?.toLowerCase()
      const b = rowB?.area_manager?.first_name?.toLowerCase()
      if (a > b) {
        return 1
      }

      if (b > a) {
        return -1
      }
      return 0
    },
    style: { position: 'sticky', left: 0, backgroundColor: '#fff', zIndex: 1, boxShadow: "3px 0px 3px 0px rgba(0,0,0,0.2)", fontSize: "12px" },

  }
  const [search, setSearch] = useState<any>("")
  const [pending, setPending] = React.useState(false)
  const [pendingBody, setPendingBody] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [areaManagerList, setAreaManager] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [columns, setColumns] = useState<any>([])
  const [sortedColumns, setSortedColumns] = useState<any>([])

  const getInititalData = async () => {
    const supervisorListRes = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
    setAreaManager(supervisorListRes?.data?.data)
  }

  useEffect(() => { getInititalData() }, [])

  const [payload, setPayload] = useState<any>({
    areaManagerIDs: [],
    date: MomentDate(0),
    customerVintage: null,
  })

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  };

  async function getAreaManagerJobList(setPendingBody: any) {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    setPendingBody(true)
		let newPayload = { ...payload }
		newPayload.customerVintage = +newPayload.customerVintage
		if (!newPayload?.customerVintage) {
			newPayload.customerVintage = 0
		}
    const getJobsDashboardData = await postRequest(URL_EndPoints(null)?.getJobsDashboardData, newPayload, null)
    const showHide = getShowHideColsLocal()
    let columnsUpdated = getJobsDashboardData?.data?.data?.length > 0 ? getJobsDashboardData?.data?.data[0]?.data : []
    const newCols = columnsUpdated?.map((ele: any, index: any) => {
      return ({
        id: index + 1,
        reorder: true,
        name: <span id={ele}>{ele?.title}</span>,
        cell: (row: any) => row?.data[index]?.total,
        width: "75px",
        sortable: true,
        sortFunction: (rowA: any, rowB: any) => {
          const a = rowA?.data[index]?.total
          const b = rowB?.data[index]?.total
          if (a > b) {
            return 1
          }
          if (b > a) {
            return -1
          }
          return 0
        },
      })
    })

    setColumns([areaMangerCol, ...newCols])
    setSortedColumns(showHide?.length > 0 ? [areaMangerCol, ...newCols]?.filter((ele: any) => showHide?.includes(ele?.id)) : [areaMangerCol, ...newCols])
    setfilterData(getJobsDashboardData?.data?.data)
    setData(getJobsDashboardData?.data?.data)
    setPendingBody(false)
    setAreaManagerJobDashboardCallApi(false)
  }

  useEffect(() => {
    areaManagerJobDashboardCallApi && getAreaManagerJobList(setPendingBody)
  }, [])

  useEffect(() => {
    const filterSearchData = AllFielSearch(data, search)
    setfilterData(filterSearchData)
  }, [search])

  const searchHandler = () => {
    getAreaManagerJobList(setPending)
  }

  const onChangeHandler = (event: any, name: any) => {
    setPayload({ ...payload, [name]: event ? event?.value : event })
  }

  const ReactComponentStylesSheet = {
    headCells: {
      style: {
        background: 'white',
        border: '1px solid rgba(0,0,0,0.2)',
      },
    },
    headRow: {
      style: {
        background: 'white',
      },
    },
    rdt_TableHead: {
      background: "white",
      zIndex: "2"
    },

  };
  const refreshNow = () => {
    getAreaManagerJobList(setPending)
  }

  return (
    <div>
      {
        pendingBody ? <IsLoading /> : <AdminClientSidePaginationLists
          title=""
          reorderColumnTitle="areaManagerListReorderColumnItem"
          sortedColumns={sortedColumns}
          filterData={filterData}
          showHideColumns={columns}
          setSortedColumns={setSortedColumns}
          pending={pending}
          clearSelectedRows={toggledClearRows}
          totalRows={filterData?.length}
          conditionalRowStyles={{}}
          onChangePage={() => { }}
          onChangeRowsPerPage={() => { }}
          onSelectedRowsChange={handleSelectedRowsChange}
          search={search}
          setSearch={setSearch}
          showFilter={true}
          pagination={false}
          selectableRows={false}
          ExpandedComponent={() => <></>}
          keyName={"areaManagerReorderedItem"}
          clientSideFilterComponent={AMFilter}
          fixedHeaderScrollHeight="95vh"
          ReactComponentStylesSheet={ReactComponentStylesSheet}
          clientSideFilterData={{
            handleActions: searchHandler,
            payloads: payload,
            onChangeHandler: onChangeHandler,
            areaManagerList: areaManagerList,
          }}
          SubHeaderComponentRightSection={() => (
            <>
              <Refresh refreshNow={refreshNow} />
            </>
          )}
          SubHeaderComponentActionButtons={() => (<></>)}
        />
      }



    </div>
  )
}
export default AreaManagerDailyDashboard
