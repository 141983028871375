import React, { useEffect, useState } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import "./styles.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

const ViewInventoryModal = ({ title, show, setShow, filterList, Component }: any) => {

    const toggleDrawer = () => {
        setShow((prevState: any) => !prevState)
    }

    return (
        <>
            <Drawer
                lockBackgroundScroll={true}
                open={show}
                onClose={toggleDrawer}
                direction="right"
                className="bla bla bla"
                size="80%"
                duration={500}
                overlayOpacity={0.1}
                style={{ height: "100%" }}
            >
                <div className='p-2 d-flex justify-content-between border border-bottom'>
                    <span>{title}</span>
                    <FontAwesomeIcon onClick={() => setShow(false)} icon={faXmarkCircle} color='#009ef7' size='xl' className='cursor-pointer ' />
                </div>
                {
                    show ? <div style={{ height: "90vh", overflowY: "scroll" }} >
                        <Component filterDataOutsource={filterList} />
                    </div> : <></>
                }
            </Drawer>
        </>
    )
}

export default ViewInventoryModal