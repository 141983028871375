import React, { FC } from 'react'
import { tags } from '../CommonData'
import SingleSelectSearchCategory from '../Select/SingleSelectSearchCategory'
import SingleSelectSearchCategoryDefault from '../Select/SingleSelectSearchCategoryDefault'
import SingleSelectSearchDetails from '../Select/SingleSelectSearchDetails'
import SingleSelectSearchDetailsDefault from '../Select/SingleSelectSearchDetailsDefault'
import SingleSelectSearchTimeSlot from '../Select/SingleSelectSearchTimeSlot'
import CustomerDefaultSelect from '../Select/CustomerDefaultSelect'
const JobListFilteration = ({ ParentData }: any) => {
  let selectedTag = tags.find((element: any) => element.id == ParentData?.tag);

  return (
    <>
      <div className='p-6 w-325px w-md-375px'>
        {/* <form > */}
        <h3 className='fw-bold text-dark mb-7'>Search & Filteration</h3>
        {/* Label options ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* <div className='mb-1'>
            <LabelOptions></LabelOptions>
          </div> */}
        {/* Label options ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* Search Box ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* <div className='mb-1'>
            <SearchInput></SearchInput>
          </div> */}
        {/* Search Box ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}


        {
          <div className='row mb-1'>
            <div className='col-6'>
              <input
                type='date'
                style={{ height: '38px' }}
                value={ParentData?.payloads?.attendencedatefrom}
                name='attendencedatefrom'
                onChange={(event: any) =>
                  ParentData.handleChangeInput(event.target, 'attendencedatefrom')
                }
                className='w-100  height-100 form-control me-2 align-start'
              />
            </div>
            <div className='col-6'>
              <input
                type='date'
                style={{ height: '38px' }}
                name='attendencedateto'
                value={ParentData?.payloads?.attendencedateto}
                onChange={(event: any) =>
                  ParentData.handleChangeInput(event.target, 'attendencedateto')
                }
                className='w-100 form-control me-2 align-start'
              />
            </div>
          </div>
        }
        {(
          <div className='mb-1'>
  
            <CustomerDefaultSelect
              handleChangeInputData={ParentData?.selectSearchValue}
              HeaderTitle='Select Customer'
              SelectData={ParentData?.searchDataCleaner}
              DynamicKey={'first_name'}
              DynamicKey2={'last_name'}
              DynamicKey3={'phone'}
              id={'id'}
              name='customerid'
              defaultData={null}
              setSeacrch={ParentData?.setstoreSeachKeywords}
              isLoading={ParentData?.isLoading}
            ></CustomerDefaultSelect>
          </div>
        )}

        {(
          <SingleSelectSearchDetailsDefault
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Select Supervisor'
            SelectData={ParentData?.AllSuperListDefaultData}
            DynamicKey={'first_name'}
            DynamicKey2={'last_name'}
            DynamicKey3={'phone'}
            id={'id'}
            name='supervisorId'
            defaultData={ParentData?.DefaultDataSupervisorOnly}
          ></SingleSelectSearchDetailsDefault>
        )}
        {(
          <SingleSelectSearchTimeSlot
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Select Timeslot'
            SelectData={ParentData?.timelotsData}
            DynamicKey={'name'}
            id={'id'}
            name='timeslotid'
            defaultData={ParentData?.DefaultDataTimeSlot}
          ></SingleSelectSearchTimeSlot>
        )}
        {(
          <SingleSelectSearchCategoryDefault
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Job Status'
            SelectData={ParentData?.SelectedFilterTicketCateogryDefaultData}
            DynamicKey={'name'}
            id={'id'}
            name='jobstatus'
            defaultData={ParentData?.DefaultDataCategoryOnly}
          ></SingleSelectSearchCategoryDefault>
        )}
        {(
          <SingleSelectSearchCategory
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Cleaner Status'
            SelectData={ParentData?.CleanerstatusData}
            DynamicKey={'name'}
            id={'id'}
            name='cleanerstatus'
          ></SingleSelectSearchCategory>
        )}
        {(
          <SingleSelectSearchCategory
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Select Reason'
            SelectData={ParentData?.P2P_filter}
            DynamicKey={'name'}
            id={'id'}
            name='p2p_reasonid'
          ></SingleSelectSearchCategory>
        )}
        {(
          <SingleSelectSearchCategory
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Select Source'
            SelectData={ParentData?.TicketSourcesData}
            DynamicKey={'name'}
            id={'id'}
            name='sourceid'
          ></SingleSelectSearchCategory>
        )}
        {(
          <div className='mb-1'>
            <SingleSelectSearchDetails
              handleChangeInputData={ParentData?.handleChangeInput}
              HeaderTitle='Select Cleaner'
              SelectData={ParentData?.CleanerfilterDataList}
              DynamicKey={'first_name'}
              DynamicKey2={'last_name'}
              DynamicKey3={'phone'}
              id={'id'}
              name='cleanerid'
              status={ParentData?.status}
            ></SingleSelectSearchDetails>
          </div>
        )}
        {(
          <SingleSelectSearchCategoryDefault
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Service Type'
            SelectData={ParentData?.JobTypesListData}
            DynamicKey={'name'}
            id={'id'}
            name='type'
            defaultData={ParentData?.DefaultDataServiceTypeOnly}
          />
        )}
        {(
          <SingleSelectSearchCategoryDefault
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Reported Category'
            SelectData={ParentData?.reported_categories}
            DynamicKey={'name'}
            id={'id'}
            name='reportedCategoryId'
            defaultData={ParentData?.DefaultDataReportedategoryOnly}
          />
        )}
       
        {ParentData?.PaidUnPaidData && (
          <SingleSelectSearchCategory
            handleChangeInputData={ParentData?.handleChangeInput}
            HeaderTitle='Select Paid/UnPaid/Refund'
            SelectData={ParentData?.PaidUnPaidData}
            DynamicKey={'name'}
            id={'id'}
            name='subscription_status'
          ></SingleSelectSearchCategory>
        )}
        {ParentData?.updatedsuperAdminListData && (
          <div className='mb-1'>
            <SingleSelectSearchDetails
              handleChangeInputData={ParentData?.handleChangeInput}
              HeaderTitle='Assigned Admin'
              SelectData={
                ParentData?.updatedsuperAdminListData ? ParentData?.updatedsuperAdminListData : []
              }
              DynamicKey={'first_name'}
              DynamicKey2={'last_name'}
              DynamicKey3={'phone'}
              id={'id'}
              name='assignedadminuserid'
              status={ParentData?.status}
            ></SingleSelectSearchDetails>
          </div>
        )}
        {ParentData?.updatedsuperVisorData && (
          <div className='mb-1'>
            <SingleSelectSearchDetails
              handleChangeInputData={ParentData?.handleChangeInput}
              HeaderTitle='Assigned Supervisor'
              SelectData={
                ParentData?.updatedsuperVisorData ? ParentData?.updatedsuperVisorData : []
              }
              DynamicKey={'first_name'}
              DynamicKey2={'last_name'}
              DynamicKey3={'phone'}
              id={'id'}
              name='assignedsupervisorid'
            ></SingleSelectSearchDetails>
          </div>
        )}
        {ParentData?.SelectedFilterTicketCateogryData && (
          <div className='mb-1'>
            <SingleSelectSearchCategory
              handleChangeInputData={ParentData?.handleChangeInput}
              HeaderTitle='Select Ticket Category'
              SelectData={ParentData?.SelectedFilterTicketCateogryData}
              DynamicKey={'category_name'}
              id={'id'}
              name='ticketidcategory'
            ></SingleSelectSearchCategory>
          </div>
        )}
        {ParentData?.SelectedFilterTicketCateogryData && (
          <div className='mb-1'>
            <SingleSelectSearchCategory
              handleChangeInputData={ParentData?.handleChangeInput}
              HeaderTitle='Select Status'
              SelectData={ParentData?.SatusListData}
              DynamicKey={'name'}
              id={'id'}
              name='status'
            ></SingleSelectSearchCategory>
          </div>
        )}
        {ParentData?.RatingsData && (
          <div className='mb-1'>
            <SingleSelectSearchCategoryDefault
              handleChangeInputData={ParentData?.handleChangeInput}
              HeaderTitle='Select Rating'
              SelectData={ParentData?.RatingsData}
              DynamicKey={'name'}
              id={'id'}
              name='rate'
              defaultData={ParentData?.DefaultRateOnly}
            ></SingleSelectSearchCategoryDefault>
          </div>
        )}
        {(
          <div className='mb-1'>
            <SingleSelectSearchCategoryDefault
              handleChangeInputData={ParentData?.handleChangeInput}
              HeaderTitle='Select Tag'
              SelectData={tags}
              DynamicKey={'label'}
              id={'id'}
              name='tag'
              defaultData={selectedTag}
            ></SingleSelectSearchCategoryDefault>
          </div>
        )}

        <SingleSelectSearchCategory
          handleChangeInputData={ParentData?.handleChangeInput}
          HeaderTitle='Marked By'
          SelectData={[{ name: "Marked By Admin", id: "admin" }, { name: "Marked By Champ", id: "champ" }]}
          DynamicKey={'name'}
          id={'id'}
          name='by'
        ></SingleSelectSearchCategory>


        {ParentData?.TransactionData && (

          <div className="row mb-5 px-2  ms-2">

            <input
              placeholder='Enter Transaction value..'
              name='transactiion_id'
              type='number'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              onChange={(event) => ParentData?.handleChangeInput(event.target, "transaction_id")}
            />
          </div>
        )}
  
      </div>
    </>
  )
}
export { JobListFilteration }
