import { CHAT_DATA } from '../../ActionTypes/ActionTypes'
const initState = {
  TicketData: {},
  getTicketByCustomer: {},
  chatHistoryList: [],
  Ticketid: null,
  customerid: null,
  JobListEventKey: null,
  JobListSubscriptionid: null,
  JobListRender: null,
  JobListDate: {},
  DefaultTabChat: null,
  DefaultTabChatRender: null,
  tabKey: {},
  noticationCredential: {},
  tableDataList: {},
  trackData: {},
  totalRowDataForCustomerDashboard: {},
  initialValue: null,
  champTicketData: {},
  renewalReasonData: [],
}
export const ChatReducers = (state = initState, { type, payload }: any) => {
  switch (type) {
    case CHAT_DATA:
      return { ...state, TicketData: payload }
    case "CHAMP_CHAT_DATA":
      return { ...state, champTicketData: payload }
    case 'tableDataList':
      return { ...state, tableDataList: payload }
    case 'INITIAL_VALUE':
      return { ...state, initialValue: payload }
    case 'DEAFUALT_CHAT_TAB':
      return { ...state, DefaultTabChat: payload.DefaultTabChat, DefaultTabChatRender: payload.render }
    case 'TAB_KEY':
      return { ...state, tabKey: payload }
    case 'CHECK_DASHBOARD_CLOSE_STATUS':
      return { ...state, noticationCredential: payload }
    case 'GET_TICKET_BY_CUSTOMER':
      return { ...state, getTicketByCustomer: payload }
    case 'CHAT_HISTORY_LIST':
      return { ...state, chatHistoryList: payload }
    case 'STORE_RENEWAL_REASON_DATA':
      return { ...state, renewalReasonData: payload }
    case 'TRACK_DATA':
      return { ...state, trackData: { ...state.trackData, [payload.key]: payload?.trackData } }
    case 'STORE_ROW_DATA_FOR_CUSTOMER_DASHBOARD':
      return { ...state, totalRowDataForCustomerDashboard: { ...state.totalRowDataForCustomerDashboard, [payload.key]: payload?.totalRowDataForCustomerDashboard } }
    case 'CHAT_JOB_LIST_EVENT_KEY':
      return {
        ...state,
        JobListEventKey: payload.JobListEventKey,
        JobListSubscriptionid: payload.JobListSubscriptionid,
        JobListRender: payload.JobListRender,
        JobListDate: payload.JobListDate,
      }
    default:
      return state
  }
}

// const JobListSubscriptionid = useSelector((store:any)=>store.ChatReducers.JobListSubscriptionid)
// const JobListRender = useSelector((store:any)=>store.ChatReducers.JobListRender)
// const JobListEventKey = useSelector((store:any)=>store.ChatReducers.JobListEventKey)
// const DefaultTabChat = useSelector((store:any)=>store.ChatReducers.DefaultTabChat)
// const tabKey = useSelector((store:any)=>store.ChatReducers.tabKey)
// const noticationCredential = useSelector((store:any)=>store.ChatReducers.noticationCredential)
// const tableDataList = useSelector((store:any)=>store.ChatReducers.tableDataList)
// const getTicketByCustomer = useSelector((store:any)=>store.ChatReducers.getTicketByCustomer)
// const chatHistoryList = useSelector((store:any)=>store.ChatReducers.chatHistoryList)
// const renewalReasonData = useSelector((store:any)=>store.ChatReducers.renewalReasonData)

