import axios from "axios"
import { MAIN_ADMIN_BASE_API_URL, MAIN_API_BASE_API_URL, TEST_ADMIN_BASE_API_URL, TEST_API_BASE_API_URL } from "../../../apiGlobally"

// export function GetAllJobStatusData(localkey:any, date='') {
//     return axios.get(`${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getJobsDetailsByTimeslots?date=${date}`)
// }

// export function GetAllChampsPointsData(localkey:any, date='') {
//     return axios.get(`${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getCleanerStatusCount?date=${date}`)
// }


export function GetAllChampsPointsData(localKeyCheck: string, payloads: any, setloading: any) {
    const result = axios.get(`${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getCleanerStatusCount?date=${payloads?.date}&supervisorid=${payloads.supervisorid}&type=${payloads.type}`).then((res) => {
        // setloading(false)
        return res
    }).catch((err) => {
        // setloading(false)
        return err
    })
    return result
}

export function GetAllJobStatusData(localKeyCheck: string, payloads: any, setloading: any) {
    const result = axios.get(`${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getJobsDetailsByTimeslots?date=${payloads?.date}&supervisorid=${payloads.supervisorid}&type=${payloads.type}`).then((res) => {
        // setloading(false)
        return res
    }).catch((err) => {
        // setloading(false)
        return err
    })
    return result
}
export function getSupervisorList(localKeyCheck: string) {
    const result = axios.get(`${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getSupervisorList`).then((res) => {
        return res
    }).catch((err) => {
        return err
    })
    return result
}
export function getAlJobTypes(localKeyCheck: string) {
    const result = axios.get(`${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getAlJobTypes`).then((res) => {
        return res
    }).catch((err) => {
        return err
    })
    return result
}
export function clickToCallFromTicket(payloads: any) {
    const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
    const result = axios.post(`${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/clickToCallFromTicket`,payloads ).then((res) => {
        // setloading(false)
        return res
    }).catch((err) => {
        // setloading(false)
        return err
    })
    return result
}

export function getOvedueJobsCount(localKeyCheck: string, payloads: any, setloading: any) {
    const result = axios.get(`${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getOvedueJobsCount?date=${payloads?.date}`).then((res) => {
        // setloading(false)
        return res
    }).catch((err) => {
        // setloading(false)
        return err
    })
    return result
}