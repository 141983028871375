import RenderData from './RenderData';
import { useEffect,  } from 'react';
import { useDispatch } from 'react-redux';
import { MenuComponent } from '../../../../_metronic/assets/ts/components';
import {postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import Calling from '../../../consts/Call/Calling';
import Layout from '../../../consts/Call/Layout';
import TagsShow from '../../../consts/TagsShow/TagsShow';

export const LeaveColumn = [
    {
        name: 'Action',
        cell: (row: any) => (
            <TemporaryAssign row={row} />
        ),
        sortable: true,
        width: "100px",
    },
    {
        name: 'ID',
        selector: (row: any) => row.id,
        sortable: true,
        id: "ID",
        width: "90px"
    },
    {
        name: 'PHONE',
        cell: (row: any) => <div >
            <Calling title={row?.first_name + " " + row?.last_name} row_id={row?.id} row={row} type="cleanerid" From={Layout} isOpen={false} />
            {<TagsShow Tag={row?.private_tag} />}
        </div>,
        sortable: true,
        width: "200px",
        sortFunction: (a: any, b: any) => {
            let date1: any = a.first_name
            let date2: any = b.first_name
            return date1.toString().toLowerCase().localeCompare(date2.toString().toLowerCase())
        },
    },
    
    {
        name: 'Jobs Done Count',
        selector: (row: any) => row?.jobsDone,
        sortable: true,
        width: "auto",
    },
    {
        name: 'Unassigned Jobs Count',
        selector: (row: { unAssignedCount: number }) => row?.unAssignedCount,
        sortable: true,
        width: "auto",
    },
    {
        name: 'Area Manager',
        selector: (row: { champSupervisor: any; }) => {
            let render: any = <RenderData supervisors={[row?.champSupervisor]} />
            return render
        },
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.champSupervisor?.first_name
            let date2: any = b?.champSupervisor?.first_name
            return date1?.toString()?.toLowerCase()?.localeCompare(date2?.toString()?.toLowerCase())
        },
       
        sortable: true,
        width: "250px",
        style: {
            whiteSpace: "break-spaces"
        },
    },
];

const TemporaryAssign = ({ row }: any) => {
    const dispatch = useDispatch()
    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])
    const handleAssign = () => {
        dispatch({
            type: 'TEMPORARY_ASSIGN', payload: {
                champAvailty: row?.id,
                champAvailtyRender: Math.random()
            }
        })
    }

    const handleRevert = async () => {
        const response = await postRequest(URL_EndPoints()?.revertLeaveAbsent,
            {
                "cleaner_id": row?.id,
                'attendance_date': row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.attendance_date : '',
                'userid': JSON.parse(localStorage.getItem("user") || '')
            }, null)
        if (response?.status == 200) {
            toast.success('Absent/Leave reverted successfully.')
            dispatch({ type: "REFRESH_TABLE", payload: Math.random() })
        }
    }

    return (
        <div className="d-flex flex-row align-items-center">
            <div >
                <button
                    className='btn btn-sm btn-light btn-active-primary  fs-8 '
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                >
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </button>
                <div
                    className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                    data-kt-menu='true'
                    style={{
                        width: "500px",
                        zIndex: '105',
                        position: 'fixed',
                        inset: '0px 0px auto auto',
                        margin: '0px',
                        transform: 'translate(-59px, 440px)',
                    }}
                >
                    <div className='menu-item px-3'>
                        <a
                            id='kt_drawer_chat4_toggle4'
                            className='menu-link  px-3'
                            data-kt-users-table-filter='delete_row'
                            onClick={handleAssign}
                        >
                            Assign
                        </a>

                    </div>
                    <div className='menu-item px-3'>
                        <a
                            className='menu-link  px-3'
                            data-kt-users-table-filter='delete_row'
                            onClick={handleRevert}
                        >
                            Revert Leave/Absent
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}






