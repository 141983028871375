import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postRequest } from '../../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit } from '@fortawesome/free-solid-svg-icons';


const PriorityForm = ({ row }: any) => {

    const isVacancyAssigned = row?.champ_requirement_completed_champ?.first_name + row?.champ_requirement_completed_champ?.last_name

    const updatePriorityData = useSelector((store: any) => store.StateMangenementCenter.updatePriorityData)

    const [show, setShow] = useState<any>(false)
    const [loader, setLoader] = useState<any>(false)
    const [defaultValue, setDefaultValue] = useState<any>(null)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [payload, setPayload] = useState<any>({
        "id": row?.id,
        "updateFields": {
            priority: null
        }
    })



    useEffect(() => { setStoreRow(row) }, [row])

    useEffect(() => {
        const updatedData = updatePriorityData?.find((priority: any) => priority?.priority == storeRow?.priority_level?.priority)
        setDefaultValue(updatedData ? updatedData?.id : null)

        if (updatedData) {
            row.priority_level.priority = updatedData?.priority
            setStoreRow(row)
        }

    }, [updatePriorityData, storeRow?.priority_level?.priority])



    const updateRequirementType = async () => {

        if (payload?.updateFields?.priority) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints(null)?.updateChampRequirement, payload, setLoader)
            if (response?.data?.status === 200) {
                const updatedData = updatePriorityData?.find((priority: any) => priority?.id == payload?.updateFields?.priority)
                row.priority_level.priority = updatedData?.priority
                setStoreRow(row)
                toast.success(response?.data?.msg, { position: "top-center" })
                setShow(false)

            } else {
                toast.error(response?.data?.msg ? response?.data?.msg : "Seems server side problem", { position: "top-center" })
                setShow(false)


            }
            setLoader(false)
        }
        else {
            toast.error("Please select location ", { position: "top-center" })
            setLoader(false)

        }
    }




    const modalHandler = (isVacancyFilledBy: any) => {

        if (isVacancyFilledBy) {
          toast.success("Vacancy already filled so action is not allowed !", { position: "top-center" })
          return
        }
        setShow(true)
    
      }


    return (

        <>
            {
                loader ? "Updating..." : <>
                    <FontAwesomeIcon icon={isVacancyAssigned ? faBan : faEdit} className={` me-2  cursor-pointer text-${isVacancyAssigned ? "danger" : "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} />
                    <span>{storeRow?.priority_level ? storeRow?.priority_level?.priority : "NA"}</span>
                </>
            }

            <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-small">
                <Modal.Header closeButton>
                    <Modal.Title>Update Priority level </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-3">
                        <div className="col-12 mb-2">
                            <select
                                name='priority'
                                aria-label='Select a Timezone'
                                data-control='select2'
                                data-placeholder='Period'
                                className='form-select form-select-sm '
                                value={payload?.priority}
                                defaultValue={defaultValue}
                                onChange={(event) => setPayload({ ...payload, ["updateFields"]: { ["priority"]: event?.target?.value } })}
                            >
                                < option > Select Priority</option>
                                {
                                    updatePriorityData?.map((ele: any) => {
                                        return (
                                            <>
                                                < option value={ele.id}>{ele?.priority}</option>
                                            </>
                                        )
                                    })
                                }
                            </select>
                        </div>

                    </div>


                    <div className='d-flex flex-end justify-content-end col-12'>
                        <button
                            className={`btn btn-sm text-${loader ? "dark" : "white"} btn-${loader ? "success" : "primary"} mb-5 `}
                            onClick={updateRequirementType}
                            disabled={loader}
                        >
                            {loader ? "Updating..." : "Update"}

                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        </>








    )
}
export default PriorityForm
