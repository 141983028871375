import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
const MultSelectFilter = (props: any) => {
  const { handleChangeInputData, HeaderTitle, SelectData, DynamicKeys, id, name, defaultData, setSeacrch, isLoading = false, targetValue, timestamp, index, multiVal, isSearchable, filters } = props

  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {

      const newData = {
        label: DynamicKeys?.map((key: any) => !ele[key] ? "NA" : ele[key]).join(" "),
        value: ele[id],
        item: ele
      }
      return newData
    })

    setShowData(updatedData)
  }, [SelectData])

  const multiOnchange = (event: any) => {
 
    handleChangeInputData({
      item: event,
      value: event?.map((ele: any) => ele?.value),
    }, name, targetValue, true, timestamp, multiVal)
  }


  const debouncing = (event: any) => {
    setSeacrch(event, filters)
  }

  return (
    <div className='me-2 w-100 form-select-sm' key={index} >
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        isMulti
        onInputChange={setSeacrch && isSearchable ? (e: any) => debouncing(e) : undefined}
        options={showData}
        onChange={(event) => multiOnchange(event)}
        defaultValue={defaultData && defaultData?.item}
        styles={colourStyles2}
        isLoading={isLoading}
      />
    </div>
  )
}
export default MultSelectFilter



// static

// <SingleSelectSearchDetailsDefault
// handleChangeInputData={onChangeHandler}
// HeaderTitle='Select Setting'
// SelectData={settingConfigResults || []}
// DynamicKeys={["name"]}
// id={"id"}
// name={"settings"}
// targetValue={"value"}
// defaultData={null}
// ></SingleSelectSearchDetailsDefault>




// for dynamic 


{/* <SingleSelectSearchDetailsDefault
handleChangeInputData={onChangeHandler}
HeaderTitle='Filter By'
SelectData={storeFilterList[keyName] ? storeFilterList[keyName] : []}
DynamicKeys = {filters?.valueConfig?.apiConfig?.displayFields}
id={filters?.valueConfig?.apiConfig?.displayValue}
name={keyName}
targetValue={"value"}
defaultData={payload?.filterData[keyName] && (payload?.filterData[keyName][keyName + "_value"] ? payload?.filterData[keyName][keyName + "_value"] : [])}
></SingleSelectSearchDetailsDefault> */}