

import Dropdown from 'react-bootstrap/Dropdown';
import React, { memo, useEffect, useMemo, useState } from 'react'
import ReactSelectSearch from './components/ReactSelectSearch';
import "./components/selectBox.css"
import ReactSelectSmartFilter from './components/ReactSelectSmartFilter';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SmartFilterForm from './components/SmartFilterForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faClose, faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { makeApiRequest, setRunFilter } from '../../modules/admins/adminUtils';
import uuid from 'react-uuid';

const FilterHandler = memo(({
	onChangeHandler,
	actionHandler,
	Component,
	filterData,
	getCurrentFilter,
	selectedFilterData,
	removeFilter,
	payload,
	storeFilterList,
	filterComponents,
	filterkey,
	OperatorsData,
	setSeacrch,
	isLoadingFilter,
	listApiUrl,
	showFilterSelectedFilterHint
}: any) => {
	// filters={{
	// 	reqApi: "getCustomersList",
	// 	storeKeyName: "customerListData",
	//   }}

	const [showAddSmartFilter, setShowAddSmarFilter] = useState<boolean>(false)
	const [showUpdateSmarFilter, setShowUpdateSmarFilter] = useState<boolean>(false)
	const [smartFitlerRowData, setSmartFitlerRowData] = useState<any>(null)
	const [exernalFilterIsChecked, setExernalFilterIsChecked] = useState<boolean>(false)
	const [throwErrorWhileUpdateSmartFilter, setThrowErrorWhileUpdateSmartFilter] = useState<any>(null)

	const checkSmartFilterIsPresent = () => {
		if (!payload?.filterData || selectedFilterData?.length == 0) {
			toast.error("Please select some filter", { position: "top-center" })
			return
		}
		setShowAddSmarFilter(true)
	}

	useEffect(() => {
		exernalFilterIsChecked && setThrowErrorWhileUpdateSmartFilter(null)
	}, [exernalFilterIsChecked])

	const checkSmartFilterIsPresentForUpdateFilter = () => {
		if (exernalFilterIsChecked == false) {
			let steps = {
				step1: "✅ Fistly Enable `Associate external filter to smart filter checkbox",
				step2: "✅ Choose Smart filter that you want update or add external filter in previous smart filter",
				note: "✅ Associate external filter to smart filter checkbox is enable or not does not matter to add smart filter",
				throwError: true
			}
			setThrowErrorWhileUpdateSmartFilter(steps)
			// setTimeout(() => { setThrowErrorWhileUpdateSmartFilter(null) }, 50000)
			toast.error("Please read instruction carefully", { position: "top-center" })
			return
		}
		if (!payload?.filterData || selectedFilterData?.length == 0) {
			toast.error("Please select some filter", { position: "top-center" })
			return
		}
		if (!smartFitlerRowData?.value) {
			toast.error("⚠️ Firstly select any smart filter to update !", { position: "top-center" })
			return
		}
		setShowUpdateSmarFilter(true)

	}

	return (
		<>
			<Dropdown className='d-inline mb-2 btn-sm me-3 mx-2 ' >
				<Dropdown.Toggle id="dropdown-basic1" className='btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3 filterBtn' style={{ position: "relative" }}>
					<FontAwesomeIcon icon={faFilter} className="text-muted me-2 fs-7" /> {showFilterSelectedFilterHint ? <></> : <span style={{ width: "32px", height: "32px", border: "4px solid white", background: "green", padding: "2px", borderRadius: "50%", color: "white", position: "absolute", right: 0, top: -18 }}>{selectedFilterData?.length}</span>} FILTER
				</Dropdown.Toggle>

				<Dropdown.Menu className='dropDownOverideCss' >
					<Component
						onChangeHandler={onChangeHandler}
						filterData={filterData}
						getCurrentFilter={getCurrentFilter}
						selectedFilterData={selectedFilterData}
						removeFilter={removeFilter}
						payload={payload}
						storeFilterList={storeFilterList}
						filterComponents={filterComponents}
						OperatorsData={OperatorsData}
						setSeacrch={setSeacrch}
						isLoadingFilter={isLoadingFilter}
						listApiUrl={listApiUrl}
						setSmartFitlerRowData={setSmartFitlerRowData}
						setExernalFilterIsChecked={setExernalFilterIsChecked}
						exernalFilterIsChecked={exernalFilterIsChecked}
						throwErrorWhileUpdateSmartFilter={throwErrorWhileUpdateSmartFilter}
						setThrowErrorWhileUpdateSmartFilter={setThrowErrorWhileUpdateSmartFilter}
					></Component>
					{
						// selectedFilterData?.length > 0 &&
						<div className='d-flex justify-content-between p-2 w-100' >
							<button
								onClick={() => checkSmartFilterIsPresent()}
								className='btn-sm btn btn-sm btn-flex  bg-body btn-active-color-dark btn-active-secondary px-2 p-1 border border-secondary me-2 '
							>
								Add smart filter
							</button>
							<button
								onClick={() => checkSmartFilterIsPresentForUpdateFilter()}
								className='btn-sm  btn btn-sm btn-flex  bg-body btn-active-color-dark btn-active-secondary px-2 p-1 border border-secondary me-2 '
							>
								Update smart filter
							</button>
							<button
								onClick={() => actionHandler()}
								className='btn-sm  btn btn-sm btn-flex  bg-body btn-active-color-dark btn-active-secondary px-8 p-2 fw-bold border border-secondary me-3 '
							>
								Apply
							</button>
						</div>
					}
				</Dropdown.Menu>
			</Dropdown>

			{
				showAddSmartFilter ? <SmartFilterForm
					show={showAddSmartFilter}
					setShow={setShowAddSmarFilter}
					initialData={{
						title: "",
						description: "",
						is_public: 0,
						list_api_url: listApiUrl?.list_api_complete_url,
						filter_obj: payload?.filterData,
					}}
					row={{ filterData: payload?.filterData, listApiUrl }}
					urlEndPoint="addSmartFilter"
					formTitle="Add Smart Filter"

				/> : <></>
			}

			{
				showUpdateSmarFilter ? <SmartFilterForm
					show={showUpdateSmarFilter}
					setShow={setShowUpdateSmarFilter}
					initialData={{
						id: smartFitlerRowData?.value,
						title: smartFitlerRowData?.label,
						description: smartFitlerRowData?.item?.description ? smartFitlerRowData?.item?.description : "",
						is_public: smartFitlerRowData?.item?.is_public,
						list_api_url: listApiUrl?.list_api_complete_url,
						filter_obj: payload?.filterData,
					}}
					row={{ filterData: payload?.filterData, listApiUrl }}
					urlEndPoint="updateSmartFilter"
					formTitle="Update Smart Filter"
				/> : <></>
			}

		</>
	);
})




const DynamicFilter = memo(
	({
		onChangeHandler,
		filterData,
		getCurrentFilter,
		selectedFilterData,
		removeFilter,
		payload,
		storeFilterList,
		filterComponents,
		OperatorsData,
		setSeacrch,
		isLoadingFilter = false,
		listApiUrl,
		setSmartFitlerRowData,
		setExernalFilterIsChecked,
		exernalFilterIsChecked,
		throwErrorWhileUpdateSmartFilter,
		setThrowErrorWhileUpdateSmartFilter
	}: any) => {
		const smartFilterListDataFromRedux = useSelector((store: any) => store.RefreshPage.smartFilterListData[listApiUrl?.list_api_complete_url])

		// const tokens = localStorage.getItem("auth-token")
		// const userId = localStorage.getItem("user")
		// const webKit_hash_id = localStorage.getItem("webKit_hash_id")

		// const makeApiRequest = (singleApi: any, key: any, method: any, payload: any, item: any) => {
		// 	if (singleApi) {
		// 		let FetchBody: any = {
		// 			method: method,
		// 			headers: {
		// 				'Authorization': `Bearer ${tokens ? tokens + "," + "Useragenthash " + webKit_hash_id : ""}`,
		// 				'Userid': `${userId}`,
		// 				'Usertype': `${5}`,
		// 				'Content-Type': 'application/json'
		// 			}
		// 		}

		// 		if (payload) {
		// 			FetchBody.body = JSON.stringify(payload)
		// 		}

		// 		let url: any = new URL(singleApi);

		// 		return new Promise((resolve, reject) => {
		// 			fetch(url.toString(), FetchBody)
		// 				.then((res) => res.json())
		// 				.then((res) => {
		// 					return resolve({ [key]: res })
		// 				})
		// 				.catch((error: any) => {
		// 					return reject(error)
		// 				})
		// 		})
		// 	}

		// }

		// const getSmartFilter = async (event: any, name: any) => {
		// 	if (event) {
		// 		setSmartFitlerRowData(event)
		// 		let filterObject = JSON.parse(JSON.stringify(event?.filter_obj))
		// 		let keyValuePairObject = Object.keys(filterObject || {}).reduce((obj: any, key: any) => {
		// 			obj[key] = key;
		// 			return obj;
		// 		}, {});

		// 		const currentAllFilterList = filterData?.filter((smartFilter: any) => keyValuePairObject[smartFilter?.key])
		// 		const EndPointURLArr = currentAllFilterList?.filter((smartFilter: any) => smartFilter?.valueConfig?.callApi)?.map((smartFilter: any) => {
		// 			let reqMethod = smartFilter?.valueConfig?.apiConfig?.method
		// 			let reqApi = smartFilter?.valueConfig?.callApi ? smartFilter?.valueConfig?.apiConfig?.APIurl : null
		// 			let reqPayload = smartFilter?.valueConfig?.apiConfig?.requestData
		// 			let reqFrom = smartFilter?.key
		// 			return {
		// 				url: reqApi,
		// 				key: reqFrom,
		// 				method: reqMethod,
		// 				payload: reqMethod == "GET" ? null : reqPayload,
		// 				item: smartFilter,
		// 			}
		// 		})

		// 		const response = await Promise.all(EndPointURLArr?.map((singleApi: any) => makeApiRequest(
		// 			(singleApi?.url),
		// 			(singleApi?.key),
		// 			(singleApi?.method),
		// 			(singleApi?.payload),
		// 			(singleApi?.item),

		// 		)))
		// 			.then((response: any) => {
		// 				return response
		// 			})
		// 			.catch((error) => {
		// 				console.error('Error during API requests:', error)
		// 			})

		// 		let finalTaransformResponse: any = {}
		// 		if (response) {
		// 			response?.forEach((ele: any) => {
		// 				let key = Object.keys(ele)[0]
		// 				if (key) {
		// 					finalTaransformResponse[key] = ele[key]?.data || []
		// 				}
		// 			})

		// 		}


		// 		currentAllFilterList?.length > 0 && getCurrentFilter({
		// 			label: "smartFilter",
		// 			value: finalTaransformResponse,
		// 			// index: index,
		// 			item: currentAllFilterList,
		// 			filter_obj: filterObject,
		// 			smartFilter: true
		// 		}, { action: "select-option" })
		// 	}

		// }

		const getSmartFilter = (event: any, name: any) => {
			setRunFilter(event, name, filterData, getCurrentFilter, setSmartFitlerRowData)
		}


		const dynamicOnChangeHandler = (event: any, currentItemRemovedOrAdded: any) => {
			if (event) {
				event["exernalFilterIsChecked"] = exernalFilterIsChecked
			}
			getCurrentFilter(event, currentItemRemovedOrAdded)
		}


		const associateExternalFilterOnChangeHandler = () => {
			setExernalFilterIsChecked((prev: boolean) => !prev)
		}

		const memoizedSelectedFilterData = useMemo(() => selectedFilterData, [selectedFilterData]);
		return (
			<>
				<div className='p-6 w-325px w-md-375px '>
					{/* type filter parent .......... */}
					<div className='col-12 text-center '>

						<div key={"3232944232323231212132_global"} className='mb-1'>
							<ReactSelectSearch
								onChangeHandler={dynamicOnChangeHandler}
								HeaderTitle={"Filter by"}
								SelectData={filterData}
								title={"title"}
								name={"name"}
							/>
						</div>

						<div className='col-12 ms-3' key={"112123"} >
							<div className="form-check form-switch d-flex col-12" >
								<input className="form-check-input me-3" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={associateExternalFilterOnChangeHandler} checked={exernalFilterIsChecked} />
								<label className="form-check-label text-muted" >Associate external filter to smart filter</label>
							</div>
						</div>

						<div key={"1212132323294423232323_smart"}>
							<ReactSelectSmartFilter
								onChangeHandler={getSmartFilter}
								HeaderTitle={"Smart Filter by"}
								SelectData={smartFilterListDataFromRedux}
								title={"title"}
								name={"filter_objXXX"}
							/>
						</div>
						<div className='separator border-dark-1000 mt-5'></div>
					</div>

					{/* filter dropdown list here ............. */}
					<div className='col-12 text-center'>
						{
							memoizedSelectedFilterData?.map((filters: any, index: number) => {
								// let uuids = uuid()
								// let uuids2 = uuids + index
								let key = filters?.valueConfig?.valueType
								let keyName = filters?.key
								let CurrentComponent: any = filterComponents[key]
								let timestamp = filters?.timestamp == false ? false : filters?.timestamp == true ? true : "null"
								let multiVal = filters?.multiVal ? true : false
								let isSearchable = filters?.searchable ? true : false
								return <div key={filters?.uuidReact}>
									<div className='card p-2 mb-2'  >
										<CurrentComponent
											onChangeHandler={onChangeHandler}
											isData={filters?.operator}
											payload={payload}
											storeFilterList={storeFilterList}
											filters={filters}
											index={index}
											removeFilter={removeFilter}
											keyName={keyName}
											setSeacrch={setSeacrch}
											isLoadingFilter={isLoadingFilter}
											timestamp={timestamp}
											multiVal={multiVal}
											isSearchable={isSearchable}
										/>
									</div>
									{
										memoizedSelectedFilterData?.length >= 1 && index < memoizedSelectedFilterData?.length - 1 ? <>
											<div style={{ position: "relative", height: "28px", marginTop: "23px", marginBottom: "-15px" }} key={filters?.uuidReact + index} >
												{
													OperatorsData?.length > 0 && <select
														name='date_typer'
														aria-label='Select a Timezone'
														data-control='select2'
														data-placeholder='Period'
														className=' bg-body btn-color-gray-700   px-2 selectBox  me-2 '
														onChange={(event: any) => onChangeHandler(event.target, memoizedSelectedFilterData[index + 1]?.key, "junction")}
														value={payload?.filterData[memoizedSelectedFilterData[index + 1]?.key]?.junction || "AND"}
														style={{ position: "absolute", top: -20, left: 100 }}
													>
														{
															OperatorsData?.map((ele: any) => {
																return (
																	<>
																		< option value={ele.id}>{ele?.name}</option>
																	</>
																)
															})
														}
													</select>
												}
												<div className='separator border-dark-1000 '></div>
											</div>
										</> : <></>
									}

								</div>
							})
						}

						{/* information shows here ............. */}

						{
							!throwErrorWhileUpdateSmartFilter?.throwError && memoizedSelectedFilterData?.length == 0 && <span className=' fs-5 mt-12 me-6 text-muted font-italic'>
								<br />
								<i className="bi bi-bug-fill fs-2 text-muted mt-12 me-4 "></i>
								<br />
								&#x261d; Oops! No filter added yet...
							</span>
						}

						{
							throwErrorWhileUpdateSmartFilter?.throwError && <div className='me-2 text-muted font-italic text-start col-12 d-flex flex-column mt-2'>
								<span className=' col-12 text-start mb-2 text-dark'>🙏 Read instruction  before update smart filter <FontAwesomeIcon icon={faTimesCircle} className='ms-2 fs-4 cursor-pointer text-danger' onClick={() => setThrowErrorWhileUpdateSmartFilter(null)} /></span>
								<div className='d-flex flex-column text-start justify-content-start align-items-center col-12'>
									<p className=' col-12 mb-2'>
										<p className='text-center text-decoration-underline text-dark'>STEP-I</p>
										{throwErrorWhileUpdateSmartFilter?.step1}
									</p>
									<p className=' col-12'>
										<p className='text-center text-decoration-underline text-dark'>STEP-II</p>
										{throwErrorWhileUpdateSmartFilter?.step2}
									</p>
									<p className=' col-12'>
										<p className='text-center text-decoration-underline text-dark'>NOTE:</p>
										{throwErrorWhileUpdateSmartFilter?.note}
									</p>
								</div>
							</div>
						}


					</div>

				</div>
			</>
		)
	}
)



export {
	FilterHandler,
	DynamicFilter,
}






// oldddddddddddddddddddddddddddddddd codeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee



// import Dropdown from 'react-bootstrap/Dropdown';
// import React, { useEffect, useState } from 'react'
// import ReactSelectSearch from './components/ReactSelectSearch';
// import "./components/selectBox.css"
// import ReactSelectSmartFilter from './components/ReactSelectSmartFilter';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import SmartFilterForm from './components/SmartFilterForm';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleCheck, faClose, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// function FilterHandler({
// 	onChangeHandler,
// 	actionHandler,
// 	Component,
// 	filterData,
// 	getCurrentFilter,
// 	selectedFilterData,
// 	removeFilter,
// 	payload,
// 	storeFilterList,
// 	filterComponents,
// 	filterkey,
// 	OperatorsData,
// 	setSeacrch,
// 	isLoadingFilter,
// 	listApiUrl
// }: any) {


// 	const [showAddSmartFilter, setShowAddSmarFilter] = useState<boolean>(false)
// 	const [showUpdateSmarFilter, setShowUpdateSmarFilter] = useState<boolean>(false)
// 	const [smartFitlerRowData, setSmartFitlerRowData] = useState<any>(null)
// 	const [exernalFilterIsChecked, setExernalFilterIsChecked] = useState<boolean>(false)
// 	const [throwErrorWhileUpdateSmartFilter, setThrowErrorWhileUpdateSmartFilter] = useState<any>(null)

// 	const checkSmartFilterIsPresent = () => {
// 		if (!payload?.filterData || selectedFilterData?.length == 0) {
// 			toast.error("Please select some filter", { position: "top-center" })
// 			return
// 		}
// 		setShowAddSmarFilter(true)
// 	}

// 	useEffect(() => {
// 		exernalFilterIsChecked && setThrowErrorWhileUpdateSmartFilter(null)
// 	}, [exernalFilterIsChecked])

// 	const checkSmartFilterIsPresentForUpdateFilter = () => {
// 		if (exernalFilterIsChecked == false) {
// 			let steps = {
// 				step1: "✅ Fistly Enable `Associate external filter to smart filter checkbox",
// 				step2: "✅ Choose Smart filter that you want update or add external filter in previous smart filter",
// 				note: "✅ Associate external filter to smart filter checkbox is enable or not does not matter to add smart filter",
// 				throwError: true
// 			}
// 			setThrowErrorWhileUpdateSmartFilter(steps)
// 			// setTimeout(() => { setThrowErrorWhileUpdateSmartFilter(null) }, 50000)
// 			toast.error("Please read instruction carefully", { position: "top-center" })
// 			return
// 		}
// 		if (!payload?.filterData || selectedFilterData?.length == 0) {
// 			toast.error("Please select some filter", { position: "top-center" })
// 			return
// 		}
// 		if (!smartFitlerRowData?.value) {
// 			toast.error("⚠️ Firstly select any smart filter to update !", { position: "top-center" })
// 			return
// 		}
// 		setShowUpdateSmarFilter(true)

// 	}

// 	return (
// 		<>
// 			<Dropdown className='d-inline mb-2 btn-sm me-3 mx-2' >
// 				<Dropdown.Toggle id="dropdown-basic1"
// 					className='btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3 filterBtn'
// 					style={{ position: "relative" }}>
// 					<i className="bi bi-filter p-1 "  ></i>  <span style={{ width: "32px", height: "32px", border: "4px solid white", background: "green", padding: "2px", borderRadius: "50%", color: "white", position: "absolute", right: 0, top: -18 }}>{selectedFilterData?.length}</span> FILTER
// 				</Dropdown.Toggle>

// 				<Dropdown.Menu className='dropDownOverideCss' >
// 					<Component
// 						onChangeHandler={onChangeHandler}
// 						filterData={filterData}
// 						getCurrentFilter={getCurrentFilter}
// 						selectedFilterData={selectedFilterData}
// 						removeFilter={removeFilter}
// 						payload={payload}
// 						storeFilterList={storeFilterList}
// 						filterComponents={filterComponents}
// 						OperatorsData={OperatorsData}
// 						setSeacrch={setSeacrch}
// 						isLoadingFilter={isLoadingFilter}
// 						listApiUrl={listApiUrl}
// 						setSmartFitlerRowData={setSmartFitlerRowData}
// 						setExernalFilterIsChecked={setExernalFilterIsChecked}
// 						exernalFilterIsChecked={exernalFilterIsChecked}
// 						throwErrorWhileUpdateSmartFilter={throwErrorWhileUpdateSmartFilter}
// 						setThrowErrorWhileUpdateSmartFilter={setThrowErrorWhileUpdateSmartFilter}
// 					></Component>
// 					{
// 						// selectedFilterData?.length > 0 &&
// 						<div className='d-flex justify-content-between p-2 w-100' >
// 							<button
// 								onClick={() => checkSmartFilterIsPresent()}
// 								className='btn-sm btn btn-sm btn-flex  bg-body btn-active-color-dark btn-active-secondary px-2 p-1 border border-secondary me-2 '
// 							>
// 								Add smart filter
// 							</button>
// 							<button
// 								onClick={() => checkSmartFilterIsPresentForUpdateFilter()}
// 								className='btn-sm  btn btn-sm btn-flex  bg-body btn-active-color-dark btn-active-secondary px-2 p-1 border border-secondary me-2 '
// 							>
// 								Update smart filter
// 							</button>
// 							<button
// 								onClick={() => actionHandler()}
// 								className='btn-sm  btn btn-sm btn-flex  bg-body btn-active-color-dark btn-active-secondary px-8 p-2 fw-bold border border-secondary me-3 '
// 							>
// 								Apply
// 							</button>
// 						</div>
// 					}
// 				</Dropdown.Menu>
// 			</Dropdown>

// 			{
// 				showAddSmartFilter ? <SmartFilterForm
// 					show={showAddSmartFilter}
// 					setShow={setShowAddSmarFilter}
// 					initialData={{
// 						title: "",
// 						description: "",
// 						is_public: 0,
// 						list_api_url: listApiUrl?.list_api_complete_url,
// 						filter_obj: payload?.filterData,
// 					}}
// 					row={{ filterData: payload?.filterData, listApiUrl }}
// 					urlEndPoint="addSmartFilter"
// 					formTitle="Add Smart Filter"

// 				/> : <></>
// 			}

// 			{
// 				showUpdateSmarFilter ? <SmartFilterForm
// 					show={showUpdateSmarFilter}
// 					setShow={setShowUpdateSmarFilter}
// 					initialData={{
// 						id: smartFitlerRowData?.value,
// 						title: smartFitlerRowData?.label,
// 						description: smartFitlerRowData?.item?.description ? smartFitlerRowData?.item?.description : "",
// 						is_public: smartFitlerRowData?.item?.is_public,
// 						list_api_url: listApiUrl?.list_api_complete_url,
// 						filter_obj: payload?.filterData,
// 					}}
// 					row={{ filterData: payload?.filterData, listApiUrl }}
// 					urlEndPoint="updateSmartFilter"
// 					formTitle="Update Smart Filter"
// 				/> : <></>
// 			}

// 		</>
// 	);
// }

// const DynamicFilter = ({
// 	onChangeHandler,
// 	filterData,
// 	getCurrentFilter,
// 	selectedFilterData,
// 	removeFilter,
// 	payload,
// 	storeFilterList,
// 	filterComponents,
// 	OperatorsData,
// 	setSeacrch,
// 	isLoadingFilter = false,
// 	listApiUrl,
// 	setSmartFitlerRowData,
// 	setExernalFilterIsChecked,
// 	exernalFilterIsChecked,
// 	throwErrorWhileUpdateSmartFilter,
// 	setThrowErrorWhileUpdateSmartFilter
// }: any) => {

// 	const smartFilterListDataFromRedux = useSelector((store: any) => store.RefreshPage.smartFilterListData[listApiUrl?.list_api_complete_url])

// 	const tokens = localStorage.getItem("auth-token")
// 	const userId = localStorage.getItem("user")
// 	const webKit_hash_id = localStorage.getItem("webKit_hash_id")



// 	const makeApiRequest = (singleApi: any, key: any, method: any, payload: any, item: any) => {
// 		if (singleApi) {
// 			let FetchBody: any = {
// 				method: method,
// 				headers: {
// 					'Authorization': `Bearer ${tokens ? tokens + "," + "Useragenthash " + webKit_hash_id : ""}`,
// 					'Userid': `${userId}`,
// 					'Usertype': `${5}`,
// 					'Content-Type': 'application/json'
// 				}
// 			}

// 			if (payload) {
// 				FetchBody.body = JSON.stringify(payload)
// 			}

// 			let url: any = new URL(singleApi);

// 			return new Promise((resolve, reject) => {
// 				fetch(url.toString(), FetchBody)
// 					.then((res) => res.json())
// 					.then((res) => {
// 						return resolve({ [key]: res })
// 					})
// 					.catch((error: any) => {
// 						return reject(error)
// 					})
// 			})
// 		}

// 	}

// 	const getSmartFilter = async (event: any, name: any) => {
// 		if (event) {
// 			setSmartFitlerRowData(event)
// 			let filterObject = JSON.parse(JSON.stringify(event?.filter_obj))
// 			let keyValuePairObject = Object.keys(filterObject || {}).reduce((obj: any, key: any) => {
// 				obj[key] = key;
// 				return obj;
// 			}, {});

// 			const currentAllFilterList = filterData?.filter((smartFilter: any) => keyValuePairObject[smartFilter?.key])
// 			const EndPointURLArr = currentAllFilterList?.filter((smartFilter: any) => smartFilter?.valueConfig?.callApi)?.map((smartFilter: any) => {
// 				let reqMethod = smartFilter?.valueConfig?.apiConfig?.method
// 				let reqApi = smartFilter?.valueConfig?.callApi ? smartFilter?.valueConfig?.apiConfig?.APIurl : null
// 				let reqPayload = smartFilter?.valueConfig?.apiConfig?.requestData
// 				let reqFrom = smartFilter?.key
// 				return {
// 					url: reqApi,
// 					key: reqFrom,
// 					method: reqMethod,
// 					payload: reqMethod == "GET" ? null : reqPayload,
// 					item: smartFilter,
// 				}
// 			})

// 			const response = await Promise.all(EndPointURLArr?.map((singleApi: any) => makeApiRequest(
// 				(singleApi?.url),
// 				(singleApi?.key),
// 				(singleApi?.method),
// 				(singleApi?.payload),
// 				(singleApi?.item),

// 			)))
// 				.then((response: any) => {
// 					return response
// 				})
// 				.catch((error) => {
// 					console.error('Error during API requests:', error)
// 				})

// 			let finalTaransformResponse: any = {}
// 			if (response) {
// 				response?.forEach((ele: any) => {
// 					let key = Object.keys(ele)[0]
// 					if (key) {
// 						finalTaransformResponse[key] = ele[key]?.data || []
// 					}
// 				})

// 			}


// 			currentAllFilterList?.length > 0 && getCurrentFilter({
// 				label: "smartFilter",
// 				value: finalTaransformResponse,
// 				// index: index,
// 				item: currentAllFilterList,
// 				filter_obj: filterObject,
// 				smartFilter: true
// 			}, { action: "select-option" })

// 		}

// 	}

// 	const dynamicOnChangeHandler = (event: any, currentItemRemovedOrAdded: any) => {
// 		if (event) {
// 			event["exernalFilterIsChecked"] = exernalFilterIsChecked
// 		}
// 		getCurrentFilter(event, currentItemRemovedOrAdded)
// 	}


// 	const associateExternalFilterOnChangeHandler = () => {
// 		setExernalFilterIsChecked((prev: boolean) => !prev)
// 	}

// 	return (
// 		<>
// 			<div className='p-6 w-325px w-md-375px '>
// 				{/* type filter parent .......... */}
// 				<div className='col-12 text-center '>



// 					<div key={"3232944232323231212132"} className='mb-1'>
// 						<ReactSelectSearch
// 							onChangeHandler={dynamicOnChangeHandler}
// 							HeaderTitle={"Filter by"}
// 							SelectData={filterData}
// 							title={"title"}
// 							name={"name"}
// 						/>
// 					</div>
// 					<div className='col-12 ms-3' key={112123} >
// 						<div className="form-check form-switch d-flex col-12" >
// 							<input className="form-check-input me-3" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={associateExternalFilterOnChangeHandler} checked={exernalFilterIsChecked} />
// 							<label className="form-check-label text-muted" >Associate external filter to smart filter</label>
// 						</div>
// 					</div>

// 					<div key={"1212132323294423232323_smart"}>
// 						<ReactSelectSmartFilter
// 							onChangeHandler={getSmartFilter}
// 							HeaderTitle={"Smart Filter by"}
// 							SelectData={smartFilterListDataFromRedux}
// 							title={"title"}
// 							name={"filter_objXXX"}
// 						/>
// 					</div>
// 					<div className='separator border-dark-1000 mt-5'></div>
// 				</div>

// 				{/* filter dropdown list here ............. */}
// 				<div className='col-12 text-center'>
// 					{
// 						selectedFilterData?.map((filters: any, index: number) => {
// 							let key = filters?.valueConfig?.valueType
// 							let keyName = filters?.key
// 							let CurrentComponent: any = filterComponents[key]
// 							let timestamp = filters?.timestamp == false ? false : filters?.timestamp == true ? true : "null"
// 							let multiVal = filters?.multiVal ? true : false
// 							let isSearchable = filters?.searchable ? true : false

// 							return <div key={filters?.uuidReact}>

// 								<div className='card p-2 mb-2'  >
// 									<CurrentComponent
// 										onChangeHandler={onChangeHandler}
// 										isData={filters?.operator}
// 										payload={payload}
// 										storeFilterList={storeFilterList}
// 										filters={filters}
// 										index={index}
// 										removeFilter={removeFilter}
// 										keyName={keyName}
// 										setSeacrch={setSeacrch}
// 										isLoadingFilter={isLoadingFilter}
// 										timestamp={timestamp}
// 										multiVal={multiVal}
// 										isSearchable={isSearchable}
// 									/>
// 								</div>
// 								{
// 									selectedFilterData?.length >= 1 && index < selectedFilterData?.length - 1 ? <>

// 										<div style={{ position: "relative", height: "28px", marginTop: "23px", marginBottom: "-15px" }} >

// 											{

// 												OperatorsData?.length > 0 && <select
// 													name='date_typer'
// 													aria-label='Select a Timezone'
// 													data-control='select2'
// 													data-placeholder='Period'
// 													className=' bg-body btn-color-gray-700   px-2 selectBox  me-2 '
// 													onChange={(event: any) => onChangeHandler(event.target, selectedFilterData[index + 1]?.key, "junction")}
// 													value={payload?.filterData[selectedFilterData[index + 1]?.key]?.junction || "AND"}
// 													style={{ position: "absolute", top: -20, left: 100 }}
// 												>
// 													{
// 														OperatorsData?.map((ele: any) => {
// 															return (
// 																<>
// 																	< option value={ele.id}>{ele?.name}</option>
// 																</>
// 															)
// 														})
// 													}
// 												</select>
// 											}
// 											{/* <hr /> */}

// 											<div className='separator border-dark-1000 '></div>

// 										</div>
// 									</> : <></>
// 								}

// 							</div>
// 						})
// 					}

// 					{/* information shows here ............. */}

// 					{
// 						!throwErrorWhileUpdateSmartFilter?.throwError && selectedFilterData?.length == 0 && <span className=' fs-5 mt-12 me-6 text-muted font-italic'>
// 							<br />
// 							<i className="bi bi-bug-fill fs-2 text-muted mt-12 me-4 "></i>
// 							<br />
// 							&#x261d; Oops! No filter added yet...
// 						</span>
// 					}

// 					{
// 						throwErrorWhileUpdateSmartFilter?.throwError && <div className='me-2 text-muted font-italic text-start col-12 d-flex flex-column mt-2'>
// 							<span className=' col-12 text-start mb-2 text-dark'>🙏 Read instruction  before update smart filter <FontAwesomeIcon icon={faTimesCircle} className='ms-2 fs-4 cursor-pointer text-danger' onClick={() => setThrowErrorWhileUpdateSmartFilter(null)} /></span>
// 							<div className='d-flex flex-column text-start justify-content-start align-items-center col-12'>
// 								<p className=' col-12 mb-2'>
// 									<p className='text-center text-decoration-underline text-dark'>STEP-I</p>
// 									{throwErrorWhileUpdateSmartFilter?.step1}
// 								</p>
// 								<p className=' col-12'>
// 									<p className='text-center text-decoration-underline text-dark'>STEP-II</p>
// 									{throwErrorWhileUpdateSmartFilter?.step2}
// 								</p>
// 								<p className=' col-12'>
// 									<p className='text-center text-decoration-underline text-dark'>NOTE:</p>
// 									{throwErrorWhileUpdateSmartFilter?.note}
// 								</p>
// 							</div>
// 						</div>
// 					}


// 				</div>

// 			</div>
// 		</>
// 	)
// }

// export {
// 	FilterHandler,
// 	DynamicFilter,
// }
































