import moment from 'moment'
import React, { memo, useCallback, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import copy from "copy-to-clipboard";
// import './custom.css'
import { postRequest } from '../../Server/Requests'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import "react-tooltip/dist/react-tooltip.css";
import { toast } from 'react-toastify';
import { openChatByMessage } from '../../../../Redux/champChatDashboardReducer/champsActions';
import { useDispatch } from 'react-redux';
import { ChatHistoryBody } from './ChatHistoryBody';
const ChatHistory = ({ TicketListDatas , cleanerid}: any) => {
    const dispatch = useDispatch()

    const [bodyData, setBodyData] = useState<any>({});
    const [selectedKey, setSelectedKey] = useState<any>([]);

    const handleSelect = async (eventKey: any) => {
        if (eventKey.length > 0 && !selectedKey.includes(+(eventKey[eventKey.length - 1]))) {
            setSelectedKey((prevSetSelectedKey: any) => [...prevSetSelectedKey, +eventKey[eventKey.length - 1]]);
            const response = await postRequest(URL_EndPoints()?.getCleanerTicketReplies, { "ticketid": +(eventKey[eventKey.length - 1]) }, null)
            if (response.status == 200) {
                const data: any = response?.data?.data
                setBodyData((prevState: any) => ({ ...prevState, [+(eventKey[eventKey.length - 1])]: data }));
            }
        } else {
        }
    };

    const openChatHistoryTicket = (ticketId:any) => dispatch(openChatByMessage({ ticketid: ticketId }, cleanerid) as any)


    function callback(value: any) {
        let val = ""
        for (let i = 0; i < value.length; i++) {
            if (value[i] == 0 || value[i] == 1 || value[i] == 2 || value[i] == 3 || value[i] == 4 || value[i] == 5 || value[i] == 6 || value[i] == 7 || value[i] == 8 || value[i] == 9) {
                val = val + value[i];
            }
        }
        copy(val)
        toast.success(`Copied to clipboard (${value} ) - `, { position: "top-center" })
    }

    const copyToClipboards = React.useCallback(callback, [callback])

    return (
        <>
            <Accordion defaultActiveKey="" onSelect={handleSelect} alwaysOpen>
                {
                    TicketListDatas?.length > 0 ? TicketListDatas?.map((items: any, index: number) => {
                        
                        return (
                            <Accordion.Item eventKey={'' + items?.id} className="margins" >
                                <Accordion.Header>


                                    <div className='col-md-11 d-flex align-items-center bg-light-secondary rounded p-2'>
                                        <div className='flex-grow-1 me-2'>

                                            <span className='svg-icon svg-icon-primary me-2'>
                                                <i className="bi bi-ticket-detailed text-primary"></i>
                                            </span>


                                            <span className='fw-bold text-primary fs-7' onClick={() => copyToClipboards(items?.ticketno)} >
                                            #{items?.ticketno}  {items?.title}
                                            </span>

                                            <span className='text-muted fw-semibold d-block'>
                                                Next title goes here    
                                            </span>

                                        </div>


                                        <span className='fw-bold fs-9 text-dark py-1 text-end mb-1'>
                                            <span className='text-danger' onClick={()=>openChatHistoryTicket(items?.id)}>
                                                <i className="fs-5 text-primary fa-solid fa-share">  </i>
                                            </span>

                                            <br />
                                            {items.updatedAt}
                                                <br />
                                        </span>

                                    </div>


                                </Accordion.Header>
                                <Accordion.Body>

                                    {bodyData['' + items.id] ? (
                                        // Render the fetched data for Accordion Item 0
                                        <div><ChatHistoryBody ticketreplies={bodyData['' + items.id]}></ChatHistoryBody></div>
                                    ) : (
                                        // Render a loading message while the data is being fetched
                                        <div>Loading...</div>
                                    )}

                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }) : ''
                }
            </Accordion>
        </>
    )
}
export default memo(ChatHistory)