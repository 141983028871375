import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import './custom.css'
import { useSelector } from 'react-redux';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { ShowHideColumns } from '../../consts/ShowHideColumns';
import { Button, Form, Modal, Spinner, Tooltip } from 'react-bootstrap';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { ToggleColumns } from '../../consts/toggleColumns/ToggleColumns';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../consts/components/Utility';
import BulkAssignTicketsCustomerLeads from './BulkAssignTicketsCustomerLeads';
import BulkWhatsappNotificationCustomerLeads from './BulkWhatsappNotificationCustomerLeads';
import BulkResetCustomerLeads from './BulkResetCustomerLeads';
import BulkBatchAssignCustomerLeads from './BulkBatchAssignCustomerLeads';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import { ReactTableComponentStylesSheet } from '../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet';
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList';
import AdminBulkForm from '../admins/adminForms/AdminBulkForm';
import { filteredSelectedRowsIds, useAdminListApiUrl, useAdminManagementPermission, useAdminSmartFilterList } from '../admins/adminUtils';
import { customerLeadsGetBatchesFormData, customerLeadsResetFormData, customerLeadsWhatsappNotificationFormData } from './customerLeadUtil';
import AdminBulkAssignUnassignForm from '../admins/adminForms/AdminBulkAssignUnassignForm';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../admins/adminConstant';
import { useParams } from 'react-router';

const VisitorsListMainV2 = ({ filterDataOutsource }: any) => {

  const { permissionWrapperHideColsUtil } = useAdminManagementPermission()

  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [filterDataTemp, setfilterDataTemp] = useState<any>([])
  const [filterDataTemp2, setfilterDataTemp2] = useState<any>([])
  // to search data here 
  const [selectedButon, setSelectedButon] = React.useState<any>(null)
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [sortedColumns, setSortedColumns] = useState<any>(permissionWrapperHideColsUtil(columns(selectedButon, handleFetchAllCallLogs, handleFetchTransactionsClick)));

  useEffect(() => {
    const filterss = storeFilterList?.batch_id?.filter((ele: any) => ele.id == 0)
    function invoked() {
      setStoreFilterList({ ...storeFilterList, ["batch_id"]: [{ name: "Not set", id: 0 }, ...storeFilterList["batch_id"]] })
    }
    filterss?.length == 0 && storeFilterList?.batch_id && invoked()
  }, [storeFilterList?.batch_id])

  useEffect(() => {
    if (filterData) {
      const newColumns = permissionWrapperHideColsUtil(columns(selectedButon, handleFetchAllCallLogs, handleFetchTransactionsClick, filterData?.map((ele: any) => ele?.id)))
      setSortedColumns(newColumns);
    }
  }, [filterData]);

  // server side
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");

  // server side

  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload(true, {
    // "status_id": { operator: "!=", value: 44 },
    // "assignedadminuserid": { operator: "==", value: userId },
  }));


  // api calling here logic  here +++++++++++++++++++++++++++++++
  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.allVisotorListPagination2, payloads, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.customerVisitor)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  // api calling here logic  here +++++++++++++++++++++++++++++++
  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)

  }

  // handle pagination here 
  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  };

  // deboucing 
  useEffect(() => {
    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [search])

  // useEffect(() => {
  //   !search && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  // }, [search])

  useEffect(() => {
    let { isFilter, customerLeadsFilter } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: customerLeadsFilter }, setPending)
    isFilter && setPayload({ ...payload, ["filterData"]: customerLeadsFilter })
  }, [
    search,
    // filterDataOutsource?.customerLeadsFilter?.id?.value,
    filterDataOutsource?.isFilter,
  ])


  const handleRefresh = async () => {
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);
    setFilteredSelectedRows([])
  }

  function handleFetchAllCallLogs() {
    // filterData is coming empty if used directly, so use below way to access current state
    setfilterData((prev: any) => {
      setfilterDataTemp(prev);
      return prev;
    });
  }

  function handleFetchTransactionsClick() {
    // filterData is coming empty if used directly, so use below way to access current state
    setfilterData((prev: any) => {
      setfilterDataTemp2(prev);
      return prev;
    });
  }

  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setPending(true);
      const clone = filterDataTemp.slice();
      const customerIds = clone.map((e: any) => e.id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, setPending);
      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e.phone || ele[0]?.To === e.phone) || [];
        clone[i].callLogs = foundLog;
      }
      setfilterData(clone);
      setPending(false);
    }
    filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [filterDataTemp])

  useEffect(() => {
    const fetchAllTransactions = async () => {
      setPending(true);
      const clone = filterDataTemp2.slice();
      const customerids = clone.map((e: any) => e.id);
      const response: any = await postRequest(URL_EndPoints()?.getTransactionsByCustomerIds, {
        customerids
      }, setPending);
      const transactions = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundTransaction = transactions?.find((ele: any) => ele[0]?.phone === e?.phone) || [];
        clone[i].transactions = foundTransaction;
      }
      setfilterData(clone);
      setPending(false);
    }
    filterDataTemp2?.length > 0 && fetchAllTransactions();
  }, [filterDataTemp2])



  // bulk assign tickets --------------------
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [showAssignPopUp, setShowAssignPopUp] = useState(false)
  const [showNotifyPopUp, setShowNotifyPopUp] = useState(false);
  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  };

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);

  }, [selectedFilterData?.length]);

  const [showResetLeadPopUp, setShowResetLeadPopUp] = useState(false);
  const [showAssignBatchPopUp, setShowAssignBatchPopUp] = useState(false);

  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  const [showCampaignContact, setShowCampaignContact] = useState(false);

  const whatsappNotifyFormData = [
    {
      HeaderTitle: "Select Whatsapp Template",
      filterApi: "whatsapp/getWhatsAppTemplate",
      label: "",
      valueType: "select",
      inputType: "",
      displayFields: ["name"],
      valueField: "name",
      name: "templateName",
      from: "",
      to: "",
      isRequired: true,
      errMessage: "Whatsapp Template name is required !",
      searchable: false,
      wa_number_id: 1,
      userType: 1,
    },
  ]


  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("allVisotorListPagination2")
  }, [])


  return (
    <>
      <div>
        {

          <DataTable
            title="Customer Leads"
            selectableRows
            customStyles={ReactTableComponentStylesSheet}
            columns={reorderColumns(sortedColumns, "customerLeadsReorderedItem")}
            data={filterData}
            onColumnOrderChange={cols => storeReorderIndexes(cols, "customerLeadsReorderedItem")}
            progressPending={pending}
            clearSelectedRows={toggledClearRows}
            fixedHeader
            fixedHeaderScrollHeight='auto'
            selectableRowsHighlight
            highlightOnHover
            paginationComponentOptions={paginationRowsPerPageOptionsAll}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            subHeader
            // server 
            pagination
            sortServer
            paginationServer
            onSort={handleSort}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSelectedRowsChange={handleSelectedRowsChange}
            // server 
            subHeaderComponent={
              <>
                <MainContainerWrapper>
                  <LeftBox>
                    <SearchBoxModal search={search} setSearch={setSearch} />
                  </LeftBox>
                  {
                    filterDataOutsource?.showFilter ? <></> : <RightBox>
                      <Refresh refreshNow={handleRefresh} />

                      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-show-hide-column"]}>
                        <ShowHideColumns columns={permissionWrapperHideColsUtil(columns(selectedButon))} sortedColumns={sortedColumns} setSortedColumns={setSortedColumns} />
                      </PermissionContextProvider>

                      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-toggle-date-format"]}>
                        <ToggleColumns columns={[{ name: "Due Date", id: "Due Date" }]} from="VisitorCustomer" />
                      </PermissionContextProvider>

                      <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-filter"]}>
                        <FilterHandler
                          onChangeHandler={onChangeHandler}
                          actionHandler={actionHandler}
                          getCurrentFilter={getCurrentFilter}
                          removeFilter={removeFilter}
                          payload={payload}
                          Component={DynamicFilter}
                          filterData={filter}
                          OperatorsData={operators}
                          selectedFilterData={selectedFilterData}
                          filterComponents={KeywiseComponents()}
                          // filter data  -----
                          storeFilterList={storeFilterList}
                          filterkey={filterkey}
                          setSeacrch={setSearchFilter}
                          isLoadingFilter={isLoadingFilter}
                          listApiUrl={listApiUrl("allVisotorListPagination2")}

                        />
                      </PermissionContextProvider>


                    </RightBox>
                  }

                </MainContainerWrapper>

                {
                  filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-bulk-assign-ticket"]}>
                      <BulkUtilButton title="Bulk Assign Ticket" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignPopUp} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-bulk-whatsapp-notification"]}>
                      <BulkUtilButton title="Bulk WhatsApp Notify Customer" filteredSelectedRows={filteredSelectedRows} setShow={setShowNotifyPopUp} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-bulk-reset-customer"]}>
                      <BulkUtilButton title="Bulk Reset Customer" filteredSelectedRows={filteredSelectedRows} setShow={setShowResetLeadPopUp} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-bulk-batch-assign-lead"]}>
                      <BulkUtilButton title="Bulk Assign Batch To Leads" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignBatchPopUp} />
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-bulk-add-to-contact-to-list"]}>
                      <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={filteredSelectedRows} setShow={setShowCampaignContact} />
                    </PermissionContextProvider>

                  </BulkUtilButtonWrapper> : <></>
                }


              </>
            }
          />
        }
      </div>

      {/* {
        showAssignPopUp ? <BulkAssignTicketsCustomerLeads
          showAssignPopUp={showAssignPopUp}
          setShowAssignPopUp={setShowAssignPopUp}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>

      } */}



      {
        showAssignPopUp ? <AdminBulkAssignUnassignForm
          show={showAssignPopUp}
          setShow={setShowAssignPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "tickets[0].id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="msg"
          initialData={{
            admins: [],
            cleaners: [],
            admins_to_unassign: [],
            cleaners_to_unassign: [],
            dueDate: "",
            should_unassign: false,
          }}
          validationSchema={{
            admins: { required: false, errorMessage: "Admins is Required !" },
            cleaners: { required: false, errorMessage: "Supervisors is Required !" },
            admins_to_unassign: { required: false, errorMessage: "Unassign Admins is Required !" },
            cleaners_to_unassign: { required: false, errorMessage: "Unassign Supervisor is Required !" },
            dueDate: { required: false, errorMessage: "Due Date is Required !" },
            should_unassign: { required: false, errorMessage: "" }
          }}
          keyName="customerLeadsAssignForm"
          formTitle={`Assign ${filteredSelectedRows?.length} Tickets to Admins/Supervisors`}
          bulkKeyName="ids"
          formSubmitApiUrl="assignToBulkTickets"
        /> : <></>
      }





      {/* {
        showNotifyPopUp ? <BulkWhatsappNotificationCustomerLeads
          showAssignPopUp={showNotifyPopUp}
          setShowAssignPopUp={setShowNotifyPopUp}
          filteredSelectedRows={filteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>

      } */}

      {
        showNotifyPopUp ? <AdminBulkForm
          show={showNotifyPopUp}
          setShow={setShowNotifyPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage=""
          formData={customerLeadsWhatsappNotificationFormData}
          initialData={{
            templateName: ''
          }}
          keyName="champLead"
          formTitle={`Send WhatsApp Notification to ${filteredSelectedRows?.length} Customers`}
          bulkKeyName="customerIds"
          formSubmitApiUrl="sendBulkWhatsAppTemplate"
        /> : <></>
      }
      {/* 
      {
        showResetLeadPopUp ? <BulkResetCustomerLeads
          showAssignPopUp={showResetLeadPopUp}
          setShowAssignPopUp={setShowResetLeadPopUp}
          filteredSelectedRows={filteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>

      } */}

      {
        showResetLeadPopUp ? <AdminBulkForm
          show={showResetLeadPopUp}
          setShow={setShowResetLeadPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage=""
          formData={customerLeadsResetFormData}
          initialData={{
            adminId: localStorage.getItem("user"),
            customerIds: [],
            adminIds: [],
            areaManagerIds: [],
          }}
          keyName="champLeadReset"
          formTitle={`Reset ${filteredSelectedRows?.length} Leads`}
          bulkKeyName="customerIds"
          formSubmitApiUrl="bulkResetCustomerLeads"
        /> : <></>
      }

      {/* {
        showAssignBatchPopUp ? <BulkBatchAssignCustomerLeads
          showAssignPopUp={showAssignBatchPopUp}
          setShowAssignPopUp={setShowAssignBatchPopUp}
          filteredSelectedRows={filteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          handleRefresh={handleRefresh}
        /> : <></>

      } */}

      {
        showAssignBatchPopUp ? <AdminBulkForm
          show={showAssignBatchPopUp}
          setShow={setShowAssignBatchPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={handleRefresh}
          toastMessage="message"
          formData={customerLeadsGetBatchesFormData}
          initialData={{
            ids: [],
            type: 'customer',
            batch_id: 0,
          }}
          keyName="champLeadBatch"
          formTitle={`Assign batch to ${filteredSelectedRows?.length} Customers`}
          bulkKeyName="ids"
          formSubmitApiUrl="assignBatch"
        /> : <></>
      }


      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={filteredSelectedRows?.map((row: any) => row && row?.id)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={handleRefresh}
          userType={"customer"}
        /> : <></>
      }
    </>
  )
}
export default VisitorsListMainV2
// just call this to route file
