import React, { memo } from 'react';
import { withSwal as DeleteVehicle } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { useDispatch } from 'react-redux';
export default DeleteVehicle((props: any) => {

    const dispatch = useDispatch()
    const { swal, confirm, cancel, payloads }: any = props;
    const { payloadData, render, successTitle, FailedTitle } = payloads
    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.vehicleDelete, payloadData, null)
            if (response?.data?.msg) {
                swal.fire(successTitle)

                dispatch({
                    type: "VEHICLE_DELTED", payload: {
                        vehicleid: 1,
                        vehicle_render: Math.random()
                    }
                })
            }
        }
        if (render) {
            swal.fire({
                title: 'Do you want to delete the vehicle?',
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});



{/* <DeleteVehicle
confirm='Yes'
cancel='No'
payloads={{
    render: VehicleDeleteShowModel,
    payloadData: {
        customerid: subscriptions?.customer_id,
        vehicleid: VehicleDeleteNumberid,
        userid: userid,

    },

    successTitle: 'Changes Are Saved Successfully ',
    FailedTitle: 'Changes Cancelled ',
}}
localKey={true}
></DeleteVehicle> */}