import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export const AdminToolTip = ({ anchorId, title, place }: any) => {
    return (
        <>
            <ReactTooltip
                anchorId={anchorId}
                place={place ? place : "top"}
                className="bg-dark"
                // content={title}
            >
                <div style={{ maxWidth: "200px", wordWrap: "break-word" }}>{title}</div>
            </ReactTooltip>
        </>
    )
}



