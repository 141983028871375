import axios from 'axios'
import {
  MAIN_ADMIN_BASE_API_URL,
  TEST_ADMIN_BASE_API_URL,
} from '../../../../apiGlobally'

export function GetAllCleanerAttendanceData(localkey: any, payloads: any) {
  return axios.post(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL
    }/admin/allChampsJobs`, payloads
  )
}

export function allChampsDectionsJobs(localkey: any, payloads: any) {
  return axios.post(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL
    }/admin/allChampsDectionsJobs`, payloads
  )
}

export function GetAllCleanerListData(localkey: any) {
  return axios.post(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getactivecleanerbycity`,
    { city: 6 }
  )
}
export function GetAllSuperVisorData(localkey: any) {
  return axios.get(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getSupervisorList`
  )
}
export function GetAllCleanerData(localkey: any) {
  return axios.get(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getCleanerList`
  )
}
export function GetAllCutomerData(localkey: any) {
  return axios.get(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getActiveCustomerlist`
  )
}
export function getAllJobStatusActionsV2(localkey: any) {
  return axios.get(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getAllJobStatusActionsV2`
  )
}
export function GetAllCleanerListandjobtype(localkey: any, AttendanceID: any) {
  return axios.post(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/cleanerattendencebyid`, { "attendenceid": AttendanceID }
  )
}
export function GetAllImage(localkey: any, AttendanceID: any) {
  return axios.post(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/showattendenceimages`, { "attendenceid": AttendanceID }
  )
}
export function PostRequestUpdateStatus(localkey: any, payloads: any) {
  return axios.post(
    `${localkey ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/updateAttendenceStatus`, payloads
  )
}
export function PostRequestRevertReportedStatus(payloads: any) {
  const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")

  return axios.post(
    `${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/revertUpdateAttendenceStatus`, payloads
  )
}
export function getAlJobTypes(localKeyCheck: string) {
  const result = axios.get(`${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/getAlJobTypes`).then((res) => {
    return res
  }).catch((err) => {
    return err
  })
  return result
}

export function changeAttendenceDate(payloads: any) {
  const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")

  return axios.post(
    `${localKeyCheck ? MAIN_ADMIN_BASE_API_URL : TEST_ADMIN_BASE_API_URL}/admin/changeAttendenceDate`, payloads
  )
}