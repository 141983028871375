import React, { memo, useState } from 'react'
import clsx from 'clsx';
import "./custom.css"
import Not_Found_Data from '../../Not_Found_Data';
import { image_Cloudfront } from '../../components/Utility';
const Gallary = ({ filterData }: any) => {

    return (
        <>
            <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
                <div
                    className={clsx('scroll-y me-n5 pe-5  max mb-4')}
                    data-kt-element='messages'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                    data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
                >
                    <div className='px-3 mt-4 margins' >
                        {
                            <>
                                <div className="row image_gallary " >
                                    {
                                        filterData?.length > 0 ? filterData?.map((ele: any, index: any) => {
                                            return <div className="image_gallary col-lg-4 col-md-12 mb-4 mb-lg-0" key={ele?.id + index}>
                                                {ele?.attendenceImage?.image && <img
                                                    // src={"https://carselona-img.s3.ap-south-1.amazonaws.com/" + ele?.attendenceImage?.image}
                                                    src={image_Cloudfront + ele?.attendenceImage?.image}
                                                    className="w-100 shadow-1-strong rounded mb-4"
                                                    alt="no Attendance Image"

                                                />}
                                            </div>

                                        })

                                            : <Not_Found_Data headerTitle={'No Images Found'} />
                                    }
                                </div>

                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default memo(Gallary)
