import {STORE_LAST_STATUS_LIST_DATA} from '../actionsType/cxActionTypes'

const initialState = {
  lastStatusListData: {},
  campaingStatusListData: {},
  currentRowDataFromList: {},
  campaingStatusName: {},
}

export const cxReducer = (state = initialState, {type, payload}: any) => {
  switch (type) {
    case STORE_LAST_STATUS_LIST_DATA:
      return {
        ...state,
        lastStatusListData: {...state.lastStatusListData, [payload?.keyName]: payload?.data},
      }
    case 'CURRENT_ROW_DATA_FOR_CUSTOEMR_DASHBOARD':
      return {
        ...state,
        currentRowDataFromList: {
          ...state.currentRowDataFromList,
          [payload?.keyName]: payload?.data,
        },
      }
    case 'CAMPAING_STATUS_LIST_DATA_WELOME_LIST_1':
      return {
        ...state,
        campaingStatusListData: {
          ...state.campaingStatusListData,
          [payload?.keyName]: payload?.data,
        },
      }

    case 'CAMPAIGN_STATUS_NAME':
      return {
        ...state,
        campaingStatusName: {
          ...state.campaingStatusName,
          [payload?.keyName]: payload?.data,
        },
      }
    default:
      return state
  }
}

// const faqListData = useSelector((store:any)=>store.cxReducer.faqListData)

// dispatch({
//   type: "CAMPAING_STATUS_LIST_DATA_WELOME_LIST_1", payload: {
//       keyName: catchCurrentOpenedUrl(),
//       data: result[0]?.value?.data?.data
//   }
// });

// dispatch({
//   type: 'CURRENT_ROW_DATA_FOR_CUSTOEMR_DASHBOARD',
//   payload: {
//     keyName: keyName,
//     data: currentRowDataFromList,
//   },
// })

// dispatch({
//   type: 'CAMPAIGN_STATUS_NAME',
//   payload: {
//     keyName: keyName,
//     data: currentRowDataFromList,
//   },
// })
