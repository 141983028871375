import DataTable, { SortFunction } from "react-data-table-component";
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from "../../../consts/components/Utility";
import { KTCardBody } from "../../../../_metronic/helpers";
import MainContainerWrapper from "../../../consts/mediaQueryLayout/MainContainerWrapper";
import LeftBox from "../../../consts/mediaQueryLayout/LeftBox";
import SearchBoxModal from "../../../consts/reuseModules/SearchBoxModal";
import RightBox from "../../../consts/mediaQueryLayout/RightBox";
import { ReactTableComponentStylesSheet } from "../../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet";
import DropDownFilterMenu from "../../../consts/BootrapDropdown/DropDownFilterMenu";
import { ShowHideColumnsLocalStorage } from "../../../consts/ShowHideColumnsLocalStorage";

interface AdminClientSidePaginationListsInteface {
  title: string;
  reorderColumnTitle: string | "";
  conditionalRowStyles: any;
  sortedColumns: any;
  filterData: any;
  pending: boolean;
  clearSelectedRows: boolean;
  totalRows: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (newPerPageSize: number, page: number) => void;
  onSelectedRowsChange: (selectedRows: any) => void;
  showFilter: boolean;
  keyName: string;
  search: string;
  setSearch: (event: string) => void;
  sortFunction?: SortFunction<unknown> | null | undefined;
  SubHeaderComponentRightSection: React.ComponentType<any>;
  SubHeaderComponentActionButtons: React.ComponentType<any>;
  ExpandedComponent: React.ComponentType<any>;
  expandableRows?: boolean;
  extraData?: any;
  clientSideFilterData: any;
  clientSideFilterComponent: any;
  pagination: any;
  classes?: any;
  showHideColumns?: any;
  setSortedColumns?: any;
  fixedHeaderScrollHeight?: any;
  ReactComponentStylesSheet?: any;
  selectableRows?: boolean;
}

type WrapperComponentProps = {
  props: any;
  rowData: any;
  extraData: any;
};

export const AdminClientSidePaginationLists: React.FC<AdminClientSidePaginationListsInteface> = ({
  title,
  reorderColumnTitle,
  conditionalRowStyles,
  sortedColumns,
  filterData,
  pending,
  clearSelectedRows,
  totalRows,
  onChangePage,
  onChangeRowsPerPage,
  onSelectedRowsChange,
  showFilter,
  keyName,
  search,
  setSearch,
  SubHeaderComponentRightSection,
  SubHeaderComponentActionButtons,
  sortFunction,
  clientSideFilterData,
  ExpandedComponent,
  expandableRows = true,
  extraData,
  clientSideFilterComponent,
  pagination,
  classes,
  showHideColumns,
  setSortedColumns,
  fixedHeaderScrollHeight = "auto",
  ReactComponentStylesSheet,
  selectableRows = true
}) => {

  const WrapperComponent: React.FC<WrapperComponentProps> = ({ props, rowData, extraData }) => {
    return <ExpandedComponent rowData={rowData} extraData={extraData} props={props} />;
  };

  return (
    <KTCardBody className="card py-4">
      <DataTable
        title={title}
        selectableRows={selectableRows}
        progressPending={pending}
        columns={reorderColumns(sortedColumns, reorderColumnTitle)}
        data={filterData}
        onColumnOrderChange={(cols) => storeReorderIndexes(cols, reorderColumnTitle)}
        customStyles={ReactComponentStylesSheet ? ReactComponentStylesSheet : ReactTableComponentStylesSheet}
        fixedHeader
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        selectableRowsHighlight
        highlightOnHover
        subHeader
        clearSelectedRows={clearSelectedRows}
        pagination={pagination}
        conditionalRowStyles={conditionalRowStyles}
        paginationTotalRows={totalRows}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSelectedRowsChange={onSelectedRowsChange}
        sortFunction={sortFunction && sortFunction}
        paginationComponentOptions={paginationRowsPerPageOptionsAll}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        key={keyName}
        className={classes}

        // expandableRows={expandableRows}
        // expandableRowsComponent={(props) => (
        //   <WrapperComponent
        //     props={props}
        //     rowData={props.data}
        //     extraData={extraData}
        //   />
        // )}
        subHeaderComponent={
          <>
            <MainContainerWrapper>
              <LeftBox>
                <SearchBoxModal search={search} setSearch={setSearch} />
              </LeftBox>
              <RightBox>
                <SubHeaderComponentRightSection />
                {showHideColumns?.length > 0 && (
                  <ShowHideColumnsLocalStorage
                    columns={showHideColumns}
                    sortedColumns={sortedColumns}
                    setSortedColumns={setSortedColumns}
                  />
                )}
                {showFilter ? (
                  <DropDownFilterMenu
                    ParentData={clientSideFilterData}
                    Component={clientSideFilterComponent}
                  />
                ) : (
                  <></>
                )}
              </RightBox>
            </MainContainerWrapper>
            <SubHeaderComponentActionButtons />
          </>
        }
      />
    </KTCardBody>
  );
};


// <AdminClientSidePaginationLists
//   title="xxxxxxxxxxxxxxxxxxxxxx"
//   reorderColumnTitle="xxxxxxxxxxxxxxxxxxxxxxReorderColumnItem"
//   sortedColumns={columns}
//   filterData={filterData}
//   pending={pending}
//   clearSelectedRows={false}
//   totalRows={filterData?.length}
//   conditionalRowStyles={{}}
//   onChangePage={() => { }}
//   onChangeRowsPerPage={() => { }}
//   onSelectedRowsChange={() => { }}
//   search={search}
//   setSearch={setSearch}
//   showFilter={true}
// ExpandedComponent={()=><></>}
// fixedHeaderScrollHeight?: any;
// ReactTableComponentStylesSheet?: any;
// pagination={true}
//   clientSideFilterData={{
//     handleChangeInputData: onChangeHandler,
//     handleActions: searchHandler,
//     payloads: payload,
//     supervisorListData: supervisorListData,
//   }}
//   clientSideFilterComponent={ActiveCleanersFilteration}
//   keyName={"xxxxxxxxxxxxxxxxxxxxxx"}
//   SubHeaderComponentRightSection={() => (<>
//       Other Action buttons
//   </>)}
//   SubHeaderComponentActionButtons={() => (<>
//     {
//       filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
//         <BulkUtilButton title="Bulk Assign  Champs" filteredSelectedRows={filteredSelectedRows} setShow={setOpenAssignModal} />
//       </BulkUtilButtonWrapper> : <></>
//     }

//   </>)}
// />




