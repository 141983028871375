import React, { memo, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { postRequest } from '../../Server/Requests'
import MultiSelectSearchDetailsDefault from '../../Select/MultiSelectSearchDetailsDefault'
import { useSelector } from 'react-redux'
const AssigningFormToUserForm = ({ handleClose,show, ticketId,invokedTicket ,champTicket }: any) => {
    const [loader, setLoader] = useState<any>(false)
    const adminData = useSelector((store: any) => store.champsReducer.statusData?.adminData)
    const supervisorData = useSelector((store: any) => store.champsReducer.statusData?.supervisorData)


    const [payload, setPayload] = React.useState<any>({
        ticketid: ticketId,
        admins: [],
        cleaners: [],
        last_date_resolution: '',
        last_date_feedback: '',
    })



    const handleChangeInputData = (event: any, name: string) => {
        if (event == null) {
            setPayload({
                ...payload,
                [name]: name === 'admins' ? [] : name === 'cleaners' ? [] : "",
            })
        }
        else {
            setPayload({
                ...payload,
                [name]: name === 'admins' ? event : name === 'cleaners' ? event : event.value,
            })
        }

    }




    const HandleAssigning = async () => {

        let payloads = {
            ticketid: ticketId,
            admins: payload?.admins,
            cleaners: payload?.cleaners,
            last_date_resolution: payload?.last_date_resolution,
            last_date_feedback: payload?.last_date_feedback,
        }

        setLoader(true)

        if (
            (payload?.admins?.length > 0 && ticketId) ||
            (payload?.cleaners?.length > 0 && ticketId)

        ) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.updateAssignedUsers, payloads, setLoader)
            if (response?.data?.status == 200) {
                toast.success( response?.data?.msg, { position: "top-center" })
                invokedTicket()
                handleClose()
                setLoader(false)
            } else {
                setLoader(false)

                toast.error( response?.data?.msg, { position: "top-center" })
            }
        } else {
            setLoader(false)
            toast.error('may be id is missing', { position: "top-center" })

            
        }
    }








    const TicketAssignData = champTicket
    const AdminsTicketAssigns = TicketAssignData?.ticket_assigns?.filter((ele: any) => ele.user_table === "ad_admin")
    const CleanerTicketAssigns = TicketAssignData?.ticket_assigns?.filter((ele: any) => ele.user_table === "cl_cleaner")

    const updateAdminsTicketAssigns = AdminsTicketAssigns?.map((ele: any, i: number) => {
        if (ele["name"]) {
            const newData = {
                label: ele["name"],
                value: ele["userid"]
            }
            return newData
        }
    })


    const updateCleanerTicketAssigns = CleanerTicketAssigns?.map((ele: any, i: number) => {
        if (ele["name"]) {
            const newData = {
                label: ele["name"],
                value: ele["userid"],
            }
            return newData
        }
    })


    useEffect(() => {

        const AdminsTicketAssigns = TicketAssignData?.ticket_assigns?.filter((ele: any) => ele.user_table === "ad_admin")
        const CleanerTicketAssigns = TicketAssignData?.ticket_assigns?.filter((ele: any) => ele.user_table === "cl_cleaner")


        const defaultAdminsId = AdminsTicketAssigns?.map((ele: any, i: number) => {
            return ele?.userid
        })

        const defaultCleanersId = CleanerTicketAssigns?.map((ele: any, i: number) => {
            return ele?.userid
        })
        setPayload({ ...payload, ['admins']: defaultAdminsId, ['cleaners']: defaultCleanersId })


    }, [TicketAssignData?.ticket_assigns])



    return (
        <>
            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Assign To User</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <MultiSelectSearchDetailsDefault
                            handleChangeInputData={handleChangeInputData}
                            HeaderTitle="Select admins"
                            SelectData={adminData}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name="admins"
                            defaultData={updateAdminsTicketAssigns}
                        ></MultiSelectSearchDetailsDefault>
                    </div>
                    <div className={`col-12  mb-1`}>
                        <MultiSelectSearchDetailsDefault
                            handleChangeInputData={handleChangeInputData}
                            HeaderTitle="Select supervisor"
                            SelectData={supervisorData}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name="cleaners"
                            defaultData={updateCleanerTicketAssigns}
                        ></MultiSelectSearchDetailsDefault>
                    </div>

                    <span className='fs-7 ms-4 mb-2'>Last Date for Resolution</span>
                    <div className="col-12  mb-4 px-2 ms-1">
                        <input type="date" onChange={(event) => handleChangeInputData(event.target, "last_date_resolution")} className='form-select form-select-solid me-2' />
                    </div>
                    <span className='fs-7 ms-4 mb-2'>Last Date for feedback</span>
                    <div className="col-12  mb-3 px-2 ms-1">
                        <input type="date" onChange={(event) => handleChangeInputData(event.target, "last_date_feedback")} className='form-select form-select-solid me-2' />
                    </div>
                    {/* <div className='d-flex  align-items-center  '>
                    </div> */}
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={ loader ? true : false} onClick={() => HandleAssigning()}>


                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Assinging...</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Assing User</h4>
                            }
                        </button>
                    </div>


                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default memo(AssigningFormToUserForm)
