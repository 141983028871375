import React, { useState, useRef } from 'react'
import "./BulkUtilButtonStyle.css"

export const BulkUtilButtonWrapper = ({ children }: any) => {
  return (
    <div className="scrollable-div">
      {children}
    </div>
  )
}

export const BulkUtilButton = ({ title, filteredSelectedRows, setShow, randomValue }: any) => {
  return (
    <button
      className='btn btn-sm btn-primary me-2'
      onClick={() => setShow ? setShow(randomValue ? Math.random() : true) : {}}
    >
      {addRowValue(title, filteredSelectedRows)}
    </button>
  )
}

const addRowValue = (title: any, filteredSelectedRows: any = []) => {
  if (filteredSelectedRows == "static") {
    return title
  }
  let titleArr = title.split(" ")
  titleArr?.splice(-1, 0, filteredSelectedRows?.length)
  let newTitle = titleArr.join(" ")
  return newTitle
}


{/* <MainContainerWrapper>

<LeftBox>

</LeftBox>


<RightBox>
 


</RightBox>

</MainContainerWrapper>

{
  filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
    <BulkUtilButton title="Bulk Assign Tickets Champ" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignPopUp} />
  </BulkUtilButtonWrapper> : <></>
} */}
