import DateFilter from '../filtersComponents/DateFilter'
import SelectFilter from '../filtersComponents/SelectFilter'
import TextFilter from '../filtersComponents/TextFilter'
import MultFilter from '../filtersComponents/MultFilter';

const KeywiseComponents = () => {
  const keys = {
    date: DateFilter,
    multiDate: DateFilter,
    select: SelectFilter,
    multi: MultFilter,
    text: TextFilter,
  }

  return keys
}

export { KeywiseComponents }
