export const CHAMPS_CHAT_MESSAGES = "CHAMPS_CHAT_MESSAGES"
export const CHAMPS_ALL_DATA = "CHAMPS_ALL_DATA"
export const CHAMPS_WHATSAPP_MESSAGES = "CHAMPS_WHATSAPP_MESSAGES"
export const CHAMPS_WHATSAPP_TEMPLATE_MESSAGES = "CHAMPS_WHATSAPP_TEMPLATE_MESSAGES"
export const CHAT_SOURCE = "CHAT_SOURCE"
export const LOADING = "LOADING"
export const SUCCESS = "SUCCESS"
export const FAILURE = "FAILURE"
export const CHAT_LOADER = "CHAT_LOADER"
export const CHAT_SUCCESS = "CHAT_SUCCESS"
export const CHAT_FAILURE = "CHAT_FAILURE"
export const WHATS_APP_LOADER = "WHATS_APP_LOADER"
export const WHATS_APP_SUCCESS = "WHATS_APP_SUCCESS"
export const WHATS_APP_FAILURE = "WHATS_APP_FAILURE"
export const STATUS_DATA = "STATUS_DATA"
export const TRAINING_TASK_LISTS = "TRAINING_TASK_LISTS"
export const CHAT_BTN_BG_COLOR = "CHAT_BTN_BG_COLOR"
export const STORE_ROW_DATA_FOR_CHAMP_DASHBOARD = "STORE_ROW_DATA_FOR_CHAMP_DASHBOARD"
export const SHOW_CHAMP_DASHBOARD_FROM_OUTSIDE = "SHOW_CHAMP_DASHBOARD_FROM_OUTSIDE"
