import React, { memo } from 'react';
import { withSwal as ChangeLocationOnMapCleaner } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
export default ChangeLocationOnMapCleaner((props: any) => {

    const { swal, confirm, cancel, payloads ,setUpdateLocationOpenModal,setNewLatLngLocation}: any = props;
    const { payloadData, render, successTitle, FailedTitle } = payloads
    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.addCleanerWorkLocation, payloadData, null)
            if (response?.data?.status == 200) {
                swal.fire(successTitle)
                setNewLatLngLocation({
                    lat: null,
                    lng: null,
                  })
            }
            else{
                swal.fire(FailedTitle)
                setNewLatLngLocation({
                    lat: null,
                    lng: null,
                  })
            }
        }
        if (render) {
            swal.fire({
                title: 'Do you want to change  the location ?',
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    setUpdateLocationOpenModal(null)
                    setNewLatLngLocation({
                        lat: null,
                        lng: null,
                      })
                    swal.fire(FailedTitle)
                }
            })
        }
    }, [render])
});
