const initialState = {
  noticationData: {},
}

export const adminNotification = (state = initialState, {type, payload}: any) => {
  switch (type) {
    case 'NOTIFICATION_DATA':
      return {
        ...state,
        noticationData: payload,
      }
    default:
      return state
  }
}

// const adminNotification = useSelector((store:any)=>store.adminNotification.noticationData)
