import React from 'react'
import IsSelectBox from '../DynamicFilteration/components/IsSelectBox'
const DateFilter = ({ onChangeHandler, payload, keyName = "", index, isData, filters, removeFilter, timestamp, multiVal}: any) => {
    return (
        <>
            <IsSelectBox onChangeHandler={onChangeHandler} payload={payload} index={index} keys={keyName} isData={isData} filters={filters} removeFilter={removeFilter} />

            <div className='row mb-1'>
                <div className='col-6'>
                    <input
                        type='date'
                        style={{ height: '38px' }}
                        value={payload?.filterData[keyName]?.from}
                        name='from'
                        onChange={(event: any) =>
                            onChangeHandler(event.target, keyName, 'from', false, timestamp, multiVal)
                        }
                        className='w-100 form-control me-2 align-start'
                    />
                </div>

                <div className='col-6'>
                    <input
                        type='date'
                        style={{ height: '38px' }}
                        name='to'
                        value={payload?.filterData[keyName]?.to}
                        onChange={(event: any) =>
                            onChangeHandler(event.target, keyName, 'to', false, timestamp, multiVal)
                        }
                        className='w-100 form-control me-2 align-start'
                    />
                </div>
            </div>
        </>
    )
}

export default DateFilter
