import React from 'react'
import Select from "react-select"
import { colourStyles2 } from '../../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet'

const MultiSelect = ({ setPayload, timeSlots, defaultData }: any) => {
    const [showData, setshowData] = React.useState<any>([])
    React.useEffect(() => {
        const updatedUser = timeSlots?.map((ele: any, i: number) => {
            if (ele.name) {
                const newUser = {
                    label: ele.name,
                    value: ele.id
                }
                return newUser
            }
        })
        setshowData(updatedUser)
    }, [])

    const onChangeHandler = (event: any) => {
        setPayload((payload: any) => ({ ...payload, ["timeslots"]: event.map((ele: any) => ele.value) }))
    }

    return (
            <Select
                isClearable
                isSearchable
                isMulti
                options={showData}
                onChange={onChangeHandler}
                defaultValue={defaultData ?? null}
                styles={colourStyles2}
            />
    )
}
export default MultiSelect
