import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { postRequest } from '../../../Server/Requests'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { useDispatch } from 'react-redux'
import { openChatByChamp } from '../../../../../Redux/champChatDashboardReducer/champsActions'
import MultiSelectSearchCategoryDefault from '../../../Select/MultiSelectSearchCategoryDefault'
import IsLoading from '../../../IsLoading/IsLoading'
// import MultiSelectSearchDetailsDefaultChampDashboard from '../../../Select/MultiSelectSearchDetailsDefaultChampDashboard'
const AddAreaName = ({ show, setCloseModal, row, AreaList, cleanerListData }: any) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState<boolean>(false)
    // const [defCleanerData, setDefCleanerData] = useState<any>([])
    const [defAreaListData, setdefAreaListData] = useState<any>([])
    const [areaLoader, setAreaLoader] = useState(false)
    // const [cleanerLoader, setCleanerLoader] = useState(false)
    const [payload, setPayload] = useState<any>({
        champId: [row?.id],
        areaId: [],
        status: 1,
    })

    useEffect(() => {
        setAreaLoader(true)
        let ids = row?.cleanerAreas?.map((row: any) => row && row?.area_id)?.filter((id: number) => (id !== undefined) && (id !== 0))
        row?.cleanerAreas?.length > 0 && setPayload({ ...payload, ["areaId"]: ids ? ids : [] })
        let defAreaListData = AreaList?.filter((ele: any) => ids?.includes(ele?.id));
        defAreaListData?.length > 0 && setdefAreaListData(defAreaListData)

        setTimeout(() => {
            setAreaLoader(false)
        }, 1000)

    }, [row?.cleanerAreas])

    // useEffect(() => {
    //     setCleanerLoader(true)
    //     let defCleanerData = cleanerListData?.filter((ele: any) => ele?.id == row?.id);
    //     defCleanerData?.length > 0 && setDefCleanerData(defCleanerData)
    //     setTimeout(() => {
    //         setCleanerLoader(false)
    //     }, 1000)
    // }, [row?.id])


    const handleSubmitAssign = async () => {

        if (payload?.areaId?.length > 0 && payload?.champId?.length > 0) {
            setLoader(true)

            const { data } = await postRequest(URL_EndPoints()?.addCleanerArea, payload, setLoader)
            if (data?.status == 200) {
                toast.success(data?.msg, { position: "top-center" })
                setCloseModal(false)
                dispatch(openChatByChamp({ champId: row?.id }, row?.id) as any)
            }
            else {
                toast.error(data?.msg, { position: "top-center" })
                setCloseModal(false)

            }
        }
        else {
            toast.error("Area / Cleaner  Ids are is missing", { position: "top-center" })

        }

    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event })
    }
    return (
        <>
            {show ? <Modal show={show} onHide={() => setCloseModal(false)} dialogClassName="modal-small"
                // aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Area Name </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        areaLoader ? <IsLoading /> : <div className='col-12 mb-2' key={11212} >
                            <MultiSelectSearchCategoryDefault
                                handleChangeInputData={onChangeHandler}
                                HeaderTitle="Select Area Name"
                                SelectData={AreaList}
                                DynamicKeys={["areaname"]}
                                id={"id"}
                                name="areaId"
                                defaultData={defAreaListData?.length > 0 ? defAreaListData?.map((ele: any) => ({ label: ele?.areaname, value: ele?.id })) : []}
                            ></MultiSelectSearchCategoryDefault>

                        </div>
                    }
                    {/* {
                        cleanerLoader ? <IsLoading /> : <div className='col-12 mb-4' key={112122}>
                            <MultiSelectSearchDetailsDefaultChampDashboard
                                handleChangeInputData={onChangeHandler}
                                HeaderTitle="Select Cleaner"
                                SelectData={cleanerListData}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name="champId"
                                defaultData={defCleanerData ?
                                    defCleanerData?.map((ele: any) => ({ label: (ele?.first_name ?? "NA" + " " + ele?.last_name ?? "NA" + ele?.phone ?? "NA"), value: ele?.id }))
                                    : []}
                            ></MultiSelectSearchDetailsDefaultChampDashboard>

                        </div>
                    } */}

                    <button disabled={loader} className={`btn btn-sm btn-${loader ? "warning" : "primary"} fs-6 col-12 w-100 `} onClick={handleSubmitAssign}>
                        {loader ?
                            <>

                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                <span>Adding...</span>

                            </>

                            : " Add Area Name"}
                    </button>

                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default AddAreaName
