import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { postRequest } from '../../../../Server/Requests';
import { URL_EndPoints } from '../../../../Server/URL_EndPoints';
import LoaderBars from '../../../../IsLoading/LoaderBars';

interface OTPDialogProps {
    customerID: (id: any) => void;
    setShowDialog: (show: boolean) => void;
    onOtpGenerated: (otp: string) => void;
}

const OTPDialog: React.FC<OTPDialogProps> = ({ customerID, setShowDialog, onOtpGenerated }) => {
    const [secretCode, setSecretCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loader, setLoader] = useState(false);

    const handleOtpGeneration = async () => {
        try {
            setLoader(true)
            const response: any = await postRequest(URL_EndPoints(null)?.matchOtp, { customerID, secretCode },
                setLoader
        );

            if (response.data.status === 200) {
                const generatedOtp = response.data.data;
                onOtpGenerated(generatedOtp);
                setLoader(false);
                setShowDialog(false);
            } else {
                setErrorMessage(response.data.message);
                setLoader(false);
            }
        } catch (error) {
            setErrorMessage(`An error occurred. Please try again. ${error}`);
        }
    };

    return (
        <Modal show onHide={() => setShowDialog(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Secret Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label>Enter Secret Code</label>
                <input
                    type="text"
                    value={secretCode}
                    onChange={(e) => setSecretCode(e.target.value)}
                    className="form-control my-2"
                    placeholder="Enter code here"
                />
                {errorMessage && <div className="text-danger">{errorMessage}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDialog(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleOtpGeneration} disabled={loader}>
                {loader ? <LoaderBars /> : 'Submit'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OTPDialog;
