import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { postRequest } from '../Server/Requests'
import { URL_EndPoints } from '../Server/URL_EndPoints'
import { toast } from 'react-toastify'

const WhatsppMessageSenderIndividual = ({ showNotifyPopUp, handleNotifyPopUpClose, row }: any) => {
    const [isBulkNotifyLoading, setisBulkNotifyLoading] = useState(false)
    const [whatsappTemplates, setWhatsappTemplates] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        templateName: ""
    })

    async function loadData() {
        const whatsappTemplates: any = await postRequest(URL_EndPoints(null)?.get_WhatsAppTemplate, {
            wa_number_id: 2
        }, null);
        setWhatsappTemplates(whatsappTemplates?.data?.data);
    }
    React.useEffect(() => {
        loadData()
    }, [])


    const handleBulkWhatsappNotifySubmit = async (e: any) => {
        e.preventDefault();
        let champIds = row?.id;
        payload['champIds'] = [champIds] || [];
        setisBulkNotifyLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.sendBulkWhatsAppTemplateToChamp, payload, setisBulkNotifyLoading)
        if (response?.data?.status == 200) {
            toast.success(response?.data?.msg, { position: "top-center" })
            handleNotifyPopUpClose()
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
            handleNotifyPopUpClose(false)
        }
        setisBulkNotifyLoading(false)

    }



    return (
        <div>
            {/* size='sm'  */}
            <Modal dialogClassName="modal-small" show={showNotifyPopUp} onHide={handleNotifyPopUpClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Send WhatsApp Notification to {1} Customers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleBulkWhatsappNotifySubmit}>
                        <Form.Group className="mb-3" >
                            <Form.Select aria-label="Default select example" value={payload?.templateName} required name='templateName' onChange={(event: any) => setPayload({
                                ...payload, ["templateName"]: event?.target?.value
                            })}>
                                <option value="">Select Template</option>
                                {whatsappTemplates?.map((template: any) => <option value={template.name}>{template.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 p-2" >
                            {
                                isBulkNotifyLoading ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary">
                                     Send Now
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default WhatsppMessageSenderIndividual




// {show ? <WhatsppMessageSenderIndividual
//     showNotifyPopUp={show}
//     handleNotifyPopUpClose={handleNotifyPopUpClose}
//     row={row}
// /> : ''}