import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { getRequest, postRequest } from "../../../../consts/Server/Requests";
import { URL_EndPoints } from "../../../../consts/Server/URL_EndPoints";
import SingleSelectSearchCategoryDefault from "../../../../consts/Select/SingleSelectSearchCategoryDefault";
import { toast } from "react-toastify";
import moment from "moment";

export const WrongReportingModal = ({ showWrongReportingForm, setShowWrongReportingForm, row }: any) => {
    const [deductionReasons, setDeductionReasons] = useState<any>([])
    const [deductions, setDeduction] = useState<any>([])
    const [buttonLoading, setButtonLoading] = useState<any>(false)
    const [payload, setPayload] = useState<any>({
        deduction_reason_id: 0,
        cleanerid: row?.cleanerid,
        jobId: row?.id,
        type: "",
        amount: 0,
        serviceDate: moment().format('YYYY-MM-DD'),
    })

    useEffect(() => {
        setPayload({
            deduction_reason_id: 0,
            cleanerid: row?.cleanerid,
            jobId: row?.id,
            type: "",
            amount: 0,
            serviceDate: moment().format('YYYY-MM-DD'),
        })
    }, [showWrongReportingForm])

    async function getDecutions() {
        const response = await getRequest(URL_EndPoints()?.getDeductionReasonsList + '?cleanerid=' + row?.cleanerid + '&jobId=' + row?.id, null)
        setDeductionReasons(response?.data?.data)
        setDeduction(response?.data?.deductions)
    }

    useEffect(() => {
        getDecutions()
    }, [])

    const handleChangeInputData = (event: any, name: any) => {
        if (!event) {
            setPayload({
                ...payload,
                [name]: event?.value,
            })
        }
        if (name == "deduction_reason_id") {
            const defaultAmount = deductionReasons?.find((reason: any) => reason?.id == event?.value)
            setPayload({
                ...payload,
                [name]: event?.value, ["amount"]: defaultAmount?.default_amount
            })
        }
    }

    const handleSubmit = async () => {
        setButtonLoading(true)
        if (payload?.type !== "" && payload?.amount > 0 && payload?.deduction_reason_id > 0) {
            const result = await postRequest(URL_EndPoints()?.addFineOnWrongReporting, payload, setButtonLoading)
            if (result?.status === 200) {
                getDecutions()
                setPayload({
                    deduction_reason_id: 0,
                    cleanerid: row?.cleanerid,
                    jobId: row?.id,
                    type: "",
                    amount: 0,
                    serviceDate: moment().format('YYYY-MM-DD'),
                })
                toast.success("Fine added successfully.")
            }
        }
        setButtonLoading(false)
    }

    const handleDelete = async (deduction: any) => {
        const userConfirmation = window.confirm('Do you want to remove this fine?');
        if (userConfirmation) {
            const result = await postRequest(URL_EndPoints()?.removeDeductedWrongReporting, { jobId: row?.id, pointCollectionId: deduction?.id }, null)
            if (result?.status === 200) {
                toast.success("Fine removed successfully.")
                getDecutions()
            }
        }
    }

    return <Modal size="lg" show={showWrongReportingForm} onHide={() => setShowWrongReportingForm(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Add Fine For Wrong Reporting: {row?.cleaner?.first_name} {row?.cleaner?.last_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="form-group">
                    <label htmlFor="id">Select Reason</label>
                    <SingleSelectSearchCategoryDefault
                        handleChangeInputData={handleChangeInputData}
                        HeaderTitle="Select Reason"
                        DynamicKey={"name"}
                        id={"id"}
                        name="deduction_reason_id"
                        SelectData={deductionReasons}
                        defaultData={null}
                    ></SingleSelectSearchCategoryDefault>
                </div>

                <div className="form-group">
                    <label htmlFor="type">Type</label>
                    <select name="type" id="type" className="form-select" onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })} required>
                        <option value="" disabled selected>Select</option>
                        <option value="point">Point</option>
                        <option value="cash">Cash</option>
                    </select>
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="amount">Amount</label>
                    <input type="text" className="form-control" name="amount" value={payload?.amount} onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })} required />
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="fine_date">Service Date </label>
                    <input
                        name='serviceDate'
                        type='date'
                        value={payload.serviceDate}
                        className='form-control  mb-3 mb-lg-0'
                        autoComplete='off'
                        onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                    />
                </div>

                <div className="form-group mt-3">
                    <button  className="btn btn-primary" disabled={buttonLoading} onClick={handleSubmit}>
                        {
                            buttonLoading ? <Spinner animation="border" /> : "Add"
                        }
                    </button>
                </div>
            <hr />
            <h3>All Fines For Current Job</h3>
            <div className="container">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Sl No</th>
                            <th scope="col">Reason</th>
                            <th scope="col">For</th>
                            <th scope="col">By</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Deduction Date</th>
                            <th scope="col">Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            deductions?.map((ele: any, index: any) => {
                                return <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{ele?.deduction_reason?.name}</td>
                                    <td>{ele?.ref_id}</td>
                                    <td>{ele?.by_user?.first_name} {ele?.by_user?.last_name} {ele?.by_usertype > 0 && (ele?.by_usertype == 5 ? "(Admin)" : (ele?.by_usertype == 2 ? "(Supervisor)" : "-"))}</td>
                                    <td>{ele?.deduction_reason?.name}</td>
                                    <td>{ele?.deduction_date}</td>
                                    <td>{ele?.method}</td>
                                    <td>{ele?.job_Points}</td>
                                    <td><i onClick={() => handleDelete(ele)} className="fa fa-trash" style={{ color: 'red', cursor: "pointer" }} aria-hidden="true"></i></td>

                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </Modal.Body>
    </Modal>;
}
