export const createMasterNotesFormData = [
    {
        HeaderTitle: 'Note Here...',
        filterApi: '',
        label: {
            show: false,
            name: 'LabelNameHere',
        },
        valueType: 'textArea',
        inputType: '',
        displayFields: [],
        valueField: '',
        name: 'note',
        from: '',
        to: '',
        isRequired: true,
        errMessage: 'Note is required !',
        searchable: false,
        wa_number_id: null,
        userType: null,
    },
    {
        HeaderTitle: 'Select Category',
        filterApi: 'getNotesCategories',
        label: {
            show: false,
            name: 'LabelNameHere',
        },
        valueType: 'select',
        inputType: '',
        displayFields: ["name"],
        valueField: 'id',
        name: 'category_id',
        from: '',
        to: '',
        isRequired: true,
        errMessage: 'Cateogry is required !',
        searchable: false,
        wa_number_id: null,
        userType: null,
    },
]