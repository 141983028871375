import React, { useState } from "react";
import Select, { SingleValue } from "react-select";

interface FilterOption {
  value: string;
  label: string;
}

const jobSiteOptions: FilterOption[] = [
  { value: "individual", label: "Individual" },
  { value: "society", label: "Society" },
  { value: "super-society-1", label: "Super Society 1" },
  { value: "super-society-2", label: "Super Society 2" },
];

const subscriptionOptions: FilterOption[] = [
  { value: "car-subscription", label: "Car Subscription" },
  { value: "bike-subscription", label: "Bike Subscription" },
  { value: "scooter-subscription", label: "Scooter Subscription" },
  { value: "on-demand", label: "On Demand" },
];

const SubscriptionPlans: React.FC = () => {
  const [jobSite, setJobSite] = useState<SingleValue<FilterOption>>(
    jobSiteOptions.find(option => option.value === "individual") || null
  );
  const [subscriptionType, setSubscriptionType] = useState<SingleValue<FilterOption>>(
    subscriptionOptions.find(option => option.value === "car-subscription") || null
  );

  const getImagePath = async (): Promise<string | null> => {
    if (!jobSite || !subscriptionType) return null;

    const baseFileName = `${subscriptionType.value}-${jobSite.value}`;
    const extensions = ['jpg', 'png', 'jpeg', 'webp'];
    
    for (const ext of extensions) {
      const path = `/media/customer-subscription-plans/${baseFileName}.${ext}`;
      try {
        // Create a new image object to test if the file exists
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = path;
        });
        return path; // If image loads successfully, return the path
      } catch {
        continue; // Try next extension if current one fails
      }
    }
    
    return null;
  };

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  React.useEffect(() => {
    getImagePath().then(path => setSelectedImage(path));
  }, [jobSite, subscriptionType]);

    return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h2>Choose Filters</h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginBottom: "20px" }}>
        <div style={{ width: "200px" }}>
          <Select
            options={jobSiteOptions}
            value={jobSite}
            onChange={setJobSite}
            placeholder="Select Job Site"
          />
        </div>

        <div style={{ width: "200px" }}>
          <Select
            options={subscriptionOptions}
            value={subscriptionType}
            onChange={setSubscriptionType}
            placeholder="Select Subscription Type"
          />
        </div>
      </div>

      {selectedImage ? (
        <div>
          <h3>Plan Chart</h3>
          <img
            src={selectedImage}
            alt="No Plan Available"
            style={{ width: "80%", height: "auto", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
          />
        </div>
      ) : (
        <p>No plan chart available for the selected options</p>
      )}
    </div>
  );
};

export default SubscriptionPlans;
