import React from 'react';
import Select from 'react-select';
import { colourStyles2 } from '../../reuseModules/ReactTableComponent/FormStyleCssSheet';

const ReactSelectSearch = ({ onChangeHandler, HeaderTitle, SelectData, title, name }: any) => {

  const [showData, setShowData] = React.useState<any>([])

  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, index: number) => {
      if (ele[title]) {
        const newData = {
          label: ele[title],
          value: ele?.key,
          index: index,
          item: ele,
        }
        return newData
      }
    })
    setShowData(updatedData)
  }, [SelectData])


  const HandleSearch = (event: any, currentItemRemovedOrAdded: any) => {
    onChangeHandler(event, currentItemRemovedOrAdded)
  }

  return (
    <div className='form-select-sm ' >
      <Select
        isClearable
        isSearchable
        // isMulti
        placeholder={HeaderTitle}
        options={showData}
        onChange={HandleSearch}
        defaultValue={showData}
        styles={colourStyles2}

      />
    </div>
  )
}
export default ReactSelectSearch
