import React from 'react'

const SearchBoxModal = ({ search, setSearch}: { search: string, setSearch: (e: string) => void }) => {
    return (
        <input
            type="text"
            placeholder='Search Here'
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
            className='w-100 form-control align-start w-25'
        />
    )
}

export default SearchBoxModal
