import React, { useState } from 'react'

const AdminTaskListStatusFileUpload = ({ samplefile, setSamplefileFile, handleSampleUploadFile }: any) => {
    const [fileSelectedSample, setFileSelectedSample] = useState<boolean>(false);
    
    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileSelectedSample(true);
        setSamplefileFile(event?.target?.files?.[0]);

    };

    const sampleCancelImage = () => {
        setSamplefileFile(null);
        setFileSelectedSample(false);
    };

    return (
        <>
            <input type="file" key={2} name="Sampleimage" id="Sampleimage" onChange={onChangeFile} style={{ display: 'none' }} />
            <label htmlFor="Sampleimage" style={{ width: "100%", height: "90%" }}>
                <span className='fw-bold fs-5 text-muted'> <i className="fa-solid fa-upload fs-3 text-primary mt-2 cursor-pointer me-2"></i> Click to upload file...</span>
            </label>


            {fileSelectedSample && (
                <div className="d-flex justify-content-between mt-2 mb-4" key={2}>
                    <div className="p-1 me-4">
                        <span className='text-primary fw-bold'> File Name :</span>  {samplefile?.name} ({(samplefile?.size / (1024 * 1024)).toFixed(2)}MB)
                    </div>
                    <div className="p-1" onClick={sampleCancelImage}>
                        <i className="fa fa-times fs-3 tex-danger" aria-hidden="true"></i>
                    </div>
                </div>
            )}
        </>
    )
}

export default AdminTaskListStatusFileUpload