import { useDispatch } from 'react-redux'
import SentenceShorter from '../../../consts/SentenceShorter'
import ToolTips from '../../../consts/Tooltip/ToolTips'
import uuid from 'react-uuid';

const JobDetailsModal = (props: any) => {
  const dispatch = useDispatch()
  const { filteredData, data, jobDetailsTimeSlot, refrencsss, jobsiteid, handleCloseModal } = props
  const handleChangeDate = (drawerRefs: any) => {
    handleCloseModal()

    dispatch({ type: "LISTDRAWER", payload: drawerRefs })
    dispatch({ type: "JOBDETAILS", payload: jobsiteid })
  }
  return (
    <div className='p-5 card' style={{ width: '500px' }}                                                                                                                                                                                                                                        >
      <div className='modal-content'>
        <div className='modal-header mb-5'>
          <h5 className='modal-title mb-2' id='exampleModalCenteredScrollableTitle'>
            Job Details
          </h5>
          <button
            type='button'
            className='btn-close mb-2'
            data-bs-dismiss='modal'
            aria-label='Close'
            onClick={handleCloseModal}
          ></button>
        </div>
        <div className="row mb-5">
          <div className="col-5">
            <h5>Job Type</h5>
            <p>{filteredData?.job_type || 'No Job Type'}</p>
          </div>
          <div className="col-5">
            <h5>Job Site</h5>
            <p>{filteredData?.customer_data?.societyid == 0 ? "Individual" : filteredData?.customer_data?.society_details?.name}</p>
          </div>
          <div className="col-5">
            <h5>Customer Name</h5>
            <p>{filteredData?.customer_data?.first_name || 'No '} {filteredData?.customer_data?.last_name || 'Name'}</p>
          </div>
          <div className="col-5">
            <h5>Vehicle Number</h5>
            <p>{filteredData?.vehicle_details?.vehicleno || 'No Vehicle Number'}</p>
          </div>

        </div>
        <div className='d-flex flex-wrap' style={{ margin: "auto" }}>
          {
           filteredData?.customer_data?.private_tag?.split(",")?.length > 0 ?
             filteredData?.customer_data?.private_tag?.split(",")?.map((ele: any, index: any) => {
                let sentence = ele
                let id = uuid()

                let color;
                if (ele?.trim().toLowerCase() === "unhappy") {
                  color = "danger"
                }
                else if (ele?.trim() && ele?.trim()?.split("_")[0] === "unhappy") {
                  color = "danger"
                }
                else if (ele?.trim().toLowerCase() === "missed service") {
                  color = "danger"
                }
                else if (ele?.trim().toLowerCase() === "new") {
                  color = "success"
                }
                return <>
                  <span className={` border bg-white p-2  rounded text-${color} mb-1 me-1 cursor-pointer`}><SentenceShorter index={id} sentence={sentence} /></span>
                  <ToolTips  toolTipId={id} ToolTipShowData={ele} place={""}  />
                    
            

                </>
              })
              : <span className='border bg-white p-1 rounded text-danger mb-1 me-1'>{"No Tag Available"}</span>
          }
        </div>
      </div>
    </div>
  )
}
export default JobDetailsModal



