import React, { useState, useEffect, useReducer, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import useOwnMoinState from './useOwnMoinState';
import { getCurrentFilterHandler, initialPayload, operators, removeFilterHandler } from '../../../consts/DynamicFilteration/components/CommonData';
import { champRequirementTypesStates, initial_champRequirementTypes_states } from '../../admins/ad_states/champRequirementTypes';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { getPostMultipleApiHandler, getRequest, postRequest } from '../../../consts/Server/Requests';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../../consts/components/Utility';
import { DynamicFilter, FilterHandler } from '../../../consts/DynamicFilteration/FilterHandler';
import { KeywiseComponents } from '../../../consts/DynamicFilteration/KeywiseComponents';
import AddChamprequirementType from './AddChamprequirementType';
import { useDispatch, useSelector } from 'react-redux';
import { requirementApiUrl } from '../../../consts/Server/data';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAdminListApiUrl, useAdminSmartFilterList } from '../../admins/adminUtils';


const ChampRequirementType2 = () => {
  const champ_rquirement_type = useSelector((store: any) => store.RefreshPage.champ_rquirement_type)
  const dispatch = useDispatch()


  const [openRequirementModal, setOpenRequirementModal] = React.useState(false)
  const [requirementType, setrequirementType] = useState<any>([])
  const [priorityData, setPriorityData] = useState<any>([])
  const [locationData, setlocationData] = useState<any>([])
  const [supervisorListData, setSupervisorsListData] = useState<any>([])
  const [areaListData, setAreaListData] = useState<any>([])


  async function getFetchInitialData() {

    const results = await getPostMultipleApiHandler(requirementApiUrl, null)

    setrequirementType(results["getRequirementTypes"] || [])
    dispatch({ type: "GET_REQUIREMENT_TYPE", payload: results["getRequirementTypes"] || [] })
    dispatch({ type: "TYPE_AREA", payload: results["getRequirementTypes"] || [] })

    setlocationData(results["getAllTrainingLocations"] || [])
    dispatch({ type: "UPDATE_TRAINING_LOCATION", payload: results["getAllTrainingLocations"] || [] })

    setAreaListData(results["getarea"] || [])
    dispatch({ type: "AREA_LIST_DATA", payload: results["getarea"] || [] })

    setSupervisorsListData(results["getSupervisorList"] || [])
    dispatch({ type: "SUPERVISOR_LIST_DATA", payload: results["getSupervisorList"] || [] })

    setPriorityData(results["getPriorityOptions"] || [])
    dispatch({ type: "UPDATE_PRIORITY", payload: results["getPriorityOptions"] || [] })


  }

  useEffect(() => {
    getFetchInitialData()
  }, [])

  const [storeFilterList, setStoreFilterList] = useOwnMoinState({})
  const [filterkey, setFilterKey] = useOwnMoinState({})
  const [selectedFilterData, setSelectedFilterData] = useOwnMoinState([])
  const [payload, setPayload] = useOwnMoinState(initialPayload(false, {}))

  const [champRequirementTypesDataStates, setLocalStateDispatch] = useReducer(champRequirementTypesStates, initial_champRequirementTypes_states);

  // api calling here logic  here +++++++++++++++++++++++++++++++

  async function InvokedRendered(payloads: any, setloading: any) {
    setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
    const { data } = await postRequest(URL_EndPoints(null)?.getChampRequirement, payload, null)
    setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: data?.data })
    setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: data?.recordsTotal })
    setLocalStateDispatch({ type: "RTL_FILTER", payload: data?.filter?.ChampRequirementList })
    setLocalStateDispatch({ type: "RTL_PENDING", payload: false })
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {

    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload

  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]:  event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: 10 })
    await InvokedRendered(handlePagination(champRequirementTypesDataStates?.search, champRequirementTypesDataStates?.sortby, champRequirementTypesDataStates?.direction, champRequirementTypesDataStates?.page, champRequirementTypesDataStates?.perPageSize), null)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }


  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  // handle pagination here 

  const handleSort = async (column: any, sortDirection: any) => {
    setLocalStateDispatch({ type: "RTL_SORT_BY", payload: column.sortField })
    setLocalStateDispatch({ type: "RTL_DIRECTION", payload: sortDirection })
    InvokedRendered(handlePagination(champRequirementTypesDataStates?.search, column.sortField, sortDirection, champRequirementTypesDataStates?.page, champRequirementTypesDataStates?.perPageSize), null)
  };

  const handlePageChange = (page: any) => {
    setLocalStateDispatch({ type: "RTL_PAGE", payload: page })
    InvokedRendered(handlePagination(champRequirementTypesDataStates?.search, champRequirementTypesDataStates?.sortby, champRequirementTypesDataStates?.direction, page, champRequirementTypesDataStates?.perPageSize), null)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setLocalStateDispatch({ type: "RTL_PER_PAGE_SIZE", payload: newPerPageSize })
  };


  // deboucing  

  useEffect(() => {

    let timer = setTimeout(async function () {
      champRequirementTypesDataStates?.search && setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
      champRequirementTypesDataStates?.search && InvokedRendered(handlePagination(champRequirementTypesDataStates?.search && champRequirementTypesDataStates?.search, champRequirementTypesDataStates?.sortby, champRequirementTypesDataStates?.direction, champRequirementTypesDataStates?.page, champRequirementTypesDataStates?.perPageSize), null)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [champRequirementTypesDataStates?.search, champ_rquirement_type])

  useEffect(() => {
    !champRequirementTypesDataStates?.search && InvokedRendered(handlePagination(champRequirementTypesDataStates?.search, champRequirementTypesDataStates?.sortby, champRequirementTypesDataStates?.direction, champRequirementTypesDataStates?.page, champRequirementTypesDataStates?.perPageSize), null)
  }, [champRequirementTypesDataStates?.search, champ_rquirement_type])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(champRequirementTypesDataStates?.search, champRequirementTypesDataStates?.sortby, champRequirementTypesDataStates?.direction, 1, champRequirementTypesDataStates?.perPageSize), null);
  }, [selectedFilterData?.length]);




  // api calling here logic  here +++++++++++++++++++++++++++++++


  useEffect(() => {
    champRequirementTypesDataStates?.fetchCallToggler && setLocalStateDispatch({ type: "RTL_TEMP_FILTERED_DATA", payload: champRequirementTypesDataStates?.filterData })
  }, [champRequirementTypesDataStates?.fetchCallToggler])


  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setLocalStateDispatch({ type: "RTL_PENDING", payload: true });
      const clone = champRequirementTypesDataStates?.filterDataTemp?.slice();
      const customerIds = clone.map((e: any) => e.customer_id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, null);

      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e?.vehicleCustomer?.phone || ele[0]?.To === e?.vehicleCustomer?.phone) || [];
        clone[i].callLogs = foundLog;
      }

      setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: clone })
      setLocalStateDispatch({ type: "RTL_PENDING", payload: false });

    }
    champRequirementTypesDataStates?.filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [champRequirementTypesDataStates?.filterDataTemp])

  // fetch call regarding logic  


  const [filteredSelectedRows, SetfilteredSelectedRows] = useState<any>([])
  const [showShow, setShowShow] = useState<any>(false)
  const [showDeletModal, setShowDeletModal] = useState<any>(false)

  const handleSelectedRowsChange = (selectedRows: any) => {
    SetfilteredSelectedRows(selectedRows?.selectedRows)
  };


  const closeBtn = () => {
    setOpenRequirementModal(false)
  }
  const [sortedColumns, setSortedColumns] = useOwnMoinState(columns());


  const bulkApproveHanlder = async () => {
    let rowIds = filteredSelectedRows?.map((row: any) => row?.id)?.filter((id: number) => (id !== undefined) && (id !== 0));

    if (rowIds?.length > 0) {
      setShowShow(true)

      const response: any = await postRequest(URL_EndPoints(null)?.approveChampRequirementInBulk, {
        "ids": rowIds
      }, setShowShow)

      if (response?.data?.status === 200) {
        toast.success(response?.data?.msg, { position: "top-center" })
        setShowShow(false)
      }
      else {
        toast.error(response?.data?.msg, { position: "top-center" })
        setShowShow(false)
      }
    }
    else {
      toast.success("Some field are missing in form !", { position: "top-center" })

    }
  }

  const BulkDeleteChampHandler = async () => {
    let rowIds = filteredSelectedRows?.map((row: any) => row?.id)?.filter((id: number) => (id !== undefined) && (id !== 0));

    if (rowIds?.length > 0) {
      setShowDeletModal(true)

      const response: any = await postRequest(URL_EndPoints(null)?.bulkDeleteChampRequirements, {
        "requirement_ids": rowIds,
        "is_deleted": 1,
      }, setShowDeletModal)

      if (response?.data?.status === 200) {
        toast.success(response?.data?.msg, { position: "top-center" })
        setShowDeletModal(false)
      }
      else {
        toast.error(response?.data?.msg, { position: "top-center" })
        setShowDeletModal(false)
      }
    }
    else {
      toast.success("Some field are missing in form !", { position: "top-center" })

    }
  }


  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getChampRequirement")
  }, [])
  return (
    <>
      <div>

        <DataTable
          title="Champ Requirement Type"
          progressPending={champRequirementTypesDataStates?.pending}
          columns={reorderColumns(sortedColumns, "RequirementListTaskReorderedItem")} 
          data={champRequirementTypesDataStates?.filterData}  
          onColumnOrderChange={cols => storeReorderIndexes(cols, "RequirementListTaskReorderedItem")}
          selectableRows 
          fixedHeader   
          fixedHeaderScrollHeight='auto'   
          selectableRowsHighlight 
          highlightOnHover  
          subHeader 
          // server 
          pagination  
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={champRequirementTypesDataStates?.totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          // server 
          subHeaderComponent={
            <>
              <div className='w-100 d-flex justify-content-between mb-3 '>
                <div className='d-flex align-items-start justify-content-start '>

                  <input
                    type="text"
                    placeholder='Search Here'
                    value={champRequirementTypesDataStates?.search}
                    onChange={(e: any) => setLocalStateDispatch({ type: "RTL_SEARCH", payload: e.target.value })}
                    className='w-100 form-control align-start'
                  />
                </div>

                <div className='d-flex align-items-center justify-content-end '>

                  <div className='d-flex align-items-center justify-content-end '>
                    <button className='btn btn-sm btn-primary' onClick={() => setOpenRequirementModal(!openRequirementModal)}> Add champ requirement</button>
                  </div>


                  <div className='d-flex align-items-center justify-content-center my-2'>
                    <FilterHandler
                      onChangeHandler={onChangeHandler} 
                      actionHandler={actionHandler}  
                      getCurrentFilter={getCurrentFilter}
                      removeFilter={removeFilter}
                      payload={payload}
                      Component={DynamicFilter}
                      filterData={champRequirementTypesDataStates?.filter}
                      OperatorsData={operators}
                      selectedFilterData={selectedFilterData}
                      filterComponents={KeywiseComponents()}
                      storeFilterList={storeFilterList}
                      filterkey={filterkey}
                      listApiUrl={listApiUrl("getChampRequirement")}

                    />
                    {/* <ToggleColumns columns={[{ name: "RENEWAL DATE", id: "RENEWAL DATE" }]} from="RENEWAL_TASK_LIST" /> */}

                  </div>


                </div>
              </div>

              <div className="w-100  d-flex">
                <div className='fiteredSelectedRows w-80 me-2'>
                  {
                    filteredSelectedRows.length > 0 ?
                      <div className=''>
                        <Button className='btn-sm' onClick={() => bulkApproveHanlder()}>
                          {
                            showShow ? "Approving..." : `Bulk Approve ${filteredSelectedRows.length} Champs`
                          }
                        </Button>
                      </div>
                      : <div></div>
                  }
                </div>

                <div className='fiteredSelectedRows w-80 me-2'>
                  {
                    filteredSelectedRows.length > 0 ?
                      <div className=''>
                        <Button className='btn-sm' onClick={() => BulkDeleteChampHandler()}>
                          {
                            showDeletModal ? "Deleting..." : `Bulk Delete Champ Requirements ${filteredSelectedRows.length} `
                          }
                        </Button>
                      </div>
                      : <div></div>
                  }
                </div>
              </div>

            </>
          }
        />


      </div>





      {


        openRequirementModal && <AddChamprequirementType closeBtn={closeBtn} show={openRequirementModal} requirementType={requirementType} priorityData={priorityData} locationData={locationData} InvokedRendered={InvokedRendered} supervisorListData={supervisorListData} areaListData={areaListData} />
      }



    </>
  )
}

export default ChampRequirementType2
