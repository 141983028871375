import DataTable from "react-data-table-component"
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll } from "../../consts/components/Utility"
import SearchBoxModal from "../../consts/reuseModules/SearchBoxModal"
import LeftBox from "../../consts/mediaQueryLayout/LeftBox"
import MainContainerWrapper from "../../consts/mediaQueryLayout/MainContainerWrapper"
import { ReactTableComponentStylesSheet } from "../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet"
import { useEffect, useState } from "react"

export const Lists = ({ filteredDataList, search, setSearch, columns, pending, keyName}: any) => {
    // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // useEffect(() => {
    //     setResetPaginationToggle(prev => !prev);
    // }, [filteredDataList]);

    return <DataTable
        title=""
        key={keyName}
        customStyles={ReactTableComponentStylesSheet}
        columns={columns}
        data={filteredDataList}
        pagination
        fixedHeader
        fixedHeaderScrollHeight='auto'
        selectableRowsHighlight
        highlightOnHover
        paginationComponentOptions={paginationRowsPerPageOptionsAll}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        subHeader
        progressPending={pending}
        // paginationResetDefaultPage={resetPaginationToggle}
        subHeaderComponent={
            <>
                <MainContainerWrapper>
                    <LeftBox>
                        <SearchBoxModal search={search} setSearch={setSearch} />
                    </LeftBox>
                </MainContainerWrapper>
            </>
        }
    />
}




