
export const initial_states_ticket_dashboard = {
    // Static Data Variable 
    DateFilter: ["createdAt", "updatedAt"],
    // fundamental comman variables  
    pending: false,
    loader: false,
    filterData: [],
    // filter variable  
    ticketfrom: null,
    ticketto: null,
    categoryid: null,
    status: null,
    assignedadminuserid: null,
    assignedsupervisorid: null,
    agent_id: null,
    // filter variable  

    filterAreaManagerId : null,
    filterAdminId : null,
    filterCategoryId : null,
    filterIsVisibleId : null,
    filterTagId : null,
    filterStatusId : null,
    filterTicketfrom : null,
    filterTicketto : null,
    filterAgentId : null,

    // FILTER LIST DATA  

    adminListData: [],
    supervisorListData: [],
    statusListData: [],
    categoryListData: [],
    campaignListData: [],
    ticketListOpenFilterData: {},

}


export const ticketDashboardStates = (initial_states_ticket_dashboard: any, { type, payload }: any) => {

    switch (type) {
        case "RTL_PENDING":
            return { ...initial_states_ticket_dashboard, pending: payload }
        case "RTL_LOADER":
            return { ...initial_states_ticket_dashboard, loader: payload }
        case "RTL_FILTERED_DATA":
            return { ...initial_states_ticket_dashboard, filterData: payload }
        case "TDL_TICKET_FROM":
            return { ...initial_states_ticket_dashboard, ticketfrom: payload }
        case "TDL_TICKET_TO":
            return { ...initial_states_ticket_dashboard, ticketto: payload }
        case "TDL_CATEGORY":
            return { ...initial_states_ticket_dashboard, categoryid: payload }
        case "TDL_STATUS":
            return { ...initial_states_ticket_dashboard, status: payload }
        case "TDL_ASSIGNED_ADMIN_USER_ID":
            return { ...initial_states_ticket_dashboard, assignedadminuserid: payload }
        case "TDL_ASSIGNED_SUPERVISOR_ID":
            return { ...initial_states_ticket_dashboard, assignedsupervisorid: payload }
        case "RTL_ADMIN_LIST_DATA":
            return { ...initial_states_ticket_dashboard, adminListData: payload }
        case "RTL_SUPER_VISOR_LIST_DATA":
            return { ...initial_states_ticket_dashboard, supervisorListData: payload }
        case "RTL_CATEGORY_LIST_DATA":
            return { ...initial_states_ticket_dashboard, categoryListData: payload }
        case "RTL_CAMPAIGN_LIST_DATA":
            return { ...initial_states_ticket_dashboard, campaignListData: payload }
        case "RTL_STATUS_LIST_DATA":
            return { ...initial_states_ticket_dashboard, statusListData: payload }
        case "TDL_FILTER_AREA_MANAGER_ID":
            return { ...initial_states_ticket_dashboard, filterAreaManagerId: payload }
        case "TDL_FILTER_ADMIN_ID":
            return { ...initial_states_ticket_dashboard, filterAdminId: payload }
        case "TDL_FILTER_CATEGORY_ID":
            return { ...initial_states_ticket_dashboard, filterCategoryId: payload }
        case "TDL_FILTER_IS_VISIBLE":
            return { ...initial_states_ticket_dashboard, filterIsVisibleId: payload }
        case "TDL_FILTER_TAGS":
            return { ...initial_states_ticket_dashboard, filterTagId: payload }
        case "TDL_FILTER_STATUS_ID":
            return { ...initial_states_ticket_dashboard, filterStatusId: payload }
        case "TDL_FILTER_TICKET_FROM":
            return { ...initial_states_ticket_dashboard, filterTicketfrom: payload }
        case "TDL_FILTER_TICKET_TO":
            return { ...initial_states_ticket_dashboard, filterTicketto: payload }
        case "TDL_FILTER_AGENT_ID":
            return { ...initial_states_ticket_dashboard, agent_id: payload }
        case "TDL_AGENT_ID":
            return { ...initial_states_ticket_dashboard, filterAgentId: payload }
        case "TICKET_LIST_OPEN_FILTER_DATA":
            return { ...initial_states_ticket_dashboard, ticketListOpenFilterData: payload }
        default:
            return initial_states_ticket_dashboard
    }
}


// filterDataOutsource?.ticketfrom,
// filterDataOutsource?.ticketto,
// filterDataOutsource?.categoryid,
// filterDataOutsource?.status,
// filterDataOutsource?.assignedadminuserid


