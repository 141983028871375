import React, { useEffect, useState } from 'react'
import { AdminClientSidePaginationLists } from '../../admins/adminClientSidePaginationLists/AdminClientSidePaginationLists'
import { MasterDeactivationNotesColumns } from './MasterDeactivationNotesColumns'
import { AllFielSearch } from '../../../consts/FieldSearch/AllFieldSearch'
import { getRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { ActiveCleanersFilteration } from '../../ActiveCleaner/ActiveCleanersFilteration'
import { BulkUtilButton } from '../../../consts/reuseModules/BulkUtilButton'
import Refresh from '../../../consts/Refresh'
import AdminForm from '../../admins/adminForms/AdminForm'
import { createMasterNotesFormData } from './MasterNotesFormData'

const MasterDeactivationNotes = () => {
    const [categoryListData, setCategoryListData] = useState<any>([])
    const [sortedColumns, setSortedColumns] = useState<any>(MasterDeactivationNotesColumns(categoryListData))
    const [search, setSearch] = useState<any>("")
    const [data, setData] = useState<any>([])
    const [filterData, setFilterData] = useState<any>([])
    const [pending, setPending] = useState<any>(false)
    const [showCreateMasterNotes, setShowCreateMasterNotes] = useState<any>(false)

    useEffect(() => {
        if (categoryListData) {
            const newColumns = MasterDeactivationNotesColumns(categoryListData);
            setSortedColumns(newColumns);
        }
    }, [categoryListData]);

    useEffect(() => {
        const filterSearchData = AllFielSearch(data, search)
        setFilterData(filterSearchData)
    }, [search])

    const getActionsData = async () => {
        const response = await getRequest(URL_EndPoints()?.getNotesCategories, null)
        setCategoryListData(response?.data?.data)
    }

    const getMasterNotesForListing = async () => {
        setPending(true)
        const response = await getRequest(URL_EndPoints()?.getMasterNotesForListing, setPending)
        setFilterData(response?.data?.data)
        setData(response?.data?.data)
    }

    useEffect(() => {
        categoryListData?.length == 0 && getActionsData()
        getMasterNotesForListing()
    }, [])

    return (
        <>
            <AdminClientSidePaginationLists
                title="Master Deactivation Notes List"
                reorderColumnTitle="masterDeactivationNotesListReorderColumnItem"
                sortedColumns={sortedColumns}
                filterData={filterData}
                pending={pending}
                clearSelectedRows={false}
                totalRows={filterData?.length}
                conditionalRowStyles={{}}
                onChangePage={() => { }}
                onChangeRowsPerPage={() => { }}
                onSelectedRowsChange={() => { }}
                search={search}
                setSearch={setSearch}
                showFilter={false}
                pagination={true}
                ExpandedComponent={()=><></>}
                clientSideFilterComponent={ActiveCleanersFilteration}
                keyName={"masterDeactivationNotesList"}
                clientSideFilterData={{
                    // handleChangeInputData: onChangeHandler,
                    // handleActions: searchHandler,
                    // payloads: payload,
                    // supervisorListData: supervisorListData,
                }}
                SubHeaderComponentRightSection={() => (<>
                        <Refresh refreshNow={getMasterNotesForListing} />
                        <BulkUtilButton title="Create Master Notes" filteredSelectedRows="static" setShow={setShowCreateMasterNotes} />
                </>)}
                SubHeaderComponentActionButtons={() => (<></>)}
            />
            {
                showCreateMasterNotes ? <AdminForm
                    show={showCreateMasterNotes}
                    setShow={setShowCreateMasterNotes}
                    refreshNow={() => { }}
                    toastMessage="message"
                    formData={createMasterNotesFormData}
                    staticFormData={[]}
                    initialData={{
                        note: "",
                        category_id: null
                    }}
                    keyName="createMasterDeactivationNotesList"
                    formTitle={`Create Master Deactivation Notes`}
                    formSubmitApiUrl="createMasterNote"
                /> : <></>
            }

        </>
    )
}

export default MasterDeactivationNotes
