import React, { memo } from 'react';
import { withSwal as CreateTicketFromWhatsappMessage } from 'react-sweetalert2';
import { toast } from 'react-toastify';
import { postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
export default CreateTicketFromWhatsappMessage((props: any) => {
    const { swal, confirm, cancel, payloads, title, render, handleCloseModalCleaner }: any = props;
    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.create_NewTicketFromWhatsAppChat, payloads, null)
            if (response?.data?.msg) {
                toast.success("New Ticket Created Successfully")
                handleCloseModalCleaner()
            }
        }



        if (render) {
            swal.fire({
                title: title,
                showDenyButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    handleCloseModalCleaner()
                    swal.fire("Request failed ")
                }
            })
        }
    }, [render])
});
