import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { postRequest } from "../../../consts/Server/Requests";
import { URL_EndPoints } from "../../../consts/Server/URL_EndPoints";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { adminToastRunner } from "../adminUtils";
import moment from "moment";
const AdminDueDateRowForm = ({ show, setShow, setStoreRow = null, initialData, dueDateKeyName, toastMessage, keyName, formTitle, formSubmitApiUrl, label = "", }: any) => {
    const [loader, setLoader] = useState(false)
    const [payload, setPayload] = useState<any>(initialData)
    const [errMessage, setErrMessage] = useState("")
    useEffect(() => { setPayload(initialData) }, [initialData])

    const submitForm = async (e: any) => {
        e.preventDefault()
        if (payload[dueDateKeyName]) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.[formSubmitApiUrl], payload, setLoader)
            adminToastRunner(response, toastMessage)
            setStoreRow && setStoreRow(response?.data?.status == 200 ? { isSet: true, due_date: payload[dueDateKeyName] } : { isSet: false, due_date: payload[dueDateKeyName] })
            if (response?.data?.status == 200) {
                setShow(false)
            }
        }
        else {
            toast.error("Form validation has Error !", { position: "top-center" })
        }
    }

    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter-duedate"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitForm}>
                        <Form.Group className="mb-3" key={keyName} >
                            {label ? <Form.Label className='text-muted'>{label}</Form.Label> : <></>}
                            <div className='mb-3'>
                                <input
                                    placeholder='Enter Rating..'
                                    name='rating'
                                    type='date'
                                    value={moment(new Date(payload[dueDateKeyName])).format('YYYY-MM-DD')}
                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                    autoComplete='off'
                                    onChange={(event) => setPayload({ ...payload, [dueDateKeyName]: event?.target?.value })}
                                />
                                {errMessage && <Form.Label className='text-danger fs-8'><FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger fs-fs-8' /> {errMessage}</Form.Label>}
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3 p-2 d-flex justify-content-end" >
                            {
                                <Button type='submit' variant={loader ? "success" : "primary"} >
                                    {loader ? "Submitting..." : "Submit"}
                                </Button>
                            }
                        </Form.Group>
                    </Form>

                </Modal.Body>
            </Modal> : ''}
        </>
    )
}


export default AdminDueDateRowForm;




// const updateDueDateInRow = (payload: any) => {
//     if (payload?.isSet) {
//       row['cleanerTicketsSingle'] = {}
//       row.cleanerTicketsSingle.last_date_resolution = payload?.due_date
//       setStoreRow(row)
//     }
//   }

//   {
//     showDueDatePopup ? <AdminDueDateRowForm
//       show={showDueDatePopup}
//       setShow={setShowDueDatePopup}
//       setStoreRow={updateDueDateInRow}
//       initialData={{
//         champId: storeRow?.id,
//         ticket_id: ticket_id,
//         prev_due_date: dueDate,
//         due_date: dueDate ? dueDate : adminMomentDueDate(0),
//         for: 'inactive_champ',
//       }}
//       dueDateKeyName="due_date"
//       toastMessage="message"
//       keyName="oldChampDueDate"
//       formTitle="Update Due Date"
//       formSubmitApiUrl="updateDueDateOldChamp"
//       label=""
//     /> : <></>
//   }
  