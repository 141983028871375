

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { postRequest } from '../../../Server/Requests'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import SingleSelectSearchDetailsDefault from '../../../filtersComponents/SingleSelectSearchDetailsDefault'
import { languageDataList, languageFullName } from '../../../CommonData'
const UpdateLanguage = ({ show, setCloseModal, row, setRowData = null, }: any) => {

    const [loader, setLoader] = useState<boolean>(false)
    const [payload, setPayload] = useState<any>({
        cleanerid: row?.id,
        code: row?.default_language ?? "",
    })

    const handleSubmitAssign = async () => {
        if (payload?.cleanerid && payload?.code) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.saveLanguage, payload, setLoader)
            toast?.[response?.data?.status == 200 ? "success" : "error"]?.(response?.data?.msg, { position: "top-center" })
            if (response?.data?.status == 200 && setRowData) {
                row["default_language"] = payload?.code
                setRowData(row)
                setCloseModal(false)
            }
        }
        else {
            toast.error("Something went wrong...", { position: "top-center" })
        }
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
    }

    return (
        <>
            {show ? <Modal show={show} onHide={() => setCloseModal(false)} dialogClassName="modal-small"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Language</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='col-12 mb-4'>
                        <SingleSelectSearchDetailsDefault
                            handleChangeInputData={onChangeHandler}
                            HeaderTitle='Select Language'
                            SelectData={languageDataList || []}
                            DynamicKeys={["name"]}
                            id={"id"}
                            name={"code"}
                            targetValue={"value"}
                            defaultData={row?.default_language ? {
                                label: languageFullName[row?.default_language],
                                value: row?.default_language
                            } : null}
                        ></SingleSelectSearchDetailsDefault>
                    </div>

                    <button disabled={loader} className={`btn btn-sm btn-${loader ? "warning" : "primary"} fs-6 col-12 w-100 `} onClick={handleSubmitAssign}>
                        {loader ?
                            <>
                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                <span>Updating...</span>
                            </>

                            : " Update Language"}
                    </button>

                </Modal.Body>
            </Modal> : ''}
        </>
    )
}


export default UpdateLanguage
