import React from 'react'
import MultiSelect from '../../../../../consts/Select/MultiSelect'

const AssigSuper = () => {
  return (
    <div>
      <MultiSelect setSelectedData={"setSelectedData "} allDatafilter={"allDatafilter"} ></MultiSelect>
    </div>
  )
}




export default AssigSuper
