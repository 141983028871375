export  const JobStatusLists = [
    "New Task",
    "notathome",
    "reported",
    "ontime",
    "early",
    "late",
    "overdue",
    "rated_commented",
    "notdue",
    "Completed",
    "Missed",
    "Extended",
    "Postponed",
    "Not_Interested"
 ]

 export const JOB_STATUSES_FORMATTED = [
    "",
    "Not At Home",
    "Reported",
    "On Time",
    "Early",
    "Late",
    "Overdue",
    "Rated Commented",
    "Not Due",
    "Completed",
    "Missed",
    "Extended",
    "Postponed",
    "Not Interested",
    "Action Taken",
    "Failed",
    "Recreate Buddy Training 1",
    "Deactivate Fresher",
    "Recreate Buddy Training 2",
    "Absent",
    "No Vacancy",
];

export  const JobStatusListsColors = [
    "danger",
    "primary",
    "primary",
    "success",
    "danger",
    "warning",
    "danger",
    "primary",
    "primary",
    "success",
    "danger",
    "warning",
    "warning",
    "primary"
 ]





// {
//     "New Task": 0,
//     "notathome": 1,
//     "reported": 2,
//     "ontime": 3,
//     "early": 4,
//     "late": 5,
//     "overdue": 6,
//     "rated_commented": 7,
//     "notdue": 8,
//     "Completed": 9,
//     "Missed": 10,
//     "Extended": 11,
//     "Postponed": 12,
//     "Not_Interested": 13,
// };