import { Dialog } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import moment from "moment";
import { LocalBaseURL } from '../../../BaseURLmanagement';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import TagsShow from '../../consts/TagsShow/TagsShow';
import MultiSelect from '../AreaWiseAvailibiltyByCleaners/common/MultiSelect';
import JobDetailsModal from '../cleaner/cleaner-items/JobDetailsModal';
import NewChampTableList from './NewChampTableList';
import IsLoading from '../../consts/IsLoading/IsLoading';
import LoaderSpinner from '../admins/LoaderSpinner';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

const NewChampAvailabilityList = ({ topHeight }: {
  topHeight?: boolean
  champId?: any
}) => {

  const paramsIDS: any = useParams()
  const urlEndPoint = window.location.pathname.split("/")
  let from = urlEndPoint[urlEndPoint?.length - 2]
  LocalBaseURL()
  const ReAssignmentDaily = useSelector((store: any) => store?.RefreshPage?.DailyReAssign)
  const [cleanerListFilterData, setCleanerListFilterData] = React.useState([])
  const [id, setId] = React.useState('')
  const [isModalOpen, setModalOpen] = React.useState(false)
  const [maxMin, setMaxMin] = useState<boolean>(false)
  const [cleanerListData, setCleanerListData] = React.useState<any>([])
  const [customerListData, setCustomerListData] = React.useState<any>([])
  const [dates, setDates] = React.useState<any>([])
  const [timeSlots, setTimeSlots] = React.useState<any>([])
  const data = useMemo(() => cleanerListData, [cleanerListData])
  const [mainLoader, setMainLoader] = React.useState(false)
  const [pending, setPending] = React.useState(false)
  const [payload, setPayload] = useState<any>({
    fromDate: moment().day("Monday").format('YYYY-MM-DD'),
    toDate: moment().day(7).format('YYYY-MM-DD'),
    subscriptionID: null,
    timeslots: [],
    distenceRadius: 1,
    cleanerid: null,
  })

  const getAvalabilitybySubscriptions = async (payload: any, setPending: any) => {
    const result = await postRequest(URL_EndPoints()?.getAvalabilitybySubscription, payload, setPending)
    setDates(result?.data?.dates)
    setTimeSlots(result?.data?.timeslots)
    setCleanerListData(result?.data?.data)
  }

  async function getXCleanerCustomers() {
    setMainLoader(true)
    const xPayload = {
      cleanerid: from == "active-paid" ? paramsIDS?.cleanerid : paramsIDS?.id,
    }
    const result = await postRequest(URL_EndPoints()?.getXCleanerCustomers, xPayload, setMainLoader)
    setCleanerListData([])
    setCustomerListData(result?.data?.data)
    const getCleanerList = await getRequest(URL_EndPoints(null)?.getCleanerList, null)
    setCleanerListFilterData(getCleanerList.data.data)
  }

  React.useEffect(() => {
    getXCleanerCustomers()
  }, [ReAssignmentDaily])

  const getCleanerAvailaibiltyBasedOnCustomer = async (id: number, customerTimeSlote: string) => {
    setPending(true)
    payload.subscriptionID = id
    payload.timeslots = [+customerTimeSlote]
    setPayload(payload)
    await getAvalabilitybySubscriptions(payload, setPending)
  }

  const searchHandler = async () => {
    setPending(true)
    await getAvalabilitybySubscriptions(payload, setPending)
  }

  const handleJobDetailSubmit = (id: any) => {
    setId(id)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  let styleConditon = customerListData?.length > 0 ? { top: !maxMin ? "249px" : "120px", zIndex: 99, } : { zIndex: 99, top: "" }
  return (
    <>
      {
        mainLoader ? <IsLoading /> :
          customerListData?.length > 0 && (
            <div className='card  p-3 d-flex flex-row align-items-center   justify-content-center position-sticky flex-wrap' style={{ top: topHeight ? "69px" : "", zIndex: 99, }}>
              <button className='btn btn-sm p-1 px-4 btn-primary fs-9' onClick={() => setMaxMin(!maxMin)} style={{ position: "absolute", right: 0, top: 0, zIndex: 999999 }}>{maxMin ? "Maximize" : "Minimize"}</button>
              <div
                className="container-fluid d-flex scrollmenu p-3"
                style={{ overflow: "auto", overflowY: "hidden", scrollBehavior: 'smooth' }}
              >
                {
                  customerListData?.map((customerlist: any, index: number) => {
                    return (
                      <div className="me-2" style={{ maxWidth: "150px", height: maxMin ? "10px" : "" }} >
                        <div
                          onClick={() => getCleanerAvailaibiltyBasedOnCustomer(customerlist?.id, customerlist?.timeslot)}
                          className={`card d-flex flex-column w-100 justify-content-center align-items-start mr-auto me-2 mb-3 ml-2 bg-${payload?.subscriptionID === customerlist?.id ? "warning" : ""} col-3`}
                          style={{
                            border:  customerlist?.isNearbySubscription == false ? "2px solid green" : "",
                            minHeight: "120px",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            whiteSpace: "normal", 
                            overflow:"hidden"
                          }}
                        >
                          <div className={`badge text-black text-center fw-bolder cursor-pointer me-lg-1 mb-1`} style={{ wordBreak: "break-all" }} >
                            {customerlist?.assignedChamp}
                          </div>
                          <div className={`badge text-black text-center fw-bolder cursor-pointer me-lg-1 mb-1`} >
                            {customerlist?.distance} {`( ${" Ass. Distance"})`}
                          </div>
                          <div className={`badge text-black text-center fw-bolder cursor-pointer me-lg-1 mb-1`} >
                            {customerlist?.myDistance}  {`( ${" My Distance"} )`}
                          </div>
                          <div className={`badge text-black text-center fw-bolder cursor-pointer me-lg-1 mb-1`} >
                            {customerlist?.name}    {`( ${" CX Name"} )`}
                          </div>
                          <div className={`badge text-black text-center fw-bolder cursor-pointer me-lg-1 mb-1`} >
                            {
                              +customerlist?.timeslot == 2 ? "06AM-07AM" :
                                +customerlist?.timeslot == 3 ? "07AM-08AM" :
                                  +customerlist?.timeslot == 4 ? "08AM-09AM" :
                                    +customerlist?.timeslot == 5 ? "09AM-10AM" :
                                      +customerlist?.timeslot == 6 ? "10AM-11AM" :
                                        +customerlist?.timeslot == 7 ? "11AM-12PM" :
                                          +customerlist?.timeslot == 8 ? "12PM-01PM" :
                                            +customerlist?.timeslot == 9 ? "01PM-02PM" :
                                              +customerlist?.timeslot == 10 ? "02PM-03PM" :
                                                !customerlist?.timeslot ? "Not Mentioned" : ""
                            }
                          </div>
                          <div className={`badge text-black text-center fw-bolder cursor-pointer me-lg-1 mb-1`} >
                            {
                              +customerlist?.fullcleaningday == 1 ? "Sunday" :
                                +customerlist?.fullcleaningday == 2 ? "Monday" :
                                  +customerlist?.fullcleaningday == 3 ? "Tuesday" :
                                    +customerlist?.fullcleaningday == 4 ? "Wednesday" :
                                      +customerlist?.fullcleaningday == 5 ? "Friday" :
                                        +customerlist?.fullcleaningday == 6 ? "Saturday" : ""
                            }  -
                            (
                            <div className={`badge badge-light-${customerlist?.frequencyid == 1 ? "success" : customerlist?.frequencyid == 2 ? "warning" : customerlist?.frequencyid == 3 ? "danger" : customerlist?.frequencyid == 4 ? "primary" : "primary"} fw-bolder cursor-pointer me-lg-1`} >
                              {customerlist?.frequencyid == 1 ? (("A")) : customerlist?.frequencyid == 2 ? ("W") : customerlist?.frequencyid == 3 ? ("D") : customerlist?.frequencyid == 4 ? ("OT") : "No Frequency Available"}
                            </div>
                            )
                          </div>
                          <div className={`badge text-black text-center fw-bolder cursor-pointer me-lg-1 mb-1`} >
                            {customerlist?.jobsitename ? customerlist?.jobsitename : "Individual"}
                          </div>
                          <div className={`badge text-black fw-bolder cursor-pointer me-lg-1 mb-1 d-flex`} >
                            <div className='d-flex flex-wrap'>
                              <TagsShow Tag={customerlist?.customerData?.private_tag}></TagsShow>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>

            </div>
          )}

      {
        <div className="card position-sticky mb-4 px-0" style={topHeight ? styleConditon : { top: "", zIndex: 99 }} >
          <div className='d-flex justify-content-around align-items-center flex-wrap mb-2   ' >
            <div className='col-12 col-sm-12 col-md-12 col-lg-5 d-flex align-items-center mt-3'>
              <span className='me-2' key="multipSelect">
                {
                  pending && timeSlots?.length == 0 ? <LoaderSpinner /> : <MultiSelect setPayload={setPayload} timeSlots={timeSlots} defaultData={null} ></MultiSelect>
                }
              </span>
              <select
                className='form-select form-select-solid'
                onChange={(event) => setPayload({ ...payload, ["cleanerid"]: +event?.target?.value })}
                value={payload["cleanerid"]}
              >
                <option value=''>Select Cleaner</option>
                {cleanerListFilterData?.map((item: any) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.first_name} {item.last_name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center mt-3'>
              <input
                type='number'
                className='form-control bg-secondary me-2 border'
                placeholder='0/km'
                onChange={(event) => setPayload({ ...payload, ["distenceRadius"]: +event?.target?.value })}
                value={payload["distenceRadius"]}
              />
              <input
                type='date'
                className='form-select form-select-solid me-2'
                onChange={(event) => setPayload({ ...payload, ["fromDate"]: event?.target?.value })}
                value={payload["fromDate"]}

              />
              <input
                type='date'
                className='form-select form-select-solid me-2'
                onChange={(event) => setPayload({ ...payload, ["toDate"]: event?.target?.value })}
                value={payload["toDate"]}

              />
              <div>
                <button className='btn btn-sm btn-warning' onClick={searchHandler}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      {
        pending ?
          <IsLoading />
          :
          <div className='card'>
            {
              cleanerListData?.length > 0 && <div className='table-responsive px-3'>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                >
                  <thead>
                    <tr>
                      <th style={{ width: '130px' }}>
                        <div className='bg-secondary text-dark py-2 me-2 text-center fw-bolder rounded'>
                          Cl Name
                        </div>
                      </th>
                      <th>
                        <div className='bg-secondary text-dark py-2 me-2 text-center fw-bolder rounded'>
                          TimeSlots
                        </div>
                      </th>
                      {dates?.map((item: any) => (
                        <th key={item}>
                          <div
                            className='bg-secondary text-success py-2  gy-5  text-center fw-bolder rounded'
                            style={{ maxWidth: '120px', width: '100px' }}
                          >
                            {moment(item).format('DD-MM')}
                            <br />
                            {moment(item).format('dddd')}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {
                    cleanerListData && <NewChampTableList
                      cleanerListData={cleanerListData}
                      timeSlots={timeSlots}
                      subscriptionid={payload?.subscriptionID}
                      handleJobDetailSubmit={handleJobDetailSubmit}
                    />
                  }
                </table>
              </div>
            }

            {isModalOpen && (
              <Dialog
                open={true}
                onClose={handleCloseModal}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <JobDetailsModal filteredData={id} data={data} handleCloseModal={handleCloseModal} />
              </Dialog>
            )}

          </div>
      }
    </>
  )
}
export default NewChampAvailabilityList
