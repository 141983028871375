import React, { FC, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Days } from "../../../consts/CommonData";
import { URL_EndPoints } from "../../../consts/Server/URL_EndPoints";
import { postRequest } from "../../../consts/Server/Requests";
type Props = {
    selectedId: any
    ParentData: any
}
const ChangeFullCleaningDay: FC<Props> = ({ ParentData, selectedId }) => {
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [payloads, setPayloads] = useState({
        subscriptionid: selectedId,
        changedatefrom: "",
        newfullcleaningday: 0
    })

    const inputsHandler = (event: any) => {
        const { name, value } = event.target
        setPayloads({ ...payloads, [name]: name === "changedatefrom" ? value : +value })
    }

    const submitFormHandler = async () => {
        setLoader(true)
        const response = await postRequest(URL_EndPoints()?.changeserviceday, payloads, setLoader)
        if (response?.data?.msg == 200) {
            toast.success(response?.data?.msg)
            setDisable(200)
            ParentData?.handleclose()

        } else {
            toast.error(response?.data?.msg)
            ParentData?.handleclose()

        }
    }
    return (
        <div id='kt_modal_add_user_form' className='form mt-4 p-3'>
            <div
                className='d-flex flex-column scroll-y me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
            >
                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Select Day</label>
                    <select
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        name="newfullcleaningday"
                        onChange={inputsHandler}
                        className={clsx(
                            'form-select form-select-solid bg-white form-control form-control-solid mb-3 mb-lg-0',
                        )}
                    >
                        <option>Select Day</option>
                        {Days?.map((item: any) => {
                            return <option value={item.id} key={item.id}>{item.name}</option>
                        })}
                    </select>
                </div>
                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Select Date</label>
                    <input
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        name="changedatefrom"
                        type="date"
                        onChange={inputsHandler}
                        className={clsx(
                            'form-select form-select-solid bg-white form-control form-control-solid mb-3 mb-lg-0',
                        )}
                    />
                </div>
            </div>
            <div className='text-center pt-4'>
                <button
                    type='submit'
                    className='btn btn-primary  '
                    disabled={disable || loader ? true : false} onClick={submitFormHandler}
                >
                    {
                        loader ?
                            <div className="d-flex">
                                <div className='spinner-border mr-15' role='status'></div>
                                <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Plz wait.....</h4>
                            </div>
                            : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update</h4>
                    }
                </button>
            </div>
        </div>
    )
}
export default ChangeFullCleaningDay;