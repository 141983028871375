import React from "react";
import JoditEditor from "jodit-react";

interface AdminReadOnlyEditorProps {
    content: string; 
}

const AdminReadOnlyEditor: React.FC<AdminReadOnlyEditorProps> = ({ content }) => {
    const config = {
        readonly: true, 
        toolbar: false, 
        toolbarSticky: false, 
        showXPathInStatusbar: false, 
    };

    return (
        <div>
            <JoditEditor
                value={JSON.parse(content)}
                config={config}
            />
        </div>
    );
};

export default AdminReadOnlyEditor;
