import React, { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { image_Cloudfront } from '../../components/Utility'
const ShowDocumentIndividual = ({ row, show, setShow }: any) => {
  const [scale, setScale] = useState(1);

  const handleWheel = (event: any) => {
    event.preventDefault();
    const delta = Math.sign(event.deltaY);
    setScale((prevScale) => Math.min(Math.max(0.5, prevScale + delta * 0.1), 3));
  };
  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };



  return (
    <>

      <Modal show={show} onHide={() => setShow(false)} dialogClassName='modal-small' >
        <Modal.Header closeButton>
          <Modal.Title>Scroll mouse wheel to Zoom In / Zoom Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div style={{ overflow: 'hidden', transformOrigin: 'top left bottom right', transform: `scale(${scale})` }} className='d-flex justify-content-center align-items-center'>
            {
              row?.docurl && <img
                className=''
                src={image_Cloudfront + row?.docurl}
                alt="No Image/Documents"
                style={{ width: "100%", objectFit: 'cover' }}
                onWheel={handleWheel}
              />
            }
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => handleZoomIn()}>Zoom In</Button>
          <Button onClick={() => handleZoomOut()}>Zoom Out</Button>
        </Modal.Footer> */}
      </Modal>

    </>
  )
}

export default ShowDocumentIndividual
