/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import "./AsidMenuCss.css"

import AsidMenuMainList from './AsidMenuMainList'
import { AsidMenuMainSearchList, AsidMenuMainSearchListRoutes } from './AsidMenuMainSearchList'
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import LiveClock from '../../../../app/consts/adminPanelCarselona/liveClock/LiveClock';
import { useSelector } from 'react-redux';
export function AsideMenuMain() {
    const userAllowedPermissionList = useSelector((store: any) => store.adminReducer.userAllowedPermissionList)

	// only for search purpose code 
	const [isSearchOpen, setSearchOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [mainFitlerSuggestionData, setMainFitlerSuggestionData] = useState<any>([]);
	const [allowedFilteredSuggestions, setAllowedFilteredSuggestions] = useState<any>([]);
	const history = useNavigate();

	useEffect(() => {
		const AllowedMenuSearch = AsidMenuMainSearchListRoutes?.filter((ele:any)=>userAllowedPermissionList?.includes(ele?.ID))
		setMainFitlerSuggestionData(AllowedMenuSearch)
	}, [userAllowedPermissionList])
	
	const handleSearchKeyDown = (event: any) => {
		if (event.ctrlKey && event.key === ' ') {
			setSearchOpen(true);
		}
	};

	const handleSearchKeyDownTask = (event: any) => {
		if (event.ctrlKey && event.altKey && event.key === 'z') {
			history("/admin/AdminPanelCarselona")
		}
	};

	const handleSearchInputChange = (event: any) => {
		setSearchQuery(event.target.value);
	};

	const handleSuggestionClick = (menuItem: any) => {
		setSearchOpen(false);
		history(menuItem)
	};


	useEffect(() => {
		document.addEventListener('keydown', handleSearchKeyDown);
		document.addEventListener('keydown', handleSearchKeyDownTask);

		return () => {
			document.removeEventListener('keydown', handleSearchKeyDown);
		};
	}, []);

	useEffect(() => {
		const filteredSuggestions = mainFitlerSuggestionData.filter(
			(item: { title: string; }) => item?.title?.toLowerCase()?.includes(searchQuery.toLowerCase())
		);
		searchQuery ? setAllowedFilteredSuggestions(filteredSuggestions) : setAllowedFilteredSuggestions([])

	}, [searchQuery])

	const handleClose = () => {
		setSearchOpen(false)
	}


	return (<>
		{
			!isSearchOpen &&
			<div className='p-2 mx-1 ' onClick={() => setSearchOpen(true)}>

				<div className=' d-flex border border-secondary rounded mx-2'>

					<span>
						<FontAwesomeIcon className='fs-2 fw-bold mt-3 text-white px-1 text-muted ' icon={faSearch} />
					</span>

					<input type="text" className='form-control cursor-pointer w-100 outlinecss ' placeholder='Search / Daily-Dashboard / Ticket Dashboard / Job-List' readOnly />

				</div>

			</div>

		}

		<AsidMenuMainList />

		{isSearchOpen && (
			<AsidMenuMainSearchList
				show={isSearchOpen}
				handleClose={handleClose}
				query={searchQuery}
				setSearchQuery={handleSearchInputChange}
				suggestions={allowedFilteredSuggestions}
				onSuggestionClick={handleSuggestionClick}
			/>
		)}
	</>
	)

}

