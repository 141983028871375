import { faAngleDoubleDown, faAngleDoubleRight, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { getName } from "../modules/admins/adminUtils";

export const AssignedAdminDetails = ({ row, name, nameArray,keys }: any) => {

    const [toggle, setToggle] = useState(false);
    return (
        <div className='d-flex justify-content-start align-items-start flex-wrap'>
            <FontAwesomeIcon icon={toggle ? faAngleDoubleDown : faAngleDoubleRight} className='me-2 text-dark cursor-pointer mt-1 fw-bold' onClick={() => setToggle((prev) => !prev)} />
            {
                !toggle ? (
                    name
                ) : (
                    <div className="d-flex flex-column">
                        {
                            nameArray.length > 0
                                ? nameArray?.map((ele: any, index: any) => (
                                    <li key={index} className='me-1'>
                                        {getName(ele.ticketAssignedAgent) ? getName(ele.ticketAssignedAgent) : "Not Assigned"}
                                    </li>
                                ))
                                : "Not Set"
                        }
                    </div>
                )
            }
        </div>
    );
};