import moment from 'moment';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ColorRing } from 'react-loader-spinner';
import { JobStatusData } from '../CommonData';
import { getCustomerActiveAubscriptions } from './API';
import './calendar.scss';

// ---- spanish
moment.locale("en");

// ---- calendar wrapper
const Calendar = (props: any) => <div className="calendar">{props.children}</div>;

// ---- calendar header
const Header = (props: any) => <header className="header">{props.children}</header>;


// ---- column component for calendar header
const Column = (props: any) => <div className={props.align}>{props.children}</div>;

// ---- button component
const Button = (props: any) => (
    <button type="button" title={props.text} aria-label={props.text} {...props}>
        {(props.icon) ? (<i className={props.icon}></i>) : props.text}
    </button>
);

// ---- week days
const WeekDays = (props: any) => {
    let m = moment().weekday();
    // map week names
    return (
        <ul className="weekdays">
            {moment.weekdays(true).map((item, i) => (
                <li key={i.toString()} className={m == i ? "today" : ""}>
                    {item}
                </li>
            ))}
        </ul>
    );
};

// ---- month events
const MonthEvents = (props: any) => {
    return props.data && props.data.map((subData: any) => {
        return subData?.attendencedata.map((c: any) => {
            // let arr = c.attendencedate.split("-");
            // if (moment(c.attendencedate).format('YYYY-MM-DD') == props.year + '-' + props.month + '-' + props.day) {
            let date = moment(c.attendencedate).format('YYYY-MM-DD');

            // let arr = moment(c.attendencedate).format('YYYY-MM-DD').split("-");
            // if (arr[0] === props.year && arr[1] === props.month && arr[2] === props.day) {
            if (date == props.currentDate) {
                // let jobStatus: any = JobStatusData?.filter((ele: any) => +ele.keys === +c.job_status);
                return (
                    <div className={`ev ${c.job_status_id > 0 ? 'label_danger' : (c.attendencedatedone != "" ? 'label_info' : 'label_warning')}`} onClick={(e) => {

                        // props.fn(c)
                    }}>
                        <div className="ev-desc">
                            <b>{c.service_type_name}({c.id})</b>
                        </div>

                        {c.job_status != 0 ?
                            <div className="ev-desc">
                                {/* <b>{c.service_type_name}</b><br /> */}
                                <small style={{ display: 'block' }}>{c?.timeslotDetail?.name}</small>
                                <small style={{ display: 'block' }}>{JobStatusData[+c?.job_status]?.name} {c?.attendencedatedone != "" ? 'at ' + moment(new Date(c?.attendencedatedone)).format('h:mm:ss a') : ""} <br />{'by ' + (c.latitude == "" ? 'Admin' : 'Champ')}</small>
                            </div> : ""

                        }

                        {c.job_status_id > 0 ?
                            <div className="ev-desc">
                                {/* <b>{c.service_type_name}</b><br /> */}
                                <small style={{ display: 'block' }}>{c.job_status_text}</small>
                            </div>
                            : ''
                        }

                        {c?.is_rated == 1 ?
                            <div className="ev-desc">
                                {/* <b>{c.service_type_name}</b><br /> */}
                                <small style={{ display: 'block' }}>Rating: {c?.job_rating?.rate}</small>
                                {c?.job_rating.comment == "" ?
                                    <small style={{ display: 'block' }}>Comment: {c?.is_rated == 1 ? c?.job_rating?.comment : ''}</small>
                                    : ""
                                }
                            </div> : ""
                        }
                        <div className="ev-desc">
                            {/* <b>{c.service_type_name}</b><br /> */}
                            <small style={{ display: 'block' }}> <img src={subData.carimage} alt="" width="20px" /> {subData.carcatname}</small>
                        </div>
                    </div>
                );
            }
        })

    });
}


// ---- month days
const MonthDays = (props: any) => {
    // first day
    const first = moment(props.date).date(1);
    // week format
    const weekF = moment().format("YYYYMM");

    // get event data
    const handleClick = useCallback((event: any) => {
        alert(event.date + ": " + event.content);
    }, []);

    // const handleClick = () => {
    // }


    // init arr
    let arr = [];

    // create 7 x 5 boxes
    for (let i = 0; i < 35; i++) {
        // day
        let day = moment(first)
            .date(1)
            .subtract(first.weekday() - i, "days")
        // day format
        let dayF = moment(day.format("YYYYMM"))

        // add class by type
        let cls = dayF.isBefore(weekF)
            ? "days-before"
            : dayF.isAfter(weekF)
                ? "days-after"
                : props.date.clone().isSame(day, "day") && "today";

        // let lastDateOfMonth = day.clone().endOf('month').date()
        // if (i + 1 <= lastDateOfMonth) {
        // push

        arr.push(
            <li key={i.toString()} className={cls}>
                <div className="date">{day.date()}</div>
                <div className="info">{day.format("dddd")}</div>

                <MonthEvents
                    data={props.data}
                    currentDate={day.format("YYYY-MM-DD")}
                    day={day.clone().format("DD")}
                    month={props.date.clone().format("MM")}
                    year={props.date.clone().format("YYYY")}
                    fn={handleClick}
                />
            </li>
        );
        // }
    }
    return <ul className="days">{arr}</ul>;
};

// ---- app
export const CalendarView: any = ({ customerId, vehicleid }: any) => {
    const [data, setdata] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        async function InvokedRendered() {

            const customerActiveAubscriptions = await getCustomerActiveAubscriptions(customerId, vehicleid)
            setdata(customerActiveAubscriptions?.data?.data)
            setIsLoading(false)
        }
        InvokedRendered()
    }, [])



    const [month, setMonth] = useState(moment());

    // next prev and today buttons
    const next = useCallback(() => {
        setMonth(month.clone().add(1, "month"));
    }, [month]);

    const prev = useCallback(() => {
        setMonth(month.clone().subtract(1, "month"));
    }, [month]);

    const today = useCallback(() => {
        setMonth(moment());
    }, [month]);

    // render
    return (
        <>
            {isLoading ?
                <div className="d-flex justify-content-center">
                    <ColorRing
                        visible={true}
                        height="35"
                        width="35"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />
                </div> :
                <Calendar>
                    <Header>
                        <Column align="left">
                            <Button
                                onClick={prev}
                                icon="fa fa-arrow-left"
                                text={"Anterior"} />
                            <Button
                                onClick={today}
                                icon="fa fa-calendar"
                                text={"Hoy"} />
                            <Button
                                onClick={next}
                                icon="fa fa-arrow-right"
                                text={"Siguiente"} />
                        </Column>
                        <Column align="center">
                            <h1>{month.format("MMMM")} {month.format("YYYY")}</h1>

                        </Column>

                        <Column align="right">
                            {/* {moment().format("dddd")} {moment().format("LL")} */}
                            <div className='text-left'>
                                <p>Name: {data?.length > 0 ? data[0]['name'] : ''}</p>
                                <p>Phone: {data?.length > 0 ? data[0]['phone'] : ''}</p>
                                <p>Vehicle No: {data?.length > 0 ? data[0]['vehicleno'] : ''}</p>
                            </div>

                        </Column>
                    </Header>
                    <WeekDays />
                    <MonthDays date={month} data={data} />
                </Calendar>
            }
        </>
    );
}
// ---- render
// ReactDOM.render(<App />, window.root);
