import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import SingleSelectSearchDetails from '../../consts/Select/SingleSelectSearchDetails';

const BulkAssigAgentCustomerAgent = ({ showAssignPopUp, setShowAssignPopUp, filteredSelectedRows, setToggleClearRows, selectedRowsCount, toggledClearRows, handleRefresh, setFilteredSelectedRows, setUpdatedCx = null, row, defaultData = null }: any) => {

    const [isTransferLoading, setIsTransferLoading] = useState(false);
    const [adminList, setAdminList] = useState<any>([])
    const [apiLoader, setIsApiLoader] = useState<any>(false)
    const [transferPayload, setTransferPayload] = useState({
        customerIds: [],
        agentId: 0,
        shouldMoveTickets: false,
    });

    async function getFetchInitialData() {
        setIsApiLoader(true)
        const results = await getRequest(URL_EndPoints()?.getAlladminlist, setIsApiLoader)
        setAdminList(results?.data?.data)
    }

    useEffect(() => {
        showAssignPopUp && getFetchInitialData()
    }, [showAssignPopUp])


    const handleTransferAgent = async (event: any) => {
        event.preventDefault();
        // const filteredSelectedRowsBulkIds = filteredSelectedRows?.map((row: any) => row?.id) || []
        // transferPayload.customerIds = filteredSelectedRowsBulkIds;
        transferPayload.customerIds = filteredSelectedRows;
        setIsTransferLoading(true);
        const res = await postRequest(URL_EndPoints(null)?.transferCustomerAgent, transferPayload, setIsTransferLoading);
        if (res?.data?.status == 200 && setUpdatedCx) {
            const updatedValue = adminList?.find((ele: any) => ele?.id == transferPayload?.agentId)
            row.customerAssignedAgent = updatedValue
            setUpdatedCx(row)
            setShowAssignPopUp(false)
        }
        setIsTransferLoading && setIsTransferLoading(false)
        setToggleClearRows && setToggleClearRows(!toggledClearRows)
        toast?.[res?.data?.status == 200 ? "success" : "error"](res?.data?.message, { position: "top-center" })
        handleRefresh && handleRefresh()
        setFilteredSelectedRows && setFilteredSelectedRows([])

    }

    return (
        <>
            <Modal size='lg' show={showAssignPopUp} onHide={() => setShowAssignPopUp(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Transfer {selectedRowsCount} Customers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleTransferAgent}>
                        <Form.Group className="mb-3 d-flex flex-column gap-5" >
                            <SingleSelectSearchDetails
                                handleChangeInputData={(event: any, name: string) => setTransferPayload((prev) => ({ ...prev, [name]: event.value }))}
                                HeaderTitle='Select Agent'
                                SelectData={adminList}
                                DynamicKey={'first_name'}
                                DynamicKey2={'last_name'}
                                DynamicKey3={'phone'}
                                id={'id'}
                                name='agentId'
                                isLoading={apiLoader}
                            ></SingleSelectSearchDetails>
                            <Form.Check
                                type='checkbox'
                                label="Do you want to transfer tickets as well?"
                                style={{ paddingLeft: '3rem' }}
                                id='should-move-tickets'
                                onChange={(event: any) => setTransferPayload((prev) => ({ ...prev, 'shouldMoveTickets': event.target.checked }))}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex flex-end " >
                            {
                                isTransferLoading ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary">
                                        Transfer
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default BulkAssigAgentCustomerAgent
