import { CHAT_DATA, CHAMP_DATA } from '../../ActionTypes/ActionTypes';
export const ChatTicketID = (ChatTicketID: any) => {
    return { type: CHAT_DATA, payload: ChatTicketID }
}

export const champChatTicketID = (chatTicketId: any) => {
    return { type: "CHAMP_CHAT_DATA", payload: chatTicketId }
}

export const champDetails = (champData: any) => {
    return { type: CHAMP_DATA, payload: champData }
}

export const tableDataList = (payloads: any) => {
    return { type: "tableDataList", payload: payloads }
}
