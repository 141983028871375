import {CHAMP_DATA} from '../../ActionTypes/ActionTypes'
const initState = {
  champChatHistory: {},
 
}
export const ChampChatReducer = (state = initState, {type, payload}: any) => {
  switch (type) {
    case CHAMP_DATA:
      return {...state, champChatHistory: payload}
    default:
      return state
  }
}

// const champChatHistory = useSelector((store:any)=>store.ChampChatReducer.champChatHistory)


