import { faComment, faCommentAlt, faCommentDots, faCommentSlash, faCommentSms, faComments, faDashboard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { setIsChatOpenedOrNot } from '../../../modules/admins/adminUtils'
const BTNbody = ({ ticketid, ticketID, backgroundColors, HandleChipActivePaid, Title, Toggleid, classes, icons, externalCss, ExternalTitle, isGlobalSearch, IsGlobalSearchComponent, }: any) => {
    const BGColor = JSON.parse(localStorage.getItem("BTNbody") || "0") || ""
    const chatClick = () => {
        document.getElementById('kt_content_container')!.style.position = 'fixed'
        setIsChatOpenedOrNot(ticketID?.id, false, "customer_chat_opened")
    }

    let styels = {
        marginTop: '4px',
        marginRight: '2px',
    }

    let bgColor = backgroundColors === BGColor ? "primary text-white" : ""
    let bgColorExternalBtn = backgroundColors === ticketid?.id && externalCss == "reply" && "secondary"

    return (
        <>
            {
                Title === "External" ?
                    <div onClick={() => HandleChipActivePaid("external")} className={`badge text-hover-primary badge-${bgColorExternalBtn} `}>
                        <span onClick={chatClick}>{ExternalTitle}</span>
                    </div>
                    :
                    <div id={Toggleid ? Toggleid : ""} className={` text-dark badge badge-${bgColor}  cursor-pointer me-lg-1 ` + classes} onClick={() => HandleChipActivePaid("")} >
                        <div onClick={chatClick} className='d-flex justify-content-center align-items-center'>


                            {icons != "globalSearch" && <span>  <FontAwesomeIcon icon={faDashboard} className=' fs-6 me-2 mb-2 text-muted' style={styels} /></span>}

                            <span> {Title ? Title : "Not Set"} {isGlobalSearch && <IsGlobalSearchComponent />}</span>
                        </div>
                    </div>


            }
        </>
    )
}
export default BTNbody
