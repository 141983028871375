import React from 'react'
import DeleteIcon from '../../DeleteIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const IsSelectBox = ({ onChangeHandler, payload, index, keys, isData, filters, removeFilter }: any) => {
    return (

        <div className='d-flex justify-content-start mb-2 ' >

            <button className=' border border-light-secondary  btn-active-color-dark  bg-body btn-color-gray-700 me-2   text-muted p-1  mb-1 col-4	'>
                {filters?.title}
            </button>
            {
                <div >
                    <select
                        className=' bg-body btn-color-gray-700   px-2 selectBox mb-1 me-2'
                        onChange={(event: any) => onChangeHandler(event.target, keys, "operator")}
                        name=''
                        value={payload?.filterData[keys]?.operator ? payload?.filterData[keys]?.operator : "Select"}
                    >
                        {
                            isData?.map((ele: any) => {
                                return (
                                    <>
                                        < option value={ele.subKey} >{ele?.name}</option>
                                    </>
                                )
                            })
                        }
                    </select>

                </div>
            }

            <button onClick={() => removeFilter(index, filters?.key)} className='   me-2   text-muted p-0 px-0  mb-1  justify-end	ms-1 text-center ' style={{ position: "absolute", right: 0, top: 5, border: "none", background: "transparent", width: "35px", height: "30px" }}>

                <FontAwesomeIcon className='fs-2  text-hover-primary' onClick={() => removeFilter(index, filters?.key)} icon={faTrashAlt} />

            </button>
        </div>




    )
}

export default IsSelectBox
