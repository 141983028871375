import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { postRequest } from '../../../consts/Server/Requests'
import { toast } from 'react-toastify'

let bulkApproveData = ["Pending", "Approved", "Denied"]
const BulkApproveForm = ({ show, setShow, filteredSelectedRows, setToggleClearRows, toggledClearRows, setFilteredSelectedRows }: any) => {
    const [isLoadingSubmitAmount, setIsLoadingSubmitAmount] = useState(false)
    const [payload, setPayload] = useState<any>({
        "ids": null,
        "status": null,
    })

    const submitHandler = async () => {
        setIsLoadingSubmitAmount(true)
        let ids: any = []
        let c_ids: any = []
        filteredSelectedRows?.forEach((row: any) => {
            if (row?.champReferralRequest && row?.champReferralRequest?.id) {
                ids.push(row?.champReferralRequest?.id)
            }
            else {
                c_ids.push(row?.id)
            }
        })
        payload["ids"] = ids
        payload["c_ids"] = c_ids
        payload["list"] = "referral"
        if (!payload?.status) {
            toast.error("Status id is missing", { position: "top-center" })
            return
        }
        const response: any = await postRequest(URL_EndPoints(null)?.updateRequestForRewardInBulk, payload, setIsLoadingSubmitAmount)
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
        setToggleClearRows(!toggledClearRows)
        setShow(false)
        setFilteredSelectedRows && setFilteredSelectedRows([])
    }

    return (
        <>
            <Modal size='lg' show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Bulk Approve {filteredSelectedRows.length} Champs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <Form.Group className="mb-3" >
                            <div className='mb-2'>
                                {
                                    <select
                                        name='date_typer'
                                        data-control='select2'
                                        data-placeholder='Period'
                                        className='form-select form-select-sm '
                                        id='status'
                                        onChange={(event: any) => setPayload({ ...payload, [event?.target?.id]: event.target.value?.toLowerCase() })}
                                    >
                                        < option value="" >Select Approve Status</option>

                                        {
                                            bulkApproveData?.map((ele: any) => {
                                                return (
                                                    < option value={ele?.toLowerCase()} >{ele}</option>
                                                )
                                            })
                                        }
                                    </select>
                                }
                            </div>
                        </Form.Group>
                    </Form>
                    <Form.Group className="mb-3 p-2" >
                        {
                            isLoadingSubmitAmount ?
                                <Spinner animation={'border'} />
                                : <Button type='submit' variant="primary" disabled={isLoadingSubmitAmount} onClick={submitHandler}>
                                    Bulk Approve
                                </Button>
                        }
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BulkApproveForm
