import { LocalBaseURL } from "../../../../../BaseURLmanagement"
import React, { useCallback, useEffect, useState } from "react"
import { MenuComponent } from "../../../../../_metronic/assets/ts/components"
import { useNavigate } from "react-router"
import GChatBTN from "../../../../consts/Chats/ChatButtons/GChatBTN"
import DialogBox from "../DialogBox/DialogBox"
import ConfirmAlert from "../../../../consts/SweetAlert/ConfirmAlert"
import { useDispatch } from "react-redux"
import { Dialog } from "@mui/material"
import CreateNewTicketsJobList from "../CreateNewTicketsJobList"
import ConvertToFullCleaningDay from "../../../../consts/SweetAlert/ConvertToFullCleaningDay"
import { WrongReportingModal } from "./WrongReportingModal"
import GiveRewardPoints from "./GiveRewardPoints"
import UpdateJobVerificationStatus from "../UpdateJobVerificationStatus"
import DeletTrainingLocation from "../../../../consts/SweetAlert/DeletTrainingLocation"
import RevertNotAtHome from "../../../../consts/SweetAlert/RevertNotAtHome"
import { FEATURES, isFeatureAllowed } from "../../../../consts/Permissions"
import PermissionContextProvider from "../../../admins/adminComponents/PermissionContextProvider"
import { adminMenuActionPermissionKeys } from "../../../admins/adminConstant"
const HandleDropDown = (props: any) => {
  LocalBaseURL()
  const cleanerid = props?.props?.cleaner?.id
  const timeslotid = props?.props?.timeslot
  const filteredData = props.props
  const jobID = props?.props?.id
  const is_verified = props?.props?.is_verified
  const subscriptionid = props?.props?.neworderid
  const cleaneridSingle = props.props?.cleaner?.id
  const attendencedate = props?.props?.attendencedate
  const attendencedatedone = props?.props?.attendencedatedone
  const siteid = props?.props?.siteid
  const servicetype = props?.props?.servicetype
  const attendanceStatusData = props.props.attendenceStatus
  const packageid = props.props.subscriptionMonth?.packageid
  const customerid = props?.props?.customerids
  const userids = JSON.parse(localStorage.getItem("user") || "0")
  const [PopupUpdateStatusOpenClose, setPopupUpdateStatusOpenClose] = useState(false)
  const [PopupCreateTicketOpenClose, setPopupCreateTicketOpenClose] = useState(false)
  const [AttendanceID, setAttendanceID] = useState<any>(0)
  const [attendancestatusList, setattendancestatusList] = useState<any>({})
  const [destination, setDestination] = useState("")
  const [showAlertRevertReported, setShowAlertRevertReported] = useState<boolean>(false)
  const [show, setshow] = useState<boolean>(false)
  const [jobVerificationShowModal, setJobVerificationShowModal] = useState<boolean>(false)
  const [Renders, setRenders] = useState<any>(null)

  const [payload, setPayload] = useState<object>({})
  const navigate = useNavigate()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const handleReAssign = (cleaneridSingle: any) => {
    navigate(`/daily/job/assign/oldjob/${cleaneridSingle}`)
  }
  const handleEditAttendance = () => {
    setAttendanceID(jobID)
    setDestination("editAttendance")
    setPopupUpdateStatusOpenClose(!PopupUpdateStatusOpenClose)
  }
  const TicketCreateHandler = () => {
    setPopupCreateTicketOpenClose(!PopupCreateTicketOpenClose)
  }
  const handleChangeDate = (id: any) => {
    navigate(`/datechange/${siteid}/${servicetype}/${attendencedate}/${jobID}/${cleanerid}/${subscriptionid}`)
  }
  const convertTofullcleaningdayHandler = (id: any) => {
    setshow(true)
    setRenders(Math.random())
  }

  const handleUpdateStatus = (cleanerid: any, jobID: any, filteredData: any, attendanceStatusData: any) => {
    if (attendanceStatusData === null) {
      let attendancestatus = {
        attendence_id: jobID,
        customerid: filteredData?.customerids,
        user_id: userids,
        job_status_id: 0,
        job_action_id: 1,
        field_type_id: 1,
      }
      setattendancestatusList(attendancestatus)
    }
    else {
      let attendancestatus = {
        user_id: userids,
        attendence_id: jobID,
        customerid: filteredData?.customerids,
        job_status_id: 0,
        job_action_id: 1,
        field_type_id: 1,
      }
      setattendancestatusList(attendancestatus)
    }
    setDestination("updateStatus")
    setPopupUpdateStatusOpenClose(!PopupUpdateStatusOpenClose)
  }
  const revertReportedStatus = (status: boolean, attendence_id: any = 0, revert: boolean = false) => {
    if (revert) {
      const payloads2 = {
        user_id: userids,
        attendence_id: attendence_id,
      }
      setPayload({
        payloadData: payloads2,
        render: attendence_id,
        successTitle: "Successfully reverted.",
        FailedTitle: "Failed to revert reported status."
      })
    }
    setShowAlertRevertReported(status)
  }

  const HandleClose = () => {
    setPopupCreateTicketOpenClose(false)
  }

  const handleSets = useCallback(() => { setPopupCreateTicketOpenClose(false) }, [setPopupCreateTicketOpenClose])
  const [showWrongReportingForm, setShowWrongReportingForm] = useState(false)
  const [showGiveModal, setshowGiveModal] = useState(false)
  const [showNotAtHome, setShowNotAtHome] = useState<null | number | undefined>(null)

  return (
    <div >
      <>
        <button
          className='btn btn-sm btn-light btn-active-primary  fs-8 '
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </button>
        <div
          className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
          style={{
            width: "500px",
            zIndex: '105',
            position: 'fixed',
            inset: '0px 0px auto auto',
            margin: '0px',
            transform: 'translate(-59px, 440px)',
          }}
        >


          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-re-assign"]}>
            <div className='menu-item px-3'>
              <a
                href={`/daily/job/assign/oldjob/${cleaneridSingle}`}
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => handleReAssign(cleaneridSingle)}
              >
                Re-Assign
              </a>
            </div>
          </PermissionContextProvider>


          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-open-chat"]}>
            <div className='menu-item px-3'>
              <a
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
              >
                <GChatBTN
                  ticketDataParent={{
                    id: null,
                    customerid: +customerid
                  }}
                  Title={"Open Chat"}
                  Toggleid={"kt_drawer_chat2_toggle2"}
                  classes={"text-muted"}
                  icons={"commenting"}
                ></GChatBTN>
              </a>
            </div>
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-edit-attendance"]}>

            <div className='menu-item px-3'>
              <a
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => handleEditAttendance()}
              >
                Edit Attendance
              </a>
            </div>
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-create-ticket"]}>

            <div className='menu-item px-3'>
              <a
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => TicketCreateHandler()}
              >
                Create Ticket
              </a>
            </div>
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-update-status-report"]}>

            <div className='menu-item px-3'>
              <a
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => handleUpdateStatus(cleaneridSingle, jobID, filteredData, attendanceStatusData)}
              >
                Update Status/Report
              </a>
            </div>
          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-revert-reported-attendance"]}>


            <div className='menu-item px-3'>
              <a
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => revertReportedStatus(true, jobID, true)}
              >
                Revert Reported Attendance
              </a>
            </div>
          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-change-date-scheduling"]}>

            <div className='menu-item px-3'>
              <a
                href={`/datechange/${siteid}/${servicetype}/${attendencedate}/${jobID}/${cleanerid}/${subscriptionid}`}
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => handleChangeDate(cleaneridSingle)}
              >
                Change Date - Scheduling
              </a>
            </div>

          </PermissionContextProvider>
          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-fine-for-wrong-reporting"]}>

            <div className='menu-item px-3'>
              <a
                href="#"
                className='menu-link  px-3'
                onClick={() => setShowWrongReportingForm(true)}
              >
                Fine For Wrong Reporting
              </a>
            </div>

          </PermissionContextProvider>


          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-give-rewards"]}>

            <div className='menu-item px-3'>
              <a
                href="#"
                className='menu-link  px-3'
                onClick={() => setshowGiveModal(true)}
              >
                Give Reward Point
              </a>
            </div>

          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-update-job-verification-status"]}>

            <div className='menu-item px-3'>
              <a
                href="#"
                className='menu-link  px-3'
                onClick={() => setJobVerificationShowModal(true)}
              >
                Update Job Verification Status
              </a>
            </div>

          </PermissionContextProvider>


          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-convert-to-full-cleaning-day"]}>

            {
              packageid == 31 && servicetype != 2 && attendencedatedone == "" ?
                <div className='menu-item px-3'>
                  <a
                    className='menu-link  px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => convertTofullcleaningdayHandler(cleaneridSingle)}
                  >
                    Convert to Fullcleaning Day
                    <ConvertToFullCleaningDay />
                    {
                      show ?
                        <ConvertToFullCleaningDay render={Renders} confirm={'Yes'} cancel={'No'} payloads={{
                          jobId: jobID,
                          subscriptionId: subscriptionid,
                        }}
                          setshow={setshow}
                          title={`Are you sure want to update`} /> : ""
                    }
                  </a>
                </div>
                : <></>
            }

          </PermissionContextProvider>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["job-list-not-at-home"]}>

            {
               <div className='menu-item px-3'>
                <a
                  href="#"
                  className='menu-link  px-3'
                  onClick={() => setShowNotAtHome(200)}
                >
                  Revert not at home
                </a>
              </div>
            }

          </PermissionContextProvider>






        </div>


        {PopupUpdateStatusOpenClose && <DialogBox AttendanceID={AttendanceID} destination={destination} handleDiloagboxform={handleUpdateStatus} PopupUpdateStatusOpenClose={PopupUpdateStatusOpenClose} attendancestatusList={attendancestatusList} cleanerid={cleaneridSingle} timeslotid={timeslotid} />}

        {showAlertRevertReported ?
          <ConfirmAlert confirm={'Yes'} cancel={'No'} payloads={payload} title={'Are you sure to revert the reported status ?'} /> : ""}
        {
          PopupCreateTicketOpenClose &&

          <Dialog
            open={true}
            onClose={HandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {<CreateNewTicketsJobList handleCloseForm={handleSets} defaultTicketData={props?.props} />}
          </Dialog>
        }
      </>

      {showWrongReportingForm && <WrongReportingModal showWrongReportingForm={showWrongReportingForm} setShowWrongReportingForm={setShowWrongReportingForm} row={filteredData} />}
      {showGiveModal && <GiveRewardPoints show={showGiveModal} setShow={setshowGiveModal} row={filteredData} />}

      {
        jobVerificationShowModal ? <UpdateJobVerificationStatus show={jobVerificationShowModal} setShow={setJobVerificationShowModal} jobID={jobID} is_verified={is_verified} /> : <></>
      }

      {
        showNotAtHome ? <RevertNotAtHome
          confirm='Yes'
          cancel='No'
          successTitle='Changes Are Saved Successfully '
          FailedTitle='Changes Cancelled '
          payload={{
            jobId: jobID
          }}
          setShow={setShowNotAtHome}
          show={showNotAtHome}
          InvokedRendered={null}
        ></RevertNotAtHome > : <></>
      }
    </div>
  )
}
export default HandleDropDown


