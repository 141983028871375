import { faDeleteLeft, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { postRequest, postRequestWithFile } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { toast } from 'react-toastify';

const FileUploadEdit = ({ url = "", payload = {}, btnTitle = "", setShow, row = {}, documentData = {}, documentTypeList = [], loadInitialData, isAciveOrInactiveDocument = false }: any) => {

    const [fileSelected, setFileSelected] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [fileType, setFileType] = useState<any>(null);
    const [imageUploadLoader, setImageUploadLoader] = useState<any>(false);
    const [documentInactiveType, setDocumentInactiveType] = useState<any>(null)

    useEffect(() => {
        const currentDoctype = documentTypeList?.find((item: any) => item?.name == documentData?.docname)
        currentDoctype && setFileType(currentDoctype?.id)
    }, [documentTypeList, documentData?.id, documentData?.docname])


    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setFileSelected(true);
        setFile(e?.dataTransfer?.files[0]);
    };

    const onChangeFile = (event: any) => {
        setFile(event?.target?.files?.[0]);
        setFileSelected(true);
    };

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const fileUploadHandler = async () => {
        setImageUploadLoader(true)
        let newPayload = { ...payload }
        newPayload.champId = row?.id
        newPayload.documentType = fileType
        newPayload.document = file
        const response = await postRequestWithFile(URL_EndPoints()?.[url], newPayload, setImageUploadLoader)
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
        loadInitialData(isAciveOrInactiveDocument ? 1 : 0)
        setShow(false)
    }

    const inactiveDocumentHandler = (event: any) => {
        setDocumentInactiveType(event?.target?.value)
        payload.upload = event?.target.value == "1" ? true : false
    }

    return (
        <>
            <div className="col-lg-12 max-auto">
                <select
                    name='documentInactiveType'
                    aria-label='Select a Timezone'
                    data-control='select2'
                    data-placeholder='Period'
                    className='form-select mb-3 text-muted'
                    value={documentInactiveType}
                    onChange={inactiveDocumentHandler}

                >
                    < option value="">Choose Option To Inactive</option>
                    {
                        [{ name: "Upload document to mark as inactive", id: 1 }, { name: "Mark document as inactive", id: 2 }]?.map((ele: any) => {
                            return (
                                <>
                                    < option value={ele.id}>{ele?.name}</option>
                                </>
                            )
                        })
                    }
                </select>
                {
                    documentInactiveType == "1" && <>
                        <Form.Group className="mb-3" onDragOver={handleDragOver} onDrop={handleDrop}>
                            <input type="file" key={12121} name="imag2" id="imag2" onChange={onChangeFile} style={{ display: 'none' }} />
                            <label htmlFor="imag2" style={{ width: "100%", height: "90%" }}>
                                <div className=" col-12 ">
                                    <div className="dz-default dz-message text-center d-flex flex-column g-2 dropzone dz-clickable border rounded bg-light p-3">
                                        <i className="bi bi-cloud-arrow-up mb-4" style={{ fontSize: "5rem" }}></i>
                                        <span className='fw-bold fs-3 text-muted'>Drop files here or click to upload...</span>
                                    </div>
                                </div>
                            </label>
                        </Form.Group>
                        {/* <!-- Uploaded image area--> */}
                        {
                            fileSelected && file && <div className=" d-flex justify-content-between mb-3 px-2 py-2 bg-white shadow-sm overflow-hidden">
                                <label id="" className="font-weight-light text-success p-2 fs-6"> {file?.name} ({(file?.size / (1024 * 1024)).toFixed(2)}MB)</label>
                                <label onClick={CancelImage} id="" className=" cursor-pointer font-weight-light text-muted mt-2  "><FontAwesomeIcon icon={faDeleteLeft} className="text-danger  fs-2" /></label>
                            </div>
                        }

                    </>
                }
                {
                    documentInactiveType == "2" && <>
                        <Form.Label className='mb-2 text-muted fw-bold'>Note : You do not need to do anything for this option...</Form.Label>
                        <Form.Label className='mb-4 text-muted fw-bold'>Just Hit 👉 Make Document Inactive Button </Form.Label>
                    </>
                }

                {
                    documentInactiveType && <div className="d-flex justify-content-end text-end">
                        <button onClick={fileUploadHandler} className="btn btn-sm px-3 btn-primary" disabled={imageUploadLoader}>{imageUploadLoader ? "Uploading..." : btnTitle}</button>
                    </div>
                }

            </div>


        </>
    )
}

export default FileUploadEdit
