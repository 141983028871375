import React from 'react'
const AdminSelectTextBox = ({ onChangeHandler = () => { }, HeaderTitle = "", payload = {}, name = "", inputType = "text", label = "", }: any) => {
  return (
    <input
      type={inputType}
      style={{ height: '38px' }}
      value={payload[name]}
      name={name}
      placeholder={HeaderTitle}
      onChange={(event: any) => onChangeHandler({ value: inputType == "number" ? event.target?.value ? Number(event.target?.value) : null : event.target?.value }, name, false)}
      className='w-100 form-control me-2 align-start'
    />
  )
}
export default AdminSelectTextBox

