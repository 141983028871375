



import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postRequest } from '../../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit } from '@fortawesome/free-solid-svg-icons';


const ReferralBonusAmountUpdate = ({ row }: any) => {
    const isVacancyAssigned = row?.champ_requirement_completed_champ?.first_name + row?.champ_requirement_completed_champ?.last_name

    const [show, setShow] = useState<any>(false)
    const [loader, setLoader] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [payload, setPayload] = useState<any>({
        "id": row?.id,
        "updateFields": {
            referral_amount: null
        }
    })

    useEffect(() => { setStoreRow(row) }, [row])



    const updateRequirementType = async () => {

        if (payload?.updateFields?.referral_amount) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints(null)?.updateChampRequirement, payload, setLoader)
            if (response?.data?.status === 200) {
                row.referral_bonus_amount = payload?.updateFields?.referral_amount
                setStoreRow(row)
                toast.success(response?.data?.msg, { position: "top-center" })
                setShow(false)

            } else {
                toast.error(response?.data?.msg ? response?.data?.msg : "Seems server side problem", { position: "top-center" })
                setShow(false)


            }
            setLoader(false)
        }
        else {
            toast.error("Request field is empty ", { position: "top-center" })
            setLoader(false)

        }
    }

    const modalHandler = (isVacancyFilledBy: any) => {

        // if (isVacancyFilledBy) {
        //   toast.success("Vacancy already filled so action is not allowed !", { position: "top-center" })
        //   return
        // }
        setShow(true)
    
      }

    return (

        <>
            {
                loader ? "Updating..." : <>
                    <FontAwesomeIcon icon={ faEdit} className={` me-2 cursor-pointer text-${ "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} />
                    {/* <FontAwesomeIcon icon={isVacancyAssigned ? faBan : faEdit} className={` me-2 cursor-pointer text-${isVacancyAssigned ? "danger" : "dark"}`} onClick={() => modalHandler(isVacancyAssigned)} /> */}
                    <span>{storeRow?.referral_bonus_amount ? storeRow?.referral_bonus_amount : "NA"}</span></>
            }

            <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-small">
                <Modal.Header closeButton>
                    <Modal.Title>Update Referral Bonus Amount   </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-3">
                        <div className="col-12 mb-2">
                            <input
                                type='number'
                                data-kt-user-table-filter='search'
                                className='form-control  bg-white'
                                placeholder='Min Payout'
                                value={payload?.updateFields?.referral_amount}
                                defaultValue={storeRow?.referral_bonus_amount}
                                onChange={(event) => setPayload({ ...payload, ["updateFields"]: { ["referral_amount"]: event?.target?.value } })}
                            />
                        </div>

                    </div>


                    <div className='d-flex flex-end justify-content-end col-12'>
                        <button
                            className={`btn btn-sm text-${loader ? "dark" : "white"} btn-${loader ? "success" : "primary"} mb-5 `}
                            onClick={updateRequirementType}
                            disabled={loader}
                        >
                            {loader ? "Updating..." : "Update"}

                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        </>



    )
}
export default ReferralBonusAmountUpdate
