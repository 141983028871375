import React, { memo } from 'react';
import { withSwal as CleanerAssigning } from 'react-sweetalert2';
import { postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { toast } from 'react-toastify';
export default CleanerAssigning((props: any) => {
    const { swal, confirm, cancel, payloads, title, render, handleCloseModalCleaner }: any = props;
    React.useEffect(() => {
        async function load() {
            const response = await postRequest(URL_EndPoints(null)?.changecleanerofcustomer, payloads, null)
            if (response?.data?.msg) {
                toast.success("Updated Successfully")
                handleCloseModalCleaner()
            }
        }

        if (render) {
            swal.fire({
                title: title,
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel,
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    load()
                } else if (result.isDenied) {
                    handleCloseModalCleaner()
                    swal.fire("Request failed ")
                }
            })
        }
    }, [render])
});
