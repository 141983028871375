import React, { useEffect,  useState } from 'react'
import {  Modal, } from 'react-bootstrap'
import WrapperLayer from '../../../consts/champChatDashboard/WrapperLayer'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import moment from 'moment'

const keys: any = {
    2: "verified_job_supervisor",
    5: "verified_job_admin",
}
const JobHistoryList = ({ row, show, setShow }: any) => {
    const [loader, setLoader] = useState(false)
    const [filterData, setFilterData] = useState<any>([])
    const laodInitialData = async (JobId: any) => {
        setLoader(true)
        const result = await postRequest(URL_EndPoints()?.getJobVerificationHistory, {
            job_id: JobId
        }, setLoader)
        setFilterData(result?.data?.data || [])
    }
    useEffect(() => {
        show && laodInitialData(row?.id)
    }, [row?.id, show])

    return (
        <>
            <Modal size='xl' show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Job Edit History List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WrapperLayer>
                        {
                            loader ? <IsLoading /> :
                                <div className='px-3 mt-4  table-responsive'>
                                    <table className="table table-bordered table-hover table-sm">
                                        <thead>
                                            <tr className='border px-3 bg-secondary'>
                                                <th scope="col">NAME</th>
                                                <th scope="col">COMMENT</th>
                                                <th scope="col">VERIFICATION DATE</th>
                                                <th scope="col">IS VERIFIED</th>
                                                <th scope="col">USER TYPE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterData?.length > 0 && filterData?.map((element: any) => {
                                                return (
                                                    <tr className={"border"} >
                                                        <td scope="row">{keys[element?.user_type] ? element[keys[element?.user_type]]?.first_name + " " + element[keys[element?.user_type]]?.last_name : "NA"}</td>
                                                        <td >{element?.comment ? element?.comment : "NA"}</td>
                                                        <td >{element?.verification_date ? moment(element?.verification_date).format("YYYY-MM-DD") : "NA"}</td>
                                                        <td > {element?.is_verified == 0 ? <span className='text-danger'>Not Verified</span> : <span className='text-success'>Verified</span>} </td>
                                                        <td >{
                                                            element?.user_type == 2 ? "Supervisor" :
                                                                element?.user_type == 5 ? "Admin" :
                                                                    ""}</td>
                                                    </tr>
                                                )
                                            }
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </WrapperLayer>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default JobHistoryList






