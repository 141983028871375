
import "./menuStyle.css"

const MainContainerWrapper = ({ children }: any) => {

    return (
        <div className='w-100 d-flex justify-content-between mb-3'>
            {children}
        </div>
    )
}

export default MainContainerWrapper
