import React from 'react'
const AdminSelectTextAreaBox = ({ onChangeHandler = () => { }, HeaderTitle = "", payload = {}, name = "", label = "",  }: any) => {

  return (
        <textarea
          className="form-control"
          name={name}
          placeholder={HeaderTitle}
          rows={3}
          value={payload[name]}
          onChange={(event) => onChangeHandler(event.target, name, false)}
        ></textarea>
  )
}
export default AdminSelectTextAreaBox

