import StatisticsDashboard from "./components/StatisticsDashboard"
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from "../_metronic/layout/core"
import { getRequest, postRequest } from "../app/consts/Server/Requests"
import { URL_EndPoints } from "../app/consts/Server/URL_EndPoints"
import MomentDate from "../app/consts/MomentDate"

import "./Cssss.css"
import { FEATURES, isFeatureAllowed } from "../app/consts/Permissions"
import CronHistory from "../app/modules/cronHistory/CronHistory"


const Dashboard = ({ isDashboard }: any) => {
    const [stats, setStats] = useState<any>([])
    const [Loader, setLoader] = useState<any>(false)
    const [date, setData] = useState(MomentDate(0))

    async function invokedStats() {
        setLoader(true)
        const response = await postRequest(URL_EndPoints()?.getCustomerStats, {
            "startDate": date
        }, setLoader)
        setStats(response?.data?.data)
    }

    useEffect(() => {
        isDashboard && invokedStats()
    }, [isDashboard])



    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xl-12'>
                    {
                        isDashboard ? <StatisticsDashboard date={date} setData={setData} Active_Subscriptions={stats?.length > 0 && stats[0]?.activeSubscriptions} className='card-xl-stretch mb-5 mb-xl-8' items={5} New_Vehicle_Onboards={stats[0]?.newVehicleOnboards} Reactivated_Subscription={stats[0]?.reactivateSubscription} Same_Day_Started_Subscriptions={stats[0]?.sameStartDateSubscriptions} Renewed_Subscription={stats[0]?.renewedSubscription} Upcoming_Subscriptions={stats[0]?.upcomingSubscriptions
                        } Pause_Subscriptions={stats?.length > 0 && stats[0]?.pauseSubscriptions} Future_Pause={stats?.length > 0 && stats[0]?.futurePauseSubscriptions
                        } Future_Inactive={stats?.length > 0 && stats[0]?.futureInactiveSubscriptions} Inactive_Subscriptions={stats?.length > 0 && stats[0]?.inactiveSubscriptions} Loader={Loader} invokedStats={invokedStats} /> : <div className="" style={{ width: "100%", height: "100%" }}>

                            {
                                isFeatureAllowed(FEATURES.cronHistory) ? <>
                                    <div style={{ width: "200px", height: "50px", margin: "auto" }}>
                                        <div className="fs-1 fw-bold mt-4 flex-start mb-10 position-relative">  Welcome to
                                            <img style={{ position: "absolute", bottom: "-8px", right: "-145px", width: "100%", height: "100%" }} src="https://www.carselonadaily.com/wp-content/uploads/2019/06/logo_black.png" alt="" />
                                        </div>
                                    </div>
                                    {<CronHistory />}
                                </> : <>
                                    <div>
                                        <h1 className="fs-1 fw-bold mt-4 flex-start mb-10  text-muted">  Welcome to </h1>
                                        <img src="https://www.carselonadaily.com/wp-content/uploads/2019/06/logo_black.png" alt="" style={{ width: "90%", height: "50%" }} />
                                    </div>
                                </>
                            }


                        </div>
                    }
                </div>
            </div>
        </>
    )
}

const DashboardWrapper = ({ isDashboard }: any) => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <Dashboard isDashboard={isDashboard} />
        </>
    )
}

export default DashboardWrapper
