import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
const SingleSearchableFilter = (props: any) => {
  const { handleChangeInputData, HeaderTitle, SelectData, DynamicKeys, id, name, defaultData, setSeacrch, isLoading = false, targetValue, timestamp, index, multiVal, isSearchable, filters } = props

  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      const newData = {
        label: DynamicKeys?.map((key: any) => !ele[key] ? "NA" : ele[key]).join(" "),
        value: ele[id],
        item: ele
      }
      return newData
    })

    setShowData(updatedData)
  }, [SelectData])

  const debouncing = (event: any) => {
    setSeacrch(event, filters)
  }

  return (
    <div className='me-2 w-100 form-select-sm ' key={index} >
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        onInputChange={setSeacrch && isSearchable ? (e: any) => debouncing(e) : undefined}
        options={showData}
        onChange={(event) => handleChangeInputData(event, name, targetValue, true, timestamp, multiVal)}
        defaultValue={defaultData && defaultData}
        styles={colourStyles2}
        isLoading={isLoading}
      />
    </div>
  )
}


export default SingleSearchableFilter

