import React from 'react'
import Multiselect from 'multiselect-react-dropdown';
const ReactDropdownMultiSelect = ({ handleChangeInputData, HeaderTitle, SelectData, DynamicKey, DynamicKey2, DynamicKey3, id, name, defaultData }: any) => {

  const onSelect = (selectedList: any, selectedItem: any) => {
    handleChangeInputData(selectedList?.map((ele: any) => ele.id), name)
  }
  const onRemove = (selectedList: any, selectedItem: any) => {
    handleChangeInputData(selectedList?.map((ele: any) => ele.id), name)
  }
  return (
    <Multiselect
    placeholder={HeaderTitle}

      options={SelectData} // Options to display in the dropdown
      selectedValues={defaultData} // Preselected value to persist in dropdown
      onSelect={onSelect} // Function will trigger on select event
      onRemove={onRemove} // Function will trigger on remove event
      displayValue="name" // Property name to display in the dropdown options
      style={css}
      singleSelect={false}
    />
  )
}
export default ReactDropdownMultiSelect
const css = {
  searchBox: {
        border: "1px solid whitesmoke",


    // fontSize:"5px"
  },
  // inputField: { // To change input field position or margin
  //     margin: "5px",
  // },
  // chips: { // To change css chips(Selected options)
  // background: "red",
  // },
  // optionContainer: { // To change css for option container 
  // border: "2px solid",
  // },
  // option: { // To change css for dropdown options
  // color: "blue",
  // },
}