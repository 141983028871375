import moment from 'moment';
import { TableColumn } from 'react-data-table-component';
export const columns: TableColumn<any>[] = [
    {
        id: 0,
        reorder: true,
        name: "ID",
        cell: (row: any) => row?.id,
        sortable: true,
    },
    // {
    //     id: 12,
    //     reorder: true,
    //     name: "BENEFICIERY NAME",
    //     cell: (row: any) => row?.beneficiary_account_name,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 13,
    //     reorder: true,
    //     name: "BENEFICIRY ACCOUNT NO.",
    //     cell: (row: any) => row?.beneficiary_account_number,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 14,
    //     reorder: true,
    //     name: "BENEFICIRY IFSC",
    //     cell: (row: any) => row?.beneficiary_account_ifsc,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 15,
    //     reorder: true,
    //     name: "BENFICIERY UPI",
    //     cell: (row: any) => row?.beneficiary_upi_handle,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 11,
    //     reorder: true,
    //     name: "BAENEF. BANK NAME",
    //     cell: (row: any) => row?.beneficiary_bank_name,
    //     sortable: true,
    //     width: "200px",
    // },
    {
        id: 21,
        reorder: true,
        name: "TRANSFER DATE",
        cell: (row: any) => moment(row?.transfer_date).format("YYYY-MM-DD"),
        sortable: true,
        width: "200px",
    },
    {
        id: 8,
        reorder: true,
        name: "AMOUNT",
        cell: (row: any) => row?.amount,
        sortable: true,
        width: "200px",
    },
    {
        id: 7,
        reorder: true,
        name: "PAYMENT MODE",
        cell: (row: any) => row?.payment_mode,
        sortable: true,
        width: "200px",
    },
    {
        id: 5,
        reorder: true,
        name: "PAYMENT CREATED",
        cell: (row: any) =>  moment(row?.created_at).format("YYYY-MM-DD"),
        sortable: true,
        width: "200px",
    },
    {
        id: 10,
        reorder: true,
        name: "NARRATION",
        cell: (row: any) => row?.narration,
        sortable: true,
        width: "200px",
    },
 
    {
        id: 19,
        reorder: true,
        name: "STATUS",
        cell: (row: any) => row?.status,
        sortable: true,
        width: "200px",
    },
  
    {
        id: 2,
        reorder: true,
        name: "TRANSACTION ID",
        cell: (row: any) => row?.txn_id,
        sortable: true,
        width: "200px",
    },
    {
        id: 6,
        reorder: true,
        name: "UNIQUE TRANS. REFRENCE",
        cell: (row: any) => row?.unique_transaction_reference,
        sortable: true,
        width: "150px",
    },
    {
        id: 28,
        reorder: true,
        name: "ACTION BY USER ID",
        cell: (row: any) => row?.easebuzz_action_user?.first_name || row?.easebuzz_action_user?.last_name ? row?.easebuzz_action_user?.first_name + " " + row?.easebuzz_action_user?.last_name : "NA",
        sortable: true,
        width: "200px",
    },
    {
        id: 29,
        reorder: true,
        name: "ACTION BY USER TYPE",
        cell: (row: any) => row?.easebuzz_action_user?.role ? row?.easebuzz_action_user?.role : "NA",
        sortable: true,
        width: "200px",
    },
    {
        id: 1,
        reorder: true,
        name: "FAILURE REASON",
        cell: (row: any) => row?.failure_reason,
        sortable: true,
        width: "200px",
    },
    {
        id: 30,
        reorder: true,
        name: "CREATED AT",
        cell: (row: any) => moment(row?.createdAt).format("YYYY-MM-DD"),
        sortable: true,
        width: "200px",
    },

    // {
    //     id: 3,
    //     reorder: true,
    //     name: "VIRTUAL ACCOUNT",
    //     cell: (row: any) => row?.source_virtual_account,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 4,
    //     reorder: true,
    //     name: "BENEFICIERY ID",
    //     cell: (row: any) => row?.beneficiary_id,
    //     sortable: true,
    //     width: "200px",
    // },

  
  
 
    // {
    //     id: 9,
    //     reorder: true,
    //     name: "CURRENCY",
    //     cell: (row: any) => row?.currency,
    //     sortable: true,
    //     width: "200px",
    // },


    // {
    //     id: 16,
    //     reorder: true,
    //     name: "SERVICE CHARGE",
    //     cell: (row: any) => row?.service_charge,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 17,
    //     reorder: true,
    //     name: "GST AMOUNT",
    //     cell: (row: any) => row?.gst_amount,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 18,
    //     reorder: true,
    //     name: "SERVICE CHARGE WITH GST",
    //     cell: (row: any) => row?.service_charge_with_gst,
    //     sortable: true,
    //     width: "200px",
    // },

    // {
    //     id: 20,
    //     reorder: true,
    //     name: "QUEUE LOW BALANCE",
    //     cell: (row: any) => row?.queue_on_low_balance,
    //     sortable: true,
    //     width: "200px",
    // },

    // {
    //     id: 22,
    //     reorder: true,
    //     name: "UDF1",
    //     cell: (row: any) => row?.udf1,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 23,
    //     reorder: true,
    //     name: "UDF2",
    //     cell: (row: any) => row?.udf2,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 24,
    //     reorder: true,
    //     name: "UDF3",
    //     cell: (row: any) => row?.udf3,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 25,
    //     reorder: true,
    //     name: "UDF4",
    //     cell: (row: any) => row?.udf4,
    //     sortable: true,
    //     width: "200px",
    // },
    // {
    //     id: 26,
    //     reorder: true,
    //     name: "UDF1",
    //     cell: (row: any) => row?.udf5,
    //     sortable: true,
    //     width: "200px",
    // },
 

    // {
    //     id: 31,
    //     reorder: true,
    //     name: "UPDATED AT",
    //     cell: (row: any) => moment(row?.updatedAt).format("YYYY-MM-DD"),
    //     sortable: true,
    //     width: "200px",
    // },

];
