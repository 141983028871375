import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as XLSX from 'xlsx';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

interface ExcelExportButtonProps {
    tableName: string;
}

const ExcelExportButton: React.FC<ExcelExportButtonProps> = ({ tableName }) => {

    const exportToExcel = () => {
        // Extract headers
        const columnHeaders = document.querySelectorAll(`${tableName} thead th`) || [];
        let headers = Array.from(columnHeaders).map((header) => header.textContent?.trim() || '');
        let newHeaderData: any = []
        let temp = false

        if (headers?.length > 0) {
            for (let index = 0; index < headers.length; index++) {
                const element = headers[index];
                if (element !== '' && temp === false) {
                    newHeaderData.push(element);
                }
                if (element === '' && index !== 0) {
                    temp = true;
                    break;
                }
            }
        }
        // Extract rows
        const rows = document.querySelectorAll(`${tableName} tbody tr`) || [];
        const rowData = Array.from(rows).map((row) => {
            const cells = row.querySelectorAll('td');
            return Array.from(cells).map((cell) => cell.textContent?.trim() || '');
        })

        const halfLength = Math.ceil(rowData.length / 2);
        const newRowData = rowData.slice(0, halfLength);

        // Combine headers and row data
        const sheetData = [newHeaderData, ...newRowData];
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Create Excel file
        const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
        saveAsExcelFile(excelBuffer, `${tableName}.xlsx`);
    };

    const saveAsExcelFile = (buffer: any, fileName: string) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(data);
        downloadLink.download = fileName;
        downloadLink.click();
    };

    return (
        <button className='btn btn-primary btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary btn-active-secondary p-3 px-3 filterBtn me-2' onClick={exportToExcel}>
            <FontAwesomeIcon icon={faFileExport} className='me-1' /> Export
        </button>
    );
};

export default ExcelExportButton;
