import React from 'react'
const AdminMultiDateFilter = ({ onChangeHandler, HeaderTitle = "", payload = "", from = "", to = "", label = "" }: any) => {

  return (
    <div className='row mb-2 mb-md-0'>
      <div className='col-md-6 mb-md-2'>
        <input
          type='date'
          style={{ height: '38px' }}
          value={payload[from]}
          name={from}
          onChange={(event: any) =>
            onChangeHandler(event.target, from, true)
          }
          className='w-100 form-control me-2 align-start'
        />
      </div>
      <div className='col-md-6'>
        <input
          type='date'
          style={{ height: '38px' }}
          name={to}
          value={payload[to]}
          onChange={(event: any) =>
            onChangeHandler(event.target, to, true)
          }
          className='w-100 form-control me-2 align-start'
        />
      </div>
    </div>
  )
}
export default AdminMultiDateFilter

