
import React, { useEffect, useState } from 'react'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import SingleSearchCategory from '../../../consts/Select/SingleSearchCategory'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import Not_Found from '../../../consts/Not_Found'
import "./Cleaner.css"
import { Button, Modal, Row } from 'react-bootstrap'
import SingleSelectSearchDetails from '../../../consts/Select/SingleSelectSearchDetails'
import { image_Cloudfront } from '../../../consts/components/Utility'
import MapMultiSelect from '../../../consts/Select/MapMultiSelect'
import SearchAreaOnMap from './SearchAreaOnMap'
import GoogleIcon from "./clipart290500.png"

const CompanyRequirementMap = () => {
  const [cityData, setCityData] = useState([])
  const [circleChamps, setCircleChamps] = useState([])
  const [champData, setchampData] = useState<any>([])
  const [cityid, setcityid] = useState<any>(0)
  const [Loader, setLoader] = useState<any>(false)
  const [map, setMap] = useState<any>(null);
  const [radius, setRadius] = useState<any>(1000)
  const [show, setShow] = useState(false);
  const [supervisorId, setSupervisorId] = useState<any>(0);
  const [areaName, setAreaName] = useState<any>("");
  const [centerLatLong, setCenterLatLong] = useState({
    lat: 12.9121181,
    lng: 77.6445548,
  })

  const [markerPositionLatLng, setMarkerPositionLatLng] = useState<any>(null)
  const [customAddressSearch, setCustomAddressSearch] = useState("")

  useEffect(() => {
    markerPositionLatLng?.lat && setCenterLatLong(
      {
        lat: markerPositionLatLng?.lat,
        lng: markerPositionLatLng?.lng,
      }
    )
  }, [markerPositionLatLng?.lat, markerPositionLatLng?.lng])

  const [Supervisordata, setSupervisordata] = React.useState<any>([])
  const handleClose = () => setShow(false);
  async function loadInitialData() {
    // setLoader(true)
    const result = await getRequest(URL_EndPoints()?.getcity, null)
    setCityData(result?.data?.data)

    const supervisorData = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
    setSupervisordata(supervisorData?.data?.data)

    setLoader(false)
  }
  async function loadChampData() {
    setLoader(true)
    const result = await postRequest(URL_EndPoints()?.mapChampRequirementsLocation, {
      // "cityid": cityid,
      // "supervisorId": supervisorId
      "start": 1,
      "length": 10,
      "search": "",
      "sortby": "",
      "direction": "",
      "filterData": {}


    }, setLoader)
    setLoader(false)
    setchampData(result?.data?.data)
  }

  // load city data 
  useEffect(() => {
    loadInitialData()
  }, [])

  const handleSearch = () => {
    setCenterLatLong({ lat: 12.9121181, lng: 77.6445548, })
  }


  useEffect(() => {
    loadChampData()
  }, [])
  // city handler  
  const cityChangeHandler = (event: any, name: any) => {
    if (event !== null) {
      setcityid(event?.value)
    }
    else {
      setcityid(null)
    }
  }


  const center = {
    lat: 12.9121181,
    lng: 77.6445548,
  };

  let brnchUrl = `${image_Cloudfront}351f2fa6-07b0-4668-9469-dac59255d3d0.png`

  let jobLeftUrl = `https://img.favpng.com/20/21/6/janaki-furniture-furnishing-house-customer-product-png-favpng-7SBmmi1rDLT6ZNWhtbqM9X852_t.jpg`

  let fultItmeUrl = `https://img.favpng.com/0/15/10/google-maps-google-map-maker-computer-icons-house-png-favpng-e46wqcvLcMYam3a7sGYU3K5ws_t.jpg`
  let searchedMarker = GoogleIcon

  const handleChangeSupervisor = (event: any) => {
    setSupervisorId(event)
    // event && event.value ? setSupervisorId(event?.value) : setSupervisorId(0)
  }


  useEffect(() => {
    // Create a new Google map
    let activeInfoWindow: any = null
    function rendered() {
      const map = new window.google.maps.Map(document.getElementById('map') as any, {
        center: centerLatLong,
        zoom: 11
      });
      setMap(map);


      let allMarkers: any = []
      // Loop through the markers array and add each marker and infowindow to the map
      champData.forEach((markerInfo: any) => {
       
        const defaultMakerColor = {
          url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
          scaledSize: new window.google.maps.Size(20, 30),
        }


        const MarkerColors: any = {
          high: {
            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
            scaledSize: new window.google.maps.Size(20, 30),
          },
          medium: {
            url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
            scaledSize: new window.google.maps.Size(20, 30),
          },
          low: {
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            scaledSize: new window.google.maps.Size(20, 30),
          },
        }

        const MarkerConditonColor: any = {
          high: { color: "red" },
          medium: { color: "green" },
          low: { color: "blue" },
        }

        

        const marker = new window.google.maps.Marker({
          position: {
            lat: +markerInfo?.trainingLocation?.latitude,
            lng: +markerInfo?.trainingLocation?.longitude,
          },
          map: map,
          title: "",
          // icon: markerInfo?.private_tag?.toLowerCase()?.includes("super champ") ? superChamp
          //   : markerInfo?.private_tag?.toLowerCase()?.includes("job left") ? jobLeft
          //     : markerInfo?.private_tag?.toLowerCase()?.includes("full time") ? fullTime
          //       : smallMarkerIcon,
          icon: MarkerColors[markerInfo.marker_details?.priority?.toLowerCase()] ? MarkerColors[markerInfo.marker_details?.priority?.toLowerCase()]:  defaultMakerColor,
          zIndex: 1,
        });

        marker.set('champData', markerInfo);

        allMarkers.push(marker)
        const commonContent = `
        Number Of Requirement: ${markerInfo.count}<br>
        Priority: ${markerInfo.marker_details?.priority}<br>
        Location Name: ${markerInfo?.trainingLocation?.locationname ?? "NA"}<hr>
        `;


        const infowindow = new window.google.maps.InfoWindow({
          content: commonContent + markerInfo?.requirements?.map((ele: any) => (`
          <div >
          <span style="font-weight-700">Requirement Type:</span> ${ele?.requirementType?.name ?? "NA"}<br>
          <span >Priority:</span>  <span style="color: ${MarkerConditonColor[ele?.priority_level?.priority?.toLowerCase()]?.color}; font-weight:1000"> ${ele?.priority_level?.priority ?? "NA"} </span> <br/>
          <span >Area Name:</span> ${ele?.champ_requirement_area?.areaname ?? "NA"}<hr>
        </div>
        `)).join("")
        });

        marker.addListener('click', function () {
          if (activeInfoWindow) {
            activeInfoWindow.close();
          }
          infowindow.open(map, marker);
          activeInfoWindow = infowindow;

        });
      });

      const circle: any = new window.google.maps.Circle({
        // strokeColor: '#0066d2', // Blue stroke color
        // fillColor: '#3d74fa',
        // strokeOpacity: 0.1,
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'rgba(0, 255, 0, 0.35)',
        
        fillOpacity: 0.40,
        // strokeWeight: 1,
        map: map,
        center: centerLatLong,
        radius: parseInt(radius),
        draggable: true,
        zIndex: 1
      });

      if (circle) {
        circle.setRadius(parseInt(radius));
      }


      // Listen for the zoom_changed event on the map
      window.google.maps.event.addListener(map, 'zoom_changed', function () {

        var zoom: any = map.getZoom();

        allMarkers.forEach(function (marker: any) {

          if (zoom > 13) {
            marker.setIcon(
              {
                url: marker.getIcon().url, //marker's same icon graphic
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(34, marker.getIcon().url == brnchUrl ? 45 : 34) //changes the scale
              }
            )
          } else if (zoom == 13) {
            marker.setIcon(
              {
                url: marker.getIcon().url, //marker's same icon graphic
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(29, marker.getIcon().url == brnchUrl ? 40 : 29) //changes the scale
              }
            )
          } else {
            marker.setIcon(
              {
                url: marker.getIcon().url, //marker's same icon graphic
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(20, marker.getIcon().url == brnchUrl ? 30 : 20)//changes the scale
              }
            )
          }


        })
      });

      circle.addListener('dragend', function () {
        var center = circle.getCenter();
        var radius = circle.getRadius();

        var pointsInsideCircle: any = []; 
        // Check if any marker/point is inside the circle
        allMarkers.map(function (mark: any) {

          // Calculate the distance between the marker and the circle center
          var distance = google.maps.geometry.spherical.computeDistanceBetween(mark.getPosition(), center);

          // Check if the distance is less than or equal to the circle radius
          if (distance <= radius) {
            pointsInsideCircle.push(mark.get('champData'))
          }
        });

        // Do something with the points inside the circle
        setCircleChamps(pointsInsideCircle)
      });

      // to get data after drag end  
      const marker = new window.google.maps.Marker({
        position: markerPositionLatLng,
        map: map,
        title: "",
        icon: searchedMarker,
        zIndex: 1,
      })

      marker.setIcon(
        {
          url:searchedMarker , 
          size: null,
          origin: null,
          anchor: null,
          scaledSize: new google.maps.Size(50,  50)
        }
      )
    }


    const x = setTimeout(() => {
      champData?.length > 0 && rendered()
    }, 1000);


    return () => {
      clearTimeout(x)
    }


  }, [champData, radius, centerLatLong])


  return (
    <>
      <div>
        <div className='d-flex justify-content-between mt-1' style={{ width: "100%" }}>
          <div className='d-flex align-middle' style={{ width: "80%", display: "inlineFlex" }}>
            
            <div className='mt-2' style={{ width: "18%", marginRight: "10px" }}>
              <SingleSearchCategory
                handleChangeInputData={cityChangeHandler}
                HeaderTitle='Select City'
                SelectData={cityData}
                DynamicKey={'city_name'}
                id={'id'}
                name='city_name'
              ></SingleSearchCategory>
            </div>

            <div className='d-flex flex-column  flex-wrap g-2 col-3 me-2'>
              <SearchAreaOnMap address={customAddressSearch} setMarkerPositionLatLng={setMarkerPositionLatLng} />
            </div>

            <div className='mt-2 ' style={{ width: "25%", marginRight: "10px" }}>
              <MapMultiSelect
                handleChangeInputData={handleChangeSupervisor}
                HeaderTitle='Select Supervisor'
                SelectData={Supervisordata}
                DynamicKey={'first_name'}
                DynamicKey2={'last_name'}
                DynamicKey3={'phone'}
                id={'id'}
                name='supervisor'
                defaultData={null}
              ></MapMultiSelect>


            </div>

            <div>
              <button type='button' className='btn btn-sm btn-primary mt-2' onClick={handleSearch}>Search</button>
            </div>
          </div>
        </div>

        <div className="d-flex">
          {/* <span className='border p-1 bg-white rounded me-3 mt-2'> High - <img style={{ width: "17px", height: "25px" }} src={brnchUrl} alt="" /></span> */}
          {/* <span className='border p-1 bg-white rounded me-3 mt-2'> Medium - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png" alt="" /></span> */}
          {/* <span className='border p-1 bg-white rounded me-3 mt-2'> Low - <img style={{ width: "20px", height: "25px" }} src={fultItmeUrl} alt="" /></span> */}



          <span className='border p-1 bg-white rounded me-3 mt-2'> High - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> Medium - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/green-dot.png" alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> Low - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png" alt="" /></span>
          <span className='border p-1 bg-white rounded me-3 mt-2'> No Priority - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/yellow-dot.png" alt="" /></span>
          <input type="text" className='border rounded me-3 mt-2' placeholder='Radius(in KM)' onChange={(event) => setRadius(Number(event.target.value) * 1000)} style={{ border: "none", outline: "none" }} />
          {/* <div className='' style={{ width: "20%" }}>

            <button style={{ height: "35px" }} className='btn btn-sm btn-primary me-3 mt-2' onClick={() => setShow(true)}>Show Champs In Circle</button>

          </div> */}
        </div>
        <br />
        <br />
        {
          champData?.length == 0 && !Loader ? <Not_Found headerTitle={champData?.length === 0 ? 'Select City To See Map' : "Select City To See Map"}></Not_Found> :
            Loader ? <IsLoading /> :
              <div id="map" style={{ height: 'calc(100vh - 250px)', width: '100%' }} />
        }

      </div>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Champs Inside Radius</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">NAME</th>
                <th scope="col">POINTS</th>
              </tr>
            </thead>
            <tbody>

              {circleChamps.map((champ: any) => {
                return <tr>
                  <th scope="row">{champ?.id}</th>
                  <td>{champ?.first_name + ' ' + champ?.last_name}({champ?.phone})</td>
                  <td>{champ?.total_points_earned}</td>
                </tr>
              })
              }

            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default CompanyRequirementMap
