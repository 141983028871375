import { KTSVG } from "../../../_metronic/helpers"
import PermissionContextProvider from "../../modules/admins/adminComponents/PermissionContextProvider"
import { adminMenuActionPermissionKeys } from "../../modules/admins/adminConstant"
import OpenChat from "../champChatDashboard/OpenChat"

const Layout = ({ title, row_id, type, row, callToTargetUser, isOpen, toCreateTicketForNewListApi, trackData, totalRowDataForChampDashboard }: any) => {
    return <div className='d-flex flex-row mb-1'>
        <div>
            {
                type == 'cleanerid' && isOpen == true && row_id ?
                    <OpenChat title={title} champId={row_id} toCreateTicketForNewListApi={toCreateTicketForNewListApi} trackData={trackData} totalRowDataForChampDashboard={totalRowDataForChampDashboard} tableRowData={row} /> : <>{title}</>
            }
        </div>

        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-call-connect"]}>
            {
                row_id && <div className='call-button ms-1 cursor-pointer' onClick={() => callToTargetUser(type, row_id)}>
                    <KTSVG path='/media/icons/duotune/Info/call002.svg' className='svg-icon-2 ml-1' />
                </div>
            }
        </PermissionContextProvider>

    </div>
}

export default Layout