import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import "./custom.css"
import moment from 'moment';
const TransactionsHistory = ({ TransactionListData }: any) => {


  return (
    <>
      <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
        <div
          // style={{ maxHeight: offersData?.length !== 0 ? "550px" : "" }}
          className={clsx('scroll-y me-n5 pe-5  max mb-4')}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
        >
          <div className='px-3 mt-4 margins table-responsive'>
            <table className="table table-bordered table-hover table-sm">
              <thead>
                <tr className='border px-3 bg-secondary'>
                  <th scope="col">NAME</th>
                  <th scope="col">EMAIL</th>
                  <th scope="col">INITIATED DATE</th>
                  <th scope="col">PAYMENT TYPE</th>
                  <th scope="col">PAYMENT ID </th>
                  <th scope="col">STATUS</th>
                  <th scope="col">CANCELLATION REASON</th>
                  <th scope="col">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {TransactionListData && TransactionListData?.map((element: any) => {
                  return (
                    <tr className={"border"} >
                      <td scope="row">{element?.firstname ? element?.firstname : "NA"}</td>
                      <td >{element?.email ? element?.email : "NA"}</td>
                      <td style={{ "width": "100px" }}>{element?.createdAt ? moment(element?.createdAt).format("YYYY-MM-DD") : "NA"}</td>
                      <td > {element?.payment_type ? element?.payment_type : "NA"} </td>
                      <td >{element?.razorpay_order_id ? element?.razorpay_order_id : "NA"}</td>
                      <td >{element?.authorization_status ? element?.authorization_status : "NA"}</td>
                      <td style={{ maxWidth: "80px" }}>{element?.cancellation_reason ? element?.cancellation_reason : "NA"}</td>
                      <td >{element?.amount}</td>
                    </tr>
                  )
                }
                )

                }
              </tbody>
            </table>
          </div>
        </div>
      </div>




    </>
  )
}
export default TransactionsHistory