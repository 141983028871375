import React, { useEffect, useState } from 'react'
import { LocalBaseURL } from '../../../../../BaseURLmanagement';
import { changeAttendenceDate, GetAllCleanerListandjobtype } from '../API';
import SingleSelectSearchDetailsDefault from '../../../../consts/Select/SingleSelectSearchDetailsDefault';
import { timelotsData } from '../../../../consts/CommonData';
import { toast } from 'react-toastify';
import { Bars, ColorRing } from 'react-loader-spinner'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { ContainerCss, PopCloseFormNotification, PopCloseFormNotificationPtag, Create_BtnCss } from '../../../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet';
import { adminToastRunner } from '../../../admins/adminUtils';
interface Props {
    AttendanceID: any
    cleanerid?: any
    timeslotid?: any
    handleDiloagboxform: (event: number | string) => void
}
const EditAttendence = ({ AttendanceID, handleDiloagboxform, cleanerid, timeslotid }: Props) => {
    LocalBaseURL()
    const dispatch = useDispatch()
    const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
    const [cleanerList, setCleanerList] = useState<any>([])
    const [isLoading, setIsLoading] = useState<any>(false)

    const [payloads, setPayloads] = useState({
        cleanerid: 0,
        attendenceid: AttendanceID,
        date: '',
        timeslot: 0
    })

    let defaultCleaner = () => {
        let clCleaner: any = cleanerList?.find((op: any) => +op.id == +cleanerid)
        let render: object = { label: 'Select Cleaner', value: 0 }
        if (clCleaner) {
            render = { label: clCleaner.first_name + ' ' + clCleaner.last_name + '(' + clCleaner.phone + ')', value: clCleaner.id }
        }
        return render as any
    }

    useEffect(() => {
        defaultCleaner()?.value && setPayloads({...payloads, ["cleanerid"]: defaultCleaner()?.value})
    }, [defaultCleaner()?.value])

    const handleChangeFormData = (event: any, name?: any) => {
        if (!name) {
            setPayloads({ ...payloads, [event.target.name]: event.target.value })
        } else {
            setPayloads({ ...payloads, [name]: event.value })
        }
    }

    const handleEditAttenddanceForm = async () => {
        setIsLoading(true)
        //api call
        if (payloads.cleanerid == 0) {
            setIsLoading(false)
            toast.error("Please select cleaner!")
            return false
        }
        const response = await changeAttendenceDate(payloads)
        if (response?.data?.status === 200) {
            dispatch({ type: "JOB_LIST_REFRESH", payload: Math.random() })
        }
        adminToastRunner(response)
        setIsLoading(false)
    }
    const handleClose = () => {
        handleDiloagboxform(0)
    }
    React.useEffect(() => {
        async function getCityInvoked() {
            const response = await GetAllCleanerListandjobtype(localKeyCheck, AttendanceID)
            setCleanerList(response?.data?.cleanerlist)
        }
        getCityInvoked()
    }, [])


    return (
        <div className="p-8" style={ContainerCss}>
            <div style={PopCloseFormNotification}><p style={PopCloseFormNotificationPtag} onClick={handleClose} >X</p></div>
            <h4 className='text-center'>Attendance Edit</h4>
            <div className="p-5 mr-auto">
                <div className='modal-content ' >
                    {
                        <div className="row mb-5">
                            <h6 >Select Cleaner</h6>
                            <div className="col-12  mb- d-flex  align-items-center">
                                {cleanerList?.length > 0 && (
                                    <SingleSelectSearchDetailsDefault
                                        handleChangeInputData={handleChangeFormData}
                                        HeaderTitle="Select Supervisor"
                                        SelectData={cleanerList}
                                        DynamicKey={"first_name"}
                                        DynamicKey2={"last_name"}
                                        DynamicKey3={"phone"}
                                        id={"id"}
                                        name="cleanerid"
                                        defaultData={defaultCleaner()}
                                    ></SingleSelectSearchDetailsDefault>
                                )}
                            </div>
                        </div>
                    }
                    <div className="row mb-5">
                        <h6 >Select Timeslot</h6>
                        <div className="col-12  mb- d-flex  align-items-center">
                            <select
                                className='form-select form-select-solid me-2'
                                name="jobtypeid"
                                onChange={handleChangeFormData}
                            >
                                <option value="" >Select Timeslot</option>
                                {
                                    timelotsData?.map((ele: any) => {
                                        return (
                                            <>
                                                <option value={ele.id} selected={+ele.id == +timeslotid ? true : false}>{ele.name}</option>
                                            </>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <h6 >Select Date</h6>
                        <div className="col-12  mb- d-flex  align-items-center">
                            <input className='form-control' type="date" name='date' onChange={handleChangeFormData} />
                        </div>
                    </div>
                </div>
                {isLoading ?
                    <div className="d-flex justify-content-end">
                        <ColorRing
                            visible={true}
                            height="35"
                            width="35"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    </div> :
                    <button
                        style={Create_BtnCss}
                        type='submit'
                        className='btn btn-sm btn-primary '
                        data-kt-users-modal-action='submit'
                        onClick={handleEditAttenddanceForm}
                    >
                        <span className='indicator-label' >Assign</span>
                    </button>
                }
            </div>
        </div>
    )
}
export default EditAttendence
