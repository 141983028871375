import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminParamsGenerator, adminQueryGenerator, adminToastRunner } from '../../admins/adminUtils';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import AdminSelectSearchable from '../../admins/adminForms/adminFormComponents/AdminSelectSearchable';
import CustomDropdown from '../CustomDropdown';
const userTypeList: any = [{ name: "Champ", id: "7_1" }, { name: "Customer", id: "0_0" }];
const AddStatusConfig = ({ show, refreshNow, setShow, initialValue, setInitialValue, apiUrl, defaultValue, setDefaultValue, originalValues }: any) => {
    const [loading, setLoading] = useState(false);
    const [statusListData, setStatusListData] = useState([]);
    const [subStatusListData, setSubStatusListData] = useState([]);
    const [statusGroupListData, setStatusGroupListData] = useState([]);
    const [userType, setUserType] = useState<any>(null);
    const [statusLoader, setStatusLoader] = useState<any>(false);


    useEffect(() => {
        initialValue?.userType && setUserType(initialValue?.userType)
    }, [initialValue?.userType])

    useEffect(() => {
        const fetchData = async (status_id: any, sub_status_id: any) => {
            setStatusLoader(true);
            try {
                const [getstatusListing, getSubStatusListData] = await Promise.all([
                    getRequest(URL_EndPoints(userType == "all" ? null : adminQueryGenerator("getMasterStatuses", { category_id: status_id }))?.getMasterStatuses, null),
                    getRequest(URL_EndPoints(userType == "all" ? null : adminQueryGenerator("getMasterSubStatuses", { category_id: sub_status_id }))?.getMasterSubStatuses, null),
                ]);
                setStatusListData(getstatusListing?.data?.data || []);
                setSubStatusListData(getSubStatusListData?.data?.data || []);
                setStatusLoader(false)

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setStatusLoader(false);
            }
        };

        if (userType) {
            fetchData(userType?.split("_")[0], userType?.split("_")[1]);
        }

    }, [userType]);

    const getMasterStatusGroups = async () => {
        try {
            const [getstatusListing] = await Promise.all([
                getRequest(URL_EndPoints()?.getMasterStatusGroups, null),
            ]);
            setStatusGroupListData(getstatusListing?.data?.data || []);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
        }
    };
    useEffect(() => {
        getMasterStatusGroups();
    }, []);

    const onChangeHandler: any = (event: any, name: any) => {
        setInitialValue({
            ...initialValue,
            [name]: event ? event?.value : event,
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const { editForm, userType, id, ...newPayloadValue } = initialValue
        let changedPayload;
        if (id) {
            changedPayload = Object.keys(newPayloadValue).reduce((acc: any, key: any) => {
                if (newPayloadValue[key] !== originalValues[key]) {
                    acc[key] = newPayloadValue[key];
                }
                return acc;
            }, {});
        }
        else {
            changedPayload = newPayloadValue
        }
        
        if(id){
            changedPayload.id = id
        }

        const response = await postRequest(URL_EndPoints()?.[apiUrl], changedPayload, setLoading)
        adminToastRunner(response)
        refreshNow && refreshNow();
    //    if(response?.data?.status == 200){
    //     setShow && setShow(false);
    //    }
        setDefaultValue && setDefaultValue(null)
    };

    const handleInputTextValue = async (value: any) => {
        const isConfirmed = window.confirm("Are you sure you want to add this group ?");
        if (!isConfirmed) {
            return;
        }
        const response = await postRequest(URL_EndPoints()?.["createGroupMasterStatus"], { name: value }, setLoading)
        adminToastRunner(response)
        if (response?.data?.status == 200) {
            getMasterStatusGroups()
        }

    }


    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Status Config</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} className="mb-3" controlId="user_type">
                                    <Form.Label><strong >Select User Type</strong></Form.Label>
                                    {
                                        <Form.Control
                                            as="select"
                                            name="user_type"
                                            value={userType}
                                            onChange={(event) => setUserType(event.target.value)}
                                        // required
                                        >
                                            <option value="">Select User Type</option>
                                            {userTypeList.map((item: any) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name ? item.name : `${(item.name)} | NA`}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    }
                                </Form.Group>

                            </Row>

                            {
                                userType && <Row className="mb-3">

                                    {
                                        <Form.Group as={Col} className="mb-3 relative" controlId="status_group_id">
                                            <Form.Label><strong >Select Status Group</strong></Form.Label>
                                            <CustomDropdown
                                                handleInputTextValue={handleInputTextValue}
                                                placeHolder="Add New Content"
                                                Component={AdminSelectSearchable}
                                                props={{
                                                    HeaderTitle: 'Select Status Group',
                                                    onChangeHandler: onChangeHandler,
                                                    selectData: statusGroupListData,
                                                    displayFields: ["name"],
                                                    id: "id",
                                                    name: "status_group_id",
                                                    searchable: false,
                                                    setSeacrch: null,
                                                    isLoading: null,
                                                    filters: null,
                                                    isMulti: false,
                                                    defaultData: defaultValue ? defaultValue?.status_group : null
                                                }}
                                                default
                                            />
                                        </Form.Group>
                                    }

                                    {
                                        statusLoader ? <IsLoading /> : statusListData?.length > 0 ? <Form.Group as={Col} className="mb-3" controlId="status_id">
                                            <Form.Label><strong >Select Status</strong></Form.Label>
                                            <AdminSelectSearchable
                                                HeaderTitle='Select Status'
                                                onChangeHandler={onChangeHandler}
                                                selectData={statusListData}
                                                displayFields={["name"]}
                                                id="id"
                                                name="status_id"
                                                defaultData={defaultValue ? defaultValue?.status_id : null}
                                                searchable={false}
                                                setSeacrch={null}
                                                isLoading={null}
                                                isMulti={false}
                                                isDisabled={initialValue?.editForm ? true : false}
                                            />
                                        </Form.Group> : <></>
                                    }

                                    {
                                        statusLoader ? <IsLoading /> : subStatusListData?.length > 0 ? <Form.Group as={Col} className="mb-3" controlId="sub_status_id">
                                            <Form.Label><strong >Select Sub Status</strong></Form.Label>
                                            <AdminSelectSearchable
                                                HeaderTitle='Select Sub Status'
                                                onChangeHandler={onChangeHandler}
                                                selectData={subStatusListData}
                                                displayFields={["name"]}
                                                id="id"
                                                name="sub_status_id"
                                                defaultData={defaultValue ? defaultValue?.sub_status_id : null}
                                                searchable={false}
                                                setSeacrch={null}
                                                isLoading={null}
                                                isMulti={false}
                                                isDisabled={initialValue?.editForm ? true : false}

                                            />
                                        </Form.Group> : <></>
                                    }


                                </Row>

                            }
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? 'Saving...' : 'Save'}
                            </Button>

                        </Form>
                    )}
            </Modal.Body>
        </Modal>
    );
};

export default AddStatusConfig;
