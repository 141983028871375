import React from 'react'
import { AdminToolTip } from '../../admins/adminComponents/AdminToolTipModal'
import { AreaManagerChartCustomerReview } from './AreaManagerChartCustomerReview'
const CardCountCustomerReview = ({ className, color, data, chartData, custom_color = null, handleTabClick }: any) => {

    let style: any = {
        backgroundColor: "#3AABFF",
        minHeight: "30px"
    }

    if (custom_color) {
        style.backgroundColor = custom_color
    }

    if (data?.style) {
        data.style = { ...data.style }
        data.style.minHeight = "30px"
    }

    const renderCard = (label1: any, totalCount1: any, label2: any, totalCount2: any, class1: any, class2: any) => (

        <div className='d-flex align-items-center justify-content-between flex-wrap w-100 p-2 mb-0 px-1 m-0' >
            <div className={`mb-1 d-flex flex-column text-center justify-content-center align-items-center p-2 badge badge-light-success text-dark`} style={{ display: 'inline-block', borderBottom: class1, marginRight: "1px", width: "49%" }}>
                <span className='fw-semibold fs-5 mb-2' >
                    {label1}
                </span>
                <span className='fw-bold fs-2'>
                    {totalCount1}
                </span>
            </div>
            <div className='mb-1 d-flex flex-column text-center justify-content-center align-items-center p-2 badge badge-light-success text-dark w-50' style={{ display: 'inline-block', borderBottom: class2, }} >
                <span className='fw-semibold fs-5 mb-2'>
                    {label2}
                </span>
                <span className='fw-bold fs-2'>
                    {totalCount2}
                </span>
            </div>
        </div>

    )
    let isNegativeMonth = data?.thisMonth?.totalCount < 0 ? "3px solid red" : "3px solid green"
    let isNegativeToday = data?.today?.totalCount < 0 ? "3px solid red" : "3px solid green"


    return (
        <div className={`${className}`} >
            <div className={`totalCount col-12 p-0`} style={data?.style ?? style} >
                <div className='d-flex justify-content-center text-center'>
                    <h3 className='m-0 mt-1 text-white fw-bold fs-5'>{data?.area_manager_name ? data?.area_manager_name : "NA"}</h3>
                    {/* <h3 className='m-0 mt-1 text-white fw-bold fs-5'>{data?.areaManager?.first_name + " " + data?.areaManager?.last_name}</h3> */}
                </div>
            </div>
            {
                renderCard(data?.currentData?.active?.label, data?.currentData?.active?.totalCount, data?.currentData?.inactive?.label, data?.currentData?.inactive?.totalCount, "3px solid green", "3px solid red")
            }

            {
                renderCard(data?.thisMonth?.label, data?.thisMonth?.totalCount, data?.today?.label, data?.today?.totalCount, isNegativeMonth, isNegativeToday)
            }

            <div className="col-12 p-0" >
                {
                    <AreaManagerChartCustomerReview data={chartData} colorCombination={data?.monthWiseProgress?.data?.map((item: any) => item?.color)} />
                }
            </div>
            <div className='p-2 col-12'>
                {
                    data?.lists?.map((sub: any, index: any) => {
                        let toolTipId = sub?.title + "_" + index
                        return <div className='d-flex flex-column align-items-center mb-1' style={sub?.style} id={toolTipId} onClick={() => handleTabClick && sub?.config?.filter ? handleTabClick(sub?.config?.filter, sub?.config?.tabKey, data?.isRedirect) : ""}>
                            <div className='d-flex align-items-center justify-content-between flex-wrap w-100 subCount' >

                                <div className='mb-1 ' >
                                    <a href='#' className='fs-7 text-gray-800'>
                                        {sub?.label}
                                    </a>
                                </div>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className=' fs-7 text-gray-800 pe-1'> {sub?.count}</div>
                                </div>

                            </div>
                            {sub?.titleTooltip && <AdminToolTip anchorId={toolTipId} title={sub?.titleTooltip} place={""} />}
                        </div>
                    })
                }
            </div>

        </div>
    )
}
export { CardCountCustomerReview }
