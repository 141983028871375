import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { LocalBaseURL } from '../../../../BaseURLmanagement'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import ReturnTimeObject from '../../../consts/Static/ReturnTimeObject'
import TimingId from '../TimingId'
import CleanerDaysReturn from '../../../consts/Static/CleanerDaysReturn'
import ReturnTimeObjectWithName from '../../../consts/Static/ReturnTimeObjectWithName'
import { weekDays } from '../../admins/adminConstant'
let days = [{ day: "Sunday", Value: 1 }, { day: "Monday", Value: 2 }, { day: "Tuesday", Value: 3 }, { day: "Wednesday", Value: 4 }, { day: "Friday", Value: 5 }, { day: "Saturday", Value: 6 }]
const CleanerAssigningForm = (props: any) => {
  const Navigates = useNavigate()
  LocalBaseURL()
  const { referece, cleanerDetails, data, timeSlotsfilter, filteredCustomerData, handleCloseModalCleaner, CleanerAvailibiltyTimeslot, customerAvailibiltyTimeslot } = props

  // const customerAvailibiltyTimeslot = ReturnTimeObject(filteredCustomerData)
  const CustomerDayname: any = weekDays[Number(filteredCustomerData?.fullcleaningday)]
  // const CustomerDayname = CleanerDaysReturn(filteredCustomerData)
  // const CleanerAvailibiltyTimeslot: any = ReturnTimeObjectWithName(CleanerAvailibiltyTimeslot)
  let updatedtimeSlotsfilter = timeSlotsfilter?.filter((orginalSlot: any) => orginalSlot.name !== customerAvailibiltyTimeslot?.name)

  let updatedays = days.filter((days: any) => days.day !== filteredCustomerData?.fulldaycleaning)

  const [SelectedTiming, SetSelectedTiming] = useState<any>( CleanerAvailibiltyTimeslot?.name ? CleanerAvailibiltyTimeslot?.id : "")

  const [SelectedDate, setSelectedDate] = useState<any>(filteredCustomerData?.startdate || moment().format("YYYY-MM-DD"))

  const [SelectedDay, SetSelectedDay] = useState<any>(CustomerDayname?.name !== "Not Availble" && CustomerDayname?.name ? CustomerDayname?.id : "")

  const [individualSelectedDay, setindividualSelectedDay] = useState<any>({})

  const [loader, setLoader] = useState(false)

  const [disable, setdisable] = useState<any>(null)

  const dispatch = useDispatch()

  useEffect(() => {

    // based on cleaner availibilty 
    const dayFromOnDemandDate: any = days?.filter((ele: any) => ele.day === moment(filteredCustomerData?.startdate).format('dddd'))
    filteredCustomerData?.packageDetail?.isFullCleaning == 0 && SetSelectedDay(dayFromOnDemandDate?.length > 0 && dayFromOnDemandDate[0]?.Value)
    CleanerAvailibiltyTimeslot?.name && SetSelectedTiming(TimingId(CleanerAvailibiltyTimeslot?.name))
  }, [filteredCustomerData, CleanerAvailibiltyTimeslot])


  useEffect(() => {
    // based on date field changes 

    const updatedDayFromDate = moment(SelectedDate).format('dddd')
    const updatedDayIdFromDate = days.filter((day) => day.day == updatedDayFromDate)
    filteredCustomerData?.packageDetail?.isFullCleaning == 0 && setindividualSelectedDay(updatedDayIdFromDate?.length > 0 ? updatedDayIdFromDate[0] : {})
    filteredCustomerData?.packageDetail?.isFullCleaning == 0 && SetSelectedDay(updatedDayIdFromDate?.length > 0 && updatedDayIdFromDate[0]?.Value)
  }, [SelectedDate])



  // statmenagments here  --------------------------------------------------------
  const handleAssigNewCleanerToCustomer = async () => {


    const payload = {
      "orderid": +filteredCustomerData?.id,
      "frequencyid": +filteredCustomerData.frequencyid,
      "jobsiteid": +filteredCustomerData?.jobsiteid,
      "newordercleanerid": +cleanerDetails?.id,
      "startdate": moment(SelectedDate).format("YYYY-MM-DD"),
      "fullcleaningday": +SelectedDay,
      "timeslotid": +SelectedTiming,
    }


    if (SelectedDay && SelectedTiming) {
      if (referece === "un-assigned") {
        setLoader(true)
        const result = await postRequest(URL_EndPoints()?.assigncleanertoneworder, payload, setLoader)
        if (result?.data?.status === 200) {
          toast.success("Successful Assigned cleaner")
          dispatch({ type: "cleanerAvailibiltyRoutes", payload: Math.random() })
          Navigates(-1)
          setLoader(false)
          setdisable(200)
          setTimeout(() => {
            handleCloseModalCleaner()
          }, 2000)
        }
        if (result?.data?.status !== 200) {
          setdisable(null)
          toast.error("something is wrong")
          setLoader(false)
          setTimeout(() => {
            handleCloseModalCleaner()
          }, 2000)
        }
      }
    } else {
      toast.error(!SelectedTiming ? "No time is selected" : !SelectedDay ? "No Day is selected" : "something is missing")
    }



  }
  // =========================================================----------==========================================
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <div className='p-10 card' style={{ width: '600px' }}                                                                                                                                                                                                                                        >
      <div className='modal-content ' >
        <div className='modal-header mb-5'>
          <h5 className='modal-title mb-2' id='exampleModalCenteredScrollableTitle'>
            What he wants ?
          </h5>
          <h5 className='modal-title mb-2 ml-10' id='exampleModalCenteredScrollableTitle'>
            What we giving ?
          </h5>
          <button
            type='button'
            className='btn-close mb-2'
            data-bs-dismiss='modal'
            aria-label='Close'
            onClick={handleCloseModalCleaner}
          ></button>
        </div>
        {/* ==================================================================================================== */}
        <div className="row mb-5">
          <div className="col-6">
            <h5>Customer Name</h5>
            <p>
              {filteredCustomerData?.name}
            </p>
          </div>
          <div className="col-5">
            <h5>Cleaner Name</h5>
            {cleanerDetails?.first_name}  {cleanerDetails?.last_name}
          </div>
          <hr />
          <div className="col-6">
            <h5>Phone No.</h5>
            <p>{filteredCustomerData?.phone}</p>
          </div>
          <div className="col-5">
            <h5>Phone No.</h5>
            <p>{cleanerDetails?.phone}</p>
          </div>
          <hr />
        </div>
        {/* ==================================================================================================== */}
        <div className="row mb-5">
          <div className="col-6">
            <h5>Subscription Date</h5>
            <p>{filteredCustomerData?.startdate}</p>
          </div>
          <div className="col-6 mb-3">
            <h5>Subscription Date</h5>
            <input
              min={disablePastDate()}
              defaultValue={filteredCustomerData?.startdate}
              type='date'
              className='form-select form-select-solid me-2'
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>
          <hr />
          <div className="col-6 mb-3">
            <h5>Cleaning Day</h5>
            {filteredCustomerData?.fullcleaningday == "1" ? "Sunday" :
              filteredCustomerData?.fullcleaningday == "2" ? "Monday" :
                filteredCustomerData?.fullcleaningday == "3" ? "Tuesday" :
                  filteredCustomerData?.fullcleaningday == "4" ? "Wednesday" :
                    filteredCustomerData?.fullcleaningday == "5" ? "friday" :
                      filteredCustomerData?.fullcleaningday == "6" ? "Saturday" :
                        "Not Availble"}
          </div>
          <div className="col-6  mb-3">
            <h5>Cleaning Day</h5>
            {
              filteredCustomerData?.packageDetail?.isFullCleaning == 0 ? <select
                className='form-select form-select-solid me-2'
              >
                <option value={moment(SelectedDate).format('dddd') === "Thursday" ? null : filteredCustomerData?.packageDetail?.isFullCleaning == 0 && SelectedDate ?
                  individualSelectedDay?.Value : CustomerDayname?.id} >{moment(SelectedDate).format('dddd') === "Thursday" ? "Service Not Available" : filteredCustomerData?.packageDetail?.isFullCleaning == 0 && SelectedDate ? individualSelectedDay?.day : CustomerDayname?.name}</option>
              </select>
                :
                <select
                  className='form-select form-select-solid me-2'
                  onChange={(e) => SetSelectedDay(e.target.value)}
                  value={SelectedDay}
                >
                  <option value={CustomerDayname?.id} >{CustomerDayname?.name}</option>
                  {/* <option value={CleanerDayname?.id} >{CleanerDayname?.name  }</option> */}
                  {updatedays?.map((item: any, index: number) => {
                    return (
                      <option value={item.Value} key={index}>
                        {item.day}
                      </option>
                    )
                  })}
                </select>
            }
          </div>
          <hr />
        </div>
        {/* ==================================================================================================== */}
        <div className="row mb-5">
          <div className="col-6">
            <h5>Timing</h5>
            {customerAvailibiltyTimeslot?.name}
          </div>
          <div className="col-6  mb-3">
            <h5>Timing</h5>
            <select
              className='form-select form-select-solid me-2'
              onChange={(e) => SetSelectedTiming(e.target.value)}
              value={SelectedTiming}
            >
              <option value={CleanerAvailibiltyTimeslot?.id} >{filteredCustomerData?.frequencyid >= 0 ? CleanerAvailibiltyTimeslot?.name : CleanerAvailibiltyTimeslot?.name}</option>
              {updatedtimeSlotsfilter?.map((item: any, index: number) => {
                return (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
          <hr />
        </div>
        {/* ==================================================================================================== */}
        <div className='modal-footer'>
          <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={handleAssigNewCleanerToCustomer}>
            {
              loader ?
                <>
                  <div className='spinner-border mr-15' role='status'></div>
                  <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>assigning.....</h4>
                </>
                : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Assign Cleaner</h4>
            }
          </button>
        </div>
      </div>
    </div>
  )
}
export default CleanerAssigningForm
