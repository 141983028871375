import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


function GenerateNode2({ data }: any) {
    const randomId = Math.floor(Math.random() * 1000 + 1);

    return (
        <Accordion defaultActiveKey={randomId.toString()}>
            <Accordion.Item eventKey="0">

                <Accordion.Header>
                    <div className='d-flex justify-content-evenly w-100'>
                        <div className="col-sm">{data.name}</div>

                        <div className="col-sm">{parseInt(data.projected)}</div>
                        <div className="col-sm">{parseInt(data.tillNow)}</div>

                    </div>
                </Accordion.Header>
                <Accordion.Body >
                    {data.data.map((parentNode: any) => {
                        const parentRandomId = Math.floor(Math.random() * 1000 + 1);

                        return (
                            <Accordion>
                                <Accordion.Item eventKey={parentRandomId.toString()}>
                                    <Accordion.Header>
                                        <div className='d-flex justify-content-evenly w-100'>
                                            <div className="col-sm">{parentNode.name}</div>

                                            <div className="col-sm">{parentNode.projected}</div>
                                            <div className="col-sm">{parentNode.tillNow}</div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>

                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        )

                    })
                    }



                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default GenerateNode2;
