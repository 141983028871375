import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
// import "./styles.css"
const SingleSelectSearchDetailsDefaultDyanmic = (props: any) => {
  const { handleChangeInputData, HeaderTitle, SelectData, DynamicKeys, id, name, defaultData, setSeacrch, isLoading = false, targetValue, timestamp, index , multiVal} = props

  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      const newData = {
        label: DynamicKeys?.map((key: any) => !ele[key] ? "NA" : ele[key]).join(" "),
        value: ele[id],
        item: ele
      }
      return newData
    })

    setShowData(updatedData)
  }, [SelectData])

  return (
    <div className='mb-2' key={index} >
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        onInputChange={setSeacrch ? (e: any) => setSeacrch(e) : undefined}
        options={showData}
        onChange={(event) => handleChangeInputData(event, name, targetValue, true, timestamp, multiVal)}
        defaultValue={defaultData && defaultData}
        styles={colourStyles2}
        isLoading={isLoading}
      />
    </div>
  )
}




export default SingleSelectSearchDetailsDefaultDyanmic



// static

// <SingleSelectSearchDetailsDefaultDyanmic
// handleChangeInputData={onChangeHandler}
// HeaderTitle='Select Setting'
// SelectData={settingConfigResults || []}
// DynamicKeys={["name"]}
// id={"id"}
// name={"settings"}
// targetValue={"value"}
// defaultData={null}
// ></SingleSelectSearchDetailsDefaultDyanmic>




// for dynamic 


{/* <SingleSelectSearchDetailsDefaultDyanmic
handleChangeInputData={onChangeHandler}
HeaderTitle='Filter By'
SelectData={storeFilterList[keyName] ? storeFilterList[keyName] : []}
DynamicKeys = {filters?.valueConfig?.apiConfig?.displayFields}
id={filters?.valueConfig?.apiConfig?.displayValue}
name={keyName}
targetValue={"value"}
defaultData={payload?.filterData[keyName] && (payload?.filterData[keyName][keyName + "_value"] ? payload?.filterData[keyName][keyName + "_value"] : [])}
></SingleSelectSearchDetailsDefaultDyanmic> */}