import React, { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'

export const PurchaseActions = ({ row, handleEdit, viewOrderItems, viewGoodReceipt, viewPaymentReceipt, viewVendorInvoice, viewProformaInvoice }: any) => {

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    return (
        <>
            <button
                className='btn btn-sm btn-light btn-active-primary  fs-8 '
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div
                className='menu menu-sub DropdownContainerwidth menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
                style={{
                    zIndex: '105',
                    position: 'fixed',
                    inset: '0px 0px auto auto',
                    margin: '0px',
                    transform: 'translate(-59px, 440px)',
                }}
            >
                <div className='menu-item px-3' onClick={() => handleEdit(row?.id)}>
                    <a className='menu-link  px-3'>Edit Purchase Order</a>
                </div>
                <div className='menu-item px-3' onClick={() => viewOrderItems(row?.id)}>
                    <a className='menu-link  px-3'>View Order Items</a>
                </div>
                <div className='menu-item px-3' onClick={() => viewGoodReceipt(row?.id)}>
                    <a className='menu-link  px-3'>View Good Receipt</a>
                </div>
                <div className='menu-item px-3' onClick={() => viewPaymentReceipt(row?.id)}>
                    <a className='menu-link  px-3'>View Payment Receipt</a>
                </div>
                <div className='menu-item px-3' onClick={() => viewVendorInvoice(row?.id)}>
                    <a className='menu-link  px-3'>View Vendor Invoice</a>
                </div>
                <div className='menu-item px-3' onClick={() => viewProformaInvoice(row?.id)}>
                    <a className='menu-link  px-3'>View Proforma Invoice</a>
                </div>


            </div>

        </>
    )
}
