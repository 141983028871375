
const initState = {
    updateTrainingLocationData: [],
    updatePriorityData: [],
    areaListData: [],
    typeDataList: [],
    supervisorListData: [],
    replacementListData: [],
}


export const StateMangenementCenter = (state = initState, { type, payload }: any) => {

    switch (type) {
        case "UPDATE_TRAINING_LOCATION":
            return { ...state, updateTrainingLocationData: payload }
        case "UPDATE_PRIORITY":
            return { ...state, updatePriorityData: payload }
        case "AREA_LIST_DATA":
            return { ...state, areaListData: payload }
        case "TYPE_AREA":
            return { ...state, typeDataList: payload }
        case "SUPERVISOR_LIST_DATA":
            return { ...state, supervisorListData: payload }
        case "GET_REQUIREMENT_TYPE":
            return { ...state, replacementListData: payload }
        default:
            return state
    }
}


// const updateTrainingLocationData = useSelector((store: any) => store.StateMangenementCenter.updateTrainingLocationData)
// const updatePriorityData = useSelector((store: any) => store.StateMangenementCenter.updatePriorityData)
