import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import './custom.css'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { ShowHideColumns } from '../../consts/ShowHideColumns';
import { ToggleColumns } from '../../consts/toggleColumns/ToggleColumns';
import RenewalBulkWhatsappNotifcationSender from '../../consts/components/RenewalBulkWhatsappNotifcationSender';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../consts/components/Utility';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import TicketAssigneForm from '../NewTicketsList/TicketAssigneForm';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import { ReactTableComponentStylesSheet } from '../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet';
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList';
import AdminBulkAssignUnassignForm from '../admins/adminForms/AdminBulkAssignUnassignForm';
import { filteredSelectedRowsIds, useAdminListApiUrl, useAdminManagementPermission, useAdminSmartFilterList } from '../admins/adminUtils';
import AdminBulkForm from '../admins/adminForms/AdminBulkForm';
import { newRenewalListWatiNotifyFormData } from './newRenewalUtils';
import { adminMenuActionPermissionKeys, adminWatiNotifyTemplatFormData } from '../admins/adminConstant';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';

const RenewalSubscriptionsListMain = ({ filterDataOutsource }: any) => {
  const { permissionWrapperHideColsUtil } = useAdminManagementPermission()

  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload())
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [sortedColumns, setSortedColumns] = useState<any>(permissionWrapperHideColsUtil(columns(handleFetchAllCallLogs, handleFetchTransactionsClick)))


  useEffect(() => {
    if (filterData) {
      const newColumns = permissionWrapperHideColsUtil(columns(handleFetchAllCallLogs, handleFetchTransactionsClick, filterData?.map((ele: any) => ele?.customerid), filterData))
      setSortedColumns(newColumns);
    }
  }, [filterData]);

  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.getRenewalSubscriptionList, payloads, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.RenewalSubscriptionList)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {

    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)

  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setPerPageSize(newPerPageSize)
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
  };

  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }

  }, [search])

  // useEffect(() => {
  //   !search && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  // }, [search])


  useEffect(() => {
    let { isFilter, newRenewalSubscriptionList } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: newRenewalSubscriptionList }, setPending)
    isFilter && setPayload({ ...payload, ["filterData"]: newRenewalSubscriptionList })
  }, [
    search,
    // filterDataOutsource?.newRenewalSubscriptionList,
    filterDataOutsource?.isFilter,
  ])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);

  }, [selectedFilterData?.length]);

  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([]);

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  }

  const [filterDataTemp, setfilterDataTemp] = useState<any>([])
  const [filterDataTemp2, setfilterDataTemp2] = useState<any>([])
  const [showNotifyPopUp, setShowNotifyPopUp] = useState(false);
  const [AdminListData, setAdminListData] = React.useState<any>([])
  const [AllSupervisroList, setSetAllSupervisroList] = React.useState<any>([])
  let updatedsuperVisorData: any = AllSupervisroList
  updatedsuperVisorData = AllSupervisroList && [
    { id: 0, first_name: 'Not', last_name: 'Assigned', phone: ' 0 ' },
    ...updatedsuperVisorData,
  ]

  function handleFetchAllCallLogs() {
    setfilterData((prev: any) => {
      setfilterDataTemp(prev);
      return prev;
    });
  }

  function handleFetchTransactionsClick() {
    setfilterData((prev: any) => {
      setfilterDataTemp2(prev);
      return prev;
    });
  }

  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setPending(true);
      const clone = filterDataTemp.slice();
      const customerIds = clone.map((e: any) => e.customerid);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, setPending);
      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e.phone || ele[0]?.To === e.phone) || [];
        clone[i].callLogs = foundLog;
      }
      setfilterData(clone);
      setPending(false);
    }
    filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [filterDataTemp])

  useEffect(() => {
    const fetchAllTransactions = async () => {
      setPending(true);
      const clone = filterDataTemp2.slice();
      const customerids = clone.map((e: any) => e.customerid);
      const response: any = await postRequest(URL_EndPoints()?.getTransactionsByCustomerIds, {
        customerids
      }, setPending);
      const transactions = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundTransaction = transactions?.find((ele: any) => ele[0]?.phone === e?.phone) || [];
        clone[i].transactions = foundTransaction;
      }
      setfilterData(clone);
      setPending(false);
    }
    filterDataTemp2?.length > 0 && fetchAllTransactions();
  }, [filterDataTemp2])

  async function loadData() {
    const supervisor: any = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
    setSetAllSupervisroList(supervisor?.data?.data)
    const getAdminListData = await getRequest(URL_EndPoints(null)?.getAdminList, null)
    setAdminListData(getAdminListData?.data?.data)

  }

  React.useEffect(() => {
    loadData()
  }, [])

  const handleNotifyPopUpClose = () => {
    setShowNotifyPopUp(false)
  }

  const [showAssignPopUp, setShowAssignPopUp] = useState(false)
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  let keyName = 'tickets[0].id'

  const refreshNow = () => {
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }
  const [showCampaignContact, setShowCampaignContact] = useState(false);


  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getRenewalSubscriptionList")
  }, [])
  return (
    <div className='New renewal-subscription'>
      {

        <DataTable
          title="New Renewal Subscriptions"
          clearSelectedRows={toggledClearRows}
          progressPending={pending}
          customStyles={ReactTableComponentStylesSheet}
          columns={reorderColumns(sortedColumns, "renewalTicketReorderdItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "renewalTicketReorderdItem")}
          selectableRows
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          // server 
          sortServer
          onSort={handleSort}
          persistTableHead
          pagination
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          // server 
          subHeaderComponent={
            <>

              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={search} setSearch={setSearch} />
                </LeftBox>
                {
                  filterDataOutsource?.showFilter ? <></> : <RightBox>
                    <Refresh refreshNow={refreshNow} />
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-show-hide-column"]}>
                      <ShowHideColumns columns={permissionWrapperHideColsUtil(columns(handleFetchAllCallLogs, handleFetchTransactionsClick))} sortedColumns={sortedColumns} setSortedColumns={setSortedColumns} />
                    </PermissionContextProvider>
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-toggle-date-format"]}>
                      <ToggleColumns columns={[{ name: "Due Date", id: "Due Date" }]} from="RenewalList" />
                    </PermissionContextProvider>
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-filter"]}>
                      <FilterHandler
                        onChangeHandler={onChangeHandler}
                        actionHandler={actionHandler}
                        getCurrentFilter={getCurrentFilter}
                        removeFilter={removeFilter}
                        payload={payload}
                        Component={DynamicFilter}
                        filterData={filter}
                        OperatorsData={operators}
                        selectedFilterData={selectedFilterData}
                        filterComponents={KeywiseComponents()}
                        storeFilterList={storeFilterList}
                        filterkey={filterkey}
                        setSeacrch={setSearchFilter}
                        isLoadingFilter={isLoadingFilter}
                        listApiUrl={listApiUrl("getRenewalSubscriptionList")}

                      />
                    </PermissionContextProvider>

                  </RightBox>
                }

              </MainContainerWrapper>

              {
                filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-bulk-assign-ticket"]}>
                    <BulkUtilButton title="Bulk Assign Ticket" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignPopUp} />
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-bulk-whatsapp-notification"]}>
                    <BulkUtilButton title="Bulk WhatsApp Notify Customer" filteredSelectedRows={filteredSelectedRows} setShow={setShowNotifyPopUp} />
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-bulk-add-to-contact-to-list"]}>
                    <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={filteredSelectedRows} setShow={setShowCampaignContact} />
                  </PermissionContextProvider>



                </BulkUtilButtonWrapper> : <></>
              }


            </>
          }
        />
      }

      {/* {
        showNotifyPopUp ? <RenewalBulkWhatsappNotifcationSender
          showNotifyPopUp={showNotifyPopUp}
          handleNotifyPopUpClose={handleNotifyPopUpClose}
          filteredSelectedRows={filteredSelectedRows}
          setFilteredSelectedRows={setFilteredSelectedRows}
          templateType={1}
          key="ticketIds"
        />
          : <></>
      } */}

      {
        showNotifyPopUp ? <AdminBulkForm
          show={showNotifyPopUp}
          setShow={setShowNotifyPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "tickets[0].id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="msg"
          formData={adminWatiNotifyTemplatFormData({ HeaderTitle: "", valueType: "", name: "", errMessage: "", label: "", wa_number_id: 1 })}
          initialData={{
            ticketIds: [],
            customerIds: [],
            templateName: '',
          }}
          keyName="renewalSubscritptionListWatiNotify"
          formTitle={`Send WhatsApp Notification to ${filteredSelectedRows?.length} Customers`}
          bulkKeyName="ticketIds"
          formSubmitApiUrl="sendBulkWhatsAppTemplate"
        /> : <></>
      }

      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={filteredSelectedRows?.map((row: any) => row && row?.customerid)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={refreshNow}
          userType={"customer"}
        /> : <></>
      }
      {/* {
        showAssignPopUp ? <TicketAssigneForm showAssignPopUp={showAssignPopUp} setShowAssignPopUp={setShowAssignPopUp} filteredSelectedRows={filteredSelectedRows} toggledClearRows={toggledClearRows} setToggleClearRows={setToggleClearRows} setFilteredSelectedRows={setFilteredSelectedRows} showDueDate={false} keyName={keyName} /> : <></>
      } */}

      {
        showAssignPopUp ? <AdminBulkAssignUnassignForm
          show={showAssignPopUp}
          setShow={setShowAssignPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "tickets[0].id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="msg"
          initialData={{
            admins: [],
            cleaners: [],
            admins_to_unassign: [],
            cleaners_to_unassign: [],
            should_unassign: false,
          }}
          validationSchema={{
            admins: { required: false, errorMessage: "Admins is Required !" },
            cleaners: { required: false, errorMessage: "Supervisors is Required !" },
            admins_to_unassign: { required: false, errorMessage: "Unassign Admins is Required !" },
            cleaners_to_unassign: { required: false, errorMessage: "Unassign Supervisor is Required !" },
            should_unassign: { required: false, errorMessage: "" }
          }}
          keyName="renewalSubscriptionListAssignForm"
          formTitle={`Assign ${filteredSelectedRows?.length} Tickets to Admins/Supervisors`}
          bulkKeyName="ids"
          formSubmitApiUrl="assignToBulkTickets"
        /> : <></>
      }



    </div>
  )
}
export default RenewalSubscriptionsListMain
// just call this to route file
