import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux'
import { LocalBaseURL } from '../../../../../../BaseURLmanagement'
import { getCleanerAllowance } from './API'
import { columns } from './Columns'
import './styles.css'
import MainContainerWrapper from '../../../../../consts/mediaQueryLayout/MainContainerWrapper'
import LeftBox from '../../../../../consts/mediaQueryLayout/LeftBox'
import SearchBoxModal from '../../../../../consts/reuseModules/SearchBoxModal'
import { ReactTableComponentStylesSheet } from '../../../../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet'
const AssignAllowance = () => {
  LocalBaseURL()

  const [AllActiveCleaner, setAllActiveCleaner] = useState<any>([])
  const [filterData, setfilterData] = useState<any>([])
  const [search, setSearch] = useState<any>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const localKeyCheck = JSON.parse(localStorage.getItem('API') || '0')
  const cleanerid = useSelector((store: any) => store?.ActiveStatsReducer?.listDrawerids)

  React.useEffect(() => {
    const updatedData = AllActiveCleaner.filter((data: any) => {
      return data.master_allowance?.name.toLowerCase().match(search.toLowerCase())
    })
    setfilterData(updatedData)
  }, [search])

  async function InvokedRendered() {
    setLoading(true)
    const { data } = await getCleanerAllowance(localKeyCheck, cleanerid)
    setAllActiveCleaner(data.data)
    setfilterData(data.data)
    filterData && setLoading(false)
  }

  useEffect(() => {
    InvokedRendered()
  }, [])
  return (
    <div>
      {(
        <DataTable
          customStyles={ReactTableComponentStylesSheet}
          columns={columns}
          data={filterData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          progressPending={isLoading}
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={search} setSearch={setSearch} />
                </LeftBox>
              </MainContainerWrapper>
            </>
          }
        />
      )}
    </div>
  )
}
export default AssignAllowance
