import clsx from 'clsx'
import { typeScripts } from './typeScripts'
import { Col, Form, Row } from 'react-bootstrap'
import { CapitalizeFirstLetter } from '../../CapitalizeFirstLetter'

export const RowModal = ({ classes = "", children }: any) => {
    return <Row className={clsx(classes)}>{children}</Row>
}

export const ColModal = ({ classes = "", children }: any) => {
    return <Col className={clsx(classes)}>{children}</Col>
}

export const GroupModal = ({ classes = "", children }: any) => {
    return <Form.Group className={clsx(classes)} controlId="">{children}</Form.Group>
}


export const LabelModal = ({ show = false, classes = "", title = "Not set" }: any) => {
    return show ? <Form.Label className={clsx(classes)} >{title}</Form.Label> : <></>
}

export const InputModal = ({ classes = "", title = "Not set", name = "", keyName = "", id = "", isData = [], payload, setPayload, defValue="" }: any) => {
    return   <input
    placeholder='flat no. '
    name='flatno'
    type='text'
    value={payload.flatno}
    className='form-control  mb-3 mb-lg-0'
    autoComplete='off'
    // onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
/>
}

export const SelectModal = ({ classes = "", title = "Not set", name = "", keyName = "", id = "", isData = [], payload, setPayload, defValue="" }: any) => {
    return <Form.Select className={clsx(classes)} name={name} required onChange={(event: any) => setPayload({ ...payload, [event.target.name]: event.target.value })} defaultValue={defValue}>
        <option value="">{title}</option>
        {isData?.map((ele: any) => { return <option value={ele[id]}>{ele[keyName]}</option> })}

    </Form.Select>
}

export const SelectModalonChange = ({ classes = "", title = "Not set", name = "", keyName = "", id = "", isData = [], handleChangeInputData }: any) => {
    return <Form.Select className={clsx(classes)} name={name} required onChange={handleChangeInputData}>

        <option value="">{title}</option>
        {isData?.map((ele: any) => { return <option value={ele[id]}>{ele[keyName]}</option> })}


    </Form.Select>
}

export const CheckboxModal = ({ classes = "", name = "",  payload, setPayload, defValue }: any) => {
    return <Form.Group className={classes} controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label={CapitalizeFirstLetter(name)} name={name}  checked={ payload[name] == 1} onChange={()=>setPayload({...payload, [name] : payload[name]  == 1 ? 0  : 1})} />
    </Form.Group>
}

export const CheckboxModalCustomName = ({  title, classes = "", name = "",  payload, setPayload, defValue }: any) => {
    return <Form.Group className={classes} controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label={title} name={name}  checked={ payload[name] == 1} onChange={()=>setPayload({...payload, [name] : payload[name]  == 1 ? 0  : 1})} />
    </Form.Group>
}

// demo copy below code and paste

// <RowModal classes="d-flex">
// 				<ColModal classes="col-12 col-md-4 col-sm-6 col-lg-4">
// 					<GroupModal classes="" >
// 						<LabelModal show={true} classes="" title="Due Date" />
// 						<SelectModal
// 							classes="mb-2"
// 							title="Select"
// 							name=""
// 							keyName="name"
// 							id="id"
// 							isData={[{name:"1", id:"1"},{name:"1", id:"1"},{name:"1", id:"1"},{name:"1", id:"1"}]}
// 						/>
// 					</GroupModal>
// 				</ColModal>
// 				<ColModal classes="col-12 col-md-4 col-sm-6 col-lg-4">
// 					<GroupModal classes="" >
// 						<LabelModal show={true} classes="" title="Due Date" />
// 						<SelectModal
// 							classes="mb-2"
// 							title="Select"
// 							name=""
// 							keyName="name"
// 							id="id"
// 							isData={[{name:"1", id:"1"},{name:"1", id:"1"},{name:"1", id:"1"},{name:"1", id:"1"}]}
// 						/>
// 					</GroupModal>
// 				</ColModal>
// 				<ColModal classes="col-12 col-md-4 col-sm-6 col-lg-4">
// 					<GroupModal classes="" >
// 						<LabelModal show={true} classes="" title="Due Date" />
// 						<SelectModal
// 							classes="mb-2"
// 							title="Select"
// 							name=""
// 							keyName="name"
// 							id="id"
// 							isData={[{name:"1", id:"1"},{name:"1", id:"1"},{name:"1", id:"1"},{name:"1", id:"1"}]}
// 						/>
// 					</GroupModal>
// 				</ColModal>
// 			</RowModal>










