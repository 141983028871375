const initState = {
    customerid: null,
}
export const CallLogReducer = (state = initState, { type, payload }: any) => {
    switch (type) {
        case "CALL_LOGS":
            return { ...state, customerid: payload }
        default:
            return state
    }
}
// const call_log_cusomerid = useSelector((store:any)=>store.CallLogReducer.customerid)

