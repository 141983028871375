import React from "react";
import { isAllowedUser } from "./components/Utility";

interface MaskedPhoneProps {
  phoneNumber: string;
  visibleDigits?: number;
}

const MaskedPhoneNumber: React.FC<MaskedPhoneProps> = ({ phoneNumber, visibleDigits = 3 }) => {
  const isUserAllowed = isAllowedUser?.includes(Number(localStorage.getItem("user")));
  const masked =
    phoneNumber?.length > visibleDigits
      ? `${"*".repeat(phoneNumber?.length - visibleDigits)}${phoneNumber?.slice(-visibleDigits)}`
      : phoneNumber;

    return <span>{isUserAllowed ? phoneNumber : masked}</span>;
};

export default MaskedPhoneNumber;
