/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
// import {Logout, AuthPage, useAuth} from '../modules/auth'
import { Logout, AuthPage } from '../modules/auth'
import { App } from '../App'
import Constants from '../consts/Consts'
import { postRequest } from '../consts/Server/Requests'
import { URL_EndPoints } from '../consts/Server/URL_EndPoints'
// import axios from 'axios'
import React from 'react'
import { MD5 } from "crypto-js"
import { Authorization } from '../modules/auth/Authorization'
import axios from 'axios'
import { useDispatch } from 'react-redux'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  // const {auth} = useAuth();
  const user = localStorage.getItem("user")
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState<any>(localStorage.getItem(Constants.token));

  React.useEffect(() => {
    async function checkUser() {
      setLoading(true)
      const Processor_id = localStorage.getItem("Processor_id")
      const webKit_hash_id = localStorage.getItem("webKit_hash_id")
      if (user && webKit_hash_id) {
        postRequest(URL_EndPoints(null)?.checkUserStatus, {
          user: user,
          userAgentHash: webKit_hash_id,
          Processor_id: Processor_id,

        }, null)
          .then(function (response) {
            if (!response?.data?.isValid) {
              setToken(false)
            }
            localStorage.setItem("call_active_status", response?.data?.user?.call_active_status);
            setLoading(false)

          })
          .catch(function (error) {
            setLoading(false)
          });
      } else {
        setLoading(false)
      }
    }
    checkUser()

  }, []);


  return (
      <BrowserRouter >
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {token ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
  )
}

export { AppRoutes }
