import Dropdown from 'react-bootstrap/Dropdown';
import React from 'react';
import "./selectBox.css"
function DropDownFilterMenu({ ParentData, Component, filterTitle }: any) {
  const [filterQty, setfilterQty] = React.useState<any>(0)

  React.useEffect(() => {
    let Filtercount = 0
    const filterValues = Object.values(ParentData?.payloads || {})
    for (let i = 0; i < filterValues.length; i++) {
      if (filterValues[i]) {
        Filtercount = Filtercount + 1
      }

    }
    setfilterQty(Filtercount)
  }, [ParentData?.payloads])
  return (
    <Dropdown className='d-inline mb-2 btn-sm me-3 mx-2'>
      <Dropdown.Toggle id="dropdown-basic2"
        className='btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3 filterBtn'
        style={{ position: "relative" }}>
        <i className="bi bi-filter p-1 "  ></i>  <span style={{ width: "32px", height: "32px", border: "4px solid white", background: "green", padding: "2px", borderRadius: "50%", color: "white", position: "absolute", right: 0, top: -18 }}>{filterQty}</span> {filterTitle ? filterTitle : "FILTER"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Component ParentData={ParentData} ></Component>
        {ParentData?.hideSubmitButton ? <></> : <Dropdown.Item onClick={() => ParentData.handleActions()}><button className="btn btn-sm btn-primary w-100 px-2 " >Search</button></Dropdown.Item>}
      </Dropdown.Menu>
    </Dropdown>
  );
}
export default DropDownFilterMenu;
