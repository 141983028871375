import React, { useEffect, useState } from "react";
import { postRequest } from "../../../consts/Server/Requests";
import { URL_EndPoints } from "../../../consts/Server/URL_EndPoints";
import { Button, Modal } from "react-bootstrap";
import { adminToastRunner } from "../adminUtils";
import moment from "moment";

const AdminDueDateForm = ({ show = false, setShow, setUpdatedData, prevInitialPayload, row, title = "", apiUrl = "", dueDateKeyName, isDateTime = false, isServerDueDate = false, serverApiUrl = "" }: any) => {
    const [loader, setLoader] = useState(false)
    const [payload, setPayload] = React.useState<any>(prevInitialPayload);

    const handleDueDateSaveAction = async () => {
        setLoader(true)
        const response = await postRequest(URL_EndPoints()?.[apiUrl], payload, setLoader)
        adminToastRunner(response)
        if (response?.data?.status == 200) {
            setUpdatedData && setUpdatedData({ ...payload, response: response?.data?.data })
            setShow && setShow(false)
        }
    }

    const serverDueDateFunction = async () => {
        // const response = await postRequest(URL_EndPoints()?.[serverApiUrl], payload, setLoader)

    }

    useEffect(() => {
        isServerDueDate && serverDueDateFunction()
    }, [isServerDueDate])


    return (
        <>
            {show ? <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter-duedate"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title} Carselona</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        name={dueDateKeyName}
                        type={isDateTime ? "datetime-local" : "date"}
                        value={moment(new Date(payload?.[dueDateKeyName])).format(`YYYY-MM-DD ${isDateTime && "HH:mm:ss"}`)}
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        onChange={(event) => setPayload({ ...payload, [dueDateKeyName]: event?.target.value })}
                    />

                    <div className='d-flex  align-items-center justify-content-end mt-4'>
                        {
                            <Button type='submit' variant={loader ? "success" : "primary"} onClick={handleDueDateSaveAction} >
                                {loader ? "Submitting..." : "Submit"}
                            </Button>
                        }
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}


export default AdminDueDateForm;





// {
//     showDueDatePopup ? <AdminDueDateForm
//         show={showDueDatePopup}
//         setShow={setShowDueDatePopup}
//         setUpdatedData={setUpdatedData}
//         prevInitialPayload={{
//             show: showDueDatePopup,
//             ticket_history_id: 0,
//             ticket_id: storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.id : 0,
//             customerid: storeRow?.id,
//             user_id: userid,
//             due_date: storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.last_date_resolution : '',
//             prev_due_date: storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.last_date_resolution : '',
//         }}
//         row={row}
//         title="Due Date Update"
//         apiUrl="updateDueDateVisitor"
//         dueDateKeyName="due_date"
//         isDateTime={false}
//         isServerDueDate={false}
//         serverApiUrl="xxxxx"
//     /> : <></>

// }