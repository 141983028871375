import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, Table, Dropdown, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { adminParamsGenerator, adminToastRunner } from '../../admins/adminUtils';
import MediaUploadFile from '../MediaUploadFile';
import MediaPreview from '../MediaPreview';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faListCheck } from '@fortawesome/free-solid-svg-icons';

const AddUpdateGoodReceipt = ({ popUpPostFormOpen, refreshNow, handleClose, setData, initialValue, setInitialValue, apiUrl, initialAddEditPayload }: any) => {
    const [loading, setLoading] = useState(false);
    const [purchageOrder, purchageOrderList] = useState([]);
    const [vendorInvoiceList, setVendorInvoiceList] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const [inventoryList, setAllInventoryList] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [file, setFile] = useState<any>(null);

    useEffect(() => {
        setSelectedItems(
            initialValue?.item_list
        );
    }, [initialValue?.item_list]);

    useEffect(() => {
        setFile(initialValue?.media)
    }, [initialValue?.media]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [purchageOrder, goodItem, adminList] = await Promise.all([
                    getRequest(URL_EndPoints()?.getPurchaseOrderListing, null),
                    getRequest(URL_EndPoints()?.getAllInventoryItems, null),
                    getRequest(URL_EndPoints()?.getAdminList, null),
                ]);
                purchageOrderList(purchageOrder?.data?.data || []);
                setAllInventoryList(goodItem?.data?.data || []);
                setAdminList(adminList?.data?.data || []);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (popUpPostFormOpen) {
            fetchData();
        }
    }, [popUpPostFormOpen]);

    const [apiLoader, setApiLoader] = useState(false)
    const getDependedList = async () => {
        setApiLoader(true)
        const vendorInc = await getRequest(URL_EndPoints(adminParamsGenerator("getVendorInvoiceByPurchaseOrderId", initialValue?.purchase_order_id))?.getVendorInvoiceByPurchaseOrderId, setApiLoader)
        setVendorInvoiceList(vendorInc?.data?.data || []);
    }

    useEffect(() => {
        initialValue?.purchase_order_id && getDependedList()
    }, [initialValue?.purchase_order_id])

    const handleInputChange = (e: any) => {

        setInitialValue({
            ...initialValue,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearchChange = (e: any) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value) {
            const filteredResults = inventoryList.filter((item: any) =>
                item.title.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults(filteredResults);
            setShowDropdown(true);
        } else {
            setSearchResults([]);
            setShowDropdown(false);
        }
    };

    const handleSelectItem = (item: any) => {
        const isItemAlreadySelected = selectedItems.some(selected => selected.item_id === item.id);
        if (isItemAlreadySelected) {
            toast.warning('Item is already selected.');
            return;
        }

        const price = Number(item.price) || 0;
        setSelectedItems(prevSelectedItems => [
            ...prevSelectedItems,
            { ...initialAddEditPayload?.item_list, ["item_id"]: item?.id, ["title"]: item?.title }

        ]);
        setShowDropdown(false);
    };

    const calculateTotalAmount = () => {
        return selectedItems.reduce((total, item) => total + Number(item.total_price), 0);
    };

    const handleRemoveItem = (index: number) => {
        const updatedItems = selectedItems.filter((_, i) => i !== index);
        setSelectedItems(updatedItems);
    };

    const [fileSelected, setFileSelected] = useState<boolean>(false);

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!selectedItems || selectedItems.length === 0) {
            toast.warning('Please select at least one item');
            return;
        }

        let payload: any = {};
        for (let key in initialValue) {
            let value = initialValue[key];
            if (value) payload[key] = value;
        }

        if (typeof (file) == "string" || !file) {
            delete payload.media
        }
        else {
            payload.media = file
        }

        if (selectedItems.length > 0) {
            payload.item_list = JSON.stringify(selectedItems)
        }

        setLoading(true);
        let response = await postRequestWithFile(URL_EndPoints()?.[apiUrl], payload, setLoading)

        adminToastRunner(response)
        if (response?.data?.data?.items) {
            setData(response?.data?.data?.items);
        }

        setSelectedItems && setSelectedItems([]);
        setSearchTerm && setSearchTerm('');
        refreshNow && refreshNow();
        handleClose && handleClose();
    };

    const handleQuantityChangeReceived = (name: any, value: any, index: any) => {

        if (name == "quantity_received") {
            const updatedItems = [...selectedItems];
            updatedItems[index].quantity_received = Number(value);
            updatedItems[index].total_price = Number(updatedItems[index].unit_price) * Number(value);
            setSelectedItems(updatedItems);
        }
        if (name == "unit_price") {
            const updatedItems = [...selectedItems];
            updatedItems[index].unit_price = Number(value);
            updatedItems[index].total_price = Number(updatedItems[index].quantity_received) * Number(value);
            setSelectedItems(updatedItems);
        }
        else {
            const updatedItems: any = [...selectedItems];
            updatedItems[index][name] = value;
            setSelectedItems(updatedItems);
        }
    };

    return (
        <Modal size='lg' show={popUpPostFormOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{initialValue?.id ? "Edit" : "Add"} Goods Receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="purchase_order_id">
                                <Form.Label><strong>Select Purchase Order</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="purchase_order_id"
                                    value={initialValue["purchase_order_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Purchase Order</option>
                                    {purchageOrder.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.purchase_order_id ? item.purchase_order_id : `${(item.purchase_order_id)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="goods_receipt_id">
                                <Form.Label><strong>Good Receipt Id</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Good Receipt Id"
                                    name="goods_receipt_id"
                                    value={initialValue['goods_receipt_id']}
                                    onChange={handleInputChange}
                                    required
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>


                            <Form.Group as={Col} className="mb-3" controlId="vendor_invoice_id">
                                <Form.Label><strong>Select Vendor Invoice</strong></Form.Label>
                                {
                                    apiLoader ? <IsLoading /> : <Form.Control
                                        as="select"
                                        name="vendor_invoice_id"
                                        value={initialValue["vendor_invoice_id"] || []}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select Vendor Invoice</option>
                                        {vendorInvoiceList.map((item: any) => (
                                            <option key={item.id} value={item.id}>
                                                {item.invoice_number}
                                            </option>
                                        ))}
                                    </Form.Control>

                                }

                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} className="mb-3" controlId="received_date">
                                <Form.Label><strong>Received Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Received Date"
                                    name="received_date"
                                    value={initialValue['received_date']}
                                    onChange={handleInputChange}
                                    required
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="received_by">
                                <Form.Label><strong>Received By</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="received_by"
                                    value={initialValue["received_by"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Received By</option>
                                    {adminList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.first_name + item?.last_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="notes">
                                <Form.Label><strong>Notes</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Notes"
                                    name="notes"
                                    value={initialValue['notes']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                        </Row>


                        <Form.Group controlId="searchItems" className="mb-3">
                            <Form.Label><strong>Search Good Items</strong></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Type to search for an item"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onFocus={() => setShowDropdown(searchResults.length > 0)}
                                onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
                            />
                            {showDropdown && (
                                <Dropdown.Menu show style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                                    {searchResults.map((item: any) => (
                                        <Dropdown.Item
                                            key={item.id}
                                            onMouseDown={() => handleSelectItem(item)}
                                        >
                                            <strong>Title:</strong> {item.title}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>

                            )}
                        </Form.Group>

                        {selectedItems?.length > 0 ? (
                            selectedItems.map((ele, index) => (
                                <div key={ele?.id || `item-${index}`}>
                                    <div className="bg-secondary border border-secondary text-center text-dark mb-4 p-2 d-flex justify-content-between align-items-center">
                                        <div
                                            className="badge badge-white rounded-0 cursor-pointer"
                                            style={{ height: "100%" }}
                                        >
                                            {ele?.title || "Unknown"}
                                            <FontAwesomeIcon icon={faArrowRight} className="ms-3 me-3 " /> {ele?.item_id}
                                        </div>
                                        <div
                                            onClick={() => handleRemoveItem(index)}
                                            className="badge badge-white rounded-0 cursor-pointer"
                                            style={{ height: "100%" }}
                                        >
                                            Remove
                                        </div>
                                    </div>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} className="mb-3" controlId={`batch_number_${ele?.id}`}>
                                            <Form.Label>
                                                <strong>{"Batch Number"}</strong>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={"Batch Number"}
                                                name="batch_number"
                                                value={ele?.batch_number || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3" controlId={`expiry_date_${ele?.id}`}>
                                            <Form.Label>
                                                <strong>Expiry Date</strong>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Expiry Date"
                                                name="expiry_date"
                                                value={ele?.expiry_date || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                                min={new Date().toISOString().split("T")[0]}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3" controlId={`unit_price_${ele?.id}`}>
                                            <Form.Label>
                                                <strong>Unit Price</strong>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Unit Price"
                                                name="unit_price"
                                                value={Number(ele?.unit_price || 0)}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} className="mb-3" controlId={`quantity_ordered_${ele?.id}`}>
                                            <Form.Label>
                                                <strong>Quantity Ordered</strong>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Quantity Ordered"
                                                name="quantity_ordered"
                                                value={ele?.quantity_ordered || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3" controlId={`quantity_received_${ele?.id}`}>
                                            <Form.Label>
                                                <strong>Quantity Received</strong>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Quantity Received"
                                                name="quantity_received"
                                                value={ele?.quantity_received || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3" controlId={`total_price_${ele?.id}`}>
                                            <Form.Label>
                                                <strong>Total Amount</strong>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Total Amount"
                                                name="total_price"
                                                value={ele?.total_price || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>
                            ))
                        ) : (
                            <></>
                        )}


                        <div style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '16px' }} className='mb-4'>
                            Total Amount: {calculateTotalAmount().toFixed(2)}
                        </div>


                        <Row className="mb-5">
                            <MediaUploadFile setFileSelected={setFileSelected} setFile={setFile} file={file} fileSelected={fileSelected} setPayload={setInitialValue} payload={initialValue} />
                            <MediaPreview fileSelected={fileSelected} file={file} CancelImage={CancelImage} defaultUrl={initialValue?.media} />
                        </Row>
                        {/* {selectedItems?.length > 0 && (
                            <div>
                                <h5><strong>Selected Items</strong></h5>
                                <Table striped bordered hover responsive>
                                    <thead style={{ backgroundColor: '#5cb85c', color: 'white' }}>
                                        <tr>
                                            <th><strong>Title</strong></th>
                                            <th><strong>Price</strong></th>
                                            <th><strong>Quantity</strong></th>
                                            <th><strong>Total Price</strong></th> 
                                            <th><strong>Unit</strong></th>
                                            <th><strong>Action</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedItems.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.title}</td>
                                                <td>{item.price}</td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        value={item.quantity}
                                                        onChange={(e: any) => handleQuantityChange(index, Number(e.target.value))}
                                                        min={1}
                                                    />
                                                </td>

                                                <td>{typeof item.total_price === 'number' ? item.total_price.toFixed(2) : '0.00'}</td>

                                                <td>{item.unit}</td>
                                                <td>
                                                    <Button variant="danger" onClick={() => handleRemoveItem(index)}>
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </Table>

                                <div style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '16px' }}>
                                    Total Amount: {calculateTotalAmount().toFixed(2)}
                                </div>


                            </div>
                        )} */}

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddUpdateGoodReceipt;
