import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';


const MediaUploadFile = ({ setFile, setFileSelected, setPayload, payload, }: any) => {


    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setFileSelected(true);
        setFile(e?.dataTransfer?.files[0]);
    };

    const onChangeFile = (event: any) => {
        setFile(event?.target?.files?.[0]);
        setFileSelected(true);
    };

    return (
        <>
            <div className="col-lg-12 max-auto">
                <Form.Group className="mb-3" onDragOver={handleDragOver} onDrop={handleDrop}>
                    <input type="file" key={12121} name="imag2" id="imag2" onChange={onChangeFile} style={{ display: 'none' }} />
                    <label htmlFor="imag2" style={{ width: "100%", height: "90%" }}>
                        <div className=" col-12 ">
                            <div className="dz-default dz-message text-center d-flex flex-column g-2 dropzone dz-clickable border rounded bg-light p-3">
                                <i className="bi bi-cloud-arrow-up mb-4" style={{ fontSize: "5rem" }}></i>
                                <span className='fs-3 text-muted'>Upload files: Drag here or click...</span>
                            </div>
                        </div>
                    </label>
                </Form.Group>
            </div >

        </>
    )
}

export default MediaUploadFile
