import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

import { toast } from 'react-toastify'
import { getRequest, postRequest } from '../../../Server/Requests'
import { ColModal, GroupModal, LabelModal, RowModal, SelectModal } from '../../../widgets/forms/FormsModals'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'

let societyType = [{ name: "Individual", id: 0 }, { name: "Society", id: 1 },]
const UpdateSocietyForm = ({ showModal, handleClose, row, setRowData = null }: any) => {

    const [loader, setloader] = useState<any>(false)
    const [societyHurryData, setSocietyHurryData] = useState<any>([])

    const [payload, setPayload] = useState<any>({
        customerid: row?.id,
        societyid: null,
        societytype: null,

    })

    const loadInitialData = async () => {
        const response = await getRequest(URL_EndPoints(null)?.getsocietyinhurry, setloader)
        setSocietyHurryData(response?.data?.data)
    }

    useEffect(() => {
        loadInitialData()
    }, []) 

    const updateAddressHandler = async () => {
        setloader(true)
        const response = await postRequest(URL_EndPoints(null)?.updatesocietyinhurry, payload, setloader)
        if (response?.data?.status == 200) {
            if (setRowData) {
                let filters = societyHurryData?.find((ele:any)=>ele?.id == payload?.societyid)
                row.society.name = filters?.name
                setRowData(row)
            }
            toast.success(response?.data?.msg, { position: "top-center" })
            handleClose()
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
            handleClose()
        }

    }



    return (
        <>
            {/* fullscreen */}
            <Modal dialogClassName="modal-small" scrollable={true} animation={true} show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-muted fs-6'>Update Society</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <RowModal classes="d-flex flex-wrap flex-column">

                            <ColModal classes="mb-1 col-12 ">
                                <GroupModal classes="" controlId="" >
                                    <LabelModal show={true} classes="" title="Present Society" />
                                    <SelectModal
                                        classes="mb-2"
                                        title="Present Society"
                                        name="parent"
                                        keyName="name"
                                        id="id"
                                        isData={row?.societyid ? [{ name:  row?.society?.name , id: row?.society?.id }] : []}
                                        payload={{}}
                                        setPayload={{}}
                                        defValue={row?.societyid  ? row?.society?.id : ""}
                                    />
                                </GroupModal>
                            </ColModal>

                            <ColModal classes="mb-1 col-12 ">
                                <GroupModal classes="" controlId="" >
                                    <LabelModal show={true} classes="" title="Change to Society/individual by below option" />
                                    <SelectModal
                                        classes="mb-2"
                                        title="Individual"
                                        name="societytype"
                                        keyName="name"
                                        id="id"
                                        isData={societyType}
                                        payload={payload}
                                        setPayload={setPayload}
                                    // defValue={row?.addresstype}
                                    />
                                </GroupModal>
                            </ColModal>

                            <ColModal classes="mb-1 col-12 ">
                                <GroupModal classes="" controlId="" >
                                    <LabelModal show={true} classes="" title="Select Society name" />
                                    <SelectModal
                                        classes="mb-2"
                                        title="Individual"
                                        name="societyid"
                                        keyName="name"
                                        id="id"
                                        isData={societyHurryData}
                                        payload={payload}
                                        setPayload={setPayload}
                                    // defValue={row?.addresstype}
                                    />
                                </GroupModal>
                            </ColModal>

                        </RowModal>





                        <div className=' col-12 w-100 d-flex justify-content-end mt-8 me- text-center'>
                            {/* <Button variant="btn-sm me-3  btn btn-sm btn-flex  bg-body btn-active-color-dark btn-active-white px-8 p-2 fw-bold border border-secondary me-3 text-hover-primary fs-6 text-end " disabled={loader} onClick={updateAddressHandler}>
                                {
                                    loader ?
                                        <div className='d-flex fs-6'>
                                            <div className='spinner-border mr-15 fs-6' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating....</h4>
                                        </div>
                                        : <span className=''>Update </span>
                                }
                            </Button> */}




                            <button className="col-12 border border-none  mb-3 bg-primary p-2 py-3 rounded text-center mt- cursor-pointer tex-white"


                                onClick={() => updateAddressHandler()}
                                disabled={loader}
                            >
                                {
                                    loader ?
                                        <>
                                            <div className='text-center'>
                                                <span className='fw-bold text-white fs-6 text-center'>
                                                    <i className="fa-solid fa-database text-white fs-6 me-4"></i>
                                                    Updating
                                                    <span className="spinner-grow spinner-grow-sm  text-white me-2 ms-2" role="status" aria-hidden="true"></span>
                                                    <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                    <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                </span>
                                            </div>
                                        </>
                                        : <div className='fw-bold text-white fs-6 text-center '>
                                            <i className="bi bi-save2 text-white fs-7 me-2"> </i>
                                            Update Society
                                        </div>
                                }

                            </button>





                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default UpdateSocietyForm
