import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../../consts/Server/Requests';
import { Modal } from 'react-bootstrap';
import { Bars } from 'react-loader-spinner';
import SingleSelectSearchDetailsDefaultDyanmic from '../../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic';

const AddRemoveWhatsappGroup = ({ setShowModal, row, showModal, setPending, setStoreRow = null }: any) => {

    const [loader, setloader] = useState<any>(false)
    const [whatsappGroupsListData, setWhatsappGroupsListData] = useState<any>([])

    const [payload, setPayload] = useState<any>({
        cleaner_id: row?.id,
        wa_group_id: null,
        group_op: null,
    })

    useEffect(() => {
        async function load() {
            const groupsData = await getRequest(URL_EndPoints()?.getWhatsAppGroupsForFilter, null)
            setWhatsappGroupsListData(groupsData?.data?.data)
        }
        setShowModal && load()
    }, [])

    const handleChangeInputData = (event: any, name: string) => {
        setPayload({ ...payload, [name]: event ? event?.value : event, item: event ? event?.item : event })
    }

    const addOrRemoveWhatsappGroupHandler = async () => {
        setloader(true)
        const newPayload = {
            cleaner_id: payload?.cleaner_id,
            wa_group_id: payload?.wa_group_id,
            group_op: payload?.group_op,
        }
        if (newPayload?.cleaner_id && newPayload?.wa_group_id && newPayload?.group_op) {
            const response = await postRequest(URL_EndPoints()?.updateChampColumns, newPayload, setPending)
            if (setStoreRow && response?.data?.status == 200) {
                row.champ_whatsapp_group = {}
                row.champ_whatsapp_group.group_name = payload?.item?.label
                setStoreRow(row)
            }
            toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
            setShowModal()
        }
        else {
            toast.error("Something is missing...")
        }

        setloader(false)
    }
    const arr = [{ name: "Add Whatsapp Group", id: "+" }, { name: "Remove Whatsapp Group", id: "-" }]

    return (
        <>

            {showModal ? <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add / Remove Whatsapp Group </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row  ms-3 mb-3 mt-2 col-12'>

                        <SingleSelectSearchDetailsDefaultDyanmic
                            handleChangeInputData={handleChangeInputData}
                            HeaderTitle='Select Whatsapp Group'
                            SelectData={whatsappGroupsListData || []}
                            DynamicKeys={["group_name"]}
                            id={"id"}
                            name={"wa_group_id"}
                            targetValue={"value"}
                            defaultData={null}
                        ></SingleSelectSearchDetailsDefaultDyanmic>

                    </div>
                    <div className='col-12 p-1 ms-2'>
                        <select
                            className='form-select  me-2'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            id='group_op'
                            style={{ height: "38px" }}
                            onChange={(event: any) => setPayload({ ...payload, ["group_op"]: event.target.value })}
                        >
                            < option value="" >Select Type</option>
                            {
                                arr?.map((ele: any) => {
                                    return (
                                        < option value={ele?.id} >{ele?.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button dir="rtl" className="btn btn-success mt-4 text-end" onClick={addOrRemoveWhatsappGroupHandler} disabled={loader ? true : false}>
                            {loader ?
                                "Saving..."
                                : 'Save'}
                        </button>
                    </div>

                </Modal.Body>
            </Modal> : ''}


        </>
    )
}
export default AddRemoveWhatsappGroup
