import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { LocalBaseURL } from '../../../BaseURLmanagement'
import SingleSelectSearchDetails from '../../consts/Select/SingleSelectSearchDetails'
import { GetAllSources, GetAllCleaners, CreateRating } from './API'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { postRequest } from '../../consts/Server/Requests'
import CustomerDefaultSelect from '../../consts/Select/CustomerDefaultSelect'
import { ContainerCss, Create_BtnCss, PopCloseFormNotification, PopCloseFormNotificationPtag } from '../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet'

interface Props {
    handlePopUpClose: () => void
}

let IDS: any = "";
const AddRatingForm = ({ handlePopUpClose }: Props) => {
    const [Sources, setSources] = useState<any[]>([])
    const [Customers, setCustomers] = useState<any[]>([])
    const [Cleaners, setCleaners] = useState<any[]>([])
    const [SelectSourceId, SetSelectSourceId] = useState<any>('')
    const [comment, setComment] = useState<any>('')
    const [rating, setRating] = useState<any>('')
    const [reviewer, setReviewer] = useState<any>('')
    const [date_time, setDateTime] = useState<any>('')
    const [paid, setPaid] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [storeCustomer, setStoreCustomer] = useState<any>("")
    LocalBaseURL()
    const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")


    const [payload, setPayload] = useState<any>({
        cleaner_id: null,
        customer_id: null,
    })

    React.useEffect(() => {
        async function getSourcesInvoked() {
            const { data } = await GetAllSources(localKeyCheck)
            setSources(data.data)
        }

        async function getCleanersInvoked() {
            const { data } = await GetAllCleaners(localKeyCheck)
            setCleaners(data.data)
        }

        getSourcesInvoked()
        getCleanersInvoked()
    }, [])

    async function getCustomerDataBySearch(storeCustomerSearch: any) {

        setIsLoading(true)
        const response: any = await postRequest(URL_EndPoints()?.getActiveCustomerlistRating, { search: storeCustomerSearch }, setIsLoading);
        response?.data?.data && setCustomers(response?.data?.data);
        setIsLoading(false)
    }


    useEffect(() => {
        let x = setTimeout(() => {
            getCustomerDataBySearch(storeCustomer)
        }, 2000)
        return () => {
            setIsLoading(false)
            clearTimeout(x)
        }
    }, [storeCustomer])


    // to close pop up form
    const handleClose = () => {
        handlePopUpClose()
    }

    const handleOnchangeforms = (event: any) => {
        let { name, value, id } = event.target


        if (name == "source_id") {
            SetSelectSourceId(value)
        }


        if (name == "rating") {
            setRating(value)
        }


        if (name == "paid") {
            setPaid(value)
        }

        if (name == "date_time") {
            setDateTime(value)
        }
    }

    const handleSelectChange = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : null })
    }

    const dispatch = useDispatch()
    const handleAddRatingCreate = async () => {

        // cleaner id  and customer id for optional and rest will be mendatory
        if (!SelectSourceId || !rating || !date_time || !comment || !reviewer) {
            toast.error("Please select required fields!!")
            return
        }

        if(rating >5){
            toast.error("Ratings should be between 1 to 5")
            return
        }

        let payloadData = {
            "sourceid": SelectSourceId,
            "customerid": payload?.customer_id,
            "cleanerid": payload?.cleaner_id,
            "rate": rating,
            "date_time": date_time,
            // "ispaid": paid ? 1 : 0,
            "comment": comment,
            "reviewer": reviewer,
        }


        const response: any = await CreateRating(localKeyCheck, payloadData)
        if (response?.data?.status === 200) {
            toast.success(response?.data?.msg)
            dispatch({ type: "TOTAL_COUNT_CHANGED", payload: '' })
            // dispatch({ type: "PAGE_SIZE_CHANGED", payload: '' })
            handleClose()
        } else {
            toast.error(response?.data?.msg)
        }
    }


    return (
        <div className="p-8" style={ContainerCss}>
            <div style={PopCloseFormNotification}><p style={PopCloseFormNotificationPtag} onClick={handleClose} >X</p></div>
            <div className="p-5 mr-auto">
                <div className='modal-content ' >
                    <div className="row mb-5">
                        <hr />
                        <div className="col-6  mb-3">
                            <h5>Rating</h5>
                            <input
                                placeholder='Enter Rating..'
                                name='rating'
                                type='number'
                                value={rating}
                        
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={(e) => handleOnchangeforms(e)}
                            />
                        </div>
                        <div className="col-6  mb-3">
                            <h5>Comment</h5>
                            <textarea
                                placeholder='Comment...'
                                name='comment'
                                value={comment}
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                        </div>
                        <hr />
                    </div>

                    {/* ==================================================================================================== */}
                    <div className="row mb-5">

                        <div className='d-flex'>
                            <div className="col-6  mb-3">
                                <h5>Source</h5>
                                <select
                                    className='form-select form-select-solid me-2'
                                    onChange={(e) => handleOnchangeforms(e)}
                                    value={SelectSourceId}
                                    name="source_id"
                                >
                                    <option >Select Source</option>
                                    {Sources?.map((item: any, index: number) => {
                                        return (
                                            <option value={item.id} key={index}>
                                                {item.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col-6  mb-3">
                                <h5>Customer</h5>



                                <CustomerDefaultSelect
                                    handleChangeInputData={handleSelectChange}
                                    HeaderTitle='Select Customer'
                                    SelectData={Customers}
                                    DynamicKey={'first_name'}
                                    DynamicKey2={'last_name'}
                                    DynamicKey3={'phone'}
                                    id={'id'}
                                    name='customer_id'
                                    defaultData={null}
                                    setSeacrch={setStoreCustomer}
                                    isLoading={isLoading}
                                ></CustomerDefaultSelect>
                            </div>
                        </div>

                        <div className="col-12  mb-3">
                            <h5>User Id</h5>
                            <input
                                placeholder='User Social Media Name'
                                name='reviewer'
                                type='text'
                                value={reviewer}
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={(e) => setReviewer(e.target.value)}
                            />
                        </div>
                        <hr />
                    </div>


                    {/* ============================================== section ========================================================= */}
                    <div className="row mb-5">
                        <div className="col-6  mb-3">
                            <h5>Cleaner</h5>
                            <SingleSelectSearchDetails
                                handleChangeInputData={handleSelectChange}
                                HeaderTitle="Select Cleaner"
                                SelectData={Cleaners}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name="cleaner_id"
                            ></SingleSelectSearchDetails>

                        </div>
                        {/* <div className="col-6  mb-3">
                            <h5>Paid</h5>
                            <div className="mb-10">
                                <div className="form-check form-check-custom form-check-solid form-check-lg">
                                    <input className="form-check-input" type="checkbox" id="paid" name='paid' onChange={(e) => handleOnchangeforms(e)} />
                                    <label className="form-check-label">
                                        Is Cleaner Paid ?
                                    </label>
                                </div>
                            </div>
                        </div> */}
                        <hr />
                    </div>

                    <div className="row mb-5">

                        <div className="col-6  mb-3">
                            <h5>Date Time</h5>
                            <div className="mb-10">
                                <div className="form-check form-check-custom form-check-solid form-check-lg">
                                    <input className="form-control" type="datetime-local" id="datetime" name='date_time' onChange={(e) => handleOnchangeforms(e)} />

                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                <button
                    style={Create_BtnCss}
                    type='submit'
                    className='btn btn-primary '
                    data-kt-users-modal-action='submit'
                    onClick={handleAddRatingCreate}
                >
                    <span className='indicator-label' >Add Rating</span>
                </button>
            </div>
        </div>
    )
}
export default AddRatingForm
