import { getName } from "../admins/adminUtils";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import PermissionContextProvider from "../admins/adminComponents/PermissionContextProvider";
import { adminMenuActionPermissionKeys } from "../admins/adminConstant";

export const NotAtHomeColumns = (champTrackData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        selector: (row: any) => row?.id ?? "NA",
        sortable: true,
        sortField: 'id',
        width: "80px"
    },
    {
        id: 2,
        reorder: true,
        name: "NAME",
        cell: (row: any) => {
            return getName(row?.agentAllotedItemAdmin)
        },
        sortable: true,
        sortField: 'agentAllotedItemAdmin.first_name',
        width: "200px",
    },
 

]
