
export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },
  {
    name: 'Cham Status',
    cell: (row: any) => row?.champStatus?.name,
    sortField: 'champStatus.name',
    sortable: true,
    reorder: true,
    id: 3,
    width: '100px',
  },

  {
    name: 'Cham Sub Status',
    cell: (row: any) => row?.champSubStatus?.name,
    sortField: 'champSubStatus.name',
    sortable: true,
    reorder: true,
    id: 4,
    width: '150px',
  },
  {
    name: 'Cham Status Group',
    cell: (row: any) => row?.champStatusGroup?.name,
    sortField: 'champStatusGroup.name',
    sortable: true,
    reorder: true,
    id: 5,
    width: '150px',
  },
  {
    name: 'Cx Status',
    cell: (row: any) => row?.customerStatus?.name,
    sortField: 'customerStatus.name',
    sortable: true,
    reorder: true,
    id: 6,
    width: '150px',
  },
  {
    name: 'Cx Sub Status',
    cell: (row: any) => row?.customerSubStatus?.name,
    sortField: 'customerSubStatus.name',
    sortable: true,
    reorder: true,
    id: 7,
    width: '150px',
  },
  {
    name: 'Cx Status Group',
    cell: (row: any) => row?.customerStatusGroup?.name,
    sortField: 'customerStatusGroup.name',
    sortable: true,
    reorder: true,
    id: 8,
    width: '150px',
  },
  {
    name: 'Is Common',
    cell: (row: any) => row?.isCommonCheck == 1 ? "Yes" : "No",
    sortField: 'isCommonCheck',
    sortable: true,
    reorder: true,
    id: 9,
    width: '100px',
  },


]
