import { postRequest } from "./Server/Requests";
import { URL_EndPoints } from "./Server/URL_EndPoints";

export const timeCaptureOpenChat = async (sourceId: any, ticket_type: any) => {
    const timeCapture: any = await postRequest(URL_EndPoints(null)?.updateTicketOpeningTime, {
        ticket_id: sourceId,
        ticket_type: ticket_type,
    }, null);

    if (timeCapture?.data?.status == 200) {
        localStorage.setItem("timeCapture", JSON.stringify(timeCapture?.data?.data?.unique_activity_id))
    }
}

export const timeCaptureCloseChat = async (unique_activity_id: any, ticket_type: any) => {

    const timeCapture: any = await postRequest(URL_EndPoints(null)?.updateTicketClosingTime, {
        unique_activity_id: unique_activity_id,
        ticket_type: ticket_type,
    }, null);

    if (timeCapture?.data?.status == 200) {
        localStorage.setItem("timeCapture", JSON.stringify(timeCapture?.data?.unique_activity_id))
    }
}
