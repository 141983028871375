import React from 'react'
const AdminSingleDateFilter = ({ onChangeHandler, HeaderTitle = "", payload = "", name = "", label = "" }: any) => {

  return (
        <input
          type='date'
          style={{ height: '38px' }}
          value={payload[name]}
          name={name}
          onChange={(event: any) =>
            onChangeHandler(event.target, name, true)
          }
          className='w-100 form-control me-2 align-start'
        />
  )
}
export default AdminSingleDateFilter

