import { useEffect, useState } from "react";

export const MasterDeactivationNotesColumns = (categoryListData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        cell: (row: any) => row?.id ?? "NA",
        sortable: true,
        width: "80px"
    },
    {
        id: 2,
        reorder: true,
        name: "NOTE",
        cell: (row: any) => row?.note ?? "NA",
        sortable: true,
    },
    // {
    //     id: 3,
    //     reorder: true,
    //     name: "CATEGORY NAME",
    //     cell: (row: any) => <ShowCategory row={row} categoryListData={categoryListData} />,
    //     sortable: true,
    // },
]


const ShowCategory = ({ row, categoryListData }: any) => {
    const [categoryName, setCategoryName] = useState<any>(null)
    useEffect(() => {
        const categgoryName = categoryListData?.find((ele: any) => ele?.id == row?.category_id)
        categgoryName && setCategoryName(categgoryName)
    }, [categoryListData, row])
    
    return (
        <span>
            {categoryName?.name ? categoryName?.name : "NA"}
        </span>
    )
}


