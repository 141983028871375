import React, { useState, useEffect, useRef } from 'react'
import { LocalBaseURL } from '../../../../BaseURLmanagement'
import { columns } from './Columns'
import '../custom.css'
import { KTCardBody } from '../../../../_metronic/helpers'
import { AdminServerSidePaginationLists } from '../../admins/adminServerSidePaginationLists/AdminServerSidePaginationLists'
import {
  getCurrentFilterHandler,
  getFilterDataBySearch,
  initialPayload,
  operators,
  removeFilterHandler,
} from '../../../consts/DynamicFilteration/components/CommonData'
import {
  useAdminListApiUrl,
  useAdminManagementPermission,
  useAdminSmartFilterList,
} from '../../admins/adminUtils'
import { KeywiseComponents } from '../../../consts/DynamicFilteration/KeywiseComponents'
import { DynamicFilter } from '../../../consts/DynamicFilteration/FilterHandler'
import {  postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import CreateUpdateWarehouseAlert from './CreateUpdateWarehouseAlert'
import Refresh from '../../../consts/Refresh'
import { BulkUtilButton } from '../../../consts/reuseModules/BulkUtilButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const WarehouseAlertStatus = () => {
  LocalBaseURL()
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0)
  const [perPageSize, setPerPageSize] = useState(10)
  const [direction, setdirections] = useState<any>('')
  const [payload, setPayload] = useState<any>(initialPayload())
  const [filter, setFilter] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [toggledClearRows, setToggleClearRows] = React.useState(false)
  const [search, setSearch] = useState<any>('')
  const [filterkey, setFilterKey] = useState<any>({})
  const [sortby, setSortBy] = useState<any>('')
  const [page, setPages] = useState<any>(1)
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>('')
  const [dynFilter, setDynFilter] = useState<any>('')
  const [sortedColumns, setSortedColumns] = useState<any>(columns())

  async function InvokedRendered(payloads: any, setloading: any) {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    setPending(true)
    const { data } = await postRequest(
      URL_EndPoints(null)?.getWarehouseAlertStatus,
      payloads,
      setloading
    )

    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.warehouseAlertStatusPagination)
    setPending(false)
  }

  useEffect(() => {
    if (filterData) {
      const newCol = columns(null, filterData?.map((ele: any) => ele?.id));
      setSortedColumns(newCol);
    }
  }, [filterData]);

  const onChangeHandler = (
    event: any,
    key: any,
    value: any,
    defVal: any = false,
    timestamp: any,
    multiVal: any
  ) => {
    setPayload({
      ...payload,
      filterData: {
        ...payload.filterData,
        [key]: {
          ...payload.filterData[key],
          [value]: event?.value,
          [key + '_' + value]: defVal ? event : '',
          isDate: defVal ? false : true,
          timestamp: timestamp === 'null' ? undefined : timestamp,
          multiVal: multiVal,
        },
      },
    })
  }

  const handlePagination = (
    search: any = '',
    sortBy = '',
    sortDirection: any = 'desc',
    page: any = 1,
    perPageSize: any = 10
  ) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(
      handlePagination(search, sortby, direction, page, perPageSize),
      setPending
    )
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(
      key,
      currentItemRemovedOrAdded,
      setFilterKey,
      setSelectedFilterData,
      selectedFilterData,
      setStoreFilterList,
      storeFilterList,
      payload,
      setPayload
    )
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)
    InvokedRendered(
      handlePagination(search, column.sortField, sortDirection, page, perPageSize),
      setPending
    )
  }

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  }

  useEffect(() => {
    let timer = setTimeout(async function () {
      search && setPending(true)
      search &&
        InvokedRendered(
          handlePagination(search && search, sortby, direction, page, perPageSize),
          setPending
        )
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [search])

  useEffect(() => {
    !search &&
      InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [search])

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    selectedFilterData?.length === 0 &&
      InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [selectedFilterData?.length])

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  }

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }
  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords &&
        (await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
          keyword: storeSearchKeywords,
        }))
      setIsLoadingFilter(false)
    }, 2000)
    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList('warehouseAlertStatusKey')
  }, [])

  let initialAddEditPayload = {
    warehouse_id: null,
    item_id: null,
    reminder_stock: null,
    alert_stock: null,
  }

  const [initialValue, setInitialValue] = useState<any>(initialAddEditPayload);
  const [show, setShow] = useState(false);
  const [apiUrl, setApiUrl] = useState("createWarehouseAlertStatus");

  const handleEdit = async (row: any) => {
    setApiUrl("createWarehouseAlertStatus")
    setShow(true);
    setInitialValue({
      warehouse_id: row?.warehouse_id,
      item_id: row?.item_id,
      reminder_stock: row?.reminder_stock,
      alert_stock: row?.alert_stock,
    });
  };


  const addWareHouseAlert = () => {
    setShow(true);
    setApiUrl("createWarehouseAlertStatus");
    setInitialValue(initialAddEditPayload);
  };

  const refreshNow = () => {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
  }

  return (
    <>
      <KTCardBody className='card py-4' key='warehouseAlertStatusKey'>
        <AdminServerSidePaginationLists
          title='Warehouse Alert Status'
          reorderColumnTitle='warehouseAlertStatusKey'
          sortedColumns={[
            {
              name: 'Id',
              cell: (row: any) => row?.id,
              sortField: 'id',
              sortable: true,
              reorder: true,
              id: 1,
              width: '80px',
            },
            {
              name: 'Edit',
              cell: (row: any) => (
                <>
                  <span><FontAwesomeIcon className='me-2 ms-2 text-muted fs-4 cursor-pointer' icon={faEdit} onClick={() => handleEdit(row)} /></span>
                </>
              ),
              reorder: true,
              id: 2,
              width: '100px',
            }, ...sortedColumns]}
          filterData={filterData}
          pending={pending}
          clearSelectedRows={toggledClearRows}
          totalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          conditionalRowStyles={{}}
          handleSort={handleSort}
          keyName={'warehouseAlertStatusKey'}
          search={search}
          setSearch={setSearch}
          paginationPerPage={perPageSize}
          showFilter={true}
          ExpandedComponent={() => <></>}
          expandableRows={false}
          filterHandler={{
            onChangeHandler: onChangeHandler,
            actionHandler: actionHandler,
            getCurrentFilter: getCurrentFilter,
            removeFilter: removeFilter,
            payload: payload,
            Component: DynamicFilter,
            filterData: filter,
            OperatorsData: operators,
            selectedFilterData,
            filterComponents: KeywiseComponents,
            storeFilterList: storeFilterList,
            filterkey: filterkey,
            setSeacrch: setSearchFilter,
            isLoadingFilter: isLoadingFilter,
            listApiUrl: listApiUrl('unsuccessfullSubscriptionskey'),
          }}

          SubHeaderComponentRightSection={() => <>
            <Refresh refreshNow={refreshNow} />
            <BulkUtilButton title="Add Warehouse Alert" filteredSelectedRows="static" setShow={addWareHouseAlert} />

          </>
          }

          SubHeaderComponentActionButtons={() => <></>}
        />
      </KTCardBody>


      {
        show ? <CreateUpdateWarehouseAlert
          show={show}
          refreshNow={refreshNow}
          setShow={setShow}
          initialValue={initialValue}
          setInitialValue={setInitialValue}
          apiUrl={apiUrl}
        /> : <></>
      }

    </>
  )
}

export default WarehouseAlertStatus
