import React, { useEffect, useState } from 'react'
import Not_Found_Data from '../../Not_Found_Data'
import { SubscriptionsBody } from './SubscriptionsBody'
import clsx from 'clsx';
import "./custom.css"
import { postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import IsLoading from '../../IsLoading/IsLoading';
const UnsuccessfulSubscriptions = ({ ctAddresses, customerDetailData }: any) => {
  const [activeColor, setActivecolor] = useState<any>(null)
  const [data, setData] = useState<any>([])
  const [loader, setLoader] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState([])

  const getData = async () => {
    setLoader(true)
    const response = await postRequest(URL_EndPoints()?.getUnSuccessfulSubscriptionsList, { customerid: customerDetailData?.id }, setLoader)
    setData(response?.data?.data)
  }

  useEffect(() => {
    getData()
  }, [customerDetailData?.id])

  const FilterData = (data: any, index: any) => {
    setSubscriptionData(data)
    setActivecolor(index)
  }

  return (
    <>
      <div className='row d-flex flex-wrap justify-content-center gap-2 align-items-center mb-4 mt-3 '>
            {
              loader ? <IsLoading />  : data?.map((data: any, index: number) => {
                return <div
                  className={`border card col-2 text-${activeColor == index ? "white" : "dark"} fw-bold text-center  p-1 cursor-pointer text-hover-white bg-hover-primary bg-${activeColor == index ? "primary" : ""}`} onClick={() => FilterData(data?.vehicleSubscriptions, index)}>
                    {data?.vehicleBrand?.name ?? "Not Set"}
                    <br />
                    {data?.vehicleno ?? "Not Set"}
                </div>
              })
            }
      </div>
      <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
        <div
          // style={{ maxHeight: SubscrptionsData?.length !== 0 ? "550px" : "" }}
          className={clsx('scroll-y me-n5 pe-5  max')}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
        >
          {
            <div className='margins'>
              {subscriptionData?.length > 0 ? (
                subscriptionData?.map((element: any, index: number, arr: any) => {
                  return (
                    <SubscriptionsBody
                      className='card-xl-stretch mb-xl-8'
                      image='abstract-4.svg'
                      title={element.packageDetail?.name}
                      subscriptions={element}
                      index={index}
                      random="unsuccess"
                      description='Create a headline that is informative<br/>and will capture readers'
                      lengths={arr?.length}
                      ctAddresses={ctAddresses}
                    />
                  )
                })
              ) : (
                <Not_Found_Data headerTitle={subscriptionData?.length === 0 ? 'No Subscriptions Found' : "Select Vehicle"}></Not_Found_Data>
              )}
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default UnsuccessfulSubscriptions
