import { faBan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import IsCustomerWhatsappEnabledMessageSenderIndividual from "./IsCustomerWhatsappEnabledMessageSenderIndividual"

const IsWhatsAppEnabled = ({ row, Ids, templateType, keyName, ApiEndUrl, to ,IsRequired}: any) => {

    const [show, setShow] = useState(false)

    const handleNotifyPopUpClose = () => {
        setShow(false)
    }

    return <>

        <span className='cursor-pointer me-4 fs-5 text-primary'>

            {
                row?.isWhatsApp == 2 ?
                    <span className='cursor-pointer me-2 fs-7 text-danger' style={{ cursor: "not-allowed" }}><FontAwesomeIcon icon={faBan} className='text-danger' /> </span>
                    :
                    <i className={`cursor-pointer fab fa-whatsapp text-${row?.isWhatsApp == 3 ? "danger" : "success"}`} onClick={() => setShow(true)}></i>
            }

        </span>


        {show ? <IsCustomerWhatsappEnabledMessageSenderIndividual
            showNotifyPopUp={show}
            handleNotifyPopUpClose={handleNotifyPopUpClose}
            row={row}
            keyName={keyName}
            Ids={Ids}
            templateType={templateType}
            ApiEndUrl={ApiEndUrl}
            to={to}
            IsRequired={IsRequired}
        /> : ''}

    </>


}

export default IsWhatsAppEnabled