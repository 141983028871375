import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { getRequest, postRequest } from '../../../Server/Requests'
import SingleSelectSearchCategoryDefault from '../../../Select/SingleSelectSearchCategoryDefault'
import { adminToastRunner } from '../../../../modules/admins/adminUtils'
import { useSelector } from 'react-redux'
const InactiveCampaignStatus = ({ show, setShow, row, reasonData, defaultData }: any) => {
    

    const [loader, setLoader] = useState(false)
    const [payload, setPayload] = useState({
        customerID: row?.id,
        renewal_reason_id: null,
    })
    const handleChange = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
    }

    const handleInactiveReason = async () => {
        setLoader(true)
        if (payload?.renewal_reason_id) {
            const response = await postRequest(URL_EndPoints(null)?.updateCustomerInactiveData, payload, setLoader)
            adminToastRunner(response)
        } else {
            toast.error("Reason id missing")
            setShow(false)
        }
    }

  

    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Status Inactive</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-1`}>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select  Reason"}
                            SelectData={reasonData}
                            DynamicKey={"name"}
                            id={"id"}
                            name={"renewal_reason_id"}
                            defaultData={defaultData}
                        />
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => handleInactiveReason()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default InactiveCampaignStatus
