const initState = {
    champAvailty:{champAvailty:null, champAvailtyRender:null, from:null},
    temporayAvailty: null,
    temporaryAvailtyRender: 0,
    modalOpenData: {},
    welcomeCampaingStatus: {},
    modalOpenDataDue: {},
    handleCloseForm:null ,
    Chat_Update_Default_value_P2P:{},
    Chat_Update_Default_value_due_date:{},
    RowDataUpdateTags:{},
    modelOpenFromCustomerDashboard:{},


}


export const Schedules = (state = initState, { type, payload }: any) => {
  
    switch (type) {
      
        case "CHAMP_AVALITY":
            return { ...state, champAvailty: payload, }
        case "TEMPORARY_ASSIGN":
            return { ...state, temporayAvailty: payload.champAvailty, temporaryAvailtyRender: payload.champAvailtyRender }
        case "MODAL_OPEN":
            return { ...state, modalOpenData: payload }
        case "MODAL_OPEN_CAMPAING_STATUS":
            return { ...state, welcomeCampaingStatus: payload }
        case "modelOpenFromCustomerDashboard":
            return { ...state, modelOpenFromCustomerDashboard: payload }
        case "MODAL_OPEN_DUE":
            return { ...state, modalOpenDataDue: payload }
        case "UPDATE_CHAT_DEFAULT_VALUE":
            return { ...state, Chat_Update_Default_value_P2P: payload }
        case "UPDATE_CHAT_DEFAULT_VALUE_DUE_DATE":
            return { ...state, Chat_Update_Default_value_due_date: payload }
        case "UPDATE_ROW_DATE":
            return { ...state, RowDataUpdateTags: payload }

    
        default:
            return state
    }
}


// const champAvailty = useSelector((store:any)=>store.Schedules.champAvailty)
// const champAvailtyRender = useSelector((store:any)=>store.Schedules.champAvailtyRender)
// const temporayAvailty = useSelector((store:any)=>store.Schedules.temporayAvailty)
// const temporaryAvailtyRender = useSelector((store:any)=>store.Schedules.temporaryAvailtyRender)
// const modalOpenDataDue = useSelector((store:any)=>store.Schedules.modalOpenDataDue)
// const Chat_Update_Default_value_P2P = useSelector((store:any)=>store.Schedules.Chat_Update_Default_value_P2P)
// const Chat_Update_Default_value_due_date = useSelector((store:any)=>store.Schedules.Chat_Update_Default_value_due_date)
// const rowid = useSelector((store:any)=>store.Schedules.rowid)
// const tag_Data_Update = useSelector((store:any)=>store.Schedules.tag_Data_Update)