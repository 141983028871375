import React from 'react'
import { AdminToolTip } from './AdminToolTipModal'
import clsx from 'clsx'

const ListRowToolTip = ({ anchorId, title, subTitle, classes }: any) => {
    return (
        <>
            <div id={anchorId} className={clsx('cursor-pointer' + classes ? classes : "")}>{subTitle}</div>
            <AdminToolTip anchorId={anchorId} title={title} place={""} />
        </>
    )
}

export default ListRowToolTip
