// SOME REUSE DATA  

export const bulkTicketPayloadData = (removeKey: any) => {
    let data: any = {
        admins: [],
        cleaners: [],
        should_unassign: false,
        admins_to_unassign: [],
        cleaners_to_unassign: [],
        dueDate: '',
    }

    for (let key in removeKey) {
        delete data[key]
    }

    return data

}
