import React, { memo, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import DialogBox from '../../modules/TicketsList/DialogBox/DialogBox'
import ChatHeaderChips from '../Chips/ChatHeaderChips'
import { toast } from 'react-toastify'
import "react-tooltip/dist/react-tooltip.css";
import { useDispatch, useSelector } from 'react-redux'
import { ChatTicketID } from '../../../Redux/Action/Chats/ChatAction'
import { URL_EndPoints } from '../Server/URL_EndPoints'
import { getRequest, postRequest } from '../Server/Requests'
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ChatBox } from './ChatBox'
import "./custom.css"
import { DueDateUpdateDashboardForm } from './DueDateUpdateDashboard/DueDateUpdateDashboardForm'
import moment from 'moment'
import AssigningFormToUser from './Components/Forms/AssigningFormToUser'
import UpdateSourceForms from './Components/Forms/UpdateSourceForms'
import TicketStatusForm from './Components/Forms/TicketStatusForm'
import IsLoading from '../IsLoading/IsLoading'
import UpdateTicketCategory from './Components/Forms/UpdateTicketCategory'
import UpdateTicketTag from '../../modules/TicketsList/UpdateTicketTag'
import { timeCaptureCloseChat } from '../timeCaptureOpenCloseChat'
import TicketStatus from '../reuseModules/TicketStatus'
import CustomerActionLayout from './CustomerActionLayout'
import { BulkUtilButtonWrapper } from '../reuseModules/BulkUtilButton'
import { catchCurrentOpenedUrl } from '../components/Utility'
import AdminLastStatusCustomerForm from '../../modules/admins/adminComponents/AdminLastStatusCustomerForm'
import { adminToastRunner, checkActionPerformedAfterCall, enableFeatures4CustomerDashboard, setIsChatOpenedOrNot } from '../../modules/admins/adminUtils'
import PermissionContextProvider from '../../modules/admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../../modules/admins/adminConstant'
import AdminDueDateForm from '../../modules/admins/adminComponents/AdminDueDateForm'
import SweetAlertCloseTimer from '../SweetAlert/SweetAlertCloseTimer'
import ActionOnCallAlertMessage from './ActionOnCallAlertMessage'

const Chats = ({ TicketData, TicketCustomerInformationPopupBTN, PopOpenClose, source, setShowImagesToggle, setShowImagesLink, customerDetailData, WhatsappMessage, WhatsappTemplate, MessageSource, handleStates, setcurrenOpenedTicketData, setWhatsappMessage, invokedApi }: any) => {
  const renewalReasonDatas = useSelector((store: any) => store.ReasonsListDataReducer.renewalReason)
  const visitorStatuses = useSelector((store: any) => store.VisitorDataReducer.visitorStatuses)
  const updatedP2PStatus = useSelector((store: any) => store.Schedules.modalOpenData) // to update from outside 
  const updateTicketSpecificTag = useSelector((store: any) => store.Schedules.modelOpenFromCustomerDashboard) // to update from outside 
  const cxLastStatusList = useSelector((store: any) => store.cxReducer.lastStatusListData[catchCurrentOpenedUrl()]) || []
  const campaingStatusListData = useSelector((store: any) => store.cxReducer.campaingStatusListData[catchCurrentOpenedUrl()]) || []
  const currentRowDataFromList = useSelector((store: any) => store.cxReducer.currentRowDataFromList[catchCurrentOpenedUrl()]) || {}
  const ticketDataRedux = useSelector((store: any) => store.ChatReducers.TicketData)

  // const [showRes, setshowRes] = useState<any>({})
  const [showResTag, setShowResTag] = useState<any>({})
  const [rowTagDash, setRowTagDash] = useState<any>({})
  const [TicketDatas, setTicketsData] = React.useState<any>(TicketData)
  const [DueDateModal, setDueDateModal] = React.useState<any>(false)
  const [TicketTepliesData, setTicketTepliesData] = React.useState<any>([])
  const [lastStatusRedux, setLastStatusRedux] = useState<any>(ticketDataRedux)
  const [AdminData, setAdminData] = React.useState<any>([])
  const [Supervisordata, setSupervisordata] = React.useState<any>([])
  const [TicketCategoryData, setTicketCategoryData] = React.useState<any>([])
  const [subTicketCategoryData, setsubTicketCategoryData] = React.useState<any>([])
  const [loading, setloading2] = React.useState<any>(false)
  const [UpdateFormPopUp, setUpdateFormPopUp] = React.useState<boolean>(false)
  const [assignformPopUp, setAssignformPopUp] = React.useState<boolean>(false)
  const [updateCategoryPopup, setUpdateCategoryPopup] = React.useState<boolean>(false)
  const [updateSourcePopup, setUpdateSourcePopup] = React.useState<boolean>(false)
  const [ticketStatusUpdateModal, setTicketStatusUpdateModal] = React.useState<boolean>(false)
  const [assignToUserUpdateModal, setAssignToUserUpdateModal] = React.useState<boolean>(false)
  const [showLastStatusModal, setShowLastStatusModal] = React.useState<boolean>(false)
  // const [showCampaignStatusModal, setShowCampaignStatusModal] = React.useState<boolean>(false)
  const setShowCampaignStatusModal = () => {
    dispatch({
      type: "MODAL_OPEN_CAMPAING_STATUS", payload: {
        toggle: true,
        id: ticketDataRedux?.id,
        random: Math.random(),
      }
    })
  }

  const [defaultLastStatus, setDefaultLastStatus] = React.useState<any>(null)
  const [sourcesData, setSourcesData] = React.useState<any>([])
  const dispatch = useDispatch()

  async function invokedTicket() {

    !ticketDataRedux?.externalTicketSource && ticketDataRedux?.customerid == "" && setloading2(true)
    let payloads = { ticketid: TicketData?.id }
    const getticketrepliesData = await postRequest(URL_EndPoints()?.getticketreplies, payloads, setloading2)
    setcurrenOpenedTicketData(getticketrepliesData?.data?.ticket)
    if (getticketrepliesData.data.ticket) {
      getticketrepliesData.data.ticket.renewal_reason_id = TicketData?.reasonid || null
    }
    setTicketsData(getticketrepliesData?.data?.ticket)
    setTicketTepliesData(getticketrepliesData?.data)

  }

  React.useEffect(() => {
    !ticketDataRedux?.externalTicketSource && ticketDataRedux?.customerid == "" && setloading2(true)
    async function invoked() {
      const adminsData = await getRequest(URL_EndPoints(null)?.getAdminList, null)
      setAdminData(adminsData?.data?.data)
      const supervisorData = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
      setSupervisordata(supervisorData?.data?.data)
      const TicketCategory = await getRequest(URL_EndPoints(null)?.getTicketCategories, null)
      setTicketCategoryData(TicketCategory?.data?.data)
      const getTicketSubCategoriesData = await getRequest(URL_EndPoints(null)?.getTicketSubCategories, null)
      setsubTicketCategoryData(getTicketSubCategoriesData?.data?.data)
      const TicketSource = await getRequest(URL_EndPoints(null)?.getTicketSources, null)
      setSourcesData(TicketSource?.data?.data)
    }

    TicketCategoryData?.length === 0 && invoked()
    setTimeout(() => {
      setloading2(false)
    }, 5000)
    TicketData?.id && invokedTicket()

  }, [TicketData?.id, ticketDataRedux?.id])


  // useEffect(() => {

  //   let updatedData: any;

  //   if (ticketDataRedux?.source_status === "visitor") {
  //     if (updatedP2PStatus?.reasonsid) {
  //       lastStatusRedux.reasonid = updatedP2PStatus?.reasonsid
  //     }
  //     setLastStatusRedux(ticketDataRedux)


  //     updatedData = visitorStatuses?.filter((ele: any) => {
  //       return ele?.id === ticketDataRedux?.reasonid
  //     })
  //   } else {

  //     if (updatedP2PStatus?.reasonsid) {
  //       lastStatusRedux.reasonid = updatedP2PStatus?.reasonsid
  //     }

  //     setLastStatusRedux(ticketDataRedux)
  //     updatedData = renewalReasonDatas?.filter((ele: any) => {
  //       return ele?.id === lastStatusRedux?.reasonid
  //     })
  //   }
  //   setshowRes(updatedData?.length > 0 ? updatedData[0] : { name: "Update Status" })


  // }, [renewalReasonDatas, ticketDataRedux, updatedP2PStatus])


  // const updateLastStatus = (rowids: any) => {
  //   dispatch({
  //     type: "MODAL_OPEN", payload: {
  //       toggle: true,
  //       id: rowids,
  //       reasonsid: lastStatusRedux?.reasonid
  //     }
  //   })
  // }

  // const DueDateUpdate = (rowids: any) => {
  //   setDueDateModal(true)
  // }


  const OnCloseCustomerDashboardChangeState = () => {
    dispatch({
      type: "modelOpenFromCustomerDashboard", payload: {
        toggle: false,
        id: null,
        render: null,
        tag: null,
      }
    })


    dispatch({
      type: "MODAL_OPEN", payload: {
        toggle: false,
        id: null,
        reasonsid: null,
      }
    })

    setTicketTepliesData([])
    setTicketsData([])


    dispatch({
      type: "CLICK_UP_TASK_COMMENT", payload: {
        clickUp: null,
        clcikUp_render: null
      }
    })


    dispatch(ChatTicketID({
      id: null,
      customerid: null,
      random: null,
    }))

    dispatch({
      type: 'CHAT_JOB_LIST_EVENT_KEY',
      payload: {
        JobListRender: null,
        JobListEventKey: 2,
        JobListSubscriptionid: "",
        JobListDate: {
          startdate: "",
          enddate: "",
        },
      },
    })

    setShowImagesToggle(false)
    toggleHandlerCloseall()
    // change redux state ................


    dispatch({
      type: 'CHAMP_AVALITY', payload: {
        champAvailty: null,
        champAvailtyRender: null,
        from: null
      }
    })


    dispatch({
      type: 'INITIATE_REFUND', payload: {
        Intiate_Refund: null,
        Intiate_Refund_Render: null
      }
    })


    dispatch({
      type: 'CHANGE_STATUS', payload: {
        Change_Status: null,
        Change_Statu_Render: null
      }
    })


    dispatch({
      type: "INITIAL_VALUE",
      payload: Math.random()
    })


    dispatch({
      type: "DEAFUALT_CHAT_TAB", payload: {
        DefaultTabChat: 2,
        render: Math.random()
      }
    })


    dispatch({
      type: "DUBLICATE_rEMOVE", payload: {
        dublicateid: null,
        dublicate_Render: null
      }
    })


    dispatch({
      type: "CURRENT_MESSAGE_SOURCE", payload: {
        CurrentMessageSource: 1,
        CurrentMessageSourceRender: Math.random(),
        from: "TicketMessageSource"
      }
    })

    handleStates()
    timeCaptureCloseChat(JSON.parse(localStorage.getItem("timeCapture") || "0") || "0", null)
    setIsChatOpenedOrNot(null, true, "customer_chat_opened")
    dispatch({
      type: "NOTIFICATION_DATA", payload: {
        chatName: null,
        id: null,
        data: {}
      }
    })

  }

  const noticationCredential = useSelector((store: any) => store.ChatReducers.noticationCredential?.customer_type)
  const [pendingActionOnCall, setPendingActionOnCall] = useState(false)
  const [re_Render, setRe_Render] = useState<any>(null)
  const [isCloseAllowed, setiICloseAllowed] = useState(false)
  const closeRef = useRef<any>(null)

  const closeHandle = async () => {

    if (noticationCredential == "inactive" || noticationCredential == "prospect") {
      setPendingActionOnCall(true)
      setRe_Render(200)
      const finalResponse = await checkActionPerformedAfterCall("checkIfAgentAllowedToCloseDashboard", { customerID: customerDetailData?.id }, setPendingActionOnCall)
      setRe_Render(null)
      setiICloseAllowed(finalResponse?.canCloseDashboard)

      if (finalResponse?.canCloseDashboard) {
        dispatch({
          type: "CHECK_DASHBOARD_CLOSE_STATUS", payload: null
        })
        if (closeRef?.current) {
          closeRef.current.click()
        }
      }

      if (finalResponse?.messageExists && finalResponse.message) {
        toast.info(finalResponse.message, { position: "top-center", autoClose: 10000 })
        return
      }

    }

    dispatch({
      type: "TAB_KEY", payload: {
        keyName: null,
        render: null
      }
    })


    document.getElementById('kt_content_container')!.style.position = 'initial'
    let classss: any = document.getElementById('kt_drawer_chat3')
    if (classss) {
      classss.classList.remove('drawer-on')
    }

    OnCloseCustomerDashboardChangeState()
  }

  const toggleHandlerCloseall = () => {

    setUpdateSourcePopup(false)
    setUpdateFormPopUp(false)
    setAssignformPopUp(false)
    setUpdateCategoryPopup(false)
    setOpenTicketModalDash(false)

  }

  const [openTicketModalDash, setOpenTicketModalDash] = useState<any>(null)


  const addOrUpdateTicketTags = (rowids: any) => {
    dispatch({
      type: "modelOpenFromCustomerDashboard", payload: {
        toggle: true,
        id: rowids,

      }
    })
  }

  const [masterTags, seMasterTags] = useState<any>([])
  useEffect(() => {
    let callApi = masterTags?.length == 0 && TicketData?.id || masterTags?.length == 0 && ticketDataRedux?.id || masterTags?.length == 0 && TicketData?.customerid || masterTags?.length == 0 && ticketDataRedux?.customerid
    async function loadTags() {
      const masterTagList = await postRequest(URL_EndPoints()?.getAllMasterTags, { userType: 5 }, null)
      seMasterTags(masterTagList?.data?.data)
    }
    callApi && loadTags()
  }, [])


  useEffect(() => {
    async function loadTags() {
      const updatedTag = masterTags?.filter((ele: any) => ele?.name == updateTicketSpecificTag?.tag)
      setShowResTag(updatedTag?.length > 0 ? updatedTag[0] : { name: "No Tag" })
    }
    updateTicketSpecificTag?.tag && loadTags()
    async function loadTags2() {
      const updatedTag = masterTags?.filter((ele: any) => ele?.name == TicketDatas?.tags)
      setShowResTag(updatedTag?.length > 0 ? updatedTag[0] : { name: "No Tag" })
    }
    !updateTicketSpecificTag?.tag && loadTags2()
  }, [updateTicketSpecificTag, TicketDatas?.tags, masterTags])

  useEffect(() => {
    async function loadTags2() {
      const updatedTag = masterTags?.filter((ele: any) => ele?.name == TicketDatas?.tags)
      setRowTagDash(updatedTag?.length > 0 ? { tags: updatedTag[0]?.name } : { tags: "No Tag" })
    }
    ticketDataRedux?.isOpen == "dashboard" && loadTags2()
  }, [ticketDataRedux, TicketDatas?.tags, masterTags])

  // last status update code 
  const setUpdatedData = (payload: any) => {
    TicketDatas['renewal_reason_id'] = +payload?.renewalreasonid
    setDefaultLastStatus(payload?.item)
    setTicketsData(TicketDatas)
  }

  useEffect(() => {
    const lastStatusName = cxLastStatusList?.find((ele: any) => {
      return ele?.id === currentRowDataFromList?.renewal_reason_id
    })

    lastStatusName && setDefaultLastStatus(lastStatusName)
  }, [cxLastStatusList, currentRowDataFromList?.renewal_reason_id])

  // last status update code  ending here...


  // welcome call list 1 code here
  const [campaingStatusName, setCampaingStatusName] = useState<any>(null)

  useEffect(() => {
    const campaingStatusName = campaingStatusListData?.find((ele: any) => {
      return ele?.id === currentRowDataFromList?.wc1_campaign_status
    })

    campaingStatusName && setCampaingStatusName(campaingStatusName)
  }, [campaingStatusListData, currentRowDataFromList?.wc1_campaign_status])

  const setUpdatedDataDueDate = (payload: any) => {
    ticketDataRedux.defaultDueDate = payload?.due_date
    dispatch({
      type: "UPDATE_CHAT_DEFAULT_VALUE_DUE_DATE", payload: {
        random: Math.random(),
        source: "pauseUnpaid",
        source_due_date: "dueDatePuaseUnPaid",
        defaultDueDate: payload?.due_date ? payload?.due_date : 'Not Set',
        subscriptionid: ticketDataRedux?.id,
      }
    })
  }

  const checkIsClosed = noticationCredential == "inactive" || noticationCredential == "prospect" ? true : false
  const userAllowedPermissionList = useSelector((store: any) => store.adminReducer.userAllowedPermissionList)
  const assignUserHandler = () => {
    const AssignToUserAllowed = userAllowedPermissionList?.includes("cx-management-dashboard-ticket-assignment")
    if (!AssignToUserAllowed) {
      return 
    }
    setAssignToUserUpdateModal(true)
  }
  return (
    <div className='border' id='kt_drawer_chat2_messenger '>
      <>
        {loading ? (
          <IsLoading></IsLoading>
        ) : (
          <>
            <div className='p-2 overflow-hidden ' style={{ height: "120px", width: "100%" }}>
              <div className='p-1 d-flex justify-content-between align-items-center mb-1'>
                <div>
                  <Link
                    to={'/'}
                    style={{ textDecoration: 'none', color: 'black' }}
                    className='text-gray-500 font-15'
                  >
                    {TicketDatas?.customer
                      ? TicketDatas?.customer?.first_name?.toUpperCase() +
                      ' ' +
                      TicketDatas?.customer?.last_name?.toUpperCase()
                      : 'No Name'.toUpperCase()}{' '}
                    ( {TicketData?.updatedAt?.toUpperCase()} )
                  </Link>
                </div>

                <div
                  className='btn btn-sm btn-icon btn-active-light-primary me-4'
                  id={checkIsClosed ? isCloseAllowed ? "kt_drawer_chat2_close" : "" : "kt_drawer_chat2_close"}
                  onClick={closeHandle}
                  ref={closeRef}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                </div>
              </div>
              <BulkUtilButtonWrapper>


                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-reply-section-ticket-status-update"]}>
                  {
                    ticketDataRedux?.id && <span id={"ticket-status-champ"}>
                      <TicketStatus ticketStatus={TicketDatas?.ticketstatus?.name} ticketStatusId={TicketDatas?.ticketstatus?.id} marginTops={true} setShow={setTicketStatusUpdateModal} />
                      <ReactTooltip
                        anchorId={"ticket-status-champ"}
                        className="bg-dark"
                        content={"Ticket Status"}
                      />
                    </span>
                  }

                </PermissionContextProvider>


                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-reply-section-assign-to-user-update"]}>

                  {
                    ticketDataRedux?.id && TicketDatas?.ticket_assigns?.length > 0 && TicketDatas?.ticket_assigns != undefined ?
                      TicketDatas?.ticket_assigns?.map((assign: any, index: any) => {
                        let uniqueToolId = index + "_" + assign?.name ? assign?.name : "NoName"
                        return <CustomerActionLayout
                          toolTipAnchorId={uniqueToolId}
                          toolTipTitle="Assign To User"
                          title={
                            assign?.name ? assign?.name : "Not Assigned"
                          }
                          setShow={assignUserHandler}
                          allowedKey={userAllowedPermissionList?.includes("cx-management-dashboard-ticket-assignment") ? false : true}
                        />
                      })
                      : ticketDataRedux?.id && <CustomerActionLayout
                        toolTipAnchorId="assign-to-user-form"
                        toolTipTitle="Assign To User"
                        title="Not Assgined"
                        setShow={assignUserHandler}
                        allowedKey={userAllowedPermissionList?.includes("cx-management-dashboard-ticket-assignment") ? false : true}
                      />
                  }

                </PermissionContextProvider>

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-reply-section-ticket-category-update"]}>


                  {
                    ticketDataRedux?.id && <CustomerActionLayout
                      toolTipAnchorId="ticket-category-champ"
                      toolTipTitle="Ticket Category"
                      title={
                        TicketDatas?.ticketcategory
                          ? TicketDatas?.ticketcategory?.category_name
                          : 'No Category'
                      }
                      setShow={setUpdateCategoryPopup}
                    />
                  }
                </PermissionContextProvider>

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-reply-section-sub-ticket-category-update"]}>


                  {
                    ticketDataRedux?.id && <CustomerActionLayout
                      toolTipAnchorId="sub-category-ticket-champ"
                      toolTipTitle="Sub Category "
                      title={
                        TicketDatas?.ticketsubcat?.masterticketsubcategory
                          ? TicketDatas?.ticketsubcat?.masterticketsubcategory?.subcategory_name
                          : 'No Sub Category'
                      }
                      setShow={setUpdateCategoryPopup}
                    />
                  }
                </PermissionContextProvider>

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-reply-section-source-update"]}>

                  {
                    ticketDataRedux?.id && <CustomerActionLayout
                      toolTipAnchorId="update-source-champ"
                      toolTipTitle="Update Source"
                      title={
                        TicketDatas?.ticketsource?.name ? TicketDatas?.ticketsource?.name : "No Source"
                      }
                      setShow={setUpdateSourcePopup}
                    />
                  }
                </PermissionContextProvider>

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-reply-section-source-update"]}>

                  {
                    ticketDataRedux?.id && <CustomerActionLayout
                      toolTipAnchorId="Due_date_update"
                      toolTipTitle="Update Due Date"
                      title={
                        ticketDataRedux?.defaultDueDate ? moment(new Date(ticketDataRedux?.defaultDueDate)).format('DD-MM-YYYY hh:mm A') : "No Due Date"
                      }
                      setShow={setDueDateModal}
                    />
                  }
                </PermissionContextProvider>

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["ticket-reply-section-last_status-update"]}>

                  {
                    ticketDataRedux?.reasonid && ticketDataRedux?.subscriptionid && enableFeatures4CustomerDashboard(catchCurrentOpenedUrl())?.renewalSubsLastStatus && <CustomerActionLayout
                      toolTipAnchorId="update-last-status-champ"
                      toolTipTitle="Update Last Status"
                      title={
                        defaultLastStatus?.name ? defaultLastStatus?.name : "No Last Status"
                      }
                      setShow={setShowLastStatusModal}
                    />
                  }
                </PermissionContextProvider>


                {
                  "wc1_campaign_status" in currentRowDataFromList && enableFeatures4CustomerDashboard(catchCurrentOpenedUrl())?.wlecomeCall1CampaignStatus && <CustomerActionLayout
                    toolTipAnchorId="update-campaign-status"
                    toolTipTitle="Update Campaign Status"
                    title={
                      campaingStatusName?.name ? campaingStatusName?.name : "No Campaign Status"
                    }
                    setShow={setShowCampaignStatusModal}
                  />
                }




                {
                  enableFeatures4CustomerDashboard(catchCurrentOpenedUrl())?.ticketListPrivateTag &&
                  <>
                    <div id={"Add/Updated Tags"} onClick={() => ticketDataRedux?.isOpen == "dashboard" ? setOpenTicketModalDash(true) : addOrUpdateTicketTags(ticketDataRedux?.id)} className={`badge badge-light-${"primary"} fw-bolder cursor-pointer me-1 mt-2 mb-2`}   >
                      {
                        ticketDataRedux?.isOpen == "dashboard" ? rowTagDash?.tags ?? "No Tag" : showResTag?.name
                      }
                      <ReactTooltip
                        anchorId={"Add/Updated Tags"}
                        className="bg-primary"
                        content={"Add/Updated Tags"}
                      />
                    </div>
                  </>
                }


                {/* update source data here  */}
                <ChatHeaderChips
                  SelectedString={TicketData?.ticketno}
                  classes={'primary'}
                ></ChatHeaderChips>
                <br />

              </BulkUtilButtonWrapper>

            </div>


            {
              loading ? <IsLoading></IsLoading> : <ChatBox isDrawer={true} TicketTepliesData={TicketTepliesData} TicketDatas={TicketDatas} setShowImagesToggle={setShowImagesToggle} setShowImagesLink={setShowImagesLink} WhatsappMessage={WhatsappMessage} WhatsappTemplate={WhatsappTemplate} MessageSource={MessageSource} customerDetailData={customerDetailData} setWhatsappMessage={setWhatsappMessage} invokedApi={invokedApi} setTicketsData={setTicketsData} setTicketTepliesData={setTicketTepliesData} ></ChatBox>
            }
          </>
        )}
      </>
      {/* all modal   */}


      {PopOpenClose && (
        <DialogBox
          handleTicketlistPopform={TicketCustomerInformationPopupBTN}
          PopUpPostFormOpen={PopOpenClose}
          ParentData={null}
          reference={'ChatGlobal'}
        />
      )}


      {/* {
        DueDateModal && <DueDateUpdateDashboardForm
          row={ticketDataRedux}
          DueDateModal={DueDateModal}
          setDueDateModal={setDueDateModal}
        />
      } */}

      {
        DueDateModal ? <AdminDueDateForm
          show={DueDateModal}
          setShow={setDueDateModal}
          setUpdatedData={setUpdatedDataDueDate}
          prevInitialPayload={{
            ticket_id: ticketDataRedux?.id ? ticketDataRedux?.id : "",
            prev_due_date: ticketDataRedux?.defaultDueDate,
            due_date: ticketDataRedux?.defaultDueDate,
          }}

          row={ticketDataRedux}
          title="Due Date Update"
          apiUrl="updateDueDate"
          dueDateKeyName="due_date"
          isDateTime={true}
          isServerDueDate={false}
          serverApiUrl="xxxxx"
        /> : <></>

      }




      {assignToUserUpdateModal ?
        <AssigningFormToUser
          setShow={setAssignToUserUpdateModal}
          show={assignToUserUpdateModal}
          AdminData={AdminData}
          Supervisordata={Supervisordata}
          TicketDatas={TicketDatas}
          invokedTicket={invokedTicket}
          TicketData={TicketData}
        ></AssigningFormToUser> : <></>
      }



      {
        ticketStatusUpdateModal ?
          <TicketStatusForm
            setShow={setTicketStatusUpdateModal}
            show={ticketStatusUpdateModal}
            TicketData={TicketData}
            invokedTicket={invokedTicket}

          ></TicketStatusForm> : <></>
      }


      {
        updateCategoryPopup &&
        <UpdateTicketCategory
          TicketCategoryData={TicketCategoryData}
          subTicketCategoryData={subTicketCategoryData}
          TicketDatas={TicketDatas}
          show={updateCategoryPopup}
          setShow={setUpdateCategoryPopup}
          TicketData={TicketData}
          invokedTicket={invokedTicket}
        ></UpdateTicketCategory>
      }

      {
        updateSourcePopup &&
        <UpdateSourceForms
          sourcesData={sourcesData}
          setShow={setUpdateSourcePopup}
          show={updateSourcePopup}
          invokedTicket={invokedTicket}
          TicketData={TicketData}
        ></UpdateSourceForms>
      }

      {
        openTicketModalDash
          ?
          <UpdateTicketTag
            showModal={openTicketModalDash}
            handleClose={toggleHandlerCloseall}
            row={ticketDataRedux}
            setRowData={setRowTagDash}
            rowTagDash={rowTagDash}
          /> : <></>
      }

      {
        showLastStatusModal ? <AdminLastStatusCustomerForm
          setShow={setShowLastStatusModal}
          show={showLastStatusModal}
          row={ticketDataRedux}
          defaultLastStatus={defaultLastStatus ? { label: defaultLastStatus?.name, value: defaultLastStatus?.id } : null}
          setUpdatedData={setUpdatedData}
          lastStatusListData={cxLastStatusList}
          initialData={{
            subscriptionid: ticketDataRedux?.subscriptionid,
            renewalreasonid: ticketDataRedux?.reasonid,
          }}
        /> : <></>
      }

      {
        re_Render ? <ActionOnCallAlertMessage Re_Render={re_Render} /> : <></>

      }

    </div >
  )
}
export default memo(Chats)
