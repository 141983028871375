import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import SingleSelectSearchCategory from '../../../Select/SingleSelectSearchCategory'
import { toast } from 'react-toastify'
import { postRequest } from '../../../Server/Requests'
import { URL_EndPoints } from '../../../Server/URL_EndPoints'
import { adminToastRunner } from '../../../../modules/admins/adminUtils'
const UpdateSourceForms = ({ setShow, show,TicketData, sourcesData, invokedTicket }: any) => {
    const [loader, setLoader] = useState(false)
    const [payload, setPayload] = useState({
        ticketid: TicketData?.id,
        source_id: 0,
    })

    const handleChnageOnSource = async (event: any, name: string) => {
        setPayload({ ...payload, [name]: event ? +event.value : 0 })
    }

    const updateSourceHadler = async () => {
        setLoader(true)
        if (!payload?.ticketid && !payload?.source_id) {
            toast.error("Something is missing", { position: "top-center" })
            setLoader(false)
            return
        }
        const response = await postRequest(URL_EndPoints()?.updateSource, payload, setLoader)
        adminToastRunner(response)
        invokedTicket()
        setShow(false)
    }

    return (
        <>
            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`col-12  mb-8`}>
                        <SingleSelectSearchCategory
                            handleChangeInputData={handleChnageOnSource}
                            HeaderTitle="Select Source"
                            SelectData={sourcesData}
                            DynamicKey={"name"}
                            id={"id"}
                            name="source_id"
                        ></SingleSelectSearchCategory>
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => updateSourceHadler()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating...</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update Source</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default UpdateSourceForms
