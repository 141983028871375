import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'
import { getRequest, postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import SingleRequirementTypeSearch from './SingleRequirementTypeSearch';

const AddChampRequirementType = ({ handleCloseForm, show, row, setRowData = null }: any) => {
    const [payload, setPayload] = useState<any>({
        "cleaner_id": row?.id,
        "champ_requirement_id": 0
    })

    const [loader, setLoader] = useState(false)
    const [updatedData, setupdatedData] = useState<any>("")
    const [disable, setDisable] = useState<any>(null)
    const [responseData, setResponseData] = useState<any>([])

    const handleSelectChange = (event: any, name: any) => {
        setPayload((prev: any) => ({ ...prev, ["champ_requirement_id"]: event ? event.value : null }));
        setupdatedData(event ? event?.item?.requirementType?.name : "")
    }

    const getData = async () => {
        const trainingLocations = await getRequest(URL_EndPoints(null)?.getChampRequirementWithChampCount, null)
        setResponseData(trainingLocations?.data?.data)
    }
    useEffect(() => {
        getData()
    }, [])

    const addChampRequirementHandler = async () => {
        if (payload?.cleaner_id && payload?.champ_requirement_id) {
            setLoader(true)
            const result = await postRequest(URL_EndPoints()?.updateChampColumns, payload, setLoader)
            if (result?.data?.status == 200) {
                if (setRowData) {
                    row.champ_requirement_details = row.champ_requirement_details ? {...row.champ_requirement_details} : {}
                    row.champ_requirement_details.requirementType = row.champ_requirement_details.requirementType ? {...row.champ_requirement_details.requirementType} : {}
                    row.champ_requirement_details.requirementType.name = updatedData
                    setRowData(row)
                }
                toast.success(result?.data?.msg)
                handleCloseForm()
                setDisable(200)
                setLoader(false)
            } else {
                setLoader(false)
                setDisable(null)
                toast.error(result?.data?.msg)
            }
        }
        else {
            toast.error("Something is missing in the form")
        }
    }

    const handleClose = () => {
        handleCloseForm()
    }

    return (
        <>

            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal-small"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Champ Requirement </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="col-12  mb-3" key={434343}>
                            <span className='ms-2 muted'>ID 👉 Type 👉 Area Name 👉 Location Name 👉 Priority 👉 Min Payout 👉 referral  </span>
                            <span className='ms-2'> Amount 👉 champ Bonus Amount 👉 Champ in training </span>
                            <SingleRequirementTypeSearch
                                handleChangeInputData={handleSelectChange}
                                HeaderTitle={"Training Location "}
                                SelectData={responseData?.map((ele: any) => {
                                    let ConcatedLocationName = { ...ele }
                                    ConcatedLocationName.showName = (ele?.id ?? "NA")
                                        + " | " + (ele?.requirementType?.name ?? "NA")
                                        + " | " + (ele?.champ_requirement_area?.areaname ?? "NA")
                                        + " | " + (ele?.trainingLocation?.locationname ?? "NA")
                                        + " | " + (ele?.priority_level?.priority ?? "NA")
                                        + " | " + (ele?.min_payout ?? "NA")
                                        + " | " + (ele?.referral_bonus_amount ?? "NA")
                                        + " | " + (ele?.champ_bonus_amount ?? "NA")
                                        + " | " + (ele?.totalChampCount ?? "NA")

                                    return ConcatedLocationName
                                })}
                                DynamicKey={"showName"}
                                id={"id"}
                                name={"trainingLocationId"}
                                defaultData={null}
                            />
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={() => addChampRequirementHandler()}>
                                {
                                    loader ?
                                        <>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Adding.....</h4>
                                        </>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Add Champ Requirement </h4>
                                }
                            </button>
                        </div>
                    </>
                </Modal.Body>
            </Modal> : ''}
        </>

    )
}
export default AddChampRequirementType
