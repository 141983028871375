const initState = {
    renewalReason: [],
    Inactive_reason: [],
    Alert_reason: [],
    Master_Reason: [],
    Pause_paid: [],
    cleanerListData: [],
    supervisorListData: [],
    jobTypeListData: [],


}
export const ReasonsListDataReducer = (state = initState, { type, payload }: any) => {
  
    switch (type) {
      
        case "RENEWAL_REASON":
            return { ...state, renewalReason: payload }
        case "INACTIVE_DATA":
            return { ...state, Inactive_reason: payload }
        case "ALET_REASON":
            return { ...state, Alert_reason: payload }
        case "MASTER_REASON":
            return { ...state, Master_Reason: payload }
        case "PAUSE_PAID":
            return { ...state, Pause_paid: payload }
        case "CLEANER_LIST_DATA_JOBLIST":
            return { ...state, cleanerListData: payload }
        case "SUPERVISOR_LIST_DATA_JOBLIST":
            return { ...state, supervisorListData: payload }
        case "JOB_TYPE_LIST_DATA_JOBLIST":
            return { ...state, jobTypeListData: payload }
    
        default:
            return state
    }
}


// const Alert_reason = useSelector((store)=>store.ReasonsListDataReducer.Master_Reason)