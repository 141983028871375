import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ColorRing } from 'react-loader-spinner'

function ChangeStartDateForm({
    startDateChangePopup,
    setStartDateChangePopup,
    changeStartDateLoader,
    handleChangeStartDateSubmit,
    setChangeStartDatePayload
}: any) {
  return (
    <Modal size="lg" show={startDateChangePopup} onHide={() => setStartDateChangePopup(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Change Subscription Start Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                <>
                    <Form onSubmit={handleChangeStartDateSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicService">
                            <Form.Group className="mb-3" controlId="formBasicStartDate">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="date" placeholder="Start Date" onChange={(e) => setChangeStartDatePayload({
                                    startDate: e.target.value,
                                })} />
                                <Form.Text className="text-muted">
                                    Start date should be future date
                                </Form.Text>
                            </Form.Group>
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={changeStartDateLoader} className='text-right'>
                            {changeStartDateLoader ? <ColorRing
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            /> : 'Submit'
                            }
                        </Button>
                    </Form>
                </>
            }
        </Modal.Body>
    </Modal>

  )
}

export default ChangeStartDateForm