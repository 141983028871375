import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LocalBaseURL } from '../../../../../BaseURLmanagement';
import { postRequest } from '../../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints';
import BankForm from './BankForm';
import UPIForm from './UPIForm';
import { ContainerCss, PopCloseFormNotification, PopCloseFormNotificationPtag } from '../../../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet';

interface Props {
    handleCloseForm: () => void
    ParentData: any | null
}
const AddNewAccountForm = ({ ParentData, handleCloseForm }: Props) => {
    LocalBaseURL()
    const dispatch = useDispatch()
    const [IsAccountType, setAccountType] = useState("")
    const [loader, setLoader] = useState(false)
    const [disable, setdisable] = useState<any>(null)

    const [PayloadsBank, setPayloadsBank] = useState<any>({
        holder_name: "",
        cleaner_id: ParentData?.id,
        account_type: "",
        account_no: "",
        ifsc: "",
        status: "",
        enable_razorpayx_payout: 0,
    })
    const [PayloadsUPI, setPayloadsUPI] = useState<any>({
        cleaner_id: ParentData?.id,
        account_type: "",
        upi_id: "",
        status: "",
        enable_razorpayx_payout: 0,

    })
    const Togglling = (event: any) => {
        setAccountType(event.value)
        if (event.value === "bank_account") {
            setPayloadsBank({ ...PayloadsBank, [event.name]: event.value })
        }
        else {
            setPayloadsUPI({ ...PayloadsUPI, [event.name]: event.value })
        }
    }


    const handleChangeInputData = (event: any, refs: string) => {
        const { name, value } = event
        if (refs === "bank") {
            setPayloadsBank({ ...PayloadsBank, [name]: value })
        }
        else if (refs == "upi") {
            setPayloadsUPI({ ...PayloadsUPI, [name]: value })
        }
        else {
            setPayloadsBank({ ...PayloadsBank, ["enable_razorpayx_payout"]: PayloadsBank.enable_razorpayx_payout == 0 ? 1 : 0 })
            setPayloadsUPI({ ...PayloadsUPI, ["enable_razorpayx_payout"]: PayloadsUPI.enable_razorpayx_payout == 0 ? 1 : 0 })
        }
    }


    const AddBankAccount = async () => {
        setLoader(true)
        let payloads = IsAccountType === "bank_account" ? PayloadsBank : PayloadsUPI
        // const result = await createCleanerBankDetails(localKeyCheck, payloads, null)
        const result = await postRequest(URL_EndPoints()?.createCleanerBankDetails, payloads, setLoader)

        dispatch({ type: "REFRESH-BANK", payload: Math.random() * 1000 })
        if (result?.data?.message == 200) {
            setdisable(200)
            handleClose()
            toast.success(result.data?.message)
        } else {
            toast.error(result?.data.message)
            handleClose()
        }
    }
    const handleClose = () => {
        handleCloseForm()
    }
    return (
        <div className="p-4" style={ContainerCss}>
            <div style={PopCloseFormNotification}><p style={PopCloseFormNotificationPtag} onClick={handleCloseForm} >X</p></div>
            <div className="p-5 mr-auto">
                <div className='modal-content ' >
                    <div className="row mb-5">
                        <div className="col-12  mb-3">
                            <select
                                className='form-select form-select-solid me-2'
                                name={"account_type"}
                                onChange={(e) => Togglling(e.target)}
                            >
                                <option>Select Account Type</option>
                                <option value={"upi"}>UPI</option>
                                <option value={"bank_account"}>Bank Account</option>
                            </select>
                        </div>
                        {/* <hr /> */}
                    </div>
                    {
                        IsAccountType === "bank_account" ?
                            <BankForm handleChangeInputData={handleChangeInputData} PayloadsBank={PayloadsBank} ></BankForm> :
                            IsAccountType === "upi" ?
                                <UPIForm handleChangeInputData={handleChangeInputData} PayloadsUPI={PayloadsUPI}></UPIForm> : <></>
                    }
                </div>
                {/* <div className='d-flex '> */}

                {/* </div> */}
                <div className='d-flex justify-content-between mt-4' >

                    <div className='d-flex'>
                        <span className='mt-1 me-3'>Enable RazorpayX Payout   </span>
                        <span className=''> <input type="checkbox" className='mt-2' onChange={(e) => handleChangeInputData(e.target.value, "enable_razorpayx_payout")} /> </span>
                    </div>

                    <div className='modal-footer'>



                        <button  className='btn btn-sm p-1 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={AddBankAccount}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h6 className='fw-bold text-white fs-6 ms-2 mt-2'>Adding....</h6>
                                    </>
                                    : <h6 className='fw-bold text-white fs-6 ms-2 mt-2'>Add Bank Account </h6>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddNewAccountForm
