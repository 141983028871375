import React from 'react'
const VideoMediaTemplate = ({ ViewMediaData }: any) => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center' >
            {/* <img className="p-2" src="https://images.pexels.com/photos/302743/pexels-photo-302743.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" /> */}
            {/* <img  className="p-2"src="https://images.pexels.com/photos/302743/pexels-photo-302743.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" /> */}
        </div>
    )
}
export default VideoMediaTemplate
