import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'
import { getRequest, postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';

const IsPaidChampStatus = ({ handleCloseForm, show, row, setRowData }: any) => {
    const [payload, setPayload] = useState<any>({
        "champ_id": row?.id,
        "service_date": null,
        item: null
    })

    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)

    const handleIsPaidUpdate = async () => {
        if (payload?.champ_id && payload?.service_date) {
            setLoader(true)
            const result = await postRequest(URL_EndPoints()?.createChampReferralPaymentRequest, payload, setLoader)
            if (result?.data?.status == 200) {
                if (setRowData) {
                    row.champReferralRequest = {}
                    row.champReferralRequest["name"] = "MOIN"
                    setRowData(row)
                }
                toast.success(result?.data?.msg)
                setDisable(200)
                setLoader(false)
                handleCloseForm()
            } else {
                setLoader(false)
                setDisable(null)
                toast.error(result?.data?.msg)
            }
            setPayload({
                "champ_id": row?.id,
                "service_date": null,
            })
        }
        else {
            toast.error("Something is missing in the form")
        }
    }


    // by this we closeing form
    const handleClose = () => {
        handleCloseForm()
    }

    return (
        <>

            {show ? <Modal show={show} onHide={handleClose} dialogClassName="modal-small"
                // size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='text-muted'>Update  Paid / Unpaid </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <>
                        <div className="col-12  mb-3" key={434343}>
                            <h5 className='mb-2 text-muted'> Service Date </h5>
                            <input
                                type='date'
                                className='form-control  ps-10'
                                name='search'
                                value={payload?.service_date}
                                onChange={(event) => setPayload({ ...payload, ["service_date"]: event.target.value })}
                            />

                        </div>

                        <div className='modal-footer'>
                            <button type='button' className='btn btn-sm p-2 btn-primary ms-4 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={() => handleIsPaidUpdate()}>
                                {
                                    loader ?
                                        <>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                        </>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update  Paid / Unpaid </h4>
                                }
                            </button>
                        </div>
                    </>


                </Modal.Body>
            </Modal> : ''}








        </>



    )
}
export default IsPaidChampStatus
