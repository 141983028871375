import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import { QueryParams } from '../../../consts/Server/QueryParams'
const StatusUpdateOldDeactivateChamp = ({ FilteredData, setStoreRowP2p = null, row }: any) => {
    const [loader, setLoader] = useState(false)
    const [defaultInputLoader, setDefaultInputLoader] = useState<any>(false)
    const [defalutStatus, setDefaultStatus] = useState<any>(null)
    const [statusUpdateDataList, setStatusUpdateDataList] = useState<any>([])

    const [payload, setPayload] = useState<any>({
        "cleaner_id": row?.id,
        "status_id": null
    })

    useEffect(() => {
        setDefaultInputLoader(true)
        row?.cleanerMasterOperationReasons?.id && setDefaultStatus({ label: row?.cleanerMasterOperationReasons?.name, value: row?.cleanerMasterOperationReasons?.id })
        row?.cleanerMasterOperationReasons?.id && setPayload({ ...payload, ["status_id"]: row?.cleanerMasterOperationReasons?.id })
        setTimeout(() => {
            setDefaultInputLoader(false)
        }, 2000)
    }, [row?.cleanerMasterOperationReasons?.id])


    useEffect(() => {
        async function loadInitialData() {
            let newQeueryParams = { ...QueryParams("getMasterReasonForVisitors", "?") }
            newQeueryParams.What += `categoryId=11`
            const { data } = await getRequest(URL_EndPoints(newQeueryParams)?.getMasterReasonForVisitors, null)
            setStatusUpdateDataList(data?.data)
        }
        loadInitialData()
    }, [])


    const handleChange = (event: any, name: any) => {
        setPayload({ ...payload, [name]: event ? event?.value : event, item: event ? event?.item : event })
    }


    const handleInactiveReason = async () => {
        setLoader(true)

        let newPayload = {
            "cleaner_id": payload?.cleaner_id,
            "status_id": payload?.status_id
        }

        if (payload?.status_id) {
            const response = await postRequest(URL_EndPoints(null)?.updateChampColumns, newPayload, setLoader)
            if (response?.data?.status == 200) {
                if (setStoreRowP2p) {

                    const updatedStatus = statusUpdateDataList?.find((status:any)=>status?.id == payload?.status_id)
                    row.cleanerMasterOperationReasons = row.cleanerMasterOperationReasons ? { ...row.cleanerMasterOperationReasons } : {}
                    row.cleanerMasterOperationReasons.name = updatedStatus?.name
                    setStoreRowP2p(row)
                }
                toast.success(response?.data?.msg, { position: "top-center" })
                FilteredData?.setUpdateStatusPop(false)
            }
            else {
                toast.success(response?.data?.msg, { position: "top-center" })
                FilteredData?.setUpdateStatusPop(false)
            }
        } else {
            toast.error("Status id missing")
            FilteredData?.setUpdateStatusPop(false)

        }
    }

    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={()=>FilteredData?.setUpdateStatusPop(false)} dialogClassName="modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        defaultInputLoader ? <IsLoading /> : <div className={`col-12  mb-1`}>
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChange}
                                HeaderTitle={"Select  Status"}
                                SelectData={statusUpdateDataList}
                                DynamicKey={"name"}
                                id={"id"}
                                name={"status_id"}
                                defaultData={defalutStatus ? defalutStatus : null}
                            />
                        </div>
                    }
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => handleInactiveReason()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default StatusUpdateOldDeactivateChamp
