
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { postRequest } from '../../Server/Requests';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const TransferPaymentChamp = ({ show, setShow, champsDetails }: any) => {
    const [apiLoader, setApiLoader] = useState<boolean>(false)
    const [error, setError] = useState<any>('')
    const [errorRemark, setErrorRemark] = useState<any>('')

    const [payload, setPayload] = useState<any>({
        champId: champsDetails?.id,
        amountValue: 0,
        remarks: '',
    })

    const validatePayload = () => {
        const amountValue = parseFloat(payload.amountValue)
        if(!amountValue || amountValue < 0) {
            setError("Amount must be positive")
            return false
        }else {
            setError("")
        }
        if(!payload.remarks) {
            setErrorRemark("Remark is missing")
            return false
        }else {
            setErrorRemark("")
        }
        return true
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!validatePayload()) {
            return
        }
        setError("")
        setApiLoader(true)
        try {
            const {data} = await postRequest(URL_EndPoints()?.initiateTransferDirectToChamp, payload, setApiLoader)
            if(!data) {
                throw new Error("Something went wrong")
            }
            data?.status == 200 && data?.data?.success ? toast.success(data?.message) : toast.error(data?.message)
            setShow(false)
        } catch (error) {
            toast.error("Something went wrong")
        }finally {
            setApiLoader(false)
        }
    }

    const handleModalClose = () => {
        setPayload((prev: any) => ({...prev, amountValue: 0}))
        setShow(false)        
    }

    return (
        <Modal size="sm" show={show} onHide={handleModalClose} >
            <Modal.Header closeButton>
                <Modal.Title>Payment Transfer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <>
                        <Form onSubmit={handleSubmit}>
                            <div className='mb-4'>
                                <Form.Label className='text-muted'>Amount (INR)</Form.Label>
                                <input
                                    placeholder='Amount'
                                    type='number'
                                    className='form-control mb-lg-0'
                                    value={payload?.amountValue}
                                    name="amountValue"
                                    onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                />
                                <span className='text-danger'>{error}</span>
                            </div>
                            <div className='mb-4'>
                                <Form.Label className='text-muted'>Remark</Form.Label>
                                <input
                                    placeholder='Remark'
                                    type='text'
                                    className='form-control mb-lg-0'
                                    value={payload?.remarks}
                                    name="remarks"
                                    onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                                />
                                <span className='text-danger'>{errorRemark}</span>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <Button variant="success" size='sm' type="submit" disabled={apiLoader} >Transfer {apiLoader ? '...' : ''}</Button>
                            </div>
                        </Form>
                    </>
            </Modal.Body>
        </Modal>

    )
}

export default TransferPaymentChamp



