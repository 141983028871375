

import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Bars } from 'react-loader-spinner'
import { postRequest } from '../../Server/Requests'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import AdminSingleSelectFilter from '../../../modules/admins/adminForms/adminFormComponents/AdminSingleSelectFilter'
import AdminTextBoxReuse from '../../../modules/admins/adminForms/adminFormComponents/AdminTextBoxReuse'
import IsLoading from '../../IsLoading/IsLoading'

const VehicleUpdateForm = ({ FilteredData, formData }: any) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [vehicleDetails, setVehicleDetails] = useState<any>({})
    const [loaderApi, setLoaderApi] = useState(false)
    const [updatedModel, setUpdatedModels] = useState([])
    const [updatedBrand, setUpdatedBrand] = useState([])
    const [payload, setPayload] = useState<any>({
        vehicle_category: null,
        vehicleid: FilteredData?.payloads?.vehicleid,
        vehicleno: "",
        brandid: null,
        modelid: null,
        parking_no: "",
        vehicle_type: null,
        color: "",
        customerid: FilteredData?.payloads?.customerid,
    })

    useEffect(() => {
        if (payload?.vehicle_category && payload?.brandid) {
            const updatedData = formData.vehicleModels?.filter((ele: any) => ele?.category_id == payload?.vehicle_category && ele?.brandid == payload?.brandid)
            setUpdatedModels(updatedData)
        }

    }, [payload?.vehicle_category, payload?.brandid])

    useEffect(() => {
        if (payload?.vehicle_type) {
            const updatedData = formData.vehicleBrands?.filter((ele: any) => ele?.vehicletype_id == payload?.vehicle_type)
            setUpdatedBrand(updatedData)
        }

    }, [payload?.vehicle_type])

    const getVehicleDetails = async () => {
        setLoaderApi(true)
        const response = await postRequest(URL_EndPoints()?.getVehicleDetail, { vehicleID: FilteredData?.payloads?.vehicleid }, setLoaderApi)
        setVehicleDetails(response?.data?.data)
    }

    useEffect(() => {
        if (FilteredData?.payloads?.vehicleid) {
            getVehicleDetails()
        }
    }, [FilteredData?.payloads?.vehicleid])

    useEffect(() => {
        if (vehicleDetails) {
            setPayload((prev: any) => ({
                ...prev,
                vehicleno: vehicleDetails?.vehicleno || prev.vehicleno,
                vehicle_category: vehicleDetails?.vehicle_category || prev.vehicle_category,
                parking_no: vehicleDetails?.parking_no || prev.parking_no,
                vehicle_type: vehicleDetails?.vehicle_type || prev.vehicle_type,
                modelid: vehicleDetails?.modelid || prev.modelid,
                brandid: vehicleDetails?.brandid || prev.brandid,
                color: vehicleDetails?.color || prev.color
            }))
        }
    }, [vehicleDetails])


    const UpdateVehicleHandler = async () => {
        if (payload?.customerid) {
            setLoader(true)
            const response: any = await postRequest(URL_EndPoints(null)?.updateVehicleData, payload, null)
            setLoader(false)
            if (response?.data?.status === 200) {
                toast.success(response?.data?.msg)
                setDisable(null)
                dispatch({ type: "VEHICLE_UPDATED", payload: { vehcileid: 1, updateVehicle_render: Math.random() } })
                FilteredData?.handleClose()
            } else {
                toast.error(response?.data?.msg)
            }
        }
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload((prev: any) => ({ ...prev, [name]: event ? event?.value : event }))
    }

    const defCategory: any = formData?.vehicleCategory?.find((ele: any) => ele.id === vehicleDetails?.vehicle_category)
    const defBrand: any = updatedBrand?.find((ele: any) => ele.id === payload?.brandid)
    // const defBrand: any = updatedBrand?.find((ele: any) => ele.id === vehicleDetails?.brandit)
    const defType: any = formData?.vehicleTypes?.find((ele: any) => ele.id === vehicleDetails?.vehicle_type)
    const defColor: any = formData?.colorsType?.find((ele: any) => ele.id === vehicleDetails?.color)
    const defModel: any = updatedModel?.find((ele: any) => ele.id === payload?.modelid)
    // const defModel: any = updatedModel?.find((ele: any) => ele.id === vehicleDetails?.modelid)
    const brandDefNotMatched = formData?.vehicleBrands?.find((ele: any) => ele.id === vehicleDetails?.brandit)
    const modelDefNotMatched = formData?.vehicleModels?.find((ele: any) => ele.id === vehicleDetails?.modelid)

    return (
        <>{FilteredData?.VehcileUpdateShow && (
            <Modal show={FilteredData?.VehcileUpdateShow} onHide={FilteredData?.handleClose} dialogClassName="modal-small"
                aria-labelledby="contained-modal-title-vcenter" centered key={"vehicleUpdateList"}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loaderApi ? <IsLoading /> : (
                        <>
                            <label htmlFor="Vehicle Number" className='mt-2 text-muted mb-1'>Vehicle Number</label>
                            <AdminTextBoxReuse onChangeHandler={onChangeHandler} HeaderTitle="Vehicle No." payload={payload} name="vehicleno" inputType="text" label="" />

                            <div key="vehicle_typeList">
                                <label htmlFor="Select Vehicle Type" className='mt-2 text-muted mb-1'>Select Vehicle Type</label>
                                <AdminSingleSelectFilter onChangeHandler={onChangeHandler} HeaderTitle="Select Vehicle Type" SelectData={formData?.vehicleTypes}
                                    displayFields={["vehicle_type"]} id="id" name="vehicle_type" defaultData={defType ? { item: { label: defType?.vehicle_type, value: defType?.id } } : null} searchable={false} />
                            </div>

                            <div key="vehicle_Category">
                                <label htmlFor="Select Vehicle Category" className='mt-2 text-muted mb-1'>Select Vehicle Category</label>
                                <AdminSingleSelectFilter onChangeHandler={onChangeHandler} HeaderTitle="Select Vehicle Category" SelectData={formData?.vehicleCategory}
                                    displayFields={["category_name"]} id="id" name="vehicle_category" defaultData={defCategory ? { item: { label: defCategory?.category_name, value: defCategory?.id } } : null} searchable={false} />
                            </div>

                            {payload?.vehicle_type && (
                                <div key="vehicle_Brand">
                                    <label htmlFor="Select Vehicle Brand" className='mt-2 text-muted mb-1'>Select Vehicle Brand
                                        {
                                            !defBrand && brandDefNotMatched ? <label  className='mt-2 text-muted mb-1'>👉  {brandDefNotMatched?.name}</label> : <></>
                                        }

                                    </label>
                                    <AdminSingleSelectFilter onChangeHandler={onChangeHandler} HeaderTitle="Select Vehicle Brand" SelectData={updatedBrand}
                                        displayFields={["name"]} id="id" name="brandid" defaultData={defBrand ? { item: { label: defBrand?.name, value: defBrand?.id } } : null} searchable={false} />

                                </div>
                            )}

                            {payload?.brandid && payload?.vehicle_category && (
                                <div key="vehicle_Model">
                                    <label htmlFor="Select Vehicle Model" className='mt-2 text-muted mb-1'>Select Vehicle Model   {
                                        !defModel && modelDefNotMatched ? <label className='mt-2 text-success mb-1'> 👉 {modelDefNotMatched?.name}</label> : <></>
                                    } </label>
                                    <AdminSingleSelectFilter onChangeHandler={onChangeHandler} HeaderTitle="Select Vehicle Model" SelectData={updatedModel}
                                        displayFields={["name"]} id="id" name="modelid" defaultData={defModel ? { item: { label: defModel?.name, value: defModel?.id } } : null} searchable={false} />

                                </div>
                            )}

                            <label htmlFor="Parking No" className='mt-2 text-muted mb-1'>Parking No.</label>
                            <AdminTextBoxReuse onChangeHandler={onChangeHandler} HeaderTitle="Parking No." payload={payload} name="parking_no" inputType="text" label="" />

                            <label htmlFor="Select Color" className='mt-2 text-muted mb-1'>Select Vehicle Color</label>
                            <AdminSingleSelectFilter onChangeHandler={onChangeHandler} HeaderTitle="Select Color" SelectData={formData?.colorsType}
                                displayFields={["name"]} id="id" name="color" defaultData={defColor ? { item: { label: defColor?.name, value: defColor?.id } } : null} searchable={false} />

                            <button className='btn btn-sm btn-dark mt-4' disabled={disable} onClick={UpdateVehicleHandler}>
                                {loader ? <Bars height="15" color="#ffffff" ariaLabel="loading" /> : "Update Vehicle"}
                            </button>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        )}
        </>
    )
}

export default VehicleUpdateForm
